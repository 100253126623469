import moment from "moment";

import { APP_ENVS, ERP_ENVS } from "constants/common";

import {
  characterValidation,
  requiredValidation,
} from "./genericErrorMessages";

export const getCurrentSubdomain = () => window.location.host.split(".")[0];
export const getDomainName = () =>
  window.location.host.split(".").slice(1).join(".");

export const checkAppEnvs = (subdomain) => APP_ENVS.includes(subdomain);
export const checkErpEnvs = (subdomain) => ERP_ENVS.includes(subdomain);

export const dateWithinTodayTomorrow = (date) =>
  moment(date).startOf("day").diff(moment().startOf("day"), "days") >= 0 &&
  moment(date).startOf("day").diff(moment().startOf("day"), "days") <= 1;

export const validateDropdowns = ({ isRequired = true, field, limit = 0 }) => {
  if (
    isRequired &&
    (!field || (!field.name && !field.value) || field.name?.length < 0)
  ) {
    return requiredValidation();
  }
  if (field && limit && field.value?.length > limit) {
    return characterValidation({ limit });
  }
  return null;
};

export const toCamelCase = (input) => {
  return input
    .toLowerCase()
    .replace(/[\s_-](.)/g, (_, char) => char.toUpperCase());
};
