import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { BsXLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import PropTypes, { string } from "prop-types";

import {
  addQuestion,
  addQuestionImage,
  addQuestionPaperQuestion,
  deleteQuestionImage,
  updateQuestion,
} from "api/erp/questionPaperApi";
import { ALERT_TYPE, ICON_SIZE_HASH, QUESTION_PAGE_TYPES } from "app/constants";
import { Button, LabeledInput } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import { SITE_TITLE } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import { requiredValidation } from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { getCategoryAction } from "store/thunkActions/erp/categoriesThunk";
import { updateQuestionAction } from "store/thunkActions/erp/questionBankThunk";
import { getQuestionTagAction } from "store/thunkActions/erp/questionTagThunk";

const Form = ({ questionPaper, selectedQuestion }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      option: [
        { text: "", image: undefined, correct_answer: false },
        { text: "", image: undefined, correct_answer: false },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "option",
  });

  const navigate = useNavigate();
  const showAlert = useAlert();
  const question = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const viewQuestionID = searchParams.get("view_question");
  const hasQuestionType = searchParams.has("question_type");
  const questionType = searchParams.get("question_type");
  const hasIndexPage = location?.state?.index;
  const clone = new URLSearchParams(window.location.search).has("clone");
  const questionBank = QUESTION_PAGE_TYPES[1].key;
  const isSectional = questionPaper?.sectional;

  const [showImages, setShowImages] = useState([]);
  const [questionImages, setQuestionImages] = useState([]);
  const [deleteQuestionImages, setDeleteQuestionImages] = useState([]);
  const [optionImage, setOptionImage] = useState([]);
  const [rowIndex, setRowIndex] = useState();
  const [disabled, setDisabled] = useState(false);
  const { data } = useSelector((state) => state.questionTag);
  const list = useSelector((state) => state.categories.data);
  const currentPageHeaderName = () => {
    if (selectedQuestion) {
      if (clone) {
        return "Clone";
      }
      return "Edit";
    }
    return "Add";
  };

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: {
          intermediateLinkList: !hasQuestionType
            ? [
                { name: "Question Paper", link: "/questions_manager" },
                {
                  name: questionPaper?.name || "",
                  link: `/questions_manager/question_paper/${
                    question?.id || ""
                  }`,
                },
              ]
            : [
                {
                  name: "Question Bank",
                  link: "/questions_manager?tab=question_bank",
                },
              ],
          currentPageName: `${currentPageHeaderName()} Question`,
        },
      })
    );
  }, [
    questionPaper,
    selectedQuestion,
    hasQuestionType,
    clone,
    dispatch,
    question?.id,
  ]);

  useEffect(() => {
    document.title = `${currentPageHeaderName()} Question - ${
      hasQuestionType ? questionType.toUpperCase() : questionPaper?.name
    } - ${SITE_TITLE}`;
  }, [selectedQuestion]);

  useEffect(() => {
    clearErrors();
  }, [showImages]);

  useEffect(() => {
    dispatch(getQuestionTagAction());
    dispatch(getCategoryAction());
  }, [dispatch]);

  useEffect(() => {
    if (selectedQuestion) {
      setQuestionImages(selectedQuestion.images);
      reset({
        ...selectedQuestion,
        question: clone
          ? `Copy of- ${selectedQuestion.text}`
          : selectedQuestion.text,
        option: selectedQuestion.answers,
        tags: selectedQuestion.tags.map(
          ({ tag_id: tagId, tag_name: tagName }) => {
            return { id: tagId, name: tagName };
          }
        ),
        category: selectedQuestion.category_name
          ? { name: selectedQuestion.category_name }
          : null,
      });
    }
    return () => {
      setShowImages([]);
      setQuestionImages([]);
    };
  }, [selectedQuestion, selectedQuestion?.answers]);

  const setErrorsOnFields = (errors) => {
    if (!watch("marks")) {
      setError("marks", { message: "can't be blank" });
    }

    Object.entries(errors).forEach(([key, value]) => {
      if (key == "text") {
        setError("question", { message: value });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const setImages = (e) => {
    const files = e.target?.files;

    for (let i = 0; i < files.length; i += 1) {
      if (files[i].size < 1000000) {
        setShowImages((prevImages) => [...prevImages, files[i]]);
      } else {
        setError("images", { message: "select less than 1 mb!" });
      }
    }
  };

  const addQuestionImages = async () => {
    const formData = new FormData();
    formData.append(
      "question_id",
      (selectedQuestion.question_id ??= selectedQuestion.id)
    );
    showImages.forEach((image) => formData.append("images[]", image));

    return addQuestionImage(formData);
  };

  const handleDeleteQuestionImages = (id) => {
    setDeleteQuestionImages((prev) => [...prev, id]);
    const arr = questionImages.filter((img) => img.id != id);
    setQuestionImages(arr);
  };

  const deleteOption = (item, index) => {
    if (rowIndex !== index && !item.has_candidate_answer) {
      setDisabled(false);
      remove(index);
    } else {
      setDisabled(true);
    }
    setRowIndex(undefined);
  };

  const handleRemoveImage = (img) => {
    const arr = showImages.filter((item) => item.name !== img.name);
    setShowImages(arr);
  };

  const getSubmitButtonText = () =>
    /* eslint-disable-next-line no-nested-ternary */
    selectedQuestion ? (clone ? "Save" : "Update") : "Save";

  const submitHandler = async (data) => {
    let checked = 0;

    data.option.forEach((item) => {
      checked += item.correct_answer;
    });

    if (checked > 1) {
      return showAlert("danger", "Select only one correct answer.");
    }

    if (checked < 1) {
      return showAlert("danger", "Select  one correct answer.");
    }

    const categoryId = list?.filter((l) => l.name == data.category?.name);

    const tagsArr = data.tags.map(({ value: name, ...rest }) => ({
      name,
      id: null,
      ...rest,
    }));

    const optionsArr = data.option.map(
      ({
        id,
        text,
        image,
        correct_answer: correctAnswer,
        attachment_content_type: attachmentContentType,
        attachment_file_name: attachmentFileName,
        attachment_file_size: attachmentFileSize,
        attachment_updated_at: attachmentUpdatedAt,
      }) => ({
        id,
        text,
        image: image.length > 0 ? image[0] : null,
        correct_answer: correctAnswer,
        attachment_content_type: attachmentContentType,
        attachment_file_name: attachmentFileName,
        attachment_file_size: attachmentFileSize,
        attachment_updated_at: attachmentUpdatedAt,
      })
    );

    const formData = new FormData();

    if (selectedQuestion && !clone) {
      formData.append("text", data.question.trim());
      formData.append("duration", data.duration);
      formData.append("marks", data.marks);
      formData.append("tags", JSON.stringify(tagsArr));
      formData.append("category_id", data.category?.id || categoryId[0]?.id);
      formData.append("active", true);
      optionsArr.forEach((option, id) => {
        if (option.id) {
          formData.append(`answers[${id}][id]`, option.id);
        }
        if (option.attachment_content_type) {
          formData.append(
            `answers[${id}][attachment_content_type]`,
            option.attachment_content_type
          );
        }
        if (option.attachment_file_name) {
          formData.append(
            `answers[${id}][attachment_file_name]`,
            option.attachment_file_name
          );
        }
        if (option.attachment_file_size) {
          formData.append(
            `answers[${id}][attachment_file_size]`,
            option.attachment_file_size
          );
        }
        if (option.attachment_updated_at) {
          formData.append(
            `answers[${id}][attachment_updated_at]`,
            option.attachment_updated_at
          );
        }
        formData.append(`answers[${id}][text]`, option.text);
        if (option.image) {
          formData.append(`answers[${id}][image]`, option.image);
        }

        formData.append(
          `answers[${id}][correct_answer]`,
          option.correct_answer
        );
      });
      if (!hasQuestionType) {
        formData.append("question_paper_id", questionPaper.id);
      }

      // eslint-disable-next-line no-return-assign
      return updateQuestion(
        (selectedQuestion.question_id ??= selectedQuestion.id),
        formData
      )
        .then(async (response) => {
          if (showImages.length > 0) {
            await addQuestionImages().catch(() => {
              setError("images", "Something went wrong.");
            });
          }

          if (deleteQuestionImages.length > 0) {
            deleteQuestionImages.map((id) => {
              return deleteQuestionImage(id).catch(() => {
                setError("images", "Something went wrong.");
              });
            });
          }
          showAlert(
            ALERT_TYPE[response.meta.message_type],
            response.meta.message
          );
          dispatch(updateQuestionAction(question.question_id, formData));
          if (hasIndexPage) {
            window.history.back();
          } else if (!hasQuestionType) {
            navigate(`/questions_manager/question_paper/${questionPaper.id}`);
          } else {
            navigate(
              `/questions_manager/question/${viewQuestionID}?${constructQueryParams(
                {
                  direct: questionBank,
                }
              )}`
            );
          }
        })
        .catch((errors) => {
          setErrorsOnFields(errors.response?.data?.errors);
        });
    }
    showImages.forEach((image) => formData.append("images[]", image));
    formData.append("tags", JSON.stringify(tagsArr));
    formData.append("text", data.question);
    formData.append("duration", data.duration);
    formData.append("marks", data.marks);
    formData.append("category_id", data.category?.id || categoryId[0]?.id);
    formData.append("active", true);
    optionsArr.forEach((option, index) => {
      formData.append(`answers[${index}][text]`, option.text);
      if (option.image) {
        formData.append(`answers[${index}][image]`, option.image);
      }

      formData.append(
        `answers[${index}][correct_answer]`,
        option.correct_answer
      );
    });
    if (!hasQuestionType) {
      formData.append("question_paper_id", questionPaper.id);
      if (isSectional) {
        formData.append("section_id", question.section_id);
      }
    } else {
      formData.append("question_type", questionType);
    }
    if (hasQuestionType) {
      return addQuestion(formData)
        .then(async (response) => {
          if (viewQuestionID) {
            navigate(
              `/questions_manager/question/${
                response.data.id
              }?${constructQueryParams({ direct: questionBank })}`
            );
          } else {
            navigate(
              `/questions_manager?${constructQueryParams({
                tab: questionBank,
              })}`
            );
          }

          if (!clone) {
            showAlert(
              ALERT_TYPE[response.meta.message_type],
              response.meta.message
            );
          } else {
            showAlert("success", "Question has been saved successfully!");
          }
        })
        .catch((errors) => {
          if (errors.response.data.meta) {
            return showAlert(
              ALERT_TYPE[errors.response.data.meta.message_type],
              errors.response.data.meta.message
            );
          }
          setErrorsOnFields(errors.response.data.errors);
        });
    }
    return addQuestionPaperQuestion(questionPaper.id, formData)
      .then(async (response) => {
        if (isSectional) {
          navigate(
            `/questions_manager/question_paper/${
              question.id
            }?${constructQueryParams({ tab: question.section_id })}`
          );
        } else {
          navigate(`/questions_manager/question_paper/${question.id}`);
        }

        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );
      })
      .catch((errors) => {
        if (errors.response.data.meta) {
          return showAlert(
            ALERT_TYPE[errors.response.data.meta.message_type],
            errors.response.data.meta.message
          );
        }
        setErrorsOnFields(errors.response.data.errors);
      });
  };

  const handleNavigateCancel = () => {
    if (hasIndexPage || (!viewQuestionID && clone)) {
      window.history.back();
    } else if (!hasQuestionType && isSectional) {
      navigate(
        `/questions_manager/question_paper/${
          question.id
        }?${constructQueryParams({ tab: question.section_id })}`
      );
    } else if (!hasQuestionType) {
      navigate(`/questions_manager/question_paper/${question.id}`);
    } else if (viewQuestionID) {
      navigate(
        `/questions_manager/question/${viewQuestionID}?${constructQueryParams({
          direct: questionBank,
        })}`
      );
    } else {
      navigate(
        `/questions_manager?${constructQueryParams({ tab: questionBank })}`
      );
    }
  };

  return (
    <div className="w-full py-4 px-4">
      <form
        className="mx-auto mt-8 h-fit w-full bg-white p-4 xl:container md:px-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
        data-testid="question-form"
      >
        <div className="flex gap-2 flex-col sm:flex-row mb-1">
          <label
            htmlFor="question"
            className={`required font-medium text-md ${
              errors.question && "text-danger-main"
            }`}
          >
            Question
          </label>
        </div>

        <Controller
          name="question"
          control={control}
          defaultValue=""
          rules={{
            required: "cant't be blank",
          }}
          render={({ field }) => (
            <RichTextEditor
              id="question"
              content={field.value}
              onChange={(content) => field.onChange(content)}
              placeholder="Enter question here"
              fieldError={!!errors.question?.message}
            />
          )}
        />
        {errors.question && (
          <p className="text-sm text-danger-dark">{errors.question.message}</p>
        )}

        <div className="mt-4 flex flex-col ">
          <div className="flex flex-wrap">
            {questionImages?.map((img) => (
              <div className="relative mx-2" key={img.id}>
                <img
                  data-testid={img.attachment_file_name}
                  className="my-2 h-[300px] w-[200px]"
                  src={img.attachment}
                  alt=""
                  onError={(event) => {
                    event.target.style.display = "none";
                  }}
                />
                {img.attachment_file_name && (
                  <button
                    title="Remove Image"
                    type="button"
                    onClick={() => handleDeleteQuestionImages(img.id)}
                    className="btn-icon-danger absolute -top-1 -right-3 bg-danger-lightest ring-2 ring-white hover:bg-danger-lighter"
                  >
                    <BsXLg />
                  </button>
                )}
              </div>
            ))}
          </div>

          {showImages?.map((img, index) => (
            <div
              key={index}
              className="mb-2 flex w-fit flex-row rounded-md bg-gray-300 p-1"
            >
              {img.name || img.attachment_file_name}
              <button
                title="Remove Image"
                type="button"
                onClick={() => handleRemoveImage(img)}
                className="mt-1 ml-2 cursor-pointer rounded-full hover:bg-primary-lightest"
              >
                <AiOutlineClose className="text-sm" />
              </button>
            </div>
          ))}
        </div>

        <label
          htmlFor="images"
          className={`pointer-events-none mt-2 mb-1 block text-md font-medium ${
            errors.images && "text-danger-main"
          }`}
          {...register("filename")}
        >
          Images <span className="mt-1 text-xs italic">( less than 1 mb )</span>
        </label>

        <label
          htmlFor="images"
          className="btn-text-primary text-md bg-primary-lightest"
        >
          Choose File(S)
        </label>

        <input
          id="images"
          className={`${errors.images ? "input-error" : ""} hidden w-[229px]`}
          type="file"
          accept=".png, .jpg, .jpeg"
          {...register("images")}
          onChange={setImages}
          multiple
        />
        {errors.images && (
          <p className="text-sm text-danger-dark">{errors.images.message}</p>
        )}

        <div className="mt-2 flex flex-col justify-between sm:flex-row">
          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="question_type"
              className="required mt-4 text-md mb-1 block font-medium"
            >
              Question Type
            </label>

            <Controller
              name="question_type"
              control={control}
              render={({ field }) => (
                <Select
                  name="question_type"
                  className="bg-gray-lighter"
                  isClearable
                  isDisabled
                  id="question_type"
                  menuPlacement="auto"
                  styles={reactSelectStyle(errors.question_type)}
                  placeholder={
                    !hasQuestionType
                      ? questionPaper?.question_paper_type?.toUpperCase()
                      : questionType.toUpperCase()
                  }
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="category"
              className={classNames(
                "required mt-4 mb-1 text-md block font-medium",
                {
                  "text-danger-main": errors.category,
                }
              )}
            >
              Categories
            </label>

            <Controller
              name="category"
              control={control}
              rules={{
                required: requiredValidation(),
              }}
              render={({ field }) => (
                <Select
                  name="category"
                  placeholder="Select a category"
                  options={list}
                  id="category"
                  className="w-full"
                  value={field.value}
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                  isClearable
                  isSearchable
                  menuPlacement="auto"
                  styles={reactSelectStyle(errors.category)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors.category && (
              <p className="text-sm text-danger-dark">
                {errors.category.message}
              </p>
            )}
          </div>
        </div>
        <LabeledInput
          id="tags"
          labelClassNames={classNames(
            "block mt-3 mb-1 text-md font-medium required",
            {
              "text-danger-main": errors.tags,
            }
          )}
          label="Tags"
          errorMessageClassNames="text-sm text-danger-dark"
        />
        <Controller
          name="tags"
          control={control}
          rules={{
            required: requiredValidation(),
          }}
          render={({ field }) => (
            <CreatableSelect
              isClearable
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              maxMenuHeight={160}
              id="tags"
              name="tags"
              menuPlacement="auto"
              className="w-full"
              styles={reactSelectStyle(errors.tags)}
              value={field.value ? field.value.name || field.value : null}
              options={data}
              onChange={(value) => field.onChange(value)}
              debounceTimeout={1000}
              placeholder="Enter text to get matching tags or to create a new tag"
              getOptionValue={(option) => option.id || option.label}
              getOptionLabel={(option) =>
                option.label || option.name || option.tag_name
              }
              formatCreateLabel={(name) => `Create "${name}"`}
            />
          )}
        />

        {errors.tags && (
          <p className="text-sm text-danger-dark">{errors.tags?.message}</p>
        )}

        <div className="mt-2 flex flex-col justify-between sm:flex-row ">
          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="duration"
              className={`required mt-2 mb-1 text-md block font-medium ${
                errors.duration && "text-danger-main"
              }`}
            >
              Duration{" "}
              <span className="mt-1 text-xs italic">( in seconds )</span>
            </label>

            <input
              id="duration"
              className={`${errors.duration ? "input-error" : "input"} w-full`}
              type="number"
              pattern="[0-9]"
              placeholder="Duration"
              {...register("duration", {
                min: {
                  value: 1,
                  message: "Minimum duration must be 1 sec",
                },
                required: "can't be blank",
                valueAsNumber: true,
              })}
            />

            {errors.duration && (
              <p className="text-sm text-danger-dark">
                {errors.duration.message}
              </p>
            )}
          </div>

          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="marks"
              className={`required mt-2 mb-1 text-md block font-medium ${
                errors.marks && "text-danger-main"
              }`}
            >
              Marks
            </label>

            <input
              id="marks"
              className={`${errors.marks ? "input-error" : "input"} w-full`}
              type="number"
              placeholder="Marks"
              pattern="[0-9]"
              step="0.01"
              {...register("marks", {
                min: {
                  value: 1,
                  message: "Minimum mark must be 1",
                },
                required: "can't be blank",
                valueAsNumber: true,
              })}
            />

            {errors.marks && (
              <p className="text-sm text-danger-dark">{errors.marks.message}</p>
            )}
          </div>
        </div>

        <div className="mt-3">
          <ActionAlert
            message={
              <p className="text-md">
                Please give at least two answer options and mark one correct.
              </p>
            }
            alertType="info"
            iconName="info"
            isButtonRequire={false}
            roundedBorder={false}
            iconSize={ICON_SIZE_HASH.md}
          />
        </div>

        {fields.map((item, index) => {
          return (
            <div key={item.id} className="mt-2 py-4">
              <div className="flex justify-between">
                <div className="flex gap-5 items-center">
                  <label
                    htmlFor={`option.${index}.text`}
                    className={`font-medium ${
                      errors.option?.[index]?.text && "text-danger-main"
                    }`}
                  >
                    Option {index + 1}
                  </label>
                  {fields.length > 2 && (
                    <div
                      data-tooltip-content={
                        // eslint-disable-next-line no-nested-ternary
                        item.has_candidate_answer
                          ? "You can't delete this answer, as it is associated with exam(s)."
                          : ""
                      }
                      data-tooltip-id="optionInfo"
                    >
                      <button
                        title="Delete Option"
                        type="button"
                        onClick={() => {
                          setRowIndex(index);
                          deleteOption(item, index);
                        }}
                        className={classNames(
                          "text-xl text-danger-main btn-icon-danger",
                          {
                            "text-gray-500": item.has_candidate_answer,
                          }
                        )}
                        disabled={!!(item.has_candidate_answer && disabled)}
                      >
                        <BiTrash />
                      </button>
                    </div>
                  )}
                </div>

                <div>
                  <label
                    htmlFor={`option.${index}.correct_answer`}
                    className="mb-1 text-md font-medium"
                  >
                    Correct
                  </label>
                  <input
                    id={`option.${index}.correct_answer`}
                    data-testid={`option-${index}-correct_answer`}
                    className=" float-left mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-700 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
                    type="checkbox"
                    {...register(`option.${index}.correct_answer`)}
                  />
                </div>
              </div>
              <Controller
                name={`option.${index}.text`}
                control={control}
                data-testid={`option-${index}-text`}
                defaultValue=""
                rules={{
                  required: "cant't be blank",
                }}
                render={({ field }) => (
                  <RichTextEditor
                    id={`option.${index}.text`}
                    content={field?.value}
                    onChange={(content) => field.onChange(content)}
                    placeholder="Enter Option"
                    fieldError={!!errors.option?.[index]?.text?.message}
                  />
                )}
              />
              {errors.option?.[index]?.text && (
                <p className="text-sm text-danger-dark">
                  {errors.option?.[index]?.text.message}
                </p>
              )}

              <div>
                <label
                  htmlFor={`option.${index}.image`}
                  className="pointer-events-none mb-1 text-md block font-medium"
                >
                  Image
                </label>
                {item.attachment_file_name && (
                  <img
                    className="h-[300px] w-[200px]"
                    src={item.attachment}
                    alt=""
                    onError={(event) => {
                      event.target.style.display = "none";
                    }}
                  />
                )}

                <label
                  htmlFor={`option.${index}.image`}
                  className="btn-text-primary text-md bg-primary-lightest"
                  {...register("filename")}
                >
                  Choose File
                </label>
                <span className="mx-2">
                  {optionImage[index]?.name || item.attachment_file_name}
                </span>

                <input
                  id={`option.${index}.image`}
                  data-testid={`option-${index}-image`}
                  name={`option.${index}.image`}
                  className="hidden w-[221px]"
                  type="file"
                  onInput={(e) => {
                    const newArray = Array.from(optionImage);
                    // eslint-disable-next-line prefer-destructuring
                    newArray[index] = e.target.files[0];
                    setOptionImage(newArray);
                  }}
                  accept=".png, .jpg, .jpeg"
                  {...register(`option.${index}.image`)}
                />
              </div>
            </div>
          );
        })}

        <div className="mt-4 flex w-full">
          <button
            title="Add Option"
            data-testid="add-option-btn"
            className="w-auto py-2 font-medium text-primary-main shadow-sm hover:underline sm:text-sm"
            type="button"
            onClick={() =>
              append({ text: "", image: undefined, correct_answer: false })
            }
          >
            Add Option +
          </button>
        </div>

        <div className="mt-6 flex w-full flex-row">
          <Button
            title={getSubmitButtonText()}
            type="primary"
            size="md"
            testId="save-edit-btn"
            buttonType="submit"
            isLoading={isSubmitting}
          >
            {getSubmitButtonText()}
          </Button>

          <Button
            title="Cancel"
            testId="cancel-btn"
            type="secondary"
            size="md"
            btnClassName="ml-2"
            onClick={handleNavigateCancel}
            buttonType="button"
          >
            Cancel
          </Button>
        </div>
      </form>
      <Tooltip id="optionInfo" variant="dark" place="right" />
    </div>
  );
};

Form.propTypes = {
  questionPaper: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    question_paper_type: string,
    sectional: PropTypes.bool,
  }),
  selectedQuestion: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    marks: PropTypes.string,
    duration: PropTypes.number,
    category_id: PropTypes.number,
    category_name: PropTypes.string,
    section_id: PropTypes.number,
    question_id: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        attachment: PropTypes.string,
        attachment_file_name: PropTypes.string,
        id: PropTypes.number,
        question_id: PropTypes.number,
        type: PropTypes.string,
      })
    ),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        marks: PropTypes.string,
        duration: PropTypes.number,
        category_id: PropTypes.number,
        category_name: PropTypes.string,
        section_id: PropTypes.number,
        question_id: PropTypes.number,
        attachment_file_name: PropTypes.string,
        attachment: PropTypes.string,
        correct_answer: PropTypes.bool,
      })
    ),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        tag_name: PropTypes.string,
        tag_id: PropTypes.number,
      })
    ),
  }),
};

export default Form;
