import PropTypes from "prop-types";

import ExamRow from "../Dashboard/ExamRow";

import InterviewGDRow from "./InterviewGDRow";

const EventList = ({ events, currentTab, currentUser }) => {
  return (
    <div className="w-full ">
      {events?.map((event) => (
        <div
          key={event.uuid}
          className="mx-2 border-b-2 bg-white px-4  py-1 hover:bg-secondary-hover"
        >
          {event.type === "exam" && (
            <ExamRow
              exam={event}
              status={currentTab}
              currentUser={currentUser}
              showType
            />
          )}
          {(event.type === "interview" ||
            event.type === "group_discussion") && (
            <InterviewGDRow event={event} status={currentTab} />
          )}
        </div>
      ))}
    </div>
  );
};

EventList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      uuid: PropTypes.string,
      organisation_name: PropTypes.string,
      duration: PropTypes.number,
      job_title: PropTypes.string,
      ctc: PropTypes.string,
      start_at: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  meta: PropTypes.shape({
    total_entries: PropTypes.number,
    total_pages: PropTypes.number,
    page_number: PropTypes.number,
  }),
  // currentPage: PropTypes.number,
  // pageSize: PropTypes.number,
  // setCurrentPage: PropTypes.func,
  currentTab: PropTypes.string,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    portfolio_url: PropTypes.string,
    about: PropTypes.string,
    date_of_birth: PropTypes.string,
    is_profile_completed: PropTypes.bool,
    photo_url: PropTypes.string,
    reference_images: PropTypes.array,
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
  }),
};

export default EventList;
