import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BiWalletAlt } from "react-icons/bi";
import { BsXLg } from "react-icons/bs";
import { CgOrganisation } from "react-icons/cg";
import { MdLocationOn, MdOutlineHomeWork } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { camelCase } from "lodash";
import PropTypes from "prop-types";

import { loiConfirmation, loiDescription } from "api/candidate/jobApi";
import { ALERT_TYPE, ICON_SIZE_HASH } from "app/constants";
import { Avatar, Button, Card, DetailBox } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import CopyLink from "components/common/CopyLink";
import { Modal } from "components/molecules";
import { SITE_TITLE } from "constants/common";
import { JOB_STATUS } from "constants/jobs";
import { getCurrentSubdomain, getDomainName } from "helpers/utils";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { job } from "types/types";

import JobStatus from "./JobStatus";

const HeaderComponent = ({
  selectedJob,
  currentTab,
  setSelectedJob,
  fromJobDescription,
}) => {
  const isActive = camelCase(selectedJob.status) === JOB_STATUS.active.text;
  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const showAlert = useAlert();

  const [loiDetails, setLoiDetails] = useState(false);
  const [getJobStatus, setGetJobStatus] = useState(false);
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);
  const [showLOIDocumentsModal, setShowLOIDocumentsModal] = useState(false);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] =
    useState(false);

  const reviewApplication = () => {
    if (currentUser !== undefined) {
      navigate(`/jobs/${selectedJob.uuid}/apply`);
    } else {
      navigate(`/login?redirect_url=${encodeURIComponent(location.pathname)}`);
    }
  };

  useEffect(() => {
    if (job) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: { currentPageName: "Jobs" },
        })
      );
    }

    window.scrollTo({ top: 0, behavior: "instant" });
  }, [dispatch]);

  const getLoiDetails = () => {
    loiDescription(params.uuid)
      .then(({ data }) => {
        setLoiDetails(data);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          showAlert("danger", response.data.errors.detail[0]);
        } else {
          showAlert(
            ALERT_TYPE[response.meta.message_type],
            response.meta.message
          );
        }
      });
  };

  const handleLoi = (status) => {
    return loiConfirmation(params.uuid, { status }).then((response) => {
      setShowAcceptConfirmationModal(false);
      setShowRejectConfirmationModal(false);
      setGetJobStatus((prev) => !prev);
      getLoiDetails();
      showAlert(ALERT_TYPE[response.meta.message_type], response.meta.message);
    });
  };

  const companyNameRedirectionLink = () => {
    if (currentUser) {
      return `/organisations/${selectedJob.organisation.id}`;
    }
    if (selectedJob.organisation.website) {
      return selectedJob.organisation.website;
    }
    return "#";
  };

  useEffect(() => {
    if (
      selectedJob.loi_status &&
      selectedJob.loi_status !== "LOI_PENDING" &&
      params.uuid
    ) {
      getLoiDetails();
    }
  }, []);

  return (
    <div className="sticky">
      <Helmet>
        <title>{`${selectedJob.title} - ${SITE_TITLE}`}</title>
        <meta
          name="description"
          content={`Apply to job ${selectedJob.title} by ${selectedJob.organisation} on JobAlley platform.`}
        />
        <meta property="og:image" content="/joballey_logo_dark_text.png" />

        <meta
          property="og:url"
          content={`https://app.${getDomainName()}/jobs/${
            selectedJob.uuid
          }/details`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${selectedJob.title} - ${SITE_TITLE}`}
        />
        <meta
          property="og:description"
          content={`Apply to job ${selectedJob.title} by ${selectedJob.organisation} on JobAlley platform.`}
        />
        <meta property="og:image" content="/joballey_logo_dark_text.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:url"
          content={`https://app.${getDomainName()}/jobs/${
            selectedJob.uuid
          }/details`}
        />
        <meta property="twitter:domain" content={`app.${getDomainName()}`} />
        <meta
          name="twitter:title"
          content={`${selectedJob.title} - ${SITE_TITLE}`}
        />
        <meta
          name="twitter:description"
          content={`Apply to job ${selectedJob.title} by ${selectedJob.organisation} on JobAlley platform.`}
        />
        <meta name="twitter:image" content="/joballey_logo_dark_text.png" />
      </Helmet>

      {!fromJobDescription && (
        <div className="float-right p-2" data-testid="job-details-close-btn">
          <BsXLg
            className="cursor-pointer"
            onClick={() => setSelectedJob(null)}
            title="Close"
          />
        </div>
      )}
      <div
        className={classNames(!fromJobDescription ? "px-3 pt-10" : "px-4 pt-2")}
      >
        <div className="flex items-start mb-3">
          {fromJobDescription &&
            (selectedJob.organisation.logo?.thumb ? (
              <Avatar
                height={16}
                width={16}
                src={selectedJob.organisation.logo?.thumb}
                alt={selectedJob.organisation}
                shape="rounded-md"
                border="border-0"
                optionalClass="mr-6 aspect-square	"
              />
            ) : (
              <CharacterAvatar
                char={selectedJob.organisation.name.charAt(0)}
                width={14}
                height={14}
                bgColor="bg-primary-main"
                textColor="text-white"
                shape="rounded-md"
                textSize="text-[2rem]"
                optionalClass="py-2 px-6 mr-6"
              />
            ))}
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold" id="job-title">
              {selectedJob.title}
            </h3>

            <div
              className="text-base font-medium text-gray-dark"
              id="job-organisation"
            >
              <a
                href={companyNameRedirectionLink()}
                target={
                  currentUser || selectedJob.organisation.website
                    ? "_blank"
                    : ""
                }
                rel="noreferrer"
              >
                <CgOrganisation
                  className="inline mr-2 mb-1"
                  size={ICON_SIZE_HASH.sm}
                  data-tooltip-content="Company Name"
                  data-tooltip-id={`company-name${job.uuid}`}
                />
                <span className="hover:text-primary-dark hover:underline">
                  {selectedJob.organisation.name}
                </span>
              </a>
            </div>
            <div className="flex flex-wrap">
              {selectedJob?.location && (
                <div
                  className="flex mr-4"
                  id="job-location"
                  data-testid="location-icon"
                >
                  <span className="text-sm italic text-gray-main">
                    <MdLocationOn
                      className="inline mr-2 mb-1 cursor-pointer"
                      size={ICON_SIZE_HASH.sm}
                      data-tooltip-content="Job Location"
                      data-tooltip-id={`jobLocation-${selectedJob.uuid}`}
                    />
                  </span>
                  <span className="text-sm italic text-gray-main">
                    {selectedJob.location}
                  </span>
                </div>
              )}

              {selectedJob?.location_type && (
                <div className="flex mr-4" data-testid="location-type-icon">
                  <span className="text-sm italic text-gray-main">
                    <MdOutlineHomeWork
                      className="inline mr-2 mb-1 cursor-pointer"
                      size={ICON_SIZE_HASH.sm}
                      data-tooltip-content="Location Type"
                      data-tooltip-id={`locationType-${selectedJob.uuid}`}
                    />
                  </span>
                  <span className="text-sm italic text-gray-main">
                    {selectedJob?.location_type}
                  </span>
                </div>
              )}

              {selectedJob.ctc !== "0" && selectedJob.ctc !== null && (
                <div className="flex mr-4" data-testid="ctc-icon">
                  <span className="text-sm italic text-gray-main">
                    <BiWalletAlt
                      className="inline mb-1 mr-2 cursor-pointer"
                      size={ICON_SIZE_HASH.sm}
                      data-tooltip-content="CTC"
                      data-tooltip-id={`ctc-${selectedJob.uuid}`}
                    />
                  </span>
                  <span className="text-sm italic text-gray-main">
                    {selectedJob?.ctc}
                  </span>
                </div>
              )}
            </div>
          </div>
          <Tooltip
            id={`company-name${job.uuid}`}
            variant="dark"
            className="text-xs"
          />
          <Tooltip
            id={`jobLocation-${selectedJob.uuid}`}
            variant="dark"
            className="text-xs"
          />
          <Tooltip
            id={`locationType-${selectedJob.uuid}`}
            variant="dark"
            className="text-xs"
          />
          <Tooltip
            id={`ctc-${selectedJob.uuid}`}
            variant="dark"
            className="text-xs"
          />
        </div>

        <div
          className={classNames(
            "flex flex-row-reverse justify-between items-center",
            { "ml-20": fromJobDescription }
          )}
        >
          <div className="mr-2">
            {selectedJob.status == "active" && (
              <CopyLink
                link={`https://${getCurrentSubdomain()}.${getDomainName()}/jobs/${
                  selectedJob.uuid
                }/details`}
                tooltipId="copyLink"
                tooltipContent="Copy Shareable Link"
              />
            )}
          </div>
          {currentTab !== "applied" && (
            <div
              className={classNames("my-2 flex justify-between", {
                "ml-2": fromJobDescription,
              })}
            >
              {selectedJob.applied ? (
                <div className="flex flex-col gap-2">
                  <div className="flex flex-column flex-wrap gap-5">
                    <Button
                      title="Applied"
                      btnName="Applied"
                      type="disabled"
                      size="md"
                    />
                    <JobStatus
                      jobUuid={selectedJob.uuid}
                      title={selectedJob.title}
                      testId="job-status-timeline"
                      getJobStatus={getJobStatus}
                    />
                  </div>
                  {loiDetails && (
                    <div className="flex items-start mt-6">
                      <p className="font-medium">LOI Received</p>
                      <Button
                        title="View"
                        type="tertiary"
                        btnName="View"
                        size="md"
                        onClick={() => {
                          setShowLOIDocumentsModal(true);
                        }}
                      />
                      {showLOIDocumentsModal && (
                        <Modal
                          title="LOI Documents"
                          scrollClass="overflow-y-scroll max-h-[24rem]"
                          handleCancel={() => setShowLOIDocumentsModal(false)}
                          onOutsideClickHandler={() =>
                            setShowLOIDocumentsModal(false)
                          }
                        >
                          <div className="flex flex-col gap-1">
                            {loiDetails?.attachments.map((attachment) => (
                              <div key={attachment.id} className=" w-full">
                                <a
                                  title="View Pdf"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={attachment.attachment}
                                  className="flex p-2 text-left text-sm text-primary-main hover:bg-primary-lightest"
                                >
                                  {attachment.name ||
                                    attachment.attachment_file_name}
                                </a>
                              </div>
                            ))}
                          </div>
                        </Modal>
                      )}
                    </div>
                  )}
                  {loiDetails.status === "LOI_SENT" && (
                    <div className="flex gap-4 py-2">
                      <Button
                        title="Reject"
                        type="danger"
                        btnName="Reject"
                        size="sm"
                        onClick={() => {
                          setShowRejectConfirmationModal(true);
                        }}
                      />
                      <Button
                        title="Accept"
                        type="primary"
                        btnName="Accept"
                        size="sm"
                        onClick={() => {
                          setShowAcceptConfirmationModal(true);
                        }}
                      />
                    </div>
                  )}

                  <div className="italic text-sm">
                    {loiDetails.status === "ACCEPTED" && (
                      <p>You have accepted the Letter of Intent.</p>
                    )}
                    {loiDetails.status === "WITHDREW" && (
                      <p> You have rejected the Letter of Intent.</p>
                    )}
                  </div>
                </div>
              ) : (
                <Button
                  title={isActive ? "Apply" : "Closed"}
                  btnName={isActive ? "Apply" : "Closed"}
                  type={isActive ? "primary" : "disabled"}
                  size="md"
                  onClick={reviewApplication}
                  testId="job-apply-btn"
                />
              )}
            </div>
          )}
          {currentTab === "applied" && (
            <JobStatus
              jobUuid={selectedJob.uuid}
              title={selectedJob.title}
              testId="job-status-timeline"
              getJobStatus={getJobStatus}
            />
          )}
        </div>
      </div>
      <hr />

      {showAcceptConfirmationModal && (
        <Modal
          title="Are you sure?"
          description="This will be an irreversible action."
          acceptBtnType="primary"
          acceptButtonText="Accept"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          handleAccept={() => handleLoi("ACCEPTED")}
          handleReject={() => setShowAcceptConfirmationModal(false)}
        />
      )}
      {showRejectConfirmationModal && (
        <Modal
          title="Are you sure?"
          description="This will be an irreversible action."
          acceptBtnType="primary"
          acceptButtonText="Reject"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          handleAccept={() => handleLoi("WITHDREW")}
          handleReject={() => setShowRejectConfirmationModal(false)}
        />
      )}
    </div>
  );
};

const JobDetails = ({
  selectedJob,
  currentTab,
  setShowConfirmModal,
  setSelectedJob,
  fromJobDescription,
}) => {
  if (selectedJob) {
    return (
      <div className={classNames(fromJobDescription && "p-2 px-2")}>
        <Card
          header={
            <HeaderComponent
              selectedJob={selectedJob}
              currentTab={currentTab}
              setShowConfirmModal={setShowConfirmModal}
              setSelectedJob={setSelectedJob}
              fromJobDescription={fromJobDescription}
            />
          }
        >
          <div className="custom-scrollbar max-h-[55vh] overflow-y-auto px-3 pb-3 text-left">
            <DetailBox
              title="Description"
              description={selectedJob.description}
              testId="job-description"
            />
            <DetailBox
              title="Requirements"
              description={selectedJob.requirements}
              testId="job-requirements"
            />
            <DetailBox
              title="Employment Type"
              description={selectedJob?.employment_type}
            />
            <DetailBox
              title="No Of Positions"
              description={
                String(selectedJob.no_of_positions) !== "0" &&
                selectedJob.no_of_positions !== null
                  ? String(selectedJob.no_of_positions)
                  : ""
              }
              testId="job-vacancy"
            />
          </div>
        </Card>
      </div>
    );
  }
  return (
    <Card
      header={
        <div className="ml-2 py-7 text-center text-base font-medium italic text-gray-400">
          Click on a job to view details
        </div>
      }
    />
  );
};

HeaderComponent.propTypes = {
  selectedJob: job,
  currentTab: PropTypes.oneOf(["suggested", "applied", "saved"]),
  setSelectedJob: PropTypes.func,
  fromJobDescription: PropTypes.bool,
};

JobDetails.propTypes = {
  selectedJob: job,
  currentTab: PropTypes.oneOf(["suggested", "applied", "saved"]),
  setShowConfirmModal: PropTypes.func,
  setSelectedJob: PropTypes.func,
  fromJobDescription: PropTypes.bool,
};

export default JobDetails;
