const showFreshWorkWidget = () => {
  const fresWorkScript = document.createElement("script");
  fresWorkScript.innerHTML = `	window.fwSettings={
              'widget_id':89000000643
              };
              !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
  document.body.appendChild(fresWorkScript);
  const freshWorkLinkScript = document.createElement("script");
  freshWorkLinkScript.src =
    "https://ind-widget.freshworks.com/widgets/89000000643.js";
  freshWorkLinkScript.async = true;
  freshWorkLinkScript.defer = true;
  document.body.appendChild(freshWorkLinkScript);
};

export default showFreshWorkWidget;
