import { createSlice } from "@reduxjs/toolkit";

import {
  createOrganisationAction,
  getOrganisationAction,
} from "store/thunkActions/erp/organisationThunk";

export const getOrganisation = createSlice({
  name: "organisation",
  initialState: {
    organisations: [],
  },
  reducers: {},
  extraReducers: {
    [getOrganisationAction.fulfilled]: (state, action) => {
      state.organisations = action.payload;
    },
    [createOrganisationAction.fulfilled]: (state, action) => {
      state.organisations.push(action.payload);
    },
  },
});

export default getOrganisation.reducer;
