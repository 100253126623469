import PropTypes from "prop-types";

import GeneralErrorImage from "assets/images/DashboardImages/Error.png";
import { Button } from "components/atoms";

import Navbar from "./Navbar";

const GenericErrorPage = ({
  homePageClickHandler,
  imgWidth,
  message,
  pageErrorImage,
  details,
  isNavHidden,
  resetErrorBoundary,
}) => {
  return (
    <div
      className={`${
        isNavHidden
          ? "h-[calc(100vh-150px)] flex items-center justify-center"
          : "h-screen w-screen"
      } bg-gray-lighterShade3 overflow-hidden `}
    >
      {!isNavHidden && (
        <Navbar isErrorPage resetErrorBoundary={resetErrorBoundary} />
      )}
      <div className="h-3/4 w-full flex items-center justify-center">
        <div className="h-max flex-col items-center">
          <img
            src={pageErrorImage}
            alt="Error Thumb"
            className={`m-auto ${imgWidth}`}
          />
          <div className="text-center w-full px-4 lg:px-60 pt-4">
            <p className="text-xl font-semibold">{message}</p>

            <p className="text-base font-normal">{details}</p>
          </div>
          <div className="flex items-center justify-center w-full lg:pt-5 pt-8">
            <Button
              title="Go to Home Page"
              btnClassName="mx-2 text-sm"
              type="primary"
              btnName="Go to Home Page"
              textTransform="normalcase"
              onClick={homePageClickHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GenericErrorPage.defaultProps = {
  homePageClickHandler: () => null,
  message: "Something went wrong!",
  pageErrorImage: GeneralErrorImage,
  details:
    "We have been notified about this issue and we will take a look at it shortly!",
  imgWidth: "lg:w-2/4 w-4/5",
  isNavHidden: false,
  resetErrorBoundary: () => null,
};

GenericErrorPage.propTypes = {
  homePageClickHandler: PropTypes.func,
  message: PropTypes.string,
  pageErrorImage: PropTypes.string,
  details: PropTypes.string,
  imgWidth: PropTypes.string,
  isNavHidden: PropTypes.bool,
  resetErrorBoundary: PropTypes.func,
};

export default GenericErrorPage;
