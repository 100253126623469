import { format } from "date-fns";

export const formatSlots = (slots) => {
  const slotDates = slots?.reduce((acc, val) => {
    const v = {};
    v[format(new Date(val.start_at), "dd-MM-yyyy")] = {};
    return { ...v, ...acc };
  }, {});

  slots?.forEach((val) => {
    Object.keys(slotDates).forEach((slotDate) => {
      if (format(new Date(val.start_at), "dd-MM-yyyy") === slotDate) {
        slotDates[slotDate][val.start_at] = {
          count: val.candidate_count,
          id: val.id,
        };
      }
    });
  });

  return slotDates;
};
