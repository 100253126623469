import { createAsyncThunk } from "@reduxjs/toolkit";

import { jobApply, saveJob, unsaveJob } from "api/candidate/jobApi";

export const handleApplyJobAction = createAsyncThunk(
  "candidate/job/apply",
  async (id, { rejectWithValue }) => {
    try {
      const response = await jobApply(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleSaveJobAction = createAsyncThunk(
  "candidate/job/apply",
  async (id, { rejectWithValue }) => {
    try {
      const response = await saveJob(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleUnsaveJobAction = createAsyncThunk(
  "candidate/job/apply",
  async (id, { rejectWithValue }) => {
    try {
      const response = await unsaveJob(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
