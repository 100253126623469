import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import axios from "axios";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { getFaceEmbeddings } from "api/candidate/candidateApi";
import { getPresignedURL } from "api/candidate/examApi";
import { detectCandidateExam } from "api/candidate/faceRecognitionApi";
import { Button } from "components/atoms";
import { EXAM_VIOLATION_TYPES } from "constants/common";
import {
  FACE_MATCH_THRESHOLD_DISTANCE,
  FACE_MISMATCH_VIOLATION_MESSAGES,
} from "constants/faceDetection";
import getPreviewScreenshot from "helpers/CameraFaceDetection/getPreviewScreenshot";
import getUserMediaDevice from "helpers/navigator";
import navigatorPermission from "helpers/navigatorPermission";

import ExamCandidateDetails from "./ExamCandidateDetails";
import ExamCandidateMetaData from "./ExamCandidateMetaData";

const Sidebar = ({
  examName,
  duration,
  onFinish,
  candidateJobId,
  sectionName,
  candidateName,
  setIsFinishModalVisible,
  isDropDownOpen,
  setIsDropDownOpen,
  videoRef,
  cameraOption,
  intervalIdRef,
  examCandidateId,
  examViolation,
  setAlertMessage,
  setIsAlertTriggered,
}) => {
  const { faceapi } = window;
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [faceEmbeddings, setFaceEmbeddings] = useState(null);
  const [time, setTime] = useState(duration);
  const canvasRef = useRef(null);
  const [lambdaCalled, setLambdaCalled] = useState(false);

  const showExamViolationMessage = (message) => {
    setAlertMessage(`ALERT!! ${message}`);
    setIsAlertTriggered(true);
  };

  const handleCapture = async () => {
    let metaDataMsg = "";

    const { base64Image, previewScreenshot: screenshotImage } =
      await getPreviewScreenshot(videoRef, canvasRef);

    const candidateDetectionResponse = await detectCandidateExam(
      base64Image,
      faceEmbeddings.feString
    );

    if (!candidateDetectionResponse) {
      metaDataMsg = "Detection failed due to unknown reasons.";
    } else if (candidateDetectionResponse.unauthorized_objects?.length > 0) {
      examViolation({
        violationType: EXAM_VIOLATION_TYPES.UNAUTHORIZED_OBJECT,
      });
      metaDataMsg = FACE_MISMATCH_VIOLATION_MESSAGES.unauthorizedObjectUse;
      showExamViolationMessage("Unauthorized device detected.");
    } else if (candidateDetectionResponse.person_count === 0) {
      examViolation({ violationType: EXAM_VIOLATION_TYPES.FACE_MISMATCH });
      metaDataMsg = FACE_MISMATCH_VIOLATION_MESSAGES.noPersonFaceDetected;
      showExamViolationMessage("No student.");
    } else if (candidateDetectionResponse.person_count > 1) {
      examViolation({ violationType: EXAM_VIOLATION_TYPES.FACE_MISMATCH });
      metaDataMsg = FACE_MISMATCH_VIOLATION_MESSAGES.multiplePersonDetected;
      showExamViolationMessage("More than one student.");
    } else if (
      candidateDetectionResponse.face_direction.substring(0, 6) === "tilted"
    ) {
      examViolation({ violationType: EXAM_VIOLATION_TYPES.FACE_MISMATCH });
      metaDataMsg = FACE_MISMATCH_VIOLATION_MESSAGES.notLookingStraight;

      if (candidateDetectionResponse.face_direction === "tilted_left") {
        showExamViolationMessage("Looking left.");
      } else if (candidateDetectionResponse.face_direction === "tilted_right") {
        showExamViolationMessage("Looking right.");
      } else {
        showExamViolationMessage("Not looking straight.");
      }
    } else {
      const { euclidean_distance: euclideanDistance } =
        candidateDetectionResponse;

      if (euclideanDistance > FACE_MATCH_THRESHOLD_DISTANCE) {
        examViolation({ violationType: EXAM_VIOLATION_TYPES.FACE_MISMATCH });
        metaDataMsg = FACE_MISMATCH_VIOLATION_MESSAGES.detectedFaceNotMatching;
        showExamViolationMessage("Face mismatch.");
      } else {
        metaDataMsg = FACE_MISMATCH_VIOLATION_MESSAGES.noViolation;
      }
    }

    const fileName = `webcam-screenshot_${format(
      new Date(),
      "yyyyMMdd_hh:mm:ss"
    )}.png`;
    const file = new File([screenshotImage], fileName, { type: "image/png" });

    getPresignedURL(examCandidateId).then((response) => {
      const presignedURL = response.data.url;
      const { fields } = response.data;
      const formData = new FormData();

      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      formData.append("X-Amz-Meta-Description", metaDataMsg);
      formData.append("Content-Type", "image/png");
      formData.append("file", file);
      axios.post(presignedURL, formData);

      setLambdaCalled(false);
    });
  };

  const startCapture = () => {
    if (intervalIdRef !== undefined && time !== 0) {
      // It generate the dynamic delays between 1-5 and convert ms into minutes
      const randDelayGenerator = () =>
        Math.floor(Math.random() * 5 + 1) * 60000;

      intervalIdRef.current = setTimeout(() => {
        setLambdaCalled(true);
        startCapture();
      }, randDelayGenerator());
    }
  };

  const detectFacebyFaceAPI = async () => {
    const detection = await faceapi
      .detectAllFaces(
        videoRef.current,
        new faceapi.SsdMobilenetv1Options({ minConfidence: 0.8 })
      )
      .withFaceLandmarks()
      .withFaceDescriptors();

    const violationSuspected =
      detection.length !== 1 || // no. of faces detected is 0 or greater than 1
      faceapi.utils.round(
        faceapi.euclideanDistance(
          faceEmbeddings.feArray,
          detection[0].descriptor
        )
      ) > FACE_MATCH_THRESHOLD_DISTANCE;
    // face mismatch as detected by face-api (comparing euclidean distance between descriptors)

    if (violationSuspected && !lambdaCalled && faceEmbeddings) {
      clearInterval(intervalIdRef.current);
      await handleCapture();
      startCapture();
    }

    setTimeout(() => detectFacebyFaceAPI(), 1000 * 5);
  };

  const stopCapture = () => {
    clearTimeout(intervalIdRef.current);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => time > 0 && time - 1);
    }, 1000);

    if (cameraOption === "REQUIRED") {
      Promise.all([
        faceapi.nets.ssdMobilenetv1.loadFromUri("/models/ssd_mobilenetv1"),
        faceapi.nets.faceLandmark68Net.loadFromUri("/models/face_landmark_68"),
        faceapi.nets.faceRecognitionNet.loadFromUri("/models/face_recognition"),
      ]).then(async () => {
        getFaceEmbeddings().then(({ data }) => {
          setFaceEmbeddings({
            feString: data.face_embedding,
            feArray: data.face_embedding.split(",").map((v) => parseFloat(v)),
          });
        }); // TODO: handle errors
      });
    }

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const bodyStyle = document.body.style;
    if (isDropDownOpen) {
      bodyStyle.overflow = "hidden";
    } else {
      bodyStyle.overflow = "auto";
    }
  }, [isDropDownOpen]);

  useLayoutEffect(() => {
    if (cameraOption === "REQUIRED") {
      getUserMediaDevice()
        .then((stream) => {
          videoRef.current.srcObject = stream;
          setIsCameraOn(true);
        })
        .catch(() => setIsCameraOn(false));
      return () =>
        videoRef.current?.srcObject?.getTracks().map((track) => {
          track.enabled = false; // TODO: we have to return anything from map, so whoever works in this file please fix this issue
          track.stop();
        });
    }
  }, [isCameraOn]);

  useEffect(() => {
    if (navigator.userAgent.match(/firefox|fxios/i)) {
      setIsCameraOn(true);
    } else {
      navigatorPermission(setIsCameraOn);
    }
  }, [isCameraOn]);

  useEffect(() => {
    if (faceEmbeddings) {
      handleCapture().then(() => {
        startCapture();
        if (videoRef) {
          detectFacebyFaceAPI();
        }
      });
    }
  }, [faceEmbeddings]);

  useEffect(() => {
    if (lambdaCalled === true) {
      handleCapture();
    }
  }, [lambdaCalled]);

  useEffect(() => {
    if (time === 0) {
      stopCapture();
      onFinish();
    }
  }, [time]);

  return (
    <div className="md:question-body flex h-auto select-none flex-col justify-between md:block md:w-1/4 md:border-l-2">
      <div className="sidebar-height hidden w-full justify-between md:sticky md:top-24 md:flex md:flex-col">
        <ExamCandidateMetaData
          candidateJobId={candidateJobId}
          candidateName={candidateName}
          sectionName={sectionName}
          duration={duration}
        />

        <div className="sticky bottom-3 right-0 mr-4 flex flex-col gap-3 self-end">
          <canvas ref={canvasRef} style={{ display: "none" }} />
          {cameraOption === "REQUIRED" && (
            <div
              className="w-full self-end pb-2 pl-4"
              data-testid="cameraPreviewWindow"
            >
              <video
                ref={videoRef}
                autoPlay
                muted
                onPause={() => setIsCameraOn(false)}
              />
            </div>
          )}
          <div className="sticky bottom-3 right-0 ml-auto self-end">
            <Button
              title="Finish Exam"
              size="md"
              customWidth={38}
              btnClassName="py-2 rounded-full text-sm px-6"
              onClick={() => setIsFinishModalVisible(true)}
              btnName="Finish Exam"
              type="warning"
            />
          </div>
        </div>
      </div>
      <div className="sticky top-24 right-2 mr-2 mb-0 hidden self-end pt-2">
        <button
          title="Menu"
          className=" backdrop-blur-sm"
          onClick={() => setIsDropDownOpen(!isDropDownOpen)}
        >
          <AiOutlineMenu className="mb-0 h-6 w-8" />
        </button>
      </div>
      {isDropDownOpen && (
        <ExamCandidateDetails
          examName={examName}
          candidateName={candidateName}
          sectionName={sectionName}
          setIsFinishModalVisible={setIsFinishModalVisible}
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={setIsDropDownOpen}
        />
      )}
    </div>
  );
};

Sidebar.propTypes = {
  examName: PropTypes.string,
  onFinish: PropTypes.func,
  duration: PropTypes.number,
  sectionName: PropTypes.string,
  candidateJobId: PropTypes.number,
  candidateName: PropTypes.string,
  setIsFinishModalVisible: PropTypes.func,
  isDropDownOpen: PropTypes.bool,
  setIsDropDownOpen: PropTypes.func,
  videoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  intervalIdRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    }),
  ]),
  cameraOption: PropTypes.string,
  examCandidateId: PropTypes.number,
  examViolation: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setIsAlertTriggered: PropTypes.func,
  // currentUserRefImageLink: PropTypes.string,
};

Sidebar.defaultProps = {
  examName: "Exam",
  duration: 0,
  examCandidateId: 0,
  onFinish: () => {},
  sectionName: "Section",
  candidateJobId: 0,
  candidateName: "Candidate",
  setIsFinishModalVisible: false,
  isDropDownOpen: false,
  setIsDropDownOpen: () => {},
  videoRef: () => {},
  intervalIdRef: () => {},
  cameraOption: "NOT_REQUIRED",
  examViolation: () => {},
  setAlertMessage: () => {},
  setIsAlertTriggered: () => {},
  currentUserRefImageLink: "",
};

export default Sidebar;
