import React from "react";
import { AiOutlineMail, AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiTransferAlt } from "react-icons/bi";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";

const HeaderActionItems = ({
  setIsAddingToStage,
  setIsUpdatingStatus,
  setIsEmailResult,
  currentLobby,
  currentStage,
  stageExamDetails,
  selectedIds,
  updateEvaluatorsHandle,
  data,
  isJobClosed,
  setShowCustomEmailModal,
  allSelected,
  totalCandidates,
  filterData,
  organisationData,
}) => {
  const addIfFilteredText = (headerMsg) => {
    return Object.keys(filterData).length > 0
      ? `${headerMsg} matching the applied filters`
      : headerMsg;
  };
  return (
    <th
      colSpan={12}
      className={classNames(
        currentLobby === "ALL_APPLICANTS"
          ? "w-32 px-2 text-primary-dark "
          : "w-36 2xl:w-32 4xl:w-24"
      )}
    >
      <div className="flex  justify-between whitespace-nowrap py-3 ">
        <div className="md:sticky md:left-[7rem]">
          <span className="text-base text-gray-dark font-normal ">
            {addIfFilteredText(
              allSelected
                ? `${totalCandidates} selected out of ${totalCandidates} on this stage`
                : `${selectedIds.length} selected out of ${data.length} on this page`
            )}
          </span>
        </div>
        {!isJobClosed && (
          <div className="flex font-semibold">
            {!allSelected && (
              <BsFillPersonPlusFill
                className="mr-8 text-2xl text-primary-main hover:cursor-pointer hover:text-primary-light"
                onClick={() => setIsAddingToStage(true)}
                data-tooltip-id="header-action-items"
                data-testid="addToStage"
                data-tooltip-content="Add to"
              />
            )}
            <BiTransferAlt
              className="mr-8 text-2xl text-primary-main hover:cursor-pointer hover:text-primary-light"
              onClick={() => setIsUpdatingStatus(true)}
              data-testid="changeStatus"
              data-tooltip-content="Change status"
              data-tooltip-id="header-action-items"
            />

            {currentStage &&
              !allSelected &&
              (stageExamDetails?.length !== 0 ||
                currentStage.stage_type === "interview") && (
                <AiOutlineMail
                  className="mr-8 text-2xl text-primary-main hover:cursor-pointer hover:text-primary-"
                  onClick={() => setIsEmailResult(true)}
                  data-testid="sendEmail"
                  data-tooltip-content="Email results"
                  data-tooltip-id="header-action-items"
                />
              )}
            {currentStage && organisationData.custom_email && (
              <MdAttachEmail
                className="mr-8 text-2xl mt-[1px] text-primary-main hover:cursor-pointer hover:text-primary-"
                onClick={() => setShowCustomEmailModal(true)}
                data-testid="sendEmail"
                data-tooltip-content="Custom Email"
                data-tooltip-id="header-action-items"
              />
            )}

            {stageExamDetails[0]?.question_paper_type === "coding" &&
              !allSelected && (
                <AiOutlineUsergroupAdd
                  data-testid="updateEvaluators"
                  className="mr-8 text-xl text-primary-main hover:cursor-pointer hover:text-primary-light"
                  data-tooltip-content="Update evaluators"
                  data-tooltip-id="header-action-items"
                  onClick={updateEvaluatorsHandle}
                />
              )}

            <Tooltip id="header-action-items" variant="dark" />
          </div>
        )}
      </div>
    </th>
  );
};

HeaderActionItems.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  currentLobby: PropTypes.string,
  stageExamDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question_paper_type: PropTypes.string,
    })
  ),
  setIsAddingToStage: PropTypes.func,
  setIsUpdatingStatus: PropTypes.func,
  setIsEmailResult: PropTypes.func,
  setShowCustomEmailModal: PropTypes.func,
  updateEvaluatorsHandle: PropTypes.func,
  isJobClosed: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool,
  totalCandidates: PropTypes.number,
  filterData: PropTypes.shape({}),
  organisationData: PropTypes.shape({
    custom_email: PropTypes.bool,
  }),
};

HeaderActionItems.defaultProps = {
  data: [],
  stageExamDetails: [],
  selectedIds: [],
  currentStage: null,
  currentLobby: null,
  setIsAddingToStage: () => {},
  setIsUpdatingStatus: () => {},
  setIsEmailResult: () => {},
  setShowCustomEmailModal: () => {},
  updateEvaluatorsHandle: () => {},
  allSelected: false,
  totalCandidates: 0,
  filterData: {},
  organisationData: {},
};

export default HeaderActionItems;
