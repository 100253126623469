import alert from "./slices/alertSlice";
import apiErrors from "./slices/apiErrorSlice";
import auth from "./slices/authSlice";
import candidateAnswers from "./slices/candidate/candidateAnswersSlice";
import contactDetails from "./slices/candidate/contactDetailsSlice";
import educationQualifications from "./slices/candidate/educationQualificationSlice";
import examCandidate from "./slices/candidate/examCandidateSlice";
import featureFlags from "./slices/candidate/featureFlagsSlice";
import candidateLanguages from "./slices/candidate/languageSlice";
import locationDetails from "./slices/candidate/locationDetailsSlice";
import phoneNumbers from "./slices/candidate/phoneNumberSlice";
import projects from "./slices/candidate/projectSlice";
import referenceDetails from "./slices/candidate/referenceDetailsSlice";
import candidateSkills from "./slices/candidate/skillSlice";
import workExperiences from "./slices/candidate/workExperienceSlice";
import candidateJobStage from "./slices/erp/candidateJobStageSlice";
import categories from "./slices/erp/categoriesSlice";
import employmentTypes from "./slices/erp/employmentTypesSlice";
import job from "./slices/erp/jobSlice";
import jobs from "./slices/erp/jobsSlice";
import locationTypes from "./slices/erp/locationTypesSlice";
import organisations from "./slices/erp/organisationSlice";
import questionBank from "./slices/erp/questionBankSlice";
import questionPaper from "./slices/erp/questionPaperSlice";
import questionPapers from "./slices/erp/questionPapersSlice";
import question from "./slices/erp/questionSlice";
import questionTag from "./slices/erp/questionTagSlice";
import sections from "./slices/erp/sectionsSlice";
import stages from "./slices/erp/stagesSlice";
import userRoles from "./slices/erp/userRolesSlice";
import users from "./slices/erp/usersSlice";
import miscellaneous from "./slices/miscellaneousSlice";
import popupAlert from "./slices/popupAlertSlice";

export default {
  auth,
  alert,
  popupAlert,
  examCandidate,
  featureFlags,
  question,
  candidateAnswers,
  candidateSkills,
  educationQualifications,
  workExperiences,
  referenceDetails,
  projects,
  employmentTypes,
  locationTypes,
  contactDetails,
  candidateLanguages,
  phoneNumbers,
  organisations,
  jobs,
  job,
  locationDetails,
  questionPapers,
  questionPaper,
  questionTag,
  questionBank,
  stages,
  candidateJobStage,
  sections,
  userRoles,
  users,
  miscellaneous,
  categories,
  apiErrors,
};
