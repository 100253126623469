import Proptypes from "prop-types";

const Hobbies = ({ interest }) => {
  return (
    <>
      <div className="resume-section-heading">INTERESTS</div>
      <div
        className="resume-section-content"
        style={{
          textAlign: "justify",
          color: "rgb(100 100 100)",
          wordBreak: "break-all",
        }}
      >
        {interest}
      </div>
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

export default Hobbies;

Hobbies.defaultProps = {
  interest: "",
};

Hobbies.propTypes = {
  interest: Proptypes.string,
};
