const blobImage = async (
  base64Image,
  handleUploadReferenceImage,
  currentUser
) => {
  const blob = await fetch(base64Image).then((res) => res.blob());
  const fileName = `Reference-Image_${currentUser.name}_${currentUser.id}`;
  const file = new File([blob], fileName, { type: "image/png" });
  handleUploadReferenceImage(base64Image, file);
};

export default blobImage;
