/*
This can be used to fire JS events or Custom events
*/

const useEvent = () => {
  const on = (eventType, listener) =>
    document.addEventListener(eventType, listener);
  const off = (eventType, listener) =>
    document.removeEventListener(eventType, listener);

  const trigger = (eventType, data) => {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
  };

  return { on, off, trigger };
};

export default useEvent;
