import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineMail } from "react-icons/ai";
import { BsDashCircle, BsPersonPlusFill, BsThreeDots } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import classNames from "classnames";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { emailResult, updateStageCandidateStatus } from "api/erp/jobApi";
import {
  ALERT_TYPE,
  EXAM_CANDIDATE_STATUS,
  INTERVIEW_GD_CANDIDATE_STATUS,
  STAGE_TYPE,
} from "app/constants";
import { Avatar, Button, ThreeDotMenu } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import { Modal } from "components/molecules";
import { getStatusSelectDisabled } from "helpers/pipeline/statusSelectDisabled";
import useAlert from "hooks/useAlert";
import { removeStageCandidateAction } from "store/thunkActions/erp/candidateJobStageThunk";

import SendCustomEmailModal from "../SendCustomEmailModal";

import ExamCandidates from "./ExamCandidates";
import GDCandidates from "./GDCandidates";
import InterviewCandidates from "./InterviewCandidates";

const FormattedTime = ({ dateArg }) =>
  dateArg && (
    <>
      <span className="mb-1 block">{format(new Date(dateArg), "d/M/yy")}</span>
      <span className="block">{format(new Date(dateArg), "h:mm aaa")}</span>
    </>
  );

const StageCandidates = ({
  candidate,
  currentStage,
  setIsEditState,
  setCandidateID,
  setExamCandidateId,
  setShowScheduleInterviewModal,
  setShowAddEvaluatorModal,
  setIsSingleOperation,
  setInterview,
  setExamEvaluators,
  setSelectedCandidates,
  getCandidateStageData,
  stageExamDetails,
  onCheck,
  isUserRoleAllowed,
  isJobClosed,
  showSidePanel,
  openSidePanel,
  closeSidePanel,
  candidatePanelId,
  organisationData,
  groups,
  setGroups,
  getGDDetails,
}) => {
  const dropdownMenuRef = useRef(null);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const params = useParams();
  const showAlert = useAlert();
  const { control } = useForm();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);
  const [isCandidateRemoving, setIsCandidateRemoving] = useState(false);
  const [isEmailResult, setIsEmailResult] = useState(false);
  const { selectedIds, selectedObjectType, allSelected } = useSelector(
    (state) => state.miscellaneous
  );

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  const submitHandler = (data) => {
    if (data === null) {
      return;
    }

    const finalData = {
      candidate_job_ids: [candidate.id.toString()],
      status: data.value,
    };

    updateStageCandidateStatus(params.uuid, currentStage.id, finalData)
      .then(({ meta }) => {
        getCandidateStageData();
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
      })
      .catch((errors) => showAlert("danger", errors.response.data.message));
  };

  const onCandidateDelete = () => {
    if (candidate.interview || candidate.exam || candidate.group !== null) {
      showAlert(
        "danger",
        `You cannot remove a candidate who has an associated ${currentStage?.stage_type}.`
      );
      setIsCandidateRemoving(false);
    } else {
      return dispatch(
        removeStageCandidateAction({
          job_uuid: params.uuid,
          stage_id: currentStage.id,
          candidate_job_ids: [candidate.id.toString()],
        })
      )
        .unwrap()
        .then(({ response }) => {
          if (candidatePanelId === candidate.id) {
            closeSidePanel();
          }
          setIsCandidateRemoving(false);
          setShowDropdown(false);
          showAlert(
            ALERT_TYPE[response.meta.message_type],
            response.meta.message
          );
        })
        .catch(({ errors, message }) => {
          if (message) {
            showAlert("danger", message);
          } else {
            showAlert("danger", errors);
          }
        });
    }
  };

  const STAGE_CANDIDATE_STATUS =
    currentStage?.stage_type === "exam"
      ? EXAM_CANDIDATE_STATUS
      : INTERVIEW_GD_CANDIDATE_STATUS;

  const sendEmail = () => {
    const data = { candidate_job_ids: [candidate.id.toString()] };
    emailResult(params.uuid, currentStage.id, data)
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        setIsEmailResult(false);
      })
      .catch((error) => {
        showAlert(
          ALERT_TYPE[error.response.data.meta?.message_type],
          error.response.data.meta?.message
        );
        setIsEmailResult(false);
      });
  };

  const calculatePosFromTop = () => {
    if (!currentStage) {
      return "top-[-8rem]";
    }

    const isInterview = currentStage?.stage_type === "interview";
    const isExam = currentStage?.stage_type === "exam";
    const isExamDetailsEmpty = stageExamDetails?.length === 0;

    if (isInterview) {
      return "top-[-7rem]";
    }

    if (isExam && !isExamDetailsEmpty) {
      return "top-[-7rem]";
    }

    return "top-[-5rem]";
  };

  return (
    <tr
      className={classNames(
        "my-4 table-row border-y hover:bg-gray-lighter",
        allSelected ||
          (selectedObjectType === "candidate" &&
            selectedIds.includes(candidate.id.toString())) ||
          showSidePanel
          ? "bg-primary-lighter"
          : "bg-white"
      )}
      data-testid={`candidate-id-${candidate.id}`}
    >
      <td
        className={classNames(
          "table-data w-10 z-[11] p-4 align-top text-slate-500 md:sticky md:left-0",
          allSelected ||
            (selectedObjectType === "candidate" &&
              selectedIds.includes(candidate.id.toString())) ||
            showSidePanel
            ? "bg-primary-lighter"
            : "bg-white"
        )}
      >
        <div className="flex flex-col gap-10">
          {isUserRoleAllowed && (
            <input
              className="h-5 w-4 hover:cursor-pointer focus:outline-none"
              data-testid={`candidate-${candidate?.id}-checkbox`}
              type="checkbox"
              checked={
                allSelected ||
                (selectedObjectType === "candidate" &&
                  selectedIds.includes(candidate.id.toString()))
              }
              value={candidate?.id}
              onChange={(e) => onCheck(e.target)}
              id="flexCheckDefault"
            />
          )}
          {searchParams.get("lobby") !== "PLACED" &&
            isUserRoleAllowed &&
            !isJobClosed && (
              <div className="relative mr-2 justify-end" ref={dropdownMenuRef}>
                <BsThreeDots
                  title="View Options"
                  role="button"
                  onClick={() => setShowDropdown((dropdown) => !dropdown)}
                  data-testid="three-dot-dropdown"
                  className="text-2xl text-gray-600 hover:cursor-pointer hover:text-primary-light"
                />

                {showDropdown && (
                  <ThreeDotMenu
                    menuRef={dropdownMenuRef}
                    menuOptions={[
                      {
                        dataTestId: "add_to_stage",
                        onClick: () => {
                          setIsEditState(true);
                          setCandidateID(candidate.id);
                          setShowDropdown(false);
                        },
                        icon: (
                          <BsPersonPlusFill className="mr-2 mt-[1.5px] text-base" />
                        ),
                        text: "Add to",
                      },
                      {
                        condition:
                          (currentStage?.stage_type === "exam" &&
                            stageExamDetails?.length !== 0) ||
                          currentStage?.stage_type === "interview",
                        dataTestId: "resultEmail",
                        onClick: () => {
                          setIsEmailResult(true);
                          setShowDropdown(false);
                        },
                        icon: (
                          <AiOutlineMail className="mr-2 mt-[1.5px] text-base" />
                        ),
                        text: "Email Result",
                      },
                      {
                        condition: organisationData?.custom_email,
                        dataTestId: "custom_email",
                        onClick: () => {
                          setShowCustomEmailModal(true);
                          setCandidateID(candidate.candidate_id);
                          setShowDropdown(false);
                        },
                        icon: (
                          <MdAttachEmail className="mr-2 mt-[1.5px] text-base" />
                        ),
                        text: "Custom Email",
                      },
                      {
                        condition: !!currentStage?.id,
                        dataTestId: "remove_from_stage",
                        onClick: () => {
                          if (candidate.interview || candidate.exam !== null) {
                            onCandidateDelete();
                          } else {
                            setIsCandidateRemoving(true);
                          }
                          setShowDropdown(false);
                        },
                        icon: (
                          <BsDashCircle className="mr-2 mt-[1.5px] text-base" />
                        ),
                        text: "Remove from List",
                      },
                    ]}
                    posFromTop={calculatePosFromTop()}
                    posFromRight="text-black-primary"
                  />
                )}
              </div>
            )}
        </div>
      </td>

      <td
        className={classNames(
          "table-data w-10 z-10 py-4 pl-2 align-top text-base text-primary-main md:sticky md:left-16 whitespace-nowrap",
          allSelected ||
            (selectedObjectType === "candidate" &&
              selectedIds.includes(candidate.id.toString())) ||
            showSidePanel
            ? "bg-primary-lighter"
            : "bg-white"
        )}
      >
        <button
          title={candidate.id}
          id="candidate-id"
          className="break-all text-base text-primary-main hover:underline"
          onClick={() => openSidePanel(candidate?.id)}
        >
          {candidate.id}
        </button>
      </td>

      <td
        className={classNames(
          `table-data p-4 align-top text-slate-500 md:sticky w-2/12 z-10 ${
            currentStage?.stage_type === "exam"
              ? "md:left-[9.70rem]"
              : "md:left-[9rem]"
          }`,
          allSelected ||
            (selectedObjectType === "candidate" &&
              selectedIds.includes(candidate.id.toString())) ||
            showSidePanel
            ? "bg-primary-lighter"
            : "bg-white"
        )}
      >
        <div className="mb-2 flex items-center gap-3">
          <div className="mb-1 h-10 w-10 rounded-full ring-2 ring-primary-dark ring-offset-2">
            {candidate?.avatar?.thumb ? (
              <div className="h-10 w-10">
                <Avatar src={candidate.avatar?.thumb} height={10} width={10} />
              </div>
            ) : (
              <CharacterAvatar
                char={candidate.name.charAt(0)}
                width={10}
                height={10}
                textSize="text-[1.5rem]"
              />
            )}
          </div>
          <span data-testid="candidate_data" className="block pr-2 pb-1">
            <button
              title={candidate?.name}
              id="candidate-name"
              className="break-words text-start text-base text-primary-main hover:underline"
              onClick={() => openSidePanel(candidate?.id)}
            >
              {candidate?.name}
            </button>
          </span>
        </div>

        <p className="capitalize">
          {candidate?.work_experiences[0]?.title
            ? candidate?.work_experiences[0]?.title
            : ""}
          {candidate?.work_experiences.length ? " , " : ""}
          {candidate?.work_experiences.length > 0
            ? candidate?.work_experiences[0]?.company_name
            : ""}
          {candidate?.work_experiences.length > 1 && (
            <span className="ml-1 text-primary-dark">
              <Button
                title="Experience Details"
                type="tertiary"
                onClick={() =>
                  openSidePanel(candidate?.id, "workExperienceRef")
                }
                btnName={`+ ${candidate.work_experiences.length - 1}`}
              />
            </span>
          )}
        </p>

        {candidate?.educations.length > 0 && (
          <p className="capitalize text-gray-main">
            {candidate?.educations?.[0]?.qualification_name
              ? candidate?.educations[0]?.qualification_name
              : ""}
            {candidate?.educations.length > 0 ? " , " : ""}
            {candidate?.educations.length > 0
              ? candidate?.educations[0]?.college.name.toLowerCase()
              : ""}
            {candidate?.educations.length > 1 && (
              <span className="ml-1 text-primary-dark">
                <Button
                  title="Education Details"
                  type="tertiary"
                  onClick={() => openSidePanel(candidate?.id, "educationRef")}
                  btnName={`+ ${candidate.educations.length - 1}`}
                />
              </span>
            )}
          </p>
        )}

        <div className="mr-4 " data-testid="status-select">
          <Controller
            name="status"
            control={control}
            type="submit"
            render={({ field }) => (
              <Select
                name="status"
                options={STAGE_CANDIDATE_STATUS}
                id="status_select"
                className={classNames(
                  searchParams.get("lobby") === "PLACED" ? "w-56" : "w-48",
                  {
                    "2xl:w-60": searchParams.get("lobby") === "ALL_APPLICANTS",
                  },
                  { "lg:w-60": searchParams.get("lobby") === "PLACED" }
                )}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                isDisabled={
                  candidate.placed?.loi_sent ||
                  getStatusSelectDisabled(candidate, searchParams) ||
                  !isUserRoleAllowed ||
                  isJobClosed
                }
                defaultValue={STAGE_CANDIDATE_STATUS.find(
                  ({ value }) => value === candidate.status
                )}
                placeholder="Select Status"
                value={field.value}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    borderColor: STAGE_CANDIDATE_STATUS.find(
                      ({ value }) => value === candidate?.status
                    )?.color,
                    borderRadius: "15px",
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 20 }),
                  singleValue: (styles) => ({
                    ...styles,
                    color: STAGE_CANDIDATE_STATUS.find(
                      ({ value }) => value === candidate?.status
                    )?.color,
                  }),
                }}
                onChange={(data) => {
                  field.onChange(data);
                  submitHandler(data);
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            )}
          />
        </div>
        {candidate.placed &&
          (getStatusSelectDisabled(candidate, searchParams) ? (
            <div className="pt-3 text-sm text-primary-dark">
              Placed from &nbsp;
              <span className="font-semibold">
                {candidate.placed.stage_name}
              </span>
            </div>
          ) : (
            candidate.placed?.loi_sent && (
              <div className="text-primary-main font-medium pt-2">LOI Sent</div>
            )
          ))}

        <div className="pt-2 capitalize">
          {candidate?.candidate_skills?.length > 0
            ? candidate.candidate_skills
                .slice(0, 4)
                .map((candidateSkill) => candidateSkill.skill.name)
                .join(", ")
            : ""}
          {candidate?.candidate_skills?.length <= 4 &&
            candidate.candidate_skills.length > 0 && (
              <div className="">
                <Button
                  title="Skills"
                  type="tertiary"
                  onClick={() => openSidePanel(candidate?.id, "skillsRef")}
                  btnName="Show details"
                />
              </div>
            )}
          {candidate?.candidate_skills?.length > 4 && (
            <Button
              title="Skills"
              type="tertiary"
              onClick={() => openSidePanel(candidate?.id, "skillsRef")}
              btnName={` + ${candidate.candidate_skills.length - 4}`}
            />
          )}
        </div>
      </td>

      {currentStage.stage_type === STAGE_TYPE[0].value && (
        <ExamCandidates
          candidate={candidate}
          setCandidateID={setCandidateID}
          setExamCandidateId={setExamCandidateId}
          setShowAddEvaluatorModal={setShowAddEvaluatorModal}
          setIsSingleOperation={setIsSingleOperation}
          setExamEvaluators={setExamEvaluators}
          setSelectedCandidates={setSelectedCandidates}
          stageExamDetails={stageExamDetails}
          isUserRoleAllowed={isUserRoleAllowed}
          isJobClosed={isJobClosed}
        />
      )}

      {currentStage.stage_type === STAGE_TYPE[2].value && (
        <InterviewCandidates
          candidate={candidate}
          currentStage={currentStage}
          setCandidateID={setCandidateID}
          setShowScheduleInterviewModal={setShowScheduleInterviewModal}
          setInterview={setInterview}
          getCandidateStageData={getCandidateStageData}
          isUserRoleAllowed={isUserRoleAllowed}
          isJobClosed={isJobClosed}
        />
      )}

      {currentStage.stage_type === STAGE_TYPE[1].value && (
        <GDCandidates
          candidate={candidate}
          currentStage={currentStage}
          setCandidateID={setCandidateID}
          setShowScheduleInterviewModal={setShowScheduleInterviewModal}
          setInterview={setInterview}
          getCandidateStageData={getCandidateStageData}
          isUserRoleAllowed={isUserRoleAllowed}
          isJobClosed={isJobClosed}
          groups={groups}
          setGroups={setGroups}
          getGDDetails={getGDDetails}
        />
      )}

      {isCandidateRemoving && (
        <Modal
          title="Are you sure?"
          acceptButtonText="Yes"
          acceptBtnType="primary"
          rejectButtonText="No"
          rejectBtnType="secondary"
          handleAccept={onCandidateDelete}
          handleReject={() => setIsCandidateRemoving(false)}
        >
          Are you sure you want to remove this candidate from
          <span className="italic"> {currentStage?.name} </span>?
        </Modal>
      )}

      {isEmailResult && (
        <Modal
          title="Are You Sure?"
          description="Email result to candidate? This will be an irreversible action."
          acceptButtonText="Send"
          acceptBtnType="primary"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          handleAccept={sendEmail}
          handleReject={() => setIsEmailResult(false)}
        />
      )}

      {showCustomEmailModal && (
        <SendCustomEmailModal
          candidate={candidate}
          currentStage={currentStage}
          setShowModal={setShowCustomEmailModal}
          getCandidateStageData={getCandidateStageData}
        />
      )}
    </tr>
  );
};

FormattedTime.propTypes = {
  dateArg: PropTypes.string.isRequired,
};

StageCandidates.defaultProps = {
  showSidePanel: false,
  openSidePanel: null,
  closeSidePanel: null,
  candidatePanelId: null,
};

StageCandidates.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    candidate_id: PropTypes.number,
    job_uuid: PropTypes.string,
    name: PropTypes.string,
    exam_evaluators: PropTypes.arrayOf(
      PropTypes.shape({
        evaluator_id: PropTypes.number,
        evaluator_name: PropTypes.string,
      })
    ),
    educations: PropTypes.array,
    candidate_skills: PropTypes.array,
    work_experiences: PropTypes.array,
    status: PropTypes.string,
    exam: PropTypes.shape({
      slot_details: PropTypes.shape({
        slot_start_at: PropTypes.string,
        slot_end_at: PropTypes.string,
      }),
      started_at: PropTypes.string,
      ended_at: PropTypes.string,
      obtained_marks: PropTypes.string,
      id: PropTypes.number,
      sectional_marks: PropTypes.arrayOf(PropTypes.shape({})),
      violations: PropTypes.shape({}),
    }),
    interview: PropTypes.shape({
      candidate_id: PropTypes.number,
      link: PropTypes.string,
    }),
    placed: PropTypes.shape({
      stage_id: PropTypes.number,
      stage_name: PropTypes.string,
      loi_sent: PropTypes.bool,
    }),
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
  }),
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  setCandidateID: PropTypes.func,
  setExamCandidateId: PropTypes.func,
  setShowScheduleInterviewModal: PropTypes.func,
  setIsSingleOperation: PropTypes.func,
  setShowAddEvaluatorModal: PropTypes.func,
  setInterview: PropTypes.func,
  setExamEvaluators: PropTypes.func,
  setSelectedCandidates: PropTypes.func,
  getCandidateStageData: PropTypes.func,
  stageExamDetails: PropTypes.arrayOf(
    PropTypes.shape({
      question_paper: PropTypes.shape({
        sectional: PropTypes.bool,
      }),
      multi_slot: PropTypes.bool,
      question_paper_type: PropTypes.string,
      camera_option: PropTypes.string,
    })
  ),
  onCheck: PropTypes.func,
  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
  showSidePanel: PropTypes.bool,
  openSidePanel: PropTypes.func,
  closeSidePanel: PropTypes.func,
  candidatePanelId: PropTypes.number,
  organisationData: PropTypes.shape({
    custom_email: PropTypes.bool,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  setGroups: PropTypes.func,
};

export default StageCandidates;
