import { AiFillLinkedin } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { GoMarkGithub } from "react-icons/go";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { getApiUrl } from "app/apiPaths";
import MicrosoftLogo from "assets/images/logo/mssymbol.png";

const ProviderButtons = ({ isSignup, isOrganisation }) => {
  const location = useLocation();

  return (
    <div
      className={
        isOrganisation
          ? "m-auto w-full px-3 lg:w-1/2 lg:px-10"
          : "w-full px-3 lg:w-1/2 lg:px-10"
      }
    >
      {!isOrganisation ? (
        <div>
          <button
            title={`${isSignup ? "Sign up" : "Login"} with LinkedIn`}
            type="button"
            className="text-md my-6 flex w-full justify-center rounded-full border-2 border-blue-600 bg-blue-600 p-6 py-3 text-center text-white hover:border-blue-800 hover:bg-blue-800 focus:bg-blue-800"
            onClick={() => {
              window.location = `${getApiUrl()}/oauth/linkedin${
                location.search
              }`;
            }}
          >
            <AiFillLinkedin className="mr-4 text-2xl" />
            {isSignup ? "Sign up" : "Login"} with LinkedIn
          </button>

          <button
            title={`${isSignup ? "Sign up" : "Login"} with Github`}
            type="button"
            className="text-md border-black my-6 flex w-full justify-center rounded-full border-2 bg-black-primary py-3 pl-6 pr-8 text-white hover:bg-gray-700 focus:bg-gray-700"
            onClick={() => {
              window.location = `${getApiUrl()}/oauth/github${location.search}`;
            }}
          >
            <GoMarkGithub className="mr-4 text-2xl" />
            {isSignup ? "Sign up" : "Login"} with Github
          </button>

          <button
            title={`${isSignup ? "Sign up" : "Login"} with Google`}
            type="button"
            className="text-md my-6 flex w-full justify-center rounded-full border-2 border-gray-300 py-3 pl-6 pr-8 hover:bg-gray-100"
            onClick={() => {
              window.location = `${getApiUrl()}/oauth/google${location.search}`;
            }}
          >
            <FcGoogle className="mr-4 text-2xl" />
            {isSignup ? "Sign up" : "Login"} with Google
          </button>
        </div>
      ) : (
        <div>
          <button
            title="Login with Google"
            type="button"
            className="text-md my-6 flex w-full justify-center rounded-full border-2 border-gray-300 py-3 pl-6 pr-8 hover:bg-gray-100"
            onClick={() => {
              window.location = `${getApiUrl()}/organisation/oauth/organisation_google${
                location.search
              }`;
            }}
          >
            <FcGoogle className="mr-4 text-2xl" />
            Login with Google
          </button>
          <button
            title="Login with Microsoft"
            type="button"
            className="text-md my-6 flex w-full justify-center rounded-full border-2 border-gray-300 py-3 pl-6 pr-8 hover:bg-gray-100"
            onClick={() => {
              window.location = `${getApiUrl()}/organisation/oauth/microsoft${
                location.search
              }`;
            }}
          >
            <img
              className="mr-4 text-2xl"
              alt="Microsoft Login"
              src={MicrosoftLogo}
            />
            Login with Microsoft
          </button>
        </div>
      )}
    </div>
  );
};

ProviderButtons.propTypes = {
  isSignup: PropTypes.bool,
  isOrganisation: PropTypes.bool,
};

export default ProviderButtons;
