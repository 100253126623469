import { createSlice } from "@reduxjs/toolkit";

import {
  setCandidateUserAction,
  setOrganisationUserAction,
  updateCurrentUserAction,
} from "store/thunkActions/currentUserThunk";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: undefined,
    currentUser: undefined,
    isLogOutClicked: false,
  },
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      state.isLogOutClicked = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCandidateUserAction.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.isLoggedIn = true;
    });

    builder.addCase(setOrganisationUserAction.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.isLoggedIn = true;
    });

    builder.addCase(setCandidateUserAction.rejected, (state) => {
      state.isLoggedIn = false;
    });

    builder.addCase(setOrganisationUserAction.rejected, (state) => {
      state.isLoggedIn = false;
    });

    builder.addCase(updateCurrentUserAction.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
