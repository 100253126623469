import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { format, isBefore, toDate } from "date-fns";
import PropTypes from "prop-types";

import { Button, LabeledInput } from "components/atoms";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";
import {
  createProjectAction,
  getProjectsAction,
  updateProjectAction,
} from "store/thunkActions/candidate/projectThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const Form = ({ project, setIsEditState, setIsAdding }) => {
  const {
    watch,
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors, isSubmitting },
    setError,
    setValue,
    clearErrors,
  } = useForm();

  const dispatch = useDispatch();
  const showAlert = useAlert();
  const startAt = watch("start_at");
  const endAt = watch("end_at");
  const isOngoing = watch("is_ongoing");

  useEffect(() => reset(project), [project]);

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    // Restrict API call when no changes
    // temporary fix for the markdown update issue
    if (
      data.id &&
      data.description == project.description &&
      data.is_ongoing === project.is_ongoing &&
      !isDirty
    ) {
      return setIsEditState(false);
    }
    // Structuring req body
    data.start_at =
      data.start_at && format(new Date(data.start_at), "yyyy-MM-dd");
    data.end_at = data.end_at && format(new Date(data.end_at), "yyyy-MM-dd");
    data.description = data.description?.trim();

    // // Update if existing record else create new
    const action = data.id
      ? updateProjectAction(data)
      : createProjectAction(data);

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(setCandidateUserAction());
        dispatch(getProjectsAction());
      })
      .catch(({ errors }) => {
        if (errors) {
          setErrorsOnFields(errors);
        } else {
          showAlert("danger", "Something went wrong!");
        }
      });
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <p className="text-sm font-medium text-gray-dark">
        Please fill out the details{project ? " to update" : " for adding new"}{" "}
        project
      </p>

      <label
        htmlFor="projectName"
        className={`required mt-2 mb-1 block font-medium ${
          errors.title && "text-danger-main"
        }`}
      >
        Project Name
      </label>

      <input
        id="projectName"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className={`${errors.title ? "input-error" : "input"} w-full`}
        type="text"
        placeholder="Enter project name"
        {...register("title", {
          required: requiredValidation(),
          maxLength: {
            value: 255,
            message: characterValidation({ limit: 255 }),
          },
        })}
      />

      {errors.title && (
        <p className="text-sm text-danger-dark">{errors.title.message}</p>
      )}

      <LabeledInput
        id="project-url"
        labelClassNames={classNames("block mt-3 mb-1 font-medium", {
          "text-danger-main": errors.project_url,
        })}
        label="URL"
        inputFieldId="project-url"
        name="project_url"
        inputClassNames={classNames(
          "w-full disabled:bg-slate-200",
          `${errors.project_url ? "input-error" : "input"}`
        )}
        placeholder="Enter url"
        register={register}
        type="url"
        validation={{
          maxLength: {
            value: 255,
            message: characterValidation({ limit: 255 }),
          },
        }}
        errorMessage={errors.project_url?.message}
        errorMessageClassNames="text-sm text-danger-dark"
      />

      <label
        htmlFor="description"
        className={`mt-4 mb-1 block font-medium ${
          errors.description && "text-danger-main"
        }`}
      >
        Description
      </label>

      <Controller
        name="description"
        control={control}
        defaultValue=""
        rules={{
          maxLength: {
            value: 2000,
            message: characterValidation({ limit: 2000 }),
          },
        }}
        render={({ field }) => (
          <RichTextEditor
            id="description"
            content={field.value}
            onChange={(content) => field.onChange(content)}
            placeholder="Enter description..."
            fieldError={!!errors.description?.message}
          />
        )}
      />
      {errors.description && (
        <p className="text-sm text-danger-dark">{errors.description.message}</p>
      )}

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="start_at"
            className={`mt-2 mb-1 block font-medium ${
              errors.start_at && "text-danger-main"
            }`}
          >
            From
          </label>

          <Controller
            control={control}
            name="start_at"
            render={({ field }) => (
              <DatePicker
                showMonthYearPicker
                showFullMonthYearPicker
                placeholderText="Select Start date"
                className={`${
                  errors.start_at ? "input-error" : "input"
                } w-full`}
                dateFormat="MMMM yyyy"
                maxDate={new Date()}
                onChange={(date) => {
                  field.onChange(date);
                  if (isBefore(new Date(endAt), new Date(date))) {
                    setValue("end_at", null);
                    clearErrors("end_at");
                  } else if (date == null) {
                    setValue("end_at", null);
                    setValue("is_ongoing", false);
                  }
                }}
                selected={field.value && toDate(new Date(field.value))}
              />
            )}
          />

          {errors.start_at && (
            <p className="text-sm text-danger-dark">
              {errors.start_at.message}
            </p>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="end_at"
            className={`mt-2 mb-1 block font-medium ${
              errors.end_at && "text-danger-main"
            }`}
          >
            To
          </label>

          <Controller
            control={control}
            name="end_at"
            rules={{
              required: startAt && !isOngoing ? requiredValidation() : null,
            }}
            render={({ field }) => (
              <DatePicker
                disabled={isOngoing ? true : !startAt}
                showPopperArrow={false}
                showYearDropdown
                dropdownMode="select"
                showMonthYearPicker
                showFullMonthYearPicker
                placeholderText="Select End date"
                className={`${
                  errors.end_at ? "input-error" : "input"
                } disabled:input-disabled w-full`}
                dateFormat="MMMM yyyy"
                minDate={new Date(startAt)}
                onChange={(date) => {
                  field.onChange(date);
                }}
                selected={field.value && toDate(new Date(field.value))}
              />
            )}
          />

          {errors.end_at && (
            <p className="text-sm text-danger-dark">{errors.end_at.message}</p>
          )}

          <label
            htmlFor="currently_ongoing"
            className="mt-1 flex items-center text-sm"
          >
            <input
              type="checkbox"
              disabled={!startAt}
              id="currently_ongoing"
              {...register("is_ongoing")}
              onChange={(e) => {
                setValue("is_ongoing", e.target.checked);
                setValue("end_at", null);
                clearErrors("end_at");
              }}
            />
            <span className="ml-2 ">Ongoing</span>
          </label>
        </div>
      </div>

      <div className="mt-4 flex w-full">
        <Button
          title={setIsAdding ? "Save" : "Update"}
          btnClassName="px-2"
          testId="saveProjectBtn"
          type="primary"
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          {setIsAdding ? "Save" : "Update"}
        </Button>

        <Button
          title="Cancel"
          testId="cancelProjectBtn"
          type="secondary"
          size="sm"
          btnClassName="px-2 ml-2"
          onClick={() => setIsEditState(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    is_ongoing: PropTypes.bool,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};

export default Form;
