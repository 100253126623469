import { useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import PropTypes from "prop-types";

import Form from "./Form";

const ContactDetail = ({ contactDetail }) => {
  const [isEditState, setIsEditState] = useState(false);

  return isEditState ? (
    <Form contactDetail={contactDetail} setIsEditState={setIsEditState} />
  ) : (
    <div className="flex items-start justify-between break-all text-justify">
      <div>
        <h4 className="pt-2 pr-2 text-sm font-normal leading-5">
          {contactDetail?.address_line1}
        </h4>

        <p className="text-sm leading-5">
          <span> {contactDetail?.address_line2}</span>
        </p>
        <p className="flex-rows mt-2 flex text-sm">
          {contactDetail?.city}, {contactDetail?.state},{" "}
          {contactDetail?.country}, {contactDetail?.postal_code}
        </p>
        <div className="mt-2 flex  text-sm text-gray-500">
          <span className="italic"> Willing to relocate?</span>
          <span className="ml-2">
            {contactDetail?.willing_to_relocate ? (
              <span className="font-semibold text-primary-main">YES</span>
            ) : (
              <span className="font-semibold text-primary-main">NO</span>
            )}
          </span>
        </div>
      </div>
      <div className="flex">
        <button
          title="Edit Address"
          data-testid="editAddressBtn"
          className="btn-icon-secondary"
          onClick={() => setIsEditState(true)}
        >
          <MdOutlineModeEditOutline className="text-lg" />
        </button>
      </div>
    </div>
  );
};

ContactDetail.propTypes = {
  contactDetail: PropTypes.shape({
    id: PropTypes.number,
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    permanent_address: PropTypes.bool,
    state: PropTypes.string,
    postal_code: PropTypes.string,
    willing_to_relocate: PropTypes.bool,
  }),
};

export default ContactDetail;
