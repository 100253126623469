import PropTypes from "prop-types";

export const job = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  organisation: PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.shape({
      original: PropTypes.string,
      thumb: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  location: PropTypes.string,
  description: PropTypes.string,
  requirements: PropTypes.string,
  is_saved: PropTypes.bool,
  status: PropTypes.oneOf(["active", "application_closed", "job_closed"]),
  ctc: PropTypes.string,
  status_updated_at: PropTypes.string,
});
