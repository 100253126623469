const constructQueryParams = (queryParamsObject) =>
  new URLSearchParams(queryParamsObject).toString();

// Here input object should have values as array, eg: obj = { status: ["a", "b"] }
const sameObjectKeyToString = (object) => {
  const queryString = Object.entries(object)
    .map(([key, values]) => {
      return values.map((value) => `${key}=${value}`).join("&");
    })
    .join("&");
  return queryString;
};

export { constructQueryParams, sameObjectKeyToString };
