import { useEffect, useRef, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { format } from "date-fns";
import { startCase } from "lodash";
import PropTypes from "prop-types";

import { Avatar, Button } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import FormatDateAndTime from "components/common/FormattedDateAndTime";
import { examDuration } from "constants/common";

const ExamCandidates = ({
  candidate,
  setCandidateID,
  setExamCandidateId,
  setShowAddEvaluatorModal,
  setIsSingleOperation,
  setExamEvaluators,
  setSelectedCandidates,
  stageExamDetails,
  isUserRoleAllowed,
  isJobClosed,
}) => {
  const dropdownMenuRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  const handleEditEvaluators = () => {
    setExamEvaluators(candidate.exam_evaluators);
    setSelectedCandidates(null);
    setCandidateID(candidate.id);
    setShowAddEvaluatorModal(true);
    setIsSingleOperation(true);
    setExamCandidateId(null);
  };

  const handleAddEvaluators = () => {
    setIsSingleOperation(true);
    setShowAddEvaluatorModal(true);
    setCandidateID(candidate.id);
    setExamEvaluators(null);
    setSelectedCandidates(null);
    setExamCandidateId(null);
  };

  const hasDaysPassed = (startedAt, numberOfDays) => {
    const createdAtDate = new Date(startedAt);
    const currentDate = new Date();
    const timeDifference = currentDate - createdAtDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference >= numberOfDays;
  };

  return (
    <>
      {stageExamDetails?.[0]?.multi_slot && (
        <td className="py-4 pr-4 pl-6 align-top text-gray-darker">
          {candidate?.exam?.slot_details && (
            <>
              <FormatDateAndTime
                date={candidate?.exam?.slot_details.slot_start_at}
              />
              <span className="block">
                {format(
                  new Date(candidate?.exam?.slot_details.slot_start_at),
                  "h:mm a"
                )}
              </span>
              <span className="my-1"> to </span>
              <span className="block">
                {format(
                  new Date(candidate?.exam?.slot_details.slot_end_at),
                  "h:mm a"
                )}
              </span>
            </>
          )}
        </td>
      )}

      <td
        className={classNames(
          "align-top text-gray-darker",
          stageExamDetails[0]?.multi_slot ? "p-4" : "py-4 pr-4 pl-6"
        )}
      >
        {candidate?.exam?.started_at && (
          <>
            <FormatDateAndTime date={candidate?.exam?.started_at} />
            <span className="block mt-1">
              {format(new Date(candidate?.exam?.started_at), "h:mm a")}
            </span>
            <div className="mt-1">
              {examDuration(
                candidate?.exam?.started_at,
                candidate?.exam?.ended_at
              )}
            </div>
            <br />
            {candidate.exam?.obtained_marks &&
              stageExamDetails?.[0]?.camera_option === "REQUIRED" &&
              (!hasDaysPassed(candidate.exam.started_at, 90) ? (
                <Link
                  title="View Snapshots"
                  className="cursor-pointer text-primary-main hover:underline"
                  to={`/jobs/${candidate.job_uuid}/candidate/${candidate.candidate_id}/exam/${candidate?.exam?.id}/screenshots`}
                  target="_blank"
                >
                  View Snapshots
                </Link>
              ) : (
                <div
                  title="Snapshots are auto-deleted after 90 days."
                  className="cursor-pointer text-gray-main"
                >
                  View Snapshots
                </div>
              ))}
          </>
        )}
      </td>

      {stageExamDetails[0]?.question_paper?.sectional && (
        <td className="p-4 align-top text-gray-darker">
          {candidate?.exam?.sectional_marks.map((section) => (
            <span key={section.id} className="mb-2 block">
              {section.section_name} : {section.obtained_marks}
            </span>
          ))}
        </td>
      )}

      <td className="p-4 text-left align-top">
        {candidate.exam?.obtained_marks && (
          <>
            <div className="mb-2 text-gray-darker">
              <span className="block">{candidate.exam?.obtained_marks}</span>
            </div>
            <Link
              title="View Answers"
              className="cursor-pointer text-primary-main hover:underline"
              to={`/jobs/${candidate.job_uuid}/candidate/${candidate.candidate_id}/exam/${candidate?.exam?.id}/answers`}
              target="_blank"
            >
              View Answers
            </Link>
          </>
        )}
      </td>

      <td className="p-4 align-top text-gray-darker whitespace-nowrap">
        {candidate?.exam?.violations &&
          Object.keys(candidate?.exam?.violations).map(
            (violation) =>
              candidate?.exam?.violations[violation] > 0 && (
                <span key={violation} className="mb-2 block leading-5">
                  {startCase(violation)} :{" "}
                  {candidate?.exam?.violations[violation]}
                </span>
              )
          )}
      </td>

      {stageExamDetails?.[0]?.question_paper_type === "coding" && (
        <td className="p-4 align-top text-gray-darker whitespace-nowrap">
          {isUserRoleAllowed &&
            !candidate?.exam_evaluators.length &&
            !isJobClosed && (
              <div>
                <Button
                  title="Add Evaluators"
                  onClick={handleAddEvaluators}
                  data-testid="add-evaluator"
                  type="tertiary"
                  btnName="Add"
                />
              </div>
            )}
          {candidate?.exam_evaluators.length > 0 && (
            <div className="flex flex-wrap items-center">
              {candidate.exam_evaluators.map((evaluator) => (
                <div key={evaluator.evaluator_id} className="p-1">
                  <div className="h-6 w-6 rounded-full ring-1 ring-primary-dark ring-offset-1 ">
                    {evaluator.evaluator_avatar.thumb ? (
                      <div className="h-6 w-6">
                        <Button
                          title={evaluator.evaluator_name}
                          btnClassName="cursor-default"
                        >
                          <Avatar
                            src={evaluator.evaluator_avatar.thumb}
                            height={6}
                            width={6}
                          />
                        </Button>
                      </div>
                    ) : (
                      <Button
                        title={evaluator.evaluator_name}
                        btnClassName="cursor-default"
                      >
                        <CharacterAvatar
                          char={evaluator.evaluator_name?.charAt(0)}
                          width={6}
                          height={6}
                          textSize="text-[1rem]"
                        />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
              {isUserRoleAllowed && !isJobClosed && (
                <div>
                  <Button
                    title="Edit Evaluators"
                    onClick={handleEditEvaluators}
                    btnClassName="p-1 text-lg text-primary-main hover:cursor-pointer"
                    data-testid="editEvaluators"
                  >
                    <BsPencilSquare />
                  </Button>
                </div>
              )}
            </div>
          )}
        </td>
      )}
    </>
  );
};

ExamCandidates.defaultProps = {
  candidate: {
    id: 0,
    uuid: "",
    candidate_id: 0,
    job_uuid: "",
    name: "",
    exam_evaluators: [],
    exam: {
      slot_details: {
        slot_start_at: "",
        slot_end_at: "",
      },
      started_at: "",
      ended_at: "",
      obtained_marks: "",
      id: 0,
      sectional_marks: [],
      violations: {
        face_mismatch: 0,
        full_screen: 0,
        tab_switch: 0,
      },
    },
  },
  setCandidateID: () => {},
  setExamCandidateId: () => {},
  setIsSingleOperation: () => {},
  setShowAddEvaluatorModal: () => {},
  setExamEvaluators: () => {},
  setSelectedCandidates: () => {},
  stageExamDetails: [],
  isUserRoleAllowed: false,
  isJobClosed: false,
};

FormatDateAndTime.propTypes = {
  date: PropTypes.string.isRequired,
};

ExamCandidates.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    candidate_id: PropTypes.number,
    job_uuid: PropTypes.string,
    name: PropTypes.string,
    exam_evaluators: PropTypes.arrayOf(
      PropTypes.shape({
        evaluator_id: PropTypes.number,
        evaluator_name: PropTypes.string,
      })
    ),
    exam: PropTypes.shape({
      slot_details: PropTypes.shape({
        slot_start_at: PropTypes.string,
        slot_end_at: PropTypes.string,
      }),
      started_at: PropTypes.string,
      ended_at: PropTypes.string,
      obtained_marks: PropTypes.string,
      id: PropTypes.number,
      sectional_marks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          obtained_marks: PropTypes.string,
          section_name: PropTypes.string,
          total_marks: PropTypes.string,
        })
      ),
      violations: PropTypes.shape({
        face_mismatch: PropTypes.number,
        full_screen: PropTypes.number,
        tab_switch: PropTypes.number,
      }),
    }),
  }),
  setCandidateID: PropTypes.func,
  setExamCandidateId: PropTypes.func,
  setIsSingleOperation: PropTypes.func,
  setShowAddEvaluatorModal: PropTypes.func,
  setExamEvaluators: PropTypes.func,
  setSelectedCandidates: PropTypes.func,
  stageExamDetails: PropTypes.arrayOf(
    PropTypes.shape({
      question_paper: PropTypes.shape({
        sectional: PropTypes.bool,
      }),
      multi_slot: PropTypes.bool,
      question_paper_type: PropTypes.string,
    })
  ),
  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
};

export default ExamCandidates;
