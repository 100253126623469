import { Controller, useForm } from "react-hook-form";
import {
  BsDashCircle,
  BsExclamationCircleFill,
  BsPlusCircle,
} from "react-icons/bs";
import PropTypes from "prop-types";

import {
  createFeedbackForGroup,
  createFeedbackForInterview,
} from "api/erp/organizationApi";
import { ICON_SIZE_HASH } from "app/constants";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Modal from "components/molecules/Modal";
import { requiredValidation } from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";

const FeedbackModal = ({
  setShowFeedbackModal,
  panel,
  getMorePanelData,
  viewOnly,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    control,
    setValue,
    getValues,
    clearErrors,
  } = useForm();

  const showAlert = useAlert();

  const submitHandler = (data) => {
    const panelData = {
      ...data,
      marks: data.is_appeared ? data.marks : 0,
      comment: data.is_appeared ? data.comment.trim() : "",
      is_appeared: !!data.is_appeared,
      candidate_job_stage_uuid: panel.candidate_job_stage_uuid,
    };

    if (panel.type === "group") {
      return createFeedbackForGroup(panel.uuid, panelData)
        .then(() => {
          showAlert("success", "Feedback shared successfully!");
          getMorePanelData(1, false);
          setShowFeedbackModal(false);
        })
        .catch(() => showAlert("danger", "Try again."));
    }
    return createFeedbackForInterview(panel.id, panelData)
      .then(() => {
        showAlert("success", "Feedback shared successfully!");
        getMorePanelData(1, false);
        setShowFeedbackModal(false);
      })
      .catch(() => showAlert("danger", "Try again."));
  };

  const onIncreaseRating = () => {
    clearErrors();
    setValue(
      "marks",
      getValues("marks") === null ? 0 : Number(getValues("marks")) + 1,
      { shouldDirty: true }
    );
  };

  const onDecreaseRating = () => {
    clearErrors();
    setValue("marks", Number(getValues("marks")) - 1);
  };

  const appeared = watch("is_appeared", true);

  return (
    <Modal
      title={viewOnly ? "Feedback" : "Share Feedback"}
      scrollClass="overflow-y-auto apply-modal-height"
      onOutsideClickHandler={() => setShowFeedbackModal(false)}
      handleCancel={() => setShowFeedbackModal(false)}
      handleAccept={
        viewOnly ? null : handleSubmit((data) => submitHandler(data))
      }
      acceptBtnType={isDirty ? "primary" : "disabled"}
      acceptButtonText="Submit"
      isDirty={isDirty}
    >
      {!viewOnly ? (
        <form
          className="m-1"
          onSubmit={handleSubmit((data) => submitHandler(data))}
        >
          <div className="mb-5 flex items-center justify-center gap-3 rounded-full border border-primary-light p-2 text-primary-main opacity-80">
            <div>
              <BsExclamationCircleFill
                size={ICON_SIZE_HASH.md}
                className="h-6 w-6"
              />
            </div>
            <div className="text-sm">
              Feedbacks once submitted, can not be edited again.
            </div>
          </div>
          <div className="flex justify-between py-2">
            <div className="text-md font-medium">Did the candidate join?</div>
            <label className="relative flex w-max cursor-pointer select-none items-center">
              <input
                type="checkbox"
                className="ml-4 h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
                {...register("is_appeared")}
                onClick={(e) => {
                  if (!e.target.checked) {
                    setValue("marks", null);
                    setValue("comment", "");
                  }
                }}
                defaultChecked
              />
              <span className="font-small absolute right-1.5 text-xs uppercase text-white">
                NO
              </span>
              <span className="absolute right-9 text-xs font-medium uppercase text-white">
                YES
              </span>
              <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
            </label>
          </div>

          <div className="min-h-[60px]">
            <div className="mt-2 flex justify-between">
              <label
                htmlFor="marks"
                className={`mt-1 required block font-medium ${
                  errors.marks && "text-danger-main"
                }`}
              >
                Rating <span className="text-sm">(Out of 5)</span>
              </label>

              <div className="flex ml-4 space-x-4">
                <BsDashCircle
                  role="button"
                  onClick={onDecreaseRating}
                  data-testid="ratingMinus"
                  className={`${
                    (watch("marks") < 1 ||
                      watch("marks") == null ||
                      !appeared) &&
                    "pointer-events-none bg-slate-200"
                  }  my-1 rounded-full text-2xl text-gray-500 hover:cursor-pointer hover:bg-gray-200`}
                />

                <input
                  id="marks"
                  name="marks"
                  readOnly
                  disabled
                  data-testid="marks"
                  className={`${
                    errors.marks && "input-error"
                  } input mt-1 h-7 w-8 rounded-md bg-gray-200 p-1 text-center hover:outline-gray-light ${
                    appeared && "bg-white"
                  }`}
                  {...register("marks", {
                    required: !!appeared && requiredValidation(),
                    value: null,
                  })}
                />

                <BsPlusCircle
                  role="button"
                  onClick={onIncreaseRating}
                  data-testid="ratingPlus"
                  className={`${
                    (watch("marks") > 4 || !appeared) &&
                    "pointer-events-none bg-slate-200"
                  } my-1 rounded-full text-2xl text-gray-500 hover:cursor-pointer hover:bg-gray-200`}
                />
              </div>
            </div>

            {errors.marks && (
              <p className="text-sm text-danger-dark">{errors.marks.message}</p>
            )}
          </div>

          <div className="py-2">
            <label
              htmlFor="comment"
              className={`mt-2 mb-3 block font-medium ${
                errors.comment && "text-danger-main"
              }`}
            >
              Comments
            </label>

            <Controller
              name="comment"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RichTextEditor
                  id="comment"
                  content={field.value}
                  onChange={(content) => field.onChange(content)}
                  placeholder="Enter comment"
                  fieldError={!!errors.comment?.message}
                  disabled={!appeared}
                />
              )}
            />
            {errors.comment && (
              <p className="text-sm text-danger-dark">
                {errors.comment.message}
              </p>
            )}
          </div>
        </form>
      ) : (
        <>
          <div className="flex justify-between py-2">
            <div className="text-md font-medium">Did the candidate join?</div>
            <label className="relative flex w-max select-none items-center">
              <input
                type="checkbox"
                readOnly
                className="ml-4 h-6 w-16 appearance-none rounded-full bg-gray-400"
                checked={panel.feedback.is_appeared}
              />
              <span className="font-small absolute right-1.5 text-xs uppercase text-white">
                NO
              </span>
              <span className="absolute right-9 text-xs font-medium uppercase text-white">
                YES
              </span>
              <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
            </label>
          </div>
          <div className="mt-2 flex justify-between ">
            <label htmlFor="marks" className="required  mb-1 block font-medium">
              Rating <span className="text-sm">(Out of 5)</span>
            </label>

            <div className="flex space-x-4">
              <input
                id="marks"
                name="marks"
                readOnly
                data-testid="marks"
                className="input mt-1 h-6 w-8 rounded-md p-1 text-center"
                value={panel.feedback.is_appeared ? panel.feedback.marks : ""}
              />
            </div>
          </div>
          <div className="py-2">
            <label htmlFor="comment" className="mt-2 mb-1 block font-medium">
              Comments
            </label>
            <div
              className="input h-48 w-full resize-y overflow-y-auto"
              readOnly
            >
              <RenderParsedHTML content={panel.feedback.comment || ""} />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

FeedbackModal.defaultProps = {
  setShowFeedbackModal: () => {},
  getMorePanelData: () => {},
  panel: {},
  viewOnly: null,
};

FeedbackModal.propTypes = {
  setShowFeedbackModal: PropTypes.func,
  getMorePanelData: PropTypes.func,
  panel: PropTypes.shape({
    id: PropTypes.number,
    job_uuid: PropTypes.string,
    stage_id: PropTypes.number,
    candidate_job_id: PropTypes.number,
    title: PropTypes.string,
    candidate_name: PropTypes.string,
    start_at: PropTypes.string,
    duration: PropTypes.number,
    link: PropTypes.string,
    type: PropTypes.string,
    candidate_job_stage_id: PropTypes.number,
    feedback: PropTypes.shape({
      id: PropTypes.number,
      marks: PropTypes.number,
      comment: PropTypes.string,
      is_appeared: PropTypes.bool,
    }),
  }),
  viewOnly: PropTypes.bool,
};

export default FeedbackModal;
