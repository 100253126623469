const selectStyle = (error, customHeight = {}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: error && "#fad1dd",
      ...customHeight,
      // eslint-disable-next-line no-nested-ternary
      borderColor: error ? "#E73067" : state.isFocused ? "#143A7B" : "#9ca3af",
      boxShadow: (state.isFocused && !error) ?? "0 0 0 1px #205dc5",
      "&:hover": {
        borderColor: error ? "#E73067" : "#205dc5",
      },
    }),
  };

  return customStyles;
};

export default selectStyle;
