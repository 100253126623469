import { createSlice } from "@reduxjs/toolkit";

import {
  createCandidatePhoneNumberAction,
  deleteCandidatePhoneNumberAction,
  getCandidatePhoneNumberAction,
  updateCandidatePhoneNumberAction,
} from "store/thunkActions/candidate/phoneNumberThunk";

export const getPhoneNumber = createSlice({
  name: "phoneNumber",
  initialState: {
    phoneNumbers: [],
  },
  reducers: {},
  extraReducers: {
    [getCandidatePhoneNumberAction.fulfilled]: (state, action) => {
      state.phoneNumbers = action.payload;
    },
    [createCandidatePhoneNumberAction.fulfilled]: (state, action) => {
      state.phoneNumbers.push(action.payload.data);
    },
    [updateCandidatePhoneNumberAction.fulfilled]: (state, action) => {
      const index = state.phoneNumbers.findIndex(
        (phoneNumber) => phoneNumber.id == action.payload.data.id
      );
      state.phoneNumbers[index] = action.payload.data;
    },
    [deleteCandidatePhoneNumberAction.fulfilled]: (state, action) => {
      const index = state.phoneNumbers.findIndex(
        (phoneNumber) => phoneNumber.id == action.payload.data.id
      );
      state.phoneNumbers.splice(index, 1);
    },
  },
});

export default getPhoneNumber.reducer;
