import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addCandidatePhoneNumbers,
  getCandidatePhoneNumbers,
  removeCandidatePhoneNumbers,
  updateCandidatePhoneNumbers,
} from "api/candidate/profileApi";

export const getCandidatePhoneNumberAction = createAsyncThunk(
  "phone-number/get",
  async () => {
    const response = await getCandidatePhoneNumbers();
    return response.data;
  }
);

export const createCandidatePhoneNumberAction = createAsyncThunk(
  "phone-number/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addCandidatePhoneNumbers(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCandidatePhoneNumberAction = createAsyncThunk(
  "phone-number/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCandidatePhoneNumbers(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCandidatePhoneNumberAction = createAsyncThunk(
  "phone-number/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await removeCandidatePhoneNumbers(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
