import { forwardRef } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { CgSpinner } from "react-icons/cg";
import classNames from "classnames";
import { bool, number, string } from "prop-types";

const ActivityIndicator = forwardRef(
  ({ main, size, containerClass, svgClassName }, forwardedRef) => (
    <div
      ref={forwardedRef}
      className={classNames("flex w-full justify-center", containerClass)}
    >
      {main ? (
        <CgSpinner
          className={classNames(
            "animate-spin text-8xl text-primary-main",
            svgClassName
          )}
        />
      ) : (
        <BiLoaderAlt
          className={classNames(
            `animate-spin h-${size} w-${size} text-primary-main`,
            svgClassName
          )}
        />
      )}
    </div>
  )
);

ActivityIndicator.displayName = "ActivityIndicator";

ActivityIndicator.defaultProps = {
  main: false,
  size: 20,
  containerClass: null,
  svgClassName: "",
};

ActivityIndicator.propTypes = {
  main: bool,
  size: number,
  containerClass: string,
  svgClassName: string,
};

export default ActivityIndicator;
