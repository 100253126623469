import PropTypes from "prop-types";

const DescriptionCard = ({ cardTitle, children }) => {
  return (
    <div className="rounded-lg px-[15px] pt-[15px] pb-[10px] border bg-white shadow-lg">
      <div className="border-b-2 pb-[15px]">
        <h3 className="text-xl">{cardTitle}</h3>
      </div>
      <div className="pt-[15px]">{children}</div>
    </div>
  );
};

DescriptionCard.propTypes = {
  cardTitle: PropTypes.string,
  children: PropTypes.node,
};

export default DescriptionCard;
