import classNames from "classnames";
import PropTypes from "prop-types";

const TITLE_CLASS = "flex justify-between items-start";

const containerClass = (isSelected) =>
  classNames(
    "p-3 text-gray-main border-b-[1px] border-b-gray-lightest hover:bg-secondary-hover ",
    isSelected
      ? "bg-secondary-selected border-l-4 border-l-primary-dark"
      : "border-l-4 border-l-transparent"
  );

const ListElement = ({ title, description, testId, isSelected, ...props }) => (
  <div data-testid={testId} className={containerClass(isSelected)} {...props}>
    {title && <div className={TITLE_CLASS}>{title}</div>}
    {description}
  </div>
);

ListElement.defaultTypes = {
  title: "",
  description: "",
  testId: "list-element",
  isSelect: false,
};

ListElement.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  testId: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default ListElement;
