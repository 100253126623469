import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { SITE_TITLE } from "constants/common";
import freshWorkWidget from "helpers/freshWorkWidget";
import showFreshWorkWidget from "helpers/showFreshWorkWidget";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const FaqPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateHeaderTitle({ breadCrumbDetails: { currentPageName: "FAQ" } })
    );
  }, []);

  useEffect(() => {
    const acc = document.getElementsByClassName("accordion");
    let i;
    for (i = 0; i < acc.length; i += 1) {
      acc[i].addEventListener("click", function addClickEvent() {
        // eslint-disable-next-line react/no-this-in-sfc
        this.classList.toggle("active");
        // eslint-disable-next-line react/no-this-in-sfc
        const panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(setCandidateUserAction())
      .unwrap()
      .then((data) => {
        freshWorkWidget(data);
      })
      .catch(() => {
        showFreshWorkWidget();
      });
  }, []);

  return (
    <div className="container mx-auto mb-2 px-2 text-md">
      <Helmet>
        <title>{`Help - ${SITE_TITLE}`}</title>
        <meta property="og:url" content="https://app.joballey.in/" />
      </Helmet>
      <div className="flex flex-col items-center justify-center">
        <h1 className=" text-primary-main">
          Please make sure that you go through the FAQs before emailing support
        </h1>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q1. When should I contact support?
        </button>
        <ul className="panel list-inside list-disc">
          <li className="list-none">
            Please go through the below questions thoroughly and if they do not
            solve your issues, you can contact us by clicking on the “Support”
            icon in the bottom right corner.
          </li>
          <li className="list-none">
            If you do proceed to creating a ticket, please make sure that you
            include the following information in your ticket:
          </li>
          <li>Describe the issue thoroughly in detail.</li>
          <li>
            Include the screenshot of the JobAlley App where you are facing the
            issue, and the related emails.
          </li>
          <li>Email ID with on which you have received the relevant emails.</li>
          <li>Operating system and browser versions.</li>
          <li>
            The type of internet connection you are using (broadband, mobile
            data, etc.) and internet package details (only for broadband, if
            available).
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q2. How can I start the exam?
        </button>
        <ul className="panel list-outside list-disc">
          <li>
            You will only get the option to start your exam{" "}
            <span className="italic">
              during your slots active window, in the JobAlley app.{" "}
            </span>{" "}
            The button will not be available prior to or post your slot window.
          </li>
          <li>
            You have to log in with the same email on which you have received
            the exam schedule email. The id has to match character by character.
            <p>
              Eg: ab@gmail.com, a.b@gmail.com and ab+@gmail.com are all treated
              as different ids.
            </p>
          </li>
          <li>
            Please refresh your dashboard once the slot time is reached to load
            the start button.
          </li>
          <li>
            The exam can not be opened if multiple tabs are accessing JobAlley
            at that moment. Make sure to close it from all other
            tabs/windows/browsers/devices and refresh the browser you decide to
            keep.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q3. I can not see exam information on the JobAlley app.
        </button>
        <ul className="panel">
          <li>
            You have to log in with the same email which you used while applying
            to the job and on which you have received the exam schedule email.
            The id has to match character by character.
            <p>
              Eg: ab@gmail.com, a.b@gmail.com and ab+@gmail.com are all treated
              as different ids.
            </p>
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <button type="button" className="accordion">
          Q4. I have given camera permission but my camera is not starting.
        </button>
        <ul className="panel">
          <li>
            If your exam page is unable to access your camera, despite you
            providing camera permission, please make sure that your camera is
            working properly and it is not being accessed by any other
            app/tab/browser at that moment.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q5. What are the minimum system requirements to appear in the exam?
        </button>
        <ul className="panel list-inside list-disc">
          <li className="list-none">
            Following are the minimum system requirements to ensure a smooth
            exam.{" "}
            <span className="font-bold">
              If any one of these are not met, JobAlley won’t be held liable for
              any issues that you might encounter.{" "}
            </span>{" "}
            Please update your system before the exam.
          </li>
          <li>
            Network Requirements: A stable internet connection with proper
            download & upload speed of at least 1 MBPS.
          </li>
          <li>
            Supported Browsers: Chrome v85.0 (or above) and Firefox v80.0 (or
            above).
          </li>
          <li>
            Operating System: Windows 10 (or above), Ubuntu 18.04 (or above),
            Linux Mint 19 (or above), Fedora 32 (or above), macOS 11 (or above).
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q6. Can I appear for the exam from my mobile phone?
        </button>
        <ul className="panel list-inside list-disc">
          <li className="list-none">
            You can appear using your mobile if you have no other devices
            available with you during the exam&apos;s active window. For the
            best bug-free experience,{" "}
            <span className="italic">
              we recommend appearing for the exam using a proper desktop/laptop.{" "}
            </span>{" "}
            <p className="font-bold">
              Please note that we will not be responsible if you face any issues
              while appearing for the exam using your mobile phone.
            </p>
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <button type="button" className="accordion">
          Q7. How can I get an exam link?
        </button>
        <ul className="panel list-inside">
          <li className="list-none">
            Exam is scheduled for only those candidates who have applied to the
            job in time. Details are sent automatically to them on the email id
            which was used during the application process.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q8. I am not getting the magic link.
        </button>
        <ul className="panel list-inside">
          <li className="list-none">
            <p>
              {" "}
              If you are trying to login via magic link, then the link will be
              sent to the email address that you have entered. Make sure that
              you are checking the correct inbox to find the login link from{" "}
              <a
                title="Support"
                className="underline"
                href="mailto:support@joballey.in"
              >
                support@joballey.in
              </a>{" "}
              . Also check your Promotions/Updates/Spam if you are unable to
              find it in your Primary inbox.
            </p>
            <p>You can also search your inbox via the above mentioned email.</p>
            <p>
              In case you are still unable to login via magic link then you can
              also Login via Google/Github/LinkedIn buttons, provided the same
              email id is linked with these apps.
            </p>
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q9. When will I get the assessment/result email?
        </button>
        <ul className="panel list-inside">
          <li className="list-none">
            You will get the mail after your exam and your slot duration, both
            are over.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q10. When will I get the exam details email?
        </button>
        <ul className="panel list-inside ">
          <li className="list-none ">
            You will get the exam details email when all of the mentioned
            conditions hold true.
          </li>
          <ul className="my-2 list-inside list-decimal">
            <li>You have applied to the job in time.</li>
            <li>You have been selected for the assessment.</li>
            <li>
              Your exam has been scheduled and the job poster has decided to
              notify you.
            </li>
          </ul>
          <li className="list-none">
            Please check the inbox of the same email id which you used to apply
            to the job and login to the JobAlley app with the same email id.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <button type="button" className="accordion">
          Q11. Can my exam be rescheduled?
        </button>
        <ul className="panel list-inside">
          <li className="list-none">
            Unfortunately, it is not possible to reschedule the exam.
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <button type="button" className="accordion">
          Q12. The exam time shown in email and Job Alley App dashboard is
          different.
        </button>
        <ul className="panel list-inside">
          <li className="list-none">
            Please make sure that both your operating system and email timezone
            are set to Indian Standard Time (IST). Please make sure that the
            time on your App has not been changed either from the inspect panel
            or with some external application.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FaqPage;
