import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCandidatePhoneNumberAction } from "store/thunkActions/candidate/phoneNumberThunk";

import CardContainer from "../Common/CardContainer";

import Form from "./Form";
import PhoneNumber from "./PhoneNumber";

const PhoneNumbers = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { phoneNumbers } = useSelector((state) => state.phoneNumbers);

  useEffect(() => {
    dispatch(getCandidatePhoneNumberAction());
  }, []);

  return (
    <CardContainer
      title="Phone Number"
      addEventButtonTitle="Add Phone Number"
      addEventButtonTestId="add-phone-number-btn"
      setIsAdding={() => setIsAdding(true)}
    >
      {!phoneNumbers.length && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on the plus icon to add phone numbers
        </span>
      )}

      {phoneNumbers.map((phoneNumber) => {
        return (
          <div
            key={phoneNumber.id}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <PhoneNumber phoneNumber={phoneNumber} />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsEditState={setIsAdding} setIsAdding={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default PhoneNumbers;
