import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createQuestionTags,
  deleteQuestionTags,
  getQuestionTags,
  updateQuestionTags,
} from "api/erp/questionPaperApi";

export const getQuestionTagAction = createAsyncThunk("tags/get", async () => {
  const response = await getQuestionTags();
  return response;
});

export const getFilterQuestionTagAction = createAsyncThunk(
  "tags/get",
  async ({ search_term: searchTerm }) => {
    const response = await getQuestionTags(searchTerm);
    return response;
  }
);

export const createQuestionTagAction = createAsyncThunk(
  "tags/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createQuestionTags(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQuestionTagAction = createAsyncThunk(
  "tags/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateQuestionTags(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuestionTagAction = createAsyncThunk(
  "tags/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteQuestionTags(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
