import Proptypes from "prop-types";

import Button from "../Button";

const CardView = ({
  children,
  acceptButtonText,
  rejectButtonText,
  handleAccept,
  handleReject,
  acceptBtnType,
  customButtonWidth,
  rejectBtnType,
}) => {
  return (
    <div className="rounded-md border border-gray-light bg-white px-2 shadow-sm">
      {children}
      <Button
        data-testid="rejectActionBtn"
        onClick={handleReject}
        size="md"
        customWidth={customButtonWidth}
        type={rejectBtnType}
        btnName={rejectButtonText}
      />
      {handleAccept && (
        <Button
          data-testid="acceptActionBtn"
          onClick={handleAccept}
          type={acceptBtnType}
          size="md"
          customWidth={customButtonWidth}
          btnName={acceptButtonText}
        />
      )}
    </div>
  );
};

CardView.defaultTypes = {
  children: null,
};

CardView.propTypes = {
  children: Proptypes.node,
  acceptButtonText: Proptypes.string,
  rejectButtonText: Proptypes.string,
  handleAccept: Proptypes.func,
  handleReject: Proptypes.func,
  acceptBtnType: Proptypes.string,
  rejectBtnType: Proptypes.string,
  customButtonWidth: Proptypes.number,
};

export default CardView;
