import {
  BsCheckCircleFill,
  BsExclamationCircleFill,
  BsXCircleFill,
} from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";

export const alertStyleTypeMapper = (type) => {
  const styleType = type === "info" ? "secondary" : type;

  return {
    backgroundColor: `bg-${styleType}-alertBg`,
    textColor: `text-${styleType}-alertDark`,
    borderColor: `border-${styleType}-alertDark`,
  };
};

export const alertIconTypeMapper = (type) => {
  switch (type) {
    case "success":
      return BsCheckCircleFill;
    case "info":
      return FaInfoCircle;
    case "warning":
      return BsExclamationCircleFill;
    case "danger":
      return BsXCircleFill;
    default:
      return null;
  }
};
