import classNames from "classnames";
import PropTypes from "prop-types";

const Avatar = ({
  src,
  height,
  width,
  testId,
  shape,
  optionalClass,
  border,
}) => {
  return (
    <img
      src={src}
      data-testid={testId}
      alt="avatar_thumbnail"
      className={classNames(
        "object-cover",
        `w-${width}`,
        `h-${height}`,
        shape,
        border,
        optionalClass
      )}
    />
  );
};

Avatar.defaultProps = {
  height: 10,
  width: 10,
  testId: "avatar",
  shape: "rounded-full",
  border: "border-2",
  optionalClass: "",
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  testId: PropTypes.string,
  shape: PropTypes.string,
  border: PropTypes.string,
  optionalClass: PropTypes.string,
};

export default Avatar;
