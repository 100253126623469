import classNames from "classnames";
import PropTypes from "prop-types";

import EducationQualification from "./EducationQualification";

const EducationQualifications = ({ educationQualifications, modalView }) => {
  return (
    <div
      className={classNames(
        " mx-auto w-full bg-white",
        !modalView && "mt-4 rounded-lg border border-gray-light p-4"
      )}
    >
      <h3
        className={classNames(
          " font-normal",
          modalView ? "text-xl" : "text-2xl"
        )}
      >
        Education
      </h3>
      {!educationQualifications?.length && (
        <span className="mt-4 block text-sm italic text-gray-main">
          No data found
        </span>
      )}
      {educationQualifications?.map((educationQualification) => {
        return (
          <div
            key={educationQualification.id}
            className="border-b border-gray-dark py-4 last:border-b-0"
          >
            <EducationQualification
              educationQualification={educationQualification}
            />
          </div>
        );
      })}
    </div>
  );
};

EducationQualifications.propTypes = {
  educationQualifications: PropTypes.array,
  modalView: PropTypes.bool,
};

export default EducationQualifications;
