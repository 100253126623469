import { AiOutlineDoubleRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import ExamRow from "./ExamRow";

const ExamCard = ({ status, exams, hasMoreExams, currentUser }) => {
  if (!exams?.length) {
    return null;
  }

  return (
    <>
      <h2 className="py-2 text-xl font-semibold capitalize">{status} Exams</h2>
      <div
        className={classNames(
          "max-h-100 relative overflow-y-auto bg-white p-3 text-sm ",
          `${status === "upcoming" ? "p-1" : "rounded-2xl border "}`
        )}
      >
        <div>
          {exams?.map((exam) => (
            <div
              className={
                exams.indexOf(exam) !== 0 &&
                "border-t-2 border-solid border-gray-lighterShade2"
              }
              key={exam.id}
            >
              <ExamRow exam={exam} status={status} currentUser={currentUser} />
            </div>
          ))}
        </div>
        {status === "upcoming" && hasMoreExams && (
          <Link
            to="/exams?tab=upcoming"
            className="absolute text-md right-1.5 bottom-1.5 flex text-primary-light"
            title="View all"
          >
            View all <AiOutlineDoubleRight className="mt-1" />
          </Link>
        )}
      </div>
    </>
  );
};

ExamCard.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming"]),
  exams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      exam_type: PropTypes.oneOf(["coding", "mcq"]),
      start_at: PropTypes.string,
      end_at: PropTypes.string,
      duration: PropTypes.number,
      job_uuid: PropTypes.string,
      job_title: PropTypes.string,
      organisation_name: PropTypes.string,
      job_location: PropTypes.string,
      has_started: PropTypes.bool,
      job_page_number: PropTypes.number,
      camera_option: PropTypes.string,
      candidate_status: PropTypes.string,
    })
  ),
  hasMoreExams: PropTypes.bool,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    portfolio_url: PropTypes.string,
    about: PropTypes.string,
    date_of_birth: PropTypes.string,
    is_profile_completed: PropTypes.bool,
    photo_url: PropTypes.string,
    reference_images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        file_name: PropTypes.string,
        attachment: PropTypes.string,
      })
    ),
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
  }),
};
export default ExamCard;
