import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import { capitalize, lowerCase } from "lodash";
import PropTypes from "prop-types";

import { getSkills } from "api/candidate/profileApi";
import { PROFICIENCY_LEVELS } from "app/constants";
import { Button } from "components/atoms";
import { handleOptionLabel } from "helpers/formatDropDownOption";
import { formatOption } from "helpers/formatOption";
import reactSelectStyle from "helpers/reactSelectStyle";
import { validateDropdowns } from "helpers/utils";
import useAlert from "hooks/useAlert";
import {
  createCandidateSkillsAction,
  getCandidateSkillsAction,
  updateCandidateSkillsAction,
} from "store/thunkActions/candidate/skillThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const AsyncPaginateCreatable = withAsyncPaginate(Creatable);

const Form = ({ candidateSkill, setIsEditState, setIsAdding }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
    clearErrors,
    setError,
    watch,
  } = useForm();

  const dispatch = useDispatch();
  const showAlert = useAlert();

  useEffect(() => {
    reset({
      proficiency: {
        label: capitalize(candidateSkill?.proficiency),
        value: candidateSkill?.proficiency,
      },
      skill: candidateSkill?.skill,
    });
  }, [candidateSkill]);

  useEffect(() => {
    dispatch(getCandidateSkillsAction());
  }, []);

  const { candidateSkills } = useSelector((state) => state.candidateSkills);

  const isSkill = watch("skill");

  const loadSkillOptions = async (search, _, { page }) => {
    const { data, meta } = await getSkills(page, search);
    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const isAlreadyAdded = (candidateSkill) => {
    const candidateSkillIds = candidateSkills?.map(
      (candidateSkill) => candidateSkill.skill.id
    );
    return candidateSkillIds.includes(candidateSkill.id);
  };

  const setErrorsOnFields = (errors) => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        if (key === "name" || key === "candidate_skill") {
          setError("skill", { message: value[0] });
        } else {
          showAlert("danger", `Error on ${lowerCase(key)} : ${value[0]}`);
        }
      });
    }
  };

  const submitHandler = (data) => {
    let action;
    const skillData = {
      proficiency: data.proficiency?.value || "",
    };
    if (candidateSkill) {
      skillData.id = candidateSkill.id;
      action = updateCandidateSkillsAction(skillData);
    } else {
      if (data.skill?.id) {
        skillData.skill_id = data.skill.id;
      } else {
        skillData.skill_name = data.skill.value;
      }
      action = createCandidateSkillsAction(skillData);
    }

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(setCandidateUserAction());
        dispatch(getCandidateSkillsAction());
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <div className="flex flex-col justify-between">
        <div className="flex sm:flex-row flex-col justify-between mb-4">
          <div
            className={classNames("sm:mr-8 m-2 sm:w-1/2 sm:m-0", {
              "hover:cursor-not-allowed": candidateSkill,
            })}
          >
            <Controller
              control={control}
              name="skill"
              rules={{
                validate: (skill) =>
                  validateDropdowns({ field: skill, limit: 50 }),
              }}
              render={({ field }) => (
                <AsyncPaginateCreatable
                  isSearchable
                  autoFocus
                  isClearable
                  menuPlacement="auto"
                  isDisabled={candidateSkill}
                  className="w-full bg-white"
                  styles={reactSelectStyle(errors.skill)}
                  value={
                    field.value?.label || field.value?.name ? field.value : null
                  }
                  loadOptions={loadSkillOptions}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  onInputChange={clearErrors}
                  debounceTimeout={1000}
                  placeholder="Select skill"
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    formatOption(option.name) || formatOption(option.label)
                  }
                  isOptionDisabled={isAlreadyAdded}
                  formatCreateLabel={(name) => `Create new skill "${name}"`}
                  additional={{ page: 1 }}
                  formatOptionLabel={(option, { context }) =>
                    handleOptionLabel(option, context)
                  }
                />
              )}
            />
          </div>
          <div className="sm:w-1/2 m-2 sm:m-0">
            <Controller
              name="proficiency"
              control={control}
              render={({ field }) => (
                <Select
                  name="proficiency"
                  options={PROFICIENCY_LEVELS}
                  isClearable
                  isSearchable
                  isDisabled={!isSkill || isSkill === null}
                  id="proficiency"
                  maxMenuHeight={120}
                  menuPlacement="auto"
                  placeholder="Proficiency level"
                  value={
                    field.value?.label || field.value?.name ? field.value : null
                  }
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
          </div>
        </div>
        {errors.skill && (
          <p className="text-sm text-danger-dark">{errors.skill.message}</p>
        )}

        <div className="mt-4 flex w-full">
          <Button
            title={setIsAdding ? "Save" : "Update"}
            btnClassName="px-2"
            testId="saveSkillBtn"
            type="primary"
            size="sm"
            buttonType="submit"
            isLoading={isSubmitting}
          >
            {setIsAdding ? "Save" : "Update"}
          </Button>

          <Button
            title="Cancel"
            testId="cancelSkillBtn"
            type="secondary"
            size="sm"
            btnClassName="px-2 ml-2"
            onClick={() => setIsEditState(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  candidateSkill: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.string,
    proficiency: PropTypes.string,
    type: PropTypes.string,
    skill: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};

export default Form;
