import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Creatable from "react-select/creatable";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import { toLower } from "lodash";
import PropTypes from "prop-types";

import {
  getEmployeeRange,
  getIndustryName,
  getOrganisationType,
} from "api/erp/organizationApi";
import { EMAIL_REGEX, RECAPTCHA_KEY } from "app/constants";
import { Button } from "components/atoms";
import {
  alphaNumericCharacterValidation,
  characterExactValidation,
  characterValidation,
  formattedPhoneNumberValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import phoneNumberFormatter from "helpers/phoneNumberFormatter";
import reactSelectStyle from "helpers/reactSelectStyle";
import { createOrganisationAction } from "store/thunkActions/erp/organisationThunk";

const AsyncPaginateCreatable = withAsyncPaginate(Creatable);

const Form = ({ setShowMessage }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm();

  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const [token, setToken] = useState(false);

  const onChange = () => {
    setToken(recaptchaRef.current.getValue());
    clearErrors("token");
  };

  const loadOrganisationTypeOptions = async () => {
    const { data } = await getOrganisationType();
    return {
      options: data,
    };
  };
  const loadEmployeeRangeOptions = async () => {
    const { data } = await getEmployeeRange();
    return {
      options: data,
    };
  };
  const loadIndustryNameOptions = async (search, _, { page }) => {
    const { data, meta } = await getIndustryName(page, search);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (key == "industry_id" || key == "industry_name") {
        setError("industry", { message: value[0] });
      } else if (key == "organisation_type_id") {
        setError("organisation", { message: value[0] });
      } else if (key == "employee_range_id") {
        setError("employee", { message: value[0] });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const submitOrganisationHandler = (data) => {
    // Structuring req body
    const organisationData = {
      ...data,
      organisation_type_id: data.organisation?.id,
      industry_name: data.industry?.name || data.industry?.label,
      industry_id: data.industry?.id,
      employee_range_id: data.employee?.id,
      token,
      phone: data.phone.replace("-", ""),
      alt_phone: data.alt_phone.replace("-", ""),
    };

    return dispatch(createOrganisationAction(organisationData))
      .unwrap()
      .then(() => {
        setShowMessage(true);
      })
      .catch(({ errors }) => {
        setErrorsOnFields(errors);
        recaptchaRef.current.reset();
        setToken(false);
      });
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitOrganisationHandler(data))}
    >
      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="organisation_name"
            className={`required mt-2 mb-1 block font-medium ${
              errors.name && "text-danger-main"
            }`}
          >
            Organisation Name
          </label>

          <input
            id="organisation_name"
            className={`${errors.name ? "input-error" : "input"} w-full`}
            type="text"
            placeholder="Enter organisation name"
            {...register("name", {
              required: requiredValidation(),
              maxLength: {
                value: 255,
                message: characterValidation({ limit: "255" }),
              },
            })}
          />

          {errors.name && (
            <p className="text-sm text-danger-dark">{errors.name.message}</p>
          )}
        </div>

        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="email"
            className={`required mt-2 mb-1 block font-medium ${
              errors.email && "text-danger-main"
            }`}
          >
            Organisation Email
          </label>

          <input
            id="email"
            className={`${errors.email ? "input-error" : "input"} w-full`}
            type="text"
            placeholder="Enter organisation email"
            {...register("email", {
              required: requiredValidation(),
              maxLength: {
                value: 255,
                message: characterValidation({ limit: "255" }),
              },
              pattern: { value: EMAIL_REGEX, message: "invalid email" },
            })}
          />

          {errors.email && (
            <p className="text-sm text-danger-dark">
              {toLower(errors.email.message)}
            </p>
          )}
        </div>
      </div>
      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="phone"
            className={`required mt-2 mb-1 block font-medium ${
              errors.phone && "text-danger-main"
            }`}
          >
            Contact Number
          </label>
          <div className="flex">
            <input
              disabled
              name="country-code"
              type="text"
              value="+91 "
              className="w-12 input"
            />
            <input
              id="phone"
              className={`${errors.phone ? "input-error" : "input"} w-full`}
              type="text"
              placeholder="XXXX-XXXXXX"
              {...register("phone", {
                required: requiredValidation(),
                maxLength: {
                  value: 11,
                  message: formattedPhoneNumberValidation(),
                },
                pattern: {
                  value: /^\d{4}-\d{6}$/i,
                  message: formattedPhoneNumberValidation(),
                },
              })}
              onChange={(e) => phoneNumberFormatter(e, setValue, clearErrors)}
            />
          </div>

          {errors.phone && (
            <p className="text-sm text-danger-dark">{errors.phone.message}</p>
          )}
        </div>

        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="alt_phone"
            className={`mt-2 mb-1 block font-medium ${
              errors.alt_phone && "text-danger-main"
            }`}
          >
            Alt. Contact Number
          </label>
          <div className="flex">
            <input
              disabled
              name="country-code"
              type="text"
              value="+91 "
              className="w-12 input"
            />
            <input
              id="alt_phone"
              className={`${errors.alt_phone ? "input-error" : "input"} w-full`}
              type="text"
              placeholder="XXXX-XXXXXX"
              {...register("alt_phone", {
                maxLength: {
                  value: 11,
                  message: formattedPhoneNumberValidation(),
                },
                pattern: {
                  value: /^\d{4}-\d{6}$/i,
                  message: formattedPhoneNumberValidation(),
                },
              })}
              onChange={(e) =>
                phoneNumberFormatter(e, setValue, clearErrors, "alt_phone")
              }
            />
          </div>
          {errors.alt_phone && (
            <p className="text-sm text-danger-dark">
              {errors.alt_phone.message}
            </p>
          )}
        </div>
      </div>
      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="typeSelect"
            className={`required mt-2 mb-1 block font-medium ${
              errors.organisation && "text-danger-main"
            }`}
          >
            Organisation Type
          </label>

          <Controller
            control={control}
            name="organisation"
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <AsyncPaginateCreatable
                isClearable
                isSearchable={false}
                id="typeSelect"
                menuPlacement="auto"
                className="w-full bg-white"
                styles={reactSelectStyle(errors.organisation)}
                value={field.value}
                loadOptions={loadOrganisationTypeOptions}
                onChange={(value) => field.onChange(value)}
                noOptionsMessage={() => null}
                debounceTimeout={1000}
                placeholder="Select organisation type"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name || option.label}
                formatCreateLabel={(name) => `Create "${name}"`}
                additional={{ page: 1 }}
              />
            )}
          />

          {errors.organisation && (
            <p className="text-sm text-danger-dark">
              {errors.organisation.message}
            </p>
          )}
        </div>

        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="industryNameSelect"
            className={`required mt-2 mb-1 block font-medium ${
              errors.industry && "text-danger-main"
            }`}
          >
            Industry
          </label>

          <Controller
            control={control}
            name="industry"
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <AsyncPaginate
                isClearable
                id="industryNameSelect"
                menuPlacement="auto"
                className="w-full bg-white"
                styles={reactSelectStyle(errors.industry)}
                value={field.value}
                loadOptions={loadIndustryNameOptions}
                onChange={(value) => field.onChange(value)}
                noOptionsMessage={() => "No options"}
                debounceTimeout={1000}
                placeholder="Select industry"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name || option.label}
                additional={{ page: 1 }}
              />
            )}
          />

          {errors.industry && (
            <p className="text-sm text-danger-dark">
              {errors.industry.message}
            </p>
          )}
        </div>
      </div>

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="employeeRangeSelect"
            className={`required mt-2 mb-1 block font-medium ${
              errors.employee_range_id && "text-danger-main"
            }`}
          >
            Number of Employees
          </label>

          <Controller
            control={control}
            name="employee"
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <AsyncPaginateCreatable
                isClearable
                isSearchable={false}
                id="employeeRangeSelect"
                menuPlacement="auto"
                className="w-full bg-white"
                styles={reactSelectStyle(errors.employee)}
                value={field.value}
                loadOptions={loadEmployeeRangeOptions}
                onChange={(value) => field.onChange(value)}
                noOptionsMessage={() => null}
                debounceTimeout={1000}
                placeholder="Select number of employees"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.range || option.label}
                formatCreateLabel={(name) => `Create "${name}"`}
                additional={{ page: 1 }}
              />
            )}
          />

          {errors.employee && (
            <p className="text-sm text-danger-dark">
              {errors.employee.message}
            </p>
          )}
        </div>

        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="registration_number"
            className={`mt-2 mb-1 block font-medium ${
              errors.registration_number && "text-danger-main"
            }`}
          >
            Corporate Identification Number (CIN)
          </label>

          <input
            id="registration_number"
            className={`${
              errors.registration_number ? "input-error" : "input"
            } w-full`}
            type="text"
            placeholder="Enter CIN"
            {...register("registration_number", {
              maxLength: {
                value: 21,
                message: characterExactValidation({ length: "21" }),
              },
              minLength: {
                value: 21,
                message: characterExactValidation({ length: "21" }),
              },
              pattern: {
                value: /^[A-Za-z0-9]*$/i,
                message: alphaNumericCharacterValidation(),
              },
            })}
          />

          {errors.registration_number && (
            <p className="text-sm text-danger-dark">
              {errors.registration_number.message}
            </p>
          )}
        </div>
      </div>

      <div className="mt-2">
        <div className=" w-full flex-col sm:w-5/12">
          <label
            htmlFor="gstNumber"
            className={`mt-2 mb-1 block font-medium ${
              errors.gstin && "text-danger-main"
            }`}
          >
            GSTIN
          </label>

          <input
            id="gstNumber"
            className={`${errors.gstin ? "input-error" : "input"} w-full`}
            type="text"
            placeholder="Enter GST number"
            {...register("gstin", {
              maxLength: {
                value: 15,
                message: characterExactValidation({ length: "15" }),
              },
              minLength: {
                value: 15,
                message: characterExactValidation({ length: "15" }),
              },
              pattern: {
                value: /^[A-Za-z0-9]*$/i,
                message: alphaNumericCharacterValidation(),
              },
            })}
          />

          {errors.gstin && (
            <p className="text-sm text-danger-dark">{errors.gstin.message}</p>
          )}
        </div>
      </div>

      {RECAPTCHA_KEY ? (
        <div className="mt-3 flex flex-col items-center">
          <div className="flex-start">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RECAPTCHA_KEY}
              size="normal"
              onChange={onChange}
            />
            {errors.token && (
              <p className="text-sm text-danger-dark">{errors.token.message}</p>
            )}
          </div>
        </div>
      ) : (
        <div className="flex text-danger-main  gap-1 mt-6 justify-start">
          <div>
            <FaInfoCircle className="text-danger-main" />
          </div>
          <span className="text-xs">
            Currently, we are facing some issues while creating organisation.
            Please try again later!
          </span>
        </div>
      )}

      <div className="mt-6 mb-2 flex w-full">
        <Button
          title="Submit"
          testId="saveOrganisationBtn"
          type={token ? "primary" : "disabled"}
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </div>

      <p className="mt-3 h-auto w-full rounded-full py-2 text-xs text-gray-dark">
        Already have an account?
        <Link
          title="Login"
          className="ml-1 text-secondary-main hover:text-secondary-dark hover:underline"
          to={`${
            window.location.search
              ? `/login${window.location.search}`
              : "/login"
          }`}
        >
          <span className="font-bold">Login</span>
        </Link>
      </p>
    </form>
  );
};

Form.propTypes = {
  setShowMessage: PropTypes.func,
};

export default Form;
