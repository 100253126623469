import PropTypes from "prop-types";

import { formatPhoneNumber } from "helpers/formatPhoneNumber";

const FormattedContactNumber = ({ number }) => {
  return <a href={`tel://+91${number}`}>+91 {formatPhoneNumber(number)}</a>;
};

FormattedContactNumber.propTypes = {
  number: PropTypes.string.isRequired,
};

export default FormattedContactNumber;
