import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { getCandidateDetails } from "api/erp/jobApi";
import Alert from "components/common/Alert";
import { SITE_TITLE } from "constants/common";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import BasicInfo from "./BasicInfo";
import ContactDetail from "./ContactDetails";
import EducationQualifications from "./Education";
import WorkExperiences from "./Experience";
import Interests from "./Interests";
import Languages from "./Languages";
import Projects from "./Projects";
import References from "./References";
import Skills from "./Skills";

const CandidateProfile = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [candidateData, setCandidateData] = useState({});
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (candidateData) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              { name: "Jobs", link: "/jobs" },
              { name: candidateData.job_title, link: `/jobs/${params.uuid}` },
            ],
            currentPageName: params.candidate_id,
          },
        })
      );
    }
  }, [updateHeaderTitle, params.uuid, params.candidate_id, candidateData]);

  useEffect(() => {
    const jobUuid = params.uuid;
    const candidateId = params.candidate_id;

    getCandidateDetails(jobUuid, candidateId)
      .then((response) => setCandidateData(response.data))
      .catch(() => setAlert(true));
  }, []);

  const getTitle = () => {
    if (!isEmpty(candidateData)) {
      return candidateData.name;
    }
    return "Candidate Answers";
  };

  return (
    <div className="  h-full md:px-4 ">
      <Helmet>
        <title>{`${getTitle()} - ${SITE_TITLE}`}</title>
      </Helmet>
      <Alert
        type="danger"
        isAlertTriggered={alert}
        setIsAlertTriggered={setAlert}
        message="Some error occured"
      />

      {candidateData && (
        <div>
          <BasicInfo candidate={candidateData} />

          <div className="mt-2 grid h-full min-h-screen grid-cols-1 gap-0 px-2 pt-2 pb-8 md:rounded-t-lg md:px-8 lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-7">
              <WorkExperiences
                workExperiences={candidateData.work_experiences}
                modalView={false}
              />
              <EducationQualifications
                educationQualifications={candidateData.educations}
                modalView={false}
              />
              <ContactDetail contactDetail={candidateData.address} />
              <References referenceDetails={candidateData.references} />
            </div>
            <div className="lg:col-span-5">
              <Skills
                candidateSkills={candidateData.candidate_skills}
                modalView={false}
              />
              <Projects projects={candidateData.projects} modalView={false} />
              <Languages
                candidateLanguages={candidateData.candidate_languages}
                modalView={false}
              />
              <Interests interests={candidateData.interests} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateProfile;
