import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsPersonPlusFill, BsThreeDots } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import classNames from "classnames";
import { format } from "date-fns";
import moment from "moment";
import PropTypes from "prop-types";

import { updateJobCandidateStatus } from "api/erp/jobApi";
import { AA_CANDIDATE_STATUS, ALERT_TYPE } from "app/constants";
import { Avatar, Button, ThreeDotMenu } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import { Modal } from "components/molecules";
import { getStatusSelectDisabled } from "helpers/pipeline/statusSelectDisabled";
import useAlert from "hooks/useAlert";

import CandidateStageHistory from "../CandidateStageData/CandidateStageHistory";

const FormattedTime = (dateArg) =>
  dateArg && (
    <>
      <span className="mb-1 block">
        {format(new Date(dateArg), "dd/MM/yyyy")}
      </span>
      <span className="block">{format(new Date(dateArg), "h:mm a")}</span>
    </>
  );

const AllApplicantsCandidates = ({
  candidate,
  currentStage,
  setIsEditState,
  setCandidateID,
  getCandidateStageData,
  onCheck,
  isUserRoleAllowed,
  isJobClosed,
  showSidePanel,
  openSidePanel,
}) => {
  const dropdownMenuRef = useRef(null);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const showAlert = useAlert();
  const { control } = useForm();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCandidateHistoryModal, setShowCandidateHistoryModal] =
    useState(false);

  const { selectedIds, selectedObjectType, allSelected } = useSelector(
    (state) => state.miscellaneous
  );

  const toggleExamHistoryModal = () => {
    setShowCandidateHistoryModal((prev) => !prev);
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  const submitHandler = (data) => {
    if (data === null) {
      return;
    }

    const finalData = {
      candidate_job_ids: [candidate.id.toString()],
      status: data.value,
    };

    updateJobCandidateStatus(params.uuid, finalData)
      .then(({ meta }) => {
        getCandidateStageData();
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
      })
      .catch((errors) => showAlert("danger", errors.response.data.message));
  };

  const willingToRelocateText = () => {
    if (candidate?.address?.willing_to_relocate === null) {
      return "";
    }
    if (candidate?.address?.willing_to_relocate) {
      return "Willing to relocate";
    }
    return "Unwilling to relocate";
  };

  return (
    <>
      <tr
        className={classNames(
          "my-4 table-row border-y hover:bg-gray-lighter",
          allSelected ||
            (selectedObjectType === "candidate" &&
              selectedIds.includes(candidate.id.toString())) ||
            showSidePanel
            ? "bg-primary-lighter"
            : "bg-white"
        )}
        data-testid={`candidate-id-${candidate.id}`}
      >
        <td
          className={classNames(
            "table-data z-10 p-4 align-top text-slate-500 md:sticky md:left-0",
            allSelected ||
              (selectedObjectType === "candidate" &&
                selectedIds.includes(candidate.id.toString())) ||
              showSidePanel
              ? "bg-primary-lighter"
              : "bg-white"
          )}
        >
          {isUserRoleAllowed && (
            <div className="flex flex-col-reverse gap-10">
              {isUserRoleAllowed && !isJobClosed && (
                <div
                  className="relative mr-2 justify-end"
                  ref={dropdownMenuRef}
                >
                  <BsThreeDots
                    title="View Options"
                    role="button"
                    onClick={() => setShowDropdown((dropdown) => !dropdown)}
                    data-testid="three-dot-dropdown"
                    className="text-2xl text-gray-600 hover:cursor-pointer hover:text-primary-light"
                  />

                  {showDropdown && (
                    <ThreeDotMenu
                      menuRef={dropdownMenuRef}
                      menuOptions={[
                        {
                          dataTestId: "add-to-stage",
                          onClick: () => {
                            setIsEditState(true);
                            setCandidateID(candidate.id);
                            setShowDropdown(false);
                          },
                          icon: (
                            <BsPersonPlusFill className="mr-2 mt-[1.5px] text-base" />
                          ),
                          text: "Add to",
                        },
                      ]}
                      posFromTop="top-[-3rem]"
                      posFromRight="text-black-primary"
                    />
                  )}
                </div>
              )}

              <input
                className="h-5 w-4 items-center hover:cursor-pointer focus:outline-none"
                data-testid={`candidate-${candidate?.id}-checkbox`}
                type="checkbox"
                checked={
                  allSelected ||
                  (selectedObjectType === "candidate" &&
                    selectedIds.includes(candidate.id.toString()))
                }
                value={candidate?.id}
                onChange={(e) => onCheck(e.target)}
                id="flexCheckDefault"
              />
            </div>
          )}
        </td>

        <td
          className={classNames(
            "table-data w-10 py-4 px-1 pl-2 align-top text-primary-main md:sticky md:left-16 break-words",
            allSelected ||
              (selectedObjectType === "candidate" &&
                selectedIds.includes(candidate.id.toString())) ||
              showSidePanel
              ? "bg-primary-lighter"
              : "bg-white"
          )}
        >
          <button
            title={candidate.id}
            id="candidate-id"
            className="break-all text-base text-primary-main hover:underline"
            onClick={() => openSidePanel(candidate?.id)}
          >
            {candidate.id}
          </button>
        </td>

        <td
          className={classNames(
            "table-data py-4 align-top text-slate-500 md:sticky md:left-[9.55rem]",
            allSelected ||
              (selectedObjectType === "candidate" &&
                selectedIds.includes(candidate.id.toString())) ||
              showSidePanel
              ? "bg-primary-lighter"
              : "bg-white"
          )}
        >
          <div className="mb-2 flex items-center gap-3">
            <div className="  mb-1  h-10  w-10 rounded-full ring-2 ring-primary-dark ring-offset-2 ">
              {candidate?.avatar?.thumb ? (
                <div className="h-10 w-10">
                  <Avatar
                    src={candidate.avatar?.thumb}
                    height={10}
                    width={10}
                  />
                </div>
              ) : (
                <CharacterAvatar
                  char={candidate.name.charAt(0)}
                  width={10}
                  height={10}
                  textSize="text-[1.5rem]"
                />
              )}
            </div>
            <span data-testid="candidate_data" className="block pr-2 pb-1 ">
              <button
                title={candidate?.name}
                id="candidate-name"
                className="break-words text-start text-base text-primary-main hover:underline"
                onClick={() => openSidePanel(candidate?.id)}
              >
                {candidate?.name}
              </button>
            </span>
          </div>

          <div className="mr-4" data-testid="status-select">
            <Controller
              name="status"
              control={control}
              type="submit"
              render={({ field }) => (
                <Select
                  name="status"
                  options={AA_CANDIDATE_STATUS}
                  id="status_select"
                  className={classNames(
                    searchParams.get("lobby") === "PLACED" ? "w-56" : "w-44",
                    {
                      "2xl:w-44":
                        searchParams.get("lobby") === "ALL_APPLICANTS",
                    },
                    { "lg:w-60": searchParams.get("lobby") === "PLACED" }
                  )}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isDisabled={
                    candidate?.placed?.loi_sent ||
                    getStatusSelectDisabled(candidate, searchParams) ||
                    !isUserRoleAllowed ||
                    isJobClosed
                  }
                  defaultValue={AA_CANDIDATE_STATUS.find(
                    ({ value }) => value === candidate?.status
                  )}
                  placeholder="Select Status"
                  value={field.value}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      borderColor: AA_CANDIDATE_STATUS.find(
                        ({ value }) => value === candidate?.status
                      )?.color,
                      borderRadius: "15px",
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      color: AA_CANDIDATE_STATUS.find(
                        ({ value }) => value === candidate?.status
                      )?.color,
                    }),
                  }}
                  onChange={(data) => {
                    field.onChange(data);
                    submitHandler(data);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />

            {candidate?.placed &&
              (getStatusSelectDisabled(candidate, searchParams) ? (
                <div className="pt-3 text-sm text-primary-dark">
                  Placed from &nbsp;
                  <span className="font-semibold">
                    {candidate.placed.stage_name}
                  </span>
                </div>
              ) : (
                candidate?.placed?.loi_sent && (
                  <div className="text-primary-main font-medium pt-2">
                    LOI Sent
                  </div>
                )
              ))}
          </div>

          <div className="pt-1">
            {!currentStage && isUserRoleAllowed && !isJobClosed && (
              <span>
                <Button
                  title="Show Journey"
                  onClick={toggleExamHistoryModal}
                  type="tertiary"
                  btnName="Show Journey"
                />
              </span>
            )}
          </div>
        </td>

        <td className="pr-2 py-4 align-top pl-6">
          <span className=" mt-1 text-sm text-gray-main">
            {candidate.applied_at && FormattedTime(candidate.applied_at)}
          </span>
        </td>

        <td className="py-4 px-2 align-top">
          {candidate?.portfolio_url && (
            <a
              title="Portfolio"
              target="_blank"
              rel="noreferrer"
              className="italic text-primary-light hover:underline"
              href={candidate.portfolio_url}
            >
              Portfolio
            </a>
          )}
        </td>

        <td
          className={classNames(
            "py-4 px-2 align-top capitalize leading-5 text-gray-dark w-32"
          )}
        >
          {candidate?.educations.length > 0 && (
            <p className="capitalize text-gray-main">
              {candidate.educations?.[0]?.qualification_name
                ? candidate.educations[0]?.qualification_name
                : ""}
              {candidate?.educations.length > 0 ? " , " : ""}
              {candidate?.educations.length > 0
                ? candidate.educations[0]?.college.name.toLowerCase()
                : ""}
              {candidate?.educations.length > 1 && (
                <Button
                  title="Education Details"
                  type="tertiary"
                  onClick={() => openSidePanel(candidate?.id, "educationRef")}
                  btnName={`+ ${candidate.educations.length - 1}`}
                />
              )}
            </p>
          )}
        </td>

        <td
          className={classNames(
            "py-4 px-2 align-top capitalize leading-5 text-gray-dark"
          )}
        >
          <p className="text-gray-main">
            {candidate?.work_experiences?.[0]?.title ? (
              <>
                {" "}
                {candidate.work_experiences[0]?.title.substring(0, 100)} |{" "}
                {candidate.work_experiences[0]?.company_name.substring(0, 100)}{" "}
                |{" "}
                {candidate.work_experiences[0]?.start_at
                  ? ` ${moment().diff(
                      candidate.work_experiences[0]?.start_at,
                      "years"
                    )} Year+`
                  : ""}
              </>
            ) : (
              ""
            )}
            &nbsp;
            {candidate?.work_experiences.length > 1 && (
              <>
                ,
                <Button
                  title="Experience Details"
                  type="tertiary"
                  onClick={() =>
                    openSidePanel(candidate?.id, "workExperienceRef")
                  }
                  btnName={`+ ${candidate.work_experiences.length - 1}`}
                />
              </>
            )}
          </p>
        </td>

        <td
          className={classNames(
            "py-4 px-2 align-top capitalize leading-5 text-gray-dark "
          )}
        >
          <div className="capitalize" data-testid="skill">
            {candidate?.candidate_skills?.length > 0
              ? candidate?.candidate_skills
                  .slice(0, 4)
                  .map((candidateSkill) => candidateSkill.skill.name)
                  .join(", ")
              : ""}
            {candidate?.candidate_skills?.length <= 4 &&
              candidate?.candidate_skills?.length > 0 && (
                <div className="">
                  <Button
                    btnClassName="mt-1 text-left"
                    title="Skills"
                    type="tertiary"
                    onClick={() => openSidePanel(candidate?.id, "skillsRef")}
                    btnName="Show details"
                  />
                </div>
              )}
            {candidate?.candidate_skills?.length > 4 && (
              <Button
                className="mt-1"
                title="Skills"
                type="tertiary"
                onClick={() => openSidePanel(candidate?.id, "skillsRef")}
                btnName={`+ ${candidate.candidate_skills.length - 4}`}
              />
            )}
          </div>
        </td>

        <td
          className={classNames(
            "py-4 px-2 align-top capitalize leading-5 text-gray-dark"
          )}
        >
          {candidate?.projects?.length > 0 && (
            <>
              <p className="capitalize text-gray-main">
                {candidate?.projects?.[0]?.title ? (
                  <a
                    title="Project"
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(
                      candidate?.projects[0]?.project_url &&
                        "text-primary-light hover:underline"
                    )}
                    href={candidate?.projects[0]?.project_url}
                  >
                    {candidate?.projects[0]?.title}
                  </a>
                ) : (
                  ""
                )}
                {candidate?.projects?.length > 1 && (
                  <>
                    {", "}
                    <a
                      title="Project"
                      target="_blank"
                      rel="noreferrer"
                      className={classNames(
                        candidate?.projects[1]?.project_url &&
                          "text-primary-light hover:underline"
                      )}
                      href={candidate?.projects[1]?.project_url}
                    >
                      {candidate?.projects[1]?.title}
                    </a>
                  </>
                )}

                {candidate?.projects?.length > 2 && (
                  <span className="ml-1">
                    + {candidate?.projects?.length - 2}
                  </span>
                )}
              </p>
              <Button
                btnClassName="mt-1 text-left"
                title="Project Details"
                type="tertiary"
                onClick={() => openSidePanel(candidate?.id, "projectsRef")}
                btnName="Show Details"
              />
            </>
          )}
        </td>

        <td
          className={classNames(
            "py-4 px-2 align-top capitalize leading-5 text-gray-dark"
          )}
        >
          <div className="capitalize">
            {candidate?.candidate_languages?.length > 0
              ? candidate?.candidate_languages
                  .slice(0, 4)
                  .map((candidateLanguage) => candidateLanguage.language.name)
                  .join(", ")
              : ""}
            {candidate?.candidate_languages?.length <= 4 &&
              candidate?.candidate_languages?.length > 0 && (
                <div className="">
                  <Button
                    btnClassName="mt-1 text-left"
                    title="Languages"
                    type="tertiary"
                    onClick={() => openSidePanel(candidate?.id, "languagesRef")}
                    btnName="Show details"
                  />
                </div>
              )}
            {candidate?.candidate_languages?.length > 4 && (
              <Button
                title="Languages"
                type="tertiary"
                btnName={`+ ${candidate.candidate_languages.length - 4}`}
                onClick={() => openSidePanel(candidate?.id, "languagesRef")}
              />
            )}
          </div>
        </td>

        <td
          className={classNames(
            "py-4 px-2 align-top capitalize leading-5 text-gray-dark"
          )}
        >
          {candidate?.address && (
            <>
              <h4 className="text-sm font-normal leading-5">
                {candidate?.address?.address_line1}
              </h4>
              <p className="text-sm leading-5">
                <span> {candidate?.address?.address_line2}</span>
              </p>
              <p className="flex-rows mt-2 flex text-sm">
                {candidate?.address?.city}, {candidate?.address?.state},{" "}
                {candidate?.address?.country}, {candidate?.address?.postal_code}
              </p>
              <div className="mt-2 flex  text-sm text-gray-500">
                <span className=" text-secondary-alertDark">
                  {willingToRelocateText()}
                </span>
              </div>
            </>
          )}
        </td>
      </tr>

      {showCandidateHistoryModal && (
        <Modal
          title="Candidate's History"
          scrollClass="overflow-y-auto max-h-[28rem]"
          handleCancel={toggleExamHistoryModal}
          onOutsideClickHandler={toggleExamHistoryModal}
        >
          <CandidateStageHistory candidate={candidate} job_uuid={params.uuid} />
        </Modal>
      )}
    </>
  );
};

AllApplicantsCandidates.defaultProps = {
  showSidePanel: false,
  openSidePanel: null,
};

AllApplicantsCandidates.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    job_uuid: PropTypes.string,
    name: PropTypes.string,
    educations: PropTypes.array,
    candidate_skills: PropTypes.array,
    work_experiences: PropTypes.array,
    designation: PropTypes.string,
    current_location: PropTypes.string,
    status: PropTypes.string,
    no_of_positions: PropTypes.number,
    exams: PropTypes.array,
    interviews: PropTypes.array,
    portfolio_url: PropTypes.string,
    placed: PropTypes.shape({
      stage_id: PropTypes.number,
      stage_name: PropTypes.string,
      loi_sent: PropTypes.bool,
    }),
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
    candidate_languages: PropTypes.array,
    applied_at: PropTypes.string,
    projects: PropTypes.array,
    address: PropTypes.shape({
      id: PropTypes.number,
      address_line1: PropTypes.string,
      address_line2: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      permanent_address: PropTypes.bool,
      state: PropTypes.string,
      postal_code: PropTypes.string,
      willing_to_relocate: PropTypes.bool,
    }),
  }),
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  setCandidateID: PropTypes.func,
  getCandidateStageData: PropTypes.func,
  onCheck: PropTypes.func,
  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
  showSidePanel: PropTypes.bool,
  openSidePanel: PropTypes.func,
};

export default AllApplicantsCandidates;
