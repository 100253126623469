import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setEmploymentTypes } from "store/slices/erp/employmentTypesSlice";
import { getWorkExperiencesAction } from "store/thunkActions/candidate/workExperienceThunk";

import CardContainer from "../Common/CardContainer";

import Form from "./Form";
import WorkExperience from "./WorkExperience";

const WorkExperiences = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { workExperiences } = useSelector((state) => state.workExperiences);

  useEffect(() => {
    dispatch(getWorkExperiencesAction());
    dispatch(setEmploymentTypes());
  }, []);

  return (
    <CardContainer
      title="Experience"
      addEventButtonTitle="Add Experience"
      addEventButtonTestId="add-experience-btn"
      setIsAdding={setIsAdding}
    >
      {!workExperiences.length && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on the plus icon to add experience
        </span>
      )}

      {workExperiences.map((workExperience) => {
        return (
          <div
            key={workExperience.id}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <WorkExperience workExperience={workExperience} />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsAdding={setIsAdding} setIsEditState={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default WorkExperiences;
