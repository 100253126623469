import classNames from "classnames";
import PropTypes from "prop-types";

import { PROFICIENCY_LEVELS } from "app/constants";
import VisualRating from "components/atoms/VisualRating";

const Skills = ({ candidateSkills, modalView, sidePanelView }) => {
  const fillUpMarkerList = candidateSkills?.map((candidateSkill) => {
    return PROFICIENCY_LEVELS.findIndex(
      (item) => item.value === candidateSkill.proficiency
    );
  });
  return (
    <div
      className={classNames(
        "mx-auto w-full bg-white",
        !modalView && "mt-4 rounded-lg border border-gray-light p-4"
      )}
    >
      <h3
        className={classNames(
          "font-normal",
          modalView ? "mb-1 text-xl" : "mb-4 text-2xl"
        )}
      >
        Skills
      </h3>
      <div className="text-sm">
        {!candidateSkills?.length && (
          <span className="italic text-gray-main">No skills to show</span>
        )}
        {candidateSkills?.map((candidateSkill, index) => {
          return (
            <div
              key={index}
              className={classNames(
                "border-b border-gray-lighterShade2 py-4 last:border-b-0 overflow-x-auto",
                sidePanelView ? "sm:overflow-x-auto" : "sm:overflow-x-hidden"
              )}
            >
              <div className="text-md flex flex-col gap-3 pt-2 pr-2 sm:flex-row sm:pt-0 sm:pr-0 sm:gap-0 font-normal sm:items-center leading-5 w-full">
                <div
                  className={classNames(
                    "break-words",
                    sidePanelView ? "ml-0" : "ml-4",
                    {
                      "sm:w-[30%]": fillUpMarkerList[index] >= 0,
                    }
                  )}
                >
                  {candidateSkill.skill.name}
                </div>
                {fillUpMarkerList[index] >= 0 && (
                  <VisualRating fillUpMarker={fillUpMarkerList[index]} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Skills.defaultProps = {
  sidePanelView: false,
};

Skills.propTypes = {
  candidateSkills: PropTypes.array,
  modalView: PropTypes.bool,
  sidePanelView: PropTypes.bool,
};

export default Skills;
