import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

const usePreviousTab = () => {
  const { currentTab } = useParams();
  const previousTab = useRef(currentTab);

  useEffect(() => {
    if (previousTab.current === null) {
      previousTab.current = currentTab;
    }
  }, [currentTab]);

  const setPreviousTab = (updatedTab) => {
    previousTab.current = updatedTab;
  };

  return { previousTab, setPreviousTab, currentTab };
};

export default usePreviousTab;
