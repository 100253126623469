import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";

const CandidateProject = ({ projects }) => {
  return (
    <>
      <div className="resume-section-heading">PROJECTS</div>
      {projects.map((project) => {
        return (
          <div
            className={classnames("resume-section-content", {
              "lg:pb-3 pb-2": projects.length > 1,
            })}
            style={{
              textAlign: "justify",
              color: "rgb(100 100 100)",
              wordBreak: "break-word",
            }}
            key={project.id}
          >
            <div className="flex lg:flex-row flex-col justify-between">
              <div className="text-base font-semibold">{project.title}</div>
              <div className="lg:w-2/6 w-full flex lg:justify-end justify-start text-gray-darker">
                {moment(project.start_at).format("YYYY")} -{" "}
                {project.is_ongoing
                  ? "Present"
                  : moment(project.end_at).format("YYYY")}
              </div>
            </div>
            <div className="pt-1">
              <RenderParsedHTML content={project.description || ""} />
            </div>
          </div>
        );
      })}
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

CandidateProject.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      start_at: PropTypes.string,
      end_at: PropTypes.string,
      is_ongoing: PropTypes.bool,
      project_url: PropTypes.string,
    })
  ),
};

export default CandidateProject;
