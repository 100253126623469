import { FormattedDate } from "react-intl";

const FormatDateAndTime = ({ date }) => {
  return (
    date && (
      <div>
        <FormattedDate
          value={date}
          year="numeric"
          month="numeric"
          day="numeric"
        />
      </div>
    )
  );
};

export default FormatDateAndTime;
