import { BiWalletAlt } from "react-icons/bi";
import { CgOrganisation } from "react-icons/cg";
import { MdLocationOn, MdOutlineHomeWork } from "react-icons/md";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";

import { ICON_SIZE_HASH } from "app/constants";
import { Avatar } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";

const SuggestedJobs = (jobsData) => {
  const data = jobsData?.job;
  const jobs = data?.slice(0, 6).map((job) => {
    return (
      <Link
        to={`/jobs/${job.uuid}/details`}
        key={job.uuid}
        className={classNames(
          "mb-3 font-sans bg-white min-h-[80px] shadow-md px-4 py-3 border  rounded-2xl text-sm  leading-normal relative max-h-100 overflow-y-auto hover:bg-secondary-hover",
          `${data.length === 1 ? "w-full" : "sm:w-[49%]"}`
        )}
      >
        <div className="flex items-start gap-1">
          <div>
            {job?.organisation.logo?.thumb ? (
              <Avatar
                height={16}
                width={16}
                src={job.organisation.logo?.thumb}
                alt={job.organisation}
                shape="rounded-md"
                border="border-0"
                optionalClass="mr-6 aspect-square"
              />
            ) : (
              <CharacterAvatar
                char={job?.organisation.name.charAt(0)}
                width={14}
                height={14}
                bgColor="bg-primary-main"
                textColor="text-white"
                shape="rounded-md"
                textSize="text-[2rem]"
                optionalClass="py-2 px-6 mr-6"
              />
            )}
          </div>

          <div className="flex-grow w-3/4">
            <div className="font-semibold text-base text-primary-light">
              {job?.title}
            </div>

            <div className="text-base pt-2 font-medium text-gray-dark">
              <CgOrganisation
                className="inline mr-2 mb-1"
                size={ICON_SIZE_HASH.sm}
                data-tooltip-content="Company Name"
                data-tooltip-id={`company-name${job.uuid}`}
              />
              {job?.organisation.name}
            </div>
            <div className="mb-1 flex flex-wrap">
              {job?.location && (
                <div className="flex mr-4" data-testid="location-icon">
                  <span className="italic font-sans text-sm text-gray-main">
                    <MdLocationOn
                      className="inline mr-2 mb-1"
                      size={ICON_SIZE_HASH.sm}
                      data-tooltip-content="Job Location"
                      data-tooltip-id={`jobLocation-${job.uuid}`}
                    />
                  </span>
                  <span className="italic font-sans text-sm text-gray-main">
                    {job.location}
                  </span>
                </div>
              )}

              {job?.location_type && (
                <div className="flex mr-4" data-testid="location-type-icon">
                  <span className="text-sm italic text-gray-main">
                    <MdOutlineHomeWork
                      className="inline mr-2 mb-1"
                      size={ICON_SIZE_HASH.sm}
                      data-tooltip-content="Location Type"
                      data-tooltip-id={`locationType-${job.uuid}`}
                    />
                  </span>
                  <span className="text-sm italic text-gray-main">
                    {job?.location_type}
                  </span>
                </div>
              )}
              {job.ctc != 0 && (
                <div className="flex mr-4" data-testid="ctc-icon">
                  <span className="text-sm italic text-gray-main">
                    <BiWalletAlt
                      className="inline mb-1 mr-2"
                      size={ICON_SIZE_HASH.sm}
                      data-tooltip-content="CTC"
                      data-tooltip-id={`ctc-${job.uuid}`}
                    />
                  </span>
                  <span className="text-sm italic text-gray-main">
                    {job?.ctc}
                  </span>
                </div>
              )}
            </div>
            <div className="flex justify-between text-black-shade1 flex-shrink-0">
              <span className="ml-auto">
                {formatDistanceToNow(
                  new Date(job?.status_updated_at || job?.created_at),
                  { addSuffix: true }
                )}
              </span>
            </div>
          </div>
        </div>
        <Tooltip id={`company-name${job.uuid}`} variant="dark" />
        <Tooltip id={`ctc-${job.uuid}`} variant="dark" />
        <Tooltip id={`locationType-${job.uuid}`} variant="dark" />
        <Tooltip id={`jobLocation-${job.uuid}`} variant="dark" />
      </Link>
    );
  });

  return (
    !!data?.length && (
      <div>
        <div className="flex justify-between">
          <h2 className="py-2 text-xl font-semibold">Suggested Jobs</h2>
          {data.length > 6 && (
            <Link
              to="/jobs"
              className=" m-4 text-md text-primary-light hover:underline"
              title="View all"
            >
              View all
            </Link>
          )}
        </div>
        <div className="flex flex-col flex-wrap justify-between pb-4 text-sm sm:flex-row lg:pb-0">
          {jobs}
        </div>
      </div>
    )
  );
};

export default SuggestedJobs;
