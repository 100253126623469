import { useCallback, useEffect, useRef, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import Webcam from "react-webcam";
import PropTypes from "prop-types";

import { uploadReferenceImage } from "api/candidate/profileApi";
import { ALERT_TYPE, ICON_SIZE_HASH } from "app/constants";
import { ActivityIndicator, Button } from "components/atoms";
import Modal from "components/molecules/Modal";
import blobImage from "helpers/blobImage";
import cameraPermissionStatus from "helpers/cameraPermissionStatus";
import NavigatorPermissionChangeEvent from "helpers/NavigatorPermissionChangeEvent";
import useAlert from "hooks/useAlert";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const RefImageButton = ({ currentUser, insideRefModal }) => {
  const showAlert = useAlert();
  const dispatch = useDispatch();

  const [isRefUploading, setIsRefUploading] = useState(false);
  const [captureModal, setCaptureModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [refImageSrc, setRefImgSrc] = useState(null);

  const webcamRef = useRef(null);

  // create a capture function
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setRefImgSrc(imageSrc);
  }, [webcamRef]);

  const cleanUpRefImageVars = () => {
    setIsRefUploading(false);
    setRefImgSrc(null);
  };

  const handleUploadReferenceImage = async (base64Image, image) => {
    setIsRefUploading(true);

    const formData = new FormData();
    formData.append("reference_image", image);
    formData.append("image_b64_string", base64Image);

    uploadReferenceImage(formData)
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        dispatch(setCandidateUserAction());
        cleanUpRefImageVars();
        setCaptureModal(false);
      })
      .catch(({ response }) => {
        const {
          data: {
            meta: { message_type: messageType, message },
          },
        } = response;
        showAlert(ALERT_TYPE[messageType], `${message}. Please try again.`);
        cleanUpRefImageVars();
      });
  };

  const handleCancel = () => {
    setRefImgSrc(null);
    setCaptureModal(false);
  };

  const renderWebcam = () => {
    if (isRefUploading) {
      return <ActivityIndicator />;
    }
    if (refImageSrc) {
      return <img src={refImageSrc} alt="webcam" />;
    }
    return <Webcam ref={webcamRef} screenshotFormat="image/png" />;
  };

  const getCaptureButtonTitle = () => {
    if (refImageSrc) {
      return "Upload";
    }
    return "Capture";
  };

  // this api will work on both firefox and chrome
  useEffect(() => {
    NavigatorPermissionChangeEvent(setCaptureModal, showAlert);
  }, []);

  return (
    <>
      {!insideRefModal &&
      currentUser?.reference_images !== undefined &&
      currentUser?.reference_images[0] ? (
        <Button
          title="View Ref. Image"
          btnName="View Ref. Image"
          btnClassName="mt-8 px-0 sm:w-36 break-normal"
          type="tertiary"
          size="smResponsive"
          customWidth={32}
          onClick={() => setViewModal(true)}
        />
      ) : (
        <Button
          title="Upload Ref. Image"
          btnName="Upload Ref. Image"
          btnClassName="mt-8 px-2 w-full sm:w-40"
          type="primary"
          size="sm"
          customWidth={36}
          onClick={() => cameraPermissionStatus(setCaptureModal, showAlert)}
        />
      )}

      {(insideRefModal ||
        (currentUser?.reference_images !== undefined &&
          !currentUser?.reference_images[0])) && (
        <div className="mb-5 flex gap-3 break-normal p-2 text-warning-main opacity-80">
          <div>
            <BsExclamationCircleFill
              size={ICON_SIZE_HASH.md}
              className="h-6 w-6"
            />
          </div>
          <div className="text-sm">Required to start camera-enabled exams.</div>
        </div>
      )}
      {captureModal && (
        <Modal
          title="Upload Reference Image"
          onOutsideClickHandler={handleCancel}
          handleCancel={handleCancel}
          customModalWidth="700px"
        >
          {!isRefUploading && (
            <div className="mb-5 flex items-center justify-center gap-3 p-2 text-primary-main opacity-80">
              <div>
                <BsExclamationCircleFill
                  size={ICON_SIZE_HASH.md}
                  className="h-6 w-6"
                />
              </div>
              <div className="text-sm">
                {refImageSrc
                  ? "Image once uploaded cannot be edited or deleted"
                  : "Please look into the camera and place your face in the center"}
              </div>
            </div>
          )}
          <div className="container"> {renderWebcam()} </div>

          <div className="mt-4 py-3 sm:flex sm:flex-row-reverse ">
            <Button
              title={getCaptureButtonTitle()}
              btnClassName="mt-3 ml-2 sm:mt-0"
              type={isRefUploading ? "disabled" : "primary"}
              size="smResponsive"
              onClick={() =>
                refImageSrc
                  ? blobImage(
                      refImageSrc,
                      handleUploadReferenceImage,
                      currentUser
                    )
                  : capture()
              }
            >
              {refImageSrc ? "Upload" : "Capture"}
            </Button>
            {refImageSrc && (
              <Button
                title="Retake"
                btnClassName="mt-3 sm:mt-0"
                type={isRefUploading ? "disabled" : "secondary"}
                size="smResponsive"
                onClick={() => setRefImgSrc(null)}
              >
                Retake
              </Button>
            )}
          </div>
        </Modal>
      )}

      {viewModal && (
        <Modal
          title="Reference image"
          onOutsideClickHandler={() => setViewModal(false)}
          handleCancel={() => setViewModal(false)}
          customModalWidth="700px"
        >
          <img
            src={currentUser?.reference_images[0].attachment}
            alt="reference_image"
          />
        </Modal>
      )}
    </>
  );
};

RefImageButton.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    portfolio_url: PropTypes.string,
    about: PropTypes.string,
    date_of_birth: PropTypes.string,
    is_profile_completed: PropTypes.bool,
    photo_url: PropTypes.string,
    reference_images: PropTypes.arrayOf(
      PropTypes.shape({
        attachment: PropTypes.string,
        file_name: PropTypes.string,
        id: PropTypes.number,
        type: PropTypes.string,
      })
    ),
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
  }).isRequired,
  insideRefModal: PropTypes.bool,
};

RefImageButton.defaultProps = {
  insideRefModal: false,
};

export default RefImageButton;
