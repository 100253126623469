import classNames from "classnames";
import propTypes from "prop-types";

const IconInput = ({
  error,
  placeholder,
  icon: Icon,
  name,
  register,
  disabled = false,
  size,
  validation,
}) => {
  return (
    <>
      <div
        className={classNames(
          "flex 2 h-11  items-center rounded-sm rounded-full border border-gray-lighter focus-within:border-primary-main hover:border-primary-main",
          {
            "border-danger-light bg-danger-lighter focus-within:border-danger-light hover:border-danger-light":
              error,
          }
        )}
      >
        <div
          className={classNames("px-2", { "bg-gray-lighterShade3": !error })}
        >
          <Icon
            size={size}
            className=" text-gray-main  "
            data-testid="social-media-icon"
          />
        </div>
        <input
          name={name}
          data-testid="input-element"
          className={`h-full w-full outline-none pl-2 ${
            error && `bg-danger-lighter`
          }`}
          disabled={disabled}
          placeholder={placeholder}
          {...register(name, validation)}
        />
      </div>
      {error && <p className="text-sm text-danger-dark">{error.message}</p>}
    </>
  );
};

IconInput.propTypes = {
  error: propTypes.object,
  placeholder: propTypes.string,
  icon: propTypes.elementType.isRequired,
  name: propTypes.string,
  register: propTypes.func,
  disabled: propTypes.bool,
  size: propTypes.number,
  validation: propTypes.object,
};

export default IconInput;
