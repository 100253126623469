const phoneNumberFormatter = (e, setValue, clearErrors, name = "phone") => {
  // to not append - when backspace is entered
  const phoneNumberFormat = /^\d{4}-\d{6}$/i;
  if (
    e.nativeEvent.inputType !== "deleteContentBackward" &&
    e.nativeEvent.inputType !== "deleteContentForward"
  ) {
    if (e.target.value.includes(" ")) {
      setValue(name, e.target.value.split(" ").join(""), { shouldDirty: true });
    }
    if (e.target.value.includes("-")) {
      setValue(name, e.target.value.split("-").join(""), { shouldDirty: true });
    }
    if (e.target.value.startsWith("+91")) {
      setValue(name, `${e.target.value.slice(3)}`, { shouldDirty: true });
    }
    if (e.target.value.startsWith("0")) {
      setValue(name, `${e.target.value.slice(1)}`, { shouldDirty: true });
    }
    if (e.target.value?.length >= 4 && !e.target.value.includes("-")) {
      setValue(
        name,
        `${e.target.value.slice(0, 4)}-${e.target.value.slice(4)}`,
        { shouldDirty: true }
      );
    } else {
      setValue(name, e.target.value, { shouldDirty: true });
    }
  } else {
    setValue(name, e.target.value, { shouldDirty: true });
  }
  if (phoneNumberFormat.test(e.target.value)) {
    clearErrors(name);
  }
};

export default phoneNumberFormatter;
