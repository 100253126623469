import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";

import { ALERT_TYPE, STAGE_TYPE } from "app/constants";
import { Modal } from "components/molecules";
import { requiredValidation } from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";
import {
  createStageAction,
  updateStageAction,
} from "store/thunkActions/erp/stagesThunk";

const StageModal = ({ currentStage, setOpenModal, setCurrentStageLobby }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
    setError,
    watch,
  } = useForm();

  const params = useParams();
  const dispatch = useDispatch();
  const showAlert = useAlert();

  const stagename = watch("name");
  const stageType = watch("stage_type");
  const anyFieldEmpty = !stagename || !stageType;

  useEffect(() => {
    if (currentStage) {
      reset({
        ...currentStage,
        stage_type: STAGE_TYPE.find(
          (type) => type.value === currentStage.stage_type
        ),
      });
    }
  }, [currentStage]);

  const { stages } = useSelector((state) => state.stages);

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    const stageModalData = { ...data };
    if (data.id) {
      stageModalData.stage_type = data.stage_type?.value;
    } else {
      stageModalData.stage_type = data.stage_type?.value;
      stageModalData.sequence =
        stages.length > 0 ? stages[stages.length - 1].sequence + 1 : 1; // add one to last stage sequence
    }

    const action = data.id
      ? updateStageAction({
          jobUuid: params.uuid, // to maintain camelcase format we have changed variable names and format here also in thunk
          stageId: data.id,
          data: stageModalData,
        })
      : createStageAction({
          jobUuid: params.uuid,
          data: { stages: [stageModalData] },
        });

    return dispatch(action)
      .unwrap()
      .then((response) => {
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );

        if (data.id) {
          setOpenModal(false);
        } else {
          setOpenModal(false);
          setCurrentStageLobby(response.data[0].id);
        }
      })
      .catch(({ errors, message }) => {
        if (message) {
          showAlert("danger", message);
        } else {
          setErrorsOnFields(errors);
        }
      });
  };

  return (
    <Modal
      title={`${currentStage ? "Rename " : "Add New "} Stage`}
      setOpen={setOpenModal}
      testId="add-and-edit-stage-modal"
      size="sm"
      acceptButtonText={currentStage ? "Update" : "Proceed"}
      acceptBtnType={anyFieldEmpty || !isDirty ? "disabled" : "primary"}
      acceptBtnSize="smResponsive"
      acceptBtnClassName="sm:ml-3"
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setOpenModal(false)}
      isDirty={isDirty}
    >
      <form
        data-testid="add-and-edit-stage-modal-form"
        className="my-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <label
          htmlFor="name"
          className={`required mt-2 text-md mb-1 block font-medium ${
            errors.name && "text-danger-main"
          }`}
        >
          Name
        </label>

        <input
          id="name"
          className={`${errors.name ? "input-error" : "input"} mb-2 w-full`}
          type="text"
          placeholder="Enter stage name"
          {...register("name", { required: requiredValidation() })} // we have added useForm validation thats why we removed manual checks
        />

        {errors.name && (
          <p className="text-sm text-danger-dark">{errors.name.message}</p>
        )}

        <label
          htmlFor="stage_type"
          className={`required mt-2 text-md mb-1 block font-medium ${
            errors.stage_type && "text-danger-main"
          }`}
        >
          Type
        </label>

        <Controller
          name="stage_type"
          control={control}
          rules={{
            required: requiredValidation(),
          }}
          render={({ field }) => (
            <Select
              name="stage_type"
              options={STAGE_TYPE}
              isClearable
              isDisabled={currentStage}
              id="stage_type_select"
              maxMenuHeight={95}
              menuPlacement="auto"
              styles={reactSelectStyle(errors.stage_type)}
              placeholder="Select stage type"
              value={field.value}
              onChange={(data) => field.onChange(data)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              className="mb-12"
            />
          )}
        />

        {errors.stage_type && (
          <p className="text-sm text-danger-dark">
            {errors.stage_type.message}
          </p>
        )}
      </form>
    </Modal>
  );
};

StageModal.propTypes = {
  isAddingStage: PropTypes.bool,
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  setOpenModal: PropTypes.func,
  setCurrentStageLobby: PropTypes.func,
  setOpenTab: PropTypes.func,
};

export default StageModal;
