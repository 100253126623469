import { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit, AiOutlineCopy } from "react-icons/ai";
import { CgCheck } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  deleteQuestionPaperQuestions,
  showQuestion,
} from "api/erp/questionPaperApi";
import { ALERT_TYPE, QUESTION_PAGE_TYPES } from "app/constants";
import { ActivityIndicator, Button } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Pill from "components/common/Pill";
import { Modal } from "components/molecules";
import { SITE_TITLE } from "constants/common";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { deleteQuestionAction } from "store/thunkActions/erp/questionBankThunk";

const ShowQuestion = () => {
  const showAlert = useAlert();
  const questionID = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const questionPaperQuestionsID = searchParams.get(
    "question_paper_questions_id"
  );
  const directURL = searchParams.get("direct");
  const sectionID = searchParams.get("section");
  const questionPaperName = searchParams.get("question_paper_name");
  const questionPaperID = localStorage.getItem("question_paper_id");
  const questionBank = QUESTION_PAGE_TYPES[1].key;

  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [question, setQuestion] = useState();

  useEffect(() => {
    if (question) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: directURL
              ? [
                  {
                    name: "Question Bank",
                    link: "/questions_manager?tab=question_bank",
                  },
                ]
              : [
                  {
                    name: "Question Paper",
                    link: "/questions_manager?tab=question_papers",
                  },
                  {
                    name: questionPaperName,
                    link: `/questions_manager/question_paper/${questionPaperID}`,
                  },
                ],
            currentPageName: "View Question",
          },
        })
      );
    }
  }, [questionPaperName, questionPaperID, directURL, question]);

  useEffect(() => {
    showQuestion(questionID?.id)
      .then(({ data }) => {
        setQuestion(data);
      })
      .catch(() => {});
  }, []);

  const handleDeleteQuestion = () => {
    return dispatch(deleteQuestionAction(questionID.id))
      .unwrap()
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        setOpenDeleteQuestionModal(false);
        navigate(`/questions_manager?tab=${questionBank}`);
      })
      .catch(({ meta, errors }) => {
        setOpenDeleteQuestionModal(false);
        if (errors) {
          showAlert(ALERT_TYPE.DANGER, errors.question_papers_questions[0]);
        } else {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
        }
      });
  };
  const handleDeleteQuestionPaperQuestion = () => {
    deleteQuestionPaperQuestions(questionPaperQuestionsID)
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        navigate(`/questions_manager/question_paper/${questionPaperID}`);
      })
      .catch(({ meta }) => {
        showAlert(ALERT_TYPE[meta?.message_type], meta?.message);
      });
    setOpenDeleteQuestionModal(false);
  };
  useEffect(() => {
    document.title = `View Question  - ${SITE_TITLE}`;
  }, []);

  return question ? (
    <div className="w-full py-4 px-4">
      <div className="flex items-center justify-between" />

      <div className="mt-8 w-full rounded-md bg-white p-4">
        <div className="flex justify-between">
          <label htmlFor="question" className="my-2 block text-lg font-medium">
            Question
          </label>

          <div className="flex">
            <Button
              title="Edit"
              onClick={() => {
                if (!directURL) {
                  navigate(
                    `/questions_manager/question_paper/${questionPaperID}/question/${sectionID}?question_id=${questionID.id}&question_paper_name=${questionPaperName}&question_paper_questions_id=${questionPaperQuestionsID}&view_question=${questionID.id}`
                  );
                } else {
                  navigate(
                    `/questions_manager/question_bank?question_type=${question.question_type}&question_id=${questionID.id}&view_question=${questionID.id}`
                  );
                }
              }}
              btnClassName="m-2 p-2 rounded-md hover:bg-primary-lighter"
            >
              <AiFillEdit className="text-xl  text-primary-dark" />
            </Button>
            {directURL && (
              <Button
                title="Clone"
                onClick={() =>
                  navigate(
                    `/questions_manager/question_bank?question_type=${question.question_type}&question_id=${questionID.id}&view_question=${questionID.id}&clone=true`
                  )
                }
                btnClassName="p-2 hover:bg-primary-lighter rounded-md"
              >
                <AiOutlineCopy className="text-xl text-primary-main" />
              </Button>
            )}
            <Button
              title="Delete"
              onClick={() => setOpenDeleteQuestionModal(true)}
              testId={`delete-question-btn-${questionID.id}`}
              btnClassName="p-2 m-2 hover:bg-danger-lighter rounded-md"
            >
              <AiFillDelete className="text-xl text-danger-dark " />
            </Button>
          </div>
        </div>
        <div data-testid="question-text">
          <RenderParsedHTML content={question.text || ""} />
        </div>
        <div className="my-2 flex flex-col ">
          <div className="flex flex-wrap gap-2">
            {question.images?.map((img) => (
              <div className="w-[32%]" key={img.id}>
                <img
                  data-testid={img.attachment_file_name}
                  className="my-2 h-auto object-contain"
                  src={img.attachment}
                  alt=""
                  onError={(event) => {
                    event.target.style.display = "none";
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="my-4 flex flex-col justify-between sm:flex-row">
          <div className="w-full flex-col sm:w-5/12">
            <span className="my-2 block font-medium">Question Type</span>
            <span className="uppercase">{question.question_type}</span>
          </div>
          <div className="w-full flex-col sm:w-5/12">
            <span className="my-2 block font-medium">Category</span>
            <span data-testid="question-category">
              {question.category_name}
            </span>
          </div>
        </div>
        <div className="my-4">
          <span className="my-2 block font-medium">Question Tags</span>
          {question.tags?.length ? (
            <span
              className="flex flex-col flex-wrap md:flex-row"
              data-testid="question-tags"
            >
              {question.tags.map((tag) => {
                return <Pill key={tag.tag_id} label={tag.tag_name} />;
              })}
            </span>
          ) : (
            <span className="text-sm italic">No tags found!</span>
          )}
        </div>
        <div className="my-4 flex flex-col justify-between sm:flex-row">
          <div className="w-full flex-col sm:w-5/12">
            <span className="mb-1 block font-medium">
              Duration{" "}
              <span className="mt-1 text-xs italic text-gray-main">
                ( in seconds )
              </span>
            </span>
            <span data-testid="question-duration">{question.duration}</span>
          </div>

          <div className="w-full flex-col sm:w-5/12">
            <span className="mb-1 block font-medium">Marks</span>
            <span data-testid="question-marks">{question.marks}</span>
          </div>
        </div>
        <div className="flex flex-col flex-wrap justify-between sm:flex-row">
          {question.answers?.map((item, index) => {
            return (
              <div key={item.id} className="my-2 w-full sm:w-5/12">
                <div className="my-2">
                  <span
                    htmlFor={`option.${index}.text`}
                    className="font-medium text-primary-main"
                  >
                    Option {index + 1}
                  </span>
                </div>

                <RenderParsedHTML content={item.text || ""} />

                <div className="my-2 flex justify-between">
                  {item.attachment_file_name && (
                    <img
                      className="my-2 w-1/3 object-contain "
                      src={item.attachment}
                      alt={item.attachment_file_name}
                    />
                  )}
                </div>

                {item.correct_answer && (
                  <div className="my-2 flex items-center pb-4">
                    <button
                      disabled
                      className="mr-2 h-4 w-4 rounded-sm border border-primary-main bg-primary-main bg-center"
                    >
                      <CgCheck className="text-white" />
                    </button>
                    <span className="text-sm">Correct</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="flex flex-col flex-wrap justify-between sm:flex-row">
          {question.driver_codes?.length !== 0 && (
            <div className="w-full">
              <div className="mt-5 font-medium"> Languages & Driver Codes</div>
              <div className="w-full">
                <div className="flex flex-col flex-wrap justify-between sm:flex-row">
                  {question.driver_codes?.map((item) => {
                    return (
                      <div key={item.id} className="w-full sm:w-5/12">
                        <div className="py-2 align-top">
                          <span className="mr-3.5 px-2 font-medium text-primary-main">
                            {item.coding_language}
                          </span>
                        </div>
                        <div className="rounded-sm border p-2">
                          <span className="whitespace-pre-wrap text-sm">
                            {item.code_string}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mb-6 flex flex-col flex-wrap justify-between sm:flex-row">
          {question.test_cases?.length !== 0 && (
            <div className="w-full">
              <div className="mt-10 font-medium"> Test Cases</div>
              <div className="mt-4 w-full">
                <div className="flex flex-col flex-wrap justify-between sm:flex-row">
                  {question.test_cases?.map((item) => {
                    return (
                      <div className="w-full sm:w-5/12" key={item.id}>
                        <div className="flex flex-col gap-1 py-2 align-top">
                          <span className="font-medium">Input:</span>
                          <span className="whitespace-pre-wrap break-words rounded-md border p-2">
                            {item.input}
                          </span>
                        </div>
                        <div className="flex flex-col gap-1 py-2 align-top">
                          <span className="font-medium">Expected Output:</span>
                          <span className="whitespace-pre-wrap break-words rounded-md border p-2">
                            {item.output}
                          </span>
                        </div>
                        <div className="mb-6 flex gap-2 py-2 align-top">
                          <span className="font-medium">Public:</span>
                          {item.is_public ? (
                            <span> Yes</span>
                          ) : (
                            <span> No</span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        {openDeleteQuestionModal && (
          <Modal
            title="Are you sure?"
            description="Are you sure you want to delete this question?"
            acceptButtonText="Yes"
            acceptBtnType="primary"
            rejectButtonText="No"
            rejectBtnType="secondary"
            handleAccept={
              directURL
                ? handleDeleteQuestion
                : handleDeleteQuestionPaperQuestion
            }
            handleReject={() => setOpenDeleteQuestionModal(false)}
          />
        )}
      </div>
    </div>
  ) : (
    <ActivityIndicator />
  );
};

export default ShowQuestion;
