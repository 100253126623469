import { useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { BsShareFill } from "react-icons/bs";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { IoIosLink } from "react-icons/io";
import { RiCheckDoubleFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";

import { Button } from "components/atoms";
import useAlert from "hooks/useAlert";

const CopyLink = ({
  link,
  tooltipId,
  tooltipContent,
  icon,
  iconSize,
  particular,
}) => {
  const showAlert = useAlert();
  const [hasCopied, setHasCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setHasCopied(true);
      setTimeout(() => setHasCopied(false), 1000);
    } catch (err) {
      showAlert(
        "danger",
        "Couldn't copy to clipboard. Make sure you have a secured connection"
      );
    }
  };
  return hasCopied ? (
    <div className="flex items-center gap-2 text-[11px] min-h-[30px]">
      <RiCheckDoubleFill className="text-sm text-gray-dark" />
      <span className="text-xs italic text-gray-dark">
        {icon === "copy" || icon === "clipboard-icon"
          ? "Copied"
          : `${particular} copied`}
      </span>
    </div>
  ) : (
    <div
      data-tooltip-content={tooltipContent}
      data-tooltip-id={tooltipId}
      className="px-1"
    >
      {icon == "share" && (
        <Button
          btnClassName="cursor-pointer btn-icon-dark-gray text-base text-primary-main"
          onClick={() => copyToClipboard(link)}
        >
          <BsShareFill />
        </Button>
      )}
      {icon == "copy" && (
        <AiOutlineCopy
          className="cursor-pointer btn-icon-dark-gray text-base text-primary-main "
          onClick={() => copyToClipboard(link)}
        />
      )}
      {icon === "clipboard-icon" && (
        <Button
          btnClassName="cursor-pointer btn-icon-dark-gray text-base text-primary-main"
          onClick={() => copyToClipboard(link)}
        >
          <HiOutlineClipboardCopy size={25} />
        </Button>
      )}
      {icon === "copy-link" && (
        <Button
          btnClassName="cursor-pointer btn-icon-dark-gray text-base text-primary-main p-1 flex align-center"
          onClick={() => copyToClipboard(link)}
        >
          <IoIosLink size={iconSize} />
        </Button>
      )}
      <Tooltip id={tooltipId} variant="dark" className="z-10" />
    </div>
  );
};

CopyLink.defaultProps = {
  icon: "share",
  iconSize: 22,
  particular: "Link",
};

CopyLink.propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string,
  iconSize: PropTypes.number,
  tooltipId: PropTypes.string,
  tooltipContent: PropTypes.string,
  particular: PropTypes.string,
};

export default CopyLink;
