import { createAsyncThunk } from "@reduxjs/toolkit";

import { getStates } from "api/candidate/profileApi";

export const getStatesAction = createAsyncThunk(
  "location-details/get",
  async () => {
    const response = await getStates();
    return response.data.data;
  }
);
