import classNames from "classnames";
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { marked } from "marked";
import PropTypes from "prop-types";

const RenderParsedHTML = ({ content, className }) => {
  // Remove harmful script tags and other unsafe HTML elements
  const specialCharactersToHTML = marked.parse(`${content}`);
  const purifiedContent = DOMPurify.sanitize(`${specialCharactersToHTML}`);
  return (
    <div
      className={classNames(
        "break-words prose max-w-none prose-p:whitespace-pre-wrap prose-p:text-sm prose-a:text-secondary-darker hover:prose-a:text-primary-main prose-code:whitespace-pre-wrap prose-code:text-black-primary prose-pre:bg-gray-lighterShade3 prose-pre:text-sm",
        className
      )}
    >
      {parse(`${purifiedContent}`)}
    </div>
  );
};

RenderParsedHTML.defaultProps = {
  className: "",
};

RenderParsedHTML.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
};
export default RenderParsedHTML;
