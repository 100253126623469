import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCurrentUser, updateCurrentUser } from "api/candidate/profileApi";
import { getOrganisationCurrentUser } from "api/erp/organizationApi";

export const setCandidateUserAction = createAsyncThunk(
  "auth/setCandidateUser",
  async () => {
    const response = await getCurrentUser();
    return response;
  }
);

export const setOrganisationUserAction = createAsyncThunk(
  "auth/setOrganisationUser",
  async () => {
    const response = await getOrganisationCurrentUser();
    return response;
  }
);

export const updateCurrentUserAction = createAsyncThunk(
  "auth/updateCurrentUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCurrentUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
