import { BsExclamationCircleFill } from "react-icons/bs";

import Header from "components/candidate/Exam/Header";
import { multipleTabsWarning } from "helpers/genericErrorMessages";

const DifferentWarningComponent = () => (
  <div className="body">
    <Header />
    <div className="container mx-auto mt-12">
      <div className="rounded-full border border-warning-alertDark bg-warning-alertBg  p-2 text-warning-alertDark opacity-80">
        <div className="flex items-center justify-center gap-3 px-2">
          <div>
            <BsExclamationCircleFill size={30} className="h-8 w-8 " />
          </div>
          <div className="text-base">{multipleTabsWarning()}</div>
        </div>
      </div>
    </div>
  </div>
);

export default DifferentWarningComponent;
