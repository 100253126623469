import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "components/atoms";
import { Modal } from "components/molecules";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { getContactDetailsction } from "store/thunkActions/candidate/contactDetailsThunk";
import { getEducationQualificationsAction } from "store/thunkActions/candidate/educationQualificationThunk";
import { getCandidateLanguagesAction } from "store/thunkActions/candidate/languageThunk";
import { getProjectsAction } from "store/thunkActions/candidate/projectThunk";
import { getCandidateSkillsAction } from "store/thunkActions/candidate/skillThunk";
import { getWorkExperiencesAction } from "store/thunkActions/candidate/workExperienceThunk";

import CandidateEducation from "./CandidateEducation";
import CandidateLanguages from "./CandidateLanguages";
import CandidateProject from "./CandidateProject";
import CandidateSkills from "./CandidateSkills";
import Contact from "./Contact";
import Hobbies from "./Hobbies";
import Profile from "./Profile";
import WorkExperience from "./WorkExperience";

const ResumePreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resumeRef = useRef(null);
  const showAlert = useAlert();
  const [isResumeGenerating, setIsResumeGenerating] = useState(false);
  const [
    { currentUser },
    { projects },
    { workExperiences },
    { contactDetails },
    { candidateSkills },
    { candidateLanguages },
    { educationQualifications },
  ] = useSelector((state) => [
    state.auth,
    state.projects,
    state.workExperiences,
    state.contactDetails,
    state.candidateSkills,
    state.candidateLanguages,
    state.educationQualifications,
  ]);

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: { currentPageName: "Resume Preview" },
      })
    );
    dispatch(getEducationQualificationsAction());
    dispatch(getCandidateLanguagesAction());
    dispatch(getCandidateSkillsAction());
    dispatch(getContactDetailsction());
    dispatch(getWorkExperiencesAction());
    dispatch(getProjectsAction());
  }, []);

  const handleClick = () => {
    const htmlContent = resumeRef.current.innerHTML;

    return fetch("/download-resume", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resume_details: htmlContent,
        resume_name: "Candidate_Resume.pdf",
      }),
    })
      .then(async (response) => {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${currentUser.data.name.replace(" ", "_")}_Resume.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        setIsResumeGenerating(false);
        showAlert("success", "Your resume is successfully generated!");
      })
      .catch(() => {
        setIsResumeGenerating(false);
        showAlert("danger", "Oops, something went wrong!");
      });
  };

  return (
    <>
      <div className="flex gap-8 justify-center w-full lg:px-5 pt-5 lg:pb-12 px-1 pb-10">
        <div className="lg:w-3/5 w-full">
          <div
            className="resume w-full rounded-md border-2 lg:p-5 p-1"
            ref={resumeRef}
          >
            {/* resume header */}
            <div
              style={{
                fontSize: "1.875rem",
                lineHeight: "2.25rem",
                fontWeight: 300,
                letterSpacing: "0.05em",
                textAlign: "center",
                textTransform: "uppercase",
                color: "rgb(55 65 81)",
                "@media (minWidth: 992px)": {
                  fontSize: "3rem",
                  lineHeight: 1,
                },
              }}
            >
              {currentUser.data.name}
            </div>
            <div
              style={{
                paddingTop: "0.25rem",
                paddingBottom: "0.5rem",
                fontSize: "1.125rem",
                lineHeight: "1.75rem",
                color: "rgb(100 100 100)",
                fontWeight: 400,
                letterSpacing: "0.025em",
                textAlign: "center",
                "@media (minWidth: 992px)": {
                  paddingTop: "0.5rem",
                  paddingBottom: "1.25rem",
                  fontSize: "1.25rem",
                  lineHeight: "1.75rem",
                },
              }}
            >
              {currentUser.data.title}
            </div>
            <hr className="h-0.5 bg-gray-dark" />

            {/* resume body */}
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "rgb(229 231 235)",
                  padding: "0.5rem",
                  width: "33.333333%",
                  "@media (minWidth: 992px)": { padding: "1rem" },
                }}
              >
                <Contact
                  portfolioUrl={currentUser.data.portfolio_url}
                  email={currentUser.data.email}
                  contact={contactDetails[contactDetails.length - 1]}
                />
                {candidateSkills.length > 0 && (
                  <CandidateSkills candidateSkills={candidateSkills} />
                )}
                {educationQualifications.length > 0 && (
                  <CandidateEducation
                    educationQualifications={educationQualifications}
                  />
                )}
                {currentUser.data.interests && (
                  <Hobbies interest={currentUser.data.interests} />
                )}
                {candidateLanguages.length > 0 && (
                  <CandidateLanguages candidateLanguages={candidateLanguages} />
                )}
              </div>
              <div
                style={{
                  padding: "0.5rem",
                  width: "66.666667%",
                  "@media (minWidth: 992px)": { padding: "1rem" },
                }}
              >
                {currentUser.data.about && (
                  <Profile about={currentUser.data.about} />
                )}
                {workExperiences.length > 0 && (
                  <WorkExperience workExperiences={workExperiences} />
                )}
                {projects.length > 0 && (
                  <CandidateProject projects={projects} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 flex w-full gap-4 border-t-2 bg-gray-lighterShade3 py-3 px-5">
        <Button
          btnClassName="mx-2 text-sm"
          type="primary"
          btnName="Generate"
          customWidth={36}
          onClick={() => setIsResumeGenerating(true)}
        />
        <Button
          btnClassName="mx-2 text-sm"
          type="secondary"
          btnName="Cancel"
          customWidth={36}
          onClick={() => navigate("/create-resume")}
        />
      </div>
      {isResumeGenerating && (
        <Modal
          title="Confirm resume creation"
          description="This will create a resume with current details."
          onOutsideClickHandler={() => setIsResumeGenerating(false)}
          acceptButtonText="Proceed"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="Edit details"
          customButtonWidth={32}
          handleAccept={handleClick}
          handleReject={() => navigate("/create-resume")}
          handleCancel={() => setIsResumeGenerating(false)}
        />
      )}
    </>
  );
};

export default ResumePreview;
