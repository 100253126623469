/**
 * This hook fire a custom event when mousedown target and mouseup target are same.
 * This help us to solve the issue which arise when user mousedown -> drag -> mouseup.
 * we also send the click target event in extra details which can be used by event listener.
 * @param {customEventName} custom event name which will be fired when mousedown target and mouseUp target are same
 * @return {undefined}
 */

import { useEffect, useRef } from "react";

import useEvent from "hooks/useEvent";

const useOnClick = (customEventName) => {
  const mouseDownRef = useRef();
  const { on, off, trigger } = useEvent();

  const clickCallBack = (event) => {
    if (mouseDownRef.current?.target == event.target) {
      trigger(customEventName, {
        mouseDownEvent: mouseDownRef.current,
        clickEvent: event.target,
      });
    }
  };

  const mouseDownCallback = (event) => {
    mouseDownRef.current = event;
  };

  useEffect(() => {
    on("mousedown", mouseDownCallback);
    on("click", clickCallBack);

    return () => {
      off("mousedown", mouseDownCallback);
      off("click", clickCallBack);
    };
  }, []);
};

export default useOnClick;
