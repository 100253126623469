const SkeletonLoader = () => {
  return (
    <div className="bg-white rounded-sm mt-2 overflow-hidden shadow-sm border border-gray-400">
      <div className="flex flex-col p-5 animate-pulse">
        <div className="w-3/5 p-2.5 bg-gray-300 rounded" />
        <div className="my-2 flex gap-2 w-1/2">
          <div className="w-2/3 p-2.5 bg-gray-300 rounded" />
          <div className="w-1/3 p-2.5 bg-gray-300 rounded" />
        </div>
        <div className="mb-2 flex gap-2 w-1/2">
          <div className="w-2/3 p-2.5 bg-gray-300 rounded" />
          <div className="w-1/3 p-2.5 bg-gray-300 rounded" />
        </div>
        <div className="w-full mb-2 p-2.5 bg-gray-300 rounded" />
        <div className="w-full mb-2 p-2.5 bg-gray-300 rounded" />
        <div className="w-2/3 p-2.5 bg-gray-300 rounded" />
      </div>
    </div>
  );
};

export default SkeletonLoader;
