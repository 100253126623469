import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "components/atoms";
import {
  formattedPhoneNumberValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import phoneNumberFormatter from "helpers/phoneNumberFormatter";
import useAlert from "hooks/useAlert";
import {
  createCandidatePhoneNumberAction,
  updateCandidatePhoneNumberAction,
} from "store/thunkActions/candidate/phoneNumberThunk";

const Form = ({ phoneNumber, setIsEditState, setIsAdding }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { isDirty, errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const showAlert = useAlert();

  useEffect(() => reset(phoneNumber), [phoneNumber, reset]);

  const submitHandler = (data) => {
    // Restrict API call when no changes
    if (data.id && !isDirty) {
      return setIsEditState(false);
    }

    // Update if existing record else create new
    const action = data.id
      ? updateCandidatePhoneNumberAction(data)
      : createCandidatePhoneNumberAction(data);

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
      })
      .catch(({ errors }) => {
        showAlert("danger", errors?.phone[0]);
      });
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) =>
        submitHandler({ ...data, phone: data.phone.replace("-", "") })
      )}
    >
      <p className="text-sm font-medium text-gray-dark">
        Please fill out the details
        {phoneNumber ? " to update" : " for adding new"} phone number
      </p>
      <label
        htmlFor="phone"
        className={`required mt-2 mb-1 block font-medium ${
          errors.phone && "text-danger-main"
        }`}
      >
        Phone Number
      </label>
      <div className="flex">
        <input
          disabled
          name="country-code"
          type="text"
          value="+91 "
          className="w-12 input"
        />
        <input
          id="phone"
          className={`${errors.phone ? "input-error" : "input"} w-full`}
          type="text"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          placeholder="XXXX-XXXXXX"
          {...register("phone", {
            maxLength: {
              value: 11,
              message: formattedPhoneNumberValidation(),
            },
            pattern: {
              value: /^\d{4}-\d{6}$/i,
              message: formattedPhoneNumberValidation(),
            },
            required: requiredValidation(),
          })}
          onChange={(e) => phoneNumberFormatter(e, setValue, clearErrors)}
        />
      </div>

      {errors.phone && (
        <p className="text-sm text-danger-dark">{errors.phone.message}</p>
      )}

      <div className="mt-4 flex w-full">
        <Button
          title={setIsAdding ? "Save" : "Update"}
          btnClassName="px-2"
          testId="savePhoneNumbertBtn"
          type="primary"
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          {setIsAdding ? "Save" : "Update"}
        </Button>

        <Button
          title="Cancel"
          testId="cancelPhoneNumberBtn"
          type="secondary"
          size="sm"
          btnClassName="px-2 ml-2"
          onClick={() => setIsEditState(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  phoneNumber: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};

export default Form;
