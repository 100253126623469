import PropTypes from "prop-types";

import { Button } from "components/atoms";

const QuestionFooter = ({
  onResetButtonClick,
  onNextButtonClick,
  currentQuestionIndex,
  totalQuestions,
  isCodingQuestion,
  customInputChecked,
  setCustomInputChecked,
  onCompileButtonClick,
  compileButton,
  saveButtonText,
  isSaving,
}) => {
  return (
    <div
      className="sticky bottom-0 flex flex-col items-center border-t-2
                    border-r bg-gray-lighterShade3 py-3 px-4 sm:flex-row sm:justify-between"
    >
      {isCodingQuestion ? (
        <div className="flex h-full w-full flex-col sm:flex-row sm:items-center">
          <a href="#code-output">
            <Button
              title="Compile & Run"
              btnClassName="border border-primary-main hover:font-medium py-2 px-4 text-primary-main w-full
                rounded-full text-sm sm:w-36"
              onClick={onCompileButtonClick}
              isAsyncClick
            >
              {compileButton()}
            </Button>
          </a>

          <label className="text-center">
            <input
              type="checkbox"
              className="mx-2"
              checked={customInputChecked}
              onChange={setCustomInputChecked}
            />
            <span className="text-sm">Test Against Custom Input</span>
          </label>
        </div>
      ) : (
        <Button
          title="Reset Answer"
          size="md"
          type="secondary"
          customWidth={36}
          onClick={onResetButtonClick}
          isAsyncClick
          btnClassName="mb-2 sm:mb-0"
        >
          Reset Answer
        </Button>
      )}

      {totalQuestions > currentQuestionIndex && (
        <Button
          title="Save & Next"
          customWidth={28}
          type={`${isSaving ? "disabled" : "primary"}`}
          onClick={onNextButtonClick}
          testId="save-and-next-btn"
        >
          {saveButtonText()}
        </Button>
      )}
    </div>
  );
};

QuestionFooter.propTypes = {
  onResetButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  currentQuestionIndex: PropTypes.number,
  totalQuestions: PropTypes.number,
  isCodingQuestion: PropTypes.bool,
  customInputChecked: PropTypes.bool,
  setCustomInputChecked: PropTypes.func,
  onCompileButtonClick: PropTypes.func,
  compileButton: PropTypes.func,
  saveButtonText: PropTypes.func,
  isSaving: PropTypes.bool,
};

export default QuestionFooter;
