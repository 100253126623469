import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { upperCase } from "lodash";
import PropTypes from "prop-types";

import { QUESTION_TYPE } from "app/constants";
import { Modal } from "components/molecules";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";
import {
  createQuestionPaperAction,
  updateQuestionPaperNameAction,
} from "store/thunkActions/erp/questionsThunk";

const QuestionPaperModal = ({
  questionPaperName,
  questionPaperType,
  isSectional,
  isQuestionPaperAdding,
  setIsEditState,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, errors },
    setError,
    reset,
    watch,
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const params = useParams();
  const questionpapername = watch("name");
  const questionpapertype = watch("question_paper_type");
  const anyFieldEmpty = !questionpapername || !questionpapertype;

  useEffect(() => {
    if (questionPaperName) {
      reset({
        name: questionPaperName,
        question_paper_type: { name: questionPaperType },
        sectional: isSectional ? "checked" : "",
      });
    }
  }, [questionPaperName]);

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    data.question_paper_type = data.question_paper_type?.name;

    if (!isDirty) {
      setError("name", { message: "can't be blank" });
      setError("question_paper_type", { message: "can't be blank" });
    }

    if (!data.question_paper_type) {
      return Promise.resolve(
        setError("question_paper_type", { message: "can't be blank" })
      );
    }

    return dispatch(createQuestionPaperAction(data))
      .unwrap()
      .then((data) => {
        showAlert("success", "Question Paper created successfully");
        navigate(`/questions_manager/question_paper/${data.id}`);
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  const updateQuestionPaperName = (data) =>
    dispatch(updateQuestionPaperNameAction({ ...data, id: params.id }))
      .unwrap()
      .then(() => {
        setIsEditState(false);
        showAlert("success", "Question Paper updated successfully");
      })
      .catch(({ errors }) => setErrorsOnFields(errors));

  return (
    <Modal
      title={`${questionPaperName ? "Edit" : "Generate"} Question Paper`}
      testId="generate-edit-question-paper-modal"
      acceptButtonText={questionPaperName ? "Update" : "Proceed"}
      acceptBtnType={anyFieldEmpty || !isDirty ? "disabled" : "primary"}
      handleAccept={
        questionPaperName
          ? handleSubmit((data) => updateQuestionPaperName(data))
          : handleSubmit((data) => submitHandler(data))
      }
      handleCancel={() => setIsEditState(false)}
      isDirty={isDirty}
    >
      <form
        data-testid="modal-body"
        className="my-4"
        onSubmit={
          questionPaperName
            ? handleSubmit((data) => updateQuestionPaperName(data))
            : handleSubmit((data) => submitHandler(data))
        }
      >
        <label
          htmlFor="name"
          className={`required mt-2 mb-1 text-md block font-medium ${
            errors.name && "text-danger-main"
          }`}
        >
          Name
        </label>

        <input
          id="name"
          className={`${errors.name ? "input-error" : "input"} mb-2 w-full`}
          type="text"
          data-testid="question-paper-name"
          placeholder="Enter question paper name"
          {...register("name", {
            required: requiredValidation(),
            maxLength: {
              value: 255,
              message: characterValidation({ limit: 255 }),
            },
          })}
        />

        {errors.name && (
          <p className="text-sm text-danger-dark">{errors.name.message}</p>
        )}

        <label
          htmlFor="question-paper-type"
          className={`required mt-2 text-md mb-1 block font-medium ${
            errors.question_paper_type && "text-danger-main"
          }`}
        >
          Type
        </label>

        <Controller
          name="question_paper_type"
          control={control}
          defaultValue={null}
          rules={{ required: requiredValidation() }}
          render={({ field }) => (
            <Select
              {...field}
              options={QUESTION_TYPE}
              isDisabled={!isQuestionPaperAdding}
              getOptionLabel={(option) => upperCase(option.name)}
              getOptionValue={(option) => option.name}
              id="question-paper-type"
              menuPlacement="auto"
              maxMenuHeight={140}
              placeholder="Select question paper type"
              styles={reactSelectStyle}
              isSearchable={false}
              isClearable={false}
              className="mb-3"
            />
          )}
        />

        {errors.question_paper_type && (
          <p className="text-sm text-danger-dark">
            {errors.question_paper_type.message}
          </p>
        )}

        <div className=" flex justify-between sm:flex-row">
          <div className="w-full sm:w-5/12">
            <p
              className={`mb-1 block font-medium ${
                errors.sectional && "text-danger-main"
              }`}
            >
              Create Section
            </p>
            <label
              htmlFor="is-sectional"
              className="relative w-max cursor-pointer select-none items-center"
              data-testid="is-sectional"
            >
              <input
                disabled={!isQuestionPaperAdding}
                defaultChecked={isQuestionPaperAdding}
                name="sectional"
                id="is-sectional"
                data-testid="is-sectional-toggle"
                type="checkbox"
                className="h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
                {...register("sectional")}
              />
              <span className="font-small absolute bottom-2.5 right-1.5 text-xs uppercase text-white">
                No
              </span>
              <span className="absolute bottom-2.5 right-9 text-xs font-medium uppercase text-white">
                Yes
              </span>
              <span className="mt-1 absolute bottom-2.5 right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
            </label>
          </div>
        </div>
      </form>
    </Modal>
  );
};

QuestionPaperModal.propTypes = {
  questionPaperName: PropTypes.string,
  questionPaperType: PropTypes.string,
  isSectional: PropTypes.bool,
  isQuestionPaperAdding: PropTypes.bool,
  setIsEditState: PropTypes.func,
};

export default QuestionPaperModal;
