/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { startCase } from "lodash";

import { getPanelDetails } from "api/erp/organizationApi";
import { ERP_PANEL_TYPES } from "app/constants";
import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import { InfiniteScrollWrapper, TabList } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import { SITE_TITLE } from "constants/common";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import FeedbackModal from "./FeedbackModal";
import PanelList from "./PanelList";

const Panels = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(ERP_PANEL_TYPES[0].key);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const [panel, setPanel] = useState();

  const tab = searchParams.get("status") || currentTab;

  const [panels, setPanels] = useState({
    data: [],
    meta: undefined,
    isLoading: true,
  });

  const dispatch = useDispatch();

  const getPanels = () =>
    getPanelDetails({ page: currentPage, status: tab }).then((response) => {
      setPanels({
        data: response.data,
        meta: response.meta,
        isLoading: false,
      });
    });

  const getMorePanelData = (page) =>
    getPanelDetails({ page, status: currentTab }).then((response) => {
      setCurrentPage((prev) => prev + 1);
      setPanels({
        data:
          response.meta.page_number === 1
            ? response.data
            : [...panels.data, ...response.data],
        meta: response.meta,
        isLoading: false,
      });

      return response;
    });

  useEffect(() => {
    setCurrentTab(tab);
  }, [currentTab]);

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: {
          currentPageName: ERP_PANEL_TYPES.find((item) => item.key === tab)
            ?.label,
        },
      })
    );
    setPanels({ data: [], meta: undefined, isLoading: true });
    getPanels();
    // Check if the tab parameter in the URL is valid, otherwise set it to the default ongoing interview tab
    const validTab = ERP_PANEL_TYPES.find((item) => item.key === tab);
    if (!validTab) {
      setCurrentTab(ERP_PANEL_TYPES[0].key);
      setSearchParams({ status: ERP_PANEL_TYPES[0].key });
    }
  }, [tab]);

  return (
    <div className="h-full w-full px-2 lg:px-4 ">
      <Helmet>
        <title>{`${startCase(
          `${tab} ${tab !== ERP_PANEL_TYPES[2].key ? " Panels" : ""} `
        )} - ${SITE_TITLE}`}</title>
        <meta property="og:url" content="https://app.joballey.in/" />
      </Helmet>
      <div className="sticky top-[45px] z-10 bg-gray-lighterShade3">
        <TabList
          testId="my-interview-tabs"
          tabs={ERP_PANEL_TYPES}
          currentTab={tab}
          paramBased
          onTabClick={(key) => {
            setCurrentTab(key);
            setSearchParams({ status: key });
          }}
        />
      </div>

      <InfiniteScrollWrapper
        hasMore={!panels.isLoading && panels?.meta?.has_more}
        loadMoreHandler={() => getMorePanelData(currentPage + 1, false)}
      >
        {panels.isLoading && <ActivityIndicator />}
        {panels && panels.data.length > 0 ? (
          <div className="overflow-x-auto">
            <PanelList
              panels={panels.data}
              status={tab}
              setInterview={setPanel}
              setShowFeedbackModal={setShowFeedbackModal}
              setViewOnly={setViewOnly}
            />

            {showFeedbackModal && (
              <FeedbackModal
                setShowFeedbackModal={setShowFeedbackModal}
                panel={panel}
                getMorePanelData={getMorePanelData}
                viewOnly={viewOnly}
              />
            )}
          </div>
        ) : (
          !panels.isLoading &&
          panels.data?.length === 0 && (
            <div className="pt-16 text-center">
              <img src={NoDataFound} alt="No data found" className="m-auto" />
            </div>
          )
        )}
      </InfiniteScrollWrapper>
    </div>
  );
};

export default Panels;
