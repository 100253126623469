import React, { useEffect, useMemo, useRef, useState } from "react";
import { CopyBlock, monoBlue } from "react-code-blocks";
import { Helmet } from "react-helmet";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { format } from "date-fns";
import { camelCase, capitalize, snakeCase, startCase } from "lodash";
import PropTypes from "prop-types";

import { ALERT_TYPE, WIDGET_TYPES } from "app/constants";
import { ActivityIndicator, Button, TabList } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import Pill from "components/atoms/Pill";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import CopyLink from "components/common/CopyLink";
import { Modal } from "components/molecules";
import { DEFAULT_PAGE_SIZE, SITE_TITLE, USER_ROLES } from "constants/common";
import {
  JOB_PREFERENCES,
  JOB_PROFILE_STATS,
  JOB_SETTINGS,
  JOB_STATUS,
  JOB_VISIBILITY,
} from "constants/jobs";
import { constructQueryParams } from "helpers/constructQueryParams";
import currentHost from "helpers/currentHost";
import { getDomainName } from "helpers/utils";
import useAlert from "hooks/useAlert";
import useCheckUserRole from "hooks/useCheckUserRole";
import { resetState } from "store/slices/erp/jobsSlice";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { getJobAction, updateJobAction } from "store/thunkActions/erp/jobThunk";

import DescriptionCard from "./DescriptionCard";
import DotMenu from "./DotMenu";

const StatDetails = ({
  label,
  statValue,
  isOrderedList = false,
  isBooleanField,
  customClassName = "pb-[10px]",
}) => (
  <div
    className={classNames(
      "grid grid-cols-2 gap-x-[40px] text-sm break-words",
      customClassName
    )}
  >
    <span className="text-primary-shadeDarkGrayBlue">{`${label}\u00a0:\u00a0`}</span>

    {isOrderedList ? (
      <ul className={classNames("list-decimal", { "pl-3": isOrderedList })}>
        {statValue.map((record, index) =>
          typeof record === "string" ? (
            <li className="text-gray-slateCustomShade1" key={index}>
              {record}
            </li>
          ) : (
            <li className="text-gray-slateCustomShade1" key={record.id}>
              {record.name}
            </li>
          )
        )}
      </ul>
    ) : (
      <span className="text-gray-slateCustomShade1">
        {isBooleanField !== true
          ? statValue
          : (() => {
              if (statValue) {
                return "Yes";
              }
              return "No";
            })()}
      </span>
    )}
  </div>
);

const Description = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [isApplicationClosed, setIsApplicationClosed] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showWidgetModal, setShowWidgetModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(WIDGET_TYPES[0].key);
  const [textCopied, setTextCopied] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const dropdownMenuRef = useRef();
  const isUserRoleAllowed = useCheckUserRole([USER_ROLES.ADMIN]);
  const checkHiringManagerUserRole = useCheckUserRole([
    USER_ROLES.HIRING_MANAGER,
  ]);

  const { currentUser } = useSelector((state) => state.auth);
  const [job, setJob] = useState();
  const showWidgetDetails =
    job?.allow_external_access &&
    (isUserRoleAllowed || checkHiringManagerUserRole);

  useEffect(() => {
    if (job) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              {
                name: "Jobs",
                link: `/jobs`,
              },
            ],
            currentPageName: job.title,
          },
        })
      );
    }
  }, [job]);

  useEffect(() => {
    dispatch(getJobAction(params.uuid))
      .unwrap()
      .then((data) => setJob(data));
  }, [dispatch, params.uuid]);

  const restrictedBy = useMemo(() => {
    const criteria = [];
    JOB_PREFERENCES.map((preferences) => {
      if (
        job &&
        job[preferences.value]?.length > 0 &&
        job[preferences.restrictedBy]
      ) {
        criteria.push(preferences.label);
      }
    });
    return criteria;
  }, [job]);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showOptions && !dropdownMenuRef.current.contains(e.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showOptions]);

  const afterJobAction = ({ message, message_type: messageType }) => {
    showAlert(ALERT_TYPE[messageType], message);
    localStorage.removeItem("jobpost:scrollY");
    navigate("/jobs");
    dispatch(resetState());
  };

  const onApplicationCloseJob = () => {
    const jobData = {
      ...job,
      college_ids:
        job.visibility === JOB_VISIBILITY.restricted
          ? job.colleges?.map((c) => c.id)
          : [],
      qualification_ids:
        job.visibility === JOB_VISIBILITY.restricted
          ? job.qualifications?.map((c) => c.id)
          : [],
      skill_ids:
        job.visibility === JOB_VISIBILITY.restricted
          ? job.skills?.map((c) => c.id)
          : [],
      status: snakeCase(JOB_STATUS.applicationClosed.text),
    };

    delete jobData.colleges;
    delete jobData.qualifications;
    delete jobData.skills;

    const action = updateJobAction(jobData);

    return dispatch(action)
      .unwrap()
      .then(({ meta }) => afterJobAction(meta));
  };

  const onCloseJob = () => {
    const jobData = {
      ...job,
      college_ids:
        job.visibility === JOB_VISIBILITY.restricted
          ? job.colleges?.map((c) => c.id)
          : [],
      qualification_ids:
        job.visibility === JOB_VISIBILITY.restricted
          ? job.qualifications?.map((c) => c.id)
          : [],
      skill_ids:
        job.visibility === JOB_VISIBILITY.restricted
          ? job.skills?.map((c) => c.id)
          : [],
      status: snakeCase(JOB_STATUS.jobClosed.text),
    };

    delete jobData.colleges;
    delete jobData.qualifications;
    delete jobData.skills;

    const action = updateJobAction(jobData);

    return dispatch(action)
      .unwrap()
      .then(({ meta }) => afterJobAction(meta))
      .catch(({ errors, meta }) => {
        // todo: when all BE will be changedto new format this extra checking will be deleted
        showAlert(
          ALERT_TYPE[meta.message_type] || "danger",
          meta?.message || errors.detail || errors.message
        );
      });
  };

  const submitHandler = (data) => {
    const jobData = {
      ...data,
      status: JOB_STATUS.active.text,
      college_ids:
        data.visibility === JOB_VISIBILITY.restricted
          ? data.colleges?.map((c) => c.id)
          : [],
      qualification_ids:
        data.visibility === JOB_VISIBILITY.restricted
          ? data.qualifications?.map((c) => c.id)
          : [],
      skill_ids:
        data.visibility === JOB_VISIBILITY.restricted
          ? data.skills?.map((c) => c.id)
          : [],
    };

    delete jobData.colleges;
    delete jobData.qualifications;
    delete jobData.skills;

    const action = updateJobAction(jobData);

    return dispatch(action)
      .unwrap()
      .then(({ meta }) => afterJobAction(meta))
      .catch(({ errors }) => {
        showAlert("danger", errors.colleges[0]);
      });
  };

  const handleClose = () => {
    setShowWidgetModal(false);
    setCurrentTab(WIDGET_TYPES[0].key);
  };

  const widgetDetails = `<div id="joballey-widget-wrapper"></div>
  <script
    src="https://app.${getDomainName()}/api/candidate/jobs/${
      job?.uuid
    }/widget?parent_element_id=joballey-widget-wrapper&widget_type=${currentTab}">
  </script>`;

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(widgetDetails);
      setTextCopied(true);
      setTimeout(() => setTextCopied(false), 1000);
    } catch (err) {
      showAlert(
        "danger",
        "Couldn't copy to clipboard. Make sure you have a secured connection"
      );
    }
  };

  if (!job) {
    return <ActivityIndicator />;
  }

  return (
    <div className="px-4 sm:px-6">
      {job && (
        <>
          <Helmet>
            <title>{`${job.title} - ${SITE_TITLE}`}</title>
            <meta
              name="description"
              content={` View job description for ${job.title} by ${currentUser?.organisation_name} on JobAlley platform.`}
            />
            <meta property="og:image" content="/joballey_logo_dark_text.png" />

            <meta
              property="og:url"
              content={`https://${currentHost}/jobs/${job.uuid}`}
            />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${job.title} | JobAlley`} />
            <meta
              property="og:description"
              content={` View job description for ${job.title} by ${currentUser?.organisation_name} on JobAlley platform.`}
            />
            <meta property="og:image" content="/joballey_logo_dark_text.png" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              property="og:url"
              content={`https://erp.${getDomainName()}/jobs/${job.uuid}`}
            />
            <meta
              property="twitter:domain"
              content={`erp.${getDomainName()}`}
            />
            <meta name="twitter:title" content={`${job.title} | JobAlley`} />
            <meta
              name="twitter:description"
              content={` View job description for ${job.title} by ${currentUser?.organisation_name} on JobAlley platform.`}
            />
            <meta name="twitter:image" content="/joballey_logo_dark_text.png" />
          </Helmet>

          <div className="py-4">
            <div
              className={classNames(
                "relative flex flex-row justify-between pt-6",
                showWidgetDetails && "sm:flex-row flex-col"
              )}
            >
              <div className="flex flex-col">
                <div className="mb-2 flex flex-col md:flex-row md:items-end">
                  <h2 className="text-xl font-semibold" data-testid="job-title">
                    {job.title}
                  </h2>
                  <div className="mt-1 flex items-center gap-2 md:mx-5">
                    <Pill
                      text={startCase(JOB_STATUS[camelCase(job.status)].text)}
                      testId="job-status"
                      type={JOB_STATUS[camelCase(job.status)].type}
                    />
                    {job.status == "active" &&
                      (checkHiringManagerUserRole || isUserRoleAllowed) && (
                        <CopyLink
                          link={`https://app.${getDomainName()}/jobs/${
                            job.uuid
                          }/details`}
                          icon="copy-link"
                          tooltipId="jobLink"
                          tooltipContent="Copy Shareable Link"
                        />
                      )}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "flex flex-row gap-4",
                  showWidgetDetails
                    ? "justify-between relative sm:static"
                    : "ml-auto"
                )}
              >
                {showWidgetDetails && (
                  <Button
                    title="Copy Widget"
                    btnName="Copy Widget"
                    type="tertiary"
                    size="sm"
                    customWidth={32}
                    onClick={() => setShowWidgetModal(true)}
                    btnClassName="h-9 border-none pr-4 pl-0 text-left sm:px-4 sm:text-center"
                  />
                )}
                <Button
                  title="View Pipeline"
                  btnName="View Pipeline"
                  type="secondary"
                  size="sm"
                  customWidth={32}
                  onClick={() =>
                    navigate(
                      `/jobs/${params.uuid}/pipeline?${constructQueryParams({
                        page: 1,
                        page_size: DEFAULT_PAGE_SIZE,
                        lobby: "ALL_APPLICANTS",
                      })}`
                    )
                  }
                  btnClassName="h-9"
                />

                {showOptions && (
                  <DotMenu
                    dropdownMenuRef={dropdownMenuRef}
                    job={job}
                    setIsActive={setIsActive}
                    setIsApplicationClosed={setIsApplicationClosed}
                    setIsClosed={setIsClosed}
                    customTopClass={
                      showWidgetDetails ? "top-10 sm:top-14" : "top-14"
                    }
                  />
                )}

                {(isUserRoleAllowed || checkHiringManagerUserRole) && (
                  <Button
                    title="View Options"
                    testId="job-three-dot-dropdown-btn"
                    btnClassName="flex justify-center items-center w-10 h-10 hover:bg-primary-lightest rounded-full text-primary-main"
                    onClick={() =>
                      setShowOptions((showOptions) => !showOptions)
                    }
                  >
                    <BiDotsVerticalRounded className="text-3xl" />
                  </Button>
                )}
              </div>
            </div>

            <div className="grid lg:grid-cols-2 gap-[35px] mt-5">
              <div className="flex flex-col gap-[35px]">
                <div>
                  <DescriptionCard cardTitle="Job Details">
                    <div className="pb-[10px]" data-testid="job-details">
                      <RenderParsedHTML
                        content={job.description || ""}
                        className="text-sm text-gray-slateCustomShade1"
                      />
                    </div>
                  </DescriptionCard>
                </div>
              </div>
              <div className="flex flex-col gap-[35px]">
                <div>
                  <DescriptionCard cardTitle="Profile Info">
                    <div data-testid="profile-info">
                      {JOB_PROFILE_STATS.map((statDetail) => {
                        if (
                          statDetail.value === "ctc" &&
                          Number(job.ctc) === 0
                        ) {
                          return " ";
                        }
                        return (
                          <StatDetails
                            label={statDetail.label}
                            key={statDetail.key}
                            statValue={job[statDetail.value] || " "}
                          />
                        );
                      })}
                    </div>
                  </DescriptionCard>
                </div>
                <div>
                  <DescriptionCard cardTitle="Preferences">
                    <div data-testid="preferences">
                      {JOB_PREFERENCES.map((statDetails) => {
                        return (
                          <StatDetails
                            label={statDetails.label}
                            key={statDetails.key}
                            statValue={
                              job[statDetails.value]?.length > 0
                                ? job[statDetails.value]
                                : " "
                            }
                            isOrderedList={job[statDetails.value]?.length > 0}
                          />
                        );
                      })}
                    </div>
                  </DescriptionCard>
                </div>
                <div>
                  <DescriptionCard cardTitle="Settings">
                    <div data-testid="settings">
                      <StatDetails
                        label="Visibility"
                        customClassName="pb-[0px]"
                        statValue={capitalize(job.visibility)}
                      />
                      {JOB_SETTINGS.map((setting) => {
                        if (
                          (job.visibility === "RESTRICTED" &&
                            restrictedBy?.length > 0) ||
                          setting.key > 1
                        ) {
                          return (
                            <StatDetails
                              label={setting.label}
                              key={setting.key}
                              statValue={
                                setting.isMultiRecords &&
                                restrictedBy?.length > 0
                                  ? restrictedBy
                                  : job[setting.value]
                              }
                              isOrderedList={
                                setting.isMultiRecords &&
                                restrictedBy?.length > 0
                              }
                              isBooleanField={setting.isBool}
                              customClassName={
                                setting.key !== 4 || job.allow_external_access
                                  ? setting.customClassName
                                  : "pb-[10px]"
                              }
                            />
                          );
                        }
                      })}
                      {job.allow_external_access && (
                        <StatDetails
                          label="Fallback URL"
                          statValue={
                            job.redirect_url ? (
                              <span className="flex break-all gap-1">
                                {job.redirect_url?.length < 50
                                  ? job.redirect_url
                                  : `${job.redirect_url?.slice(0, 50)}...`}
                                <CopyLink
                                  link={job.redirect_url}
                                  icon="copy-link"
                                  tooltipId="copyLink"
                                  tooltipContent="Copy Redirect Link"
                                  iconSize={18}
                                />
                              </span>
                            ) : (
                              " "
                            )
                          }
                        />
                      )}
                      <StatDetails
                        label="Posted By"
                        statValue={
                          <>
                            {job.updated_by ?? job.created_by}
                            {" , "}
                            {job.created_at &&
                              format(
                                new Date(
                                  job.status_updated_at || job.created_at
                                ),
                                "dd/MM/yyyy 'at' h:mm a"
                              )}
                          </>
                        }
                      />
                    </div>
                  </DescriptionCard>
                </div>
              </div>
            </div>
          </div>

          {isApplicationClosed && (
            <Modal
              title="Are you sure?"
              description="Are you sure you want to close application for this job?"
              acceptButtonText="Yes"
              acceptBtnType="primary"
              rejectBtnType="secondary"
              rejectButtonText="No"
              handleAccept={onApplicationCloseJob}
              handleReject={() => setIsApplicationClosed(false)}
            />
          )}

          {isClosed && (
            <Modal
              title="Are you sure?"
              testId="job-close-confirmation-modal"
              description="Are you sure you want to close this job?"
              acceptButtonText="Yes"
              acceptBtnType="primary"
              rejectBtnType="secondary"
              rejectButtonText="No"
              handleAccept={onCloseJob}
              handleReject={() => setIsClosed(false)}
            />
          )}

          {isActive && (
            <Modal
              title="Post Job"
              description="Are you sure you want to post this job?"
              acceptButtonText="Yes"
              rejectButtonText="No"
              acceptBtnType="primary"
              rejectBtnType="secondary"
              handleAccept={() => submitHandler(job)}
              handleReject={() => setIsActive(false)}
            />
          )}

          {showWidgetModal && (
            <Modal
              title="Copy Job Widget"
              customModalWidth="550px"
              acceptButtonText={textCopied ? "Copied" : "Copy"}
              rejectButtonText="Cancel"
              acceptBtnType={textCopied ? "disabled" : "primary"}
              handleAccept={() => handleClick()}
              handleCancel={() => handleClose()}
            >
              <div className="flex flex-col align-middle">
                <ActionAlert
                  message={
                    <p className="text-sm font-semibold">
                      Select the type of widget you want to copy.
                    </p>
                  }
                  alertType="info"
                  iconName="info"
                  isButtonRequire={false}
                  customClassName="border-none py-0 xs:ml-4"
                  iconSize={20}
                />
                <TabList
                  testId="widget-type-tabs"
                  tabs={WIDGET_TYPES}
                  customClassNames="pt-0"
                  currentTab={currentTab}
                  paramBased
                  onTabClick={(key) => {
                    if (key !== currentTab) {
                      setCurrentTab(key);
                    }
                  }}
                />
                <div className="p-2">
                  <CopyBlock
                    text={widgetDetails}
                    language="html"
                    showLineNumbers={false}
                    theme={monoBlue}
                    codeBlock
                    wrapLines
                  />
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

Description.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    requirements: PropTypes.string,
    location: PropTypes.string,
    no_of_positions: PropTypes.number,
    ctc: PropTypes.string,
    restricted_by: PropTypes.shape({
      colleges: PropTypes.bool,
      qualifications: PropTypes.bool,
      skills: PropTypes.bool,
    }),
  }),
};

StatDetails.propTypes = {
  label: PropTypes.string,
  statValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })
    ),
  ]),
  isOrderedList: PropTypes.bool,
  isBooleanField: PropTypes.bool,
  customClassName: PropTypes.string,
};

export default Description;
