import { isEqual } from "lodash";
import moment from "moment";

const filterSearchParams = (
  data,
  stageExamDetails,
  statusParam,
  locationsParams,
  groupsParams,
  setSearchParams
) => {
  const params = new URLSearchParams(window.location.search);

  if (data.has_different_sectional_cut_off_marks) {
    data.sectional_cutoff = encodeURIComponent(
      JSON.stringify(data.sectional_cutoff)
    );
    stageExamDetails[0]?.question_paper?.sections?.map((section) => {
      data[`enter_section_cut_off_marks_${section.id}`] = null;
    });
  }

  if (data.academic_marks) {
    const academicMarkCount = data.academic_marks.length;

    data.academic_marks = encodeURIComponent(
      JSON.stringify(data.academic_marks)
    );

    for (let i = 0; i < academicMarkCount; i += 1) {
      data[`education_level_id_${i}`] = null;
      data[`marks_${i}`] = null;
    }
  }
  // creating an object reqSreachParams containing the prev params and submitted data
  const reqSearchParams = {
    ...Object.fromEntries(params.entries()),
    ...data,
    page: 1,
  };

  Object.keys(reqSearchParams).map((key) => {
    if (["", undefined, null].includes(reqSearchParams[key])) {
      delete reqSearchParams[key];
    } else if (key === "statuses") {
      const newStatus = data.statuses.map((status) => status.value);
      reqSearchParams.statuses = newStatus;
      if (!isEqual(newStatus, statusParam.current)) {
        statusParam.current = newStatus;
      }
    } else if (key === "locations") {
      const newLocations = data.locations.map((status) => status.name);
      reqSearchParams.locations = newLocations;
      if (!isEqual(newLocations, locationsParams.current)) {
        locationsParams.current = newLocations;
      }
    } else if (key === "groups") {
      const newGroups = data.groups.map((group) => group.uuid);
      reqSearchParams.groups = newGroups;
      if (!isEqual(newGroups, groupsParams.current)) {
        groupsParams.current = newGroups;
      }
    } else if (key === "willing_to_relocate" || key === "has_portfolio") {
      if (Array.isArray(data[key])) {
        // eslint-disable-next-line prefer-destructuring
        data[key] = data[key][0];
      }
      reqSearchParams[key] = data[key].value;
    } else if (["cutoff_type", "marks_type"].includes(key)) {
      if (data.has_different_sectional_cut_off_marks) {
        reqSearchParams[key] = data[key].value;
        reqSearchParams.has_different_sectional_cut_off_marks =
          data.has_different_sectional_cut_off_marks;
      } else if (data.cutoff_marks !== "") {
        reqSearchParams[key] = data[key].value;
        reqSearchParams.has_different_sectional_cut_off_marks = Boolean(
          data.has_different_sectional_cut_off_marks
        );
      } else {
        delete reqSearchParams[key];
      }
    } else if (key === "cutoff_marks" && reqSearchParams[key] !== "") {
      reqSearchParams[key] =
        data.marks_type?.value === "PERCENTAGE"
          ? (Number(reqSearchParams[key]) / 100).toString()
          : reqSearchParams[key];
    } else if (key === "from_date" || key === "to_date") {
      reqSearchParams[key] = moment(reqSearchParams[key]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else if (key === "appeared" && !data.from_date) {
      delete reqSearchParams[key];
    }
  });

  setSearchParams(reqSearchParams);
};

export { filterSearchParams };
