import { createPortal } from "react-dom";

let PORTAL = document.getElementById("portal-root");

if (!PORTAL) {
  PORTAL = document.createElement("div");
  PORTAL.setAttribute("id", "portal-root");
  document.body.appendChild(PORTAL);
}

const RenderWithPortal = ({ children }) => createPortal(children, PORTAL);

export default RenderWithPortal;
