import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";

import api from "api/axios";
import {
  FEATURE_FLAGS,
  NAVBAR_PATH_EXCEPTIONS,
  SESSION_EXPIRED,
  TRACKING_ID,
} from "app/constants";
import FallbackRender from "components/atoms/FallbackRender";
import ActivityIndicator from "components/common/ActivityIndicator";
import Alert from "components/common/Alert";
import ApiErrorWrapper from "components/common/ApiErrorWrapper";
import CandidateAnswer from "components/common/CandidateAnswer";
import { FeatureFlagable } from "components/common/FeatureFlagable";
import Footer from "components/common/Footer";
import Layout from "components/common/Layout";
import Navbar from "components/common/Navbar";
import NotFound from "components/common/NotFound";
import { RequireAuth } from "components/common/RequireAuth";
import { RequireNoAuth } from "components/common/RequireNoAuth";
import Version from "components/common/Version";
import { checkPublicPage } from "helpers/checkPublicPage";
import useAlert from "hooks/useAlert";
import { hideAlert } from "store/slices/alertSlice";
import { flagsDetails } from "store/slices/candidate/featureFlagsSlice";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

import ResumePreview from "./CreateResume/ResumePreview";
import ExamInstructions from "./Exam/ExamInstruction/ExamInstructions";
import JobDescription from "./Job/JobDescription";
import RestrictedJobWithToken from "./Job/RestrictedJobWithTokenReviewJobApplication";
import CompleteApplication from "./Job/ReviewJobApplication";
import CreateResume from "./CreateResume";
import Dashboard from "./Dashboard";
import EventPage from "./EventPage";
import ExamComponent from "./Exam";
import FaqPage from "./FAQ";
import InterviewPage from "./Interviews";
import Job from "./Job";
import MockTest from "./MockTest";
import OrganisationProfile from "./OrganisationProfile";
import CompleteProfile from "./Profile";
import Signin from "./Signin";

ReactGA.initialize(TRACKING_ID);

function CandidateApp() {
  const [openAside, setOpenAside] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const showAlert = useAlert();

  const [isLoggedIn, currentUser, alertType, alertMessage] = useSelector(
    ({ auth, alert }) => [
      auth.isLoggedIn,
      auth.currentUser,
      alert.type,
      alert.message,
    ]
  );

  const { isLoading } = useSelector((state) => state.featureFlags);

  useEffect(() => {
    dispatch(setCandidateUserAction())
      .unwrap()
      .then(() => {
        if (location.search?.includes("last_session_expired")) {
          showAlert("info", `${SESSION_EXPIRED}`);
        }
      })
      .catch((error) => {
        if (error?.name === "Error" && !checkPublicPage()) {
          showAlert("danger", "Please login/signup to proceed.");
        } else if (error?.message.includes("401") && !checkPublicPage()) {
          setLoginError(true);
        }
      });
    api.get("/application_feature_flags").then((response) => {
      dispatch(flagsDetails(response.data));
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (typeof isLoggedIn === "undefined" || isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="App">
      {alertMessage && (
        <Alert
          type={alertType}
          message={alertMessage}
          isAlertTriggered={!!alertMessage}
          setIsAlertTriggered={() => dispatch(hideAlert())}
        />
      )}
      <ApiErrorWrapper>
        <ErrorBoundary FallbackComponent={FallbackRender}>
          <Layout
            openAside={openAside}
            setOpenAside={setOpenAside}
            currentUser={currentUser?.data}
          >
            <>
              {!NAVBAR_PATH_EXCEPTIONS.includes(location.pathname) && (
                <Navbar setOpenAside={setOpenAside} />
              )}
              <main className="main-body bg-gray-lighterShade3">
                <Routes>
                  <Route path="*" element={<NotFound />} />
                  <Route element={<RequireNoAuth />}>
                    <Route
                      path="/signup"
                      element={<Signin isRedirect={loginError} isSignupPage />}
                    />
                    <Route
                      path="/login"
                      element={<Signin isRedirect={loginError} />}
                    />
                  </Route>
                  <Route path="/help" element={<FaqPage />} />
                  <Route path="/about" element={<Version />} />
                  <Route
                    path="/jobs/:uuid/details"
                    element={<JobDescription />}
                  />

                  <Route element={<RequireAuth />}>
                    <Route
                      path="/organisations/:id"
                      element={<OrganisationProfile />}
                    />
                    <Route path="/" exact element={<Dashboard />} />
                    <Route path="/profile" element={<CompleteProfile />} />
                    <Route path="/create-resume" element={<CreateResume />} />
                    <Route
                      path="/create-resume-preview"
                      element={<ResumePreview />}
                    />
                    <Route path="/jobs">
                      <Route index element={<Job />} />
                      <Route path=":currentTab" element={<Job />} />
                      <Route
                        path=":uuid/details"
                        element={<JobDescription />}
                      />
                      <Route
                        path=":uuid/apply"
                        element={<CompleteApplication />}
                      />
                      <Route
                        path=":uuid/apply_now/:token"
                        element={<RestrictedJobWithToken />}
                      />
                      <Route path=":uuid/loi" element={<JobDescription />} />
                    </Route>

                    <Route path="/events" element={<EventPage />} />
                    <Route path="/interviews" element={<InterviewPage />} />
                    <Route
                      element={
                        <FeatureFlagable
                          featureName={FEATURE_FLAGS.MOCK_TEST}
                        />
                      }
                    >
                      <Route path="/mock-tests">
                        <Route index element={<MockTest />} />
                        <Route
                          path=":examId/answers"
                          element={<CandidateAnswer />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="/instructions"
                      element={<ExamInstructions />}
                    />
                    <Route path="/question_paper" element={<ExamComponent />} />
                  </Route>
                </Routes>
              </main>

              <Footer />
            </>
          </Layout>
        </ErrorBoundary>
      </ApiErrorWrapper>
    </div>
  );
}

export default CandidateApp;
