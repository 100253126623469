import { Link } from "react-router-dom";
import classNames from "classnames";
import { format, formatDistanceToNow } from "date-fns";
import { startCase } from "lodash";
import PropTypes from "prop-types";

import Pill from "components/atoms/Pill";
import CopyLink from "components/common/CopyLink";
import StatusPill from "components/common/StatusPill";

const InterviewGDRow = ({ event, status }) => {
  return (
    <div
      className="min-h-[150px] py-3 px-2 font-sans"
      data-testid={`event-id-${event.uuid}`}
    >
      <div className="flex items-start justify-between gap-1">
        <div className="flex">
          <span className="pr-6 text-lg font-bold text-black-shade1" id="title">
            {event.title}
          </span>

          <Pill text={startCase(event.type)} type="info" />
        </div>

        {status !== "past" && status !== "feedback_pending" && (
          <CopyLink
            link={event.link || event.address}
            tooltipId="eventLink"
            tooltipContent={`Copy ${startCase(event.type)} ${
              event.link ? "Link" : "Address"
            }`}
            particular={event.address ? "Address" : "Link"}
          />
        )}
      </div>

      <div className="mt-2 mb-2 text-[14px] font-medium text-gray-lightShade1">
        <Link
          title="View Job Details"
          className="cursor-pointer hover:underline"
          to={`/jobs/${event.job_uuid}/details`}
        >
          {event.job_title}
          <br />
          <p className="inline">
            {event.organisation_name}&nbsp;
            {event.job_location ? "|" : ""} {event.job_location}
          </p>
        </Link>
      </div>

      <div className={classNames("flex justify-between mb-2")}>
        <div className="mr-2 text-[12px] text-gray-lightShade1">
          <p className=" text-black-primary">
            {event.start_at && format(new Date(event.start_at), "dd/MM/yyyy")}
          </p>

          <p className="whitespace-nowrap">
            Duration: {event.duration && `${event.duration} mins`}
          </p>
        </div>

        {status === "ongoing" && event.link && (
          <a
            title={`Join ${startCase(event.type)}`}
            href={event.link}
            target="_blank"
            rel="noreferrer"
            className="btn-success block w-28 self-end rounded-full text-center"
            id="event_link"
          >
            Join
          </a>
        )}

        {status === "upcoming" && (
          <div className="self-end text-primary-main text-[15px]">
            {formatDistanceToNow(new Date(event.start_at), {
              addSuffix: true,
            })}
          </div>
        )}

        {status === "past" && event?.candidate_status !== null && (
          <StatusPill label={event?.candidate_status} />
        )}
      </div>
    </div>
  );
};

InterviewGDRow.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming"]),
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    duration: PropTypes.number,
    job_uuid: PropTypes.string,
    job_title: PropTypes.string,
    organisation_name: PropTypes.string,
    job_location: PropTypes.string,
    has_started: PropTypes.bool,
    link: PropTypes.string,
    job_page_number: PropTypes.number,
    candidate_status: PropTypes.string,
    uuid: PropTypes.string,
    type: PropTypes.string,
    address: PropTypes.string,
  }),
};

export default InterviewGDRow;
