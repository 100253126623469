import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getExamCandidateExam } from "api/candidate/candidateApi";

export const setExamCandidate = createAsyncThunk(
  "api/exam_candidate/exam",
  async () => {
    const response = await getExamCandidateExam();
    return response.data;
  }
);

export const examCandidateSlice = createSlice({
  name: "examCandidate",
  initialState: {
    candidate: {},
    exam: {},
    message: null,
  },

  extraReducers: (builder) => {
    builder.addCase(setExamCandidate.fulfilled, (state, action) => {
      state.candidate = action.payload.candidate;
      state.exam = action.payload.exam;
    });
    builder.addCase(setExamCandidate.rejected, (state) => {
      state.message = `Thank You ${state.candidate.name}! Your Exam Has been Ended`;
    });
  },
});

export default examCandidateSlice.reducer;
