import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";

import { CommonSortArrowIcon } from "components/common/Arrow/SortArrow";
import { showTooltipSort } from "helpers/pipeline/sort/showToolTip";
import { toCamelCase } from "helpers/utils";

const PlacedColumnHeaders = ({
  filteredPlacedHeaders,
  sortBy,
  sortOrder,
  onClickSort,
}) => {
  return (
    <>
      {filteredPlacedHeaders.map(
        ({ label, sortingAllowed, sortByText, headerClassName }) => {
          if (sortingAllowed) {
            return (
              <th
                className={classNames("p-4 group text-left", headerClassName)}
                key={label}
              >
                <button
                  data-tooltip-id={`${toCamelCase(label)}Sort`}
                  data-tooltip-content={showTooltipSort(
                    sortByText,
                    sortBy,
                    sortOrder
                  )}
                  onClick={() => onClickSort(sortByText)}
                >
                  <span className="mr-2 text-base font-semibold text-primary-dark">
                    {label}
                  </span>
                  <span className="inline-block">
                    <CommonSortArrowIcon
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      sortByText={sortByText}
                    />
                  </span>
                  <Tooltip id={`${toCamelCase(label)}Sort`} variant="dark" />
                </button>
              </th>
            );
          }

          return (
            <th
              key={label}
              className={classNames(
                "p-4 text-left bg-gray-lighterShade3 sticky top-0",
                headerClassName,
                { "z-10": ["Name", "Id"].includes(label) }
              )}
            >
              <span className="text-base font-semibold text-primary-dark">
                {label}
              </span>
            </th>
          );
        }
      )}
    </>
  );
};

PlacedColumnHeaders.propTypes = {
  filteredPlacedHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      sortByText: PropTypes.string,
      headerClassName: PropTypes.string,
      sortingAllowed: PropTypes.bool,
    })
  ),
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onClickSort: PropTypes.func.isRequired,
};

PlacedColumnHeaders.defaultProps = {
  filteredPlacedHeaders: [],
};

export default PlacedColumnHeaders;
