import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import logo from "assets/images/logo/joballey_logo_light_text.png";
import { Button } from "components/atoms";

import ExamCandidateMetaData from "./ExamCandidateMetaData";

const Header = ({
  examName,
  onFullScreenButtonClick,
  showFullScreenButton,
  isQuestionPresent,
  candidateJobId,
  sectionName,
  duration,
  setIsFinishModalVisible,
}) => {
  const { currentUser } = useSelector((state) => state.auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleHamburgerClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div
        className={classNames(
          "items-center sticky top-0 z-20 flex flex-wrap bg-primary-main p-4 text-white md:flex-nowrap",
          `${
            showFullScreenButton && isQuestionPresent
              ? "justify-start"
              : "justify-start sm:justify-between"
          }`
        )}
      >
        {isQuestionPresent && (
          <button
            title="Menu"
            className="focus:outline-none md:hidden"
            onClick={handleHamburgerClick}
          >
            <AiOutlineMenu className="h-6 w-6" />
          </button>
        )}
        <div>
          <img className="mx-4 pb-1" width={168} src={logo} alt="logo" />
        </div>

        <p
          className={classNames(
            "hidden self-center break-words text-center text-white pl-4 text-xl font-medium sm:block sm:px-5",
            { "mr-32 grow": !(showFullScreenButton && isQuestionPresent) }
          )}
        >
          {examName}
        </p>

        {showFullScreenButton && isQuestionPresent && (
          <Button
            size="md"
            customWidth={34}
            btnClassName="border border-white px-4 self-center rounded-full m-2 py-2"
            onClick={onFullScreenButtonClick}
            type="primary"
            btnName="Full Screen"
            title="Full Screen"
          />
        )}
      </div>
      {isQuestionPresent && (
        <div
          className={classNames(
            "md:w-1/4 md:border-l-2 md:translate-x-0 transform transition-transform duration-300 ease-in-out",
            `${
              sidebarOpen
                ? "md:block sticky w-full top-20 z-20 bg-white lg:hidden"
                : "hidden"
            }`
          )}
        >
          <ExamCandidateMetaData
            examName={examName}
            candidateJobId={candidateJobId}
            candidateName={currentUser.data.name}
            sectionName={sectionName}
            duration={duration}
          />
          <div className="py-5 ml-32 mt-3">
            <Button
              size="md"
              customWidth={38}
              btnClassName="py-2 rounded-full text-sm px-6"
              onClick={() => setIsFinishModalVisible(true)}
              btnName="Finish Exam"
              type="warning"
              title="Finish Exam"
            />
          </div>
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  examName: PropTypes.string,
  onFullScreenButtonClick: PropTypes.func,
  showFullScreenButton: PropTypes.bool,
  isQuestionPresent: PropTypes.bool,
  candidateJobId: PropTypes.number,
  sectionName: PropTypes.string,
  duration: PropTypes.number,
  setIsFinishModalVisible: PropTypes.func,
};

export default Header;
