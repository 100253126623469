import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import classNames from "classnames";
import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Pill from "components/common/Pill";

const McqAnswerScript = ({ candidateAnswer, index }) => {
  const checkAnswerForPill = (answer) => {
    let label;

    if (answer?.correct) {
      label = `+ ${parseFloat(answer.question?.marks)}`;
    } else if (answer?.correct === false) {
      label = `- ${(parseFloat(answer.question?.marks) / 4).toFixed(2)}`;
    } else {
      label = "0";
    }

    return label;
  };

  return (
    <div className="p-3">
      <div className="flex justify-between items-center">
        <span className="mr-2 text-xl font-semibold">Question {index + 1}</span>

        <span>
          {/* eslint-disable-next-line no-nested-ternary */}
          {candidateAnswer.correct ? (
            <span className="flex">
              <Pill
                textColor="text-success-alertDark"
                label="Correct"
                outline
                padding="py-0"
                margin="my-0"
              />
              <Pill
                textColor="text-success-alertDark"
                label={checkAnswerForPill(candidateAnswer)}
                outline
                bgColor="bg-success-alertBg"
                padding="py-0"
                margin="my-0"
              />
            </span>
          ) : candidateAnswer?.correct == false ? (
            <span className="flex">
              <Pill
                textColor="text-danger-alertDark"
                label="Incorrect"
                outline
                padding="py-0"
                margin="my-0"
              />
              <Pill
                textColor="text-danger-alertDark"
                label={checkAnswerForPill(candidateAnswer)}
                outline
                bgColor="bg-danger-alertBg"
                padding="py-0"
                margin="my-0"
              />
            </span>
          ) : (
            <span className="flex">
              <Pill
                textColor="text-gray-500"
                label="Un-attempted"
                outline
                padding="py-0"
                margin="my-0"
              />
              <Pill
                textColor="text-gray-500"
                label={checkAnswerForPill(candidateAnswer)}
                outline
                bgColor="bg-gray-lighterShade2"
                padding="py-0"
                margin="my-0"
              />
            </span>
          )}
        </span>
      </div>
      <RenderParsedHTML
        content={candidateAnswer.question?.text || ""}
        className="flex-1"
      />

      {!!candidateAnswer.question?.images.length && (
        <div className="my-2 flex flex-row space-x-2">
          {candidateAnswer.question?.images.map((image) => (
            <img
              key={image.id}
              src={image.attachment}
              alt={image.attachment_file_name}
              className="h-48 w-80"
            />
          ))}
        </div>
      )}

      <ul className="mt-4 space-y-3 pl-0">
        {candidateAnswer.question?.answers.map((answer) => (
          <li key={answer.id} className="flex list-outside flex-col">
            <div className="flex flex-row">
              <span
                className={classNames("mx-2 self-start", {
                  "text-success-main": answer.correct_answer,
                  "text-danger-main":
                    candidateAnswer.answer_id == answer.id &&
                    !candidateAnswer.correct,
                })}
              >
                <input
                  type="radio"
                  className="mr-2"
                  readOnly
                  checked={candidateAnswer.answer_id === answer.id}
                />
              </span>
              <RenderParsedHTML content={answer.text || ""} />
              <span className="min-w-fit">
                {answer.correct_answer &&
                  candidateAnswer.answer_id === answer.id && (
                    <BsCheckCircleFill className="mx-2 mt-1 text-success-main" />
                  )}
                {answer.correct_answer &&
                  candidateAnswer.answer_id !== answer.id && (
                    <BsCheckCircleFill className="mx-2 mt-1 text-success-main" />
                  )}

                {candidateAnswer.answer_id === answer.id &&
                  !candidateAnswer.correct && (
                    <BsFillXCircleFill className="mx-2 mt-1 text-danger-main" />
                  )}
              </span>
            </div>
            <div>
              {answer.attachment_file_name && (
                <div className="px-4 py-2">
                  <img
                    src={answer.attachment}
                    alt={answer.attachment_file_name}
                    className="max-h-60 max-w-auto"
                  />
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

McqAnswerScript.propTypes = {
  candidateAnswer: PropTypes.shape({
    correct: PropTypes.bool,
    answer_id: PropTypes.number,
    question: PropTypes.shape({
      answers: PropTypes.arrayOf(PropTypes.shape({})),
      images: PropTypes.arrayOf(PropTypes.shape({})),
      text: PropTypes.string,
    }),
  }),
  index: PropTypes.number,
};

export default McqAnswerScript;
