import { createSubscription } from "api/candidate/candidateApi";
import { getCurrentUser } from "api/candidate/profileApi";
import { WEB_PUSH_PUBLIC_VAPID_KEY } from "app/constants";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const binaryString = window.atob(base64);
  const binaryView = new Uint8Array(
    Array.from(binaryString, (char) => char.charCodeAt(0))
  );

  return binaryView;
}

const subscribeOptions = {
  userVisibleOnly: true,
  applicationServerKey: WEB_PUSH_PUBLIC_VAPID_KEY
    ? urlBase64ToUint8Array(WEB_PUSH_PUBLIC_VAPID_KEY)
    : new Uint8Array([]),
};

const sendData = (subscription) => {
  getCurrentUser().then((response) => {
    const data = {
      candidate_uuid: response.data.uuid,
      auth_token: subscription.keys.auth,
      endpoint: subscription.endpoint,
      public_key: subscription.keys.p256dh,
    };
    createSubscription(data);
  });
};

export const subscribe = (registration) => {
  if ("Notification" in window) {
    registration.pushManager?.getSubscription().then((existedSubscription) => {
      if (existedSubscription === null) {
        getCurrentUser().then((response) => {
          if (response.data) {
            registration.pushManager
              .subscribe(subscribeOptions)
              .then((newSubscription) => {
                sendData(JSON.parse(JSON.stringify(newSubscription)));
              })
              // added to handle situation if user doesn't allow notifications
              .catch(() => {});
          }
        });
      }
    });
  }
};
