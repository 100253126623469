import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import PropTypes from "prop-types";

import CopyLink from "components/common/CopyLink";
import StatusPill from "components/common/StatusPill";

const Interview = ({ interview, status }) => {
  return (
    <div
      className="min-h-[150px] py-3"
      data-testid={`interview-id-${interview.id}`}
    >
      <div className="flex items-start justify-between gap-1">
        <span
          className="block pr-1 text-base font-bold text-black-shade1"
          id="title"
        >
          {interview.title}
        </span>
        {status !== "past" && status !== "feedback_pending" && (
          <CopyLink
            link={interview.link}
            tooltipId="interviewLink"
            tooltipContent="Copy Interview Link"
          />
        )}
      </div>

      <div className="mt-2 mb-2 text-[14px] font-medium font-sans text-gray-lightShade1">
        <Link
          title="View Job Details"
          className="cursor-pointer hover:underline"
          to={`/jobs/${interview.job_uuid}/details`}
        >
          {interview.job_title}
          <br />
          <p className="inline">
            {interview.organisation_name}&nbsp;
            {interview.job_location ? "|" : ""} {interview.job_location}
          </p>
        </Link>
      </div>

      <div className="flex justify-between font-sans">
        <div className="text-[12px] text-gray-lightShade1">
          <p className=" text-black-primary">
            {format(new Date(interview.start_at), "dd/MM/yyyy")}
          </p>

          <p className="whitespace-nowrap">
            Duration: {interview.duration && `${interview.duration} mins`}
          </p>
        </div>

        {status === "ongoing" && (
          <a
            title="Join Interview"
            href={interview.link}
            target="_blank"
            rel="noreferrer"
            className="btn-success block w-28 self-end rounded-full text-center"
            id="interview_link"
          >
            Join
          </a>
        )}
        {status === "upcoming" && (
          <div className="self-end text-primary-main text-[14px]">
            {formatDistanceToNow(new Date(interview.start_at), {
              addSuffix: true,
            })}
          </div>
        )}
        {status === "past" && interview?.candidate_status !== null && (
          <StatusPill label={interview?.candidate_status} />
        )}
      </div>
    </div>
  );
};

Interview.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming"]),
  interview: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    interview_type: PropTypes.oneOf(["coding", "mcq"]),
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    duration: PropTypes.number,
    job_uuid: PropTypes.string,
    job_title: PropTypes.string,
    organisation_name: PropTypes.string,
    job_location: PropTypes.string,
    has_started: PropTypes.bool,
    link: PropTypes.string,
    job_page_number: PropTypes.number,
    candidate_status: PropTypes.string,
  }),
};

export default Interview;
