import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { removeResume, uploadResume } from "api/candidate/profileApi";
import { ALERT_TYPE } from "app/constants";
import ActionAlert from "components/atoms/ActionAlert";
import Button from "components/atoms/Button";
import { Modal } from "components/molecules";
import { SITE_TITLE } from "constants/common";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

import BasicInfo from "./BasicInfo";
import CommunicationPreference from "./CommunicationPreference";
import ContactDetails from "./ContactDetails";
import Education from "./Education";
import WorkExperiences from "./Experience";
import Interests from "./Interests";
import Languages from "./Languages";
import PhoneNumbers from "./PhoneNumbers";
import Projects from "./Projects";
import References from "./References";
import Skills from "./Skills";

const CompleteProfile = ({ communicationPreferenceVisibility }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const params = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: { currentPageName: "My Profile" },
      })
    );
  }, []);

  useEffect(() => {
    hideFreshWorkWidget();
    document.title = `Profile - ${SITE_TITLE}`;
  }, []);

  const onDelete = () => {
    return removeResume(currentUser.data.attachment)
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        dispatch(setCandidateUserAction());
      })
      .catch(({ response }) =>
        showAlert("danger", response?.data?.errors?.detail)
      )
      .finally(() => setIsDeleting(false));
  };

  const handleUploadResume = (file) => {
    setIsUploading(true);

    if (file.size > 10000000) {
      showAlert("danger", "File size must be less than 10 MB");
      setIsUploading(false);
    } else {
      const formData = new FormData();
      formData.append("resume", file);

      uploadResume(formData)
        .then(({ meta }) => {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
          dispatch(setCandidateUserAction());
        })
        .catch(({ response }) =>
          showAlert("danger", response?.data?.meta?.message)
        )
        .finally(() => setIsUploading(false));
    }
  };

  return (
    <div className="h-full min-h-screen pt-5">
      {!params.uuid &&
        (!currentUser?.data?.is_profile_completed ||
          (currentUser?.data?.reference_images != undefined &&
            !currentUser?.data?.reference_images[0] &&
            currentUser?.meta !== null)) && (
          <div className="mx-2 sm:mx-4 flex justify-center mb-2 ">
            <ActionAlert
              message={
                <p className="xs:pr-0 pr-4">
                  {currentUser?.meta?.action_message}
                </p>
              }
              alertType="warning"
              iconName="warning"
              isButtonRequire={false}
              roundedBorder
              customClassName="mb-2 sm:px-2"
            />
          </div>
        )}
      <div
        className={classNames(
          "mt-4 sm:mt-2 mr-4 flex flex-col xs:flex-row gap-6 xs:gap-0 flex-1 justify-start xs:justify-end align-baseline absolute xs:static right-0 sm:mr-6",
          params.uuid && "pt-12 sm:pt-8"
        )}
      >
        {currentUser?.data?.attachment ? (
          <Button
            title="My Résumé"
            onClick={() => setIsShown((current) => !current)}
            type="tertiary"
            btnClassName="xs:mx-8 text-sm text-right mx-1.5 xs:text-center"
          >
            My Résumé
            {isShown && (
              <div className="top-50 right-50 absolute z-10 mt-2 rounded-md border bg-white shadow-2xl ">
                <a
                  title="View Résumé"
                  target="_blank"
                  rel="noreferrer"
                  href={currentUser?.data?.resume}
                  className="flex pr-5 pl-3 py-2 text-left text-sm text-black-primary hover:bg-gray-lighter"
                >
                  View Résumé
                </a>
                <Button
                  title="Delete Résumé"
                  btnClassName="flex font-medium pl-3 pr-2 py-2 text-left text-sm text-black-primary hover:bg-gray-lighter"
                  btnName="Delete Résumé"
                  onClick={() => {
                    setIsDeleting(true);
                    setIsShown(false);
                  }}
                />
              </div>
            )}
            {isDeleting && (
              <Modal
                title="Are you sure?"
                description="Are you sure you want to delete your résumé?"
                acceptButtonText="Yes"
                acceptBtnType="primary"
                rejectBtnType="secondary"
                rejectButtonText="No"
                handleAccept={onDelete}
                handleReject={() => setIsDeleting(false)}
              />
            )}
          </Button>
        ) : (
          <>
            <label
              htmlFor="image"
              className="xs:mr-8 mr-2 capitalize text-primary-main hover:cursor-pointer hover:underline"
            >
              <span className="flex justify-end text-sm">
                {isUploading ? (
                  <>
                    <CgSpinner className="mr-2 animate-spin text-xl text-primary-main" />
                    Uploading...
                  </>
                ) : (
                  "Upload Résumé"
                )}
              </span>
            </label>

            <input
              id="image"
              type="file"
              onChange={({ target }) => handleUploadResume(target.files[0])}
              onClick={(e) => {
                e.target.value = null;
              }}
              className="hidden"
              accept=".doc, .docx, .pdf"
              disabled={isUploading}
            />
          </>
        )}
        {/* Upcoming Feature */}
        {/* <Button
          btnClassName="mx-2 text-sm xs:text-center text-right"
          type="tertiary"
          btnName="Create Résumé"
          onClick={() => navigate("/create-resume")}
        /> */}
      </div>

      <BasicInfo currentUser={currentUser} jobApplicationId={params.uuid} />

      <div className="mt-2 grid h-full min-h-screen grid-cols-1 gap-0 px-2 pt-2 pb-8 sm:px-8 md:rounded-t-lg md:px-6 xl:grid-cols-12 xl:gap-8">
        <div className="xl:col-span-7">
          <WorkExperiences />
          <Projects />
          <Education />
          <ContactDetails />
          <References />
          {communicationPreferenceVisibility && windowWidth >= 1200 && (
            <CommunicationPreference currentUser={currentUser} />
          )}
        </div>
        <div className="xl:col-span-5">
          <Skills />
          <Languages />
          <Interests />
          <PhoneNumbers />
          {communicationPreferenceVisibility && windowWidth < 1200 && (
            <CommunicationPreference currentUser={currentUser} />
          )}
        </div>
      </div>
    </div>
  );
};

CompleteProfile.defaultProps = { communicationPreferenceVisibility: true };

CompleteProfile.propTypes = {
  communicationPreferenceVisibility: PropTypes.bool,
};

export default CompleteProfile;
