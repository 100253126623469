import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { FcInfo } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import { isBefore, toDate } from "date-fns";
import PropTypes from "prop-types";

import {
  createInterview,
  getAllUsers,
  updateInterview,
} from "api/erp/organizationApi";
import { ALERT_TYPE } from "app/constants";
import { LabeledInput } from "components/atoms";
import { Modal } from "components/molecules";
import {
  dateTimeValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";

const ScheduleInterviewModal = ({
  setShowScheduleInterviewModal,
  candidateId,
  currentStageId,
  interview,
  getCandidateStageData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setError,
    reset,
    watch,
  } = useForm();

  const showAlert = useAlert();
  const params = useParams();
  const { currentUser } = useSelector((state) => state.auth);

  const interviewName = watch("title");
  const startAt = watch("start_at");
  const duration = watch("duration");
  const anyFieldEmpty = !interviewName || !startAt || !duration;

  const loadAllUsersOptions = async (search, _, { page }) => {
    const { data, meta } = await getAllUsers(page, search, [
      "ADMIN",
      "INTERVIEWER_MODERATOR",
    ]);
    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const setErrorsOnFields = ({ errors }) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (key == "user_ids") {
        setError("interviewers", { message: value[0] });
      } else {
        setError(key, { message: value[0] });
      }
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    // Structuring req body
    const fieldValue = {};
    fieldValue.title = data.title;
    fieldValue.link = data.link;
    fieldValue.start_at =
      data.start_at && new Date(data.start_at).toISOString();

    fieldValue.duration = Number(data.duration);

    if (interview) {
      if (data.interviewers.length > 0) {
        fieldValue.user_ids = data.interviewers.map(({ id }) => id);
      }

      return updateInterview(
        params.uuid,
        currentStageId,
        candidateId,
        data.id,
        fieldValue
      )
        .then(({ meta }) => {
          getCandidateStageData();
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
          setShowScheduleInterviewModal(false);
        })
        .catch((errors) => {
          showAlert(
            ALERT_TYPE[errors.response.data.meta.message_type],
            errors.response.data.meta.message
          );
          setErrorsOnFields(errors.response.data);
        });
    }
    fieldValue.user_ids = data.interviewers?.map(({ id }) => id);

    return createInterview(params.uuid, currentStageId, candidateId, fieldValue)
      .then(({ meta }) => {
        getCandidateStageData();
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        setShowScheduleInterviewModal(false);
      })
      .catch((errors) => {
        showAlert("danger", errors.response.data.message);
        setErrorsOnFields(errors.response.data);
      });
  };

  const modalHeaderLabel = () => {
    if (interview) {
      return "Edit Interview";
    }
    return "Schedule Interview";
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() <= selectedDate.getTime();
  };

  const customSelect = {
    maxHeight: "80px",
    overflowY: "auto",
  };

  useEffect(() => {
    if (interview) {
      reset({
        ...interview,
        interviewers: interview.interview_users?.map(
          ({ user_id: userId, user_name: userName }) => {
            return { id: userId, name: userName || "" };
          }
        ),
      });
    }
  }, [interview]);

  const formatOptionLabel = (option, field) => {
    const isIdPresent = field?.value?.some((item) => item.id === option.id);

    return (
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold break-words max-w-[180px] sm:max-w-[250px]">
          {option.name}
        </span>
        {isIdPresent ? null : (
          <span className="text-xs pb-1">
            {option.email || option.user_name}
          </span>
        )}
      </div>
    );
  };

  return (
    <Modal
      title={modalHeaderLabel()}
      testId="schedule-interview-modal"
      acceptButtonText={`${interview ? "Update" : "Create"}`}
      acceptBtnType={anyFieldEmpty || !isDirty ? "disabled" : "primary"}
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setShowScheduleInterviewModal(false)}
      isDirty={isDirty}
    >
      <form
        data-testid="Candidate-ScheduleInterview-Form"
        className="my-3"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <LabeledInput
          id="title"
          labelClassNames={classNames("block mt-2 mb-1 font-medium required", {
            "text-danger-main": errors.title,
          })}
          label="Interview Name"
          inputFieldId="title"
          name="title"
          inputClassNames={classNames(
            "w-full",
            `${errors.title ? "input-error" : "input"}`
          )}
          placeholder="Enter interview name"
          register={register}
          type="text"
          validation={{
            required: requiredValidation(),
          }}
          errorMessage={errors.title?.message}
          errorMessageClassNames="text-sm text-danger-dark"
        />
        <div className="mt-3 flex flex-col justify-between sm:flex-row">
          <div className="w-full flex-col sm:w-5/12">
            <LabeledInput
              id="start_at"
              labelClassNames={classNames(
                "block mt-2 mb-1 font-medium required",
                { "text-danger-main": errors.start_at }
              )}
              label="Start"
            />

            <Controller
              control={control}
              name="start_at"
              rules={{
                required: requiredValidation(),
                validate: (startDate) => {
                  if (isBefore(new Date(startDate), new Date())) {
                    return dateTimeValidation();
                  }
                },
              }}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Start date and time"
                  className={`${
                    errors.start_at ? "input-error" : "input"
                  } w-full`}
                  minDate={new Date()}
                  filterTime={filterPassedTime}
                  dateFormat="dd/MM/yyyy h:mm aa"
                  showTimeSelect
                  timeFormat="p"
                  styles={reactSelectStyle(errors.end_at)}
                  timeIntervals={15}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value && toDate(new Date(field.value))}
                />
              )}
            />

            {errors.start_at && (
              <p className="text-sm text-danger-dark">
                {errors.start_at.message}
              </p>
            )}
          </div>

          <div className="w-full flex-col sm:w-5/12">
            <LabeledInput
              id="duration"
              labelClassNames={classNames(
                "block mt-2 mb-1 font-medium required",
                { "text-danger-main": errors.duration }
              )}
              label="Duration"
              inputFieldId="duration"
              name="duration"
              inputClassNames={classNames(
                "w-full",
                `${errors.duration ? "input-error" : "input"}`
              )}
              placeholder="Enter duration"
              register={register}
              type="number"
              validation={{
                required: requiredValidation(),
                min: {
                  value: 10,
                  message: "must be greater than or equal to 10",
                },
              }}
              errorMessage={errors.duration?.message}
              errorMessageClassNames="text-sm text-danger-dark"
            >
              <span className="text-xs font-normal"> in minutes</span>
            </LabeledInput>
          </div>
        </div>
        <LabeledInput
          id="interviewers"
          labelClassNames={classNames("block mt-2 mb-1 font-medium", {
            "text-danger-main": errors.interviewers,
          })}
          label="Interviewers"
          errorMessage={errors.interviewers?.message}
          errorMessageClassNames="text-sm text-danger-dark"
        />
        <Controller
          name="interviewers"
          control={control}
          render={({ field }) => (
            <AsyncPaginate
              isClearable
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              maxMenuHeight={152}
              id="interviewers-select"
              menuPlacement="auto"
              className="w-full"
              styles={reactSelectStyle(errors.interviewers, customSelect)}
              value={field.value}
              loadOptions={loadAllUsersOptions}
              onChange={(value) => field.onChange(value)}
              debounceTimeout={1000}
              placeholder="Select interviewers"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              formatOptionLabel={(option) => formatOptionLabel(option, field)}
              additional={{ page: 1 }}
            />
          )}
        />

        <LabeledInput
          id="link"
          labelClassNames={classNames("block mt-2 mb-1 font-medium", {
            "text-danger-main": errors.link,
          })}
          label="Meeting Link"
          inputFieldId="link"
          name="link"
          inputClassNames={classNames(
            "w-full mb-2 disabled:bg-slate-200",
            `${errors.link ? "input-error" : "input"}`
          )}
          placeholder="Enter interview meeting link"
          register={register}
          type="url"
          disabled={interview && interview.is_notified}
          errorMessage={errors.link?.message}
          errorMessageClassNames="text-sm text-danger-dark"
        />

        {currentUser?.data?.has_google_account && (
          <div className="flex">
            <FcInfo size={interview && !interview?.link ? 60 : 20} />
            <span className="ml-2 mb-12 text-sm text-secondary-alertDark ">
              {interview && !interview?.link
                ? "The meeting link could not be auto generated due to lack of permissions. Please log in back using google and reschedule it to get auto generated meeting link."
                : "If a meeting link is not pasted, it will be generated automatically with you as the organizer."}
            </span>
          </div>
        )}
      </form>
    </Modal>
  );
};

ScheduleInterviewModal.propTypes = {
  setShowScheduleInterviewModal: PropTypes.func,
  candidateId: PropTypes.number,
  currentStageId: PropTypes.number,
  getCandidateStageData: PropTypes.func,
  interview: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    is_notified: PropTypes.bool,
    link: PropTypes.string,
    start_at: PropTypes.string,
    interview_users: PropTypes.array,
  }),
};

export default ScheduleInterviewModal;
