import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import { Modal } from "components/molecules";
import { deleteWorkExperiencesAction } from "store/thunkActions/candidate/workExperienceThunk";

import DescriptiveCard from "../Common/DescriptiveCard";

import Form from "./Form";

const WorkExperience = ({ workExperience }) => {
  const [isEditState, setIsEditState] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  const onDelete = () => {
    return dispatch(deleteWorkExperiencesAction(workExperience.id));
  };

  return isEditState ? (
    <Form workExperience={workExperience} setIsEditState={setIsEditState} />
  ) : (
    <DescriptiveCard
      title={workExperience.company_name}
      editButtonTitle="Edit Experience"
      deleteButtonTitle="Delete Experience"
      editButtonTestId="editBtn"
      deleteButtonTestId="deleteBtn"
      setIsEditState={setIsEditState}
      setIsDeleting={setIsDeleting}
    >
      <p className="text-sm">
        {workExperience.title} |
        <span> {workExperience.employment_type_name}</span>
      </p>
      <p className="text-sm italic text-gray-main">
        {moment(workExperience.start_at).format("MMMM YYYY")} -{" "}
        {workExperience.is_presently_working
          ? "Present"
          : moment(workExperience.end_at).format("MMMM YYYY")}
      </p>

      <div className="mt-2">
        <RenderParsedHTML content={workExperience.description || ""} />
      </div>

      {isDeleting && (
        <Modal
          title="Confirm Delete"
          description="This will delete the experince from your profile."
          acceptButtonText="OK, Delete"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="Cancel"
          customButtonWidth={32}
          handleAccept={onDelete}
          handleReject={() => setIsDeleting(false)}
        />
      )}
    </DescriptiveCard>
  );
};

WorkExperience.propTypes = {
  workExperience: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    is_presently_working: PropTypes.bool,
    company_id: PropTypes.number,
    company_name: PropTypes.string,
    employment_type_id: PropTypes.number,
    employment_type_name: PropTypes.string,
  }),
};

export default WorkExperience;
