import React from "react";
import {
  bool,
  func,
  instanceOf,
  node,
  number,
  oneOf,
  string,
} from "prop-types";

const LabeledInput = ({
  id,
  labeTestId,
  label,
  type,
  containerClassNames,
  labelClassNames,
  errorMessage,
  errorMessageClassNames,
  containerTestId,
  inputTestId,
  errorFieldTestId,
  children,
  ...inputFieldProps
}) => (
  <div data-testid={containerTestId} className={containerClassNames}>
    {label && (
      <label className={labelClassNames} data-testid={labeTestId} htmlFor={id}>
        {label} {children}
      </label>
    )}
    <InputField
      type={type}
      inputTestId={inputTestId}
      inputFieldId={id}
      {...inputFieldProps}
    />
    {errorMessage && (
      <p data-testid={errorFieldTestId} className={errorMessageClassNames}>
        {errorMessage}
      </p>
    )}
  </div>
);

const InputField = ({
  register,
  name,
  type,
  inputTestId,
  inputFieldId,
  inputClassNames,
  validation,
  disabled,
  rows,
  ...inputFieldProps
}) => {
  switch (type) {
    case "text":
    case "number":
      return (
        <input
          type={type}
          id={inputFieldId}
          className={`${inputClassNames} ${disabled ? "bg-gray-200" : ""}`}
          data-testid={inputTestId}
          disabled={disabled}
          {...(register ? register(name, validation) : {})}
          {...inputFieldProps}
        />
      );

    case "tel":
      return (
        <div className="flex">
          <span className="text-md flex items-center rounded-l-sm bg-gray-200 px-3 outline outline-1 outline-gray-400">
            +91
          </span>

          <input
            type={type}
            id={inputFieldId}
            className={inputClassNames}
            data-testid={inputTestId}
            disabled={disabled}
            {...(register ? register(name, validation) : {})}
            {...inputFieldProps}
          />
        </div>
      );

    case "textarea":
      return (
        <textarea
          data-testid={inputTestId}
          id={inputFieldId}
          rows={rows}
          className={inputClassNames}
          disabled={disabled}
          {...(register ? register(name, validation) : {})}
          {...inputFieldProps}
        />
      );

    case "url":
      return (
        <input
          type={type}
          data-testid={inputTestId}
          id={inputFieldId}
          className={inputClassNames}
          disabled={disabled}
          {...(register ? register(name, validation) : {})}
          {...inputFieldProps}
        />
      );
    // TODO: Add other input fields eg single select, Date select.
    default:
      return null;
  }
};

InputField.propTypes = {
  type: oneOf(["text", "number", "tel", "textarea", "url"]).isRequired,
  inputFieldProps: instanceOf(Object),
  inputTestId: string,
  inputFieldId: string,
  inputClassNames: string,
  name: string,
  register: func,
  validation: instanceOf(Object),
  disabled: bool,
  rows: number,
};

InputField.defaultProps = {
  register: null,
  name: "",
  validation: {},
};

LabeledInput.propTypes = {
  id: string.isRequired,
  labeTestId: string.isRequired,
  label: string,
  children: node,
  type: oneOf(["text", "number", "tel", "textarea"]).isRequired, // Update types when new cases added
  errorMessage: string,
  containerClassNames: string,
  labelClassNames: string,
  inputClassNames: string,
  errorMessageClassNames: string,
  containerTestId: string,
  inputTestId: string.isRequired,
  errorFieldTestId: string,
};

LabeledInput.defaultProps = {
  errorMessageClassNames: "text-sm text-danger-dark",
  containerTestId: "labelled-input-container",
  label: "",
  errorMessage: "",
  containerClassNames: "",
  labelClassNames: "",
  labeTestId: "",
  inputTestId: "",
  errorFieldTestId: "error-message",
};

export default LabeledInput;
