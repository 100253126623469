import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FormattedTime } from "react-intl";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { Modal } from "components/molecules";
import { requiredValidation } from "helpers/genericErrorMessages";

const MultiSlotForm = ({
  formId,
  pickedDate,
  slotTimes,
  register,
  watch,
  errors,
  setError,
  clearErrors,
  testId,
  Controller,
  control,
  getValues,
  candidateLeft,
  setValue,
  onCandidateScheduledChange,
  handleDeleteDate,
  maximumCandidate,
}) => {
  const [scheduledFor, setScheduledFor] = useState(0);
  const [isDeleteDateModalOpen, setIsDeleteDateModalOpen] = useState(false);
  const calculateSum = () => {
    let sum = 0;
    let countForThisDay = 0;
    const slotsArray = getValues(`slotValues`);

    slotsArray.forEach((slots, index) => {
      const slotKeys = Object.keys(slots);

      slotKeys.forEach((key) => {
        if (key.startsWith("check_") && slots[key]) {
          const time = key.split("_")[1];
          const inputKey = `slotValues[${index}].input_${time}`;
          const value =
            parseInt(getValues(inputKey) || 0, 10) > 0
              ? parseInt(getValues(inputKey) || 0, 10)
              : 0;
          countForThisDay += index === formId ? value : 0;
          sum += value || 0;
        }
      });
    });
    setScheduledFor(countForThisDay);
    return sum;
  };

  const validateSum = () => {
    const sum = calculateSum();
    return sum <= candidateLeft;
  };
  const blockInvalidChar = (e) =>
    ["+", "-"].includes(e.key) && e.preventDefault();

  const handleEqualCandidateCheckboxToggle = () => {
    if (getValues(`slotValues[${formId}].equal_candidate`)) {
      const slots = getValues(`slotValues[${formId}]`);
      const slotKeys = Object.keys(slots);

      slotKeys.forEach((key) => {
        if (key.startsWith("check_") && slots[key]) {
          const time = key.split("_")[1];
          const inputKey = `slotValues[${formId}].input_${time}`;
          setValue(inputKey, "");
        }
      });
    } else {
      setValue(`slotValues[${formId}].candidates_per_slot`, "");
      clearErrors();
    }
    const isSumValid = validateSum();

    if (isSumValid) {
      clearErrors();
    } else {
      setError(`slotValues[${formId}].candidates_per_slot`, {
        message: "Not enough candidates in this stage",
      });
    }
    onCandidateScheduledChange();
  };

  const onTrashIconClickHandler = () => {
    handleDeleteDate(pickedDate, formId);
    calculateSum();
  };

  const handleCandidateInputChange = (time) => {
    const isSumValid = validateSum();
    const fieldName = `slotValues[${formId}].input_${time}`;
    if (isSumValid) {
      clearErrors();
    } else {
      setError(fieldName, { message: "Not enough candidates in this stage" });
    }

    onCandidateScheduledChange();
  };

  const handleCandidateCheckBoxChange = (time) => {
    const fieldName = `slotValues[${formId}].input_${time}`;
    if (getValues(`slotValues[${formId}].check_${time}`)) {
      if (getValues(`slotValues[${formId}].equal_candidate`)) {
        if (
          +getValues(`slotValues[${formId}].candidates_per_slot`) +
            calculateSum() >
          candidateLeft
        ) {
          setValue(fieldName, candidateLeft - calculateSum());
        } else {
          setValue(
            fieldName,
            getValues(`slotValues[${formId}].candidates_per_slot`)
          );
        }
      }
    } else {
      clearErrors(fieldName);
      setValue(fieldName, "");
    }

    const isSumValid = validateSum();

    if (isSumValid) {
      clearErrors();
    } else if (getValues(`slotValues[${formId}].equal_candidate`)) {
      setError(`slotValues[${formId}].candidates_per_slot`, {
        message: "Not enough candidates in this stage",
      });
    }

    onCandidateScheduledChange();
  };

  const handleCandidatePerSlotChange = () => {
    // This function is to show the value of input as the value of candidate per slot if equal candidate toggle om
    const candidatesPerSlotValue = getValues(
      `slotValues[${formId}].candidates_per_slot`
    );

    const slotsArray = getValues(`slotValues`);
    const totalCandidateLeft = candidateLeft - calculateSum() + scheduledFor;

    slotsArray.forEach((slots) => {
      const slotKeys = Object.keys(slots);
      let i = 0;
      slotKeys.forEach((key) => {
        if (key.startsWith("check_") && slots[key]) {
          i += 1;
          const time = key.split("_")[1];
          const inputKey = `slotValues[${formId}].input_${time}`;
          if (
            +getValues(`slotValues[${formId}].candidates_per_slot`) * i >
            totalCandidateLeft
          ) {
            const val =
              totalCandidateLeft >
              +getValues(`slotValues[${formId}].candidates_per_slot`) * (i - 1)
                ? totalCandidateLeft -
                  +getValues(`slotValues[${formId}].candidates_per_slot`) *
                    (i - 1)
                : 0;
            setValue(inputKey, val);
          } else {
            setValue(inputKey, candidatesPerSlotValue);
          }
        }
      });
    });

    const isSumValid = validateSum();
    if (isSumValid) {
      clearErrors();
    } else {
      setError(`slotValues[${formId}].candidates_per_slot`, {
        message: "Not enough candidates in this stage",
      });
    }

    onCandidateScheduledChange();
  };

  return (
    <div data-testid={testId}>
      <div className="flex justify-between mt-2">
        <div className="flex flex-row gap-3">
          <h4 className="text-primary-dark font-semibold">
            {`${format(new Date(pickedDate), "dd/MM/yyyy")}`}
          </h4>
          <AiFillDelete
            data-testid="delete-date-btn"
            onClick={() => setIsDeleteDateModalOpen(true)}
            className="cursor-pointer text-lg mb-1 self-end text-danger-main"
          />
        </div>
        <p className="text-primary-dark">Scheduled for: {scheduledFor}</p>
      </div>
      {isDeleteDateModalOpen && (
        <Modal
          title="Are you sure?"
          testId="delete-date-container"
          customModalWidth="540px"
          scrollClass="overflow-auto"
          handleReject={() => setIsDeleteDateModalOpen(false)}
          handleAccept={() => {
            onTrashIconClickHandler();
          }}
          acceptButtonText="Yes"
          rejectButtonText="No"
          acceptBtnType="primary"
          rejectBtnType="secondary"
        >
          Are you sure you want to delete this Date?
        </Modal>
      )}

      <input
        value={pickedDate}
        hidden
        type="text"
        name={`slotValues[${formId}].date`}
        id={`slotValues[${formId}].date`}
        {...register(`slotValues[${formId}].date`)}
      />

      <div className="flex flex-col">
        <div className="flex sm:flex-row flex-col justify-between mt-5">
          <div className="flex flex-row justify-between">
            <div className="flex-col md:w-5/12 w-full">
              <Controller
                name={`slotValues[${formId}].equal_candidate`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <label
                    htmlFor={`slotValues[${formId}].equal_candidate`}
                    className="flex items-center relative w-max cursor-pointer select-none"
                  >
                    <span className="text-base mr-3">
                      Equal candidates per slot
                    </span>

                    <input
                      {...field}
                      type="checkbox"
                      className="appearance-none cursor-pointer w-16 h-6 ml-4 rounded-full bg-gray-400"
                      id={`slotValues[${formId}].equal_candidate`}
                      data-testid="equal-candidate"
                      onChange={(e) => {
                        field.onChange(e);
                        handleEqualCandidateCheckboxToggle();
                      }}
                    />

                    <span className="absolute font-small text-xs uppercase right-1.5 text-white">
                      OFF
                    </span>
                    <span className="absolute font-medium text-xs uppercase right-10 text-white">
                      ON
                    </span>
                    <span className="w-7 h-4 right-8 absolute rounded-full transform transition-transform bg-white" />
                  </label>
                )}
              />
            </div>
          </div>

          {watch(`slotValues[${formId}].equal_candidate`) && (
            <div className="flex flex-row sm:w-1/2 sm:mt-0 mt-3 w-full justify-between">
              <Controller
                name={`slotValues[${formId}].candidates_per_slot`}
                control={control}
                rules={{
                  required: requiredValidation(),
                  min: {
                    value: 1,
                    message: "Minimum number of candidate is 1!",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid number",
                  },
                }}
                render={({ field }) => (
                  <>
                    <label
                      htmlFor={`slotValues[${formId}].candidates_per_slot`}
                      className={`required mb-1 font-medium text-sm w-2/5 ${
                        errors?.slotValues?.[formId]?.candidates_per_slot
                          ? "text-danger-main"
                          : ""
                      }`}
                    >
                      No. of candidate per slot
                    </label>
                    <input
                      {...field}
                      type="number"
                      id={`slotValues[${formId}].candidates_per_slot`}
                      className={`${
                        errors?.slotValues?.[formId]?.candidates_per_slot
                          ? "input-error"
                          : "input"
                      } sm:w-1/2 w-1/4`}
                      data-testid="candidates-per-slot"
                      onChange={(e) => {
                        field.onChange(e);
                        handleCandidatePerSlotChange();
                      }}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                    />
                  </>
                )}
              />
            </div>
          )}
        </div>

        {errors?.slotValues?.[formId]?.candidates_per_slot && (
          <p className="ml-auto text-sm text-danger-dark">
            {errors.slotValues[formId].candidates_per_slot.message}
          </p>
        )}
      </div>

      {slotTimes.map((time) => (
        <div key={time} className="flex flex-col">
          <div className="flex justify-between mt-4">
            <div className="px-1">
              <Controller
                name={`slotValues[${formId}].check_${time}`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <input
                      {...field}
                      type="checkbox"
                      id={`slotValues[${formId}].check_${time}`}
                      defaultChecked={watch(
                        `slotValues[${formId}].check_${time}`
                      )}
                      data-testid="input-checkbox"
                      onChange={(e) => {
                        field.onChange(e);
                        handleCandidateCheckBoxChange(time);
                      }}
                    />
                    <label
                      htmlFor={`slotValues[${formId}].check_${time}`}
                      className="ml-2"
                    >
                      <FormattedTime value={new Date(time)} />
                    </label>
                  </>
                )}
              />
            </div>
            <Controller
              name={`slotValues[${formId}].input_${time}`}
              control={control}
              rules={{
                required: {
                  value:
                    watch(`slotValues[${formId}].check_${time}`) &&
                    !watch(`slotValues[${formId}].equal_candidate`),
                  message: requiredValidation(),
                },
                min: watch(`slotValues[${formId}].check_${time}`) &&
                  !watch(`slotValues[${formId}].equal_candidate`) && {
                    value: 1,
                    message: "Minimum number of candidates must be 1!",
                  },
                max: {
                  value: maximumCandidate,
                  message: `Maximum number of candidate ${maximumCandidate}!`,
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Invalid number",
                },
              }}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  id={`slotValues[${formId}].input_${time}`}
                  disabled={
                    watch(`slotValues[${formId}].equal_candidate`) ||
                    !watch(`slotValues[${formId}].check_${time}`)
                  }
                  className={`${
                    errors?.slotValues?.[formId]?.[`input_${time}`]
                      ? "input-error"
                      : "input"
                  } w-1/4`}
                  data-testid="input-value"
                  onChange={(e) => {
                    field.onChange(e);
                    handleCandidateInputChange(time);
                  }}
                  onKeyDown={blockInvalidChar}
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
          </div>

          {errors?.slotValues?.[formId]?.[`input_${time}`] && (
            <p className="ml-auto text-sm text-danger-dark">
              {errors.slotValues[formId][`input_${time}`].message}
            </p>
          )}
        </div>
      ))}
      <hr className="mt-5" />
    </div>
  );
};

MultiSlotForm.defaultProps = {
  errors: {},
  testId: "",
};

MultiSlotForm.propTypes = {
  pickedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  formId: PropTypes.number.isRequired,
  slotTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      slotValues: PropTypes.arrayOf(
        PropTypes.shape({
          message: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  setError: PropTypes.func.isRequired,
  candidateLeft: PropTypes.number.isRequired,
  clearErrors: PropTypes.func.isRequired,
  testId: PropTypes.string,
  Controller: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  onCandidateScheduledChange: PropTypes.func.isRequired,
  handleDeleteDate: PropTypes.func.isRequired,
  maximumCandidate: PropTypes.number.isRequired,
};

export default MultiSlotForm;
