const freshWorkWidget = ({ data: { name, email } }) => {
  const freshWorkWidgetInitscript = document.createElement("script");
  freshWorkWidgetInitscript.innerHTML = `	window.fwSettings={
              'widget_id':89000000643
              };
              !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
  document.body.appendChild(freshWorkWidgetInitscript);

  const freshWorkWidgetLinkscript = document.createElement("script");
  freshWorkWidgetLinkscript.src =
    "https://ind-widget.freshworks.com/widgets/89000000643.js";
  freshWorkWidgetLinkscript.async = true;
  freshWorkWidgetLinkscript.defer = true;
  document.body.appendChild(freshWorkWidgetLinkscript);

  const freshWorkWidgetPrefilscript = document.createElement("script");
  freshWorkWidgetPrefilscript.innerText = `
          FreshworksWidget('prefill', 'ticketForm', {
            name: '${name}',
            email: '${email}',
          })`;
  document.body.appendChild(freshWorkWidgetPrefilscript);

  const freshWorkWidgetDisbaleEmailscript = document.createElement("script");
  freshWorkWidgetDisbaleEmailscript.innerText = `
            FreshworksWidget('disable', 'ticketForm',['email'], {})`;
  document.body.appendChild(freshWorkWidgetDisbaleEmailscript);
};

export default freshWorkWidget;
