import { useState } from "react";
import { Helmet } from "react-helmet";

import { SITE_TITLE } from "constants/common";

import Form from "./Form";

const Organisations = () => {
  const [showMessage, setShowMessage] = useState(false);

  return (
    <div className="mx-auto max-w-[49rem] rounded-lg p-4">
      <Helmet>
        <title>{`Create Organisation - ${SITE_TITLE}`}</title>
      </Helmet>
      {!showMessage ? (
        <div>
          <div className="flex items-center justify-between">
            <h3 className="m-auto text-2xl font-semibold text-primary-main">
              Create Organisation
            </h3>
          </div>
          <Form setShowMessage={setShowMessage} />
        </div>
      ) : (
        <div className="mt-60 flex flex-col text-center">
          <h3 className="text-2xl text-primary-main ">
            <p>Thank you for showing interest in JobAlley!</p>
          </h3>
          <p>
            We have registered your request and will be reaching out to you
            shortly for further processing.
          </p>
        </div>
      )}
    </div>
  );
};

export default Organisations;
