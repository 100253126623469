import { forwardRef } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { CgSpinner } from "react-icons/cg";
import { bool } from "prop-types";

const ActivityIndicator = forwardRef(({ main = false }, forwardedRef) => (
  <div ref={forwardedRef} className="mt-8 flex w-full justify-center">
    {main ? (
      <CgSpinner
        className="animate-spin text-8xl text-primary-main"
        data-testid="cgSpinner"
      />
    ) : (
      <BiLoaderAlt
        className="animate-spin text-6xl text-primary-main"
        data-testid="biLoader"
      />
    )}
  </div>
));

ActivityIndicator.displayName = "ActivityIndicator";

ActivityIndicator.propTypes = { main: bool };

ActivityIndicator.defaultProps = { main: false };

export default ActivityIndicator;
