import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { BiX } from "react-icons/bi";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import { isAfter, isBefore, toDate } from "date-fns";
import { startCase, upperCase } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import { createExam, updateExam } from "api/erp/organizationApi";
import {
  getDriverCodes,
  getQuestionPapersByType,
} from "api/erp/questionPaperApi";
import {
  ALERT_TYPE,
  CAMERA_OPTION,
  DECIMAL_INFO,
  QUESTION_TYPE,
  WEBSITE_REGEX,
} from "app/constants";
import { LabeledInput } from "components/atoms";
import { Modal } from "components/molecules";
import { CUT_OFF_TYPES, MARKS_TYPES } from "constants/common";
import {
  characterValidation,
  requiredValidation,
  toDateTimeValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";

const ExamModal = ({
  currentStageId,
  setOpenModal,
  getExamData,
  stageExamDetail,
  setShowMultiSlotConfirmation,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, errors },
    setError,
    clearErrors,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: 45,
  });

  const showAlert = useAlert();
  const params = useParams();
  const slotDurationRef = useRef();
  const [checked, setChecked] = useState(false);
  const [sectionIds, setSectionIds] = useState([]);

  const questionPaperType = watch("question_paper_type");
  const questionPaper = watch("question_paper");
  const name = watch("name");
  const duration = watch("duration");
  const cutOffType = watch("cut_off_type");

  const handleScrollOnClick = (e) => {
    if (e.target.checked) {
      // set time out has been added as after clicking on the toggle it renders the input into the dom
      setTimeout(() => {
        slotDurationRef.current?.scrollIntoView();
      }, 100);
      setValue("slot_active_duration", "");
      clearErrors("slot_active_duration");
    }
  };

  const handleAutoEvaluateCandidateClick = (e) => {
    if (!e.target.checked) {
      setValue("auto_email", false);
    }
  };

  const loadQuestionPaperOptions = async (
    search,
    _,
    { page },
    type = questionPaperType.name
  ) => {
    const { data, meta } = await getQuestionPapersByType(page, search, type);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const afterExamCreateActions = (meta) => {
    showAlert(ALERT_TYPE[meta.message_type], meta.message);
    getExamData();
  };

  const codingLanguageOptions = async (search, _, { page }) => {
    const { data } = await getDriverCodes(page, search);
    return {
      options: data,
    };
  };

  const setErrorsOnFields = ({ errors }) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (key == "detail") {
        setError("end_at", { message: value });
      } else if (key == "message") {
        setError("end_at", { message: value });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const submitHandlerExam = (data) => {
    const examData = { ...data };
    const sectionalCutOff = [];
    examData.slot_active_duration = parseInt(data.slot_active_duration, 10);
    examData.cut_off_marks = checked ? null : data.cut_off_marks;
    examData.has_different_sectional_cut_off_marks = checked;

    if (checked) {
      for (let i = 0; i < sectionIds.length; i += 1) {
        const sectionInputs = document.getElementsByName(
          `enter_section_cut_off_marks_${i}`
        );
        if (stageExamDetail) {
          const sectionalData = {
            id: stageExamDetail?.sectional_cut_off[i]?.id,
            section_id: sectionIds[i],
            cut_off_marks:
              data.cut_off_marks_type?.value == "PERCENTAGE"
                ? Number(sectionInputs[0]?.value) / 100
                : Number(sectionInputs[0]?.value),
          };
          sectionalCutOff.push(sectionalData);
        } else {
          const sectionalData = {
            section_id: sectionIds[i],
            cut_off_marks:
              data.cut_off_marks_type?.value == "PERCENTAGE"
                ? Number(sectionInputs[0]?.value) / 100
                : Number(sectionInputs[0]?.value),
          };
          sectionalCutOff.push(sectionalData);
        }
      }
    }

    examData.sectional_cut_off = sectionalCutOff;

    if (!isDirty) {
      return setOpenModal(false);
    }

    examData.coding_language_ids =
      questionPaperType?.name === QUESTION_TYPE[1].name
        ? data.coding_language_ids?.map((c) => c.id)
        : [];

    examData.question_paper_id = data.question_paper.id;
    examData.question_paper_type = data.question_paper_type.name;
    examData.cut_off_type = data.cut_off_type?.value;
    examData.cut_off_marks_type = data.cut_off_marks_type?.value;
    examData.cut_off_marks =
      data.cut_off_marks_type?.value == "PERCENTAGE"
        ? Number(data.cut_off_marks) / 100
        : data.cut_off_marks;
    examData.start_at = new Date(
      moment(data.start_at).format("YYYY-MM-DD HH:mm:ss")
    ).toISOString();
    examData.end_at = new Date(
      moment(data.end_at).format("YYYY-MM-DD HH:mm:ss")
    ).toISOString();
    examData.camera_option = data.camera_option?.value;
    examData.feedback_link = data.feedback_link;

    delete examData.question_paper;

    if (stageExamDetail) {
      const multiSlotExam = stageExamDetail.multi_slot;
      return updateExam(
        params.uuid,
        currentStageId,
        stageExamDetail.id,
        examData
      )
        .then(({ meta }) => {
          setOpenModal(false);
          if (!multiSlotExam && data.multi_slot) {
            setShowMultiSlotConfirmation(true);
          }
          afterExamCreateActions(meta);
        })
        .catch((errors) => {
          showAlert(
            ALERT_TYPE[errors.response.data.meta?.message_type],
            errors.response.data.meta?.message
          );
          setErrorsOnFields(errors.response.data);
        });
    }
    return createExam(params.uuid, currentStageId, examData)
      .then(({ meta }) => {
        setOpenModal(false);
        if (data.multi_slot) {
          setShowMultiSlotConfirmation(true);
        }
        afterExamCreateActions(meta);
      })
      .catch((errors) => {
        showAlert(
          ALERT_TYPE[errors.response.data.meta?.message_type] || "danger",
          errors.response.data.meta?.message || errors.response.data.message
        );
        setErrorsOnFields(errors.response.data);
      });
  };

  const submitBtnLabel = () => {
    if (stageExamDetail) {
      return "Update";
    }
    return "Create";
  };

  const modalHeaderLabel = () => {
    if (stageExamDetail) {
      return "Edit Exam";
    }
    return "Create Exam";
  };

  const filterPassedTime = (time, fromDate) => {
    const currentDate = new Date(fromDate);
    const selectedDate = new Date(time);

    return currentDate.getTime() <= selectedDate.getTime();
  };

  const anyFieldEmptyInSectionCutOffMarks = () => {
    return sectionIds.some(
      (_, index) => checked && !watch(`enter_section_cut_off_marks_${index}`)
    );
  };

  const resetValueInSectionCutOffMarks = () => {
    for (let i = 0; i < sectionIds.length; i += 1) {
      setValue(`enter_section_cut_off_marks_${i}`, "");
    }
  };

  const anyFieldEmpty =
    !name ||
    !duration ||
    !questionPaperType ||
    !questionPaper ||
    !watch("start_at") ||
    !watch("end_at") ||
    !watch("cut_off_marks_type") ||
    !watch("cut_off_type") ||
    (!checked && !watch("cut_off_marks")) ||
    anyFieldEmptyInSectionCutOffMarks() ||
    !watch("camera_option") ||
    (watch("multi_slot") && !watch("slot_active_duration"));

  useEffect(() => {
    if (stageExamDetail) {
      setChecked(stageExamDetail.has_different_sectional_cut_off_marks);

      const sectionalCutOff = {};

      if (stageExamDetail.has_different_sectional_cut_off_marks) {
        for (let i = 0; i < stageExamDetail.sectional_cut_off.length; i += 1) {
          if (stageExamDetail.cut_off_marks_type === "PERCENTAGE") {
            sectionalCutOff[`enter_section_cut_off_marks_${i}`] = (
              Number(stageExamDetail.sectional_cut_off[i].cut_off_marks) * 100
            ).toFixed(2);
          } else {
            sectionalCutOff[`enter_section_cut_off_marks_${i}`] = Number(
              stageExamDetail.sectional_cut_off[i].cut_off_marks
            ).toFixed(2);
          }
        }
      }
      reset({
        ...sectionalCutOff,
        ...stageExamDetail,
        question_paper_type: { name: stageExamDetail.question_paper_type },
        question_paper: stageExamDetail.question_paper,
        coding_language_ids: stageExamDetail.coding_languages?.map((value) => {
          return { id: value?.id, name: value?.name };
        }),
        cut_off_marks_type: MARKS_TYPES.find(
          (marksType) => marksType.value === stageExamDetail.cut_off_marks_type
        ),
        cut_off_type: CUT_OFF_TYPES.find(
          (cutOffType) => cutOffType.value === stageExamDetail.cut_off_type
        ),
        camera_option: CAMERA_OPTION.find(
          (cameraOption) => cameraOption.value === stageExamDetail.camera_option
        ),
        cut_off_marks:
          stageExamDetail.cut_off_marks_type === "PERCENTAGE"
            ? (Number(stageExamDetail.cut_off_marks) * 100).toFixed(2)
            : Number(stageExamDetail.cut_off_marks).toFixed(2),
        duration: Math.ceil(stageExamDetail.duration / 60),
        feedback_link: stageExamDetail.feedback_link,
      });
    }
  }, [stageExamDetail]);

  useEffect(() => {
    setSectionIds([]);
    questionPaper?.sections?.map((section) => {
      setSectionIds((prev) => [...prev, section.id]);
    });
  }, [questionPaper]);

  return (
    <Modal
      title={modalHeaderLabel()}
      testId="create-exam-modal"
      customModalWidth="720px"
      acceptButtonText={submitBtnLabel()}
      acceptBtnType={!isDirty || anyFieldEmpty ? "disabled" : "primary"}
      handleAccept={handleSubmit((data) => submitHandlerExam(data))}
      scrollClass="overflow-y-auto max-h-[70vh]"
      handleCancel={() => setOpenModal(false)}
      isDirty={isDirty}
    >
      <form onSubmit={handleSubmit((data) => submitHandlerExam(data))}>
        <div className="mb-2">
          <label
            htmlFor="name"
            className={`required mb-1 block font-medium ${
              errors.name && "text-danger-main"
            }`}
          >
            Exam Name
          </label>
          <input
            id="name"
            className={`${errors.name ? "input-error" : "input"} w-full`}
            type="text"
            placeholder="Enter exam name"
            {...register("name", {
              required: requiredValidation(),
              maxLength: {
                value: 70,
                message: characterValidation({ limit: 70 }),
              },
            })}
          />
          {errors.name && (
            <p className="text-sm text-danger-dark">{errors.name.message}</p>
          )}

          <label
            htmlFor="question_paper_type"
            className={`required mt-2 mb-1 block font-medium ${
              errors.question_paper_type && "text-danger-main"
            }`}
          >
            Question Paper Type
          </label>

          <Controller
            name="question_paper_type"
            control={control}
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <Select
                name="question_paper_type"
                options={QUESTION_TYPE}
                isClearable
                id="question-paper-type-select"
                menuPlacement="auto"
                styles={reactSelectStyle(errors.question_paper_type)}
                placeholder="Select question paper type"
                value={field.value}
                onChange={(data) => {
                  field.onChange(data);
                  setValue("question_paper", null);
                  setValue("cut_off_marks", "");
                  setValue("cut_off_type", null);
                  setChecked(false);
                }}
                getOptionLabel={(option) => upperCase(option.name)}
                getOptionValue={(option) => option.name}
              />
            )}
          />

          {errors.question_paper_type && (
            <p className="text-sm text-danger-dark">
              {errors.question_paper_type.message}
            </p>
          )}

          <label
            htmlFor="question_paper"
            className={`required mt-2 mb-1 block font-medium ${
              errors.question_paper && "text-danger-main"
            }`}
          >
            Question Paper
          </label>
          <Controller
            name="question_paper"
            control={control}
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <AsyncPaginate
                isSearchable
                maxMenuHeight={120}
                isDisabled={!questionPaperType}
                id="question-paper-select"
                menuPlacement="auto"
                className={`w-full disabled:bg-gray-lighter ${
                  !questionPaperType && "bg-gray-lighter"
                }`}
                styles={reactSelectStyle(errors.question_paper)}
                value={field.value}
                loadOptions={loadQuestionPaperOptions}
                onChange={(value) => {
                  setValue("duration", Math.round(value.duration / 60));
                  setValue("cut_off_marks", "");
                  setValue("cut_off_type", null);
                  setChecked(false);
                  return field.onChange(value);
                }}
                cacheUniqs={[questionPaperType]}
                placeholder="Select question paper"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name || option.label}
                additional={{ page: 1 }}
              />
            )}
          />

          {errors.question_paper && (
            <p className="text-sm text-danger-dark">
              {errors.question_paper.message}
            </p>
          )}
        </div>

        {questionPaperType?.name === QUESTION_TYPE[1].name && (
          <div className="my-3 justify-between sm:flex-row">
            <label
              htmlFor="coding_language_ids"
              className={`required mt-2 mb-1 block font-medium ${
                errors.coding_language_ids && "text-danger-main"
              }`}
            >
              Coding Languages
            </label>
            <Controller
              control={control}
              name="coding_language_ids"
              rules={{
                required: requiredValidation(),
              }}
              render={({ field }) => (
                <AsyncPaginate
                  isSearchable
                  isClearable
                  isMulti
                  closeMenuOnSelect={false}
                  id="coding_language_ids"
                  menuPlacement="auto"
                  className="w-full"
                  styles={reactSelectStyle(errors.coding_language_ids)}
                  value={field.value}
                  loadOptions={codingLanguageOptions}
                  onChange={(value) => field.onChange(value)}
                  noOptionsMessage={() => "No languages"}
                  debounceTimeout={1000}
                  placeholder="Enter coding language(s)"
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  additional={{ page: 1 }}
                />
              )}
            />

            {errors.coding_language_ids && (
              <p className="text-sm text-danger-dark">
                {errors.coding_language_ids.message}
              </p>
            )}
          </div>
        )}

        {watch("question_paper") && (
          <p className="mb-3 sm:flex sm:justify-between grid grid-cols-2 grid-rows-2 gap-1 text-base text-gray-500">
            <span className="md:ml-0">
              Sections: {watch("question_paper").total_sections || 0}
            </span>
            <span>
              Questions: {watch("question_paper").total_questions || 0}
            </span>
            <span>Total Marks: {watch("question_paper").total_marks || 0}</span>
            <span>
              Max Time:{" "}
              {(watch("question_paper").duration &&
                moment()
                  .startOf("day")
                  .seconds(watch("question_paper").duration)
                  .format("HH:mm:ss")) ||
                "00:00:00"}
            </span>
          </p>
        )}

        <div className="mb-2 flex flex-col justify-between sm:flex-row sm:space-x-4">
          <div className="w-full sm:w-1/2">
            <label
              htmlFor="cut_off_marks_types"
              className={`required mt-2 block font-medium ${
                errors.cut_off_type && "text-danger-main"
              }`}
            >
              Cutoff Type
            </label>

            <Controller
              control={control}
              name="cut_off_type"
              id="cutOffSelect"
              rules={{
                validate: {
                  not_blank: (v) =>
                    watch("cut_off_marks_type") === "" ||
                    (v && v.value !== "") ||
                    requiredValidation(),
                },
              }}
              render={({ field }) => (
                <Select
                  name="cutOffSelect"
                  isClearable
                  isDisabled={!questionPaper || questionPaper === null}
                  className={`mb-3 mt-1 disabled:bg-gray-lighter xs:mb-0 ${
                    (!questionPaper || questionPaper === null) &&
                    "bg-gray-lighter"
                  }`}
                  styles={{
                    control: (styles, state) => ({
                      ...styles,
                      paddingTop: 2,
                      paddingBottom: 2,
                      backgroundColor: errors.cut_off_type && "#fad1dd",
                      // eslint-disable-next-line no-nested-ternary
                      borderColor: errors.cut_off_type
                        ? "#E73067"
                        : state.isFocused
                        ? "#143A7B"
                        : "#CCCCCC",
                      boxShadow:
                        (state.isFocused && !errors.cut_off_type) ??
                        "0 0 0 1px #205dc5",
                      "&:hover": {
                        borderColor: errors.cut_off_type
                          ? "#E73067"
                          : "#205dc5",
                      },
                    }),
                  }}
                  placeholder="Select cutoff type"
                  options={CUT_OFF_TYPES}
                  id="cutoff-type-select"
                  menuPlacement="auto"
                  isOptionDisabled={(option) =>
                    questionPaper?.sectional === false &&
                    option.value === "SECTIONAL"
                  }
                  value={field.value}
                  onChange={(value) => {
                    if (value === null && watch("cut_off_marks_type") === "") {
                      clearErrors("cut_off_marks_type");
                    }
                    field.onChange(value);
                    if (value === CUT_OFF_TYPES[1]) {
                      setValue("has_different_sectional_cut_off_marks", null);
                      setChecked(false);
                    }
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />

            {errors.cut_off_type && (
              <p className="text-sm text-danger-dark">
                {errors.cut_off_type.message}
              </p>
            )}

            <label
              htmlFor="has_different_sectional_cut_off_marks"
              data-testid="has_different_sectional_cut_off_marks"
              className={classNames(
                "mt-1 flex items-center text-sm",
                questionPaper?.sectional === false ? "hidden" : "visible"
              )}
            >
              <input
                type="checkbox"
                id="has_different_sectional_cut_off_marks"
                disabled={
                  JSON.stringify(cutOffType) !==
                  JSON.stringify(CUT_OFF_TYPES[0])
                }
                checked={checked}
                {...register("has_different_sectional_cut_off_marks")}
                onChange={() => {
                  setChecked((prevState) => !prevState);
                  setValue("cut_off_marks", "");
                  resetValueInSectionCutOffMarks();
                }}
              />
              <span className="ml-2 ">Set for each section separately</span>
            </label>
          </div>

          {!checked ? (
            <div className="sm:w-1/2">
              <label
                htmlFor="enter_cutoff_marks"
                className={`required mt-2 block font-medium ${
                  errors.cut_off_marks && "text-danger-main"
                }`}
              >
                Cutoff Marks
              </label>

              <div className="flex items-center">
                <div className="flex w-2/3 flex-col">
                  <input
                    data-testid="cutoff-marks-input"
                    name="cut_off_marks"
                    placeholder="Enter cutoff marks"
                    disabled={!questionPaper || questionPaper === null}
                    className={classNames(
                      "mr-[1px] mt-1 rounded-r-none  disabled:bg-gray-lighter",
                      `${errors.cut_off_marks ? "input-error" : "input"}`
                    )}
                    type="text"
                    {...register("cut_off_marks", {
                      max: {
                        value:
                          JSON.stringify(watch("cut_off_marks_type")) ===
                          JSON.stringify(MARKS_TYPES[1])
                            ? 100
                            : null,
                        message:
                          JSON.stringify(watch("cut_off_marks_type")) ===
                            JSON.stringify(MARKS_TYPES[1]) &&
                          "must be less than or equal to 100",
                      },
                      min: {
                        value: 0.01,
                        message: "must be greater than 0",
                      },
                      pattern: {
                        value: /^\d*\.{0,1}\d{0,2}$/,
                        message: DECIMAL_INFO.PATTERN,
                      },

                      required:
                        watch("cut_off_type") && watch("cut_off_type") !== null
                          ? requiredValidation()
                          : false,

                      onChange: (e) => {
                        if (e.target.value === "") {
                          clearErrors("cut_off_marks_type");
                        }
                      },
                    })}
                  />

                  {watch("cut_off_marks") && (
                    <div className="relative">
                      <BiX
                        className="absolute bottom-[9px] right-6 text-2xl text-gray-600"
                        onClick={() => {
                          setValue("cut_off_marks", "");
                          clearErrors("cut_off_marks_type");
                          clearErrors("cut_off_type");
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="w-1/3">
                  <Controller
                    control={control}
                    name="cut_off_marks_type"
                    id="cutOffSelect"
                    defaultValue={MARKS_TYPES[1]}
                    render={({ field }) => (
                      <Select
                        name="marksTypeSelect"
                        isDisabled={!questionPaper || questionPaper === null}
                        className="mt-1 rounded-r-sm border-l-0"
                        styles={{
                          control: (styles, state) => ({
                            ...styles,
                            padding: "2px",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            // eslint-disable-next-line no-nested-ternary
                            borderColor: errors.cut_off_marks_type
                              ? "#E73067"
                              : state.isFocused
                              ? "#143A7B"
                              : "#CCCCCC",
                            boxShadow:
                              (state.isFocused && !errors.cut_off_marks_type) ??
                              "0 0 0 1px #205dc5",
                            "&:hover": {
                              borderColor: errors.cut_off_marks_type
                                ? "#E73067"
                                : "#205dc5",
                            },
                          }),
                          dropdownIndicator: (styles) => ({
                            ...styles,
                            padding: "1px",
                          }),
                        }}
                        options={MARKS_TYPES}
                        id="marksTypeSelect"
                        menuPlacement="auto"
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>
              {errors.cut_off_marks && (
                <p className="text-sm text-danger-dark">
                  {errors.cut_off_marks.message}
                </p>
              )}

              {Number(watch("cut_off_marks")) >
                Number(watch("question_paper")?.total_marks) &&
                JSON.stringify(watch("cut_off_marks_type")) ===
                  JSON.stringify(MARKS_TYPES[0]) && (
                  <p className="mt-1 text-sm text-warning-dark">
                    <span>Cut off marks is greater than sectional Marks</span>
                  </p>
                )}
            </div>
          ) : (
            <div className="sm:w-1/2">
              <label
                htmlFor="enter_section_cutoff_type"
                className={`required mt-2 block font-medium ${
                  errors.cut_off_marks_type && "text-danger-main"
                }`}
              >
                Cutoff Marks Type
              </label>
              <div className="flex items-center">
                <div className="w-full">
                  <Controller
                    control={control}
                    name="cut_off_marks_type"
                    id="sectionCutOffSelect"
                    defaultValue={MARKS_TYPES[1]}
                    render={({ field }) => (
                      <Select
                        name="marksTypeSelect"
                        isDisabled={!questionPaper || questionPaper === null}
                        className="mt-1 rounded-r-sm border-l-0"
                        styles={{
                          control: (styles, state) => ({
                            ...styles,
                            padding: "2px",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            // eslint-disable-next-line no-nested-ternary
                            borderColor: errors.cut_off_marks_type
                              ? "#E73067"
                              : state.isFocused
                              ? "#143A7B"
                              : "#CCCCCC",
                            boxShadow:
                              (state.isFocused && !errors.cut_off_marks_type) ??
                              "0 0 0 1px #205dc5",
                            "&:hover": {
                              borderColor: errors.cut_off_marks_type
                                ? "#E73067"
                                : "#205dc5",
                            },
                          }),
                          dropdownIndicator: (styles) => ({
                            ...styles,
                            padding: "1px",
                          }),
                        }}
                        options={MARKS_TYPES}
                        id="marksTypeSelect"
                        menuPlacement="auto"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("cut_off_marks_type", value);
                        }}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>
              {errors.cut_off_marks_type && (
                <p className="text-sm text-danger-dark">
                  {errors.cut_off_marks_type.message}
                </p>
              )}
            </div>
          )}
        </div>

        {checked && (
          <div className="flex flex-col flex-wrap justify-between gap-4 sm:flex-row">
            {questionPaper?.sections.map((section, id) => (
              <div className="w-full flex-col md:w-[48.7%]" key={id}>
                <label
                  htmlFor={`enter_section_cut_off_marks_${id}`}
                  className={`required mt-2 block font-medium ${
                    errors.section_cut_off_marks && "text-danger-main"
                  }`}
                >
                  {section.name}
                </label>

                <div className="flex items-center">
                  <div className="w-4/5">
                    <input
                      data-testid="section-marks-from-input"
                      name={`enter_section_cut_off_marks_${id}`}
                      placeholder="Enter cutoff marks"
                      disabled={!questionPaper || questionPaper === null}
                      className={classNames(
                        "input mr-[1px] mt-1  rounded-r-none disabled:bg-gray-lighter"
                      )}
                      type="text"
                      {...register(`enter_section_cut_off_marks_${id}`, {
                        max: {
                          value:
                            JSON.stringify(watch("cut_off_marks_type")) ===
                            JSON.stringify(MARKS_TYPES[1])
                              ? 100
                              : null,
                          message:
                            JSON.stringify(watch("cut_off_marks_type")) ===
                              JSON.stringify(MARKS_TYPES[1]) &&
                            DECIMAL_INFO.MAX,
                        },
                        min: {
                          value: 0.01,
                          message: DECIMAL_INFO.MIN,
                        },
                        pattern: {
                          value: /^\d*\.{0,1}\d{0,2}$/,
                          message: DECIMAL_INFO.PATTERN,
                        },

                        required:
                          watch("cut_off_type") &&
                          watch("cut_off_type") !== null
                            ? requiredValidation()
                            : false,

                        onChange: (e) => {
                          if (e.target.value === "") {
                            clearErrors("cut_off_marks_type");
                          }
                        },
                      })}
                    />

                    {watch(`enter_section_cut_off_marks_${id}`) && (
                      <div className="relative">
                        <BiX
                          className="absolute bottom-[9px] right-7 text-2xl text-gray-600"
                          onClick={() => {
                            setValue(`enter_section_cut_off_marks_${id}`, "");
                            clearErrors("cut_off_marks_type");
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="input mt-1 w-1/5 bg-gray-lighterShade5">
                    <span className="">
                      {watch("cut_off_marks_type").label}
                    </span>
                  </div>
                </div>

                {errors[`enter_section_cut_off_marks_${id}`] && (
                  <p className="mt-1 text-sm text-danger-dark">
                    {errors[`enter_section_cut_off_marks_${id}`].message}
                  </p>
                )}

                {Number(watch(`enter_section_cut_off_marks_${id}`)) >
                  Number(section.total_marks) &&
                  JSON.stringify(watch("cut_off_marks_type")) ===
                    JSON.stringify(MARKS_TYPES[0]) && (
                    <p className="mt-1 text-sm text-warning-dark">
                      <span>Cut off marks is greater than sectional Marks</span>
                    </p>
                  )}
              </div>
            ))}
          </div>
        )}

        <div className="mb-2 flex flex-col justify-between sm:flex-row sm:space-x-4">
          <div className="w-full flex-col  sm:mt-0 sm:w-1/2">
            <label
              htmlFor="appeared"
              className={`required mt-2 mb-1 block font-medium ${
                errors.question_paper && "text-danger-main"
              }`}
            >
              Active From
            </label>

            <Controller
              control={control}
              name="start_at"
              rules={{
                required: requiredValidation(),
              }}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Active from - date and time"
                  isClearable
                  className={`${
                    errors.start_at ? "input-error" : "input"
                  } w-full`}
                  dateFormat="dd/MM/yyyy h:mm aa"
                  showTimeSelect
                  timeFormat="p"
                  minDate={new Date()}
                  styles={reactSelectStyle(errors.start_at)}
                  timeIntervals={15}
                  filterTime={(e) => filterPassedTime(e, new Date())}
                  onChange={(date) => {
                    if (date === undefined || date === null) {
                      setValue("end_at", null);
                      clearErrors("end_at");
                    } else if (isAfter(new Date(date), watch("end_at"))) {
                      setError("end_at", {
                        message: toDateTimeValidation(),
                      });
                    } else {
                      clearErrors("end_at");
                    }
                    return field.onChange(date);
                  }}
                  selected={field.value && toDate(new Date(field.value))}
                />
              )}
            />
            {errors.start_at && (
              <p className="mt-2 text-sm text-danger-dark">
                {errors.start_at.message}
              </p>
            )}
          </div>

          <div className="mt-3 w-full flex-col sm:mt-0 sm:w-1/2">
            <label
              htmlFor="appeared"
              className={`required mt-2 mb-1 block font-medium ${
                errors.end_at && "text-danger-main"
              }`}
            >
              Active Till
            </label>
            <Controller
              control={control}
              name="end_at"
              rules={{
                required: watch("start_at") ? requiredValidation() : false,
                validate: (endDate) => {
                  if (isBefore(new Date(endDate), watch("start_at"))) {
                    return toDateTimeValidation();
                  }
                },
              }}
              render={({ field }) => (
                <DatePicker
                  disabled={
                    watch("start_at") === undefined ||
                    watch("start_at") === null
                  }
                  placeholderText="Active till - date and time"
                  isClearable
                  className={`${
                    errors.end_at ? "input-error" : "input"
                  } disabled:input-disabled w-full`}
                  dateFormat="dd/MM/yyyy h:mm aa"
                  filterTime={(e) => filterPassedTime(e, watch("start_at"))}
                  showTimeSelect
                  timeFormat="p"
                  styles={reactSelectStyle(errors.end_at)}
                  timeIntervals={15}
                  onChange={(date) => {
                    if (isAfter(new Date(date), watch("start_at"))) {
                      clearErrors("end_at");
                    }
                    field.onChange(date);
                  }}
                  selected={field.value && toDate(new Date(field.value))}
                  minDate={watch("start_at") ? watch("start_at") : null}
                />
              )}
            />
            {errors.end_at && (
              <p className="mt-2 text-sm text-danger-dark">
                {errors.end_at.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col justify-between sm:flex-row sm:space-x-4">
          <div className="w-full flex-col sm:w-1/2">
            <label
              htmlFor="duration"
              className={`required mt-2 mb-1 block font-medium ${
                errors.duration && "text-danger-main"
              }`}
            >
              Exam Duration <span className="text-xs">(in mins)</span>
            </label>
            <input
              id="duration"
              className={`${errors.duration ? "input-error" : "input"} w-full`}
              placeholder="Enter exam duration"
              {...register("duration", {
                required: requiredValidation(),
                min: {
                  value: 1,
                  message: "Minimum exam duration 1!",
                },
                max: {
                  value: 900,
                  message: "Maximum exam duration 900",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Invalid number",
                },
              })}
            />

            {errors.duration && (
              <p className="mt-1 text-sm text-danger-dark">
                {errors.duration.message}
              </p>
            )}
          </div>

          <div className="w-full flex-col sm:w-1/2">
            <label
              htmlFor="camera-option"
              className={`required mt-2 mb-1 block font-medium ${
                errors.camera_option && "text-danger-main"
              }`}
            >
              Camera Type
            </label>

            <Controller
              name="camera_option"
              control={control}
              defaultValue={watch("camera_option") || CAMERA_OPTION[0]}
              render={({ field }) => (
                <Select
                  name="camera_option"
                  options={CAMERA_OPTION}
                  isSearchable
                  maxMenuHeight={95}
                  id="camera-option"
                  menuPlacement="auto"
                  styles={{
                    control: (styles, state) => ({
                      ...styles,
                      paddingTop: 2,
                      paddingBottom: 2,
                      backgroundColor: errors.camera_option && "#fad1dd",
                      // eslint-disable-next-line no-nested-ternary
                      borderColor: errors.camera_option
                        ? "#E73067"
                        : state.isFocused
                        ? "#143A7B"
                        : "#CCCCCC",
                      boxShadow:
                        (state.isFocused && !errors.camera_option) ??
                        "0 0 0 1px #205dc5",
                      "&:hover": {
                        borderColor: errors.camera_option
                          ? "#E73067"
                          : "#205dc5",
                      },
                    }),
                  }}
                  placeholder="Select camera Type"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  getOptionLabel={(option) => startCase(option.name)}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
          </div>
        </div>

        <LabeledInput
          id="feedback_link"
          labelClassNames="block mt-4 mb-1 font-medium"
          label="Survey Link"
          inputFieldId="feedback_link"
          name="feedback_link"
          inputClassNames="input w-full"
          placeholder="Paste survey link"
          register={register}
          type="text"
          validation={{
            maxLength: {
              value: 255,
              message: characterValidation({ limit: 255 }),
            },
            pattern: {
              value: WEBSITE_REGEX,
              message: "invalid URL",
            },
          }}
          errorMessage={errors.feedback_link?.message}
          errorMessageClassNames="text-sm text-danger-dark"
        />

        {/* checkbox ID changed to data-testid for cypress and using default id to proper working of toggle */}
        <div className="mt-5 flex flex-col justify-between md:flex-row md:space-x-4">
          <div className="w-full flex-col md:w-5/12 ">
            <label
              htmlFor="auto_approve"
              className="relative flex w-max cursor-pointer select-none items-center"
            >
              <span className="mr-3 text-base  ">
                Auto evaluate candidate status
              </span>
              <input
                type="checkbox"
                className="sm:ml-4 h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
                name="auto_approve"
                id="auto_approve"
                data-testid="auto-evaluate-candidate-status"
                {...register("auto_approve")}
                onClick={(e) => handleAutoEvaluateCandidateClick(e)}
              />
              <span className="font-small absolute right-1.5 text-xs uppercase text-white">
                OFF
              </span>
              <span className="absolute right-10 text-xs font-medium uppercase text-white">
                ON
              </span>
              <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
            </label>
          </div>

          {watch("auto_approve") && (
            <div className="mt-5 w-full flex-col md:mt-0 md:w-5/12">
              <label
                htmlFor="auto_email"
                className="relative flex w-max cursor-pointer select-none items-center"
              >
                <span className="mr-3 text-base">Auto send result email</span>
                <input
                  type="checkbox"
                  className="sm:ml-4 h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
                  name="auto_email"
                  id="auto_email"
                  data-testid="auto-send-result-email"
                  {...register("auto_email")}
                />
                <span className="font-small absolute right-1.5 text-xs uppercase text-white">
                  OFF
                </span>
                <span className="absolute right-10 text-xs font-medium uppercase text-white">
                  ON
                </span>
                <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
              </label>
            </div>
          )}
        </div>

        <div className="flex flex-col sm:flex-row justify-between mt-5">
          <div className="flex-col md:w-5/12 w-full">
            <label
              htmlFor="multi_slot"
              className="flex items-center relative w-max cursor-pointer select-none"
            >
              <span className="text-base mr-3">Enable Multi Slot</span>
              <input
                type="checkbox"
                className="sm:ml-4 appearance-none cursor-pointer w-16 h-6 rounded-full bg-gray-400"
                name="multi_slot"
                id="multi_slot"
                {...register("multi_slot")}
                disabled={stageExamDetail}
                onClick={(e) => handleScrollOnClick(e)}
              />
              <span className="absolute font-small text-xs uppercase right-1.5 text-white">
                OFF
              </span>
              <span className="absolute font-medium text-xs uppercase right-10 text-white">
                ON
              </span>
              <span className="w-7 h-4 right-8 absolute rounded-full transform transition-transform bg-white" />
            </label>
          </div>
        </div>
        {watch("multi_slot") && (
          <div className="w-full flex-row sm:w-1/2" ref={slotDurationRef}>
            <label
              htmlFor="slot_active_duration"
              className={`required mb-1 mt-4 block font-medium ${
                errors.slot_active_duration ? "text-danger-main" : ""
              }`}
            >
              Slot Active Duration <span className="text-xs">(in mins)</span>
            </label>
            <input
              id="slot_active_duration"
              className={`${
                errors.slot_active_duration ? "input-error" : "input"
              } w-full`}
              placeholder="Active Slot duration"
              {...register("slot_active_duration", {
                required: requiredValidation(),
                min: {
                  value: 1,
                  message: "Minimum exam duration 1!",
                },
                max: {
                  value: 120,
                  message: "Maximum slot active duration 120",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Invalid number",
                },
              })}
            />

            {errors.slot_active_duration && (
              <p className="mt-1 text-sm text-danger-dark">
                {errors.slot_active_duration.message}
              </p>
            )}
          </div>
        )}
      </form>
    </Modal>
  );
};

ExamModal.propTypes = {
  setOpenModal: PropTypes.func,
  currentStageId: PropTypes.number,
  getExamData: PropTypes.func,
  setShowMultiSlotConfirmation: PropTypes.func,
  stageExamDetail: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    question_paper_id: PropTypes.number,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    camera_option: PropTypes.string,
    auto_approve: PropTypes.bool,
    multi_slot: PropTypes.bool,
    duration: PropTypes.number,
    feedback_link: PropTypes.string,
    auto_email: PropTypes.bool,
    cut_off_marks: PropTypes.string,
    cut_off_marks_type: PropTypes.string,
    cut_off_type: PropTypes.string,
    question_paper_type: PropTypes.string,
    has_different_sectional_cut_off_marks: PropTypes.bool,
    sectional_cut_off: PropTypes.array,
    coding_languages: PropTypes.array,
    questionPaperType: PropTypes.string,
    question_paper: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      question_paper_type: PropTypes.string,
      created_by: PropTypes.string,
      total_sections: PropTypes.number,
      total_questions: PropTypes.number,
      total_marks: PropTypes.number,
      duration: PropTypes.number,
      created_at: PropTypes.string,
    }),
  }),
};

export default ExamModal;
