import classNames from "classnames";
import PropTypes from "prop-types";

import { Button, RenderWithPortal } from "components/atoms";

const Suggestionbox = ({
  title,
  description,
  acceptButtonText,
  rejectButtonText,
  handleAccept,
  handleReject,
  children,
  scrollClass,
  acceptBtnType,
  rejectBtnType,
  customButtonWidth,
  isAsyncClick,
}) => {
  return (
    <RenderWithPortal>
      <div className="fixed top-0">
        <div
          className={classNames(
            "fixed right-0 mr-0 mt-10 h-full w-[70%] overflow-y-auto rounded border-2 bg-gray-lightest sm:mt-0 sm:w-[50%] md:w-[45%] lg:w-[40%]"
          )}
        >
          {title && (
            <div className=" mt-16 flex items-center justify-center border-b p-4 sm:justify-between">
              <h3
                className="flex items-center justify-center gap-2 text-xl font-medium leading-6 text-black-shade1 sm:m-0 sm:justify-start"
                data-testid="SuggestionBox-title"
              >
                {title}
              </h3>

              {handleAccept && (
                <Button
                  title={acceptButtonText}
                  data-testid="acceptActionBtn"
                  onClick={handleAccept}
                  type={acceptBtnType}
                  btnClassName="h-8 pt-2"
                  size="sm"
                  customWidth={customButtonWidth}
                  btnName={acceptButtonText}
                  isAsyncClick={isAsyncClick}
                />
              )}
            </div>
          )}
          <div className={classNames("p-4 text-gray-darker", scrollClass)}>
            {children || <p> {description}</p>}
          </div>
          {typeof handleReject === "function" && (
            <div className="flex justify-end gap-2 bg-gray-lighterShade1 px-4 py-3 sm:px-6">
              <Button
                title={rejectButtonText}
                data-testid="rejectActionBtn"
                onClick={handleReject}
                size="md"
                customWidth={customButtonWidth}
                type={rejectBtnType}
                btnName={rejectButtonText}
              />
            </div>
          )}
        </div>
      </div>
    </RenderWithPortal>
  );
};

Suggestionbox.defaultProps = {
  title: "",
  description: "",
  customButtonWidth: 28,
  isAsyncClick: true,
};

Suggestionbox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string,
  acceptButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  handleAccept: PropTypes.func,
  handleReject: PropTypes.func,
  children: PropTypes.node,
  scrollClass: PropTypes.string,
  acceptBtnType: PropTypes.string,
  rejectBtnType: PropTypes.string,
  customButtonWidth: PropTypes.number,
  isAsyncClick: PropTypes.bool,
};

export default Suggestionbox;
