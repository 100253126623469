import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { Button } from "components/atoms";

import InterviewActions from "../../InterviewActions";
import InterviewFeedback from "../../InterviewFeedback";

const FormattedTime = ({ dateArg }) =>
  dateArg && (
    <>
      <span className="mb-1">{format(new Date(dateArg), "dd/MM/yyyy")}, </span>
      <span>{format(new Date(dateArg), "h:mm a")}</span>
    </>
  );

const InterviewCandidates = ({
  candidate,
  currentStage,
  setCandidateID,
  setShowScheduleInterviewModal,
  setInterview,
  getCandidateStageData,
  isUserRoleAllowed,
  isJobClosed,
}) => {
  const dropdownMenuRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  return (
    <>
      <td className="py-4 pr-4 pl-6 align-top text-gray-darker">
        {isUserRoleAllowed && candidate?.interview == null && !isJobClosed && (
          <Button
            title="Schedule Interview"
            btnName="Schedule Interview"
            testId="schedule-interview-btn"
            type="tertiary"
            onClick={() => {
              setShowScheduleInterviewModal(true);
              setCandidateID(candidate.candidate_id);
              setInterview(null);
            }}
          />
        )}
        <InterviewActions
          interview={candidate?.interview}
          currentStageId={currentStage.id}
          candidateName={candidate?.name}
          setShowScheduleInterviewModal={setShowScheduleInterviewModal}
          setInterview={setInterview}
          setCandidateID={setCandidateID}
          getCandidateStageData={getCandidateStageData}
          isUserRoleAllowed={isUserRoleAllowed}
        />
      </td>

      <td className="p-4 align-top text-gray-darker">
        {candidate?.interview?.start_at && (
          <FormattedTime dateArg={candidate?.interview?.start_at} />
        )}
      </td>

      <td className="p-4 align-top text-gray-darker">
        {candidate?.interview && (
          <span>{candidate?.interview?.duration} Mins</span>
        )}
      </td>

      <td className="p-4 align-top text-gray-darker">
        {candidate.interview?.interview_users?.map((user) => (
          <span key={user.id} className="mb-2 block leading-5">
            {user.user_name}
            {user.feedback && `: ${user.feedback.marks}/5`}
          </span>
        ))}

        {candidate.interview?.interview_users?.some(
          (user) => user.feedback
        ) && (
          <InterviewFeedback
            interview={candidate.interview}
            candidateName={candidate.name}
          />
        )}
      </td>
    </>
  );
};

FormattedTime.propTypes = {
  dateArg: PropTypes.string.isRequired,
};

InterviewCandidates.defaultProps = {
  candidate: {
    id: 0,
    uuid: "",
    candidate_id: 0,
    job_uuid: "",
    name: "",
    interview: {
      candidate_id: 0,
      link: "",
      start_at: "",
      candidate_job_stage_id: 0,
      duration: 0,
      id: 0,
      interview_users: [],
    },
  },
  currentStage: {
    id: 0,
    name: "",
    sequence: 0,
    stage_type: "",
  },
  setCandidateID: () => {},
  setShowScheduleInterviewModal: () => {},
  setInterview: () => {},
  getCandidateStageData: () => {},
  isUserRoleAllowed: false,
  isJobClosed: false,
};

InterviewCandidates.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    candidate_id: PropTypes.number,
    job_uuid: PropTypes.string,
    name: PropTypes.string,
    interview: PropTypes.shape({
      candidate_id: PropTypes.number,
      link: PropTypes.string,
      start_at: PropTypes.string,
      candidate_job_stage_id: PropTypes.number,
      duration: PropTypes.number,
      id: PropTypes.number,
      interview_users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          interview_id: PropTypes.number,
          user_id: PropTypes.number,
          user_name: PropTypes.string,
          feedback: PropTypes.shape({
            comment: PropTypes.string,
            id: PropTypes.number,
            is_appeared: PropTypes.bool,
            marks: PropTypes.number,
          }),
        })
      ),
    }),
  }),
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  setCandidateID: PropTypes.func,
  setShowScheduleInterviewModal: PropTypes.func,
  setInterview: PropTypes.func,
  getCandidateStageData: PropTypes.func,
  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
};

export default InterviewCandidates;
