import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { createStageCandidate } from "api/erp/jobApi";
import { ALERT_TYPE } from "app/constants";
import { Modal } from "components/molecules";
import useAlert from "hooks/useAlert";
import { resetSelection } from "store/slices/miscellaneousSlice";

const AddToStageModal = ({ setIsEditState, candidateID, currentStage }) => {
  const { stages } = useSelector((state) => state.stages);
  const selectedIds = useSelector((state) => state.miscellaneous.selectedIds);
  const presentStages = stages.filter((stage) => stage.id !== currentStage);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
  } = useForm();

  const showAlert = useAlert();
  const params = useParams();
  const dispatch = useDispatch();

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (value[0] == "has already been taken") {
        setError("candidate_job_stage", {
          message: `${
            selectedIds.length > 1
              ? "One or more candidates already exist"
              : "Candidate already exists"
          } in selected stage`,
        });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const submitHandler = (data) => {
    const addToStageModalData = {
      ...data,
      candidate_job_ids: candidateID ? [candidateID.toString()] : selectedIds,
    };

    if (!data.stage_id && !presentStages?.length) {
      return setIsEditState(false);
    }
    if (!data.stage_id) {
      return setError("candidate_job_stage", { message: "can't be blank" });
    }
    return createStageCandidate(
      params.uuid,
      Number(addToStageModalData.stage_id),
      addToStageModalData
    )
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);

        if (!candidateID && selectedIds.length > 0) {
          dispatch(resetSelection());
        }
        setIsEditState(false);
      })
      .catch((errors) => {
        if (errors?.response?.data?.message) {
          showAlert("danger", errors.response.data.message);
        } else {
          setErrorsOnFields(errors.response.data.errors);
        }
      });
  };

  return (
    <Modal
      title="Select stage"
      testId="add-candidate-to-stage-modal"
      acceptButtonText="OK"
      handleAccept={handleSubmit((data) => submitHandler(data))}
      acceptBtnType="primary"
      handleCancel={() => setIsEditState(false)}
      onOutsideClickHandler={() => setIsEditState(false)}
      scrollClass="overflow-y-auto max-h-[70vh]"
    >
      <form
        data-testid="Candidate-Stage-Form"
        className="mt-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        {!presentStages?.length && (
          <span className="mt-4 block text-sm italic text-gray-main">
            Add more stages to add Candidates
          </span>
        )}

        <div className="my-4">Please select a stage for candidates:</div>

        {presentStages.length != 0 &&
          presentStages.map((stage) => (
            <div key={stage.id} className="mt-2">
              <label className="my-2 cursor-pointer">
                <input
                  className="mr-2 cursor-pointer"
                  type="radio"
                  value={stage.id}
                  name={stage.id}
                  {...register("stage_id")}
                  onInput={() => clearErrors()}
                  onClick={() => setValue("stage_name", `${stage.name}`)}
                />
                {stage.name}
              </label>
            </div>
          ))}
        {errors.candidate_job_stage && (
          <p className="mt-2 text-sm text-danger-dark">
            {errors.candidate_job_stage.message}
          </p>
        )}
      </form>
    </Modal>
  );
};

AddToStageModal.propTypes = {
  setIsEditState: PropTypes.func,
  candidateID: PropTypes.number,
  currentStage: PropTypes.number,
  setCandidateID: PropTypes.func,
};

export default AddToStageModal;
