import { createAsyncThunk } from "@reduxjs/toolkit";

import { createJob, getJob, getJobs, updateJob } from "api/erp/jobApi";

export const getJobsAction = createAsyncThunk(
  "jobs/get",
  async ({ page, searchTerm, statuses, visibility, hidden }) => {
    const response = await getJobs(
      page,
      searchTerm,
      statuses,
      visibility,
      hidden
    );
    return response;
  }
);

export const getJobAction = createAsyncThunk(
  "jobs/show",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getJob(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createJobAction = createAsyncThunk(
  "jobs/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createJob(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateJobAction = createAsyncThunk(
  "jobs/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateJob(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
