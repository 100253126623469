import { BsExclamationCircleFill, BsInfoCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Proptypes from "prop-types";

import { Button } from "..";

const ActionAlert = ({
  message,
  link,
  alertType,
  btnName = "Go to",
  buttonType,
  buttonTitle,
  testId,
  isButtonRequire,
  customButtonWidth,
  handleButtonClick,
  customClassName,
  iconName,
  roundedBorder,
  iconSize,
  iconVerticalAlignment,
}) => {
  const navigate = useNavigate();
  // todo: add more alertType of customization
  return (
    <div
      className={classNames(
        `${customClassName} mx-2 mb-10 flex max-w-3xl flex-col justify-between text-center xs:mx-auto xs:flex-row`,
        { "rounded-full border p-1 opacity-80": roundedBorder },
        { "text-secondary-alertDark opacity-100": alertType === "info" },
        { "border-primary-light text-primary-main": alertType === "primary" },
        { "border-warning-light text-warning-main": alertType === "warning" },
        {
          "mb-2 text-start p-0 max-w-full": isButtonRequire === false,
        }
      )}
      data-testid={testId}
    >
      <div
        className={classNames(
          "flex items-center justify-center gap-3 p-2 text-center",
          {
            "text-start py-1 px-1": isButtonRequire === false,
          }
        )}
      >
        <div className={iconVerticalAlignment}>
          {iconName === "info" ? (
            <BsInfoCircleFill size={iconSize} />
          ) : (
            <BsExclamationCircleFill size={iconSize} className="h-8 w-8" />
          )}
        </div>
        <div className="text-base">{message}</div>
      </div>
      {isButtonRequire && (
        <Button
          size="md"
          customWidth={customButtonWidth}
          btnClassName="border border-white px-4 self-center rounded-full m-1 py-2"
          onClick={() =>
            handleButtonClick ? handleButtonClick() : navigate(link)
          }
          type={buttonType || alertType}
          btnName={btnName}
          title={buttonTitle}
        />
      )}
    </div>
  );
};

ActionAlert.defaultProps = {
  message: "",
  link: "",
  btnName: "",
  buttonType: "",
  customButtonWidth: 36,
  customClassName: "",
  testId: "generic-button",
  isButtonRequire: true,
  iconName: "",
  roundedBorder: true,
  iconSize: 30,
  iconVerticalAlignment: "self-center",
};
ActionAlert.propTypes = {
  message: Proptypes.oneOfType([Proptypes.node, Proptypes.string]),
  buttonType: Proptypes.string,
  buttonTitle: Proptypes.string,
  link: Proptypes.string,
  customButtonWidth: Proptypes.number,
  handleButtonClick: Proptypes.func,
  customClassName: Proptypes.string,
  isButtonRequire: Proptypes.bool,
  roundedBorder: Proptypes.bool,
  iconName: Proptypes.string,
  btnName: Proptypes.string,
  alertType: Proptypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "danger",
    "info",
  ]).isRequired,
  testId: Proptypes.string,
  iconSize: Proptypes.number,
  iconVerticalAlignment: Proptypes.string,
};
export default ActionAlert;
