import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import { addMonths, format, isBefore, isEqual, toDate } from "date-fns";
import { lowerCase } from "lodash";
import PropTypes from "prop-types";

import {
  getColleges,
  getEducationLevels,
  getQualifications,
  getSpecializations,
  getStates,
} from "api/candidate/profileApi";
import { DECIMAL_INFO } from "app/constants";
import { Button, LabeledInput } from "components/atoms";
import { handleOptionLabel } from "helpers/formatDropDownOption";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import { validateDropdowns } from "helpers/utils";
import useAlert from "hooks/useAlert";
import {
  createEducationQualificationsAction,
  getEducationQualificationsAction,
  updateEducationQualificationsAction,
} from "store/thunkActions/candidate/educationQualificationThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const Form = ({ educationQualification, setIsEditState, setIsAdding }) => {
  const {
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    clearErrors,
    formState: { isDirty, errors, isSubmitting },
    register,
  } = useForm();

  const dispatch = useDispatch();
  const showAlert = useAlert();

  useEffect(
    () =>
      reset({
        ...educationQualification,
        state: {
          id: educationQualification?.college?.state_id,
          name: educationQualification?.college?.state_name,
        },
        college: {
          id: educationQualification?.college?.id,
          name: educationQualification?.college?.name,
          state_name: educationQualification?.college?.state_name,
        },
        qualification: {
          id: educationQualification?.qualification_id,
          name: educationQualification?.qualification_name,
          branch_required: educationQualification?.branch_required,
        },
        branch: {
          id: educationQualification?.branch_id,
          name: educationQualification?.branch_name,
        },
        level: {
          id: educationQualification?.education_level_id,
          name: educationQualification?.education_level_name,
          allow_external_qualification:
            educationQualification?.allow_external_qualification,
        },
      }),
    [educationQualification]
  );

  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const isPursuing = watch("is_pursuing");
  const college = watch("college");
  const qualification = watch("qualification");
  const branch = watch("branch");
  const state = watch("state");
  const stateId = watch("state")?.id;
  const level = watch("level");
  const educationLevelId = level?.id;
  const [selectedLevel, setSelectedLevel] = useState();

  const handleStatusChange = (value) => {
    setSelectedLevel(value);
  };

  const loadEducationLevels = async (search, _, { page }) => {
    const { data, meta } = await getEducationLevels(page, search);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    loadQualification(1, educationLevelId);
  }, [selectedLevel]);

  const loadQualification = (page, educationLevelId) => {
    getQualifications(page, "", educationLevelId).then((response) => {
      if (
        selectedLevel &&
        selectedLevel.allow_external_qualification == false
      ) {
        setValue("qualification", {
          name: response.data[0]?.name,
          id: response.data[0]?.id,
          branch_required: response.data[0]?.branch_required,
        });

        if (errors.qualification) {
          clearErrors("qualification");
        }
      }
    });
  };

  const loadStateOptions = async (search, _, { page }) => {
    const {
      data: {
        data: options,
        meta: { has_more: hasMore },
      },
    } = await getStates(page, search);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCollegeOptions = async (search, _, { page }) => {
    const { data, meta } = await getColleges(page, search, stateId);
    return {
      options: meta.has_more
        ? data
        : [...data, { id: null, name: "OTHERS", state: "" }],
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadQualificationOptions = async (search, _, { page }) => {
    const { data, meta } = await getQualifications(
      page,
      search,
      educationLevelId
    );

    return {
      // eslint-disable-next-line no-nested-ternary
      options: meta.has_more
        ? data
        : level?.allow_external_qualification === false
        ? [...data]
        : [...data, { id: null, name: "OTHERS" }],
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadSpecializationOptions = async (search, _, { page }) => {
    const { data, meta } = await getSpecializations(page, search);

    return {
      options: meta.has_more ? data : [...data, { id: null, name: "OTHERS" }],
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const setErrorsOnFields = (errors) => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        showAlert("danger", `Error on ${lowerCase(key)} : ${value[0]}`);
      });
    }
  };

  const submitHandler = (data) => {
    // Restrict API call when no changes
    if (
      data.id &&
      !isDirty &&
      educationQualification?.is_pursuing === data.is_pursuing
    ) {
      return setIsEditState(false);
    }

    const collegeData = { ...data };

    // Structuring req body
    if (data.college?.name === "OTHERS") {
      collegeData.college_name = "OTHERS";
      collegeData.state_id = data.otherState?.id;
    } else {
      collegeData.college_name = data.college?.label;
    }

    // TODO: Education API has some problem, in this PR this will rectify
    if (data.qualification?.name === "OTHERS") {
      collegeData.qualification_name = "OTHERS";
      collegeData.education_level_id = data.level.id;
    } else {
      collegeData.qualification_name = data.qualification?.label;
    }

    collegeData.branch_name =
      data.branch?.name === "OTHERS" ? "OTHERS" : data.branch?.label;

    collegeData.college_name_other = data.OtherCollege?.toUpperCase();
    collegeData.qualification_name_other = data.OtherQualification;
    collegeData.college_id = data.college?.id;
    collegeData.qualification_id = data.qualification?.id;

    collegeData.branch_name_other = data.OtherBranch;
    collegeData.branch_id = data.branch ? data.branch.id : null;
    collegeData.start_date =
      data.start_date && format(new Date(data.start_date), "yyyy-MM-dd");
    collegeData.end_date =
      data.end_date && format(new Date(data.end_date), "yyyy-MM-dd");
    delete collegeData.level;

    // Update if existing record else create new
    const action = data.id
      ? updateEducationQualificationsAction(collegeData)
      : createEducationQualificationsAction(collegeData);

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(setCandidateUserAction());
        dispatch(getEducationQualificationsAction());
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };
  const inputErrClass = (error) => {
    return error ? "input-error" : "input";
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <p className="text-sm font-medium text-gray-dark">
        Please fill out the details for adding new education
      </p>
      <label
        htmlFor="college_select"
        className={`required mt-2 mb-1 block font-medium ${
          errors.college && "text-danger-main"
        }`}
      >
        College/School
      </label>

      {college?.name !== "OTHERS" && (
        <>
          <p className="text-sm font-medium text-gray-dark">
            You can select the state in which your school/college is located to
            get a filtered list.
          </p>
          <div className="my-4 flex gap-2">
            <Controller
              control={control}
              name="state"
              render={({ field }) => (
                <AsyncPaginate
                  isSearchable
                  isClearable
                  id="state_select"
                  menuPlacement="auto"
                  className="w-full"
                  styles={reactSelectStyle(errors.state)}
                  value={
                    field.value?.name || college?.state_name
                      ? field.value
                      : null
                  }
                  loadOptions={loadStateOptions}
                  onChange={(value) => {
                    field.onChange(value);
                    setValue("college", null);
                  }}
                  noOptionsMessage={() => "Not found"}
                  debounceTimeout={1000}
                  placeholder="Filter by state"
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  additional={{ page: 1 }}
                  formatOptionLabel={(option, { context }) =>
                    handleOptionLabel(option, context)
                  }
                />
              )}
            />
          </div>
        </>
      )}
      <Controller
        control={control}
        name="college"
        rules={{
          validate: (college) =>
            validateDropdowns({ field: college, limit: 255 }),
        }}
        render={({ field }) => (
          <AsyncPaginate
            isSearchable
            isClearable
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            id="college_select"
            menuPlacement="auto"
            className="w-full"
            styles={reactSelectStyle(errors.college)}
            value={field.value?.label || field.value?.name ? field.value : null}
            loadOptions={loadCollegeOptions}
            onChange={(value) => {
              field.onChange(value);
              setValue("state", {
                id: value?.state_id,
                name: value?.state_name,
              });
            }}
            noOptionsMessage={() => "Not found"}
            debounceTimeout={1000}
            cacheUniqs={[state]}
            placeholder="Select College/School Name"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.name}
            additional={{ page: 1 }}
            formatOptionLabel={(option, { context }) =>
              handleOptionLabel(option, context)
            }
          />
        )}
      />

      {college?.name === "OTHERS" && (
        <>
          <p className="text-sm  text-warning-main">
            Please recheck the list again before creating a custom
            college/school name.
          </p>
          <LabeledInput
            id="otherscollege"
            labelClassNames={classNames(
              "block mt-2 mb-1 font-medium required",
              {
                "text-danger-main": errors.OtherCollege,
              }
            )}
            label="College/School Name"
            name="OtherCollege"
            inputClassNames={classNames(
              "w-full",
              inputErrClass(errors.OtherCollege)
            )}
            placeholder="Select College/School Name"
            register={register}
            type="text"
            rows={1}
            validation={{
              required: requiredValidation(),
              maxLength: {
                value: 255,
                message: characterValidation({ limit: 255 }),
              },
            }}
            errorMessage={errors.OtherCollege?.message}
          />
          <div className="my-4 flex gap-4">
            <div className="w-full">
              <label
                htmlFor="other_state_select"
                className={`required mt-2 mb-1 w-full font-medium ${
                  errors.otherState && "text-danger-main"
                }`}
              >
                State
              </label>
              <Controller
                control={control}
                name="otherState"
                rules={{
                  validate: (otherState) =>
                    validateDropdowns({ field: otherState, limit: 100 }),
                }}
                render={({ field }) => (
                  <AsyncPaginate
                    isSearchable
                    isClearable
                    id="other_state_select"
                    menuPlacement="auto"
                    className="w-full"
                    styles={reactSelectStyle(errors.state)}
                    value={
                      field.value?.label || field.value?.name
                        ? field.value
                        : null
                    }
                    loadOptions={loadStateOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    noOptionsMessage={() => "Not found"}
                    debounceTimeout={1000}
                    placeholder="Select State"
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    additional={{ page: 1 }}
                    formatOptionLabel={(option, { context }) =>
                      handleOptionLabel(option, context)
                    }
                  />
                )}
              />
              {errors.otherState && (
                <p className="text-sm text-danger-dark">
                  {errors.otherState.message}
                </p>
              )}
            </div>
          </div>
        </>
      )}
      {errors.college && (
        <p className="text-sm text-danger-dark">{errors.college.message}</p>
      )}

      <label
        htmlFor="level_select"
        className={`required mt-2 mb-1 block font-medium ${
          errors.level && "text-danger-main"
        }`}
      >
        Level
      </label>

      <Controller
        control={control}
        name="level"
        rules={{
          validate: (level) => validateDropdowns({ field: level, limit: 255 }),
        }}
        render={({ field }) => (
          <AsyncPaginate
            isSearchable={false}
            isClearable
            id="level_select"
            menuPlacement="auto"
            className="w-full"
            styles={reactSelectStyle(errors.level)}
            value={field.value?.label || field.value?.name ? field.value : null}
            loadOptions={loadEducationLevels}
            onChange={(value) => {
              field.onChange(value);
              setValue("qualification", null);
              setValue("branch", null);
              handleStatusChange(value);
            }}
            noOptionsMessage={() => "Not found"}
            debounceTimeout={1000}
            placeholder="Select the level of education"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option?.name}
            additional={{ page: 1 }}
          />
        )}
      />

      {errors.level && (
        <p className="text-sm text-danger-dark">{errors.level.message}</p>
      )}

      <label
        htmlFor="qualification_select"
        className={`required mt-2 mb-1 block font-medium ${
          errors.qualification && "text-danger-main"
        }`}
      >
        Qualification
      </label>
      <Controller
        control={control}
        name="qualification"
        rules={{
          validate: (qualification) =>
            validateDropdowns({ field: qualification, limit: 100 }),
        }}
        render={({ field }) => (
          <AsyncPaginate
            isSearchable
            isClearable
            isDisabled={level?.name == null}
            id="qualification_select"
            menuPlacement="auto"
            className={`w-full ${level?.name == null && "bg-gray-lighter"}`}
            styles={reactSelectStyle(errors.qualification)}
            value={field.value?.label || field.value?.name ? field.value : null}
            loadOptions={loadQualificationOptions}
            cacheUniqs={[level]}
            onChange={(value) => {
              field.onChange(value);
              setValue("branch", null);
            }}
            noOptionsMessage={() => "Not found"}
            debounceTimeout={1000}
            placeholder="Enter qualification"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name || option.label}
            additional={{ page: 1 }}
            formatOptionLabel={(option, { context }) =>
              handleOptionLabel(option, context)
            }
          />
        )}
      />
      {qualification?.name === "OTHERS" && (
        <>
          <p className="text-sm  text-warning-main">
            Please recheck the list again before creating a custom qualification
            name.
          </p>
          <LabeledInput
            id="othersqualification"
            labelClassNames={classNames(
              "block mt-2 mb-1 font-medium required",
              {
                "text-danger-main": errors.OtherQualification,
              }
            )}
            label="Qualification Name"
            name="OtherQualification"
            inputClassNames={classNames(
              "w-full",
              inputErrClass(errors.OtherQualification)
            )}
            placeholder="Enter qualification name"
            register={register}
            type="text"
            rows={1}
            validation={{
              required: requiredValidation(),
              maxLength: {
                value: 100,
                message: characterValidation({ limit: 100 }),
              },
            }}
            errorMessage={errors.OtherQualification?.message}
          />
        </>
      )}
      {errors.qualification && (
        <p className="text-sm text-danger-dark">
          {errors.qualification.message}
        </p>
      )}

      <label
        htmlFor="marks"
        className={`mt-2 mb-1 block font-medium ${
          errors.marks && "text-danger-main"
        }`}
      >
        Marks
      </label>
      <div className="mt-2 flex items-center text-secondary-alertDark">
        <BsFillExclamationCircleFill size={16} />
        <span className="ml-2 text-sm">
          If you have marks in GPA, please convert it as per your
          board/university standard before entering.
        </span>
      </div>

      <div className="mt-2 flex items-center">
        <div className="w-4/5">
          <input
            id="marks"
            className={`${errors.marks ? "input-error" : "input"} w-full`}
            type="text"
            placeholder="Enter percentage of marks"
            {...register("marks", {
              max: {
                value: 100,
                message: DECIMAL_INFO.MAX,
              },
              min: {
                value: 0,
                message: "must be greater than or equal to 0",
              },
              pattern: {
                value: /^\d*\.{0,1}\d{0,2}$/,
                message: DECIMAL_INFO.PATTERN,
              },
            })}
          />
        </div>

        <div className="input w-1/5 bg-gray-lighterShade5">
          <span>%</span>
        </div>
      </div>
      {errors.marks && (
        <p className="mt-1 text-sm text-danger-dark">{errors.marks.message}</p>
      )}
      {qualification?.branch_required === true && (
        <div>
          <label
            htmlFor="specialization_select"
            className={`mt-2 mb-1 block font-medium ${
              errors.branch && "text-danger-main"
            }`}
          >
            Specialization
          </label>
          <Controller
            control={control}
            name="branch"
            rules={{
              validate: (branch) =>
                validateDropdowns({
                  field: branch,
                  limit: 100,
                  isRequired: false,
                }),
            }}
            render={({ field }) => (
              <AsyncPaginate
                isSearchable
                isClearable
                isDisabled={qualification?.name == null}
                id="specialization_select"
                menuPlacement="auto"
                className={`w-full ${
                  qualification?.name == null && "bg-gray-lighter"
                }`}
                styles={reactSelectStyle(errors.branch)}
                value={
                  field.value?.label || field.value?.name ? field.value : null
                }
                loadOptions={loadSpecializationOptions}
                onChange={(value) => field.onChange(value)}
                noOptionsMessage={() => "Not found"}
                debounceTimeout={1000}
                placeholder="Enter specialization"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name || option.label}
                additional={{ page: 1 }}
              />
            )}
          />
        </div>
      )}
      {branch?.name === "OTHERS" && (
        <>
          <p className="text-sm  text-warning-main">
            Please recheck the list again before creating a custom
            specialization name.
          </p>
          <LabeledInput
            id="othersBranch"
            labelClassNames={classNames(
              "block mt-2 mb-1 font-medium required",
              {
                "text-danger-main": errors.OtherBranch,
              }
            )}
            label="Specialization Name"
            name="OtherBranch"
            inputClassNames={classNames(
              "w-full",
              inputErrClass(errors.OtherBranch)
            )}
            placeholder="Enter specialization name"
            register={register}
            type="text"
            rows={1}
            validation={{
              required: requiredValidation(),
              maxLength: {
                value: 100,
                message: characterValidation({ limit: 100 }),
              },
            }}
            errorMessage={errors.OtherBranch?.message}
          />
        </>
      )}
      {errors.branch && (
        <p className="text-sm text-danger-dark">{errors.branch.message}</p>
      )}
      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="start_date"
            className={`required mt-2 mb-1 block font-medium ${
              errors.start_date && "text-danger-main"
            }`}
          >
            From
          </label>

          <Controller
            control={control}
            name="start_date"
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <DatePicker
                showMonthYearPicker
                showFullMonthYearPicker
                placeholderText="Select start date"
                id="start_date"
                className={`${
                  errors.start_date ? "input-error" : "input"
                } w-full`}
                dateFormat="MMMM yyyy"
                maxDate={new Date()}
                onChange={(date) => {
                  field.onChange(date);
                  if (isBefore(new Date(endDate), new Date(date))) {
                    setValue("end_date", null);
                    clearErrors("end_date");
                  } else if (isEqual(new Date(endDate), new Date(date))) {
                    setValue("end_date", null);
                    clearErrors("end_date");
                  } else if (date == null) {
                    setValue("end_date", null);
                  }
                }}
                selected={field.value && toDate(new Date(field.value))}
              />
            )}
          />

          {errors.start_date && (
            <p className="text-sm text-danger-dark">
              {errors.start_date.message}
            </p>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="end_date"
            className={`required mt-2 mb-1 block font-medium ${
              errors.end_date && "text-danger-main"
            }`}
          >
            To
          </label>

          <Controller
            control={control}
            name="end_date"
            rules={{
              required: isPursuing ? null : requiredValidation(),
            }}
            render={({ field }) => (
              <DatePicker
                disabled={isPursuing ? true : !startDate}
                showPopperArrow={false}
                showYearDropdown
                dropdownMode="select"
                showMonthYearPicker
                showFullMonthYearPicker
                placeholderText="Select end date"
                id="end_date"
                className={`${
                  errors.end_date ? "input-error" : "input"
                } disabled:input-disabled w-full`}
                dateFormat="MMMM yyyy"
                minDate={startDate ? addMonths(new Date(startDate), 1) : null}
                onChange={(date) => {
                  field.onChange(date);
                }}
                selected={field.value && toDate(new Date(field.value))}
              />
            )}
          />

          {errors.end_date && (
            <p className="text-sm text-danger-dark">
              {errors.end_date.message}
            </p>
          )}

          <label
            htmlFor="currently_pursuing"
            className="mt-1 flex items-center text-sm"
          >
            <input
              data-testid="college-name"
              type="checkbox"
              id="currently_pursuing"
              {...register("is_pursuing")}
              onChange={(e) => {
                setValue("is_pursuing", e.target.checked);
                setValue("end_date", null);
                clearErrors("end_date");
              }}
            />
            <span className="ml-2 ">Pursuing</span>
          </label>
        </div>
      </div>

      {/* Added a flag input to satisfy the default condition of useforms */}
      <input
        id="candidate_education"
        {...register("candidate_education")}
        className="hidden"
      />
      {errors.candidate_education && (
        <p className="text-sm text-danger-dark">
          {errors.candidate_education.message}
        </p>
      )}
      <div className="mt-6 mb-2 flex w-full">
        <Button
          title={setIsAdding ? "Save" : "Update"}
          testId="saveEducationBtn"
          type="primary"
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          {setIsAdding ? "Save" : "Update"}
        </Button>

        <Button
          title="Cancel"
          testId="cancelEducationBtn"
          type="secondary"
          size="sm"
          btnClassName="ml-2"
          onClick={() => setIsEditState(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  educationQualification: PropTypes.shape({
    id: PropTypes.number,
    college: PropTypes.object,
    qualification_id: PropTypes.number,
    qualification_name: PropTypes.string,
    is_pursuing: PropTypes.bool,
    branch_id: PropTypes.number,
    branch_name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    education_level_id: PropTypes.number,
    education_level_name: PropTypes.string,
    allow_external_qualification: PropTypes.bool,
    branch_required: PropTypes.bool,
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};

export default Form;
