import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { snakeCase, startCase } from "lodash";

import { listJobs } from "api/candidate/jobApi";
import { checkProfile } from "api/candidate/profileApi";
import { ALERT_TYPE } from "app/constants";
import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import { InfiniteScrollWrapper, TabList } from "components/atoms";
import { Modal } from "components/molecules";
import { SITE_TITLE } from "constants/common";
import { JOB_STATUS, JOB_TYPES } from "constants/jobs";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";
import useAlert from "hooks/useAlert";
import usePreviousTab from "hooks/usePreviousTab";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import {
  handleApplyJobAction,
  handleSaveJobAction,
  handleUnsaveJobAction,
} from "store/thunkActions/candidate/jobApplyThunk";

import ConfirmApplyModal from "./ConfirmApplyModal";
import JobDetails from "./JobDetails";
import JobList from "./JobList";

const Job = () => {
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [jobList, setjobList] = useState({
    data: [],
    meta: { total_pages: 1 },
    isLoading: true,
  });

  const {
    previousTab,
    setPreviousTab,
    currentTab = "suggested",
  } = usePreviousTab();

  const [selectedJob, setSelectedJob] = useState(null);
  const [showConfirmApplyModal, setShowConfirmApplyModal] = useState(false);
  const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [savedJobId, setSavedJobId] = useState(0);
  const maxTabScreenWidth = 992;

  const jobDetailClass = classNames({
    "w-full sticky top-[120px]": true,
    block: selectedJob,
    "hidden lg:block": !selectedJob,
  });

  const jobListClass = classNames({
    "max-h-[80vh] overflow-y-auto custom-scrollbar": true,
    "w-full lg:w-6/12": true,
    block: !selectedJob,
    "hidden lg:block": selectedJob,
  });

  useEffect(() => {
    hideFreshWorkWidget();
    dispatch(
      updateHeaderTitle({ breadCrumbDetails: { currentPageName: "Jobs" } })
    );
  }, []);

  useEffect(() => {
    hideFreshWorkWidget();
    checkProfile().then((response) => {
      setIsProfileCompleted(response.data?.profile_completed);
    });
  }, []);

  useEffect(() => {
    if (previousTab !== currentTab) {
      setCurrentPage(0);
      setjobList({
        data: [],
        meta: { total_pages: 1 },
        isLoading: true,
      });
      setPreviousTab(currentTab);
      setSelectedJob(null);
    }
  }, [currentTab]);

  useEffect(() => {
    const scrollYSavedOffset = localStorage.getItem("jobPage:scrollY");
    const screenWidth = window.innerWidth;

    if (scrollYSavedOffset && !selectedJob && screenWidth < maxTabScreenWidth) {
      window.scrollTo({ top: Number(scrollYSavedOffset), behavior: "smooth" });
      localStorage.removeItem("jobPage:scrollY");
    }
  }, [selectedJob]);

  const getJobs = (page) =>
    listJobs(page, currentTab).then((response) => {
      setCurrentPage((prev) => prev + 1);
      setjobList({
        data:
          response.meta?.page_number === 1
            ? response.data
            : [...jobList.data, ...response.data],
        meta: response.meta,
        isLoading: false,
      });

      return response;
    });

  const mappedJob = (jobs, condition, key, value) =>
    jobs.map((data) => (condition(data) ? { ...data, [key]: value } : data));

  const filteredJob = (jobs, condition) => jobs.filter((job) => condition(job));

  const handleNotFound = (isAffectedSelectedJob, jobUuid) => {
    if (isAffectedSelectedJob) {
      setSelectedJob({
        ...selectedJob,
        status: snakeCase(JOB_STATUS.applicationClosed.text),
      });
    }
    setjobList((prevState) => {
      const data = mappedJob(
        prevState.data,
        (value) => value.uuid === jobUuid,
        "status",
        snakeCase(JOB_STATUS.applicationClosed.text)
      );
      return { ...prevState, data };
    });
  };

  const handleOnJobClick = (job) => {
    setSelectedJob(job);
    localStorage.setItem("jobPage:scrollY", window.scrollY);
  };

  const handleApplyJob = () => {
    return dispatch(handleApplyJobAction(selectedJob.uuid))
      .unwrap()
      .then(() => {
        setjobList((prevState) => {
          const data = filteredJob(
            prevState.data,
            (value) => value.uuid !== selectedJob.uuid
          );

          return { ...prevState, data };
        });
        setSelectedJob(null);
        setShowConfirmApplyModal(false);
        navigate("/jobs/applied");
      })
      .catch(({ meta }) => {
        setShowConfirmApplyModal(false);
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        if (meta) {
          handleNotFound(true, selectedJob.uuid);
        }
      });
  };

  const handleBookmarkJob = (event, job) => {
    event.stopPropagation();

    if (job.is_saved) {
      setSavedJobId(job.uuid);
      setShowConfirmSaveModal(true);
    } else {
      dispatch(handleSaveJobAction(job.uuid))
        .unwrap()
        .then(() => {
          setjobList((prevState) => {
            const data = mappedJob(
              prevState.data,
              (value) => value.uuid === job.uuid,
              "is_saved",
              true
            );
            return { ...prevState, data };
          });
        })
        .catch((err) => {
          if (err?.response?.data) {
            showAlert("danger", Object.values(err.response.data.errors)[0]);
          }
          if (err.response.status === 404) {
            handleNotFound(selectedJob?.uuid === job.uuid, job.uuid);
          }
        });
    }
  };

  const removeBookmark = () => {
    dispatch(handleUnsaveJobAction(savedJobId))
      .unwrap()
      .then(() => {
        if (selectedJob?.uuid === savedJobId) {
          setSelectedJob(null);
        }
        setjobList((prevState) => {
          const data =
            currentTab === "suggested"
              ? mappedJob(
                  prevState.data,
                  (value) => value.uuid === savedJobId,
                  "is_saved",
                  false
                )
              : filteredJob(
                  prevState.data,
                  (value) => value.uuid !== savedJobId
                );
          return { ...prevState, data };
        });
      })
      .catch((err) => {
        showAlert(
          ALERT_TYPE[err.response.data.meta.message_type],
          err.response.data.meta.message
        );
        if (err.response.status === 404) {
          handleNotFound(selectedJob?.uuid === savedJobId, savedJobId);
        }
      })
      .finally(() => setShowConfirmSaveModal(false));
  };

  return (
    <div className="break-word px-4 bg-gray-lighterShade3">
      <Helmet>
        <title>{`${startCase(currentTab)} Jobs - ${SITE_TITLE}`}</title>
      </Helmet>
      <div className="sticky text-md top-[45px] z-10 bg-gray-lighterShade3">
        <TabList
          tabs={JOB_TYPES}
          currentTab={currentTab}
          link="/jobs"
          onTabClick={(key) => {
            if (key !== currentTab) {
              setSelectedJob(null);
            }
          }}
        />
      </div>
      <div className="flex lg:gap-2">
        <div className={jobListClass}>
          <InfiniteScrollWrapper
            hasMore={currentPage < jobList.meta.total_pages}
            loadMoreHandler={() => getJobs(currentPage + 1, false)}
          >
            <JobList
              currentTab={currentTab}
              jobs={jobList.data}
              selectedJob={selectedJob}
              handleOnJobClick={handleOnJobClick}
              handleBookmarkJob={handleBookmarkJob}
              getJobs={getJobs}
            />
          </InfiniteScrollWrapper>
        </div>
        <div className="sticky flex-1 lg:mt-2 mt-6">
          {jobList.data.length > 0 && (
            <div className={jobDetailClass}>
              <JobDetails
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                currentTab={currentTab}
                setShowConfirmModal={setShowConfirmApplyModal}
              />
            </div>
          )}
        </div>
      </div>

      {jobList.data.length === 0 && !jobList.isLoading && (
        <div className="pt-16">
          <img src={NoDataFound} alt="no data found" className="m-auto" />
        </div>
      )}

      {showConfirmApplyModal && selectedJob && (
        <ConfirmApplyModal
          isProfileCompleted={isProfileCompleted}
          handleApplyJob={handleApplyJob}
          setShowConfirmApplyModal={setShowConfirmApplyModal}
          setIsProfileCompleted={setIsProfileCompleted}
          visibility={selectedJob.visibility}
        />
      )}

      {showConfirmSaveModal && (
        <Modal
          title="Are you sure?"
          description="Are you sure you want to remove this job from your saved jobs list?"
          acceptButtonText="Yes"
          rejectButtonText="No"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          testId="confirm-job-bookmark-modal"
          handleAccept={() => removeBookmark()}
          handleReject={() => setShowConfirmSaveModal(false)}
        />
      )}
    </div>
  );
};

export default Job;
