import { createSlice } from "@reduxjs/toolkit";

import { getAllUsersAction } from "store/thunkActions/erp/userRolesThunk";

const evaluators = {
  filters: ["ADMIN", "EVALUATOR"],
  data: [],
  meta: { has_more: true, total_pages: 1, page_number: 0 },
  isLoading: null,
};

const userRoles = createSlice({
  name: "userRoles",
  initialState: evaluators,
  reducers: {
    updateStatus: (state, action) => {
      return {
        ...state,
        filters: action.payload.selectedFilters,
      };
    },
    resetState: () => evaluators,
  },
  extraReducers: {
    [getAllUsersAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllUsersAction.fulfilled]: (state, action) => {
      state.data = [
        ...(action.payload.meta.page_number === 1 ? [] : state.data),
        ...action.payload.data,
      ];
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
  },
});

export const { updateStatus } = userRoles.actions;

export default userRoles.reducer;
