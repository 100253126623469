import api, { organisationApi } from "api/axios";

// Question Paper APIs
export const getQuestionPapers = async (
  page,
  pageSize,
  sectional,
  type,
  active,
  searchTerm
) => {
  const response = await organisationApi.get("/question_papers", {
    params: {
      page,
      page_size: pageSize,
      sectional,
      type,
      active,
      search_term: searchTerm,
    },
  });
  return response;
};

export const getQuestionPapersByType = (page, searchTerm, type) => {
  const response = organisationApi.get("/question_papers", {
    params: { page, type, active: true, search_term: searchTerm },
  });
  return response;
};

export const createQuestionPaper = async (data) => {
  const response = await organisationApi.post("/question_papers", data);
  return response;
};

export const showQuestionPaper = async (id) => {
  const response = await organisationApi.get(`/question_papers/${id}`);
  return response;
};

export const updateQuestionPaperName = async (data) => {
  const response = await organisationApi.patch(
    `/question_papers/${data.id}`,
    data
  );
  return response;
};

export const archiveQuestionPaper = async (archiveQuestionPaperData) => {
  const response = await organisationApi.patch(
    `/question_papers/${archiveQuestionPaperData.id}`,
    archiveQuestionPaperData
  );
  return response;
};

export const unArchiveQuestionPaper = async (unArchiveQuestionPaperData) => {
  const response = await organisationApi.patch(
    `/question_papers/${unArchiveQuestionPaperData.id}`,
    unArchiveQuestionPaperData
  );
  return response;
};

// Sections APIs
export const getSections = async (id) => {
  const response = await organisationApi.get(`/question_paper/${id}/sections`);
  return response;
};

export const addSection = async (data) => {
  const response = await organisationApi.post("/sections", data);
  return response;
};

export const updateSectionName = async (data) => {
  const response = await organisationApi.patch(`/sections/${data.id}`, data);
  return response;
};

export const deleteSection = async (data) => {
  const response = await organisationApi.delete(`/sections/${data.id}`);
  return response;
};

// Question APIs
export const getQuestionPaperQuestions = async (questionPaperId) => {
  const response = await organisationApi.get(
    `/question_paper/${questionPaperId}/questions`
  );
  return response;
};

export const addQuestionPaperQuestion = async (id, data) => {
  const response = await organisationApi.post(
    `/question_paper/${id}/questions`,
    data
  );
  return response;
};

export const getQuestionPaperQuestion = async (questionPaperId, id) => {
  const response = await organisationApi.get(
    `/question_paper/${questionPaperId}/questions/${id}`
  );
  return response;
};

export const getQuestionPaperQuestionsBySection = (id, sectionId) => {
  const response = organisationApi.get(`/question_paper/${id}/questions`, {
    params: { section_id: sectionId },
  });
  return response;
};

export const addQuestionImage = async (data) => {
  const response = await organisationApi.post("/question/images", data);
  return response;
};

export const deleteQuestionImage = async (id) => {
  const response = await organisationApi.delete(`/question/images/${id}`);
  return response;
};

export const deleteQuestionPaperQuestions = async (id) => {
  const response = await organisationApi.delete(
    `/question_papers_questions/${id}`
  );
  return response;
};

export const addQuestionBankQuestions = async (data) => {
  const response = await organisationApi.post(
    "/question_papers_questions",
    data
  );
  return response;
};

// Question Bank APIs
export const getQuestionBank = async (
  page,
  type,
  category,
  tagIds,
  searchTerm
) => {
  const response = await organisationApi.get("/questions", {
    params: { page, type, category, tag_ids: tagIds, search_term: searchTerm },
  });
  return response;
};

export const addQuestion = async (data) => {
  const response = await organisationApi.post("/questions", data);
  return response;
};

export const updateQuestion = async (id, data) => {
  const response = await organisationApi.patch(`/questions/${id}`, data);
  return response;
};

export const showQuestion = async (id) => {
  const response = await organisationApi.get(`/questions/${id}`);
  return response;
};

export const deleteQuestion = async (id) => {
  const response = await organisationApi.delete(`/questions/${id}`);
  return response;
};

// Tags
export const getQuestionTags = async (searchTerm) => {
  const response = await organisationApi.get("/tags", {
    params: {
      search_term: searchTerm,
    },
  });
  return response;
};

export const createQuestionTags = async (data) => {
  const response = await organisationApi.post("/tags", data);
  return response;
};

export const updateQuestionTags = async (data) => {
  const response = await organisationApi.patch(`/tags/${data.id}`, data);
  return response;
};

export const deleteQuestionTags = async (data) => {
  const response = await organisationApi.delete(`/tags/${data.id}`);
  return response;
};

// Categories
export const getCategories = async (parentCategoryId, searchTerm) => {
  const response = await organisationApi.get("/categories", {
    params: { parent_category_id: parentCategoryId, search_term: searchTerm },
  });
  return response;
};

export const createCategories = async (data) => {
  const response = await organisationApi.post("/categories", data);
  return response;
};

export const updateCategories = async (data) => {
  const response = await organisationApi.patch(`/categories/${data.id}`, data);
  return response;
};

export const deleteCategories = async (data) => {
  const response = await organisationApi.delete(`/categories/${data.id}`);
  return response;
};

// MCQ Options
// export const getOptions = async (id) => {
//   const response = await organisationApi.get(`/question_paper/${id}/questions`);
// };

export const addOption = async (data) => {
  const response = await organisationApi.post("/question/answers", data);
  return response;
};

export const updateOption = async (id, data) => {
  const response = await organisationApi.patch(`/question/answers/${id}`, data);
  return response;
};

export const deleteOption = async (id) => {
  const response = await organisationApi.delete(`/question/answers/${id}`);
  return response;
};

// Coding languages & Driver codes
export const getDriverCodes = async () => {
  const response = await api.get("/coding_language");
  return response;
};

// Get Answers
export const getCandidateAnswers = async (candidateId, examId) => {
  const response = await organisationApi.get("/exam_candidate", {
    params: {
      candidate_id: candidateId,
      exam_id: examId,
    },
  });
  return response;
};

export const updateCandidateAnswer = async (id, data) => {
  const response = await organisationApi.patch(
    `/candidate_answers/${id}`,
    data
  );
  return response;
};

export const reEvaluateCandidateAnswer = async (data) => {
  const response = await organisationApi.patch("/reevaluate_answer", data);
  return response;
};
