import { BiLink } from "react-icons/bi";
import { MdLocationOn, MdMailOutline } from "react-icons/md";
import PropTypes from "prop-types";

import { ICON_SIZE_HASH } from "app/constants";

const Contact = ({ email, portfolioUrl, contact }) => {
  return (
    <>
      <div className="resume-section-heading">CONTACT</div>
      <div
        style={{
          fontSize: "0.875rem",
          color: "rgb(100 100 100)",
          lineHeight: ["1.25rem", "1rem"],
          "@media (minWidth: 992px)": { lineHeight: "1.25rem" },
        }}
      >
        <div className="pb-1" style={{ display: "flex" }}>
          <MdMailOutline
            size={ICON_SIZE_HASH.sm}
            style={{
              marginTop: "0.125rem",
              marginRight: "0.25rem",
              flexShrink: "0",
              "@media (minWidth: 992px)": { marginRight: "0.5rem" },
            }}
          />
          <div style={{ wordBreak: "break-word" }}>{email}</div>
        </div>

        {portfolioUrl && (
          <div className="pb-1" style={{ display: "flex" }}>
            <BiLink
              size={ICON_SIZE_HASH.sm}
              style={{
                marginTop: "0.125rem",
                marginRight: "0.25rem",
                flexShrink: "0",
                "@media (minWidth: 992px)": { marginRight: "0.5rem" },
              }}
            />
            <div style={{ wordBreak: "break-word" }}>{portfolioUrl}</div>
          </div>
        )}

        {contact && (
          <div className="pb-1" style={{ display: "flex" }}>
            <MdLocationOn
              size={ICON_SIZE_HASH.sm}
              style={{
                marginTop: "0.125rem",
                marginRight: "0.25rem",
                flexShrink: "0",
                "@media (minWidth: 992px)": { marginRight: "0.5rem" },
              }}
            />
            <div style={{ wordBreak: "break-word" }}>
              {`${contact.address_line1 ? contact.address_line1 : ""}, ${
                contact.city ? contact.city : ""
              }, ${contact.country ? contact.country : ""}`}
            </div>
          </div>
        )}
      </div>
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

export default Contact;

Contact.defaultProps = {
  email: "",
  portfolioUrl: "",
  contact: {},
};

Contact.propTypes = {
  email: PropTypes.string,
  portfolioUrl: PropTypes.string,
  contact: PropTypes.shape({
    id: PropTypes.number,
    address_line1: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
};
