import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { FaCheckCircle, FaEnvelope, FaInfoCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { toLower } from "lodash";

import api from "api/axios";
import { ALERT_TYPE, EMAIL_REGEX, RECAPTCHA_KEY } from "app/constants";
import { Button } from "components/atoms";
import { RECAPTCHA_LOGIN_FAILED_MESSAGE } from "constants/common";
import { requiredValidation, userNotFound } from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";

const OrganisationSigninForm = () => {
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    watch,
    clearErrors,
  } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const location = useLocation();
  const showAlert = useAlert();
  const recaptchaRef = useRef();
  const [token, setToken] = useState(false);
  const email = watch("email");

  useEffect(() => {
    clearErrors();
    setIsSubmitted(false);
  }, []);

  // currently this is hard coded key check, later we will implement dynamic checking for user email check
  useEffect(() => {
    if (location.search.includes("redirect_url")) {
      showAlert(ALERT_TYPE.INFO, "Please login/signup to proceed.");
    }
    if (location.search.includes("message")) {
      showAlert(
        ALERT_TYPE.INFO,
        new URLSearchParams(location.search).get("message")
      );
    }
  }, [
    new URLSearchParams(location.search).get("redirect_url"),
    new URLSearchParams(location.search).get("message"),
  ]);

  const onChange = () => {
    setToken(recaptchaRef.current.getValue());
  };

  const setErrorsOnFields = (errors) => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) =>
        setError(key, { message: value[0] })
      );
    }
  };

  const submitHandler = async (body) => {
    setIsSaving(true);
    const url = "/organisation/login_email";
    const data = {
      email: body.email,
      redirect_url: new URLSearchParams(location.search).get("redirect_url"),
      token,
    };
    return api
      .post(url, data)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          setError("email", { type: "manual", message: userNotFound() });
        } else {
          setErrorsOnFields(error?.response?.data?.errors);
        }
        recaptchaRef.current?.reset();
        setToken(false);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const changeBtnText = () => {
    if (isSaving) {
      return "Processing...";
    }
    if (email && token) {
      return "Login";
    }
    return "Login";
  };

  return isSubmitted ? (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <FaCheckCircle className="mt-3 text-6xl text-green-500" />
      <p className="mt-3 text-center text-gray-dark">
        Login successful. <br />
        An email containing the password-less login link has been sent to{" "}
        <span className="font-semibold">{getValues("email")}</span>.
        <br />
        Please follow the instructions on the mail to continue.
      </p>
    </div>
  ) : (
    <form
      className="min-h-13 mx-auto w-full pb-6"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <p className="mb-3 text-center text-sm text-gray-dark">
        We will send a link to your email for password less login.
      </p>

      <div className="my-3">
        <div
          className={`flex h-10 items-center rounded-sm border-2 border-gray-lighter pl-2 text-gray-main focus-within:border-primary-main focus-within:text-gray-dark hover:border-primary-main hover:text-gray-dark ${
            errors.email &&
            `border-danger-light bg-danger-lighter focus-within:border-danger-light hover:border-danger-light`
          }`}
        >
          <FaEnvelope className="mr-4" />
          <input
            name="email"
            className={`h-full w-full outline-none pl-2 ${
              errors.email && `bg-danger-lighter`
            }`}
            disabled={!RECAPTCHA_KEY}
            placeholder="Email"
            {...register("email", {
              required: requiredValidation(),
              pattern: { value: EMAIL_REGEX, message: "invalid email" },
            })}
          />
        </div>
        {errors.email && (
          <p className="text-sm text-danger-dark">
            {toLower(errors.email.message)}
          </p>
        )}
        {RECAPTCHA_KEY ? (
          <div className="mt-3 flex flex-col items-center">
            <div className="flex-start">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="normal"
                onChange={onChange}
              />
              {errors.token && (
                <p className="text-sm text-danger-dark">
                  {errors.token.message}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="flex text-danger-main  gap-1 mt-6 justify-start">
            <div className="mt-0.5">
              <FaInfoCircle className="text-danger-main" />
            </div>
            <span className="text-xs"> {RECAPTCHA_LOGIN_FAILED_MESSAGE}</span>
          </div>
        )}
      </div>

      <Button
        title={changeBtnText()}
        btnClassName="w-full h-auto py-2 font-semibold text-sm"
        type={email && token && !isSaving ? "primary" : "disabled"}
        testId="organisation-login-signup"
      >
        {changeBtnText()}
      </Button>
    </form>
  );
};

export default OrganisationSigninForm;
