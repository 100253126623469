import { useState } from "react";
import { BiSearch, BiX } from "react-icons/bi";
import classNames from "classnames";
import PropTypes from "prop-types";

import { DEFAULT_PAGE_SIZE } from "constants/common";

import SearchBarFilterButton from "./SearchBarFilterButton";

const SearchBar = ({
  anyFilterApplied,
  resetFilterForm,
  useFormProps,
  filterField,
  searchParams,
  setSearchParams,
  searchBarPlaceholder,
  defaultSearchParams,
  skipSearchParams,
  handleSubmit,
  searchTerm,
  handleChange,
  handleClear,
}) => {
  const [searchQuery, setSearchQuery] = useState(
    searchParams?.get("search_term") || ""
  );

  const updateQueryParameters = (queryParams) => {
    const oldSearchParams = new URLSearchParams(window.location.search);
    const oldSearchParamObject = Object.fromEntries(oldSearchParams.entries());

    if (filterField) {
      const multiSelectLabels = filterField.filter(
        (label) => label.multiSelect
      );

      // code for handling array params, because normal process keeps only single ones
      multiSelectLabels.forEach((label) => {
        if (oldSearchParams.has(label.name)) {
          oldSearchParamObject[label.name] = oldSearchParams.getAll(label.name);
        }
      });
    }

    const reqSearchParams = {
      ...oldSearchParamObject,
      ...queryParams,
    };

    if (defaultSearchParams?.page) {
      reqSearchParams.page = 1;
    }

    Object.keys(reqSearchParams).forEach((key) => {
      if ([undefined, null].includes(reqSearchParams[key])) {
        delete reqSearchParams[key];
      }
    });

    setSearchParams(reqSearchParams);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    updateQueryParameters({ search_term: searchQuery.trim() });
  };

  const clearSearchBar = () => {
    const nameKeyword = searchParams.get("search_term");
    if (nameKeyword !== null) {
      searchParams.delete("search_term");
      setSearchParams(searchParams);
    }
    setSearchQuery("");
  };

  const handleOnChange = (e) => {
    setSearchQuery(e.target.value);
    if (!e.target.value) {
      clearSearchBar();
    }
  };

  const handleClearFilter = () => {
    Object.keys(defaultSearchParams).forEach((key) => {
      if (!defaultSearchParams[key]) {
        delete defaultSearchParams[key];
      }
    });

    if (searchParams.has("search_term")) {
      setSearchParams({
        ...defaultSearchParams,
        search_term: searchParams.get("search_term"),
      });
    } else {
      setSearchParams(defaultSearchParams);
    }
  };

  return (
    <div className="flex">
      {filterField && (
        <SearchBarFilterButton
          updateQueryParameters={updateQueryParameters}
          anyFilterApplied={anyFilterApplied}
          handleClearFilter={handleClearFilter}
          resetFilterForm={resetFilterForm}
          useFormProps={useFormProps}
          filterField={filterField}
        />
      )}

      <form
        onSubmit={skipSearchParams ? handleSubmit : handleSearch}
        className="flex-grow"
      >
        <div className="relative w-full">
          <input
            type="text"
            data-testid="search-input"
            id="search-dropdown"
            className={classNames(
              "block p-2 w-full z-20 text-sm pr-[72px] h-10 rounded-r-lg  border border-primary-main",
              filterField ? "rounded-l-none" : "rounded-l-lg"
            )}
            placeholder={searchBarPlaceholder}
            required
            title=""
            value={skipSearchParams ? searchTerm : searchQuery}
            onChange={skipSearchParams ? handleChange : handleOnChange}
          />
          {((skipSearchParams && searchTerm) || searchQuery) && (
            <div className="relative">
              <BiX
                className="absolute bottom-[8px] right-12 text-2xl text-gray-600 cursor-pointer"
                onClick={skipSearchParams ? handleClear : clearSearchBar}
              />
            </div>
          )}
          <button
            title="Search"
            type="submit"
            name="Search"
            className="absolute top-0 right-0 p-2 text-sm font-medium h-full text-white bg-primary-main rounded-r-lg border border-primary-main hover:bg-primary-dark"
          >
            <BiSearch className="w-6 h-6 cursor-pointer" />
          </button>
        </div>
      </form>
    </div>
  );
};

const filterFieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool.isRequired,
});

SearchBar.propTypes = {
  anyFilterApplied: PropTypes.func,
  skipSearchParams: PropTypes.bool,
  handleSubmit: PropTypes.func,
  searchTerm: PropTypes.string,
  handleChange: PropTypes.func,
  handleClear: PropTypes.func,
  resetFilterForm: PropTypes.func,
  useFormProps: PropTypes.shape({
    reset: PropTypes.func.isRequired,
  }),
  filterField: PropTypes.arrayOf(filterFieldShape),
  searchParams: PropTypes.instanceOf(URLSearchParams),
  setSearchParams: PropTypes.func,
  searchBarPlaceholder: PropTypes.string.isRequired,
  defaultSearchParams: PropTypes.shape({
    page: PropTypes.number,
    page_size: PropTypes.string,
    tab: PropTypes.string,
  }),
};

SearchBar.defaultProps = {
  defaultSearchParams: {
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
  },
  skipSearchParams: false,
  searchParams: new URLSearchParams(),
  setSearchParams: () => null,
};

export default SearchBar;
