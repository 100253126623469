import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";

import {
  updateJobCandidateStatus,
  updateStageCandidateStatus,
} from "api/erp/jobApi";
import {
  AA_CANDIDATE_STATUS,
  EXAM_CANDIDATE_STATUS,
  INTERVIEW_GD_CANDIDATE_STATUS,
} from "app/constants";
import { Modal } from "components/molecules";
import { requiredValidation } from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";
import { resetSelection } from "store/slices/miscellaneousSlice";

const UpdateStatusModal = ({
  setIsEditState,
  currentStage,
  getCandidateStageData,
  filterData,
}) => {
  const { selectedIds, allSelected } = useSelector(
    (state) => state.miscellaneous
  );
  const dispatch = useDispatch();

  const STAGE_CANDIDATE_STATUS =
    currentStage?.stage_type === "exam"
      ? EXAM_CANDIDATE_STATUS
      : INTERVIEW_GD_CANDIDATE_STATUS;

  const CANDIDATE_STATUS_LIST = currentStage
    ? STAGE_CANDIDATE_STATUS
    : AA_CANDIDATE_STATUS;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const showAlert = useAlert();
  const params = useParams();

  const submitHandler = ({ status }) => {
    let finalData = {};
    if (status === null) {
      return undefined;
    }

    if (allSelected) {
      finalData = {
        select_all: true,
        ...filterData,
        status: status.value,
      };
    } else {
      finalData = {
        candidate_job_ids: selectedIds,
        status: status.value,
      };
    }

    if (currentStage) {
      return updateStageCandidateStatus(params.uuid, currentStage.id, finalData)
        .then(() => {
          getCandidateStageData();

          if (selectedIds.length > 0 || allSelected) {
            dispatch(resetSelection());
          }

          showAlert("success", "Status Updated Successfully!");
          setIsEditState(false);
        })
        .catch((errors) =>
          showAlert("danger", errors.response.data.meta.message)
        );
    }
    return updateJobCandidateStatus(params.uuid, finalData)
      .then(() => {
        getCandidateStageData();

        if (selectedIds.length > 0 || allSelected) {
          dispatch(resetSelection());
        }

        showAlert("success", "Status Updated Successfully!");
        setIsEditState(false);
      })
      .catch((errors) =>
        showAlert("danger", errors.response.data.meta.message)
      );
  };

  return (
    <Modal
      title="Update status"
      onClose={() => setIsEditState(false)}
      acceptButtonText="Update"
      acceptBtnType={isDirty ? "primary" : "disabled"}
      handleAccept={handleSubmit((data) => submitHandler(data))}
      onOutsideClickHandler={() => setIsEditState(false)}
      handleCancel={() => setIsEditState(false)}
      scrollClass="overflow-y-auto h-[27vh]"
    >
      <form
        data-testid="Candidate-Status-Form"
        className="mt-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <div className="my-4 text-md">Please select a status:</div>
        <div className="mr-4 flex-grow" data-testid="bulkStatusSelect">
          <Controller
            name="status"
            control={control}
            type="submit"
            className="w-auto px-5"
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <Select
                name="status"
                options={CANDIDATE_STATUS_LIST}
                id="statusSelect"
                menuPlacement="auto"
                maxMenuHeight={120}
                placeholder="Select Status"
                className="mb-12"
                value={field.value}
                onChange={(data) => {
                  field.onChange(data);
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            )}
          />
        </div>

        {errors.status && (
          <p className="mt-2 text-sm text-danger-dark">
            {errors.status.message}
          </p>
        )}
      </form>
    </Modal>
  );
};

UpdateStatusModal.propTypes = {
  setIsEditState: PropTypes.func,
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
    type: PropTypes.string,
  }),
  getCandidateStageData: PropTypes.func,
  filterData: PropTypes.shape({}),
};

export default UpdateStatusModal;
