import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getGroupDiscussions, getInterviews } from "api/candidate/candidateApi";
import { getExams } from "api/candidate/examApi";
import { listJobs } from "api/candidate/jobApi";
import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import ActionAlert from "components/atoms/ActionAlert";
import { SITE_TITLE } from "constants/common";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import ExamCard from "./ExamCard";
import GroupDiscussionCard from "./GroupDiscussionCard";
import InterviewCard from "./InterviewCard";
import SuggestedJobs from "./SuggestedJobs";

const Dashboard = () => {
  const [currentUser] = useSelector(({ auth }) => [auth.currentUser]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateHeaderTitle({ breadCrumbDetails: { currentPageName: "Dashboard" } })
    );
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("isFullScreen");
  }, []);

  useEffect(() => {
    hideFreshWorkWidget();
    // dispatch(updateHeaderTitle({ title: "Dashboard" }));
  }, []);

  const [jobs, setJobs] = useState(null);
  const [ongoingExams, setOngoingExams] = useState(null);
  const [hasMoreOngoingExams, setHasMoreOngoingExams] = useState(false);
  const [ongoingInterviews, setOngoingInterviews] = useState(null);
  const [hasMoreOngoingInterviews, setHasMoreOngoingInterviews] =
    useState(false);
  const [ongoingGroupDiscussions, setOngoingGroupDiscussions] = useState(null);
  const [hasMoreOngoingGroupDiscussions, setHasMoreOngoingGroupDiscussions] =
    useState(false);
  const [upcomingExams, setUpcomingExams] = useState([]);
  const [hasMoreUpcomingExams, setHasMoreUpcomingExams] = useState(false);
  const [upcomingInterviews, setUpcomingInterviews] = useState([]);
  const [hasMoreUpcomingInterviews, setHasMoreUpcomingInterviews] =
    useState(false);
  const [upcomingGroupDiscussions, setUpcomingGroupDiscussions] = useState([]);
  const [hasMoreUpcomingGroupDiscussions, setHasMoreUpcomingGroupDiscussions] =
    useState(false);

  const fetchJobs = async () => {
    const response = await listJobs();
    setJobs(response.data);
  };

  const fetchGroupDiscussions = () => {
    getGroupDiscussions({ page: 1, status: "ongoing", days: 30 }).then(
      (response) => {
        const { data, meta } = response;
        setOngoingGroupDiscussions(data);
        setHasMoreOngoingGroupDiscussions(meta?.total_pages > 1);
      }
    );

    getGroupDiscussions({ page: 1, status: "upcoming" }).then((response) => {
      const { data, meta } = response;
      setUpcomingGroupDiscussions(data);
      setHasMoreUpcomingGroupDiscussions(meta?.total_pages > 1);
    });
  };

  const fetchInterviews = () => {
    getInterviews({ page: 1, status: "ongoing", days: 30 }).then((response) => {
      const { data, meta } = response;
      setOngoingInterviews(data);
      setHasMoreOngoingInterviews(meta?.total_pages > 1);
    });

    getInterviews({ page: 1, status: "upcoming" }).then((response) => {
      const { data, meta } = response;
      setUpcomingInterviews(data);
      setHasMoreUpcomingInterviews(meta?.total_pages > 1);
    });
  };

  const fetchExams = () => {
    getExams({ page: 1, status: "ongoing", days: 30 }).then((response) => {
      const { data, meta } = response;
      const exam = data?.filter(
        (item) => item.active === true && item.has_ended === false
      );
      setOngoingExams(exam);
      setHasMoreOngoingExams(meta.total_pages > 1);
    });
    getExams({ page: 1, status: "upcoming", days: 30 }).then((response) => {
      const { data, meta } = response;
      const exam = data?.filter((item) => item.has_ended === false);
      setUpcomingExams(exam);
      setHasMoreUpcomingExams(meta.total_pages > 1);
    });
  };

  useEffect(() => {
    fetchExams();
    fetchInterviews();
    fetchGroupDiscussions();
    fetchJobs();
  }, []);

  let shouldDisplayAlert;

  if (
    !currentUser ||
    !currentUser?.data ||
    !currentUser?.data.is_profile_completed
  ) {
    shouldDisplayAlert = true;
  } else if (
    currentUser?.data?.reference_images !== undefined &&
    !currentUser?.data?.reference_images[0] &&
    currentUser.meta !== null
  ) {
    shouldDisplayAlert = true;
  } else {
    shouldDisplayAlert = false;
  }

  return (
    <div className="px-4 py-4 md:px-6">
      <Helmet>
        <title>{`Dashboard - ${SITE_TITLE}`}</title>
        <meta property="og:url" content="https://app.joballey.in/" />
      </Helmet>
      {shouldDisplayAlert && (
        <ActionAlert
          message={
            <p className="xs:pr-0 pr-4">{currentUser?.meta?.action_message}</p>
          }
          alertType="warning"
          iconName="warning"
          isButtonRequire
          roundedBorder
          btnName="View profile"
          buttonTitle="View Profile"
          customClassName="mb-2"
          handleButtonClick={() => navigate(`/profile`)}
        />
      )}

      {jobs?.length === 0 &&
      ongoingInterviews?.length === 0 &&
      upcomingInterviews?.length === 0 &&
      ongoingExams?.length === 0 &&
      upcomingExams?.length === 0 &&
      ongoingGroupDiscussions?.length === 0 &&
      upcomingGroupDiscussions?.length === 0 ? (
        <div className="flex flex-col items-center justify-center">
          <img src={NoDataFound} alt="No Data Found" />
          <p>
            Sorry!!! You do not have any suggested jobs and ongoing/upcoming
            exams for now
          </p>
        </div>
      ) : (
        <div className="lg:grid lg:grid-cols-3 lg:gap-4">
          <div
            className={` ${
              upcomingInterviews?.length !== 0 ||
              upcomingExams?.length !== 0 ||
              upcomingGroupDiscussions?.length !== 0
                ? "border-solid  border-gray-lighterShade2 lg:col-span-2 lg:border-t-0  lg:border-r-2 lg:pr-4"
                : "lg:col-span-3 "
            }`}
          >
            <div
              className={`${
                (ongoingInterviews?.length !== 0 &&
                  ongoingExams?.length !== 0) ||
                (ongoingGroupDiscussions?.length !== 0 &&
                  ongoingExams?.length !== 0) ||
                (ongoingGroupDiscussions?.length !== 0 &&
                  ongoingInterviews?.length !== 0 &&
                  "flex flex-col justify-between  md:flex-row md:gap-3")
              }`}
            >
              {ongoingExams?.length !== 0 && (
                <div
                  className={` ${
                    ongoingInterviews?.length === 0 &&
                    ongoingGroupDiscussions?.length === 0 &&
                    (upcomingInterviews?.length !== 0 ||
                      upcomingGroupDiscussions?.length !== 0 ||
                      upcomingExams?.length !== 0)
                      ? "md:w-full"
                      : "md:w-[49%]"
                  }  mb-6`}
                >
                  <ExamCard
                    status="ongoing"
                    exams={ongoingExams}
                    hasMoreExams={hasMoreOngoingExams}
                    currentUser={currentUser.data}
                  />
                </div>
              )}
              {ongoingInterviews?.length !== 0 && (
                <div
                  className={` ${
                    ongoingExams?.length === 0 &&
                    ongoingGroupDiscussions?.length === 0 &&
                    (upcomingInterviews?.length !== 0 ||
                      upcomingExams?.length !== 0 ||
                      upcomingGroupDiscussions?.length !== 0)
                      ? "md:w-full"
                      : "md:w-[49%]"
                  }  mb-6`}
                >
                  <InterviewCard
                    status="ongoing"
                    interviews={ongoingInterviews}
                    hasMoreInterviews={hasMoreOngoingInterviews}
                  />
                </div>
              )}
              {ongoingGroupDiscussions?.length !== 0 && (
                <div
                  className={` ${
                    ongoingExams?.length === 0 &&
                    ongoingInterviews?.length === 0 &&
                    (upcomingGroupDiscussions?.length !== 0 ||
                      upcomingExams?.length !== 0 ||
                      upcomingInterviews?.length !== 0)
                      ? "md:w-full"
                      : "md:w-[49%]"
                  }  mb-6`}
                >
                  <GroupDiscussionCard
                    status="ongoing"
                    groupDiscussions={ongoingGroupDiscussions}
                    hasMoreGroupDiscussions={hasMoreOngoingGroupDiscussions}
                  />
                </div>
              )}
            </div>
            <div
              className={`${
                upcomingInterviews?.length === 0 &&
                upcomingExams?.length === 0 &&
                upcomingGroupDiscussions?.length === 0 &&
                jobs?.length === 1
                  ? "md:w-[49%]"
                  : ""
              }`}
            >
              <SuggestedJobs job={jobs} />
            </div>
          </div>
          <div
            className={` ${
              ongoingInterviews?.length === 0 &&
              ongoingExams?.length === 0 &&
              ongoingGroupDiscussions?.length === 0 &&
              jobs?.length === 0
                ? "md: col-span-3 md:max-w-[49%]"
                : "lg:col-span-1"
            }`}
          >
            {upcomingExams?.length !== 0 && (
              <div className="mb-6 rounded-2xl border bg-white p-4 pt-2">
                <ExamCard
                  status="upcoming"
                  exams={upcomingExams}
                  hasMoreExams={hasMoreUpcomingExams}
                  currentUser={currentUser.data}
                />
              </div>
            )}
            {upcomingInterviews?.length !== 0 && (
              <div className="rounded-2xl border bg-white p-4 pt-2 mb-6">
                <InterviewCard
                  status="upcoming"
                  interviews={upcomingInterviews}
                  hasMoreInterviews={hasMoreUpcomingInterviews}
                />
              </div>
            )}
            {upcomingGroupDiscussions?.length !== 0 && (
              <div className="rounded-2xl border bg-white p-4 pt-2">
                <GroupDiscussionCard
                  status="upcoming"
                  groupDiscussions={upcomingGroupDiscussions}
                  hasMoreGroupDiscussions={hasMoreUpcomingGroupDiscussions}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
