import { createSlice } from "@reduxjs/toolkit";

import {
  createReferenceDetailsAction,
  deleteReferenceDetailsAction,
  getReferenceDetailsAction,
  updateReferenceDetailsAction,
} from "store/thunkActions/candidate/referenceDetailsThunk";

export const getReferenceDetails = createSlice({
  name: "referenceDetails",
  initialState: {
    referenceDetails: [],
  },
  reducers: {},
  extraReducers: {
    [getReferenceDetailsAction.fulfilled]: (state, action) => {
      state.referenceDetails = action.payload;
    },
    [createReferenceDetailsAction.fulfilled]: (state, action) => {
      state.referenceDetails.push(action.payload.data);
    },
    [updateReferenceDetailsAction.fulfilled]: (state, action) => {
      const index = state.referenceDetails.findIndex(
        (referenceDetail) => referenceDetail.uuid == action.payload.data.uuid
      );
      state.referenceDetails[index] = action.payload.data;
    },
    [deleteReferenceDetailsAction.fulfilled]: (state, action) => {
      const index = state.referenceDetails.findIndex(
        (referenceDetail) => referenceDetail.uuid == action.payload.data.uuid
      );
      state.referenceDetails.splice(index, 1);
    },
  },
});

export default getReferenceDetails.reducer;
