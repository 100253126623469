const codeEditorText = (question) =>
  !question.coding_answer.coding_language_id
    ? question.driver_codes[0]?.code_string
    : question.coding_answer.code_string ??
      question.driver_codes.find(
        (l) =>
          l.coding_language_id === question.coding_answer.coding_language_id
      ).code_string;

export default codeEditorText;
