import { useEffect, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  finishQuestion,
  getNextQuestion,
  updateCandidateAnswers,
} from "api/candidate/candidateApi";
import { internalErrorMessage } from "helpers/genericErrorMessages";

import Question from "./Question";
import Sidebar from "./Sidebar";

const errorMessageClass = classNames(
  "border opacity-95 border-primary-light rounded-full text-primary-main p-2 w-auto mx-2 xs:mx-8 md:mx-24 lg:mx-32 mt-16"
);

const QuestionPaper = ({
  examName,
  duration,
  examCandidateId,
  candidateJobId,
  totalQuestions,
  examType,
  onFinish,
  setIsFinishModalVisible,
  isDropDownOpen,
  setIsDropDownOpen,
  videoRef,
  cameraOption,
  intervalIdRef,
  examViolation,
  setSectionName,
  setAlertMessage,
  setIsAlertTriggered,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [customInputChecked, setCustomInputChecked] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [question, setQuestion] = useState();
  const [candidateAnswerId, setCandidateAnswerId] = useState(null);
  const [isInternalExamError, setIsInternalExamError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const fetchNextQuestion = async () => {
    if (candidateAnswerId) {
      return finishQuestion(candidateAnswerId).then(() => {
        if (totalQuestions !== question.question_index + 1) {
          getNextQuestion(examCandidateId)
            .then((response) => {
              setQuestion(response.data);
              setSectionName(response.data.section?.name);
              setCandidateAnswerId(response.data.candidate_answer_id);
            })
            .catch((err) => {
              if (
                err?.response?.status === 422 ||
                err?.response?.status === 404
              ) {
                onFinish();
              } else {
                setIsInternalExamError(true);
              }
            });
        } else {
          onFinish();
        }
      });
    }

    return getNextQuestion(examCandidateId)
      .then((response) => {
        setQuestion(response.data);
        setSectionName(response.data.section?.name);
        setCandidateAnswerId(response.data.candidate_answer_id);
        setIsInternalExamError(false);
      })
      .catch((err) => {
        if (err.response?.status === 422 || err.response?.status === 404) {
          onFinish();
        } else {
          setIsInternalExamError(true);
        }
      });
  };

  const onResetButtonClick = () => {
    setSelectedOption(null);
    return updateCandidateAnswers(candidateAnswerId);
  };

  const onOptionChange = ({ target }) => {
    setSelectedOption(Number(target.value));
    updateCandidateAnswers(candidateAnswerId, target.value);
  };

  const handleNextQuestionRender = () => {
    setIsSaving(true);
    localStorage.removeItem("codeLanguage");
    return fetchNextQuestion();
  };

  useEffect(() => {
    fetchNextQuestion();
  }, []);

  return (
    <div>
      {!isInternalExamError ? (
        <div className="flex flex-row justify-between">
          {question && (
            <Question
              question={question}
              fetchNextQuestion={handleNextQuestionRender}
              currentQuestionIndex={question.question_index + 1}
              onNextButtonClick={handleNextQuestionRender}
              setIsSaving={setIsSaving}
              isSaving={isSaving}
              onResetButtonClick={onResetButtonClick}
              selectedOption={selectedOption}
              onOptionChange={onOptionChange}
              totalQuestions={totalQuestions}
              isCodingQuestion={examType}
              customInputChecked={customInputChecked}
              customInput={customInput}
              setCustomInputChecked={setCustomInputChecked}
              setCustomInput={setCustomInput}
              sectionName={question?.section && question.section.name}
            />
          )}

          <Sidebar
            examName={examName}
            duration={duration}
            onFinish={onFinish}
            candidateJobId={candidateJobId}
            candidateName={currentUser.data.name}
            sectionName={question?.section && question.section.name}
            setIsFinishModalVisible={setIsFinishModalVisible}
            isDropDownOpen={isDropDownOpen}
            setIsDropDownOpen={setIsDropDownOpen}
            videoRef={videoRef}
            cameraOption={cameraOption}
            intervalIdRef={intervalIdRef}
            examCandidateId={examCandidateId}
            currentUserRefImageLink={
              currentUser.data.reference_images[0]?.attachment
            }
            examViolation={examViolation}
            setAlertMessage={setAlertMessage}
            setIsAlertTriggered={setIsAlertTriggered}
          />
        </div>
      ) : (
        <div className={errorMessageClass}>
          <div className="flex items-center justify-center gap-4 pl-2">
            <BsExclamationCircleFill
              size={88}
              className="h-44 w-44 sm:h-32 sm:w-32 lg:h-auto lg:w-auto"
            />
            <div className="my-2 mr-2 p-2 text-base leading-7">
              {internalErrorMessage()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

QuestionPaper.propTypes = {
  examName: PropTypes.string,
  duration: PropTypes.number,
  examCandidateId: PropTypes.number,
  onFinish: PropTypes.func,
  candidateJobId: PropTypes.number,
  totalQuestions: PropTypes.number,
  examType: PropTypes.bool,
  setIsFinishModalVisible: PropTypes.func,
  setSectionName: PropTypes.func,
  isDropDownOpen: PropTypes.bool,
  setIsDropDownOpen: PropTypes.func,
  videoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  intervalIdRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    }),
  ]),
  cameraOption: PropTypes.string,
  examViolation: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setIsAlertTriggered: PropTypes.func,
};

QuestionPaper.defaultProps = {
  examName: "Exam",
  duration: 0,
  examCandidateId: 0,
  onFinish: () => {},
  candidateJobId: 0,
  totalQuestions: 0,
  examType: false,
  setIsFinishModalVisible: false,
  setSectionName: () => {},
  isDropDownOpen: false,
  setIsDropDownOpen: () => {},
  videoRef: () => {},
  intervalIdRef: () => {},
  cameraOption: "NOT_REQUIRED",
  examViolation: () => {},
  setAlertMessage: () => {},
  setIsAlertTriggered: () => {},
};

export default QuestionPaper;
