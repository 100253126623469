const cameraPermissionStatus = (setCaptureModal, showAlert) => {
  navigator?.permissions
    ?.query({ name: "camera" })
    .then((res) => {
      if (res.state === "denied") {
        showAlert(
          "danger",
          "Please enable your camera permission to upload photo"
        );
      } else {
        setCaptureModal(true);
      }
    })
    .catch(() => {
      showAlert("danger", "Camera permission denied.");
    });
};
export default cameraPermissionStatus;
