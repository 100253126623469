import React, { useEffect, useRef, useState } from "react";
import { BsXLg } from "react-icons/bs";
import classNames from "classnames";
import PropTypes from "prop-types";

const SidePanel = ({
  children,
  onClosePanel,
  isOpen,
  customClassName,
  cancellable,
}) => {
  const OPEN_TRANSITION_CLASS =
    "transform translate-x-0 transition-transform duration-500 ease-in-out";
  const CLOSE_TRANSITION_CLASS =
    "transform translate-x-full transition-transform duration-400 ease-in-out";

  const sidePanelContainerRef = useRef(null);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape" && isOpen) {
        event.preventDefault();
        onClosePanel();
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen]);

  return (
    <div
      ref={sidePanelContainerRef}
      className={classNames(
        customClassName,
        "sm:border-l-2 sm:border-t-2 border-gray-lighter hover:border-gray-light w-full sm:w-[30%] sm:min-w-[350px] h-[100%] opacity-100 fixed top-0 right-0 z-50 bg-gray-lighterShade3 overflow-y-auto",
        isRendered && isOpen
          ? `${OPEN_TRANSITION_CLASS}`
          : `${CLOSE_TRANSITION_CLASS}`
      )}
    >
      {cancellable && (
        <div className="absolute top-14 mx-3 right-4">
          <BsXLg
            data-testid="close-panel-btn"
            role="button"
            className="ml-auto mr-2"
            onClick={onClosePanel}
          />
        </div>
      )}
      {children}
    </div>
  );
};

SidePanel.defaultProps = {
  children: null,
  onClosePanel: null,
  isOpen: false,
  cancellable: true,
  customClassName: "",
};

SidePanel.propTypes = {
  children: PropTypes.node,
  onClosePanel: PropTypes.func,
  isOpen: PropTypes.bool,
  cancellable: PropTypes.bool,
  customClassName: PropTypes.string,
};

export default SidePanel;
