import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select from "react-select";
import classNames from "classnames";
import PropTypes from "prop-types";

import { getCategories } from "api/erp/questionPaperApi";
import { ALERT_TYPE, DURATION_TYPE } from "app/constants";
import { LabeledInput } from "components/atoms";
import Modal from "components/molecules/Modal";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";
import {
  createCategoryAction,
  getCategoryAction,
  updateCategoryAction,
} from "store/thunkActions/erp/categoriesThunk";

const CategoriesModal = ({ category, setIsEditState }) => {
  const {
    watch,
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
  } = useForm();

  const showAlert = useAlert();
  const dispatch = useDispatch();
  const [list, setList] = useState();

  const setErrorsOnFields = ({ errors }) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    if (data.parent_category_id) {
      data.parent_category_id = data?.parent_category_id?.id;
    }

    if (data.duration_type.name === "Min") {
      data.default_duration *= 60;
    }

    const action = category
      ? updateCategoryAction({
          ...data,
          name: data.name.trim(),
          parent_category_id: data?.parent_category_id,
          default_duration: data?.default_duration,
        })
      : createCategoryAction({
          ...data,
          name: data.name.trim(),
          parent_category_id: data?.parent_category_id,
          default_duration: data?.default_duration,
        });
    return dispatch(action)
      .unwrap()
      .then(({ meta }) => {
        setIsEditState(false);
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        dispatch(getCategoryAction());
      })
      .catch((errors) => {
        if (errors) {
          setErrorsOnFields(errors);
        }
      });
  };

  const inputErrClass = (error) => {
    return error ? "input-error" : "input";
  };

  useEffect(() => {
    getCategories().then((response) => {
      setList(response.data);
    });
  }, []);

  useEffect(() => {
    if (category) {
      reset({
        ...category,
        ...(category?.parent_category
          ? { parent_category_id: { name: category?.parent_category } }
          : {}),
        default_duration:
          category?.default_duration % 60 === 0 &&
          category?.default_duration > 0
            ? category?.default_duration / 60
            : category?.default_duration,
      });
    }
  }, [category]);

  return (
    <Modal
      title={category ? "Edit Category" : "Add Category"}
      testId="add-edit-category-modal"
      acceptBtnType={!watch("name") ? "disabled" : "primary"}
      acceptButtonText={category ? "Update" : "Add"}
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setIsEditState(false)}
      isDirty={isDirty}
    >
      <form
        className={classNames("m-1 ", !category && "min-h-[120px]")}
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <LabeledInput
          id="categories"
          labelClassNames={classNames(
            "block font-medium mt-1 mb-1 text-md required",
            {
              "text-danger-main": errors.name,
            }
          )}
          label="Category"
          name="name"
          inputClassNames={classNames("w-full", inputErrClass(errors.name))}
          placeholder="Please enter a category name"
          register={register}
          type="text"
          validation={{
            maxLength: {
              value: 50,
              message: characterValidation({ limit: 50 }),
            },
            required: requiredValidation(),
          }}
          errorMessage={errors.name?.message}
          autoComplete="off"
        />

        <div className=" flex flex-col md:flex-row md:gap-1">
          <div className="w-full md:w-3/4">
            <LabeledInput
              id="duration"
              labelClassNames={classNames(
                "block font-medium text-md mt-4 mb-1",
                {
                  "text-danger-main": errors.default_duration,
                }
              )}
              label="Default Duration"
              name="default_duration"
              inputClassNames={classNames(
                "w-full",
                inputErrClass(errors.default_duration)
              )}
              placeholder="Select default duration for questions"
              register={register}
              type="number"
              validation={{
                min: {
                  value: 0,
                  message: "Can't be negative",
                },
                maxLength: {
                  value: 10,
                  message: characterValidation({ limit: 10 }),
                },
              }}
              errorMessage={errors.default_duration?.message}
              autoComplete="off"
            />
          </div>

          <div className="w-full md:w-1/4">
            <Controller
              name="duration_type"
              control={control}
              defaultValue={
                category?.default_duration % 60 === 0 &&
                category?.default_duration > 0
                  ? DURATION_TYPE[1]
                  : DURATION_TYPE[0]
              }
              rules={{ required: requiredValidation() }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={DURATION_TYPE}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  menuPlacement="auto"
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      height: 41,
                    }),
                  }}
                  isClearable={false}
                  className="mt-2 mb-1 md:mt-11"
                />
              )}
            />
          </div>
        </div>

        <label
          htmlFor="parent-category-id"
          className={`mt-2 mb-1 block text-md font-medium ${
            errors.parent_category_id && "text-danger-main"
          }`}
        >
          Parent Category
        </label>
        <Controller
          name="parent_category_id"
          control={control}
          render={({ field }) => (
            <Select
              name="parent_category_id"
              placeholder="Select a parent category"
              options={list?.filter((l) => l.id != category?.id)}
              id="parent-category-id"
              maxMenuHeight={115}
              menuPlacement="auto"
              className="mb-12 w-full"
              value={field.value}
              onChange={(data) => {
                field.onChange(data);
              }}
              isClearable
              isSearchable
              getOptionLabel={(option) => option.name || option.parent_category}
              getOptionValue={(option) =>
                option.id || option.name || option.parent_category
              }
            />
          )}
        />
      </form>
    </Modal>
  );
};

CategoriesModal.propTypes = {
  setIsEditState: PropTypes.func,
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    default_duration: PropTypes.number,
    parent_category_id: PropTypes.number,
    parent_category: PropTypes.string,
  }),
};

export default CategoriesModal;
