import { candidateApi, examApi } from "api/axios";

// old exams api
export async function getExamCandidateExam() {
  const response = await examApi.get("/exam_candidates/exam");
  return response;
}

export async function getNextQuestion() {
  const response = await examApi.get("/next_question");
  return response;
}

export async function finishQuestion(candidateAnswerId) {
  const response = await examApi.patch(
    `/finish_question?candidate_answer_id=${candidateAnswerId}`
  );
  return response;
}

export async function finishExam() {
  const response = await examApi.patch("/exam_candidates/finish_exam");
  return response;
}

export async function fetchCandidateAnswers() {
  const response = await examApi.get(`/candidate_answers`);
  return response;
}

export async function updateCandidateAnswers(questionId, answerId) {
  const response = await examApi.patch(
    `/candidate_answers/${questionId}?answer_id=${answerId || ""}`
  );
  return response;
}

export async function saveExamViolation(data) {
  const response = await candidateApi.post("/exam_violations", data);
  return response;
}

export async function updateCodingAnswer(data) {
  const response = await candidateApi.post("/coding_answers", data);
  return response;
}

export async function evaluateCodingAnswer(data) {
  const response = await candidateApi.post("/evaluate_code", data);
  return response;
}

// new exams api
export async function getExams(params) {
  const response = await candidateApi.get("/exams", {
    params,
  });
  return response;
}

export async function checkExamAppearance(id) {
  const response = await candidateApi.get(
    `/exam_candidates/check_appearance?exam_id=${id}`
  );
  return response;
}

export async function createExamCandidate(id) {
  const response = await candidateApi.post(`/exam_candidates?exam_id=${id}`);
  return response;
}

export async function showExamCandidate(id) {
  const response = await candidateApi.get(`/exam_candidates/${id}`);
  return response;
}

// Finish Exam
export async function finishExamCandidate(id) {
  const response = await candidateApi.patch(`/exam_candidates/${id}`);
  return response;
}

// Post Screenshot using presigned URLs
export async function getPresignedURL(id) {
  const response = await candidateApi.get("/presigned_url", {
    params: { id },
  });
  return response;
}

// Get mock tests

export async function getMockExams() {
  const response = await candidateApi.get("/mock_tests");
  return response;
}

// Get mock test answers
export const getMockTestAnswers = async (examId) => {
  const response = await candidateApi.get("/mock_test_candidate_answers", {
    params: {
      exam_id: examId,
    },
  });
  return response;
};
