import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import PropTypes from "prop-types";

import CopyLink from "components/common/CopyLink";
import StatusPill from "components/common/StatusPill";

const GroupDiscussion = ({ groupDiscussion, status }) => {
  return (
    <div
      className="min-h-[150px] py-3"
      data-testid={`groupDiscussion-id-${groupDiscussion.id}`}
    >
      <div className="flex items-start justify-between gap-1">
        <span
          className="block pr-1 text-base font-bold text-black-shade1"
          id="title"
        >
          {groupDiscussion.title}
        </span>
        {status !== "past" && status !== "feedback_pending" && (
          <CopyLink
            link={groupDiscussion.link || groupDiscussion.address}
            tooltipId="groupDiscussionLink"
            tooltipContent={`Copy Group Discussion ${
              groupDiscussion.address ? "Address" : "Link"
            }`}
            particular={groupDiscussion.address ? "Address" : "Link"}
          />
        )}
      </div>
      <div className="flex justify-between  font-sans">
        <div className="mr-2 w-3/5 text-gray-lightShade1">
          <div className=" mt-2 mb-2 text-[15px] font-medium">
            <Link
              title="View Job Details"
              className="cursor-pointer hover:underline"
              to={`/jobs/${groupDiscussion.job_uuid}/details`}
            >
              {groupDiscussion.job_title}
              <br />
              <p className="inline">
                {groupDiscussion.organisation_name}&nbsp;
                {groupDiscussion.job_location ? "|" : ""}{" "}
                {groupDiscussion.job_location}
              </p>
            </Link>
          </div>

          <div className="text-[13px] ">
            <p className=" text-black-primary">
              {format(new Date(groupDiscussion.start_at), "dd/MM/yyyy")}
            </p>

            <p className="whitespace-nowrap">
              Duration:{" "}
              {groupDiscussion.duration && `${groupDiscussion.duration} mins`}
            </p>
          </div>
        </div>

        {status === "ongoing" && groupDiscussion.mode === "VIRTUAL" && (
          <a
            title="Join Group Discussion"
            href={groupDiscussion.link}
            target="_blank"
            rel="noreferrer"
            className="btn-success block w-28 self-end rounded-full text-center"
            id="groupDiscussion_link"
          >
            Join
          </a>
        )}
        {status === "upcoming" && (
          <div className="self-end text-primary-main text-[14px]">
            {formatDistanceToNow(new Date(groupDiscussion.start_at), {
              addSuffix: true,
            })}
          </div>
        )}
        {status === "past" && groupDiscussion?.candidate_status !== null && (
          <StatusPill label={groupDiscussion?.candidate_status} />
        )}
      </div>
    </div>
  );
};

GroupDiscussion.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming"]),
  groupDiscussion: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    groupDiscussion_type: PropTypes.oneOf(["coding", "mcq"]),
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    duration: PropTypes.number,
    job_uuid: PropTypes.string,
    job_title: PropTypes.string,
    organisation_name: PropTypes.string,
    job_location: PropTypes.string,
    has_started: PropTypes.bool,
    address: PropTypes.string,
    mode: PropTypes.string,
    link: PropTypes.string,
    job_page_number: PropTypes.number,
    candidate_status: PropTypes.string,
  }),
};

export default GroupDiscussion;
