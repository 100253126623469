import axios from "axios";

export const setupFRFiles = async () => {
  if (process.env.REACT_APP_FACE_DETECTION_API) {
    return axios
      .post(
        process.env.REACT_APP_FACE_DETECTION_API,
        JSON.stringify({ task: "SETUP" }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data)
      .catch(() => {
        return { success: false };
      });
  }

  return { success: false };
};

export const detectRefImage = async (base64Image, onFailure) => {
  if (process.env.REACT_APP_FACE_DETECTION_API) {
    return axios
      .post(
        process.env.REACT_APP_FACE_DETECTION_API,
        JSON.stringify({ image: base64Image, task: "REF_IMAGE" }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data.response)
      .catch(() => {
        onFailure();
      });
  }

  return undefined;
};

export const detectCandidateExam = async (base64Image, faceDescriptors) => {
  if (process.env.REACT_APP_FACE_DETECTION_API) {
    return axios
      .post(
        process.env.REACT_APP_FACE_DETECTION_API,
        JSON.stringify({
          image: base64Image,
          task: "EXAM_RECOGNIZE",
          face_embedding: faceDescriptors,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data.response)
      .catch(() => {
        return undefined;
      });
  }

  return undefined;
};
