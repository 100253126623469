import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createProject,
  deleteProject,
  getProjects,
  updateProject,
} from "api/candidate/profileApi";

export const getProjectsAction = createAsyncThunk("project/get", async () => {
  const response = await getProjects();
  return response.data;
});

export const createProjectAction = createAsyncThunk(
  "project/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createProject(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProjectAction = createAsyncThunk(
  "project/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateProject(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProjectAction = createAsyncThunk(
  "project/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteProject(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
