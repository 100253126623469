import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { upperCase } from "lodash";

import { createExamCandidate, getMockExams } from "api/candidate/examApi";
import { QUESTION_PAPER_TYPE } from "app/constants";
import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import { ActivityIndicator, Button } from "components/atoms";
import Pagination from "components/common/Pagination";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import {
  convertSecondsToTime,
  DEFAULT_PAGE_SIZE,
  PER_PAGE_RECORD_LIMIT,
  SITE_TITLE,
} from "constants/common";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

const MockTest = () => {
  const dispatch = useDispatch();
  const useFormProps = useForm();
  const { reset } = useFormProps;
  const [searchParams, setSearchParams] = useSearchParams();

  const [mockTests, setMockTests] = useState({ data: [], meta: {} });
  const [isMockTestLoading, setIsMockTestLoading] = useState(false);
  const [updatedMockTestList, setUpdatedMockTestList] = useState();

  const pageSize =
    parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE;

  const filterField = [
    {
      label: "Mock Test Type",
      placeholder: "Select type",
      name: "question_paper_type",
      multiSelect: false,
      dropdownOptions: QUESTION_PAPER_TYPE,
    },
  ];

  useEffect(() => {
    hideFreshWorkWidget();
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: { currentPageName: "Mock Tests" },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setIsMockTestLoading(true);
    getMockExams().then(({ data, meta }) => {
      setMockTests({ data, meta });
      setIsMockTestLoading(false);
    });
  }, []);

  const updateMockTests = useCallback(() => {
    let mcqCount = 0;
    let codingCount = 0;

    const updatedTests = mockTests.data.map((mockTest) => {
      if (mockTest.mock_test_type === "mcq") {
        mcqCount += 1;
        return { ...mockTest, mock_test_type: `mcq${mcqCount}` };
      }
      if (mockTest.mock_test_type === "coding") {
        codingCount += 1;
        return { ...mockTest, mock_test_type: `coding${codingCount}` };
      }
      return mockTest;
    });

    setUpdatedMockTestList(updatedTests);
  }, [mockTests.data]);

  useEffect(() => {
    updateMockTests();
  }, [updateMockTests]);

  const handleStartExam = (exam) => {
    // We are removing check appearance api as user can appear multiple times,
    // so calling this api creating lot of confusing data in started_at and ended_at
    localStorage.setItem("exam", JSON.stringify(exam));
    window.open(`/instructions?id=${exam.id}`, "_blank", "noopener");
  };

  const handleResumeExam = (exam) => {
    createExamCandidate(exam.id).then((response) => {
      if (exam.mock_test_type) {
        window.open(
          `/question_paper?id=${response.data.id}&&mock_test=true`,
          "_blank"
        );
      } else {
        window.open(`/question_paper?id=${response.data.id}`, "_blank");
      }
    });
  };

  const anyFilterApplied = () => searchParams.has("question_paper_type");

  const resetFilterForm = () => {
    reset({
      status: searchParams.get("question_paper_type")
        ? QUESTION_PAPER_TYPE.find(
            (type) => type.value === searchParams.get("question_paper_type")
          )
        : undefined,
    });
  };

  const generateFilterCriterias = () => {
    const filterCriterias = [];

    if (searchParams.has("question_paper_type")) {
      filterCriterias.push("Question Paper Type");
    }

    return filterCriterias.join(" | ");
  };
  const urlQueryParams = {
    question_paper_type: searchParams.get("question_paper_type"),
    search_term: searchParams.get("search_term"),
  };

  const newUrlSearchParams = (pageNumber, pageSizeValue = pageSize) => {
    const newSearchParams = {
      page: pageNumber,
      page_size: pageSizeValue,
    };

    if (urlQueryParams.question_paper_type !== null) {
      newSearchParams.question_paper_type = urlQueryParams.question_paper_type;
    }
    if (urlQueryParams.search_term !== null) {
      newSearchParams.search_term = urlQueryParams.search_term;
    }

    return newSearchParams;
  };

  const onSetCurrentPage = (pageNumber, pageSizeValue = pageSize) => {
    const newSearchParams = newUrlSearchParams(pageNumber, pageSizeValue);
    setSearchParams(newSearchParams);
    window.scrollTo({ top: 0, left: 0 });
  };

  const getViewAnswerStatus = (endedAt) => {
    const targetDate = new Date(endedAt);
    const currentTime = new Date();
    const timeDifference = currentTime - targetDate;
    const timeDifferenceInSeconds = timeDifference / 1000;
    // Check if the difference is greater than 60 seconds
    if (timeDifferenceInSeconds > 60) {
      return true;
    }
    return false;
  };

  if (isMockTestLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Mock Test - ${SITE_TITLE}`}</title>
      </Helmet>
      <div className="flex gap-4 pl-3.5 lg:pl-5 pr-4 lg:pr-3 justify-between items-start py-4 bg-gray-lighterShade3 z-10 sticky top-16">
        <div>
          <div className="sm:max-w-[450px]">
            <SearchBar
              anyFilterApplied={anyFilterApplied}
              searchParams={searchParams}
              resetFilterForm={resetFilterForm}
              useFormProps={useFormProps}
              setSearchParams={setSearchParams}
              filterField={filterField}
              searchBarPlaceholder="Search by Question Name"
            />
          </div>
          {anyFilterApplied() && (
            <p className="my-3 text-sm text-primary-main align-top">
              <span className="mr-1"> Filtered by: </span>

              <span>{generateFilterCriterias()}</span>
            </p>
          )}
        </div>
      </div>
      {updatedMockTestList && updatedMockTestList.length > 0 ? (
        <div className="overflow-x-auto mx-2 sm:mx-6">
          <table className="my-8 min-w-[736px] md:w-full table-auto border-collapse text-sm">
            <thead>
              <tr className="border-b-2 text-lg capitalize text-primary-main">
                <th className="w-2/5 py-4 pl-4 text-left">Name</th>
                <th className="w-1/5 py-4 pl-4 text-center">Duration</th>
                <th className="w-1/5 py-4 pl-4 text-left">Answers</th>
                <th className="w-1/5 py-4 pr-4 text-left" />
              </tr>
            </thead>

            <tbody className="bg-white">
              {updatedMockTestList.map((mockTest) => (
                <tr className="border-b-2 items-center" key={mockTest.id}>
                  <td className="w-2/5 break-all py-4 pl-4 pr-8 text-left">
                    {`${upperCase(mockTest.mock_test_type)}`}
                  </td>

                  <td className="break-all w-1/5 w py-4 pl-6 pr-4 text-center">
                    {convertSecondsToTime(mockTest.duration)}
                  </td>
                  <td className="w-1/5 py-4 px-4 text-left">
                    {mockTest.ended_at &&
                      getViewAnswerStatus(mockTest.ended_at) && (
                        <Link
                          title="View My Score"
                          btnName="View My Score"
                          to={`/mock-tests/${mockTest.id}/answers`}
                          target="_blank"
                          className="hover:underline text-primary-main"
                        >
                          View My Score
                        </Link>
                      )}
                  </td>

                  <td className="w-1/5 py-4 px-4 items-center">
                    {mockTest.started_at && mockTest.ended_at && (
                      <Button
                        onClick={() => handleStartExam(mockTest)}
                        type="success"
                        size="sm"
                        customWidth={28}
                        btnClassName="h-fit"
                        testId="exam-reappear-btn"
                        title="Reappear"
                      >
                        Reappear
                      </Button>
                    )}

                    {mockTest.started_at && !mockTest.ended_at && (
                      <Button
                        onClick={() => handleResumeExam(mockTest)}
                        type="success"
                        size="sm"
                        customWidth={28}
                        btnClassName="h-fit"
                        testId="exam-resume-btn"
                        title="Resume"
                      >
                        Resume
                      </Button>
                    )}
                    {!mockTest.started_at && !mockTest.ended_at && (
                      <Button
                        onClick={() => handleStartExam(mockTest)}
                        type="success"
                        size="sm"
                        customWidth={28}
                        btnClassName="h-fit"
                        testId="exam-start-btn"
                        title="Start"
                      >
                        Start
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {mockTests.meta?.total_entries > PER_PAGE_RECORD_LIMIT && (
            <Pagination
              currentPage={mockTests.meta?.page_number}
              totalPage={mockTests.meta?.total_pages}
              setCurrentPage={onSetCurrentPage}
              totalEntries={mockTests.meta?.total_entries}
              pageSize={
                parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE
              }
            />
          )}
        </div>
      ) : (
        <div className="pt-16 text-center">
          <img src={NoDataFound} alt="No Data Found" className="m-auto" />
        </div>
      )}
    </div>
  );
};
export default MockTest;
