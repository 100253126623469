import { createSlice } from "@reduxjs/toolkit";

const apiErrorSlice = createSlice({
  name: "apiErrors",
  initialState: {
    status: null,
    message: "",
    url: "",
    baseURL: "",
    errorMessage: "",
  },
  reducers: {
    setApiError: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.url = action.payload.url;
      state.baseURL = action.payload.baseURL;
      state.errorMessage = action.payload.errorMessage;
    },
    clearApiError: (state) => {
      state.status = null;
      state.message = "";
      state.url = "";
      state.baseURL = "";
      state.errorMessage = "";
    },
  },
});

export const { setApiError, clearApiError } = apiErrorSlice.actions;
export default apiErrorSlice.reducer;
