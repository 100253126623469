import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addQuestion,
  deleteQuestion,
  getQuestionBank,
  updateQuestion,
} from "api/erp/questionPaperApi";

export const getQuestionBankAction = createAsyncThunk(
  "questions/get",
  async ({
    page,
    type,
    category,
    tag_ids: tagIds,
    search_term: searchTerm,
  }) => {
    const response = await getQuestionBank(
      page,
      type,
      category,
      tagIds,
      searchTerm
    );
    return response;
  }
);

export const createQuestionAction = createAsyncThunk(
  "questions/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addQuestion(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQuestionAction = createAsyncThunk(
  "questions/update",
  async (id, data, { rejectWithValue }) => {
    try {
      const response = await updateQuestion(id, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuestionAction = createAsyncThunk(
  "questions/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteQuestion(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
