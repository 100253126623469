import { useEffect, useRef, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { BsEyeFill, BsEyeSlashFill, BsXLg } from "react-icons/bs";
import { CgCheck } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import {
  deleteQuestionPaperQuestions,
  getQuestionPaperQuestionsBySection,
  showQuestion,
} from "api/erp/questionPaperApi";
import { ALERT_TYPE } from "app/constants";
import { Button, CardView } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Pill from "components/common/Pill";
import Modal from "components/molecules/Modal";
import { constructQueryParams } from "helpers/constructQueryParams";
import useAlert from "hooks/useAlert";
import { deleteSectionAction } from "store/thunkActions/erp/sectionsThunk";

import QuestionPaperSuggestionBox from "./QuestionPaperSuggestionBox";
import SectionModal from "./SectionModal";

const Section = ({
  sectionQuestions,
  section,
  isSectional,
  questionPaperName,
  questionPaperType,
  questionPaperId,
  setQuestions,
}) => {
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const questionPaper = useParams();

  const [isEditingSection, setIsEditingSection] = useState(false);
  const [isDeletingSection, setIsDeletingSection] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSuggestionBox, setShowSuggestionBox] = useState(false);
  const [viewQuestionDetail, setViewQuestionDetail] = useState();
  const [rowIndex, setRowIndex] = useState();
  const [questionAdded, setQuestionAdded] = useState(false);
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const questions = sectionQuestions;
  const firstLoad = useRef(true);

  useEffect(() => {
    if (firstLoad.current) {
      window.scrollTo(0, 0);
      firstLoad.current = false;
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
    setQuestionAdded(false);
  }, [questionAdded]);

  const storePosition = (e) => {
    const position = e.target.getBoundingClientRect();
    sessionStorage.setItem(
      `PositionQP-${questionPaperId}-${section?.name}-X`,
      window.pageXOffset + position.x
    );
    sessionStorage.setItem(
      `PositionQP-${questionPaperId}-${section?.name}-Y`,
      window.pageYOffset
    );
  };

  useEffect(() => {
    const positionX = sessionStorage.getItem(
      `PositionQP-${questionPaperId}-${section?.name}-X`
    );
    const positionY = sessionStorage.getItem(
      `PositionQP-${questionPaperId}-${section?.name}-Y`
    );
    window.scrollTo({ top: positionY, left: positionX, behavior: "smooth" });
  }, [questionPaperId, section?.name]);

  const handleDelete = (question) => {
    setOpenDeleteQuestionModal(true);
    setSelectedQuestion(question);
  };

  const deleteHandler = () =>
    dispatch(deleteSectionAction({ id: section.id }))
      .unwrap()
      .then(() => {
        showAlert("success", "Section has been successfully deleted!");
        setIsDeletingSection(false);
        navigate(`/questions_manager/question_paper/${questionPaper.id}`);
      })
      .catch(() => showAlert("danger", "Something went wrong!"));

  const handleDeleteQuestionPaperQuestion = (question) => {
    deleteQuestionPaperQuestions(question.id)
      .then(({ meta }) => {
        if (!showSuggestionBox) {
          setOpenDeleteQuestionModal(false);
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
        }

        getQuestionPaperQuestionsBySection(questionPaperId, section?.id).then(
          (response) => {
            setQuestions(response.data);
          }
        );
        setQuestionAdded(false);
      })
      .catch(({ meta }) => {
        showAlert(ALERT_TYPE[meta?.message_type], meta?.message);
      });
  };

  const setCollapsQuestionData = (question) => setViewQuestionDetail(question);

  return (
    <div className="w-full mb-12">
      {isSectional && (
        <div className="flex py-4">
          <span className="text-xl font-semibold"> {section.name} </span>
          <Button
            title="Edit Section Name"
            testId="edit-section-btn"
            onClick={() => setIsEditingSection(true)}
            btnClassName="p-2 ml-2 rounded-md items-center hover:bg-primary-lighter py-1"
          >
            <AiFillEdit className="text-xl text-primary-dark" />
          </Button>

          <Button
            title="Delete Section"
            testId="delete-section-btn"
            onClick={() => setIsDeletingSection(true)}
            btnClassName="p-2 ml-2 rounded-md items-center hover:bg-danger-lighter py-1"
          >
            <AiFillDelete className="text-xl text-danger-dark" />
          </Button>
        </div>
      )}

      {!questions?.length && (
        <p className="italic text-gray-600">Add questions to display here</p>
      )}
      <div>
        <div
          className={`flex flex-col ${
            showSuggestionBox ? "w-[50%]" : "w-full"
          }`}
        >
          {questions?.map((question, questionIndex) => {
            return (
              <div
                className="mb-2 bg-white"
                key={question.id}
                data-testid={`question-id-${question.question_id}`}
              >
                <CardView>
                  <div className="mt-2 flex w-full items-center justify-between gap-2 bg-white">
                    <div className="flex flex-grow justify-between items-center">
                      <span className="top-0 mr-2 text-lg font-medium">
                        Question {questionIndex + 1}
                      </span>
                      <div className="flex justify-end items-center pb-2">
                        {viewQuestionDetail && rowIndex == questionIndex ? (
                          <Button
                            title="Collapse"
                            onClick={() => {
                              setRowIndex(undefined);
                              setViewQuestionDetail(undefined);
                            }}
                            btnClassName="p-1 sm:p-2 mx-1 hover:bg-primary-lighter rounded-md"
                          >
                            <BsEyeSlashFill className="text-xl text-primary-main " />
                          </Button>
                        ) : (
                          <Button
                            title="Expand"
                            onClick={() => {
                              showQuestion(question?.question_id).then(
                                ({ data }) => {
                                  setRowIndex(questionIndex);
                                  setCollapsQuestionData(data);
                                }
                              );
                            }}
                            btnClassName="p-1 sm:p-2 mx-1 hover:bg-primary-lighter rounded-md"
                          >
                            <BsEyeFill className="text-xl text-primary-main " />
                          </Button>
                        )}
                        {showSuggestionBox ? (
                          <Button
                            title="Remove"
                            onClick={() => {
                              handleDeleteQuestionPaperQuestion(question);
                            }}
                            btnClassName="p-2 mx-1 hover:bg-danger-lighter rounded-md"
                          >
                            <BsXLg className="text-lg  text-red-600" />
                          </Button>
                        ) : (
                          <div className="flex">
                            <Link
                              title="Edit"
                              className="mx-1 cursor-pointer rounded-md p-1 hover:bg-primary-lighter sm:p-2"
                              to={
                                isSectional
                                  ? `/questions_manager/question_paper/${questionPaperId}/question/${
                                      section.id
                                    }?${constructQueryParams({
                                      question_id: question?.question_id,
                                      question_paper_name: questionPaperName,
                                      question_paper_questions_id: question?.id,
                                    })}`
                                  : `/questions_manager/question_paper/${questionPaperId}/question?${constructQueryParams(
                                      {
                                        question_id: question?.question_id,
                                        question_paper_name: questionPaperName,
                                        question_paper_questions_id:
                                          question?.id,
                                      }
                                    )}`
                              }
                              onClick={storePosition}
                            >
                              <AiFillEdit className="text-xl text-primary-dark" />
                            </Link>
                            <Button
                              title="Delete"
                              testId="delete-question"
                              onClick={() => {
                                handleDelete(question);
                              }}
                              btnClassName="p-1 sm:p-2 hover:bg-danger-lighter rounded-md"
                            >
                              <AiFillDelete className="text-xl text-danger-dark " />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex cursor-default flex-col ">
                    <div>
                      <RenderParsedHTML content={question.text || ""} />
                    </div>

                    {!(questionIndex == rowIndex) && (
                      <div className="text-sm text-stone-500 mt-1">
                        Marks: {question.marks} | Duration: {question.duration}s
                      </div>
                    )}
                  </div>
                  {viewQuestionDetail && questionIndex == rowIndex && (
                    <div className="ml-3 text-sm text-gray-600">
                      <div className="my-2 flex flex-col ">
                        {viewQuestionDetail.images?.map((img) => (
                          <div className="w-[32%]" key={img.id}>
                            <img
                              data-testid={img.attachment_file_name}
                              className="my-2 h-auto object-contain"
                              src={img.attachment}
                              alt=""
                              onError={(event) => {
                                event.target.style.display = "none";
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="my-2 flex flex-col justify-between md:flex-row">
                        <div className="w-full flex-col md:w-5/12">
                          <span className="mb-1 block font-medium">
                            Question Type
                          </span>
                          <span className="uppercase">
                            {viewQuestionDetail.question_type}
                          </span>
                        </div>
                        <div className="w-full flex-col md:w-5/12">
                          <span className="mb-1 block font-medium">
                            Category
                          </span>
                          {viewQuestionDetail.category_name?.length ? (
                            <span>{viewQuestionDetail.category_name}</span>
                          ) : (
                            <span className="text-sm italic">
                              No categories found!
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="my-4">
                        <span className="block font-medium">Question Tags</span>
                        {viewQuestionDetail.tags?.length ? (
                          <span className="flex flex-col flex-wrap md:flex-row">
                            {viewQuestionDetail.tags.map((tag) => {
                              return (
                                <Pill key={tag.tag_id} label={tag.tag_name} />
                              );
                            })}
                          </span>
                        ) : (
                          <span className="text-sm italic">No tags found!</span>
                        )}
                      </div>
                      <div className="my-4 flex flex-col justify-between md:flex-row">
                        <div className="w-full flex-col md:w-5/12">
                          <span className="mb-1 block font-medium">
                            Duration{" "}
                            <span className="mt-1 text-xs italic text-gray-main">
                              ( in seconds )
                            </span>
                          </span>
                          <span>{viewQuestionDetail.duration}</span>
                        </div>

                        <div className="w-full flex-col md:w-5/12">
                          <span className="mb-1 block font-medium">Marks</span>
                          <span>{viewQuestionDetail.marks}</span>
                        </div>
                      </div>
                      <div className="flex flex-col flex-wrap justify-between md:flex-row">
                        {viewQuestionDetail.answers?.map((item, index) => {
                          return (
                            <div key={item.id} className="w-full md:w-5/12">
                              <div>
                                <span
                                  htmlFor={`option.${index}.text`}
                                  className="font-medium text-primary-main"
                                >
                                  Option {index + 1}
                                </span>
                              </div>

                              <RenderParsedHTML content={item.text || ""} />

                              <div className="flex justify-between">
                                {item.attachment_file_name && (
                                  <img
                                    className="my-2 w-1/3 object-contain "
                                    src={item.attachment}
                                    alt={item.attachment_file_name}
                                  />
                                )}
                              </div>

                              {item.correct_answer && (
                                <div className="my-2 flex items-center">
                                  <button
                                    disabled
                                    className="mr-2 h-4 w-4 rounded-sm border border-primary-main bg-primary-main bg-center"
                                  >
                                    <CgCheck className="text-white" />
                                  </button>
                                  <span className="text-sm">Correct</span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>

                      <div className="flex flex-col flex-wrap justify-between md:flex-row">
                        {viewQuestionDetail.driver_codes?.length !== 0 && (
                          <div className="w-full">
                            <div className="mt-5 font-medium">
                              {" "}
                              Languages & Driver Codes
                            </div>
                            <div className="w-full">
                              <div className="flex flex-col flex-wrap justify-between md:flex-row">
                                {viewQuestionDetail.driver_codes?.map(
                                  (item) => {
                                    return (
                                      <div
                                        key={item.id}
                                        className="w-full md:w-5/12"
                                      >
                                        <div className="py-2 align-top">
                                          <span className="mr-3.5 px-2 font-medium text-primary-main">
                                            {item.coding_language}
                                          </span>
                                        </div>
                                        <div className="rounded-sm border p-2">
                                          <span className="whitespace-pre-wrap text-sm">
                                            {item.code_string}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="mb-6 flex flex-col flex-wrap justify-between md:flex-row">
                        {viewQuestionDetail.test_cases?.length !== 0 && (
                          <div className="w-full">
                            <div className="mt-10 font-medium"> Test Cases</div>
                            <div className="mt-4 w-full">
                              <div className="flex flex-col flex-wrap justify-between md:flex-row">
                                {viewQuestionDetail.test_cases?.map((item) => {
                                  return (
                                    <div
                                      className="w-full md:w-5/12"
                                      key={item.id}
                                    >
                                      <div className="flex flex-col gap-1 py-2 align-top">
                                        <span className="font-medium">
                                          Input:
                                        </span>
                                        <span className="whitespace-pre-wrap break-words rounded-md border p-2">
                                          {item.input}
                                        </span>
                                      </div>
                                      <div className="flex flex-col gap-1 py-2 align-top">
                                        <span className="font-medium">
                                          Expected Output:
                                        </span>
                                        <span className="whitespace-pre-wrap break-words rounded-md border p-2">
                                          {item.output}
                                        </span>
                                      </div>
                                      <div className="flex gap-2 py-2 align-top">
                                        <span className="font-medium">
                                          Public:
                                        </span>
                                        {item.is_public ? (
                                          <span> Yes</span>
                                        ) : (
                                          <span> No</span>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </CardView>
              </div>
            );
          })}
        </div>
        {showSuggestionBox && (
          <QuestionPaperSuggestionBox
            setIsEditState={setShowSuggestionBox}
            questionPaperType={questionPaperType}
            sectionId={section?.id}
            questionPaperId={questionPaperId}
            setQuestions={setQuestions}
            sectionQuestions={sectionQuestions}
            setQuestionAdded={setQuestionAdded}
          />
        )}
      </div>

      {!showSuggestionBox && (
        <div className="py-4">
          <Button
            title="Add Question"
            testId="add-question-btn"
            onClick={() => setShowDropdown((current) => !current)}
            type="tertiary"
            btnClassName="text-primary-main font-medium hover:font-semibold text-sm"
          >
            {showDropdown ? "- " : "+ "}Add Question
            {showDropdown && (
              <div className="top-50 right-50 absolute z-10 mt-2 rounded-md border shadow-2xl bg-white">
                <Button
                  title="New Question"
                  btnClassName="text-primary-main font-medium w-full border-b hover:bg-gray-lighter hover:font-semibold text-sm p-3"
                  onClick={() =>
                    isSectional
                      ? navigate(
                          `/questions_manager/question_paper/${questionPaper.id}/question/${section.id}`
                        )
                      : navigate(
                          `/questions_manager/question_paper/${questionPaper.id}/question`
                        )
                  }
                  testId="add-new-question-btn"
                >
                  New Question
                </Button>
                <Button
                  title="From Question Bank"
                  btnClassName="flex font-medium p-3 w-full text-sm text-primary-main hover:bg-gray-lighter hover:font-semibold"
                  onClick={() => {
                    setShowSuggestionBox(true);
                  }}
                  testId="add-question-bank-question-btn"
                >
                  From Question Bank
                </Button>
              </div>
            )}
          </Button>
        </div>
      )}

      {isEditingSection && (
        <SectionModal
          setIsEditState={setIsEditingSection}
          sectionName={section.name}
          sectionId={section.id}
        />
      )}

      {openDeleteQuestionModal && (
        <Modal
          title="Are you sure?"
          testId="delete-question-modal"
          description="Are you sure you want to delete this question?"
          acceptButtonText="Yes"
          acceptBtnType="primary"
          rejectButtonText="No"
          rejectBtnType="secondary"
          handleAccept={() =>
            handleDeleteQuestionPaperQuestion(selectedQuestion)
          }
          handleReject={() => setOpenDeleteQuestionModal(false)}
        />
      )}

      {isDeletingSection && (
        <Modal
          title="Are you sure?"
          testId="delete-section-modal"
          description="This also deletes all questions within this section and this cannot be undone."
          acceptBtnType="primary"
          acceptButtonText="Yes"
          rejectBtnType="secondary"
          rejectButtonText="No"
          handleAccept={deleteHandler}
          handleReject={() => setIsDeletingSection(false)}
        />
      )}
    </div>
  );
};

Section.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),

  sectionQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      marks: PropTypes.string,
      duration: PropTypes.number,
      section_id: PropTypes.number,
    })
  ),
  questionPaperName: PropTypes.string,
  questionPaperType: PropTypes.string,
  isSectional: PropTypes.bool,
  questionPaperId: PropTypes.number,
  setQuestions: PropTypes.func,
};

export default Section;
