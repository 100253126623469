import { useEffect, useRef, useState } from "react";
import { BiLink } from "react-icons/bi";
import { BsXLg } from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiCake2Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import PropTypes from "prop-types";

import { ICON_SIZE_HASH } from "app/constants";
import { Avatar } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import CopyLink from "components/common/CopyLink";
import { getDomainName } from "helpers/utils";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import ContactDetail from "./ContactDetails";
import EducationQualifications from "./Education";
import WorkExperiences from "./Experience";
import Interests from "./Interests";
import Languages from "./Languages";
import Projects from "./Projects";
import References from "./References";
import Skills from "./Skills";

const CandidateProfilePanel = ({
  candidatePanelDetails,
  jobTitle,
  scrollToRef,
  onClose,
  showSidePanel,
}) => {
  const workExperienceRef = useRef(null);
  const educationRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const languagesRef = useRef(null);
  const candidateProfileRef = useRef(null);

  const params = useParams();
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const InfoRef = {
    workExperienceRef,
    educationRef,
    skillsRef,
    projectsRef,
    languagesRef,
  };

  useEffect(() => {
    if (candidatePanelDetails && showSidePanel) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              { name: "Jobs", link: "/jobs" },
              {
                name: jobTitle,
                link: `/jobs/${params.uuid}`,
              },
            ],
            currentPageName: candidatePanelDetails.id,
          },
        })
      );
    }
  }, [
    params.uuid,
    candidatePanelDetails,
    dispatch,
    jobTitle,
    scrollToRef,
    showSidePanel,
  ]);

  const handleScrollAndAnimation = () => {
    InfoRef[scrollToRef].current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });

    setTimeout(() => {
      if (InfoRef[scrollToRef].current) {
        InfoRef[scrollToRef].current.style.borderLeft = "solid";
        InfoRef[scrollToRef].current.style.borderLeftColor = `#71ACC7`;
        InfoRef[scrollToRef].current.style.borderLeftWidth = `7px`;
      }
    }, 800);

    setTimeout(() => {
      if (InfoRef[scrollToRef].current) {
        InfoRef[scrollToRef].current.style.border = "none";
      }
    }, 1800);
  };

  useEffect(() => {
    setShowMore(false);
    setTimeout(() => {
      if (scrollToRef && InfoRef[scrollToRef].current) {
        handleScrollAndAnimation();
      }
    }, 800);
  }, [scrollToRef, candidatePanelDetails]);

  return (
    <div className="h-full px-2">
      {candidatePanelDetails && (
        <div
          ref={candidateProfileRef}
          className="whitespace-normal break-words "
        >
          <div className="sticky top-0 right-0 z-50 bg-gray-lighterShade3 shadow-sm">
            <div
              className={classNames(
                "absolute ml-2 right-0 mr-1 p-1 top-5 max-w-[10%]"
              )}
            >
              <BsXLg
                data-testid="close-panel-btn"
                role="button"
                size="16px"
                title="Close Panel"
                onClick={onClose}
              />
            </div>
            <div className="mx-auto w-full p-2 px-2 ">
              <div className="flex">
                {candidatePanelDetails?.avatar?.thumb ? (
                  <div
                    className={classNames(
                      "rounded-full ring-2 ring-primary-dark h-12 w-12 max-w-[25%] mt-3 mr-3"
                    )}
                  >
                    <Avatar
                      src={candidatePanelDetails.avatar?.thumb}
                      height={12}
                      width={12}
                    />
                  </div>
                ) : (
                  <div
                    className={classNames(
                      "bg:to-primary-light m-2 flex items-center justify-center rounded-full bg-orange-50 font-medium text-primary-main ring-2 ring-primary-dark",
                      "w-12 h-12 max-w-[25%] mt-3 text-[31px] ring-2 ring-primary-dark"
                    )}
                  >
                    {candidatePanelDetails?.name?.charAt(0)}
                  </div>
                )}
                <div className="mx-2 md:mt-0 w-[65%]">
                  <div className="flex gap-2">
                    <h3
                      className="mt-2 flex font-normal break-all text-2xl"
                      data-testid="candidate-name"
                    >
                      {candidatePanelDetails?.name || "Name"}
                    </h3>
                    <div className="mt-2">
                      <CopyLink
                        link={`https://erp.${getDomainName()}/jobs/${
                          candidatePanelDetails?.job_uuid
                        }/candidates/${candidatePanelDetails?.id}`}
                        tooltipId="copyLink"
                        tooltipContent="Get Shareable Profile Link"
                      />
                    </div>
                  </div>
                  <p
                    className="text-md block font-medium italic text-gray-dark"
                    data-testid="candidate-designation"
                  >
                    {candidatePanelDetails?.designation || "Current Position"}
                  </p>
                  {candidatePanelDetails?.date_of_birth && (
                    <p className="my-1 flex items-center text-sm text-gray-dark">
                      <RiCake2Line className="mr-2 inline" />
                      {format(
                        new Date(candidatePanelDetails.date_of_birth),
                        "dd MMMM yyyy",
                        {
                          locale: enGB,
                        }
                      )}
                    </p>
                  )}
                  {candidatePanelDetails.portfolio_url && (
                    <span className="items-center text-xs text-gray-dark">
                      <BiLink
                        className="mr-2 mb-1 inline"
                        size={ICON_SIZE_HASH.sm}
                      />
                      <a
                        title="Portfolio"
                        target="_blank"
                        rel="noreferrer"
                        className="italic text-primary-light hover:underline"
                        href={candidatePanelDetails.portfolio_url}
                      >
                        Portfolio
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {candidatePanelDetails?.about && (
            <div className={classNames("bg-white mx-2 rounded-lg mt-2")}>
              <button
                type="button"
                className="bg-gray-200 p-3 w-full flex justify-between items-center hover:bg-gray-light"
                onClick={() => setShowMore((prevShowMore) => !prevShowMore)}
              >
                <p>About me </p>
                <div>{showMore ? <FaChevronUp /> : <FaChevronDown />}</div>
              </button>
              {showMore && (
                <p className="mx-3 py-2">
                  <RenderParsedHTML
                    content={candidatePanelDetails.about || ""}
                  />
                </p>
              )}
            </div>
          )}

          <div className="whitespace-normal break-all h-full min-h-screen grid-cols-1 gap-0 px-2 pt-2 pb-8 md:rounded-t-lg lg:grid-cols-12 lg:gap-8">
            <div className="lg:col-span-7">
              <div className="workExperience" ref={workExperienceRef}>
                <WorkExperiences
                  workExperiences={candidatePanelDetails.work_experiences}
                  modalView={false}
                />
              </div>
              <div ref={educationRef}>
                <EducationQualifications
                  educationQualifications={candidatePanelDetails.educations}
                  modalView={false}
                />
              </div>
              <ContactDetail contactDetail={candidatePanelDetails.address} />
              <References referenceDetails={candidatePanelDetails.references} />
            </div>
            <div className="lg:col-span-5">
              <div ref={skillsRef}>
                <Skills
                  candidateSkills={candidatePanelDetails.candidate_skills}
                  modalView={false}
                  sidePanelView
                />
              </div>
              <div ref={projectsRef}>
                <Projects
                  projects={candidatePanelDetails.projects}
                  modalView={false}
                />
              </div>
              <div ref={languagesRef}>
                <Languages
                  candidateLanguages={candidatePanelDetails.candidate_languages}
                  modalView={false}
                  sidePanelView
                />
              </div>
              <Interests interests={candidatePanelDetails.interests} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CandidateProfilePanel.defaultProps = {
  jobTitle: "",
  // scrollStart: false,
  scrollToRef: "",
  onClose: null,
};

CandidateProfilePanel.propTypes = {
  candidatePanelDetails: PropTypes.shape({
    about: PropTypes.string,
    interests: PropTypes.string,
    date_of_birth: PropTypes.string,
    references: PropTypes.array,
    id: PropTypes.number,
    job_uuid: PropTypes.string,
    name: PropTypes.string,
    educations: PropTypes.array,
    candidate_skills: PropTypes.array,
    work_experiences: PropTypes.array,
    designation: PropTypes.string,
    current_location: PropTypes.string,
    status: PropTypes.string,
    portfolio_url: PropTypes.string,
    placed: PropTypes.shape({
      stage_id: PropTypes.number,
      stage_name: PropTypes.string,
      loi_sent: PropTypes.bool,
    }),
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
    candidate_languages: PropTypes.array,
    applied_at: PropTypes.string,
    projects: PropTypes.array,
    address: PropTypes.shape({
      id: PropTypes.number,
      address_line1: PropTypes.string,
      address_line2: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      permanent_address: PropTypes.bool,
      state: PropTypes.string,
      postal_code: PropTypes.string,
      willing_to_relocate: PropTypes.bool,
    }),
  }),
  jobTitle: PropTypes.string,
  // scrollStart: PropTypes.bool, todo: remove unnecessary props from parent
  scrollToRef: PropTypes.string,
  onClose: PropTypes.func,
  showSidePanel: PropTypes.bool,
};

export default CandidateProfilePanel;
