import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProjectsAction } from "store/thunkActions/candidate/projectThunk";

import CardContainer from "../Common/CardContainer";

import Form from "./Form";
import Project from "./Project";

const Projects = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { projects } = useSelector((state) => state.projects);

  useEffect(() => {
    dispatch(getProjectsAction());
  }, []);

  return (
    <CardContainer
      title="Project"
      addEventButtonTitle="Add Project"
      addEventButtonTestId="add-project-btn"
      setIsAdding={setIsAdding}
    >
      {!projects.length && !isAdding && (
        <span className="block text-sm italic text-gray-main ">
          Click on the plus icon to add projects
        </span>
      )}

      {projects.map((project) => {
        return (
          <div
            key={project.id}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <Project project={project} />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsEditState={setIsAdding} setIsAdding={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default Projects;
