import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getJobDetail } from "api/candidate/jobApi";
import { checkProfile } from "api/candidate/profileApi";
import { ALERT_TYPE } from "app/constants";
import { ActivityIndicator } from "components/atoms";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { handleApplyJobAction } from "store/thunkActions/candidate/jobApplyThunk";

import ConfirmApplyModal from "./ConfirmApplyModal";
import JobDetails from "./JobDetails";

const JobDescription = () => {
  const param = useParams();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const dispatch = useDispatch();

  const [jobDetails, setJobDetails] = useState();
  const [showConfirmApplyModal, setShowConfirmApplyModal] = useState(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const { job } = useSelector((state) => state.job);
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (job) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: { currentPageName: "Jobs" },
        })
      );
    }
  }, [job]);

  useEffect(() => {
    getJobDetail(param.uuid)
      .then(({ data }) => {
        if (
          data.uuid === "018ac59c-4eb2-7409-bc95-edb62d66b494" &&
          data.applied === false
        ) {
          window.location.replace(
            "https://www.kreeti.com/event/elixir-phoenix-bootcamp"
          );
        } else {
          setJobDetails(data);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      checkProfile().then((response) => {
        setIsProfileCompleted(response.data?.profile_completed);
      });
    }
  }, [isLoggedIn]);

  const handleApplyJob = () =>
    dispatch(handleApplyJobAction(param.uuid))
      .unwrap()
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        navigate("/jobs/applied");
      })
      .catch(({ meta }) => {
        setShowConfirmApplyModal(false);
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
      });

  if (!jobDetails) {
    return <ActivityIndicator />;
  }

  return (
    <>
      {jobDetails && (
        <JobDetails
          selectedJob={jobDetails}
          setShowConfirmModal={setShowConfirmApplyModal}
          fromJobDescription
        />
      )}

      {showConfirmApplyModal && (
        <ConfirmApplyModal
          isProfileCompleted={isProfileCompleted}
          handleApplyJob={handleApplyJob}
          setShowConfirmApplyModal={setShowConfirmApplyModal}
          setIsProfileCompleted={setIsProfileCompleted}
          visibility={jobDetails.visibility}
        />
      )}
    </>
  );
};

export default JobDescription;
