import Proptypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";

const Profile = ({ about }) => {
  return (
    <>
      <div className="resume-section-heading">PROFILE</div>
      <div
        className="resume-section-content"
        style={{
          textAlign: "justify",
          wordBreak: "break-word",
          color: "rgb(100 100 100)",
        }}
      >
        <RenderParsedHTML content={about || ""} />
      </div>
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

export default Profile;

Profile.defaultProps = {
  about: "",
};

Profile.propTypes = {
  about: Proptypes.string,
};
