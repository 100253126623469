import { createSlice } from "@reduxjs/toolkit";

import {
  createQuestionPaperAction,
  getQuestionPapersAction,
} from "store/thunkActions/erp/questionsThunk";

const questionPapers = createSlice({
  name: "questionPapers",
  initialState: {
    data: [],
    meta: undefined,
    isLoading: true,
  },
  reducers: {},
  extraReducers: {
    [createQuestionPaperAction.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [getQuestionPapersAction.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
  },
});

export default questionPapers.reducer;
