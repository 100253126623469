import PropTypes from "prop-types";

const Interests = ({ interests }) => {
  return (
    <div className="mx-auto mt-4 w-full rounded-lg border border-gray-light bg-white p-4">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-2xl font-normal">Hobbies &amp; Interests</h3>
      </div>

      <div className=" flex flex-wrap text-sm">
        {interests ? (
          <p className="pr-2 text-sm  leading-5">{interests}</p>
        ) : (
          <span className="italic text-gray-main">No Interests to show</span>
        )}
      </div>
    </div>
  );
};

Interests.propTypes = {
  interests: PropTypes.string,
};

export default Interests;
