import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { startCase } from "lodash";

import { getInterviews } from "api/candidate/candidateApi";
import { INTERVIEW_TYPES } from "app/constants";
import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import { TabList } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import Pagination from "components/common/Pagination";
import {
  DEFAULT_PAGE_SIZE,
  PER_PAGE_RECORD_LIMIT,
  SITE_TITLE,
} from "constants/common";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import Interview from "./Interview";

const InterviewPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(INTERVIEW_TYPES[0].key);
  const [interviewList, setInterviewList] = useState({
    data: [],
    meta: undefined,
    isLoading: true,
  });
  const page = parseInt(searchParams.get("page"), 10) || 1;
  const pageSize =
    parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE;
  const tab = searchParams.get("tab") || currentTab;
  const dispatch = useDispatch();

  useEffect(() => {
    hideFreshWorkWidget();
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: { currentPageName: "Interviews" },
      })
    );
  }, []);

  if (searchParams.get("page") > interviewList.meta?.total_pages) {
    setSearchParams({
      tab,
      page: interviewList.meta?.page_number,
      page_size: pageSize,
    });
  }

  useEffect(() => {
    setInterviewList({ data: [], meta: undefined, isLoading: true });
    getInterviewList();
  }, [tab]);

  const getInterviewList = (pageNumber = page, pageSizeValue = pageSize) =>
    getInterviews({
      page: pageNumber,
      status: tab,
      page_size: pageSizeValue,
    }).then((response) => {
      setInterviewList({
        data: response.data,
        meta: response.meta,
        isLoading: false,
      });
    });

  const onSetCurrentPage = (page, pageSizeValue = pageSize) => {
    setSearchParams({ tab, page, page_size: pageSizeValue });
    setInterviewList({ isLoading: true });
    getInterviewList(page, pageSizeValue);
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <div className="h-full w-full px-2 lg:px-4">
      <Helmet>
        <title>{`${startCase(tab)} Interviews - ${SITE_TITLE}`}</title>
      </Helmet>
      <div className="sticky top-[45px] z-10 bg-gray-lighterShade3">
        <TabList
          testId="interview-tabs"
          tabs={INTERVIEW_TYPES}
          currentTab={tab}
          paramBased
          onTabClick={(key) => {
            setCurrentTab(key);
            setSearchParams({ tab: key, page, page_size: pageSize });
          }}
        />
      </div>

      {interviewList.isLoading && <ActivityIndicator />}

      {!!interviewList.data?.length && (
        <div className="flex flex-col py-2 lg:flex-row ">
          <div className="w-full">
            {interviewList.data?.map((interview) => (
              <div
                key={interview.id}
                className="mx-2 border-b-2 bg-white px-4 py-1 hover:bg-secondary-hover"
              >
                <Interview interview={interview} status={tab} />
              </div>
            ))}

            {interviewList.meta?.total_entries > PER_PAGE_RECORD_LIMIT && (
              <Pagination
                currentPage={interviewList.meta?.page_number || 1}
                totalPage={interviewList.meta?.total_pages}
                setCurrentPage={onSetCurrentPage}
                totalEntries={interviewList.meta?.total_entries}
                pageSize={
                  parseInt(searchParams.get("page_size"), 10) ||
                  DEFAULT_PAGE_SIZE
                }
              />
            )}
          </div>
        </div>
      )}

      {!interviewList.isLoading && interviewList.data?.length === 0 && (
        <div className="pt-16">
          <img src={NoDataFound} alt="No Data Found" className="m-auto" />
        </div>
      )}
    </div>
  );
};

export default InterviewPage;
