export const FACE_MATCH_THRESHOLD_DISTANCE = 11.6;

export const REF_IMG_ERROR_MSG = {
  noFace: "No face detected",
  multipleFace: "Multiple faces detected",
  notStraight: "You are not looking at the camera",
};

export const FACE_MISMATCH_VIOLATION_MESSAGES = {
  unauthorizedObjectUse: "Unauthorized device use detected",
  noPersonFaceDetected: "No person / face detected",
  multiplePersonDetected: "Multiple people detected",
  notLookingStraight: "Candidate is not looking straight",
  detectedFaceNotMatching:
    "Detected face is not matching with the uploaded reference image of the candidate",
  noViolation: "No face mismatch violation occurred",
};
