import { useState } from "react";
import classNames from "classnames";
import Proptypes from "prop-types";

import ActivityIndicator from "components/common/ActivityIndicator";

const BUTTON_SIZES = {
  sm: { defaultSizeClass: "w-20", otherClassNames: "text-xs px-4" },
  smResponsive: {
    defaultSizeClass: "sm:w-24",
    otherClassNames: "text-sm px-4 w-full",
  },
  md: { defaultSizeClass: "w-28", otherClassNames: "text-sm px-5" },
  lg: { defaultSizeClass: "w-32", otherClassNames: "text-base px-6" },
  custom: { defaultSizeClass: "", otherClassNames: "" },
};

const TEXT_TRANSFORM = {
  uppercase: "uppercase",
  lowercase: "lowercase",
  capitalize: "capitalize",
  normalcase: "normal-case",
};

const LOADING_CLASS_NAMES =
  "flex justify-center items-center cursor-not-allowed";

/**
 * @param {boolean} isAsyncClick if button makes API call on click and in pending state show loading icon with disable state
 * @param {function} onClick return promise if button is isAsyncClick type
 */

/* To use this, return the api call / redux action contained inside the function, which
   which is passed as the prop of the button, as a promise (prefix 'return' to the call).
   Also, in the button component, pass isAsyncClick (in case of non-form calls)
   or isLoading (for form).
*/

const Button = ({
  title,
  btnName,
  customWidth, // Give valid tailwind class width, else it won't work as expected
  type,
  buttonType,
  onClick,
  testId,
  size,
  textTransform,
  children,
  btnClassName,
  isAsyncClick,
  isLoading,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const asyncClickHandler = (event) => {
    setIsSubmitting(true);
    return onClick(event)?.finally(() => setIsSubmitting((prev) => !prev));
  };

  return (
    <button
      title={title}
      disabled={type === "disabled" || isSubmitting || isLoading}
      data-testid={testId}
      className={classNames(
        `btn-${type}`,
        type === "primary"
          ? "disabled:bg-gray-lighter"
          : `disabled:bg-${type || `primary`}-light`,
        type === "tertiary"
          ? "text-primary-main hover:underline"
          : `hover:bg-${type}-dark`,
        type === "info" &&
          "bg-secondary-alertBg border border-secondary-alertDark capitalize hover:bg-secondary-alertDark hover:text-white text-secondary-alertDark p-2 font-medium text-xs rounded-full",
        `${BUTTON_SIZES[size].otherClassNames}`,
        customWidth
          ? `w-${customWidth}`
          : `${BUTTON_SIZES[size].defaultSizeClass}`,
        `${TEXT_TRANSFORM[textTransform]}`,
        btnClassName,
        {
          [LOADING_CLASS_NAMES]: isSubmitting || isLoading,
        }
      )}
      type={buttonType}
      onClick={isAsyncClick ? asyncClickHandler : onClick}
    >
      {isSubmitting || isLoading ? (
        <ActivityIndicator
          containerClass="mt-0"
          svgClassName="w-100%"
          size={4}
        />
      ) : (
        btnName || children
      )}
    </button>
  );
};

Button.defaultProps = {
  title: "",
  btnName: "",
  type: "icon",
  testId: "generic-button",
  textTransform: "capitalize",
  children: null,
  btnClassName: "",
  isAsyncClick: false,
  isLoading: false,
  onClick: () => null,
  size: "custom",
};

Button.propTypes = {
  title: Proptypes.string,
  btnName: Proptypes.string,
  children: Proptypes.node,
  textTransform: Proptypes.oneOf([
    "uppercase",
    "lowercase",
    "capitalize",
    "normalcase",
  ]),
  type: Proptypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "success",
    "warning",
    "info",
    "danger",
    "disabled",
    "icon",
    "secondary-danger",
  ]),
  customWidth: Proptypes.number,
  onClick: Proptypes.func,
  testId: Proptypes.string,
  size: Proptypes.oneOf(["sm", "smResponsive", "md", "lg", "custom"]),
  btnClassName: Proptypes.string,
  buttonType: Proptypes.string,
  isAsyncClick: Proptypes.bool,
  isLoading: Proptypes.bool,
};

export default Button;
