export const uniqueValidation = ({ field = "data" }) =>
  `This ${field} has already been added`;

export const requiredValidation = () => `can't be blank`;

export const characterValidation = ({ limit }) =>
  `should be at most ${limit} character(s)`;

export const minCharacterValidation = ({ limit }) =>
  `should be at least ${limit} character(s)`;

export const dateValidation = () =>
  `Start date should be earlier than End date`;

export const phoneNumberValidation = () =>
  `please enter a valid 10 digit phone number`;

export const formattedPhoneNumberValidation = () =>
  `please enter a valid phone number with format eg: 1234-567890`;

export const alphaNumericCharacterValidation = () =>
  `should only contain alphanumeric character(s)`;

export const characterExactValidation = ({ length }) =>
  `should be ${length} characters long`;

export const validFormatValidation = ({ data }) => `has invalid ${data} format`;

export const dateTimeValidation = () =>
  "should not be earlier than the current date & time";

export const reviewAutoCloseDate = () =>
  "Please review auto-close date before posting.";

export const selectFutureDateTime = () =>
  "Please select a future date and time or remove it to activate the job";

export const toDateTimeValidation = () =>
  "should not be earlier than the from date & time";

export const cameraWarning = () =>
  "Please enable camera permission. Denying it will disqualify you from the exam.";

export const fullScreenWarning = () =>
  "Please go to full-screen by clicking on the Full Screen button. Not doing this will disqualify you from the exam.";

export const internalErrorMessage = () =>
  " We are unable to fetch the next question at this moment due to some unexpected technical difficulty. Your answers so far have been successfully saved. Please try again after refreshing the page. If that does not work, please close this window and resume the exam after sometime from JobAlley dashboard.";

export const multipleTabsWarning = () =>
  "Sorry! The exam window can be opened only on a single tab or window.";

export const userNotFound = () => "Not Found";
