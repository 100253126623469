import { createSlice } from "@reduxjs/toolkit";

import {
  showQuestionPaperAction,
  updateQuestionPaperNameAction,
} from "store/thunkActions/erp/questionsThunk";

const questionPaper = createSlice({
  name: "questionPaper",
  initialState: {
    data: undefined,
  },
  reducers: {},
  extraReducers: {
    [showQuestionPaperAction.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [updateQuestionPaperNameAction.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export default questionPaper.reducer;
