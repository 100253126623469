import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import { capitalize } from "lodash";
import PropTypes from "prop-types";

import { getLanguages } from "api/candidate/profileApi";
import { PROFICIENCY_LEVELS } from "app/constants";
import { Button } from "components/atoms";
import { handleOptionLabel } from "helpers/formatDropDownOption";
import { formatOption } from "helpers/formatOption";
import reactSelectStyle from "helpers/reactSelectStyle";
import { validateDropdowns } from "helpers/utils";
import {
  createCandidateLanguagesAction,
  getCandidateLanguagesAction,
  updateCandidateLanguagesAction,
} from "store/thunkActions/candidate/languageThunk";

const AsyncPaginateCreatable = withAsyncPaginate(Creatable);

const Form = ({ candidateLanguage, setIsEditState, setIsAdding }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
    clearErrors,
    setError,
    watch,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(
    () =>
      reset({
        proficiency: {
          label: capitalize(candidateLanguage?.proficiency),
          value: candidateLanguage?.proficiency,
        },
        language: candidateLanguage?.language,
      }),
    [candidateLanguage]
  );

  useEffect(() => {
    dispatch(getCandidateLanguagesAction());
  }, []);

  const { candidateLanguages } = useSelector(
    (state) => state.candidateLanguages
  );

  const isLanguage = watch("language");

  const loadLanguageOptions = async (search, _, { page }) => {
    const { data, meta } = await getLanguages(page, search);
    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const isAlreadyAdded = (candidateLanguage) => {
    const candidateLanguageIds = candidateLanguages?.map(
      (candidateLanguage) => candidateLanguage.language.id
    );
    return candidateLanguageIds.includes(candidateLanguage.id);
  };

  const setErrorsOnFields = (errors) => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        if (key === "name" || key === "candidate_language") {
          setError("language", { message: value[0] });
        } else {
          setError(key, { message: value[0] });
        }
      });
    }
  };

  const submitHandler = (data) => {
    let action;
    const languageData = {
      proficiency: data.proficiency?.value || "",
    };
    if (candidateLanguage) {
      languageData.id = candidateLanguage.id;
      action = updateCandidateLanguagesAction(languageData);
    } else {
      if (data.language?.id) {
        languageData.language_id = data.language.id;
      } else {
        languageData.language_name = data.language.value;
      }
      action = createCandidateLanguagesAction(languageData);
    }

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(getCandidateLanguagesAction());
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <div className="flex flex-col justify-between">
        <div className="flex sm:flex-row flex-col justify-between mb-4">
          <div
            className={classNames("sm:mr-8 m-2 sm:w-1/2 sm:m-0", {
              "hover:cursor-not-allowed": candidateLanguage,
            })}
          >
            <Controller
              control={control}
              name="language"
              rules={{
                validate: (language) =>
                  validateDropdowns({ field: language, limit: 50 }),
              }}
              render={({ field }) => (
                <AsyncPaginateCreatable
                  isSearchable
                  autoFocus
                  isClearable
                  menuPlacement="auto"
                  isDisabled={candidateLanguage}
                  className="w-full bg-white"
                  styles={reactSelectStyle(errors.language)}
                  value={
                    field.value?.label || field.value?.name ? field.value : null
                  }
                  loadOptions={loadLanguageOptions}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  onInputChange={clearErrors}
                  debounceTimeout={1000}
                  placeholder="Select language"
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    formatOption(option.name) || formatOption(option.label)
                  }
                  isOptionDisabled={isAlreadyAdded}
                  formatCreateLabel={(name) => `Create new language "${name}"`}
                  additional={{ page: 1 }}
                  formatOptionLabel={(option, { context }) =>
                    handleOptionLabel(option, context)
                  }
                />
              )}
            />
          </div>
          <div className="sm:w-1/2 m-2 sm:m-0">
            <Controller
              name="proficiency"
              control={control}
              render={({ field }) => (
                <Select
                  name="proficiency"
                  options={PROFICIENCY_LEVELS}
                  isClearable
                  isSearchable
                  isDisabled={!isLanguage || isLanguage === null}
                  id="proficiency"
                  maxMenuHeight={120}
                  menuPlacement="auto"
                  placeholder="Proficiency level"
                  value={
                    field.value?.label || field.value?.name ? field.value : null
                  }
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
          </div>
        </div>
        {errors.language && (
          <p className="text-sm text-danger-dark">{errors.language.message}</p>
        )}

        <div className="mt-4 flex w-full">
          <Button
            title={setIsAdding ? "Save" : "Update"}
            btnClassName="px-2"
            testId="saveLanguageBtn"
            type="primary"
            size="sm"
            buttonType="submit"
            isLoading={isSubmitting}
          >
            {setIsAdding ? "Save" : "Update"}
          </Button>

          <Button
            title="Cancel"
            testId="cancelLanguageBtn"
            type="secondary"
            size="sm"
            btnClassName="px-2 ml-2"
            onClick={() => setIsEditState(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  candidateLanguage: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.string,
    proficiency: PropTypes.string,
    type: PropTypes.string,
    language: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};

export default Form;
