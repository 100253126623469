import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const RequireNoAuth = () => {
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
