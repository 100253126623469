import { createSlice } from "@reduxjs/toolkit";

import {
  createStageAction,
  deleteStageAction,
  getStagesAction,
  updateStageAction,
} from "store/thunkActions/erp/stagesThunk";

export const stagesSlice = createSlice({
  name: "stages",
  initialState: {
    isLoading: true,
    stages: [],
  },
  reducers: {},
  extraReducers: {
    [createStageAction.fulfilled]: (state, action) => {
      action.payload.data.forEach((s) => state.stages.push(s));
    },
    [getStagesAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getStagesAction.fulfilled]: (state, action) => {
      state.stages = action?.payload?.data;
      state.isLoading = false;
    },
    [updateStageAction.pending]: (state) => {
      state.isLoading = true;
    },
    [updateStageAction.fulfilled]: (state, action) => {
      const index = state.stages.findIndex(
        (stage) => stage.id == action.payload.data.id
      );
      state.stages[index] = action.payload.data;
      state.isLoading = false;
    },
    [deleteStageAction.fulfilled]: (state, action) => {
      const index = state.stages.findIndex(
        (stage) => stage.id == action.payload.data.id
      );
      state.stages.splice(index, 1);
    },
  },
});

export default stagesSlice.reducer;
