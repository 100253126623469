import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getEmploymentTypes } from "api/candidate/profileApi";

export const setEmploymentTypes = createAsyncThunk(
  "employmentTypes/setEmploymentTypes",
  async () => {
    const response = await getEmploymentTypes();
    return response.data;
  }
);

export const employmentTypesSlice = createSlice({
  name: "employmentTypes",
  initialState: {
    employmentTypes: undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setEmploymentTypes.fulfilled, (state, action) => {
      state.employmentTypes = action.payload;
    });
  },
});

export default employmentTypesSlice.reducer;
