import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { parseISO } from "date-fns";
import { orderBy, startCase } from "lodash";

import { getGroupDiscussions, getInterviews } from "api/candidate/candidateApi";
import { getExams } from "api/candidate/examApi";
import { EVENT_TYPES } from "app/constants";
import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import { TabList } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import { DEFAULT_PAGE_SIZE, SITE_TITLE } from "constants/common";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import EventList from "./EventList";

const ExamPage = () => {
  const [currentUser] = useSelector(({ auth }) => [auth.currentUser]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(EVENT_TYPES[0].key);
  const [eventList, setEventList] = useState({
    data: [],
    meta: undefined,
    isLoading: true,
  });
  const page = parseInt(searchParams.get("page"), 10) || 1;
  const pageSize =
    parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE;
  const tab = searchParams.get("tab") || currentTab;
  const dispatch = useDispatch();

  const getAllEvents = async ({ status, type }) => {
    let totalPages = 1;
    const otherPageResults = [];
    const fetchMethods = {
      exam: getExams,
      interview: getInterviews,
      gd: getGroupDiscussions,
    };

    const firstPageResults = await fetchMethods[type]({
      status,
      page: 1,
      page_size: DEFAULT_PAGE_SIZE,
    }).then((value) => {
      totalPages = value.meta.total_pages;
      return value.data;
    });

    if (totalPages <= 1) {
      return firstPageResults;
    }

    for (let i = 2; i <= totalPages; i += 1) {
      otherPageResults.push(
        fetchMethods[type]({
          status,
          page: i,
          page_size: DEFAULT_PAGE_SIZE,
        }).then((value) => value.data)
      );
    }

    return [...firstPageResults, ...(await Promise.all(otherPageResults))[0]];
  };

  const getEventList = async () => {
    await Promise.all([
      getAllEvents({ status: tab, type: "exam" }),
      getAllEvents({ status: tab, type: "interview" }),
      getAllEvents({ status: tab, type: "gd" }),
    ]).then((values) => {
      const allEvents = orderBy(
        values.flat(),
        (event) => parseISO(event.start_at),
        ["desc"]
      );

      setEventList({
        data: allEvents,
        isLoading: false,
      });
    });
  };

  useEffect(() => {
    hideFreshWorkWidget();
    dispatch(
      updateHeaderTitle({ breadCrumbDetails: { currentPageName: "Events" } })
    );
  }, []);

  useEffect(() => {
    setEventList({ data: [], meta: undefined, isLoading: true });
    getEventList();
  }, [tab]);

  return (
    <div className="h-full w-full px-2 lg:px-4">
      <Helmet>
        <title>{`${startCase(tab)} Events - ${SITE_TITLE}`}</title>
      </Helmet>
      <div className="sticky top-[45px] z-10 bg-gray-lighterShade3">
        <TabList
          testId="exam-tabs"
          tabs={EVENT_TYPES}
          currentTab={tab}
          paramBased
          onTabClick={(key) => {
            setCurrentTab(key);
            setSearchParams({ tab: key, page, page_size: pageSize });
          }}
        />
      </div>

      {eventList.isLoading && <ActivityIndicator />}

      {!!eventList.data?.length && (
        <div className="flex flex-col py-2 lg:flex-row">
          <EventList
            currentTab={tab}
            events={eventList.data}
            currentUser={currentUser?.data}
          />
        </div>
      )}

      {!eventList.isLoading && eventList.data?.length === 0 && (
        <div className="pt-16 text-center">
          <img src={NoDataFound} alt="No Data Found" className="m-auto" />
        </div>
      )}
    </div>
  );
};

export default ExamPage;
