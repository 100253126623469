import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { Modal } from "components/molecules";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import {
  createSectionAction,
  getSectionAction,
  updateSectionNameAction,
} from "store/thunkActions/erp/sectionsThunk";

const SectionModal = ({ setIsEditState, sectionName, sectionId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  useEffect(() => reset({ name: sectionName }), [sectionName]);

  const submitHandler = (data) => {
    const updatedData = { ...data, question_paper_id: params.id };

    return dispatch(createSectionAction(updatedData))
      .unwrap()
      .then((data) => {
        setIsEditState(false);
        dispatch(getSectionAction(params.id));
        navigate(
          `/questions_manager/question_paper/${params.id}?tab=${data.id}`
        );
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  const updateSectionName = (data) => {
    const updatedData = { ...data, id: sectionId };

    return dispatch(updateSectionNameAction(updatedData))
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(getSectionAction(params.id));
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };
  return (
    <Modal
      title={`${sectionId ? "Edit" : "Add"} Section Name`}
      testId="add-edit-section-modal"
      acceptButtonText={sectionId ? "Update" : "Create"}
      acceptBtnType={isDirty ? "primary" : "disabled"}
      handleAccept={handleSubmit(sectionId ? updateSectionName : submitHandler)}
      handleCancel={() => setIsEditState(false)}
      isDirty={isDirty}
    >
      <form
        data-testid="section-body"
        className="my-2"
        onSubmit={handleSubmit(sectionId ? updateSectionName : submitHandler)}
      >
        <label
          htmlFor="section"
          className="required mt-2 mb-1 block font-medium"
        >
          Section
        </label>
        <input
          id="name"
          className={`${errors.name ? "input-error" : "input"} mb-12 w-full`}
          type="text"
          placeholder="Enter Section name"
          {...register("name", {
            required: requiredValidation(),
            maxLength: {
              value: 100,
              message: characterValidation({ limit: 100 }),
            },
          })}
        />
        {errors.name && (
          <p className="text-sm text-danger-dark">{errors.name.message}</p>
        )}
      </form>
    </Modal>
  );
};

SectionModal.propTypes = {
  questionPaper: PropTypes.shape({
    name: PropTypes.string,
    question_paper_type: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  sectionName: PropTypes.string,
  sectionId: PropTypes.number,
};

export default SectionModal;
