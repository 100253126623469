import api, { candidateApi } from "api/axios";

export const listJobs = async (page, status) => {
  const response = await candidateApi.get("/jobs", {
    params: { page, status },
  });
  return response;
};

export const getJobDetail = async (uuid) => {
  const response = await candidateApi.get(`/jobs/${uuid}`);
  return response;
};

// get restricted job with token api
export const getRestrictedJobDetailWithToken = async (uuid, token) => {
  const response = await candidateApi.get(`/jobs/${uuid}/apply_now/${token}`);
  return response;
};

export const saveJob = async (uuid) => {
  const response = await candidateApi.post(`jobs/${uuid}/save`);
  return response;
};

export const unsaveJob = async (uuid) => {
  const response = await candidateApi.delete(`jobs/${uuid}/unsave`);
  return response;
};

// Candidate apply job
export const jobApply = async (jobUuid) => {
  const response = await candidateApi.post(`/jobs/${jobUuid}/apply`);
  return response;
};

// Candidate LOI
export const loiDescription = async (jobUuid) => {
  const response = await candidateApi.get(`/jobs/${jobUuid}/loi`);
  return response;
};

export const loiConfirmation = async (jobUuid, status) => {
  const response = await candidateApi.post(`/jobs/${jobUuid}/loi`, status);
  return response;
};

// Job organisation
export const getJobOrganisation = async (jobUuid) => {
  const response = await api.get(`/organisations/${jobUuid}`);
  return response;
};

export const applicationTimeline = async (jobUuid) => {
  const response = await candidateApi.get(
    `/jobs/${jobUuid}/application_timeline`
  );
  return response;
};
