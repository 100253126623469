import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { IoArchiveSharp } from "react-icons/io5";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { ALERT_TYPE } from "app/constants";
import { EmptyMessage } from "components/atoms";
import Pill from "components/atoms/Pill";
import ActivityIndicator from "components/common/ActivityIndicator";
import Pagination from "components/common/Pagination";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import { Modal } from "components/molecules";
import { DEFAULT_PAGE_SIZE, PER_PAGE_RECORD_LIMIT } from "constants/common";
import { SearchParamsFinder } from "helpers/SearchParamsFinder";
import useAlert from "hooks/useAlert";
import {
  getQuestionPapersAction,
  updateQuestionPaperNameAction,
} from "store/thunkActions/erp/questionsThunk";

import QuestionPaperModal from "./QuestionPaperModal";

const QuestionPapersIndex = ({
  currentPage,
  setCurrentPage,
  showQPImportAlert,
  setIsLoading,
}) => {
  const [currentUser, data, meta] = useSelector(({ auth, questionPapers }) => [
    auth.currentUser,
    questionPapers.data,
    questionPapers.meta,
  ]);

  const dispatch = useDispatch();
  const showAlert = useAlert();
  const useFormProps = useForm();

  const pageNumber = sessionStorage.getItem("questionsPage") || currentPage;
  const pageSize =
    sessionStorage.getItem("questionsPageSize") || DEFAULT_PAGE_SIZE;

  const [searchParams, setSearchParams] = useSearchParams();
  const [isQuestionPaperAdding, setIsQuestionPaperAdding] = useState(false);
  const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState(false);
  const [selectedQuestionPaper, setSelectedQuestionPaper] = useState();

  const QUESTION_PAPER_FILTER = [
    {
      label: "Question Paper Type",
      placeholder: "question paper type",
      name: "type",
      multiSelect: false,
      dropdownOptions: [
        { label: "MCQ", value: "mcq" },
        { label: "Coding", value: "coding" },
      ],
    },
    {
      label: "Section Type",
      placeholder: "section type",
      name: "sectional",
      multiSelect: false,
      dropdownOptions: [
        { label: "Sectional", value: "true" },
        { label: "Sectionless", value: "false" },
      ],
    },
    {
      label: "Question Paper Status",
      placeholder: "question paper status",
      name: "active",
      multiSelect: false,
      dropdownOptions: [
        { label: "Active", value: "true" },
        { label: "Archived", value: "false" },
      ],
    },
  ];

  const storePosition = (e) => {
    const position = e.target.getBoundingClientRect();
    sessionStorage.setItem(
      `page:${currentPage}:PositionX`,
      window.pageXOffset + position.x
    );
    sessionStorage.setItem(`page:${currentPage}:PositionY`, window.pageYOffset);
  };

  if (parseInt(searchParams.get("page"), 10) > meta?.total_pages) {
    setSearchParams({
      page: meta?.page_number,
      page_size: meta?.page_size,
      tab: searchParams.get("tab"),
    });
  }

  if (
    /[a-z]/gi.test(searchParams.get("page")) ||
    /[a-z]/gi.test(searchParams.get("page_size"))
  ) {
    setSearchParams({
      page:
        parseInt(SearchParamsFinder(searchParams, "page"), 10) ||
        meta?.page_number ||
        1,
      page_size:
        parseInt(SearchParamsFinder(searchParams, "page_size"), 10) ||
        meta?.page_size ||
        DEFAULT_PAGE_SIZE,
      tab: searchParams.get("tab"),
    });
  }

  const resetFilterForm = () => {
    useFormProps.reset({
      type: searchParams.get("type")
        ? QUESTION_PAPER_FILTER[0].dropdownOptions.find(
            (type) => type.value === searchParams.get("type")
          )
        : undefined,

      sectional: searchParams.has("sectional")
        ? QUESTION_PAPER_FILTER[1].dropdownOptions.find(
            (sectional) => sectional.value === searchParams.get("sectional")
          )
        : undefined,
      active: searchParams.has("active")
        ? QUESTION_PAPER_FILTER[2].dropdownOptions.find(
            (active) => active.value === searchParams.get("active")
          )
        : undefined,
    });
  };

  const getQuestionPapersData = () => {
    dispatch(
      getQuestionPapersAction({
        page: searchParams.get("page"),
        page_size: searchParams.get("page_size"),
        sectional: searchParams.get("sectional"),
        type: searchParams.get("type"),
        active: searchParams.get("active"),
        search_term: searchParams.get("search_term"),
      })
    ).then(() => {
      setIsLoading(false);
      resetFilterForm();
    });
  };

  useEffect(() => {
    const positionX = sessionStorage.getItem(`page:${pageNumber}:PositionX`);
    const positionY = sessionStorage.getItem(`page:${pageNumber}:PositionY`);

    if (pageNumber) {
      setCurrentPage(pageNumber);
    }

    window.scrollTo({ top: positionY, left: positionX, behavior: "smooth" });
  }, [pageNumber, pageSize]);

  useEffect(() => {
    getQuestionPapersData();
  }, [searchParams]);

  const onSetCurrentPage = (
    page,
    pageSizeValue = parseInt(searchParams.get("page_size"), 10) ||
      DEFAULT_PAGE_SIZE
  ) => {
    const currentSearchParams = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...currentSearchParams, page, page_size: pageSizeValue });
    sessionStorage.setItem("questionsPage", page);
    sessionStorage.setItem("questionsPageSize", pageSizeValue);
  };

  const anyFilterApplied = () =>
    searchParams.has("type") ||
    searchParams.has("sectional") ||
    searchParams.has("active");

  const generateFilterCriterias = () => {
    const filterCriterias = [];

    if (searchParams.has("type")) {
      filterCriterias.push("Question Paper Type");
    }
    if (searchParams.has("sectional")) {
      filterCriterias.push("Section Type");
    }
    if (searchParams.has("active")) {
      filterCriterias.push("Question Paper Status");
    }

    return filterCriterias.join(" | ");
  };

  const handleArchiveQuestionPaper = async ({ type, questionPaper }) => {
    const archiveQuestionPaperData =
      type === "archive"
        ? { ...selectedQuestionPaper, active: false }
        : { ...questionPaper, active: true };

    return dispatch(updateQuestionPaperNameAction(archiveQuestionPaperData))
      .unwrap()
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        if (type === "archive") {
          setShowArchiveConfirmModal(false);
        }

        getQuestionPapersData();
      })
      .catch((error) => {
        showAlert(ALERT_TYPE[error.meta.message_type], error.meta.message);
      });
  };

  if (!currentUser || !data) {
    return <ActivityIndicator />;
  }

  return (
    <div>
      <div
        className={classNames(
          "py-4 sticky z-10 bg-gray-lighterShade3 flex gap-4 min-h-[50px] items-start justify-between",

          showQPImportAlert ? "top-[230px] sm:top-[175px]" : "top-[94px]"
        )}
      >
        <div>
          <div className="sm:max-w-[450px]">
            <SearchBar
              anyFilterApplied={anyFilterApplied}
              searchParams={searchParams}
              resetFilterForm={resetFilterForm}
              useFormProps={useFormProps}
              setSearchParams={setSearchParams}
              filterField={QUESTION_PAPER_FILTER}
              searchBarPlaceholder="Search by question paper name"
              defaultSearchParams={{
                tab: searchParams.get("tab"),
                page: pageNumber,
                page_size: pageSize,
              }}
            />
          </div>
          {anyFilterApplied() && (
            <p className="my-3 text-sm text-primary-main align-top">
              <span className="mr-1"> Filtered by: </span>

              <span>{generateFilterCriterias()}</span>
            </p>
          )}
        </div>
        <button
          title="Create Question Paper"
          data-testid="add-question-paper-btn"
          className="z-10 items-center rounded-full bg-primary-main p-2 hover:bg-primary-dark"
          onClick={() => setIsQuestionPaperAdding(true)}
        >
          <AiOutlinePlus className="text-2xl text-white" />
        </button>

        {isQuestionPaperAdding && (
          <QuestionPaperModal
            setIsEditState={setIsQuestionPaperAdding}
            isQuestionPaperAdding={isQuestionPaperAdding}
          />
        )}
      </div>
      {data?.length === 0 && <EmptyMessage message="" />}

      {!!data.length && (
        <div className="overflow-hidden py-3">
          <div className="w-full text-sm">
            <div className="py-2">
              {data.map((questionPaper) => {
                return (
                  <div
                    className="flex flex-row justify-between my-1 border-b-2 bg-white px-3 py-4 align-bottom hover:bg-gray-lighter"
                    key={questionPaper.id}
                  >
                    <Link
                      to={`/questions_manager/question_paper/${questionPaper.id}`}
                      className="flex flex-col"
                      data-testid={`question-paper-id-${questionPaper.id}`}
                      onClick={storePosition}
                    >
                      <div className="text-base text-black-shade1 flex flex-grow">
                        <span className="mr-1 pr-1 font-bold">
                          {questionPaper.name}
                        </span>

                        <div className="mx-2 h-fit min-w-fit items-center rounded-md bg-gray-lighterShade2 px-2 py-1 text-xs font-medium uppercase text-black-shade1 xl:ml-4">
                          {questionPaper.question_paper_type}
                        </div>

                        {!questionPaper.active && (
                          <div className="pl-2">
                            <Pill text="Archived" type="info" />
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="p-2 text-sm text-gray-main">
                          {questionPaper.sectional
                            ? `${questionPaper.total_sections} Sections | `
                            : ""}
                          {questionPaper.total_questions} Questions
                        </div>

                        <div className="pl-2 text-sm text-gray-main">
                          <span className="font-medium">Created By :</span>{" "}
                          {questionPaper.created_by},{" "}
                          {questionPaper.created_at
                            ? format(
                                new Date(questionPaper.created_at),
                                "dd/MM/yyyy 'at' h:mm a"
                              )
                            : ""}
                        </div>
                      </div>
                    </Link>
                    <div className="mx-4 sm:mx-8">
                      {questionPaper.active ? (
                        <button
                          disabled={questionPaper["has_exam?"]}
                          data-testid="archive-question-paper-btn"
                          onClick={() => {
                            setSelectedQuestionPaper(questionPaper);
                            setShowArchiveConfirmModal(true);
                          }}
                          className={classNames(
                            "cursor-pointer btn-icon-dark-gray",
                            { "opacity-30": questionPaper["has_exam?"] }
                          )}
                        >
                          <IoArchiveSharp
                            className="text-xl text-primary-main disabled:text-primary-lighterShade1"
                            data-tooltip-id="archiveQuestionPaper"
                            data-tooltip-content={
                              questionPaper["has_exam?"]
                                ? "You can't delete this question paper, as it is associated with candidate answer(s)."
                                : "Archive question paper"
                            }
                          />
                        </button>
                      ) : (
                        <button
                          data-testid="unarchive-question-paper-btn"
                          onClick={() => {
                            handleArchiveQuestionPaper({
                              type: "unarchive",
                              questionPaper,
                            });
                          }}
                          className="cursor-pointer btn-icon-dark-gray"
                        >
                          <RiInboxUnarchiveFill
                            className="text-xl text-primary-main"
                            data-tooltip-id="unArchiveQuestionPaper"
                            data-tooltip-content="Unarchive question paper"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {showArchiveConfirmModal && (
        <Modal
          title="Are you sure?"
          description="Are you sure you want to archive this question paper?"
          acceptButtonText="Yes"
          rejectButtonText="No"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          testId="confirm-question-paper-archive-modal"
          handleAccept={() =>
            handleArchiveQuestionPaper({
              type: "archive",
              questionPaper: {},
            })
          }
          handleReject={() => setShowArchiveConfirmModal(false)}
        />
      )}

      {meta?.total_entries > PER_PAGE_RECORD_LIMIT && (
        <Pagination
          currentPage={meta?.page_number || 1}
          totalPage={meta?.total_pages}
          setCurrentPage={onSetCurrentPage}
          pageName="questions"
          totalEntries={meta?.total_entries}
          pageSize={
            parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE
          }
        />
      )}
      <Tooltip id="archiveQuestionPaper" variant="dark" className="text-xs" />
      <Tooltip id="unArchiveQuestionPaper" variant="dark" className="text-xs" />
    </div>
  );
};

QuestionPapersIndex.propTypes = {
  setCurrentPage: PropTypes.func,
  setIsLoading: PropTypes.func,
  currentPage: PropTypes.number,
  showQPImportAlert: PropTypes.bool,
};

QuestionPapersIndex.defaultProps = {
  setCurrentPage: () => {},
  setIsLoading: () => {},
  showQPImportAlert: false,
  currentPage: 1,
};

export default QuestionPapersIndex;
