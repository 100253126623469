import { candidateApi, examApi } from "api/axios";

// exams
export async function getExamCandidateExam() {
  const response = await examApi.get("/exam_candidates/exam");
  return response;
}

export async function getNextQuestion(id) {
  const response = await candidateApi.get("/next_question", {
    params: { id },
  });
  return response;
}

export async function finishQuestion(candidateAnswerId) {
  const response = await candidateApi.patch(
    `/finish_question?candidate_answer_id=${candidateAnswerId}`
  );
  return response;
}

export async function updateCandidateAnswers(candidateAnswerId, answerId) {
  const response = await candidateApi.patch(
    `/candidate_answers/${candidateAnswerId}`,
    {
      answer_id: answerId || null,
    }
  );
  return response;
}

export async function finishExam() {
  const response = await examApi.patch("/exam_candidates/finish_exam");
  return response;
}

export async function fetchCandidateAnswers() {
  const response = await examApi.get(`/candidate_answers`);
  return response;
}

export async function updateCodingAnswer(data) {
  const response = await examApi.post("/coding_answers", data);
  return response;
}

export async function evaluateCodingAnswer(data) {
  const response = await examApi.post("/evaluate_code", data);
  return response;
}

// Interview API
export async function getInterviews(params) {
  const response = await candidateApi.get("/interviews", {
    params,
  });
  return response;
}

export async function getGroupDiscussions(params) {
  const response = await candidateApi.get("/group_discussions", {
    params,
  });
  return response;
}

export const createSubscription = async (data) => {
  const response = await candidateApi.post(`/web_subscriptions`, data);
  return response;
};

export async function getPresignedURL(id, filename) {
  const response = await candidateApi.get("/avatar_presigned_url", {
    params: { id, filename },
  });
  return response;
}

export const getFaceEmbeddings = async () => {
  const response = await candidateApi.get(`/face_embedding`);
  return response;
};
