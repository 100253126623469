import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { checkAppEnvs, getCurrentSubdomain } from "helpers/utils";

const BreadCrumb = () => {
  const headerTitle = useSelector((state) => state.miscellaneous.headerTitle);
  const subdomain = getCurrentSubdomain();
  const { intermediateLinkList, currentPageName, breadCrumbColor } =
    headerTitle;

  const shouldSlice = (str) => str?.length > 20;
  const sliceWithEllipsis = (str) => `${str.slice(0, 17)}...`;

  return (
    <div className="container px-2">
      {intermediateLinkList?.map((item) => (
        <span key={item.name}>
          {item.link ? (
            <Link
              className="text-primary-light hover:underline"
              to={item.link}
              title={item.name}
            >
              {shouldSlice(item.name)
                ? sliceWithEllipsis(item.name)
                : item.name}
            </Link>
          ) : (
            <span>{item.name}</span>
          )}
          &nbsp; &gt; &nbsp;
        </span>
      ))}
      <span
        className={`${breadCrumbColor} ${
          checkAppEnvs(subdomain) ? "font-bold" : ""
        }`}
      >
        {shouldSlice(currentPageName)
          ? sliceWithEllipsis(currentPageName)
          : currentPageName}
      </span>
    </div>
  );
};

export default BreadCrumb;
