import { useEffect, useState } from "react";
import { BsExclamationCircleFill, BsXLg } from "react-icons/bs";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Button, RenderWithPortal } from "components/atoms";
import { MODAL_TYPE } from "constants/common";
import useEvent from "hooks/useEvent";
import useOnClick from "hooks/useOnClick";

const OVERLAY_CLASS_NAME = "modal-overlay";
const CUSTOM_EVENT_NAME = "modal:trigger";

const Modal = ({
  warningIcon,
  title,
  description,
  acceptButtonText,
  rejectButtonText,
  handleAccept,
  handleReject,
  handleCancel,
  isDefaultScrolling,
  children,
  childrenClass,
  scrollClass,
  onOutsideClickHandler,
  acceptBtnType,
  rejectBtnType,
  customModalWidth,
  customButtonWidth,
  isAsyncClick,
  testId,
  modalType,
  isDirty,
  rejectButtonClassName,
  hasSideJobButton,
  sideJobButtonProps,
}) => {
  const { on, off } = useEvent();
  const [confirmModal, setConfirmModal] = useState(false);

  useOnClick(CUSTOM_EVENT_NAME);

  switch (modalType) {
    case MODAL_TYPE.confirm:
      title = title || "Are you sure?";
      acceptButtonText = acceptButtonText || "OK, Delete";
      rejectButtonText = rejectButtonText || "Cancel";
      acceptBtnType = acceptBtnType || "primary";
      rejectBtnType = rejectBtnType || "secondary";
      break;

    case MODAL_TYPE.form:
      title = title || "Form";
      acceptButtonText = acceptButtonText || "Submit";
      acceptBtnType = acceptBtnType || "primary";
      break;

    case MODAL_TYPE.warning:
      title = title || "Warning";
      rejectButtonText = rejectButtonText || "OK, I understand";
      rejectBtnType = rejectBtnType || "warning";
      break;

    case MODAL_TYPE.message:
      title = title || "Message";
      acceptButtonText = acceptButtonText || "OK";
      acceptBtnType = acceptBtnType || "primary";
      break;

    default:
  }

  const outsideClickHandler = (event) => {
    if (event.detail.clickEvent.classList.contains(OVERLAY_CLASS_NAME)) {
      onOutsideClickHandler();
    }
  };

  const handleCancelWithConfirm = () => {
    if (isDirty) {
      setConfirmModal(true);
    } else {
      handleCancel();
    }
  };

  useEffect(() => {
    on(CUSTOM_EVENT_NAME, outsideClickHandler);

    return () => off(CUSTOM_EVENT_NAME, outsideClickHandler);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [confirmModal]);

  const confirmationForFormModal = (
    <Modal
      modalType="confirm"
      acceptButtonText="No"
      rejectButtonText="Yes"
      handleAccept={() => {
        setConfirmModal(false);
      }}
      handleReject={handleCancel}
    >
      Your changes will be lost. Are you sure you want to proceed?
    </Modal>
  );

  return (
    <RenderWithPortal>
      <div
        className={classNames(
          `${OVERLAY_CLASS_NAME} fixed inset-0 z-30 flex h-full w-full items-center justify-center bg-gray-light bg-opacity-50 px-4 lg:pl-36`,
          { "overflow-y-auto": isDefaultScrolling }
        )}
      >
        {confirmModal && confirmationForFormModal}
        <div
          style={{ width: customModalWidth ? `${customModalWidth}` : "500px" }}
          className={classNames("overflow-hidden rounded bg-white shadow-5xl")}
          data-testid={testId}
          id="genericModal"
        >
          {title && (
            <div className="flex items-center sm:justify-between border-b p-4">
              <h3
                className="flex items-center justify-center gap-2 text-xl font-medium leading-6 text-black-shade1 sm:m-0 sm:justify-start"
                data-testid="modal-title"
              >
                {warningIcon && (
                  <BsExclamationCircleFill
                    size={32}
                    className="h-8 w-8 text-warning-main sm:h-12 sm:w-12 md:h-auto md:w-auto"
                  />
                )}
                {title}
              </h3>
              {handleCancel && (
                <BsXLg
                  title="Close"
                  data-testid="modal-cancel-btn"
                  role="button"
                  className="ml-auto mr-4 hover:cursor-pointer sm:m-0"
                  onClick={handleCancelWithConfirm}
                />
              )}
            </div>
          )}

          <div className={classNames(childrenClass, scrollClass)}>
            {children || <div> {description}</div>}
          </div>

          {(typeof handleAccept === "function" ||
            typeof handleReject === "function") && (
            <div className="flex justify-between px-4 py-3 sm:px-6 bg-gray-lighterShade1">
              <div>
                {hasSideJobButton && (
                  <Button
                    title={sideJobButtonProps.title}
                    testId={sideJobButtonProps.testId}
                    onClick={sideJobButtonProps.onClick}
                    type={sideJobButtonProps.type}
                    size="md"
                    customWidth={customButtonWidth}
                    btnName={sideJobButtonProps.btnName}
                    isAsyncClick={sideJobButtonProps.isAsyncClick}
                  />
                )}
              </div>
              <div className="flex gap-2">
                {handleReject && (
                  <Button
                    title={rejectButtonText}
                    testId="reject-action-btn"
                    onClick={handleReject}
                    size="md"
                    customWidth={customButtonWidth}
                    type={rejectBtnType}
                    btnName={rejectButtonText}
                    btnClassName={rejectButtonClassName}
                  />
                )}

                {handleAccept && (
                  <Button
                    title={acceptButtonText}
                    testId="accept-action-btn"
                    onClick={handleAccept}
                    type={acceptBtnType}
                    size="md"
                    customWidth={customButtonWidth}
                    btnName={acceptButtonText}
                    isAsyncClick={isAsyncClick}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </RenderWithPortal>
  );
};

Modal.defaultProps = {
  title: "",
  description: "",
  onOutsideClickHandler: () => null,
  customButtonWidth: 28,
  isAsyncClick: true,
  testId: "generic-modal",
  isDirty: false,
  childrenClass: "p-4 text-gray-darker",
  isDefaultScrolling: true,
  hasSideJobButton: false,
};

Modal.propTypes = {
  warningIcon: PropTypes.bool,
  isDefaultScrolling: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  acceptButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  handleAccept: PropTypes.func,
  handleReject: PropTypes.func,
  handleCancel: PropTypes.func,
  children: PropTypes.node,
  childrenClass: PropTypes.string,
  scrollClass: PropTypes.string,
  onOutsideClickHandler: PropTypes.func,
  acceptBtnType: PropTypes.string,
  rejectBtnType: PropTypes.string,
  customModalWidth: PropTypes.string,
  customButtonWidth: PropTypes.number,
  isAsyncClick: PropTypes.bool,
  testId: PropTypes.string,
  modalType: PropTypes.string,
  rejectButtonClassName: PropTypes.string,
  isDirty: PropTypes.bool,
  hasSideJobButton: PropTypes.bool,
  sideJobButtonProps: PropTypes.shape({
    title: PropTypes.string,
    testId: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    btnName: PropTypes.string,
    isAsyncClick: PropTypes.bool,
  }),
};

export default Modal;
