import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";

const Projects = ({ project }) => {
  const getProjectStatus = () => {
    if (project.is_ongoing) {
      return "Ongoing";
    }
    if (project.end_at) {
      return moment(project.end_at).format("MMMM YYYY");
    }
    return "";
  };

  return (
    <>
      <div className="flex items-start justify-between">
        <h4 className="pt-2 pr-2 text-lg font-medium leading-5">
          <a
            title="Project"
            target="_blank"
            rel="noreferrer"
            className={classNames(
              project.project_url && "text-primary-light hover:underline"
            )}
            href={project.project_url}
          >
            {project.title}
          </a>
        </h4>
      </div>

      <p className="text-md">
        <RenderParsedHTML content={project.description || ""} />
      </p>

      <p className="text-sm italic text-gray-main">
        {project.start_at ? moment(project.start_at).format("MMMM YYYY") : ""} -{" "}
        {getProjectStatus()}
      </p>
    </>
  );
};

Projects.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    is_ongoing: PropTypes.bool,
    project_url: PropTypes.string,
  }),
};

export default Projects;
