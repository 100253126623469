import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { CgCheck } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  addQuestionBankQuestions,
  getCategories,
  getQuestionPaperQuestionsBySection,
  showQuestion,
} from "api/erp/questionPaperApi";
import { ALERT_TYPE } from "app/constants";
import { Button, CardView, LabeledInput } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Pill from "components/common/Pill";
import { Suggestionbox } from "components/molecules";
import useAlert from "hooks/useAlert";
import { getQuestionBankAction } from "store/thunkActions/erp/questionBankThunk";
import { getQuestionTagAction } from "store/thunkActions/erp/questionTagThunk";

const QuestionPaperSuggestionBox = ({
  setIsEditState,
  questionPaperType,
  sectionId,
  questionPaperId,
  setQuestions,
  sectionQuestions,
  setQuestionAdded,
}) => {
  const { control } = useForm();

  const { data } = useSelector((state) => state.questionTag);

  const dispatch = useDispatch();
  const showAlert = useAlert();

  const [filteredQuestions, setFilteredQuestions] = useState(null);
  const [selectedTags, setSelectedTags] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryList, setCategoryList] = useState();
  const [viewQuestionDetail, setViewQuestionDetail] = useState();
  const [rowIndex, setRowIndex] = useState();

  const isQuestionAdded = (id) => {
    let sectionQuestionCount = 0;
    sectionQuestions.map((sectionQuestion) => {
      if (sectionQuestion.question_id == id) {
        sectionQuestionCount += 1;
      }
    });
    return sectionQuestionCount > 0;
  };

  const getQuestionBank = async (page, type, category, tagIds) =>
    dispatch(getQuestionBankAction({ page, type, category, tag_ids: tagIds }))
      .unwrap()
      .then(({ data }) => {
        if (selectedCategory || selectedTags?.length > 0) {
          setFilteredQuestions(data);
        }
      });

  const handleCategoryStatusChange = (selectedOptions) => {
    setSelectedCategory(selectedOptions?.name);
  };

  const handleTagStatusChange = (selectedOptions) => {
    const tags = selectedOptions.map((item) => item.id);
    setSelectedTags(tags);
  };

  const setCollapsQuestionData = (question) => setViewQuestionDetail(question);

  const addQuestionBtn = (data) => {
    const questionData = {
      question_id: data.id,
      question_paper_id: questionPaperId,
      section_id: sectionId,
    };
    addQuestionBankQuestions(questionData)
      .then(async () => {
        getQuestionPaperQuestionsBySection(questionPaperId, sectionId).then(
          (response) => {
            setQuestions(response.data);
            setQuestionAdded(true);
          }
        );
      })
      .catch(({ meta, errors }) => {
        showAlert("danger", "Question already added in another section!");
        if (errors) {
          showAlert(ALERT_TYPE.DANGER, errors.question[0]);
        } else if (meta) {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
        }
      });
  };

  useEffect(() => {
    dispatch(getQuestionTagAction());
  }, []);

  useEffect(() => {
    getQuestionBank(1, questionPaperType, selectedCategory, selectedTags);
  }, [selectedCategory, selectedTags]);

  useEffect(() => {
    getCategories().then((response) => {
      setCategoryList(response.data);
    });
  }, []);

  return (
    <Suggestionbox
      title="Question Suggestions"
      scrollClass="max-h-screen"
      acceptButtonText="Done"
      acceptBtnType="primary"
      onOutsideClickHandler={() => setIsEditState(false)}
      handleAccept={() => setIsEditState(false)}
    >
      <form data-testid="SuggestionBox-body" className="my-4 mb-8">
        <div className="w-full flex-col">
          <label
            htmlFor="category"
            className={classNames("mt-4 mb-1 block font-medium")}
          >
            Categories
          </label>

          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Select
                name="category"
                placeholder="Select a category"
                options={categoryList}
                id="category"
                className="w-full"
                value={field.value}
                onChange={(selectedOptions) => {
                  field.onChange(selectedOptions);
                  handleCategoryStatusChange(selectedOptions);
                }}
                isClearable
                isSearchable
                menuPlacement="auto"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            )}
          />
        </div>

        <LabeledInput
          id="tags"
          labelClassNames={classNames("block mt-2 mb-1 font-medium")}
          label="Tags"
          errorMessageClassNames="text-sm text-danger-dark"
        />
        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <Select
              isClearable
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              maxMenuHeight={160}
              id="tags"
              name="tags"
              menuPlacement="auto"
              className="w-full"
              value={field.value ? field.value.name || field.value : null}
              options={data}
              onChange={(selectedOptions) => {
                field.onChange(selectedOptions);
                handleTagStatusChange(selectedOptions);
              }}
              debounceTimeout={1000}
              placeholder="Select tags"
              getOptionValue={(option) => option.id || option.label}
              getOptionLabel={(option) =>
                option.label || option.name || option.tag_name
              }
            />
          )}
        />
      </form>

      {/* eslint-disable-next-line no-nested-ternary */}
      {filteredQuestions == null ? (
        <div className="pt-4 text-center italic">
          {" "}
          Select a category and/or tag(s) to get suggestions.
        </div>
      ) : filteredQuestions?.length == 0 ? (
        <div className="pt-4 text-center italic">
          {" "}
          No matching suggestions.{" "}
        </div>
      ) : (
        <div className="flex flex-col overflow-y-auto">
          {filteredQuestions?.map((data, questionIndex) => {
            return (
              <div
                key={data.id}
                className="mb-2"
                data-testid={`question-id-${data.id}`}
              >
                <CardView>
                  <div className="flex justify-end items-center mt-2">
                    {viewQuestionDetail && rowIndex == questionIndex ? (
                      <Button
                        title="Collapse"
                        onClick={() => {
                          setRowIndex(undefined);
                          setViewQuestionDetail(undefined);
                        }}
                        btnClassName="p-2 mx-1 hover:bg-primary-lighter rounded-md"
                      >
                        <BsEyeSlashFill className="text-xl text-primary-main " />
                      </Button>
                    ) : (
                      <Button
                        title="Expand"
                        onClick={() => {
                          showQuestion(data?.id).then(({ data }) => {
                            setRowIndex(questionIndex);
                            setCollapsQuestionData(data);
                          });
                        }}
                        btnClassName="p-2 mx-1 hover:bg-primary-lighter rounded-md"
                      >
                        <BsEyeFill className="text-xl text-primary-main " />
                      </Button>
                    )}
                    <Button
                      title={isQuestionAdded(data.id) ? "Added" : "Add"}
                      btnClassName="h-6 pt-1 w-10 ml-2"
                      type={isQuestionAdded(data.id) ? "disabled" : "secondary"}
                      size="sm"
                      btnName={isQuestionAdded(data.id) ? "Added" : "Add"}
                      onClick={() => {
                        addQuestionBtn(data, questionIndex);
                      }}
                      testId="add-question-btn"
                    />
                  </div>

                  <div className="flex flex-col-reverse">
                    <div className="flex items-start justify-between gap-2 bg-white">
                      <div className="flex max-w-xl flex-col px-2 pt-2 md:max-w-full">
                        <div>
                          {" "}
                          <RenderParsedHTML content={data.text || ""} />
                        </div>
                        <div className="text-sm text-stone-500 mt-1">
                          Marks: {data.marks} | Duration: {data.duration}s
                        </div>
                      </div>
                    </div>
                    {viewQuestionDetail && questionIndex == rowIndex && (
                      <div className="ml-3 text-sm text-gray-600">
                        <div className="my-2 flex flex-col ">
                          {viewQuestionDetail.images?.map((img) => (
                            <div className="w-[32%]" key={img.id}>
                              <img
                                data-testid={img.attachment_file_name}
                                className="my-2 h-auto object-contain"
                                src={img.attachment}
                                alt=""
                                onError={(event) => {
                                  event.target.style.display = "none";
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="my-2 flex flex-col justify-between md:flex-row">
                          <div className="w-full flex-col md:w-5/12">
                            <span className="mb-1 block font-medium">
                              Question Type
                            </span>
                            <span className="uppercase">
                              {viewQuestionDetail.question_type}
                            </span>
                          </div>
                          <div className="w-full flex-col md:w-5/12">
                            <span className="mb-1 block font-medium">
                              Category
                            </span>
                            {viewQuestionDetail.category_name?.length ? (
                              <span>{viewQuestionDetail.category_name}</span>
                            ) : (
                              <span className="text-sm italic">
                                No categories found!
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="my-4">
                          <span className="block font-medium">
                            Question Tags
                          </span>
                          {viewQuestionDetail.tags?.length ? (
                            <span className="flex flex-col flex-wrap md:flex-row">
                              {viewQuestionDetail.tags.map((tag) => {
                                return (
                                  <Pill key={tag.tag_id} label={tag.tag_name} />
                                );
                              })}
                            </span>
                          ) : (
                            <span className="text-sm italic">
                              No tags found!
                            </span>
                          )}
                        </div>
                        <div className="my-4 flex flex-col justify-between md:flex-row">
                          <div className="w-full flex-col md:w-5/12">
                            <span className="mb-1 block font-medium">
                              Duration{" "}
                              <span className="mt-1 text-xs italic text-gray-main">
                                ( in seconds )
                              </span>
                            </span>
                            <span>{viewQuestionDetail.duration}</span>
                          </div>

                          <div className="w-full flex-col md:w-5/12">
                            <span className="mb-1 block font-medium">
                              Marks
                            </span>
                            <span>{viewQuestionDetail.marks}</span>
                          </div>
                        </div>
                        <div className="flex flex-col flex-wrap justify-between md:flex-row">
                          {viewQuestionDetail.answers?.map((item, index) => {
                            return (
                              <div key={item.id} className="w-full md:w-5/12">
                                <div>
                                  <span
                                    htmlFor={`option.${index}.text`}
                                    className="font-medium text-primary-main"
                                  >
                                    Option {index + 1}
                                  </span>
                                </div>

                                <RenderParsedHTML content={item.text || ""} />
                                <div className="flex justify-between">
                                  {item.attachment_file_name && (
                                    <img
                                      className="my-2 w-1/3 object-contain "
                                      src={item.attachment}
                                      alt={item.attachment_file_name}
                                    />
                                  )}
                                </div>

                                {item.correct_answer && (
                                  <div className="my-2 flex items-center">
                                    <button
                                      disabled
                                      className="mr-2 h-4 w-4 cursor-pointer rounded-sm border border-primary-main bg-primary-main bg-center pointer-events-none"
                                    >
                                      <CgCheck className="text-white" />
                                    </button>
                                    <span className="text-sm">Correct</span>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>

                        <div className="flex flex-col flex-wrap justify-between md:flex-row">
                          {viewQuestionDetail.driver_codes?.length !== 0 && (
                            <div className="w-full">
                              <div className="mt-5 font-medium">
                                Languages & Driver Codes
                              </div>
                              <div className="w-full">
                                <div className="flex flex-col flex-wrap justify-between md:flex-row">
                                  {viewQuestionDetail.driver_codes?.map(
                                    (item) => {
                                      return (
                                        <div
                                          key={item.id}
                                          className="w-full md:w-5/12"
                                        >
                                          <div className="py-2 align-top">
                                            <span className="mr-3.5 px-2 font-medium text-primary-main">
                                              {item.coding_language}
                                            </span>
                                          </div>
                                          <div className="rounded-sm border p-2">
                                            <span className="whitespace-pre-wrap text-sm">
                                              {item.code_string}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="mb-6 flex flex-col flex-wrap justify-between md:flex-row">
                          {viewQuestionDetail.test_cases?.length !== 0 && (
                            <div className="w-full">
                              <div className="mt-10 font-medium">
                                {" "}
                                Test Cases
                              </div>
                              <div className="mt-4 w-full">
                                <div className="flex flex-col flex-wrap justify-between md:flex-row">
                                  {viewQuestionDetail.test_cases?.map(
                                    (item) => {
                                      return (
                                        <div
                                          className="w-full md:w-5/12"
                                          key={item.id}
                                        >
                                          <div className="flex flex-col gap-1 py-2 align-top">
                                            <span className="font-medium">
                                              Input:
                                            </span>
                                            <span className="whitespace-pre-wrap break-words rounded-md border p-2">
                                              {item.input}
                                            </span>
                                          </div>
                                          <div className="flex flex-col gap-1 py-2 align-top">
                                            <span className="font-medium">
                                              Expected Output:
                                            </span>
                                            <span className="whitespace-pre-wrap break-words rounded-md border p-2">
                                              {item.output}
                                            </span>
                                          </div>
                                          <div className="flex gap-2 py-2 align-top">
                                            <span className="font-medium">
                                              Public:
                                            </span>
                                            {item.is_public ? (
                                              <span> Yes</span>
                                            ) : (
                                              <span> No</span>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </CardView>
              </div>
            );
          })}
        </div>
      )}
    </Suggestionbox>
  );
};

QuestionPaperSuggestionBox.propTypes = {
  questionPaperType: PropTypes.string,
  setIsEditState: PropTypes.func,
  sectionId: PropTypes.number,
  questionPaperId: PropTypes.number,
  setQuestions: PropTypes.func,
  setQuestionAdded: PropTypes.func,
  sectionQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      marks: PropTypes.string,
      duration: PropTypes.number,
      section_id: PropTypes.number,
      question_id: PropTypes.number,
    })
  ),
};

export default QuestionPaperSuggestionBox;
