import { differenceInSeconds, format } from "date-fns";
import addMinutes from "date-fns/addMinutes";

export const ERP_ENVS = ["erp", "erp-prod"];
export const APP_ENVS = ["app", "app-prod"];

export const NOT_MENTIONED = "Not Mentioned";
export const NOTIFY_EXAM = "You cannot notify an exam that has already ended.";
export const EDIT_EXAM = "You can not edit an exam which has already started.";
export const EXAM_WINDOW_END = "Exam's active window is over.";

export const LOGO_TYPE = "Please select only image (.jpg, .jpeg, .png) files.";
export const LOGO_SIZE = "Please select an image less than 5 MB.";
export const ATTACHMENT_SIZE = "Please select a file less than 5 MB.";
export const LOGO_UPLOAD_FAILED = "Upload failed. please try again.";
export const NAVBAR_HEIGHT_CUSTOM_EVENT = "navbar-height";
export const ATTACHMENT_TYPE = "Please select only PDF (.pdf) files.";
export const CUSTOM_ATTACHMENT_TYPE =
  "Please select only image (.jpg, .jpeg, .png) or PDF (.pdf) files.";
export const RECAPTCHA_LOGIN_FAILED_MESSAGE =
  "We are facing issues with login via email. Please try logging in via third-party options.";

export const RECAPTCHA_SIGNUP_FAILED_MESSAGE =
  "We are facing issues with sign-up via email. Please try signing up via the third-party options.";

export const UPLOAD_REF_IMAGE_MODAL_MESSAGE =
  "Please upload your reference photo for your identity verification";

export const REF_IMAGE_RESHOOT_MESSAGE =
  "To ensure the best possible results, we'd request that you re-shoot it.";

export const formatDateToUTC = (date, dateFormat = "yyyy-MM-dd HH:mm:ss") =>
  format(addMinutes(date, date.getTimezoneOffset()), dateFormat);

export const convertSecondsToTime = (time) => {
  const dateObj = new Date(time * 1000);
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  const timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return timeString;
};

export const SITE_TITLE =
  "JobAlley | End-to-end hiring platform for finding skilled talent";

export const PER_PAGE_RECORD_LIMIT = 20;

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_NUMBER = 1;

export const CUT_OFF_TYPES = [
  { label: "Sectional", value: "SECTIONAL" },
  { label: "Overall", value: "OVERALL" },
];

export const MARKS_TYPES = [
  { label: "Num", value: "NUMBER" },
  { label: "%", value: "PERCENTAGE" },
];

export const WILLING_TO_RELOCATE_TYPES = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const PORTFOLIO_URL_TYPES = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const CANDIDATE_ANSWER_STATUS = {
  COMPILATION_FAILED: "compilation failed",
  REQUEST_TIMED_OUT: "request timed out",
};

export const COMPILATION_FAILED_MESSAGE =
  "Test case evaluation unavailable. Compilation failed.";
export const UNATTEMPTED_MESSAGE =
  "Test case evaluation unavailable. The candidate has not attempted the coding question.";
export const TEST_CASES_UNAVAILABLE = "Test case evaluation unavailable.";

export const EXAM_VIOLATION_TYPES = {
  CAMERA_PERMISSION: "CAMERA_PERMISSION",
  UNAUTHORIZED_OBJECT: "UNAUTHORIZED_OBJECT",
  PERSON_COUNT: "PERSON_COUNT",
  FACE_MISMATCH: "FACE_MISMATCH",
  FULL_SCREEN: "FULL_SCREEN",
  TAB_SWITCH: "TAB_SWITCH",
};

export const USER_ROLES = {
  ADMIN: "ADMIN",
  HIRING_MANAGER: "HIRING_MANAGER",
  QUESTION_SETTER: "QUESTION_SETTER",
  INTERVIEWER: "INTERVIEWER_MODERATOR",
  EVALUATOR: "EVALUATOR",
};

export const MODAL_TYPE = {
  warning: "warning",
  confirm: "confirm",
  form: "form",
  message: "message",
};

export const examDuration = (startedAt, endedAt) => {
  if (startedAt && endedAt) {
    const startTime = new Date(startedAt);
    const endTime = new Date(endedAt);
    const diffInSec = differenceInSeconds(endTime, startTime);
    const hours = String(Math.floor(diffInSec / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((diffInSec % 3600) / 60)).padStart(
      2,
      "0"
    );
    const seconds = String(diffInSec % 60).padStart(2, "0");
    return (
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
  return "";
};

export const MANAGE_USER_ROLES = [
  { label: "Admin", value: "ADMIN" },
  { label: "Hiring Manager", value: "HIRING_MANAGER" },
  { label: "Question Setter", value: "QUESTION_SETTER" },
  { label: "Interviewer/Moderator", value: "INTERVIEWER_MODERATOR" },
  { label: "Evaluator", value: "EVALUATOR" },
];

export const MANAGE_USER_STATUS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];
