import { useEffect, useState } from "react";
import { BsGlobe2 } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { getJobOrganisation } from "api/candidate/jobApi";
import { ICON_SIZE_HASH } from "app/constants";
import { Avatar } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import SocialMediaIcon from "components/atoms/SocialMediaIcon";
import ActivityIndicator from "components/common/ActivityIndicator";
import { SITE_TITLE } from "constants/common";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

const OrganisationProfile = () => {
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState();
  const params = useParams();

  useEffect(() => {
    document.title = organisation
      ? `${organisation?.name} - ${SITE_TITLE}`
      : SITE_TITLE;
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: {
          currentPageName: organisation ? "Company Profile" : "",
        },
      })
    );
  }, [organisation]);

  const getOrganisation = () => {
    getJobOrganisation(params.id)
      .then((response) => {
        setOrganisation(response.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getOrganisation();
  }, []);

  if (!organisation) {
    return <ActivityIndicator />;
  }

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row border-solid border-b-2 border-primary-lighter mx-2 pb-5">
        {organisation.logo?.thumb ? (
          <div className="flex flex-col self-start">
            <div className="rounded-full h-32 w-32 ring-2 ring-primary-dark">
              <Avatar
                src={organisation.logo?.thumb}
                height={32}
                width={32}
                optionalClass="border -2"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col self-start ring-2 ring-primary-dark rounded-full ring-offset-4 w-28 h-28 bg-primary-lightest ml-3">
            <CharacterAvatar
              char={organisation.name?.split(" ")[0][0]}
              width={28}
              height={28}
            />
          </div>
        )}

        <div className="flex-grow mt-4 md:w-5/12 md:mt-0 md:ml-8">
          <div className="flex justify-between">
            <h3 className="flex items-center text-3xl font-normal">
              {organisation.name}
            </h3>
          </div>
          <div className="flex flex-row items-center">
            {organisation.website && (
              <div className="mt-3 mb-2">
                <a
                  title="Website"
                  href={organisation.website}
                  target="_blank"
                  className={classNames("text-sm text-gray-dark italic", {
                    "pointer-events-none": !organisation.website,
                  })}
                  rel="noreferrer"
                >
                  <BsGlobe2
                    className="inline mr-2 mb-1"
                    size={ICON_SIZE_HASH.sm}
                  />
                </a>
              </div>
            )}
            {organisation.linkedin_url && (
              <SocialMediaIcon
                title="Linkedin"
                link={organisation.linkedin_url}
                icon={FaLinkedin}
              />
            )}
            {organisation.twitter_url && (
              <SocialMediaIcon
                title="Twitter"
                link={organisation.twitter_url}
                icon={FaTwitter}
              />
            )}
            {organisation.instagram_url && (
              <SocialMediaIcon
                title="Instagram"
                link={organisation.instagram_url}
                icon={FaInstagram}
              />
            )}
            {organisation.facebook_url && (
              <SocialMediaIcon
                title="Facebook"
                link={organisation.facebook_url}
                icon={FaFacebook}
              />
            )}
          </div>

          <div className="pt-2 sm:w-5/6">
            <h4 className="mt-2 mb-1 font-medium"> About Us </h4>

            <span className="text-gray-main">
              <RenderParsedHTML content={organisation.about || ""} />
            </span>
          </div>
        </div>
      </div>

      <div className="mt-1 md:mr-6 md:ml-36">
        <div className="flex flex-col sm:flex-row sm:justify-between mx-2 md:mb-8">
          <div className="sm:w-5/12">
            <div className="py-2">
              <h4 className="mt-2 mb-1 font-medium">Company Type</h4>

              <span className="text-gray-main">
                {organisation.organisation_type || ""}
              </span>
            </div>

            <div className="py-2">
              <h4 className="mt-2 mb-1 font-medium"> Number of Employees </h4>

              <span className="text-gray-main">
                {organisation.employee_range || ""}
              </span>
            </div>
          </div>

          <div className="sm:w-5/12">
            <div className="py-2">
              <h4 className="mt-2 mb-1 font-medium"> Industry </h4>

              <span className="text-gray-main whitespace-pre-wrap">
                {organisation.industry_name || ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationProfile;
