import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getEducationQualificationsAction } from "store/thunkActions/candidate/educationQualificationThunk";

import CardContainer from "../Common/CardContainer";

import EducationQualification from "./EducationQualification";
import Form from "./Form";

const EducationQualifications = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { educationQualifications } = useSelector(
    (state) => state.educationQualifications
  );

  useEffect(() => {
    dispatch(getEducationQualificationsAction());
  }, []);

  return (
    <CardContainer
      title="Education"
      addEventButtonTitle="Add Education"
      addEventButtonTestId="add-education-btn"
      setIsAdding={setIsAdding}
    >
      {!educationQualifications.length && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on the plus icon to add education
        </span>
      )}
      {educationQualifications.map((educationQualification) => {
        return (
          <div
            key={educationQualification.id}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <EducationQualification
              educationQualification={educationQualification}
            />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsEditState={setIsAdding} setIsAdding={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default EducationQualifications;
