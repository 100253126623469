import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { subscribe } from "subscription/subscribe";

import { initializeAxios } from "api/axiosConfig";
import { getApiUrl } from "app/apiPaths";
import { TRACKING_ID, WEB_PUSH_PUBLIC_VAPID_KEY } from "app/constants";
import Candidate from "components/candidate";
import ERP from "components/erp";
import ScrollToTop from "helpers/scrollToTop";
import {
  checkAppEnvs,
  checkErpEnvs,
  getCurrentSubdomain,
  getDomainName,
} from "helpers/utils";

import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "./index.css";

ReactGA.initialize(TRACKING_ID);

initializeAxios(store);

const subdomain = getCurrentSubdomain();

function App() {
  if (checkAppEnvs(subdomain)) {
    return <Candidate />;
  }
  if (checkErpEnvs(subdomain)) {
    return <ERP />;
  }
}

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "job-alley@1.0.0",
    environment: "production",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      /^FreshworksWidget is not defined$/,
      /^Freshworks Widget is not mounted, mount first/,
      /^document.body.appendChild is not a function/,
      /^Request failed with status code 401$/,
      getApiUrl(),
    ],
  });
}

const root = createRoot(document.getElementById("root"));

root.render(
  <IntlProvider locale="en-IN">
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <Helmet>
            <title>
              JobAlley | End-to-end hiring platform for finding skilled talent
            </title>
            <meta
              name="description"
              content="An intelligent solution with integrated scheduling tools, inbuilt examination interface and auto job matching to make the hiring process effortless"
            />
            <meta property="og:image" content="/joballey_logo_dark_text.png" />
            <meta
              property="og:url"
              content={
                checkAppEnvs(subdomain)
                  ? `https://${subdomain}.${getDomainName()}`
                  : `https://erp.${getDomainName()}`
              }
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="JobAlley | End-to-end hiring platform for finding skilled talent"
            />
            <meta
              property="og:description"
              content="An intelligent solution with integrated scheduling tools, inbuilt examination interface and auto job matching to make the hiring process effortless"
            />
            <meta property="og:image" content="/joballey_logo_dark_text.png" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
              property="og:url"
              content={
                checkAppEnvs(subdomain)
                  ? `https://${subdomain}.${getDomainName()}`
                  : `https://erp.${getDomainName()}`
              }
            />
            <meta
              property="twitter:domain"
              content={
                checkAppEnvs(subdomain)
                  ? `app.${getDomainName()}`
                  : `erp.${getDomainName()}`
              }
            />
            <meta
              name="twitter:title"
              content="JobAlley | End-to-end hiring platform for finding skilled talent"
            />
            <meta
              name="twitter:description"
              content="An intelligent solution with integrated scheduling tools, inbuilt examination interface and auto job matching to make the hiring process effortless"
            />
            <meta name="twitter:image" content="/joballey_logo_dark_text.png" />
          </Helmet>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (
  checkAppEnvs(subdomain) &&
  "serviceWorker" in navigator &&
  WEB_PUSH_PUBLIC_VAPID_KEY
) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        // Registration was successful
        subscribe(registration);
      });
  });
}
