import { createSlice } from "@reduxjs/toolkit";

import {
  createCandidateSkillsAction,
  deleteCandidateSkillsAction,
  getCandidateSkillsAction,
  updateCandidateSkillsAction,
} from "store/thunkActions/candidate/skillThunk";

export const skillSlice = createSlice({
  name: "skill",
  initialState: {
    candidateSkills: [],
  },
  reducers: {},
  extraReducers: {
    [getCandidateSkillsAction.fulfilled]: (state, action) => {
      state.candidateSkills = action.payload;
    },
    [createCandidateSkillsAction.fulfilled]: (state, action) => {
      state.candidateSkills.push(action.payload.data);
    },
    [updateCandidateSkillsAction.fulfilled]: (state, action) => {
      const index = state.candidateSkills.findIndex(
        (candidateSkill) => candidateSkill.id === action.payload.data.id
      );
      state.candidateSkills[index] = action.payload.data;
    },
    [deleteCandidateSkillsAction.fulfilled]: (state, action) => {
      state.candidateSkills = state.candidateSkills.filter(
        (s) => s.id != action.payload.data.id
      );
    },
  },
});

export default skillSlice.reducer;
