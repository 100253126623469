import { useDispatch } from "react-redux";

import { renderAlert } from "store/slices/alertSlice";

const useAlert = () => {
  const dispatch = useDispatch();

  const showAlert = (type, message) => {
    dispatch(renderAlert({ type, message }));
  };

  return showAlert;
};

export default useAlert;
