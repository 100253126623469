import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import AllApplicantsCandidates from "./AllApplicantsCandidates";
import PlacedCandidates from "./PlacedCandidates";
import StageCandidates from "./StageCandidates";

const Candidates = ({
  index,
  candidate,
  currentLobby,
  candidateCount,
  currentStage,
  setIsEditState,
  setCandidateID,
  setExamCandidateId,
  setShowScheduleInterviewModal,
  setShowAddEvaluatorModal,
  setInterview,
  setExamEvaluators,
  setSelectedCandidates,
  setIsSingleOperation,
  getCandidateStageData,
  stageExamDetails,
  onCheck,
  isUserRoleAllowed,
  isJobClosed,
  showSidePanel,
  openSidePanel,
  closeSidePanel,
  candidatePanelId,
  organisationData,
  groups,
  setGroups,
  getGDDetails,
}) => {
  const dropdownMenuRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  return (
    <>
      {currentLobby === "PLACED" && (
        <PlacedCandidates
          candidate={candidate}
          currentStage={currentStage}
          getCandidateStageData={getCandidateStageData}
          isUserRoleAllowed={isUserRoleAllowed}
          isJobClosed={isJobClosed}
          showSidePanel={showSidePanel}
          openSidePanel={openSidePanel}
        />
      )}
      {currentLobby === "ALL_APPLICANTS" && (
        <AllApplicantsCandidates
          candidate={candidate}
          currentStage={currentStage}
          setIsEditState={setIsEditState}
          setCandidateID={setCandidateID}
          getCandidateStageData={getCandidateStageData}
          onCheck={onCheck}
          isUserRoleAllowed={isUserRoleAllowed}
          isJobClosed={isJobClosed}
          showSidePanel={showSidePanel}
          openSidePanel={openSidePanel}
        />
      )}
      {currentStage && (
        <StageCandidates
          index={index}
          candidate={candidate}
          candidateCount={candidateCount}
          currentStage={currentStage}
          setIsEditState={setIsEditState}
          setCandidateID={setCandidateID}
          setExamCandidateId={setExamCandidateId}
          setShowScheduleInterviewModal={setShowScheduleInterviewModal}
          setShowAddEvaluatorModal={setShowAddEvaluatorModal}
          setInterview={setInterview}
          setExamEvaluators={setExamEvaluators}
          setSelectedCandidates={setSelectedCandidates}
          setIsSingleOperation={setIsSingleOperation}
          getCandidateStageData={getCandidateStageData}
          stageExamDetails={stageExamDetails}
          onCheck={onCheck}
          isUserRoleAllowed={isUserRoleAllowed}
          isJobClosed={isJobClosed}
          showSidePanel={showSidePanel}
          openSidePanel={openSidePanel}
          closeSidePanel={closeSidePanel}
          candidatePanelId={candidatePanelId}
          organisationData={organisationData}
          groups={groups}
          setGroups={setGroups}
          getGDDetails={getGDDetails}
        />
      )}
    </>
  );
};

Candidates.defaultProps = {
  showSidePanel: false,
  openSidePanel: null,
  closeSidePanel: null,
  candidatePanelId: null,
};

Candidates.propTypes = {
  candidate: PropTypes.object,
  currentStage: PropTypes.object,
  index: PropTypes.number,
  currentLobby: PropTypes.string,
  candidateCount: PropTypes.number,
  setIsEditState: PropTypes.func,
  setCandidateID: PropTypes.func,
  setShowScheduleInterviewModal: PropTypes.func,
  setIsSingleOperation: PropTypes.func,
  setShowAddEvaluatorModal: PropTypes.func,
  setExamCandidateId: PropTypes.func,
  setInterview: PropTypes.func,
  setExamEvaluators: PropTypes.func,
  isJobClosed: PropTypes.bool,
  getCandidateStageData: PropTypes.func,
  stageExamDetails: PropTypes.arrayOf(
    PropTypes.shape({
      multi_slot: PropTypes.bool,
    })
  ),
  onCheck: PropTypes.func,
  isUserRoleAllowed: PropTypes.bool,
  setSelectedCandidates: PropTypes.func,
  showSidePanel: PropTypes.bool,
  openSidePanel: PropTypes.func,
  closeSidePanel: PropTypes.func,
  candidatePanelId: PropTypes.number,
  organisationData: PropTypes.shape({
    custom_email: PropTypes.bool,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  setGroups: PropTypes.func,
};

export default Candidates;
