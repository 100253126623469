import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { difference, intersection, union } from "lodash";
import PropTypes from "prop-types";

import { createEvaluators, updateEvaluators } from "api/erp/organizationApi";
import { ALERT_TYPE } from "app/constants";
import { InfiniteScrollWrapper } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import { Modal } from "components/molecules";
import specialCharacterChecker from "helpers/specialCharacterChecker";
import useAlert from "hooks/useAlert";
import { getAllUsersAction } from "store/thunkActions/erp/userRolesThunk";

const AddEvaluatorModal = ({
  setShowAddEvaluatorModal,
  candidateId,
  examCandidateId,
  currentStageId,
  examEvaluators,
  selectedCandidates,
  isSingleOperation,
  getCandidateStageData,
}) => {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const params = useParams();
  const [users, setUsers] = useState([]);
  const [removedCheckboxes, setRemovedCheckboxes] = useState([]);
  const [addedCheckboxes, setAddedCheckboxes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const { meta: usersMeta, isLoading: isUsersLoading } = useSelector(
    (state) => state.userRoles
  );

  const getAllUsers = async (page, roles, searchTerm = "") => {
    const handleCheck = (evaluatorId) => {
      let checkboxState = "";
      examEvaluators?.forEach((e) => {
        if (evaluatorId === e.evaluator_id) {
          checkboxState = "checked";
        }
      });
      return checkboxState;
    };

    const getCheckboxState = (evaluatorId) => {
      const evaluatorIds = selectedCandidates?.map((candidate) => {
        return candidate.map((e) => e.evaluator_id);
      });
      const evaluatorsPresentAtleastOnceIds = union(...evaluatorIds);
      const evaluatorsPresentInAllIds = intersection(...evaluatorIds);
      const indeterminateEvaluators = difference(
        evaluatorsPresentAtleastOnceIds,
        evaluatorsPresentInAllIds
      );
      let checkboxState = "";
      if (evaluatorsPresentInAllIds.includes(evaluatorId)) {
        checkboxState = "checked";
      } else if (indeterminateEvaluators.includes(evaluatorId)) {
        checkboxState = "indeterminate";
      }
      return checkboxState;
    };

    const queryParams = searchTerm
      ? { page, roles, ...{ name: searchTerm.trim() } }
      : { page, roles };

    dispatch(getAllUsersAction(queryParams))
      .unwrap()
      .then(({ data, meta }) => {
        const selectedEvaluator =
          examEvaluators &&
          examEvaluators.map((user) => {
            return { ...user, state: "checked" };
          });

        const filteredUserData =
          examEvaluators &&
          data.filter((user) => {
            return !selectedEvaluator.some(
              (selected) => selected.evaluator_id === user.id
            );
          });

        const userData = (filteredUserData || data).map((user) => {
          const checkboxState = isSingleOperation
            ? handleCheck(user.id || user.evaluator_id)
            : getCheckboxState(user.id || user.evaluator_id);
          if (checkboxState === "checked") {
            return { ...user, state: "checked" };
          }
          if (checkboxState === "indeterminate") {
            return { ...user, state: "indeterminate" };
          }
          if (!checkboxState) {
            return { ...user, state: "unchecked" };
          }
          return user;
        });

        if (selectedEvaluator) {
          setUsers(
            meta.page_number === 1
              ? [...selectedEvaluator, ...userData]
              : [...users, ...userData]
          );
        } else {
          setUsers(meta.page_number === 1 ? userData : [...users, ...userData]);
        }
      });
  };

  useEffect(() => {
    getAllUsers(1, ["ADMIN", "EVALUATOR"], searchQuery);
  }, []);

  const handleCheckboxChange = (index, value, evaluator) => {
    const evaluatorId = evaluator.evaluator_id || evaluator.id;

    if (value === "unchecked") {
      if (
        !addedCheckboxes.includes(evaluatorId) &&
        !removedCheckboxes.includes(evaluatorId)
      ) {
        setRemovedCheckboxes([...removedCheckboxes, evaluatorId]);
      }

      if (
        addedCheckboxes.includes(evaluatorId) &&
        !removedCheckboxes.includes(evaluatorId)
      ) {
        setAddedCheckboxes(addedCheckboxes.filter((id) => id !== evaluatorId));
        if (!isSingleOperation) {
          setRemovedCheckboxes([...removedCheckboxes, evaluatorId]);
        }
      }
    }

    if (value === "checked") {
      if (
        !addedCheckboxes.includes(evaluatorId) &&
        !removedCheckboxes.includes(evaluatorId)
      ) {
        setAddedCheckboxes([...addedCheckboxes, evaluatorId]);
      }

      if (
        !addedCheckboxes.includes(evaluatorId) &&
        removedCheckboxes.includes(evaluatorId)
      ) {
        setRemovedCheckboxes(
          removedCheckboxes.filter((id) => id !== evaluatorId)
        );
      }
    }

    if (evaluator.state === "indeterminate") {
      evaluator.state = value;
    }
  };

  const submitHandler = () => {
    // Structuring req body
    const formData = {};

    // Update operation
    if (examEvaluators || selectedCandidates) {
      if (examCandidateId?.length > 0) {
        formData.candidate_job_ids = examCandidateId;
      } else {
        formData.candidate_job_ids = [candidateId];
      }
      formData.user_ids_to_add = addedCheckboxes;
      formData.user_ids_to_remove = removedCheckboxes;

      return updateEvaluators(params.uuid, currentStageId, formData)
        .then(({ meta }) => {
          getCandidateStageData();
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
          setShowAddEvaluatorModal(false);
        })
        .catch((error) => {
          showAlert(
            ALERT_TYPE[error.response.data.meta.message_type],
            error.response.data.meta.message
          );
        });
    }

    formData.candidate_job_id = candidateId;
    formData.user_ids_to_add = addedCheckboxes;

    // Create action for single operation
    return createEvaluators(params.uuid, currentStageId, formData)
      .then(({ meta }) => {
        getCandidateStageData();
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        setShowAddEvaluatorModal(false);
      })
      .catch((error) => {
        showAlert("danger", error.response.data.message);
      });
  };

  const clearSearchBar = () => {
    setSearchQuery("");
    getAllUsers(1, ["ADMIN", "EVALUATOR"], "");
  };

  const handleInputChange = (e) => {
    // Clear the previous timeout added when last key entered
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    if (!e.target.value) {
      clearSearchBar();
    } else {
      setSearchQuery(e.target.value);

      // It will wait for 1200ms to hit API once user type any key and this waiting period will be re-initialized after every key-press
      const newDebounceTimeout = setTimeout(() => {
        if (specialCharacterChecker(e.target.value, /[%]/g)) {
          getAllUsers(1, ["ADMIN", "EVALUATOR"], e.target.value);
        } else {
          setUsers([]);
        }
      }, 1200);

      setDebounceTimeout(newDebounceTimeout);
    }
  };

  const modalHeaderLabel = () => {
    if (examEvaluators?.length > 0 || selectedCandidates?.length > 0) {
      return "Edit Evaluators";
    }
    return "Add Evaluators";
  };

  return (
    <Modal
      title={modalHeaderLabel()}
      testId="add-evaluators-modal"
      scrollClass="overflow-y-auto max-h-[60vh]"
      acceptButtonText={`${
        examEvaluators?.length > 0 || selectedCandidates?.length > 0
          ? "Update"
          : "Add"
      }`}
      acceptBtnType={
        addedCheckboxes?.length === 0 &&
        removedCheckboxes?.length === 0 &&
        isSingleOperation
          ? "disabled"
          : "primary"
      }
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setShowAddEvaluatorModal(false)}
      onOutsideClickHandler={() => setShowAddEvaluatorModal(false)}
      childrenClass="text-gray-darker px-4 pb-4"
    >
      {!users && <ActivityIndicator />}
      {users && (
        <div className="pt-4 pb-2 sticky top-0 z-10 bg-white min-h-[50px] items-start">
          <SearchBar
            skipSearchParams
            handleSubmit={(e) => e.preventDefault()}
            searchTerm={searchQuery}
            handleChange={handleInputChange}
            handleClear={clearSearchBar}
            searchBarPlaceholder="Search by name or email"
          />
        </div>
      )}
      <form
        data-testid="Candidate-AddEvaluators-Form"
        className="my-3"
        onSubmit={handleSubmit(() => submitHandler())}
      >
        <InfiniteScrollWrapper
          hasMore={
            usersMeta.has_more &&
            !isUsersLoading &&
            specialCharacterChecker(searchQuery, /[%]/g)
          }
          loadMoreHandler={() =>
            getAllUsers(
              usersMeta.page_number + 1,
              ["ADMIN", "EVALUATOR"],
              searchQuery
            )
          }
        >
          {users &&
            users.map((evaluator, index) => {
              return (
                <div
                  key={evaluator.id || evaluator.evaluator_id}
                  className="mt-2 hover:bg-gray-lighter pl-2 rounded-lg"
                >
                  <label
                    htmlFor={evaluator.id || evaluator.evaluator_id}
                    className="my-2 cursor-pointer mb-2"
                  >
                    <div className="flex justify-between items-center px-4">
                      <div>
                        <div className="text-gray-800 font-semibold pt-1 break-words max-w-[180px] sm:max-w-[250px]">
                          {evaluator?.name ||
                            evaluator?.email ||
                            evaluator.evaluator_name ||
                            evaluator.evaluator_email}
                        </div>
                        <div className="text-xs pb-1">
                          {evaluator?.email || evaluator.evaluator_email}
                        </div>
                      </div>
                      <div className="cursor-pointer">
                        <input
                          className="cursor-pointer h-5 w-5 mt-1"
                          id={evaluator.id || evaluator.evaluator_id}
                          type="checkbox"
                          defaultChecked={evaluator.state === "checked"}
                          ref={(input) => {
                            if (input) {
                              input.indeterminate =
                                evaluator.state === "indeterminate";
                            }
                          }}
                          name={evaluator.id || evaluator.evaluator_id}
                          onChange={(e) => {
                            const newValue = e.target.checked
                              ? "checked"
                              : "unchecked";
                            handleCheckboxChange(index, newValue, evaluator);
                          }}
                        />
                      </div>
                    </div>
                  </label>
                </div>
              );
            })}
          {users?.length === 0 && (
            <span className="mt-4 block text-lg pl-1 italic text-gray-main">
              No user found
            </span>
          )}
        </InfiniteScrollWrapper>
      </form>
    </Modal>
  );
};

AddEvaluatorModal.defaultProps = {
  setShowAddEvaluatorModal: "",
  candidateId: PropTypes.number,
  currentStageId: PropTypes.number,
  getCandidateStageData: PropTypes.func,
  isSingleOperation: PropTypes.bool,
  examCandidateId: [],
  selectedCandidates: [],
  examEvaluators: [{}],
};

AddEvaluatorModal.propTypes = {
  setShowAddEvaluatorModal: PropTypes.func,
  candidateId: PropTypes.number,

  get examCandidateId() {
    return this.examCandidateId;
  },
  set examCandidateId(value) {
    this.examCandidateId = value;
  },
  currentStageId: PropTypes.number,
  getCandidateStageData: PropTypes.func,
  isSingleOperation: PropTypes.bool,

  get selectedCandidates() {
    return this.selectedCandidates;
  },
  set selectedCandidates(value) {
    this.selectedCandidates = value;
  },
  examEvaluators: PropTypes.arrayOf(
    PropTypes.shape({
      evaluator_id: PropTypes.number,
      evaluator_name: PropTypes.string,
    })
  ),
};

export default AddEvaluatorModal;
