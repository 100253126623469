import { createSlice } from "@reduxjs/toolkit";

export const popupAlertSlice = createSlice({
  name: "popupAlert",
  initialState: {
    type: undefined,
    message: undefined,
    header: undefined,
  },
  reducers: {
    renderPopupAlert: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.header = action.payload.header;
      state.triggerAlert = !!action.payload.message;
    },
    hidePopupAlert: (state) => {
      state.type = undefined;
      state.message = undefined;
      state.header = undefined;
    },
  },
});

export const { renderPopupAlert, hidePopupAlert } = popupAlertSlice.actions;

export default popupAlertSlice.reducer;
