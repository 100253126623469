import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addSection,
  deleteSection,
  getSections,
  updateSectionName,
} from "api/erp/questionPaperApi";

export const createSectionAction = createAsyncThunk(
  "section/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addSection(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSectionAction = createAsyncThunk(
  "section/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getSections(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSectionNameAction = createAsyncThunk(
  "section/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateSectionName(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSectionAction = createAsyncThunk(
  "section/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteSection(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
