import PropTypes from "prop-types";

const CandidateLanguages = ({ candidateLanguages }) => {
  return (
    <>
      <div className="resume-section-heading">LANGUAGES</div>
      <div
        className="resume-section-content"
        style={{
          display: "flex",
          flexWrap: "wrap",
          color: "rgb(100 100 100)",
          textAlign: "justify",
          wordBreak: "break-word",
        }}
      >
        {candidateLanguages.map((language, index) => {
          return (
            <div className="" key={language.id}>
              {language.language.name}
              {index < candidateLanguages.length - 1 ? `,\u00A0` : "."}
            </div>
          );
        })}
      </div>
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

CandidateLanguages.propTypes = {
  candidateLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

export default CandidateLanguages;
