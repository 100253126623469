import { organisationApi } from "api/axios";

// Job APIs
export const createJob = async (data) => {
  const response = await organisationApi.post("/jobs", data);
  return response;
};

export const getJobs = async (
  page,
  searchTerm,
  statuses,
  visibility,
  hidden
) => {
  const response = await organisationApi.get("/jobs", {
    params: { page, search_term: searchTerm, statuses, visibility, hidden },
  });
  return response;
};

export const getJob = async (uuid) => {
  const response = await organisationApi.get(`/jobs/${uuid}`);
  return response;
};

export const updateJob = async (data) => {
  const response = await organisationApi.patch(`/jobs/${data.uuid}`, data);
  return response;
};

// Job Stage API
export const getStages = async (id) => {
  const response = await organisationApi.get(`/jobs/${id}/stages`);
  return response;
};

export const createStage = async (jobUuid, data) => {
  const response = await organisationApi.post(`/jobs/${jobUuid}/stages`, data);
  return response;
};

export const updateStage = async (jobUuid, stageId, data) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}`,
    data
  );
  return response;
};

// email result
export const emailResult = async (jobUuid, stageId, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageId}/email_result`,
    data
  );
  return response;
};

export const deleteStage = async (jobUuid, stageId) => {
  const response = await organisationApi.delete(
    `/jobs/${jobUuid}/stages/${stageId}`
  );
  return response;
};

// Job Candidates API
export const getJobsCandidate = async (
  jobUuid,
  page,
  candidateName,
  id,
  statuses,
  locations,
  project,
  willingToRelocate,
  hasPortfolio,
  sortOrder,
  sortBy,
  academicMarks,
  pageSize
) => {
  const response = await organisationApi.get(`/jobs/${jobUuid}/candidates`, {
    params: {
      page,
      candidate_name: candidateName,
      id,
      statuses,
      locations,
      project,
      willing_to_relocate: willingToRelocate,
      has_portfolio: hasPortfolio,
      sort_order: sortOrder,
      sort_by: sortBy,
      academic_marks: academicMarks,
      page_size: pageSize,
    },
  });
  return response;
};

export const updateJobCandidateStatus = async (jobUuid, data) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/candidates`,
    data
  );
  return response;
};

export const updatePlacedCandidateStatus = async (jobUuid, data) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/placed_candidates`,
    data
  );
  return response;
};

// Stage Candidates API
export const getStageCandidates = async (
  jobUuid,
  stageId,
  page,
  candidateName,
  id,
  cutoffMarks,
  cutoffType,
  marksType,
  hasDifferentSectionalCutOffMarks,
  sectionalCutoff,
  statuses,
  fromDate,
  toDate,
  appeared,
  sortOrder,
  sortBy,
  pageSize,
  groupUuids
) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/candidates?stage_id=${stageId}`,
    {
      params: {
        page,
        candidate_name: candidateName,
        id,
        cutoff_marks: cutoffMarks,
        cutoff_type: cutoffType,
        marks_type: marksType,
        has_different_sectional_cut_off_marks: hasDifferentSectionalCutOffMarks,
        sectional_cutoff: sectionalCutoff,
        statuses,
        from_date: fromDate,
        to_date: toDate,
        appeared,
        sort_order: sortOrder,
        sort_by: sortBy,
        page_size: pageSize,
        group_uuids: groupUuids,
      },
    }
  );
  return response;
};

// Get Stage Candidates as options for dropdown
export const getStageCandidateOptions = async (
  jobUuid,
  stageId,
  page,
  pageSize,
  groupUuids
) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/candidates?stage_id=${stageId}`,
    {
      params: {
        page,
        page_size: pageSize,
        groupUuids,
        no_group: true,
      },
    }
  );
  return response;
};

// Placed Candidates API
export const getPlacedCandidates = async (
  jobUuid,
  page,
  candidateName,
  id,
  statuses,
  sortOrder,
  sortBy,
  pageSize
) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/placed_candidates`,
    {
      params: {
        page,
        candidate_name: candidateName,
        id,
        statuses,
        sort_order: sortOrder,
        sort_by: sortBy,
        page_size: pageSize,
      },
    }
  );
  return response;
};

export const createStageCandidate = async (jobUuid, stageId, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageId}/candidates`,
    data
  );
  return response;
};

export const updateStageCandidateStatus = async (jobUuid, stageId, data) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}/candidates`,
    data
  );
  return response;
};

export const removeStageCandidate = async (
  jobUuid,
  stageId,
  candidateJobIds
) => {
  const response = await organisationApi.delete(
    `/jobs/${jobUuid}/stages/${stageId}/candidates`,
    {
      data: { candidate_job_ids: candidateJobIds },
    }
  );
  return response;
};

export const getCandidateStageHistory = async (jobUuid, candidateJobId) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/candidates/${candidateJobId}/stage_history`
  );
  return response;
};

// Candidate details
export const getCandidateDetails = async (jobUuid, candidateId) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/candidates/${candidateId}`
  );
  return response;
};

// placed candidates LOI
export const sampleLOIDetails = async (placedCandidateId) => {
  const response = await organisationApi.get("/loi", {
    params: { placed_candidate_id: placedCandidateId },
  });
  return response;
};

export const sendLOI = async (jobUuid, placedCandidateId, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/placed_candidates/${placedCandidateId}/lois`,
    data
  );
  return response;
};

export const showLOI = async (jobUuid, placedCandidateId, loiId) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/placed_candidates/${placedCandidateId}/lois/${loiId}`
  );
  return response;
};

export const generateJobDescription = async (jobPromptData) => {
  const response = await organisationApi.post("/generate_job_description", {
    title: jobPromptData.title,
    skills: jobPromptData.skills,
    emp_type: jobPromptData.emp_type,
    location_type: jobPromptData.location_type,
    location: jobPromptData.location,
    vacancies: jobPromptData.vacancies,
    ctc: jobPromptData.ctc,
    qualifications: jobPromptData.qualifications,
  });
  return response;
};

export const sendCustomEmail = async (jobUuid, stageID, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageID}/custom_email`,
    data
  );
  return response;
};

export const showCustomEmail = async (jobUuid, stageID, candidateJobId) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/stages/${stageID}/custom_email`,
    { params: { candidate_job_id: candidateJobId } }
  );
  return response;
};

// create groups
export const createGroups = async (jobUuid, stageID, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageID}/groups`,
    data
  );
  return response;
};

export const getGroups = async (jobUuid, stageID, sortOrder, sortBy) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/stages/${stageID}/groups`,
    {
      params: {
        sort_order: sortOrder,
        sort_by: sortBy,
      },
    }
  );
  return response;
};

export const getGroupCandidates = async (jobUuid, stageID, groupUuid) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/stages/${stageID}/groups/${groupUuid}`
  );
  return response;
};

export const updateGroupDetails = async (jobUuid, stageID, groupUuid, data) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageID}/groups/${groupUuid}`,
    data
  );
  return response;
};

export const updateCandidateForGroups = async (
  jobUuid,
  stageID,
  groupUuid,
  candidateJobId
) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageID}/candidate_group/${groupUuid}?candidate_job_id=${candidateJobId}`
  );
  return response;
};

export const notifyGroupDiscussion = async (jobUuid, stageId, groupUuid) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}/notify_groups/${groupUuid}`
  );
  return response;
};

export const deleteSingleGroup = async (jobUuid, stageId, groupUuid) => {
  const response = await organisationApi.delete(
    `/jobs/${jobUuid}/stages/${stageId}/groups/${groupUuid}`
  );
  return response;
};
