import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { BiX } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import { isAfter, isBefore, toDate } from "date-fns";
import { arrayOf, func, object } from "prop-types";

import { getEducationLevels, getStates } from "api/candidate/profileApi";
import {
  AA_CANDIDATE_STATUS,
  DECIMAL_INFO,
  EXAM_CANDIDATE_STATUS,
  INTERVIEW_GD_CANDIDATE_STATUS,
  PLACED_CANDIDATE_STATUS,
} from "app/constants";
import { Button } from "components/atoms";
import { Modal } from "components/molecules";
import {
  CUT_OFF_TYPES,
  MARKS_TYPES,
  PORTFOLIO_URL_TYPES,
  WILLING_TO_RELOCATE_TYPES,
} from "constants/common";
import {
  characterValidation,
  requiredValidation,
  toDateTimeValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";

const CandidateFilterModal = ({
  currentStage,
  setShowFilterModal,
  useFormProps,
  searchFormSubmitHandler,
  handleClearFilter,
  resetSearchFormToDefault,
  stageExamDetails,
  groupsList,
}) => {
  const {
    register,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useFormProps;
  const [searchParams] = useSearchParams();
  const [isSectionalData, setIsSectionalData] = useState([]);
  const [academicFieldCount, setAcademicFieldCount] = useState([1]);
  const cutoffType = watch("cutoff_type");
  const checked = watch("has_different_sectional_cut_off_marks") || false;

  let CANDIDATE_STATUS_LIST;

  if (currentStage) {
    CANDIDATE_STATUS_LIST =
      currentStage.stage_type === "exam"
        ? EXAM_CANDIDATE_STATUS
        : INTERVIEW_GD_CANDIDATE_STATUS;
  } else if (searchParams.get("lobby") === "ALL_APPLICANTS") {
    CANDIDATE_STATUS_LIST = AA_CANDIDATE_STATUS;
  } else {
    CANDIDATE_STATUS_LIST = PLACED_CANDIDATE_STATUS;
  }

  const loadStateOptions = async (search, _, { page }) => {
    const {
      data: {
        data: options,
        meta: { has_more: hasMore },
      },
    } = await getStates(page, search);

    // Filtering out NOT selected state options
    const selectedStatesArr = watch("locations");
    let filteredStates = options;

    if (selectedStatesArr) {
      filteredStates = options.filter(
        (item) => !selectedStatesArr.some(({ name }) => name === item.name)
      );
    }

    return {
      options: filteredStates,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadEducationLevels = async (search, _, { page }) => {
    const { data, meta } = await getEducationLevels(page, search);

    const selectedOptions = [];

    // Selected educational level options
    academicFieldCount.forEach((_, i) => {
      const selectedValue = watch(`education_level_id_${i}`);
      if (selectedValue) {
        selectedOptions.push(selectedValue);
      }
    });

    // Filtering out NOT selected educational level options
    const filteredData = data.filter(
      (item) => !selectedOptions.some(({ id }) => id === item.id)
    );

    return {
      options: filteredData,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleAcademicMarksField = () => {
    if (academicFieldCount.length < 4) {
      const newId = academicFieldCount.length + 1;
      setAcademicFieldCount((prevFields) => [...prevFields, newId]);
    }
  };

  const marksOrPoints = (suffix) =>
    `${
      ["interview", "gd"].includes(currentStage.stage_type) ? "Rating" : "Marks"
    } ${suffix}`;

  const marksOrPointsPlaceholder = (suffix) =>
    `${
      ["interview", "gd"].includes(currentStage.stage_type) ? "rating" : "marks"
    } ${suffix}`;

  const handleCancelForm = () => {
    resetSearchFormToDefault();
    setShowFilterModal(false);
  };

  const filterPassedTime = (time, fromDate) => {
    const currentDate = new Date(fromDate);
    const selectedDate = new Date(time);

    return currentDate.getTime() <= selectedDate.getTime();
  };

  const submitHandler = (data) => {
    const sectionalCutOff = [];
    const academicMarks = [];
    const filterData = { ...data };

    if (checked) {
      stageExamDetails[0]?.question_paper?.sections.map((section) => {
        const sectionCutOffMarks = watch(
          `enter_section_cut_off_marks_${section.id}`
        );

        if (sectionCutOffMarks) {
          const sectionalCutoffTemp = {
            section_id: section.id,
            cut_off_marks:
              JSON.stringify(data.marks_type) === JSON.stringify(MARKS_TYPES[1])
                ? Number(sectionCutOffMarks) / 100
                : Number(sectionCutOffMarks),
          };
          sectionalCutOff.push(sectionalCutoffTemp);
        }
      });
      filterData.sectional_cutoff = sectionalCutOff;
    } else {
      filterData.sectional_cutoff = null;
    }

    if (searchParams.get("lobby") === "ALL_APPLICANTS") {
      academicFieldCount.map((_, index) => {
        const academicLevel = watch(`education_level_id_${index}`);
        const academicMarksWatch = watch(`marks_${index}`);

        const academicMark = {
          education_level_id: academicLevel?.id,
          marks: Number(academicMarksWatch),
        };
        if (academicLevel) {
          academicMarks.push(academicMark);
        }
      });

      filterData.academic_marks =
        academicMarks.length === 0 ? null : academicMarks;
    }

    if (checked) {
      if (sectionalCutOff.every((elm) => elm === null)) {
        setError("has_different_sectional_cut_off_marks", {
          message: "At least one section cutoff marks is required",
        });
      } else {
        searchFormSubmitHandler(filterData);
      }
    } else {
      searchFormSubmitHandler(filterData);
    }
  };

  const clearSectionCutOffMarks = () => {
    stageExamDetails[0]?.question_paper?.sections.map((section) => {
      setValue(`enter_section_cut_off_marks_${section.id}`, null);
    });
  };

  const clearSectionalCutOffMarksError = () => {
    stageExamDetails[0]?.question_paper?.sections.map((section) => {
      clearErrors(`enter_section_cut_off_marks_${section.id}`, null);
    });
  };

  const getSlotFilterText = () => {
    if (currentStage.stage_type === "exam") {
      if (stageExamDetails?.[0]?.multi_slot) {
        return "Slot";
      }
      return "Appeared On";
    }

    return "Scheduled On";
  };

  useEffect(() => {
    // mapping for each section is done on the basis of section.id position
    const sectionList = stageExamDetails[0]?.question_paper?.sections.map(
      (section) => {
        return {
          id: section.id,
          name: section.name,
        };
      }
    );

    setIsSectionalData(sectionList);
  }, [stageExamDetails]);

  useEffect(() => {
    resetSearchFormToDefault();
    const academicMarks = searchParams.get("academic_marks");
    if (academicMarks) {
      const decodedAcademicMarks = JSON.parse(
        decodeURIComponent(academicMarks)
      );
      setAcademicFieldCount(decodedAcademicMarks);
    } else {
      setAcademicFieldCount([1]);
    }
  }, []);

  return (
    <Modal
      title="Filter"
      acceptButtonText="Apply Filter"
      rejectButtonText="Clear Filter"
      acceptBtnType="primary"
      rejectBtnType="secondary"
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleReject={handleClearFilter}
      handleCancel={handleCancelForm}
      onOutsideClickHandler={handleCancelForm}
      customButtonWidth={32}
      customModalWidth="650px"
      scrollClass="overflow-y-auto max-h-[75vh]"
    >
      <form>
        <div className="mb-12">
          <label
            htmlFor="candidate_name"
            className="mt-2 mb-1 block font-medium"
          >
            Name
          </label>

          <div className="flex">
            <input
              data-testid="name-input"
              placeholder="Enter candidate name"
              {...register("candidate_name", {
                maxLength: {
                  value: 255,
                  message: characterValidation({ limit: 255 }),
                },
              })}
              className={`${
                errors.candidate_name ? "input-error" : "input"
              } w-full`}
            />

            {watch("candidate_name") && (
              <div className="relative">
                <BiX
                  className="absolute top-[9px] right-1.5 text-2xl text-gray-600"
                  onClick={() => setValue("candidate_name", "")}
                />
              </div>
            )}
          </div>

          {errors.candidate_name && (
            <p className="mt-2 text-sm text-danger-dark">
              {errors.candidate_name.message}
            </p>
          )}

          <label htmlFor="candidate_id" className="mt-2 mb-1 block font-medium">
            Id
          </label>

          <div className="flex">
            <input
              data-testid="candidate-id-input"
              placeholder="Enter candidate Id"
              type="number"
              {...register("candidate_id", {
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Invalid number",
                },
                maxLength: {
                  value: 19,
                  message: "Maximum value is 9999999999999999999",
                },
              })}
              className={`${
                errors.candidate_id ? "input-error" : "input"
              } w-full`}
            />

            {watch("candidate_id") && (
              <div className="relative">
                <BiX
                  className="absolute top-[9px] right-7 text-2xl text-gray-600"
                  onClick={() => setValue("candidate_id", "")}
                />
              </div>
            )}
          </div>

          {errors.candidate_id && (
            <p className="mt-2 text-sm text-danger-dark">
              {errors.candidate_id.message}
            </p>
          )}

          {currentStage?.stage_type === "gd" && (
            <div className="w-full">
              <label
                htmlFor="currentGroups"
                className="mt-2 mb-1 block font-medium"
              >
                Group
              </label>
              <Controller
                name="groups"
                control={control}
                render={({ field }) => (
                  <Select
                    name="groups"
                    placeholder="Select group"
                    options={groupsList}
                    id="current-group"
                    maxMenuHeight={120}
                    menuPlacement="auto"
                    isMulti
                    className="mb-1 w-full"
                    value={field.value}
                    onChange={(data) => {
                      field.onChange(data);
                    }}
                    isClearable
                    isSearchable
                    closeMenuOnSelect={false}
                    getOptionLabel={(option) => option.title || option.value}
                    getOptionValue={(option) => option.uuid || option.name}
                  />
                )}
              />
            </div>
          )}
          {currentStage && (
            <>
              <div className="my-2">
                <div
                  className={classNames({
                    "flex flex-col": ["interview", "gd"].includes(
                      currentStage.stage_type
                    ),
                  })}
                >
                  <label
                    htmlFor="cutoff_marks"
                    className={classNames("mt-4 mb-1 block font-medium")}
                  >
                    {marksOrPoints(" Obtained")}
                    <span className="ml-1 text-sm">
                      (Greater than or equal to)
                    </span>
                  </label>

                  <div
                    className={classNames("flex flex-col justify-between", {
                      "gap-4 xs:flex-row": currentStage.stage_type === "exam",
                    })}
                  >
                    {currentStage.stage_type === "exam" && (
                      <Controller
                        control={control}
                        name="cutoff_type"
                        id="cutOffSelect"
                        rules={{
                          validate: {
                            not_blank: (v) =>
                              watch("cutoff_marks") === "" ||
                              (v && v.value !== "") ||
                              requiredValidation(),
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            name="cutOffSelect"
                            inputId="cutOffSelect"
                            isClearable
                            className="mb-3 w-full xs:mb-0 sm:w-1/2"
                            styles={{
                              control: (styles, state) => ({
                                ...styles,
                                paddingTop: 2,
                                paddingBottom: 2,
                                backgroundColor:
                                  errors.cutoff_type && "#fad1dd",
                                // eslint-disable-next-line no-nested-ternary
                                borderColor: errors.cutoff_type
                                  ? "#E73067"
                                  : state.isFocused
                                  ? "#143A7B"
                                  : "#CCCCCC",
                                boxShadow:
                                  (state.isFocused && !errors.cutoff_type) ??
                                  "0 0 0 1px #205dc5",
                                "&:hover": {
                                  borderColor: errors.cutoff_type
                                    ? "#E73067"
                                    : "#205dc5",
                                },
                              }),
                            }}
                            placeholder="Select type"
                            options={CUT_OFF_TYPES}
                            id="cutOffSelect"
                            isOptionDisabled={(option) =>
                              stageExamDetails[0]?.question_paper?.sectional ===
                                false && option.value === "SECTIONAL"
                            }
                            menuPlacement="auto"
                            value={field.value}
                            onChange={(value) => {
                              if (
                                value === null &&
                                watch("cutoff_marks") === ""
                              ) {
                                clearErrors("cutoff_marks");
                                setValue(
                                  "has_different_sectional_cut_off_marks",
                                  null
                                );
                              }
                              field.onChange(value);
                              if (value === CUT_OFF_TYPES[1]) {
                                setValue(
                                  "has_different_sectional_cut_off_marks",
                                  false
                                );
                                clearErrors(
                                  "has_different_sectional_cut_off_marks"
                                );
                              }

                              if (
                                value === null &&
                                watch("cutoff_marks") !== ""
                              ) {
                                setValue(
                                  "has_different_sectional_cut_off_marks",
                                  false
                                );
                              }
                            }}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                          />
                        )}
                      />
                    )}

                    <div
                      className={classNames(
                        currentStage.stage_type === "exam"
                          ? "flex w-full items-center sm:w-1/2"
                          : "block"
                      )}
                    >
                      {!checked && (
                        <>
                          <input
                            data-testid="marks-from-input"
                            placeholder={`Enter ${marksOrPointsPlaceholder(
                              ""
                            )}obtained`}
                            className={classNames(
                              "rounded-l-sm p-2",
                              `${
                                errors.cutoff_marks ? "input-error" : "input"
                              }`,
                              "w-full"
                            )}
                            type="text"
                            step={
                              currentStage.stage_type === "exam" ? ".01" : "1"
                            }
                            min={0}
                            {...register("cutoff_marks", {
                              max: {
                                value:
                                  JSON.stringify(watch("marks_type")) ===
                                  JSON.stringify(MARKS_TYPES[1])
                                    ? 100
                                    : null,
                                message:
                                  JSON.stringify(watch("marks_type")) ===
                                    JSON.stringify(MARKS_TYPES[1]) &&
                                  DECIMAL_INFO.MAX,
                              },
                              min: {
                                value: 0,
                                message: DECIMAL_INFO.MIN_ZERO,
                              },
                              pattern: {
                                value:
                                  currentStage.stage_type === "exam"
                                    ? /^\d*\.{0,1}\d{0,2}$/
                                    : /^[0-5]$/,
                                message:
                                  currentStage.stage_type === "exam"
                                    ? DECIMAL_INFO.PATTERN
                                    : "must be an integer between 0 to 5",
                              },
                              validate: {
                                not_blank: (v) =>
                                  ["interview", "gd"].includes(
                                    currentStage.stage_type
                                  ) ||
                                  watch("cutoff_type") === undefined ||
                                  watch("cutoff_type") === null ||
                                  v !== "" ||
                                  requiredValidation(),
                              },
                              onChange: (e) => {
                                if (e.target.value === "") {
                                  clearErrors("cutoff_type");
                                }
                                clearErrors(
                                  "has_different_sectional_cut_off_marks"
                                );
                              },
                            })}
                          />

                          {watch("cutoff_marks") && (
                            <div className="relative">
                              <BiX
                                className={`absolute ${
                                  currentStage.stage_type === "exam"
                                    ? "-bottom-3"
                                    : "bottom-2"
                                } right-4 text-2xl text-gray-600`}
                                onClick={() => {
                                  setValue("cutoff_marks", "");
                                  if (watch("cutoff_type") === null) {
                                    setValue(
                                      "has_different_sectional_cut_off_marks",
                                      null
                                    );
                                  }
                                  clearErrors("cutoff_type");
                                  clearErrors("cutoff_marks");
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}

                      <div className={`${checked ? "w-full" : "w-28"}`}>
                        {currentStage.stage_type === "exam" && (
                          <Controller
                            control={control}
                            name="marks_type"
                            id="cutOffSelect"
                            defaultValue={MARKS_TYPES[1]}
                            render={({ field }) => (
                              <Select
                                name="marksTypeSelect"
                                className="rounded-r-sm border-l-0"
                                styles={{
                                  control: (styles, state) => ({
                                    ...styles,
                                    padding: "2px",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    // eslint-disable-next-line no-nested-ternary
                                    borderColor: errors.marks_type
                                      ? "#E73067"
                                      : state.isFocused
                                      ? "#143A7B"
                                      : "#CCCCCC",
                                    boxShadow:
                                      (state.isFocused && !errors.marks_type) ??
                                      "0 0 0 1px #205dc5",
                                    "&:hover": {
                                      borderColor: errors.marks_type
                                        ? "#E73067"
                                        : "#205dc5",
                                    },
                                  }),
                                  dropdownIndicator: (styles) => ({
                                    ...styles,
                                    padding: "1px",
                                  }),
                                }}
                                options={MARKS_TYPES}
                                id="marksTypeSelect"
                                menuPlacement="auto"
                                value={field.value}
                                onChange={(value) => {
                                  field.onChange(value);
                                  clearErrors("cutoff_marks");
                                  clearSectionalCutOffMarksError();
                                }}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {errors.cutoff_type && (
                    <p className="mt-2 text-sm text-danger-dark">
                      type {errors.cutoff_type.message}
                    </p>
                  )}
                  {errors.cutoff_marks && (
                    <p className="mt-2 text-sm text-danger-dark">
                      {currentStage.stage_type === "exam" && "marks"}{" "}
                      {errors.cutoff_marks.message}
                    </p>
                  )}
                  {errors.marks_type && (
                    <p className="mt-2 text-sm text-danger-dark">
                      marks type {errors.marks_type.message}
                    </p>
                  )}
                </div>
              </div>

              {currentStage.stage_type === "exam" && (
                <div
                  className={classNames(
                    "flex",
                    stageExamDetails[0]?.question_paper?.sectional === false
                      ? "hidden"
                      : "visible"
                  )}
                >
                  <input
                    type="checkbox"
                    name="has_different_sectional_cut_off_marks"
                    id="has_different_sectional_cut_off_marks"
                    className="hover:cursor-pointer"
                    disabled={
                      JSON.stringify(cutoffType) !==
                      JSON.stringify(CUT_OFF_TYPES[0])
                    }
                    {...register("has_different_sectional_cut_off_marks")}
                    onChange={(e) => {
                      setValue(
                        "has_different_sectional_cut_off_marks",
                        e.target.checked
                      );
                      clearSectionCutOffMarks();
                      setValue("cutoff_marks", "");
                      clearErrors("cutoff_marks");
                      clearErrors("has_different_sectional_cut_off_marks");
                      isSectionalData?.map((section) => {
                        setValue(
                          `enter_section_cut_off_marks_${section.id}`,
                          null
                        );
                      });
                    }}
                  />
                  <label
                    htmlFor="has_different_sectional_cut_off_marks"
                    className="flex items-center text-sm hover:cursor-pointer"
                  >
                    <span className="ml-2">
                      Set for each section separately
                    </span>
                  </label>
                </div>
              )}

              {errors.has_different_sectional_cut_off_marks && (
                <p className="mt-2 text-sm text-danger-dark">
                  {errors.has_different_sectional_cut_off_marks.message}
                </p>
              )}
            </>
          )}

          {checked && (
            <div className="flex flex-col flex-wrap justify-between gap-4 sm:flex-row">
              {isSectionalData?.map((section) => (
                <div className="flex-col sm:w-[48%]" key={section.id}>
                  <label
                    htmlFor={`enter_section_cut_off_marks_${section.id}`}
                    className={`mt-2 block font-medium ${
                      errors.section_cut_off_marks && "text-danger-main"
                    }`}
                  >
                    {section.name}
                  </label>
                  <div className="flex items-center">
                    <div className="w-4/5">
                      <input
                        data-testid="section-marks-from-input"
                        name={`enter_section_cut_off_marks_${section.id}`}
                        placeholder="Enter marks obtained"
                        className={classNames(
                          "input mr-[1px] mt-1  rounded-r-none disabled:bg-gray-lighter"
                        )}
                        type="text"
                        {...register(
                          `enter_section_cut_off_marks_${section.id}`,
                          {
                            max: {
                              value:
                                JSON.stringify(watch("marks_type")) ===
                                JSON.stringify(MARKS_TYPES[1])
                                  ? 100
                                  : null,
                              message:
                                JSON.stringify(watch("marks_type")) ===
                                  JSON.stringify(MARKS_TYPES[1]) &&
                                DECIMAL_INFO.MAX,
                            },
                            min: {
                              value: 0.01,
                              message: DECIMAL_INFO.MIN,
                            },
                            pattern: {
                              value: /^\d*\.{0,1}\d{0,2}$/,
                              message: DECIMAL_INFO.PATTERN,
                            },
                          }
                        )}
                        onChange={() => {
                          clearErrors("has_different_sectional_cut_off_marks");
                        }}
                      />

                      {watch(`enter_section_cut_off_marks_${section.id}`) && (
                        <div className="relative">
                          <BiX
                            className="absolute bottom-2 right-8 text-2xl text-gray-600"
                            onClick={() => {
                              setValue(
                                `enter_section_cut_off_marks_${section.id}`,
                                ""
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="input mt-1 w-1/5 bg-gray-lighterShade5">
                      <span className="">{watch("marks_type").label}</span>
                    </div>
                  </div>

                  {errors[`enter_section_cut_off_marks_${section.id}`] && (
                    <p className="mt-1 text-sm text-danger-dark">
                      {
                        errors[`enter_section_cut_off_marks_${section.id}`]
                          .message
                      }
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}

          <div className="my-3 mb-4">
            <label htmlFor="statuses" className="mt-2 mb-1 block font-medium">
              Status
            </label>

            <Controller
              name="statuses"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  data-testid="status-input"
                  id="statusInput"
                  maxMenuHeight={120}
                  menuPlacement="auto"
                  placeholder="Select status"
                  options={CANDIDATE_STATUS_LIST}
                  value={field.value}
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  styles={reactSelectStyle(errors.statuses)}
                />
              )}
            />
          </div>

          {searchParams.get("lobby") === "ALL_APPLICANTS" && (
            <>
              <div className="w-full">
                <label
                  htmlFor="currentLocation"
                  className="mt-2 mb-1 block font-medium"
                >
                  Current Location
                </label>
                <Controller
                  name="locations"
                  control={control}
                  render={({ field }) => (
                    <AsyncPaginate
                      isMulti
                      isSearchable
                      isClearable
                      data-testid="current-location"
                      id="currentLocation"
                      maxMenuHeight={120}
                      menuPlacement="auto"
                      placeholder="Select current location"
                      closeMenuOnSelect={false}
                      loadOptions={loadStateOptions}
                      noOptionsMessage={() => "Not found"}
                      debounceTimeout={1000}
                      value={field.value}
                      onChange={(data) => field.onChange(data)}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      additional={{ page: 1 }}
                    />
                  )}
                />
              </div>

              <div className="flex flex-row justify-between w-full gap-2">
                <div className="w-8/12">
                  <label
                    htmlFor="willingToRelocate"
                    className="mt-2 mb-1 block font-medium"
                  >
                    Willing to relocate?
                  </label>
                  <Controller
                    name="willing_to_relocate"
                    control={control}
                    render={({ field }) => (
                      <Select
                        isSearchable
                        isClearable
                        data-testid="willingness-to-relocate"
                        id="willingToRelocate"
                        maxMenuHeight={120}
                        menuPlacement="auto"
                        placeholder="Select"
                        options={WILLING_TO_RELOCATE_TYPES}
                        value={field.value}
                        onChange={(data) => {
                          field.onChange(data);
                        }}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  />
                </div>
                <div className="w-8/12">
                  <label
                    htmlFor="hasPortfolio"
                    className="mt-2 mb-1 block font-medium"
                  >
                    Portfolio link present or not?
                  </label>
                  <Controller
                    name="has_portfolio"
                    control={control}
                    render={({ field }) => (
                      <Select
                        isSearchable
                        isClearable
                        data-testid="has-portfolio"
                        id="hasPortfolio"
                        maxMenuHeight={120}
                        menuPlacement="auto"
                        placeholder="Select"
                        options={PORTFOLIO_URL_TYPES}
                        value={field.value}
                        onChange={(data) => {
                          field.onChange(data);
                        }}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  htmlFor="project_name"
                  className="mt-2 mb-1 block font-medium"
                >
                  Projects
                </label>
                <div className="flex">
                  <input
                    data-testid="project"
                    placeholder="Enter keyword"
                    {...register("project", {
                      maxLength: {
                        value: 255,
                        message: characterValidation({ limit: 255 }),
                      },
                    })}
                    className={`${
                      errors.project ? "input-error" : "input"
                    } w-full`}
                  />

                  {watch("project") && (
                    <div className="relative">
                      <BiX
                        className="absolute top-[9px] right-1.5 text-2xl text-gray-600"
                        onClick={() => setValue("project", "")}
                      />
                    </div>
                  )}
                </div>

                {errors.project && (
                  <p className="mt-2 text-sm text-danger-dark">
                    {errors.project.message}
                  </p>
                )}
              </div>
            </>
          )}

          {searchParams.get("lobby") === "ALL_APPLICANTS" && (
            <>
              {academicFieldCount?.map((_, index) => (
                <div className="my-3" key={index}>
                  <div className="my-3">
                    <label
                      htmlFor="statuses"
                      className="mt-2 mb-1 block font-medium"
                    >
                      Marks &#40;&gt;&#61;&#41;
                    </label>

                    <div className="flex gap-2">
                      <div className="flex w-full flex-col">
                        <Controller
                          control={control}
                          name={`education_level_id_${index}`}
                          render={({ field }) => (
                            <AsyncPaginate
                              isSearchable
                              isClearable
                              id={`education_level_id_${index}`}
                              menuPlacement="auto"
                              className="w-full"
                              styles={reactSelectStyle(
                                errors[`education_level_id_${index}`]
                              )}
                              value={
                                field.value?.label || field.value?.name
                                  ? field.value
                                  : null
                              }
                              loadOptions={loadEducationLevels}
                              onChange={(value) => {
                                field.onChange(value);
                                setValue(`marks_${index}`, "");
                                clearErrors(`marks_${index}`);
                              }}
                              noOptionsMessage={() => "Not found"}
                              debounceTimeout={1000}
                              cacheUniqs={[loadEducationLevels]}
                              placeholder="Select education level"
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) => option?.name}
                              additional={{ page: 1 }}
                            />
                          )}
                        />
                        {errors[`education_level_id_${index}`] && (
                          <p className="text-sm text-danger-dark">
                            {errors[`education_level_id_${index}`].message}
                          </p>
                        )}
                      </div>
                      <div className="flex w-full flex-col">
                        <div className="flex">
                          <input
                            data-testid="education-level-marks"
                            name={`marks_${index}`}
                            placeholder="Enter marks"
                            className={classNames(
                              "input mr-[1px] rounded-r-none disabled:bg-gray-lighter"
                            )}
                            disabled={
                              watch(`education_level_id_${index}`) == null
                            }
                            type="text"
                            {...register(`marks_${index}`, {
                              max: {
                                value: 100,
                                message: DECIMAL_INFO.MAX,
                              },
                              min: {
                                value: 0,
                                message: DECIMAL_INFO.MIN,
                              },
                              pattern: {
                                value: /^\d*\.{0,1}\d{0,2}$/,
                                message: DECIMAL_INFO.PATTERN,
                              },
                              required:
                                watch(`education_level_id_${index}`) !==
                                  undefined &&
                                watch(`education_level_id_${index}`) !== null &&
                                requiredValidation(),
                            })}
                            onChange={() => {
                              clearErrors(`marks_${index}`);
                            }}
                          />

                          <div className="input w-1/5 bg-gray-lighterShade5">
                            <span>%</span>
                          </div>
                        </div>
                        {errors[`marks_${index}`] && (
                          <p className="text-sm text-danger-dark">
                            {errors[`marks_${index}`].message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {academicFieldCount.length < 4 && (
                <Button
                  title="Add More"
                  buttonType="button"
                  onClick={handleAcademicMarksField}
                  type="tertiary"
                  size="sm"
                  customWidth={24}
                  btnClassName="pl-0"
                  testId="add-academic-marks"
                >
                  Add More
                </Button>
              )}
            </>
          )}

          {currentStage && (
            <div className="my-3">
              <label
                htmlFor="from_date_and_time"
                className="mt-2 mb-1 block font-medium"
              >
                {getSlotFilterText()}
              </label>

              <div className="mt-3 flex flex-col justify-between sm:flex-row sm:space-x-4">
                <div className="w-full flex-col sm:w-1/2">
                  <Controller
                    control={control}
                    name="from_date"
                    rules={{
                      required:
                        currentStage.stage_type === "exam" &&
                        stageExamDetails?.[0]?.multi_slot &&
                        watch("appeared") &&
                        requiredValidation(),
                    }}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="From - date and time"
                        isClearable
                        className={`${
                          errors.from_date ? "input-error" : "input"
                        } w-full`}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        showTimeSelect
                        timeFormat="p"
                        styles={reactSelectStyle(errors.from_date)}
                        timeIntervals={15}
                        onChange={(date) => {
                          if (isAfter(new Date(date), watch("to_date"))) {
                            setError("to_date", {
                              message: toDateTimeValidation(),
                            });
                          } else if (date === undefined || date === null) {
                            setValue("to_date", null);
                            clearErrors("to_date");
                          } else {
                            clearErrors("to_date");
                          }
                          return field.onChange(date);
                        }}
                        selected={field.value && toDate(new Date(field.value))}
                      />
                    )}
                  />
                  {errors.from_date && (
                    <p className="mt-2 text-sm text-danger-dark">
                      {errors.from_date.message}
                    </p>
                  )}
                </div>

                <div className="mt-3 w-full flex-col sm:mt-0 sm:w-1/2">
                  <Controller
                    control={control}
                    name="to_date"
                    rules={{
                      required: watch("from_date") && requiredValidation(),
                      validate: (endDate) => {
                        if (isBefore(new Date(endDate), watch("from_date"))) {
                          return toDateTimeValidation();
                        }
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        disabled={
                          watch("from_date") === undefined ||
                          watch("from_date") === null
                        }
                        placeholderText="To - date and time"
                        isClearable
                        className={`${
                          errors.to_date ? "input-error" : "input"
                        } disabled:input-disabled w-full`}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        filterTime={(e) =>
                          filterPassedTime(e, watch("from_date"))
                        }
                        showTimeSelect
                        timeFormat="p"
                        styles={reactSelectStyle(errors.to_date)}
                        timeIntervals={15}
                        onChange={(date) => {
                          if (isAfter(new Date(date), watch("from_date"))) {
                            clearErrors("to_date");
                          }
                          field.onChange(date);
                        }}
                        selected={field.value && toDate(new Date(field.value))}
                        minDate={watch("from_date") ? watch("from_date") : null}
                      />
                    )}
                  />
                  {errors.to_date && (
                    <p className="mt-2 text-sm text-danger-dark">
                      {errors.to_date.message}
                    </p>
                  )}
                </div>
              </div>

              {currentStage.stage_type === "exam" &&
                stageExamDetails?.[0]?.multi_slot && (
                  <div>
                    <input
                      type="checkbox"
                      className="mt-3"
                      {...register("appeared")}
                    />
                    <label htmlFor="appeared" className="ml-2">
                      Only show candidates who have appeared
                    </label>
                  </div>
                )}
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

CandidateFilterModal.propTypes = {
  currentStage: object,
  setShowFilterModal: func,
  useFormProps: object,
  searchFormSubmitHandler: func,
  handleClearFilter: func,
  resetSearchFormToDefault: func,
  stageExamDetails: arrayOf(object),
  groupsList: arrayOf(object),
};

export default CandidateFilterModal;
