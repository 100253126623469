import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BiFilterAlt } from "react-icons/bi";
import { BsCalendarRange, BsTrash } from "react-icons/bs";
import { HiFilter } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { isEmpty } from "lodash";

import { getGroups } from "api/erp/jobApi";
import { getExams, getOrganisationDetails } from "api/erp/organizationApi";
import {
  AA_CANDIDATE_STATUS,
  ALERT_TYPE,
  EXAM_CANDIDATE_STATUS,
  INTERVIEW_GD_CANDIDATE_STATUS,
  LOBBY_TYPES,
  PIPELINE_FILTER_PARAMS_NAME,
  PIPELINE_PRESERVED_PARAMS_NAME,
  PLACED_CANDIDATE_STATUS,
  SORT_BY,
  SORT_ORDER,
  STAGE_TYPE,
} from "app/constants";
import { Button, TabList, ThreeDotMenu } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import SidePanel from "components/atoms/SidePanel";
import ActivityIndicator from "components/common/ActivityIndicator";
import Pagination from "components/common/Pagination";
import { Modal } from "components/molecules";
import {
  DEFAULT_PAGE_SIZE,
  PER_PAGE_RECORD_LIMIT,
  USER_ROLES,
} from "constants/common";
import { JOBS_STATUSES } from "constants/jobs";
import { constructQueryParams } from "helpers/constructQueryParams";
import { filterSearchParams } from "helpers/pipeline/filter/filterSearchParams";
import { resetFilterForm } from "helpers/pipeline/filter/resetFilterForm";
import { SearchParamsFinder } from "helpers/SearchParamsFinder";
import useAlert from "hooks/useAlert";
import useCheckUserRole from "hooks/useCheckUserRole";
import {
  addSelectedIds,
  removeSelectedIds,
  resetSelection,
  updateHeaderTitle,
} from "store/slices/miscellaneousSlice";
import {
  getJobCandidatesAction,
  getPlacedCandidatesAction,
  getStageCandidatesAction,
} from "store/thunkActions/erp/candidateJobStageThunk";
import { getJobAction } from "store/thunkActions/erp/jobThunk";
import {
  deleteStageAction,
  getStagesAction,
} from "store/thunkActions/erp/stagesThunk";

import CandidateProfilePanel from "./CandidateProfile/CandidateProfilePanel";
import CandidateFilterModal from "./Candidates/CandidateFilterModal";
import ExamModal from "./Exam/ExamModal";
import MultiSlotModal from "./Exam/MultiSlotModal";
import StageExamDetails from "./Exam/StageExamDetails";
import AddToStageModal from "./Stage/AddToStageModal";
import StageModal from "./Stage/StageModal";
import AddEvaluatorModal from "./AddEvaluatorModal";
import BulkStageModal from "./BulkStages";
import Candidates from "./Candidates";
import CandidateStatusCard from "./CandidateStatusCard";
import GroupDiscussionCard from "./GroupDiscussionCard";
import GDModal from "./GroupDiscussionModal";
import JobVisibility from "./JobVisibility";
import PipelineCandidatesHeader from "./PipelineCandidateHeader";
import PipelineMetaContent from "./PipelineMetaContent";
import ScheduleGDModal from "./ScheduleGDModal";
import ScheduleInterviewModal from "./ScheduleInterviewModal";
import UpdateStatusModal from "./UpdateStatusModal";

const PipeLine = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const dropdownMenuRef = useRef();
  const scrollToCandidatesHeaderRef = useRef();
  const groupsRef = useRef(null);

  const scrollToHeader = useRef(false);
  const filterData = useRef({});
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  const useFormProps = useForm();
  const { reset, setError } = useFormProps;

  const { stages, isLoading: isStageLoading } = useSelector(
    (state) => state.stages
  );
  const [isLoading, data, meta] = useSelector(({ candidateJobStage }) => [
    candidateJobStage.isLoading,
    candidateJobStage.data,
    candidateJobStage.meta,
  ]);
  const [job, setJob] = useState();
  const stageId = searchParams.get("stageId");
  const currentLobby = searchParams.get("lobby");

  const [currentStage, setCurrentStage] = useState(
    stages && stages.length > 0
      ? stages.find((s) => s.id === parseInt(stageId, 10))
      : undefined
  );
  const FILTER_DATA_EXCLUDE_PARAMS = [
    "job_uuid",
    "sort_by",
    "sort_order",
    "page",
    "page_size",
  ];

  let CANDIDATE_STATUS_LIST;

  if (currentStage) {
    CANDIDATE_STATUS_LIST =
      currentStage.stage_type === "exam"
        ? EXAM_CANDIDATE_STATUS
        : INTERVIEW_GD_CANDIDATE_STATUS;
  } else if (currentLobby === "ALL_APPLICANTS") {
    CANDIDATE_STATUS_LIST = AA_CANDIDATE_STATUS;
  } else {
    CANDIDATE_STATUS_LIST = PLACED_CANDIDATE_STATUS;
  }

  const [openAddStageModal, setOpenAddStageModal] = useState(false);
  const [openBulkStageModal, setOpenBulkStageModal] = useState(false);
  const [openEditStageModal, setOpenEditStageModal] = useState(false);
  const [addExamModal, setAddExamModal] = useState(false);
  const [isAddingToStage, setIsAddingToStage] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [showGDModal, setShowGDModal] = useState(false);

  const [candidatePanelDetails, setCandidatePanelDetails] = useState();
  const [candidatePanelId, setCandidatePanelId] = useState();
  const [scrollToRef, setScrollToRef] = useState("");

  const [candidateID, setCandidateID] = useState(null);
  const [selectedGroupDetails, setSelectedGroupDetails] = useState(null);
  const [examCandidateId, setExamCandidateId] = useState([]);
  const [isDeletingStage, setIsDeletingStage] = useState(false);
  const [stageExamDetails, setStageExamDetails] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showScheduleInterviewModal, setShowScheduleInterviewModal] =
    useState(false);
  const [showAddEvaluatorModal, setShowAddEvaluatorModal] = useState(false);
  const [examEvaluators, setExamEvaluators] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [isSingleOperation, setIsSingleOperation] = useState(false);
  const [interview, setInterview] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showMultiSlotConfirmation, setShowMultiSlotConfirmation] =
    useState(false);
  const [showMultiSlotModal, setShowMultiSlotModal] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [organisationData, setOrganisationData] = useState();
  const [alreadyGDOverCandidates, setAlreadyGDOverCandidates] = useState([]);

  const statusParam = useRef(searchParams.getAll("statuses"));
  const locationParams = useRef(searchParams.getAll("locations"));
  const groupsParams = useRef(searchParams.getAll("groups"));

  const isUserRoleAllowed = useCheckUserRole([USER_ROLES.ADMIN]);
  const checkHiringManagerUserRole = useCheckUserRole([
    USER_ROLES.HIRING_MANAGER,
  ]);

  const isDragAndDropAllowed = isUserRoleAllowed || checkHiringManagerUserRole;

  if (parseInt(searchParams.get("page"), 10) > meta?.total_pages) {
    const urlParams = new URLSearchParams(window.location.search);

    const paramsHash = [...urlParams.keys()].reduce((acc, key) => {
      if (key !== "page") {
        acc[key] = urlParams.get(key);
      }
      return acc;
    }, {});
    setSearchParams({
      page: meta?.page_number,
      ...paramsHash,
    });
  }

  const openSidePanel = (candidateId, scrollRef = "") => {
    if (scrollRef) {
      setScrollToRef(scrollRef);
    } else {
      setScrollToRef("");
    }
    setCandidatePanelId(candidateId);
    setShowSidePanel(true);
  };

  const closeSidePanel = () => {
    setScrollToRef("");
    setCandidatePanelDetails(null);

    setShowSidePanel(false);
  };

  const getGDDetails = () => {
    if (currentStage.id) {
      getGroups(
        params.uuid,
        currentStage.id,
        SORT_ORDER.ASC,
        SORT_BY.CREATED_AT
      ).then((response) => {
        setGroups(
          response.data.map((group, index) => ({
            ...group,
            serialNo: index + 1,
          }))
        );
      });
    }
  };

  useEffect(() => {
    setGroups([]);
    if (currentStage && currentStage.stage_type === STAGE_TYPE[1].value) {
      getGDDetails();
    }
  }, [currentStage?.id]);

  useEffect(() => {
    setStageExamDetails([]);
    if (currentStage && currentStage.stage_type === "exam") {
      getExamData();
    }
  }, [currentStage?.id]);

  useEffect(() => {
    if (showSidePanel && data.length > 0) {
      setCandidatePanelDetails(
        ...data.filter((candidate) => candidate.id === candidatePanelId)
      );
    }
  }, [showSidePanel, data, candidatePanelId]);

  useEffect(() => {
    getOrganisationDetails().then((response) => {
      setOrganisationData(response.data);
    });
  }, []);

  const getStageLabelByKey = (key) => {
    const lobbyType = LOBBY_TYPES.find((lobby) => lobby.key === key);
    return lobbyType ? lobbyType.label : null;
  };

  useEffect(() => {
    if (job && (currentStage || currentLobby)) {
      const intermediateLinkList = [
        {
          name: "Jobs",
          link: `/jobs`,
        },
        { name: job?.title || "", link: `/jobs/${params?.uuid || ""}` },
      ];
      const currentPageName =
        getStageLabelByKey(currentLobby) || currentStage?.name;
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList,
            currentPageName,
          },
        })
      );
    }
  }, [currentLobby, currentStage, job, showSidePanel, searchParams]);

  const getExamData = () => {
    getExams(params.uuid, currentStage.id).then((response) => {
      setStageExamDetails(response.data);
    });
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  useEffect(() => {
    dispatch(getJobAction(params.uuid))
      .unwrap()
      .then((response) => setJob(response));
    dispatch(getStagesAction(params.uuid));
  }, [dispatch, params.uuid]);

  useEffect(() => {
    if (stages && stages.length > 0 && stageId) {
      const stage = stages.find((s) => s.id === parseInt(stageId, 10));

      if (stage && stages.length > 0) {
        setCurrentStage(stage);
      }
    } else {
      setCurrentStage(undefined);
    }

    dispatch(resetSelection());
  }, [stages, stageId, searchParams]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sortByParam = queryParams.get("sort_by");
    const sortOrderParam = queryParams.get("sort_order");
    if (sortByParam && sortOrderParam) {
      setSortBy(sortByParam);
      setSortOrder(sortOrderParam);
    }
  }, [location.search]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    let reqSearchParams = {
      ...Object.fromEntries(urlParams.entries()),
    };

    if ("statuses" in reqSearchParams) {
      reqSearchParams = {
        ...reqSearchParams,
        statuses: urlParams.getAll("statuses"),
      };
    }
    if ("locations" in reqSearchParams) {
      reqSearchParams = {
        ...reqSearchParams,
        locations: urlParams.getAll("locations"),
      };
    }

    if ("groups" in reqSearchParams) {
      reqSearchParams = {
        ...reqSearchParams,
        groups: urlParams.getAll("groups"),
      };
    }

    if (
      "has_portfolio" in reqSearchParams &&
      reqSearchParams.has_portfolio !== "true" &&
      reqSearchParams.has_portfolio !== "false"
    ) {
      delete reqSearchParams.has_portfolio;
    }

    if (
      "willing_to_relocate" in reqSearchParams &&
      reqSearchParams.willing_to_relocate !== "true" &&
      reqSearchParams.willing_to_relocate !== "false"
    ) {
      delete reqSearchParams.willing_to_relocate;
    }
  }, []);

  const setErrorsOnFields = useCallback(
    (errors) => {
      if (errors) {
        Object.entries(errors).forEach(([key, value]) => {
          setError(key, { message: value[0] });
        });
      }
    },
    [setError]
  );

  const checkBulkStageAccess = () => {
    if (
      stages?.length === 0 &&
      !openBulkStageModal &&
      (isUserRoleAllowed || checkHiringManagerUserRole)
    ) {
      return true;
    }
    return false;
  };

  const setCandidateHeaderViewAfterSort = () =>
    scrollToCandidatesHeaderRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

  const handleFilterModal = () => {
    if (currentStage && currentStage.stage_type === STAGE_TYPE[1].value) {
      getGroups(params.uuid, currentStage.id)
        .then((response) => {
          const selectedGroups = response.data?.filter((group) =>
            searchParams
              .getAll("groups")
              .some((selectedUuid) => selectedUuid === group.uuid)
          );
          const groupOptions = [];
          selectedGroups?.forEach((grp) =>
            groupOptions.push({ title: grp.title, uuid: grp.uuid })
          );
          groupsRef.current = groupOptions;
          setShowFilterModal(!showFilterModal);
        })
        .catch((error) => {
          showAlert(
            ALERT_TYPE[error.response.data.meta.message_type],
            error.response.data.meta.message
          );
          setShowFilterModal(!showFilterModal);
        });
    } else if (
      (currentStage && currentStage.stage_type !== STAGE_TYPE[1].value) ||
      currentLobby
    ) {
      setShowFilterModal(!showFilterModal);
    }
  };

  const resetSearchFormToDefault = useCallback(async () => {
    resetFilterForm(
      searchParams,
      CANDIDATE_STATUS_LIST,
      reset,
      groupsRef.current
    );
  }, [CANDIDATE_STATUS_LIST, reset, searchParams]);

  const getCandidateStageData = useCallback(
    (hasSortingParams) => {
      let action;
      let data;

      // defined inside this function due to unexpected results otherwise
      const generateFilterData = (paramsToExclude) => {
        return Object.fromEntries(
          Object.entries(data).filter((entry) => {
            const key = entry[0];
            const value = data[key];

            const isExcludedKey = paramsToExclude.includes(key);
            const isValidValue =
              (!Array.isArray(value) && value !== null) ||
              (Array.isArray(value) && value.length > 0);

            return !isExcludedKey && isValidValue;
          })
        );
      };

      if (currentLobby === "PLACED") {
        action = getPlacedCandidatesAction;
        data = {
          job_uuid: params.uuid,
          candidate_name: searchParams.get("candidate_name"),
          id: searchParams.get("candidate_id"),
          statuses: searchParams.getAll("statuses"),
          sort_by: searchParams.get("sort_by"),
          sort_order: searchParams.get("sort_order"),
          page: parseInt(searchParams.get("page"), 10),
          page_size:
            parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE,
        };
      } else if (currentLobby === "ALL_APPLICANTS") {
        action = getJobCandidatesAction;
        data = {
          job_uuid: params.uuid,
          candidate_name: searchParams.get("candidate_name"),
          id: searchParams.get("candidate_id"),
          statuses: searchParams.getAll("statuses"),
          locations: searchParams.getAll("locations"),
          project: searchParams.get("project"),
          willing_to_relocate: searchParams.get("willing_to_relocate"),
          has_portfolio: searchParams.get("has_portfolio"),
          sort_by: searchParams.get("sort_by"),
          sort_order: searchParams.get("sort_order"),
          page: parseInt(searchParams.get("page"), 10),
          page_size:
            parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE,
          academic_marks:
            searchParams.get("academic_marks") &&
            JSON.stringify(
              JSON.parse(decodeURIComponent(searchParams.get("academic_marks")))
            ),
        };

        filterData.current = generateFilterData(FILTER_DATA_EXCLUDE_PARAMS);
      } else if (stageId) {
        data = {
          job_uuid: params.uuid,
          stages_id: stageId,
          page: parseInt(searchParams.get("page"), 10) || 1,
          page_size:
            parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE,
          candidate_name: searchParams.get("candidate_name"),
          id: searchParams.get("candidate_id"),
          cutoff_marks: searchParams.get("cutoff_marks"),
          cutoff_type: searchParams.get("cutoff_type"),
          marks_type: searchParams.get("marks_type"),
          has_different_sectional_cut_off_marks: searchParams.get(
            "has_different_sectional_cut_off_marks"
          ),
          sort_by: searchParams.get("sort_by"),
          sort_order: searchParams.get("sort_order"),
          statuses: searchParams.getAll("statuses"),
          groupUuids: searchParams.getAll("groups"),
        };

        if (searchParams.get("from_date")) {
          data.from_date = new Date(
            searchParams.get("from_date")
          ).toISOString();
        }

        if (searchParams.get("to_date")) {
          data.to_date = new Date(searchParams.get("to_date")).toISOString();
        }

        if (
          searchParams.get("has_different_sectional_cut_off_marks") === "true"
        ) {
          const sectionalData = searchParams.get("sectional_cutoff");
          data.sectional_cutoff = JSON.stringify(
            JSON.parse(decodeURIComponent(sectionalData))
          );
        }

        if (searchParams.get("from_date") && searchParams.has("appeared")) {
          data.appeared = searchParams.get("appeared") === "true";
        }

        action = getStageCandidatesAction;

        filterData.current = generateFilterData([
          ...FILTER_DATA_EXCLUDE_PARAMS,
          "stages_id",
        ]);
      }

      if (action && data) {
        dispatch(action(data))
          .unwrap()
          .then(() => {
            setShowSidePanel(false);
            if (scrollToHeader.current || hasSortingParams) {
              setCandidateHeaderViewAfterSort();
            }
            setShowFilterModal(false);
            resetSearchFormToDefault();
          })
          .catch(({ errors }) => {
            setErrorsOnFields(errors);
          });
      }
    },
    [
      currentLobby,
      dispatch,
      params.uuid,
      resetSearchFormToDefault,
      searchParams,
      setErrorsOnFields,
      stageId,
    ]
  );

  useEffect(() => {
    const hasSortingParams =
      searchParams.get("sort_by") || searchParams.get("sort_order");
    getCandidateStageData(hasSortingParams);
  }, [currentLobby, searchParams]);

  useEffect(() => {
    const isValidLobby =
      currentLobby === "PLACED" || currentLobby === "ALL_APPLICANTS";

    if (!stageId && !isValidLobby) {
      setSearchParams(
        constructQueryParams({
          page: 1,
          lobby: "ALL_APPLICANTS",
        })
      );
    } else if (
      /[a-z]/gi.test(searchParams.get("page")) ||
      /[a-z]/gi.test(searchParams.get("page_size"))
    ) {
      const urlParams = new URLSearchParams(window.location.search);

      const paramsHash = [...urlParams.keys()].reduce((acc, key) => {
        if (key !== "page" && key !== "page_size") {
          acc[key] = urlParams.get(key);
        }
        return acc;
      }, {});
      // SearchParamsFinder helper method to fetch URL params
      setSearchParams({
        page:
          parseInt(SearchParamsFinder(searchParams, "page"), 10) ||
          meta?.page_number ||
          1,
        page_size:
          parseInt(SearchParamsFinder(searchParams, "page_size"), 10) ||
          meta?.page_size ||
          DEFAULT_PAGE_SIZE,
        ...paramsHash,
      });
    }
  }, [
    currentLobby,
    meta?.page_number,
    meta?.page_size,
    searchParams,
    setSearchParams,
    stageId,
  ]);

  const clearFiltersSetStageLobby = (
    toStageId = undefined,
    toLobby = undefined
  ) => {
    reset({});
    const filterParams = new URLSearchParams(window.location.search);

    const paramsToDelete = [];
    filterParams.forEach((_, param) => {
      // Here we are assuming other than PIPELINE_PRESERVED_PARAMS_NAME if any param is there it is coming from filter
      if (!PIPELINE_PRESERVED_PARAMS_NAME.includes(param)) {
        paramsToDelete.push(param);
      }
    });

    paramsToDelete.forEach((param) => {
      filterParams.delete(param);
    });

    if (!toStageId || toLobby) {
      filterParams.delete("stageId");
    } else {
      filterParams.delete("lobby");
    }

    const reqSearchParams = {
      ...Object.fromEntries(filterParams.entries()),
      ...(toStageId ? { stageId: toStageId } : {}),
      ...(toLobby ? { lobby: toLobby } : {}),
      page: 1,
      page_size: DEFAULT_PAGE_SIZE,
    };

    setSearchParams(reqSearchParams);
  };

  const onDeleteStage = () => {
    return dispatch(
      deleteStageAction({ jobUuid: params.uuid, stageId: currentStage.id })
    )
      .unwrap()
      .then((response) => {
        clearFiltersSetStageLobby(undefined, "ALL_APPLICANTS");
        setIsDeletingStage(false);
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );
      })

      .catch(({ errors, message, meta }) => {
        if (message) {
          showAlert("danger", message);
        } else if (errors?.detail) {
          showAlert("danger", errors?.detail);
        } else if (meta?.message) {
          showAlert("danger", meta?.message);
        } else {
          showAlert("danger", errors.message);
        }
      })
      .finally(() => {
        setIsDeletingStage(false);
        dispatch(resetSelection());
      });
  };

  const onSetCurrentPage = (
    page,
    pageSizeParam = parseInt(searchParams.get("page_size"), 10) ||
      DEFAULT_PAGE_SIZE
  ) => {
    scrollToHeader.current = true;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("candidate_id");
    let paramsHash = {};

    urlParams.forEach((_, key) => {
      if (
        key !== "statuses" ||
        key !== "locations" ||
        key !== "groups" ||
        key !== "appeared"
      ) {
        paramsHash = { ...paramsHash, [key]: urlParams.get(key) };
      } else if (key === "appeared") {
        paramsHash = { ...paramsHash, appeared: urlParams.get(key) === "true" };
      }
    });

    if (urlParams.has("statuses")) {
      paramsHash = { ...paramsHash, statuses: urlParams.getAll("statuses") };
    }
    if (urlParams.has("locations")) {
      paramsHash = { ...paramsHash, locations: urlParams.getAll("locations") };
    }

    if (urlParams.has("groups")) {
      paramsHash = { ...paramsHash, groups: urlParams.getAll("groups") };
    }

    setSearchParams({ ...paramsHash, page, page_size: pageSizeParam });
  };

  const searchFormSubmitHandler = (formData) => {
    filterSearchParams(
      formData,
      stageExamDetails,
      statusParam,
      locationParams,
      groupsParams,
      setSearchParams
    );
  };

  const handleClearFilter = () => {
    if (stageId) {
      clearFiltersSetStageLobby(stageId);
    } else {
      clearFiltersSetStageLobby(undefined, currentLobby);
    }
    setShowFilterModal(false);
  };

  const anyFilterApplied = () =>
    PIPELINE_FILTER_PARAMS_NAME.some((param) => searchParams.has(param));

  const checkedHandler = ({ checked, value }) => {
    if (checked) {
      dispatch(addSelectedIds({ objectIds: [value], objectType: "candidate" }));
    } else {
      dispatch(removeSelectedIds({ objectIds: [value] }));
    }
  };

  const onClickSort = (sortData, flag = false) => {
    let sortByColumn;
    if (sortData) {
      sortByColumn = sortData;
    } else {
      sortByColumn = "";
      setSortOrder("");
      return;
    }

    let newSortOrder;
    if (flag) {
      if (sortByColumn !== sortBy && sortOrder !== "") {
        newSortOrder = SORT_ORDER.ASC;
      } else if (sortOrder === SORT_ORDER.ASC || sortOrder === "") {
        newSortOrder = SORT_ORDER.DESC;
      } else {
        newSortOrder = "";
      }
    } else if (sortByColumn !== sortBy || sortOrder === "") {
      newSortOrder = SORT_ORDER.ASC;
    } else if (sortOrder === SORT_ORDER.ASC) {
      newSortOrder = SORT_ORDER.DESC;
    } else {
      newSortOrder = "";
    }

    setSortBy(sortByColumn);
    setSortOrder(newSortOrder);

    const urlSearchParams = new URLSearchParams(window.location.search);

    if (newSortOrder) {
      urlSearchParams.set("sort_by", sortByColumn);
      urlSearchParams.set("sort_order", newSortOrder);
    } else {
      urlSearchParams.delete("sort_order");
      urlSearchParams.delete("sort_by");
    }

    setSearchParams(urlSearchParams);
  };

  const getTitle = () => {
    if (currentStage) {
      return currentStage.name;
    }
    if (currentLobby !== undefined) {
      return getStageLabelByKey(currentLobby);
    }
    return "Pipeline";
  };

  const handleAccept = () => {
    setShowMultiSlotConfirmation(false);
    setShowMultiSlotModal(true);
  };

  const checkJobClosedStatus = () => job?.status === JOBS_STATUSES[3];
  const isJobClosed = checkJobClosedStatus();

  if (isLoading || isStageLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="h-full w-full px-4 md:px-6">
      <PipelineMetaContent getTitle={getTitle} />
      {checkBulkStageAccess() && !isLoading && (
        <div className="pt-3 pb-1 bg-gray-lighterShade3">
          <ActionAlert
            message={
              <p className="xs:pr-0 pr-4">
                We have suggested a few stages for you to get started.
              </p>
            }
            alertType="primary"
            iconVerticalAlignment="xs:self-center self-end"
            btnName="Review and Create"
            buttonTitle="Review and Create"
            buttonType="primary"
            customButtonWidth={41}
            iconName="info"
            customClassName="mb-2"
            handleButtonClick={() => setOpenBulkStageModal(true)}
          />
        </div>
      )}
      <div
        className={classNames(
          `sticky z-20 bg-gray-lighterShade3
            ${
              checkBulkStageAccess()
                ? "top-[56px] sm:top-[50px]"
                : "top-[44px] sm:top-[44px]"
            }`
        )}
      >
        <TabList
          lobbyTabs={LOBBY_TYPES}
          currentTab={currentLobby}
          currentStageTab={currentStage?.id.toString()}
          stageTabs={stages?.map((stage) => ({
            label: stage.name,
            key: stage.id.toString(),
            sequence: stage.sequence,
          }))}
          paramBased
          onTabClick={(key) => {
            clearFiltersSetStageLobby(undefined, key);
          }}
          onStageTabClick={(key) => {
            clearFiltersSetStageLobby(key, undefined);
          }}
          isDragAndDropAllowed={isDragAndDropAllowed}
        />
      </div>
      <div
        className={classNames("flex md:flex-row", {
          "flex-col-reverse": stageId,
        })}
      >
        <div>
          <JobVisibility job={job} stageCount={stages?.length} />
        </div>
        <div className="flex ml-auto">
          <div className="flex ml-auto items-center mt-4">
            <div>
              <span className="relative">
                {anyFilterApplied() ? (
                  <>
                    <HiFilter
                      className="mr-4 text-2xl text-primary-main hover:cursor-pointer"
                      onClick={() => handleFilterModal()}
                      data-tooltip-content="Filter Candidates"
                      data-tooltip-id="filterModal"
                      data-testid="filter-records-btn"
                    />
                    <span className="dot" />
                  </>
                ) : (
                  <BiFilterAlt
                    className="mr-4 text-2xl text-primary-main hover:cursor-pointer hover:text-primary-light"
                    onClick={() => handleFilterModal()}
                    data-tooltip-content="Filter Candidates"
                    data-tooltip-id="filterModal"
                    data-testid="filter-records-btn"
                  />
                )}
              </span>
              <Tooltip id="filterModal" variant="dark" className="z-30" />
            </div>
            {(isUserRoleAllowed || checkHiringManagerUserRole) &&
              !isJobClosed && (
                <div className="relative ml-auto flex">
                  <Button
                    title="Add Stage"
                    testId="add-stage-btn"
                    onClick={setOpenAddStageModal}
                    size="sm"
                    customWidth={28}
                    btnClassName={classNames(
                      `btn-secondary hover:font-medium hover:outline hover:outline-1`,
                      { "rounded-r-none": currentStage }
                    )}
                  >
                    Add Stage
                  </Button>
                  {currentStage && (
                    <Button
                      title="View Options"
                      data-testid="three_dot"
                      onClick={() => setShowDropdown((dropdown) => !dropdown)}
                      customWidth={12}
                      btnClassName="btn-secondary rounded-l-none pl-4"
                    >
                      <span>&#9660;</span>
                    </Button>
                  )}

                  <div className="relative mt-2">
                    {showDropdown && (
                      <ThreeDotMenu
                        menuRef={dropdownMenuRef}
                        menuOptions={[
                          {
                            dataTestId: "rename_stage",
                            onClick: () => {
                              setOpenEditStageModal(true);
                              setShowDropdown(false);
                            },
                            icon: (
                              <BsCalendarRange className="mr-2 mt-[2px] text-base" />
                            ),
                            text: "Rename Stage",
                          },
                          {
                            dataTestId: "delete_stage",
                            onClick: () => {
                              setIsDeletingStage(true);
                              setShowDropdown(false);
                            },
                            icon: (
                              <BsTrash className="mr-2 mt-[2px] text-base" />
                            ),
                            text: "Delete Stage",
                          },
                        ]}
                        posFromRight="right-2"
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className="flex ml-auto items-center mt-4">
            {!isLoading &&
              currentStage &&
              currentStage.stage_type === "exam" &&
              stageExamDetails.length === 0 &&
              !isJobClosed &&
              (isUserRoleAllowed || checkHiringManagerUserRole) && (
                <div className="mx-4">
                  <Button
                    title="Create Exam"
                    testId="create-exam-btn"
                    onClick={() => {
                      setAddExamModal(true);
                      setShowDropdown(false);
                    }}
                    size="sm"
                    customWidth={32}
                    btnClassName="btn-secondary mr-3"
                  >
                    Create Exam
                  </Button>
                </div>
              )}
            {!isLoading &&
              currentStage &&
              currentStage.stage_type === STAGE_TYPE[1].value &&
              groups.length === 0 &&
              (isUserRoleAllowed || checkHiringManagerUserRole) && (
                <div className="mx-4">
                  <Button
                    title="Create Group(s)"
                    testId="create-group-btn"
                    onClick={() => {
                      setShowGDModal(true);
                    }}
                    size="sm"
                    customWidth={32}
                    btnClassName="btn-secondary mr-3 normal-case"
                  >
                    Create Group(s)
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="relative mt-2 flex justify-between pb-4">
        {showFilterModal && (
          <CandidateFilterModal
            currentStage={currentStage}
            setShowFilterModal={setShowFilterModal}
            useFormProps={useFormProps}
            searchFormSubmitHandler={searchFormSubmitHandler}
            handleClearFilter={handleClearFilter}
            resetSearchFormToDefault={resetSearchFormToDefault}
            stageExamDetails={stageExamDetails}
            groupsList={groups}
          />
        )}
      </div>

      <div className="flex w-full flex-col justify-between md:flex-row md:flex-wrap">
        <CandidateStatusCard
          isLoading={isLoading}
          currentStageType={currentStage?.stage_type || STAGE_TYPE[0].value}
          statusCounts={meta}
          anyFilterApplied={anyFilterApplied()}
        />

        {currentStage &&
          currentStage.stage_type === STAGE_TYPE[0].value &&
          stageExamDetails.length > 0 &&
          stageExamDetails.map((stageExamDetail) => (
            <StageExamDetails
              isLoading={isLoading}
              stageExamDetail={stageExamDetail}
              stageExamDetails={stageExamDetails}
              currentStage={currentStage}
              setStageExamDetails={setStageExamDetails}
              key={stageExamDetail.id}
              getExamData={getExamData}
              setShowMultiSlotConfirmation={setShowMultiSlotConfirmation}
              isUserRoleAllowed={
                isUserRoleAllowed || checkHiringManagerUserRole
              }
              getCandidateStageData={getCandidateStageData}
              isJobClosed={isJobClosed}
            />
          ))}

        {currentStage &&
          currentStage.stage_type === STAGE_TYPE[1].value &&
          groups.length > 0 && (
            <GroupDiscussionCard
              currentStageId={currentStage.id}
              statusCounts={meta}
              isLoading={isLoading}
              stageGroupDiscussionDetails={groups}
              currentStage={currentStage}
              setStageGroupDiscussionDetails={setGroups}
              getGroupDiscussionData={getGDDetails}
              setSelectedGroupDetails={setSelectedGroupDetails}
              isUserRoleAllowed={
                isUserRoleAllowed || checkHiringManagerUserRole
              }
              getCandidateStageData={getCandidateStageData}
              isJobClosed={isJobClosed}
              setAlreadyGDOverCandidates={setAlreadyGDOverCandidates}
            />
          )}
      </div>

      <div
        ref={scrollToCandidatesHeaderRef} // scroll ref added for when user sorting from header header will be on top
        className={classNames(
          "sm:overflow-x-auto overflow-y-hidden",
          data.length > 0 ? "pt-[88px] mt-[-20px]" : "pt-[10px]",
          showSidePanel && !isEmpty(candidatePanelDetails)
            ? "overflow-x-hidden"
            : "overflow-x-auto"
        )}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <ActivityIndicator />
        ) : data.length > 0 ? (
          <table
            className={classNames(
              "w-full table-auto border-spacing-8 text-sm",
              // eslint-disable-next-line no-nested-ternary
              currentStage
                ? "min-w-[1200px]"
                : currentLobby === "PLACED"
                ? "min-w-[768px]"
                : "min-w-[992px]"
            )}
          >
            <thead>
              <PipelineCandidatesHeader
                data={data}
                totalCandidates={meta?.total_entries}
                currentStage={currentStage}
                currentLobby={currentLobby}
                stageExamDetails={stageExamDetails}
                setIsAddingToStage={setIsAddingToStage}
                setIsUpdatingStatus={setIsUpdatingStatus}
                setShowAddEvaluatorModal={setShowAddEvaluatorModal}
                setExamCandidateId={setExamCandidateId}
                setExamEvaluators={setExamEvaluators}
                setSelectedCandidates={setSelectedCandidates}
                setIsSingleOperation={setIsSingleOperation}
                isUserRoleAllowed={
                  isUserRoleAllowed || checkHiringManagerUserRole
                }
                onClickSort={onClickSort}
                sortOrder={sortOrder}
                sortBy={sortBy}
                isJobClosed={isJobClosed}
                filterData={filterData.current}
                getCandidateStageData={getCandidateStageData}
                organisationData={organisationData}
              />
            </thead>

            <tbody>
              {data.map((candidate, index) => {
                return (
                  <Candidates
                    key={candidate.id}
                    candidate={candidate}
                    currentLobby={currentLobby}
                    candidateCount={meta?.total_entries}
                    currentStage={currentStage}
                    index={index}
                    onCheck={checkedHandler}
                    setIsEditState={setIsAddingToStage}
                    setCandidateID={setCandidateID}
                    setExamCandidateId={setExamCandidateId}
                    setInterview={setInterview}
                    setExamEvaluators={setExamEvaluators}
                    setSelectedCandidates={setSelectedCandidates}
                    setShowScheduleInterviewModal={
                      setShowScheduleInterviewModal
                    }
                    setShowAddEvaluatorModal={setShowAddEvaluatorModal}
                    setIsSingleOperation={setIsSingleOperation}
                    stageExamDetails={stageExamDetails}
                    getCandidateStageData={getCandidateStageData}
                    isUserRoleAllowed={
                      isUserRoleAllowed || checkHiringManagerUserRole
                    }
                    isJobClosed={isJobClosed}
                    showSidePanel={
                      showSidePanel &&
                      candidatePanelDetails?.id === candidate?.id
                    }
                    openSidePanel={openSidePanel}
                    closeSidePanel={closeSidePanel}
                    candidatePanelId={candidatePanelDetails?.id}
                    organisationData={organisationData}
                    groups={groups}
                    setGroups={setGroups}
                    getGDDetails={getGDDetails}
                  />
                );
              })}
            </tbody>
          </table>
        ) : (
          <span className="my-4 block text-lg italic text-gray-main">
            No Candidates Found
          </span>
        )}
      </div>

      {meta?.total_entries > PER_PAGE_RECORD_LIMIT && (
        <Pagination
          currentPage={meta?.page_number}
          totalPage={meta?.total_pages}
          setCurrentPage={onSetCurrentPage}
          totalEntries={meta?.total_entries}
          pageName={getStageLabelByKey(currentLobby) || currentStage?.name}
          pageSize={
            parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE
          }
        />
      )}

      {openAddStageModal && (
        <StageModal
          setOpenModal={setOpenAddStageModal}
          setCurrentStageLobby={clearFiltersSetStageLobby}
        />
      )}

      {addExamModal && (
        <ExamModal
          currentStageId={currentStage?.id}
          setOpenModal={setAddExamModal}
          getExamData={getExamData}
          setShowMultiSlotConfirmation={setShowMultiSlotConfirmation}
        />
      )}

      {isAddingToStage && (
        <AddToStageModal
          currentStage={currentStage?.id}
          candidateID={candidateID}
          setIsEditState={setIsAddingToStage}
        />
      )}

      {openEditStageModal && (
        <StageModal
          currentStage={currentStage}
          setOpenModal={setOpenEditStageModal}
          setCurrentStageLobby={clearFiltersSetStageLobby}
        />
      )}

      {openBulkStageModal && (
        <BulkStageModal
          setOpenBulkStageModal={setOpenBulkStageModal}
          setCurrentStageLobby={clearFiltersSetStageLobby}
        />
      )}

      {isUpdatingStatus && (
        <UpdateStatusModal
          setIsEditState={setIsUpdatingStatus}
          currentStage={currentStage}
          getCandidateStageData={getCandidateStageData}
          filterData={filterData.current}
        />
      )}

      {isDeletingStage && (
        <Modal
          title="Are you sure?"
          description="Are you sure you want to delete this stage?"
          acceptButtonText="Yes"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="No"
          handleAccept={onDeleteStage}
          handleReject={() => setIsDeletingStage(false)}
        />
      )}

      {showMultiSlotConfirmation && (
        <Modal
          title="Schedule slot(s)?"
          acceptButtonText="Yes"
          acceptBtnType="primary"
          handleAccept={handleAccept}
          rejectButtonText="I'll do it later"
          rejectBtnType="secondary"
          handleReject={() => setShowMultiSlotConfirmation(false)}
          onOutsideClickHandler={() => setShowMultiSlotConfirmation(false)}
          customButtonWidth={32}
          customModalWidth="720px"
        >
          You have selected this exam to be a multi-slot exam. Do you want to
          schedule the slot(s) now?
        </Modal>
      )}

      {showMultiSlotModal && (
        <MultiSlotModal
          setShowMultiSlotModal={setShowMultiSlotModal}
          currentStageId={currentStage.id}
        />
      )}

      {showScheduleInterviewModal && (
        <ScheduleInterviewModal
          setShowScheduleInterviewModal={setShowScheduleInterviewModal}
          candidateId={candidateID}
          currentStageId={currentStage.id}
          interview={interview}
          getCandidateStageData={getCandidateStageData}
        />
      )}
      {showAddEvaluatorModal && (
        <AddEvaluatorModal
          setShowAddEvaluatorModal={setShowAddEvaluatorModal}
          candidateId={candidateID}
          currentStageId={currentStage.id}
          examEvaluators={examEvaluators}
          selectedCandidates={selectedCandidates}
          examCandidateId={examCandidateId}
          getCandidateStageData={getCandidateStageData}
          isSingleOperation={isSingleOperation}
        />
      )}

      {showGDModal && (
        <GDModal
          currentStage={currentStage}
          setShowGDModal={setShowGDModal}
          candidateCount={meta?.total_entries}
          getGDDetails={getGDDetails}
        />
      )}

      {selectedGroupDetails && (
        <ScheduleGDModal
          currentStageId={currentStage.id}
          selectedGroupDetails={selectedGroupDetails}
          setSelectedGroupDetails={setSelectedGroupDetails}
          getGDDetails={getGDDetails}
          alreadyGDOverCandidates={alreadyGDOverCandidates}
          getCandidateStageData={getCandidateStageData}
        />
      )}

      <div>
        <SidePanel
          isOpen={showSidePanel}
          cancellable={false}
          onClosePanel={closeSidePanel}
        >
          <CandidateProfilePanel
            candidatePanelDetails={candidatePanelDetails}
            jobTitle={job?.title}
            scrollToRef={scrollToRef}
            onClose={closeSidePanel}
            showSidePanel={showSidePanel}
          />
        </SidePanel>
      </div>
    </div>
  );
};

export default PipeLine;
