import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";

import { getMockTestAnswers } from "api/candidate/examApi";
import { getCandidateAnswers } from "api/erp/questionPaperApi";
import { TabList } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import { NAVBAR_HEIGHT_CUSTOM_EVENT, SITE_TITLE } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import { setApiError } from "store/slices/apiErrorSlice";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import ExamCandidateMetaData from "./ExamCandidateMetaData";
import McqCodingCandidateAnswer from "./McqCodingCandidateAnswer";
import SectionCandidateMetaData from "./SectionCandidateMetaData";

const CandidateAnswer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [examCandidate, setExamCandidate] = useState();
  const [sections, setSections] = useState([]);
  const [sectionTabList, setSectionTabList] = useState([]);
  const [currentSection, setCurrentSection] = useState();
  const [navHeight, setNavHeight] = useState("71");
  const [searchParams, setSearchParams] = useSearchParams();
  const isSectionalQP = useRef();

  const getAnswers = useCallback(() => {
    const getAnswerFunction = params.candidateId
      ? getCandidateAnswers(params.candidateId, params.examId)
      : getMockTestAnswers(params.examId);
    if (getAnswerFunction) {
      getAnswerFunction
        .then((response) => {
          const { data } = response;
          isSectionalQP.current = data.sections !== undefined;
          if (data) {
            setExamCandidate(data);
          }
          if (data && isSectionalQP.current) {
            const newSectionTabList = data.sections.map((section) => ({
              label: section.name,
              key: section.id.toString(),
            }));
            setSectionTabList(newSectionTabList);
            setSections(data.sections);
            const sectionId = searchParams.get("section_id");

            if (sectionId) {
              const sectionTab = data.sections.find(
                (section) => section.id.toString() === sectionId
              );

              if (sectionTab) {
                setCurrentSection(sectionTab);
              } else {
                dispatch(
                  setApiError({ status: 404, message: "Section not found" })
                );
              }
            } else {
              setCurrentSection(data.sections[0]);
              setSearchParams({ section_id: data.sections[0].id });
            }
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            navigate("/notfound");
          }
        });
    }
  }, [params.examId]);

  const getTitle = () => {
    if (currentSection) {
      return currentSection.name;
    }
    return "Candidate Answers";
  };

  useEffect(() => {
    const handleNavbarHeightUpdate = (event) => {
      const navbarHeight = event?.detail;
      setNavHeight(navbarHeight.toString());
    };

    window.addEventListener(
      NAVBAR_HEIGHT_CUSTOM_EVENT,
      handleNavbarHeightUpdate
    );
    return () =>
      window.removeEventListener(
        NAVBAR_HEIGHT_CUSTOM_EVENT,
        handleNavbarHeightUpdate
      );
  }, []);

  useEffect(() => {
    if (examCandidate && params.candidateId) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              { name: "Jobs", link: "/jobs" },
              { name: examCandidate.job_title, link: `/jobs/${params.uuid}` },
              {
                name: examCandidate.stage_name,
                link: `/jobs/${params.uuid}/pipeline?${constructQueryParams({
                  page: 1,
                  page_size: 20,
                  stageId: examCandidate.stage_id,
                })}`,
              },
              {
                name: examCandidate.candidate_job_id,
                link: `/jobs/${params.uuid}/candidates/${examCandidate.candidate_job_id}`,
              },
            ],
            currentPageName: "Answers",
          },
        })
      );
    } else {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              {
                name: "Mock Tests",
                link: `/mock-tests?${constructQueryParams({
                  page: 1,
                  page_size: 20,
                })}`,
              },
            ],
            currentPageName: examCandidate?.exam_name,
          },
        })
      );
    }
  }, [params.uuid, examCandidate, params.examId]);

  useEffect(() => {
    getAnswers();
  }, []);

  if (!examCandidate) {
    return <ActivityIndicator />;
  }

  return (
    <div
      className={classNames(
        "flex flex-col sm:flex-row w-full bg-gray-lighterShade3 px-4 pt-4 md:px-6",
        { "pt-0": isSectionalQP.current }
      )}
    >
      <Helmet>
        <title>{`${getTitle()} - ${SITE_TITLE}`}</title>
      </Helmet>
      <div className="flex flex-col sm:w-2/3 w-full">
        {isSectionalQP.current && (
          <div
            className="w-full sticky z-10 bg-gray-lighterShade3 overflow-auto"
            style={{ top: `${navHeight}px` }}
          >
            <TabList
              tabs={sectionTabList}
              currentTab={currentSection?.id?.toString()}
              paramBased
              onTabClick={(key) => {
                const sectionTab = sections.find(
                  (section) => section.id == key
                );
                setCurrentSection(sectionTab);
                setSearchParams({ section_id: sectionTab.id });
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
          </div>
        )}
        <div>
          {(currentSection || examCandidate) && (
            <McqCodingCandidateAnswer
              isMockTest={!params.candidateId}
              candidateAnswers={
                currentSection?.candidate_answers ||
                examCandidate?.candidate_answers
              }
              getAnswers={getAnswers}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col w-full order-first sm:order-last sm:w-1/3">
        <div
          className={classNames("w-full sm:sticky top-[50px] pt-1", {
            "top-[93px]": isSectionalQP.current,
          })}
        >
          <span className="ml-6 w-full text-base text-primary-main">
            {examCandidate.candidate_name}
          </span>
          {examCandidate && (
            <ExamCandidateMetaData examCandidate={examCandidate} />
          )}
          {(currentSection?.name ||
            examCandidate?.candidate_answers?.length > 0) && (
            <SectionCandidateMetaData
              currentSection={currentSection || examCandidate}
              isSectional={isSectionalQP.current}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateAnswer;
