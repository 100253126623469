import classNames from "classnames";
import PropTypes from "prop-types";

const CharacterAvatar = ({
  char,
  height,
  width,
  testId,
  bgColor,
  textColor,
  shape,
  textSize,
  optionalClass,
}) => {
  return (
    <div
      data-testid={testId}
      className={classNames(
        "flex justify-center self-center capitalize items-center font-medium",
        `w-${width}`,
        `h-${height}`,
        bgColor,
        textColor,
        shape,
        textSize,
        optionalClass
      )}
    >
      {char}
    </div>
  );
};

CharacterAvatar.defaultProps = {
  height: 24,
  width: 24,
  testId: "character-avatar",
  bgColor: "bg-primary-lightest",
  textColor: "text-primary-main",
  shape: "rounded-full",
  textSize: "text-[4rem]",
  optionalClass: "",
};

CharacterAvatar.propTypes = {
  char: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  testId: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  shape: PropTypes.string,
  textSize: PropTypes.string,
  optionalClass: PropTypes.string,
};

export default CharacterAvatar;
