import Proptypes from "prop-types";

const Card = ({ header: CardHeader, children }) => {
  return (
    <article className="rounded-md border border-gray-lighter bg-white shadow-sm">
      {CardHeader}
      {children}
    </article>
  );
};

Card.defaultTypes = {
  children: null,
};

Card.propTypes = {
  header: Proptypes.node,
  children: Proptypes.node,
};

export default Card;
