import { useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { camelCase, capitalize, startCase } from "lodash";
import PropTypes from "prop-types";

import { Button } from "components/atoms";
import Pill from "components/atoms/Pill";
import CopyLink from "components/common/CopyLink";
import { DEFAULT_PAGE_SIZE, USER_ROLES } from "constants/common";
import { JOB_STATUS, JOB_VISIBILITY } from "constants/jobs";
import { constructQueryParams } from "helpers/constructQueryParams";
import { getDomainName } from "helpers/utils";
import useCheckUserRole from "hooks/useCheckUserRole";

import AllowedColleges from "./AllowedColleges";

const Job = ({ job, onJobClick, isUserRoleAllowed }) => {
  const navigate = useNavigate();
  const [showRestrictedCollege, setShowRestrictedCollege] = useState(false);

  const checkHiringManagerUserRole = useCheckUserRole([
    USER_ROLES.HIRING_MANAGER,
  ]);

  const handleViewPipelineClick = () => {
    onJobClick();
    navigate(
      `/jobs/${job.uuid}/pipeline?${constructQueryParams({
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
        lobby: "ALL_APPLICANTS",
      })}`
    );
  };

  return (
    <>
      <td className="p-2 pl-4">
        <Link
          data-testid="job-title"
          to={`/jobs/${job.uuid}`}
          onClick={() => onJobClick(job.uuid)}
        >
          <p
            title={`View ${job.title}`}
            className="break-word pt-2 pr-2 pb-3 text-base leading-5 text-primary-main"
          >
            {job.title}

            {job.hidden && (
              <span className="rounded-md border border-primary-light  px-1 ml-2 text-sm">
                Hidden
              </span>
            )}
          </p>

          <p className="mr-2 whitespace-pre-wrap text-gray-dark">
            Vacancies: {job.no_of_positions > 0 ? job.no_of_positions : ""}
            {job.status === "active" && `  |  Applicants: ${job.total}`}
          </p>
        </Link>
      </td>

      <td className="p-2">
        <div className="flex justify-center">
          <Pill
            text={startCase(JOB_STATUS[camelCase(job.status)].text)}
            type={JOB_STATUS[camelCase(job.status)].type}
          />
        </div>
      </td>

      <td className="break-words p-2 leading-5 text-gray-dark">
        {job.location}
      </td>

      <td className="break-words p-2 leading-5 text-gray-dark">
        {job.visibility === JOB_VISIBILITY.restricted ? (
          <div className="flex">
            {capitalize(JOB_VISIBILITY.restricted)}
            <FaInfoCircle
              className="ml-1 cursor-pointer text-secondary-alertDark"
              size={18}
              onClick={() => setShowRestrictedCollege(true)}
            />
          </div>
        ) : (
          capitalize(job.visibility)
        )}
      </td>

      <td className="text-center">
        <Button
          title="View Pipeline"
          btnName="Pipeline"
          testId="view-pipeline-btn"
          type="primary"
          size="sm"
          customWidth={24}
          btnClassName="whitespace-nowrap"
          onClick={handleViewPipelineClick}
        />
      </td>
      <td className="text-center">
        {job.status == "active" &&
          (isUserRoleAllowed || checkHiringManagerUserRole) && (
            <CopyLink
              link={`https://app.${getDomainName()}/jobs/${job.uuid}/details`}
              tooltipId="copyLink"
              tooltipContent="Copy Shareable Link"
            />
          )}
      </td>
      <td>
        {(isUserRoleAllowed || checkHiringManagerUserRole) && (
          <button
            data-testid="clone-job-btn"
            onClick={() => navigate(`/jobs/${job?.uuid}/edit?clone=true`)}
            className="cursor-pointer btn-icon-dark-gray"
          >
            <AiOutlineCopy
              className="text-xl text-primary-main"
              data-tooltip-id="cloneJob"
              data-tooltip-content="Clone Job"
            />
          </button>
        )}

        <Tooltip id="cloneJob" variant="dark" />
      </td>

      {showRestrictedCollege && (
        <AllowedColleges
          setShowRestrictedCollege={setShowRestrictedCollege}
          allowedColleges={job.colleges}
          allowedSkills={job.skills}
          allowedQualifications={job.qualifications}
          restrictedByColleges={job?.restricted_by_colleges}
          restrictedByQualifications={job?.restricted_by_qualifications}
          restrictedBySkills={job?.restricted_by_skills}
        />
      )}
    </>
  );
};

Job.propTypes = {
  job: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    requirements: PropTypes.string,
    visibility: PropTypes.string,
    colleges: PropTypes.array,
    skills: PropTypes.array,
    qualifications: PropTypes.array,
    hidden: PropTypes.bool,
    created_at: PropTypes.string,
    created_by: PropTypes.string,
    status: PropTypes.string,
    no_of_positions: PropTypes.number,
    total: PropTypes.number,
    status_updated_at: PropTypes.string,
    updated_by: PropTypes.string,
    restricted_by_colleges: PropTypes.bool,
    restricted_by_qualifications: PropTypes.bool,
    restricted_by_skills: PropTypes.bool,
  }),
  onJobClick: PropTypes.func,
  isUserRoleAllowed: PropTypes.bool,
};

export default Job;
