import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { upperCase } from "lodash";
import PropTypes from "prop-types";

import { QUESTION_TYPE } from "app/constants";
import { Modal } from "components/molecules";
import { requiredValidation } from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";

const QuestionBankModal = ({ setIsEditState }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    setError,
  } = useForm();

  const navigate = useNavigate();

  const submitHandler = (data) => {
    data.question_type = data.question_type?.name;
    navigate(
      `/questions_manager/question_bank?question_type=${data.question_type}`
    );
    setIsEditState(false);

    if (!data.question_type) {
      return Promise.resolve(
        setError("question_type", { message: "can't be blank" })
      );
    }
  };

  return (
    <Modal
      title="Generate Question"
      testId="generate-question-modal"
      acceptButtonText="Proceed"
      acceptBtnType={isDirty ? "primary" : "disabled"}
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setIsEditState(false)}
      isDirty={isDirty}
    >
      <form
        data-testid="modal-body"
        className="my-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <label
          htmlFor="question-type"
          className={`required mt-2 mb-1 text-md block font-medium ${
            errors.question_paper_type && "text-danger-main"
          }`}
        >
          Type
        </label>

        <Controller
          name="question_type"
          control={control}
          defaultValue={null}
          rules={{ required: requiredValidation() }}
          render={({ field }) => (
            <Select
              {...field}
              options={QUESTION_TYPE}
              getOptionLabel={(option) => upperCase(option.name)}
              getOptionValue={(option) => option.name}
              id="question-type"
              menuPlacement="auto"
              maxMenuHeight={120}
              placeholder="Select question type"
              styles={reactSelectStyle}
              isSearchable={false}
              isClearable={false}
              className="mb-12"
            />
          )}
        />

        {errors.question_paper_type && (
          <p className="text-sm text-danger-dark">
            {errors.question_paper_type.message}
          </p>
        )}
      </form>
    </Modal>
  );
};

QuestionBankModal.propTypes = {
  questionPaper: PropTypes.shape({
    name: PropTypes.string,
    question_paper_type: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
};

export default QuestionBankModal;
