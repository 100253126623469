import { createSlice } from "@reduxjs/toolkit";

import {
  createWorkExperiencesAction,
  deleteWorkExperiencesAction,
  getWorkExperiencesAction,
  updateWorkExperiencesAction,
} from "store/thunkActions/candidate/workExperienceThunk";

export const getWorkExperience = createSlice({
  name: "workExperience",
  initialState: {
    workExperiences: [],
  },
  reducers: {},
  extraReducers: {
    [getWorkExperiencesAction.fulfilled]: (state, action) => {
      state.workExperiences = action.payload;
    },
    [createWorkExperiencesAction.fulfilled]: (state, action) => {
      state.workExperiences.push(action.payload.data);
    },
    [updateWorkExperiencesAction.fulfilled]: (state, action) => {
      const index = state.workExperiences.findIndex(
        (workExperience) => workExperience.id == action.payload.data.id
      );
      state.workExperiences[index] = action.payload.data;
    },
    [deleteWorkExperiencesAction.fulfilled]: (state, action) => {
      const index = state.workExperiences.findIndex(
        (workExperience) => workExperience.id == action.payload.data.id
      );
      state.workExperiences.splice(index, 1);
    },
  },
});

export default getWorkExperience.reducer;
