import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addCandidateLanguage,
  getCandidateLanguage,
  removeCandidateLanguage,
  updateCandidateLanguage,
} from "api/candidate/profileApi";

export const getCandidateLanguagesAction = createAsyncThunk(
  "language/get/candidate-languages",
  async () => {
    const response = await getCandidateLanguage();
    return response.data;
  }
);

export const createCandidateLanguagesAction = createAsyncThunk(
  "language/create/candidate-languages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addCandidateLanguage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateCandidateLanguagesAction = createAsyncThunk(
  "language/update/candidate-languages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCandidateLanguage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCandidateLanguagesAction = createAsyncThunk(
  "language/delete/candidate-languages",
  async (language, { rejectWithValue }) => {
    try {
      const response = await removeCandidateLanguage(language.id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
