import Proptypes from "prop-types";

const PILL_COMMON_CLASS =
  "border rounded-md px-2 py-1 w-fit h-fit text-xs text-center";

const Pill = ({ text, type, testId }) => (
  <div data-testid={testId} className={`${PILL_COMMON_CLASS} ${type}-pill`}>
    {text}
  </div>
);

Pill.propTypes = {
  text: Proptypes.oneOfType([Proptypes.string, Proptypes.number]).isRequired,
  type: Proptypes.oneOf(["success", "info", "warning", "danger"]).isRequired,
  testId: Proptypes.string,
};

export default Pill;
