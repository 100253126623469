import { AiOutlinePlus } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { MdOutlineModeEditOutline } from "react-icons/md";
import classNames from "classnames";
import PropTypes from "prop-types";

const PILL_CLASS = (isEditState) =>
  classNames(
    " flex hover:bg-gray-lighter rounded-full items-center",
    isEditState ? "px-2 py-1" : "p-2 btn-icon-secondary "
  );

const CardContainer = ({
  title,
  addEventButtonTitle,
  addEventButtonTestId,
  setIsAdding,
  isPillType,
  children,
  customContainerClass,
  eventsLength,
  isEditState,
  setEditState,
  customChildrenClass,
  hideAddButton,
}) => {
  return (
    <div className={customContainerClass}>
      <div className="flex justify-between px-2 pb-2">
        <h3 className="text-2xl font-normal">{title}</h3>
        {!hideAddButton && addEventButtonTitle && (
          <button
            title={addEventButtonTitle}
            className="items-center rounded-full border border-primary-main p-2  text-primary-main hover:bg-primary-lightest"
            data-testid={addEventButtonTestId}
            onClick={() => setIsAdding(true)}
          >
            <AiOutlinePlus className="text-lg" />
          </button>
        )}
        {isPillType && (
          <button
            className={classNames(
              `${PILL_CLASS(isEditState)}`,
              "items-center rounded-full border border-primary-main text-primary-main "
            )}
            onClick={() => setEditState(!isEditState)}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {isEditState ? (
              <>
                <FcCheckmark />
                <span className="ml-2 text-sm ">Done</span>
              </>
            ) : eventsLength ? (
              <MdOutlineModeEditOutline className="text-lg" />
            ) : (
              <AiOutlinePlus className="text-lg" />
            )}
          </button>
        )}
      </div>
      <div className={customChildrenClass}>{children}</div>
    </div>
  );
};

CardContainer.defaultProps = {
  customContainerClass: "mx-auto w-full p-4",
  customChildrenClass: "rounded-lg  border border-gray-light bg-white p-4",
  hideAddButton: false,
};

CardContainer.propTypes = {
  title: PropTypes.string,
  addEventButtonTestId: PropTypes.string,
  customContainerClass: PropTypes.string,
  addEventButtonTitle: PropTypes.string,
  setIsAdding: PropTypes.func,
  children: PropTypes.node,
  isPillType: PropTypes.bool,
  eventsLength: PropTypes.number,
  isEditState: PropTypes.bool,
  setEditState: PropTypes.func,
  customChildrenClass: PropTypes.string,
  hideAddButton: PropTypes.bool,
};

export default CardContainer;
