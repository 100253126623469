import api, {
  candidateApi,
  commonApi,
  organisationApi,
  organisationFileApi,
} from "api/axios";
import { isPublicPage } from "helpers/checkPublicPage";
import { setApiError } from "store/slices/apiErrorSlice";

const updateResponseData = ({ data, meta }) => ({ data, meta });

const updateResponseError = ({ response, message, config }) => {
  if (!response && message) {
    return { status: 500, message };
  }

  if (response && response.status === 404) {
    return { status: response.status, message };
  }

  if (response && response.status !== 422 && !isPublicPage()) {
    return {
      status: response.status,
      message: response.statusText,
      url: config.url,
      baseURL: config.baseURL,
      errorMessage: response?.data?.message,
    };
  }
};

const apiErrorDispatchAction = (error, store) => {
  const apiError = updateResponseError(error);
  if (apiError) {
    store.dispatch(setApiError(apiError));
  }
};

export const initializeAxios = (store) => {
  api.interceptors.response.use(
    (response) => {
      return updateResponseData(response.data);
    },
    (error) => {
      apiErrorDispatchAction(error, store);
      return Promise.reject(error);
    }
  );

  commonApi.interceptors.response.use(
    (response) => {
      return updateResponseData(response.data);
    },
    (error) => {
      apiErrorDispatchAction(error, store);
      return Promise.reject(error);
    }
  );

  candidateApi.interceptors.response.use(
    (response) => {
      return updateResponseData(response.data);
    },
    (error) => {
      apiErrorDispatchAction(error, store);
      return Promise.reject(error);
    }
  );

  organisationApi.interceptors.response.use(
    (response) => {
      return updateResponseData(response.data);
    },
    (error) => {
      apiErrorDispatchAction(error, store);
      return Promise.reject(error);
    }
  );

  organisationFileApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      apiErrorDispatchAction(error, store);
      return Promise.reject(error);
    }
  );

  return { api, candidateApi, organisationApi, organisationFileApi };
};
