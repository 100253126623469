import { useEffect, useRef, useState } from "react";
import {
  BsBell,
  BsCalendarPlus,
  BsEyeFill,
  BsPencilSquare,
  BsTrash,
} from "react-icons/bs";
import { MdNotificationsActive } from "react-icons/md";
import { RiEdit2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { format, isAfter, isBefore, isSameDay, parseISO } from "date-fns";
import { lowerCase, startCase, upperCase } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import { deleteExam, notifyExam } from "api/erp/organizationApi";
import { ALERT_TYPE, QUESTION_TYPE } from "app/constants";
import { ThreeDotMenu } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import { Modal } from "components/molecules";
import { EDIT_EXAM, EXAM_WINDOW_END, NOTIFY_EXAM } from "constants/common";
import useAlert from "hooks/useAlert";

import ExamModal from "./ExamModal";
import MultiSlotModal from "./MultiSlotModal";
import SlotViewModal from "./SlotViewModal";

const StageExamDetails = ({
  isLoading,
  stageExamDetail,
  setStageExamDetails,
  currentStage,
  getExamData,
  stageExamDetails,
  isUserRoleAllowed,
  setShowMultiSlotConfirmation,
  getCandidateStageData,
  isJobClosed,
}) => {
  const [addExamModal, setAddExamModal] = useState(false);
  const [multiSlotModalView, setMultiSlotModalView] = useState(false);
  const [isDeleteExamModalOpen, setIsDeleteExamModalOpen] = useState(false);
  const [isSectionalData, setIsSectionalData] = useState([]);
  const [viewSlot, setViewSlot] = useState(false);
  const [options, setOptions] = useState(false);

  const params = useParams();
  const showAlert = useAlert();
  const dropdownMenuRef = useRef();

  const handleDeleteExam = () =>
    deleteExam(params.uuid, currentStage?.id, stageExamDetail.id)
      .then((response) => {
        getCandidateStageData();
        setStageExamDetails((prevExamDetails) =>
          prevExamDetails.filter((exam) => exam.id != response.data.id)
        );
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );
        setIsDeleteExamModalOpen(false);
      })
      .catch((errors) => {
        // todo: when all BE will be changedto new format this extra checking will be deleted
        if (errors.response?.data.message) {
          showAlert("danger", errors.response.data.message);
        } else if (errors.response?.data.meta) {
          showAlert("danger", errors.response.data.meta.message);
        } else {
          showAlert("danger", errors.response?.data.errors.detail);
        }
      });

  const handleNotifyExam = () => {
    notifyExam(params.uuid, currentStage?.id, stageExamDetail.id)
      .then((response) => {
        const updatedExams = stageExamDetails.map((exam) =>
          exam.id === response.data.id ? { ...exam, ...response.data } : exam
        );
        setStageExamDetails(() => updatedExams);
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );
      })
      .catch((errors) => {
        showAlert(
          ALERT_TYPE[errors.response.data.meta.message_type],
          errors.response.data.meta.message
        );
      });
  };

  const handleEditExam = () => {
    if (
      isBefore(parseISO(stageExamDetail?.start_at), new Date()) &&
      isAfter(parseISO(stageExamDetail?.end_at), new Date())
    ) {
      showAlert("danger", EDIT_EXAM);
    } else if (
      isBefore(parseISO(stageExamDetail?.start_at), new Date()) &&
      isBefore(parseISO(stageExamDetail?.end_at), new Date())
    ) {
      showAlert("danger", EXAM_WINDOW_END);
    } else {
      setAddExamModal(true);
    }
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (options && !dropdownMenuRef.current.contains(e.target)) {
        setOptions(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [options]);

  useEffect(() => {
    if (stageExamDetail?.question_paper?.sections) {
      const sectionList = stageExamDetail.question_paper.sections.map(
        (section) => {
          const sectionalData = stageExamDetail.sectional_cut_off.find(
            (list) => list.section_id === section.id
          );
          return {
            id: section.id,
            name: section.name,
            cut_off_marks: sectionalData?.cut_off_marks,
          };
        }
      );
      setIsSectionalData(sectionList);
    }
  }, [stageExamDetail]);

  const handleOnClick = () => {
    if (stageExamDetail?.multi_slot) {
      setOptions((prev) => !prev);
    } else {
      handleEditExam();
    }
  };

  const onScheduleSlotClick = () => {
    if (
      isBefore(parseISO(stageExamDetail?.start_at), new Date()) &&
      isBefore(parseISO(stageExamDetail?.end_at), new Date())
    ) {
      showAlert("danger", EXAM_WINDOW_END);
    } else {
      setMultiSlotModalView(true);
    }
  };

  return (
    <div className="mb-5 mt-2 h-full w-full rounded-lg border border-sky-200 bg-white p-2 shadow-lg md:w-[49%]">
      {isLoading ? (
        <div className="my-8">
          <ActivityIndicator
            containerClass="mt-2"
            svgClassName="w-100%"
            size={12}
          />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between pt-1">
            <span
              title={stageExamDetail?.name}
              className="overflow-hidden text-ellipsis whitespace-nowrap text-xl font-medium capitalize leading-7 hover:cursor-pointer"
            >
              {stageExamDetail?.name}
            </span>

            {isUserRoleAllowed && !isJobClosed && (
              <div className="ml-4 flex flex-row justify-between space-x-3">
                {stageExamDetail?.is_notified ? (
                  <MdNotificationsActive
                    data-testid="bell-notified"
                    className="text-base"
                    onClick={() => handleNotifyExam()}
                  />
                ) : (
                  <BsBell
                    data-testid="bell"
                    className={`cursor-pointer ${
                      isBefore(parseISO(stageExamDetail?.end_at), new Date()) &&
                      "opacity-25"
                    }`}
                    data-tooltip-content={
                      isBefore(parseISO(stageExamDetail?.end_at), new Date())
                        ? NOTIFY_EXAM
                        : ""
                    }
                    data-tooltip-id="notify"
                    onClick={() => handleNotifyExam()}
                  />
                )}
                <BsTrash
                  data-testid="delete-exam-btn"
                  onClick={() => setIsDeleteExamModalOpen(true)}
                  className="cursor-pointer text-base"
                />
                <BsPencilSquare
                  data-testid="edit-exam-btn"
                  onClick={handleOnClick}
                  className={classNames(
                    "mx-5 text-base text-primary-main hover:cursor-pointer",
                    {
                      "opacity-25":
                        !stageExamDetail.multi_slot &&
                        isBefore(
                          parseISO(stageExamDetail?.start_at),
                          new Date()
                        ),
                    }
                  )}
                  data-tooltip-id="editExam"
                />
                <div className="relative top-1">
                  {options && (
                    <ThreeDotMenu
                      menuRef={dropdownMenuRef}
                      menuOptions={[
                        {
                          dataTestId: "edit-exam",
                          onClick: () => {
                            handleEditExam();
                            setOptions(false);
                          },
                          icon: (
                            <RiEdit2Line
                              className={classNames(
                                "mr-2 mt-[1.5px] text-base",
                                {
                                  "opacity-10": isBefore(
                                    parseISO(stageExamDetail?.start_at),
                                    new Date()
                                  ),
                                }
                              )}
                            />
                          ),
                          text: "Edit Exam",
                        },
                        {
                          dataTestId: "view-slots",
                          onClick: () => {
                            setViewSlot(true);
                            setOptions(false);
                          },
                          icon: (
                            <BsEyeFill className="mr-2 mt-[1.5px] text-base" />
                          ),
                          text: "View Slot(s)",
                        },
                        {
                          dataTestId: "schedule-slots",
                          onClick: () => {
                            onScheduleSlotClick(true);
                            setOptions(false);
                          },
                          icon: (
                            <BsCalendarPlus
                              className={classNames(
                                "mr-2 mt-[1.5px] text-base",
                                {
                                  "opacity-10": isBefore(
                                    parseISO(stageExamDetail?.end_at),
                                    new Date()
                                  ),
                                }
                              )}
                            />
                          ),
                          text: "Schedule Slot(s)",
                        },
                      ]}
                      posFromRight="right-2"
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <p className="my-2 border-b-2 pb-2 text-sm text-primary-main">
            {upperCase(stageExamDetail?.question_paper_type)}

            {stageExamDetail?.multi_slot && " | Multi-Slot"}
          </p>

          <p className="text-sm text-gray-500">
            Window:&nbsp;
            {stageExamDetail?.start_at &&
              format(new Date(stageExamDetail?.start_at), "dd/MM/yyyy")}
            ,&nbsp;
            {stageExamDetail?.start_at &&
              format(new Date(stageExamDetail?.start_at), "h:mm a")}
            &nbsp;-&nbsp;
            {stageExamDetail?.end_at &&
              `${
                !isSameDay(
                  new Date(stageExamDetail?.start_at),
                  new Date(stageExamDetail?.end_at)
                )
                  ? `${format(
                      new Date(stageExamDetail?.end_at),
                      "dd/MM/yyyy"
                    )}, `
                  : ""
              }${format(new Date(stageExamDetail?.end_at), "h:mm a")}`}
          </p>

          <p className="text-sm text-gray-500">
            Duration:&nbsp;
            {(stageExamDetail?.duration &&
              moment()
                .startOf("day")
                .seconds(stageExamDetail?.duration)
                .format("HH:mm:ss")) ||
              ""}
          </p>

          <p
            className={classNames(
              `text-sm text-gray-500`,
              stageExamDetail?.question_paper_type == QUESTION_TYPE[1].name
                ? ""
                : "mb-3"
            )}
          >
            Cutoff:&nbsp;
            {startCase(lowerCase(stageExamDetail?.cut_off_type))} |&nbsp;
            {/* eslint-disable-next-line no-nested-ternary */}
            {stageExamDetail?.cut_off_marks_type === "PERCENTAGE" ? (
              stageExamDetail?.sectional_cut_off?.length !== 0 ? (
                isSectionalData?.map((section, id) => (
                  <span key={id}>
                    {section.name}:{" "}
                    {(Number(section?.cut_off_marks) * 100).toFixed(2)}%
                    {isSectionalData.length - 1 !== id && <span>,</span>} &nbsp;
                  </span>
                ))
              ) : (
                <span>
                  {(Number(stageExamDetail?.cut_off_marks) * 100).toFixed(2)}%
                </span>
              )
            ) : stageExamDetail?.sectional_cut_off?.length !== 0 ? (
              isSectionalData?.map((section, id) => (
                <span key={id}>
                  {section.name}: {Number(section?.cut_off_marks).toFixed(2)}{" "}
                  marks
                  {isSectionalData.length - 1 !== id && <span>,</span>} &nbsp;
                </span>
              ))
            ) : (
              <span>
                {" "}
                {Number(stageExamDetail?.cut_off_marks).toFixed(2)} marks
              </span>
            )}
          </p>
          {stageExamDetail?.question_paper_type === QUESTION_TYPE[1].name && (
            <p className="mb-3 text-sm text-gray-500">
              Allowed Languages:
              {stageExamDetail.coding_languages?.map((value) => (
                <span key={value.id}>&nbsp;{value.name}</span>
              ))}
            </p>
          )}

          {isDeleteExamModalOpen && (
            <Modal
              title="Are you sure?"
              description="Are you sure you want to cancel the exam?"
              acceptButtonText="Yes"
              acceptBtnType="primary"
              rejectButtonText="No"
              rejectBtnType="secondary"
              handleAccept={handleDeleteExam}
              handleReject={() => setIsDeleteExamModalOpen(false)}
            />
          )}

          {addExamModal && (
            <ExamModal
              currentStageId={currentStage?.id}
              setOpenModal={setAddExamModal}
              getExamData={getExamData}
              stageExamDetail={stageExamDetail}
              setShowMultiSlotConfirmation={setShowMultiSlotConfirmation}
            />
          )}
          {viewSlot && (
            <SlotViewModal
              currentStageId={currentStage?.id}
              setViewSlot={setViewSlot}
              onScheduleSlotClick={onScheduleSlotClick}
            />
          )}
          {multiSlotModalView && (
            <MultiSlotModal
              setShowMultiSlotModal={setMultiSlotModalView}
              currentStageId={currentStage?.id}
            />
          )}

          <Tooltip id="editExam" variant="dark" />
          <Tooltip id="notify" variant="dark" />
        </>
      )}
    </div>
  );
};

StageExamDetails.defaultProps = {
  getCandidateStageData: PropTypes.func,
  setStageExamDetails: PropTypes.func,
};

StageExamDetails.propTypes = {
  stageExamDetail: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    question_paper_id: PropTypes.number,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    duration: PropTypes.number,
    cut_off_marks: PropTypes.string,
    cut_off_type: PropTypes.string,
    cut_off_marks_type: PropTypes.string,
    question_paper_type: PropTypes.string,
    total_questions: PropTypes.number,
    total_marks: PropTypes.string,
    is_notified: PropTypes.bool,
    multi_slot: PropTypes.bool,
    question_paper: PropTypes.object,
    sectional_cut_off: PropTypes.array,
    coding_languages: PropTypes.array,
  }),
  stageExamDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  setStageExamDetails: PropTypes.func,
  getCandidateStageData: PropTypes.func,
  isLoading: PropTypes.bool,
  getExamData: PropTypes.func,
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  setShowMultiSlotConfirmation: PropTypes.func,
  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
};

export default StageExamDetails;
