import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createCategories,
  deleteCategories,
  getCategories,
  updateCategories,
} from "api/erp/questionPaperApi";

export const getCategoryAction = createAsyncThunk(
  "categories/get",
  async () => {
    const response = await getCategories();
    return response;
  }
);

export const getFilterCategoryAction = createAsyncThunk(
  "categories/get",
  async ({ parent_category_id: parentCategoryId, search_term: searchTerm }) => {
    const response = await getCategories(parentCategoryId, searchTerm);
    return response;
  }
);

export const createCategoryAction = createAsyncThunk(
  "categories/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createCategories(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCategoryAction = createAsyncThunk(
  "categories/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCategories(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCategoryAction = createAsyncThunk(
  "categories/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteCategories(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
