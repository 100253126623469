import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import FallbackRender from "components/atoms/FallbackRender";
import ActivityIndicator from "components/common/ActivityIndicator";
import Alert from "components/common/Alert";
import ApiErrorWrapper from "components/common/ApiErrorWrapper";
import Footer from "components/common/Footer";
import Layout from "components/common/Layout";
import Navbar from "components/common/Navbar";
import NotFound from "components/common/NotFound";
import PopupAlert from "components/common/PopupAlert";
import { RequireAuth } from "components/common/RequireAuth";
import { RequireNoAuth } from "components/common/RequireNoAuth";
import Version from "components/common/Version";
import { USER_ROLES } from "constants/common";
import { hideAlert } from "store/slices/alertSlice";
import { hidePopupAlert } from "store/slices/popupAlertSlice";
import { setOrganisationUserAction } from "store/thunkActions/currentUserThunk";

import CandidateAnswer from "../common/CandidateAnswer";

import Description from "./Jobs/Description";
import Edit from "./Jobs/Edit";
import Form from "./Jobs/Form";
import PipeLine from "./Jobs/PipeLine";
import CandidateProfile from "./Jobs/PipeLine/CandidateProfile/CandidateProfile";
import QuestionForm from "./QuestionsManager/QuestionBank/QuestionBankForm";
import ShowQuestion from "./QuestionsManager/QuestionBank/ShowQuestion";
import QuestionPaper from "./QuestionsManager/QuestionPaper/QuestionPaper";
import Question from "./QuestionsManager/QuestionPaper/Section/QuestionPaperForm";
import Dashboard from "./Dashboard";
import Jobs from "./Jobs";
import Organisations from "./Organisation";
import Interviews from "./Panels";
import Profile from "./Profile";
import QuestionsManager from "./QuestionsManager";
import Screenshots from "./Screenshots";
import Signin from "./Signin";
import Users from "./Users";

function ERP() {
  const dispatch = useDispatch();

  const [
    isLoggedIn,
    currentUser,
    alertType,
    alertMessage,
    popupAlertType,
    popupAlertMessage,
    popupAlertHeader,
  ] = useSelector(({ auth, alert, popupAlert, miscellaneous }) => [
    auth.isLoggedIn,
    auth.currentUser,
    alert.type,
    alert.message,
    popupAlert.type,
    popupAlert.message,
    popupAlert.header,
    miscellaneous.intermediateLinkList,
    miscellaneous.currentPageName,
  ]);

  const [openAside, setOpenAside] = useState(false);

  useEffect(() => {
    dispatch(setOrganisationUserAction());
  }, []);

  if (typeof isLoggedIn === "undefined") {
    return <ActivityIndicator main />;
  }

  return (
    <div className="App">
      {alertMessage && (
        <Alert
          type={alertType}
          message={alertMessage}
          isAlertTriggered={!!alertMessage}
          setIsAlertTriggered={() => dispatch(hideAlert())}
        />
      )}

      {popupAlertMessage && (
        <PopupAlert
          type={popupAlertType}
          message={popupAlertMessage}
          header={popupAlertHeader}
          isAlertTriggered={!!popupAlertMessage}
          setIsAlertTriggered={() => dispatch(hidePopupAlert())}
        />
      )}

      <ApiErrorWrapper>
        <ErrorBoundary FallbackComponent={FallbackRender}>
          <Layout
            openAside={openAside}
            setOpenAside={setOpenAside}
            currentUser={currentUser?.data}
          >
            <Navbar setOpenAside={setOpenAside} />

            <div className="main-body bg-gray-lighterShade3">
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route element={<RequireNoAuth />}>
                  <Route path="/organisation" element={<Organisations />} />
                </Route>

                <Route element={<RequireNoAuth />}>
                  <Route path="/login" element={<Signin />} />
                </Route>

                {/** **Dashboard*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        USER_ROLES.ADMIN,
                        USER_ROLES.INTERVIEWER,
                        USER_ROLES.QUESTION_SETTER,
                        USER_ROLES.EVALUATOR,
                        USER_ROLES.HIRING_MANAGER,
                      ]}
                    />
                  }
                >
                  <Route path="/" exact element={<Dashboard />} />
                </Route>

                {/** **Users*** */}
                <Route
                  element={<RequireAuth allowedRoles={[USER_ROLES.ADMIN]} />}
                >
                  <Route path="/users" element={<Users />} />
                </Route>

                {/** **Interview Module Start*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.INTERVIEWER]}
                    />
                  }
                >
                  <Route path="/panels" element={<Interviews />} />
                </Route>
                {/** **Interview Module End*** */}

                {/** **Question Module Start*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        USER_ROLES.ADMIN,
                        USER_ROLES.QUESTION_SETTER,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/questions_manager"
                    element={<QuestionsManager />}
                  />
                  <Route
                    path="/questions_manager/question_paper/:id/question/:section_id"
                    element={<Question />}
                  />
                  <Route
                    path="/questions_manager/question_paper/:id/question"
                    element={<Question />}
                  />

                  <Route
                    path="/questions_manager/question_bank"
                    element={<QuestionForm />}
                  />
                  <Route
                    path="/questions_manager/question/:id"
                    element={<ShowQuestion />}
                  />
                  <Route
                    path="/questions_manager/question_paper/:id"
                    element={<QuestionPaper />}
                  />
                </Route>
                {/** **Question Module End*** */}

                {/** **Jobs Module Start*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        USER_ROLES.ADMIN,
                        USER_ROLES.INTERVIEWER,
                        USER_ROLES.EVALUATOR,
                        USER_ROLES.HIRING_MANAGER,
                      ]}
                    />
                  }
                >
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/jobs/:uuid" element={<Description />} />
                  <Route
                    element={
                      <RequireAuth
                        allowedRoles={[
                          USER_ROLES.ADMIN,
                          USER_ROLES.HIRING_MANAGER,
                        ]}
                      />
                    }
                  >
                    <Route path="/jobs/create" element={<Form />} />
                    <Route path="/jobs/:uuid/edit" element={<Edit />} />
                  </Route>

                  <Route path="/jobs/:uuid/pipeline" element={<PipeLine />} />
                </Route>
                {/** **Jobs Module End*** */}

                {/** **Candidate Profile*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        USER_ROLES.ADMIN,
                        USER_ROLES.INTERVIEWER,
                        USER_ROLES.EVALUATOR,
                        USER_ROLES.HIRING_MANAGER,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/jobs/:uuid/candidates/:candidate_id"
                    element={<CandidateProfile />}
                  />
                </Route>

                {/** **Candidate Answer Module*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        USER_ROLES.ADMIN,
                        USER_ROLES.INTERVIEWER,
                        USER_ROLES.EVALUATOR,
                        USER_ROLES.HIRING_MANAGER,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/jobs/:uuid/candidate/:candidateId/exam/:examId/answers"
                    element={<CandidateAnswer />}
                  />
                </Route>

                {/** **Company Profile Module*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        USER_ROLES.ADMIN,
                        USER_ROLES.INTERVIEWER,
                        USER_ROLES.QUESTION_SETTER,
                        USER_ROLES.HIRING_MANAGER,
                        USER_ROLES.EVALUATOR,
                      ]}
                    />
                  }
                >
                  <Route path="/profile" element={<Profile />} />
                </Route>

                {/** **Candidate Exam ScreenShots*** */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        USER_ROLES.ADMIN,
                        USER_ROLES.INTERVIEWER,
                        USER_ROLES.HIRING_MANAGER,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/jobs/:uuid/candidate/:candidateId/exam/:examId/screenshots"
                    element={<Screenshots />}
                  />
                </Route>
                <Route path="/about" element={<Version />} />
              </Routes>
            </div>

            <Footer />
          </Layout>
        </ErrorBoundary>
      </ApiErrorWrapper>
    </div>
  );
}

export default ERP;
