import { createSlice } from "@reduxjs/toolkit";

import {
  createJobAction,
  getJobsAction,
  updateJobAction,
} from "store/thunkActions/erp/jobThunk";

const initialJobListState = {
  filters: [],
  data: [],
  meta: { has_more: true, total_pages: 1, page_number: 0 },
  isLoading: null, // using null to check componentDidMount life cycle.
};

const jobs = createSlice({
  name: "jobs",
  initialState: initialJobListState,
  reducers: {
    updateStatus: (state, action) => {
      return {
        ...state,
        filters: action.payload.selectedFilters,
      };
    },
    resetState: () => initialJobListState,
  },
  extraReducers: {
    [getJobsAction.pending]: (state) => {
      state.isLoading = true;
    },

    [getJobsAction.fulfilled]: (state, action) => {
      state.data = [
        ...(action.payload.meta.page_number == 1 ? [] : state.data),
        ...action.payload.data,
      ];
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    [createJobAction.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [updateJobAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (job) => job.uuid == action.payload.data.uuid
      );
      state.data[index] = action.payload.data;
    },
  },
});

export const { updateStatus, resetState } = jobs.actions;

export default jobs.reducer;
