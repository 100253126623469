import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import PropTypes from "prop-types";

import { createSlots, getExams, getSlots } from "api/erp/organizationApi";
import { ALERT_TYPE } from "app/constants";
import { ActivityIndicator } from "components/atoms";
import { Modal } from "components/molecules";
import { DEFAULT_PAGE_SIZE } from "constants/common";
import useAlert from "hooks/useAlert";
import { getStageCandidatesAction } from "store/thunkActions/erp/candidateJobStageThunk";

import MultiSlotForm from "./MultiSlotForm";

const MultiSlotModal = ({ setShowMultiSlotModal, currentStageId }) => {
  const params = useParams();
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const [stageExamDetails, setStageExamDetails] = useState([]);
  const [pickedDates, setPickedDate] = useState([]);
  const [slots, setSlots] = useState([]);
  const [totalCandidateLeft, setTotalCandidateLeft] = useState(0); // set by API
  const [candidatesScheduled, setCandidatesScheduled] = useState(0);
  const [maximumCandidate, setMaximumCandidate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchExamDetails = useCallback(() => {
    getExams(params.uuid, currentStageId).then((response) => {
      setStageExamDetails(response.data);
    });
  }, [currentStageId, params.uuid]);

  const {
    register,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      slotValues: [],
    },
  });

  useEffect(() => {
    fetchExamDetails();
  }, [fetchExamDetails]);

  const fetchSlotDetails = useCallback(() => {
    if (stageExamDetails[0]) {
      getSlots(stageExamDetails[0]?.id).then((response) => {
        setSlots(response.data.slots);
        setTotalCandidateLeft(response.data.candidates_left);
        setMaximumCandidate(response.data.max_candidates_in_slot);
        setIsLoading(true);
      });
    }
  }, [stageExamDetails]);

  useEffect(() => {
    fetchSlotDetails();
  }, [fetchSlotDetails, stageExamDetails]);

  const submitHandler = (data) => {
    const value = { slots: {} };
    let total = 0;

    data.slotValues.forEach((slotValue) => {
      slots[format(new Date(slotValue.date), "yyyy-MM-dd")].forEach((slot) => {
        if (slotValue[`check_${slot}`] && +slotValue[`input_${slot}`] > 0) {
          value.slots[slot] = +slotValue[`input_${slot}`];
          total += +slotValue[`input_${slot}`];
        }
      });
    });

    if (total <= totalCandidateLeft) {
      return createSlots(
        params.uuid,
        currentStageId,
        stageExamDetails[0].id,
        value
      )
        .then(({ meta }) => {
          setShowMultiSlotModal(false);
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
          dispatch(
            getStageCandidatesAction({
              stages_id: currentStageId,
              page_size: DEFAULT_PAGE_SIZE,
              page: 1,
              job_uuid: params.uuid,
            })
          );
        })
        .catch((e) => {
          showAlert(
            ALERT_TYPE[e.response.data.meta?.message_type] || "danger",
            e.response.data.meta?.message || errors.response.data.message
          );
        });
    }

    return showAlert(
      "danger",
      "Candidate count should be less than or equal to total number of candidates in the stage"
    );
  };

  const handleCandidateScheduledChange = () => {
    let sum = 0;
    const slotsArray = getValues(`slotValues`);

    slotsArray.forEach((slotsObject, index) => {
      const slotKeys = Object.keys(slotsObject);

      slotKeys.forEach((key) => {
        if (key.startsWith("check_") && slotsObject[key]) {
          const time = key.split("_")[1];
          const inputKey = `slotValues[${index}].input_${time}`;
          const value =
            (parseInt(getValues(inputKey), 10) || 0) > 0
              ? parseInt(getValues(inputKey), 10) || 0
              : 0;
          sum += value;
        }
      });
    });
    setCandidatesScheduled(sum);
  };

  const handleDeleteDate = (date, index) => {
    setPickedDate((prev) => prev.filter((pickedDate) => date !== pickedDate));
    getValues(`slotValues`).splice(index, 1);
    errors.slotValues?.splice(index, 1);
    handleCandidateScheduledChange();
  };

  if (!isLoading) {
    return (
      <Modal
        title="Scheduled Slot(s)"
        testId="scheduled-slots-container"
        customModalWidth="720px"
        scrollClass="overflow-auto"
      >
        <div className="max-h-[600px] min-w-[400px]">
          <div className="my-8">
            <ActivityIndicator
              containerClass="mt-2"
              svgClassName="w-100%"
              size={12}
            />
          </div>
        </div>
      </Modal>
    );
  }

  const candidatesLeft =
    totalCandidateLeft > candidatesScheduled
      ? totalCandidateLeft - candidatesScheduled
      : 0;

  const title = (
    <div className="flex-col">
      <h3>Schedule Slot(s)</h3>
      <p className="text-sm mt-2">Candidates Left: {candidatesLeft}</p>
    </div>
  );

  const allowedDates = Object.keys(slots).map((date) => new Date(date));

  return (
    <Modal
      title={title}
      customModalWidth="720px"
      acceptButtonText="Create"
      acceptBtnType={
        candidatesScheduled > 0 && candidatesScheduled <= totalCandidateLeft
          ? "primary"
          : "disabled"
      }
      handleCancel={() => setShowMultiSlotModal(false)}
      handleAccept={handleSubmit((data) => submitHandler(data))}
      scrollClass="overflow-y-auto max-h-[70vh]"
      testId="multi-slot"
      isDirty={isDirty}
    >
      <form
        className="p-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
        data-testid="multi-slot-form"
      >
        {pickedDates.map((pickedDate, index) => (
          <MultiSlotForm
            key={pickedDate}
            formId={index}
            pickedDate={pickedDate}
            slotTimes={slots[format(new Date(pickedDate), "yyyy-MM-dd")]}
            register={register}
            watch={watch}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            testId="multi-slot-form-modal"
            Controller={Controller}
            control={control}
            getValues={getValues}
            candidateLeft={totalCandidateLeft}
            setValue={setValue}
            onCandidateScheduledChange={handleCandidateScheduledChange}
            handleDeleteDate={handleDeleteDate}
            maximumCandidate={maximumCandidate}
          />
        ))}

        <label htmlFor="date" className="block font-medium mt-2 mb-1">
          Select Date for Multi slot
        </label>
        <DatePicker
          id="date"
          placeholderText="Select Date"
          isClearable
          className="input disabled:input-disabled sm:w-2/5 w-full"
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            setPickedDate((prev) => prev.concat(date));
          }}
          includeDates={allowedDates}
          excludeDates={pickedDates}
          data-testid="date-picker"
          disabled={candidatesLeft === 0}
        />
      </form>
    </Modal>
  );
};

MultiSlotModal.propTypes = {
  setShowMultiSlotModal: PropTypes.func.isRequired,
  currentStageId: PropTypes.number.isRequired,
};

export default MultiSlotModal;
