import { createSlice } from "@reduxjs/toolkit";

import {
  createSectionAction,
  deleteSectionAction,
  getSectionAction,
  updateSectionNameAction,
} from "store/thunkActions/erp/sectionsThunk";

const sections = createSlice({
  name: "sections",
  initialState: {
    data: undefined,
  },
  reducers: {},
  extraReducers: {
    [createSectionAction.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [getSectionAction.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [updateSectionNameAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (section) => section.id == action.payload.id
      );
      state.data[index] = action.payload;
    },
    [deleteSectionAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (section) => section.id == action.payload.id
      );
      state.data.splice(index, 1);
    },
  },
});

export default sections.reducer;
