import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import NotFoundImage from "assets/images/DashboardImages/404_Error.png";
import InternalServerImage from "assets/images/DashboardImages/500_Error.png";
import { clearApiError, setApiError } from "store/slices/apiErrorSlice";
import { logout } from "store/slices/authSlice";

import GenericErrorPage from "./GenericError";

const ErrorWrapper = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, url } = useSelector((state) => state.apiErrors);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const homeClickHandler = () => {
    dispatch(clearApiError());
    navigate("/");
  };

  if (status) {
    if (status === 401) {
      if (isLoggedIn && url !== "/me") {
        dispatch(logout()).then(() => {
          dispatch(
            setApiError({
              status,
              message: "Please login/signup to proceed.",
            })
          );
        });
      }
      return children;
    }
    if (status === 404) {
      return (
        <GenericErrorPage
          homePageClickHandler={homeClickHandler}
          pageErrorImage={NotFoundImage}
          imgWidth="lg:w-1/3 w-2/3"
          message="Not Found"
          details="Unfortunately the page you are looking for has been moved, deleted or does not exist!"
        />
      );
    }
    if (status === 500) {
      return (
        <GenericErrorPage
          homePageClickHandler={homeClickHandler}
          pageErrorImage={InternalServerImage}
          imgWidth="lg:w-1/4 w-2/3"
          message="Internal Server Error"
          details="The server encountered an internal error or misconfiguration, and was unable to complete your request. We have been notified about this issue and we will take a look at it shortly!"
        />
      );
    }

    return <GenericErrorPage homePageClickHandler={homeClickHandler} />;
  }

  return children;
};

ErrorWrapper.defaultProps = {
  children: null,
};

ErrorWrapper.propTypes = {
  children: PropTypes.node,
};

export default ErrorWrapper;
