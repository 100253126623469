import { createSlice } from "@reduxjs/toolkit";

import {
  createQuestionAction,
  deleteQuestionAction,
  getQuestionBankAction,
  updateQuestionAction,
} from "store/thunkActions/erp/questionBankThunk";

const initialQuestionBankListState = {
  filters: "",
  data: [],
  meta: { has_more: true, total_pages: 1, page_number: 0 },
  isLoading: null,
};

const questionBank = createSlice({
  name: "questionBank",
  initialState: initialQuestionBankListState,
  reducers: {
    updateStatus: (state, action) => {
      return {
        ...state,
        filters: action.payload.selectedFilters,
      };
    },
    resetState: () => initialQuestionBankListState,
  },
  extraReducers: {
    [getQuestionBankAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getQuestionBankAction.fulfilled]: (state, action) => {
      state.data = [
        ...(action.payload.meta.page_number == 1 ? [] : state.data),
        ...action.payload.data,
      ];
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    [createQuestionAction.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [updateQuestionAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (question) => question.id == action.payload.data.id
      );
      state.data[index] = action.payload.data;
    },
    [deleteQuestionAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (question) => question.id == action.payload.data.id
      );
      state.data.splice(index, 1);
    },
  },
});

export const { updateStatus, resetState } = questionBank.actions;

export default questionBank.reducer;
