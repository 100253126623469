import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Editor from "@monaco-editor/react";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  reEvaluateCandidateAnswer,
  updateCandidateAnswer,
} from "api/erp/questionPaperApi";
import { ALERT_TYPE, EDITOR_FORMAT } from "app/constants";
import { Button, ThreeDotMenu } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Pill from "components/common/Pill";
import {
  CANDIDATE_ANSWER_STATUS,
  COMPILATION_FAILED_MESSAGE,
  TEST_CASES_UNAVAILABLE,
  UNATTEMPTED_MESSAGE,
  USER_ROLES,
} from "constants/common";
import useAlert from "hooks/useAlert";
import useCheckUserRole from "hooks/useCheckUserRole";

import TestCaseModal from "./TestCaseModal";

const CodingAnswerScript = ({
  isMockTest,
  candidateAnswer,
  index,
  getAnswers,
}) => {
  const dropdownMenuRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showTestCasesModal, setShowTestCasesModal] = useState(false);
  const params = useParams();
  const showAlert = useAlert();
  const codingAnswer = candidateAnswer.coding_answer;
  const isCorrect = candidateAnswer.correct;

  // !className in tailwind css is !important in regular CSS
  const splitButtonClassName =
    "border px-5 py-2 rounded-full text-xs hover:font-medium hover:outline hover:outline-1 rounded-l-none pl-2 pb-1 mr-2 !my-0";

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  const checkAdminUserRole = useCheckUserRole([USER_ROLES.ADMIN]);
  const checkEvaluatorUserRole = useCheckUserRole([USER_ROLES.EVALUATOR]);

  const updateCodingAnswer = (answer, candidateAnswerId) => {
    const data = {
      exam_id: parseInt(params.examId, 10),
      correct: answer === "correct",
    };

    updateCandidateAnswer(candidateAnswerId, data)
      .then(() => {
        getAnswers();
      })
      .catch((errors) => {
        showAlert("danger", errors.response.data.errors.detail);
      });
  };

  const reEvaluateCodingAnswer = async (candidateAnswerId) => {
    const data = {
      id: candidateAnswerId,
      exam_id: parseInt(params.examId, 10),
    };

    return reEvaluateCandidateAnswer(data)
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        getAnswers();
      })
      .catch((errors) =>
        showAlert(
          ALERT_TYPE[errors.response.data.meta.message_type],
          errors.response.data.meta.message
        )
      );
  };

  const checkAnswerForPill = (answer) => {
    if (answer.correct) {
      return `+ ${parseFloat(answer.question?.marks)}`;
    }
    return `0`;
  };

  const checkPublicTestCase = (codingAnswer) => {
    const totalCount = codingAnswer?.public_test_case_count;
    const passedCount = codingAnswer?.public_test_case_passed_count;
    return `${passedCount || 0} test cases passed out of ${totalCount}`;
  };

  const checkPrivateTestCase = (codingAnswer) => {
    const totalCount = codingAnswer?.private_test_case_count;
    const passedCount = codingAnswer?.private_test_case_passed_count;
    return `${passedCount || 0} test cases passed out of ${totalCount}`;
  };

  const showCodingLanguage = () => {
    const matchedDriverCode = candidateAnswer.question?.driver_codes?.find(
      (driverCode) =>
        driverCode?.coding_language_id === codingAnswer?.coding_language_id
    );
    return matchedDriverCode?.coding_language;
  };

  const textColorForPill = (passedCount, totalCount) => {
    if (totalCount !== null) {
      if (passedCount === totalCount && totalCount !== 0) {
        return "text-success-alertDark";
      }
      if (passedCount !== totalCount && passedCount !== 0) {
        return "text-warning-alertDark";
      }
      return "text-danger-alertDark";
    }
    return "";
  };

  const bgColorForPill = (passedCount, totalCount) => {
    if (totalCount !== null) {
      if (passedCount === totalCount && totalCount !== 0) {
        return "bg-success-alertBg";
      }
      if (passedCount !== totalCount && passedCount !== 0) {
        return "bg-warning-alertBg";
      }
      return "bg-danger-alertBg";
    }
    return "";
  };

  const generateButtonTitle = (answer, codingAnswer) => {
    let title = "View Test Cases";

    if (
      answer.status?.toLowerCase() ===
      CANDIDATE_ANSWER_STATUS.COMPILATION_FAILED
    ) {
      title = COMPILATION_FAILED_MESSAGE;
    } else if (!codingAnswer) {
      title = UNATTEMPTED_MESSAGE;
    } else if (codingAnswer?.candidate_answer_test_cases?.length === 0) {
      title = TEST_CASES_UNAVAILABLE;
    }
    return title;
  };

  const generateButtonType = (answer, codingAnswer) => {
    const hasNoCodingAnswer = !codingAnswer;
    const isCompilationFailed =
      answer.status?.toLowerCase() ===
      CANDIDATE_ANSWER_STATUS.COMPILATION_FAILED;
    const noCandidateTestCases =
      codingAnswer?.candidate_answer_test_cases?.length === 0;
    const hasPrivateTestCases = codingAnswer?.private_test_case_count > 0;
    const noPublicTestCases = codingAnswer?.public_test_case_count < 1;

    if (
      hasNoCodingAnswer ||
      isCompilationFailed ||
      noCandidateTestCases ||
      (hasPrivateTestCases && isMockTest && noPublicTestCases)
    ) {
      return "disabled";
    }

    return "tertiary";
  };

  const generateButtonClassName = (answer, codingAnswer) => {
    return (
      !codingAnswer ||
      answer.status?.toLowerCase() ===
        CANDIDATE_ANSWER_STATUS.COMPILATION_FAILED ||
      answer.status?.toLowerCase() ===
        CANDIDATE_ANSWER_STATUS.REQUEST_TIMED_OUT ||
      codingAnswer?.candidate_answer_test_cases?.length === 0 ||
      (isMockTest && Number(codingAnswer.private_test_case_passed_count) === 0)
    );
  };

  return (
    <>
      <div className="flex flex-row justify-between items-start ml-4">
        <span className="mr-2 text-xl font-semibold">Question {index + 1}</span>
        <div className="flex items-center flex-wrap">
          <Pill
            className={classNames("!px-2 !my-0", {
              "split-button rounded-r-none":
                codingAnswer && (checkAdminUserRole || checkEvaluatorUserRole),
            })}
            textColor={
              /* eslint-disable-next-line no-nested-ternary */
              isCorrect
                ? "text-success-alertDark"
                : isCorrect == false && codingAnswer
                ? "text-danger-alertDark"
                : "text-gray-500"
            }
            outline
            label={
              /* eslint-disable-next-line no-nested-ternary */
              isCorrect
                ? "Correct"
                : isCorrect == false && codingAnswer
                ? "Incorrect"
                : "Un-attempted"
            }
          />
          {(checkAdminUserRole || checkEvaluatorUserRole) && codingAnswer && (
            <Button
              title="Mark Answer"
              onClick={() => setShowDropdown((dropdown) => !dropdown)}
              customWidth={8}
              btnClassName={classNames(
                splitButtonClassName,
                isCorrect
                  ? "text-success-alertDark border-success-alertDark"
                  : "text-danger-alertDark border-danger-alertDark"
              )}
            >
              <span>&#9660;</span>
            </Button>
          )}

          <Pill
            textColor={
              /* eslint-disable-next-line no-nested-ternary */
              isCorrect
                ? "text-success-alertDark"
                : isCorrect == false && codingAnswer
                ? "text-danger-alertDark"
                : "text-gray-500"
            }
            label={checkAnswerForPill(candidateAnswer)}
            outline
            bgColor={
              /* eslint-disable-next-line no-nested-ternary */
              isCorrect
                ? "bg-success-alertBg"
                : isCorrect == false && codingAnswer
                ? "bg-danger-alertBg"
                : "bg-gray-lighterShade2"
            }
            className="!my-0 !mr-0"
          />

          <div className="relative bottom-2">
            {showDropdown && (
              <ThreeDotMenu
                menuRef={dropdownMenuRef}
                menuOptions={[
                  {
                    condition: !isCorrect,
                    dataTestId: "mark_correct",
                    onClick: () => {
                      updateCodingAnswer("correct", candidateAnswer.id);
                      setShowDropdown(false);
                    },
                    text: "Mark as Correct",
                  },
                  {
                    condition: isCorrect,
                    dataTestId: "mark_incorrect",
                    onClick: () => {
                      updateCodingAnswer("incorrect", candidateAnswer.id);
                      setShowDropdown(false);
                    },
                    text: "Mark as Incorrect",
                  },
                ]}
                posFromRight="right-2"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 ml-4">
        <RenderParsedHTML
          content={candidateAnswer.question?.text || ""}
          className="flex-1"
        />
      </div>
      <div className="h-auto w-full pl-4">
        <div className="mt-2">
          <span className="font-semibold ">Coding Language : </span>
          <span className="ml-1">
            {showCodingLanguage() ||
              candidateAnswer.question?.driver_codes[0]?.coding_language}
          </span>
        </div>
        <div className="mt-4">
          <div className="flex justify-between items-center mb-1">
            <span className="font-semibold">Input Code</span>
            {codingAnswer && (checkAdminUserRole || checkEvaluatorUserRole) ? (
              <span
                data-tooltip-id="reEvaluate"
                data-tooltip-content="Warning: This might change the evaluation."
              >
                <Button
                  title="Re-evaluate"
                  btnName="Re-evaluate"
                  type="secondary"
                  btnClassName="hover:font-medium hover:outline hover:outline-1 !normal-case !px-1"
                  onClick={() => reEvaluateCodingAnswer(candidateAnswer.id)}
                  isAsyncClick
                  customWidth={28}
                />
                <Tooltip id="reEvaluate" variant="dark" className="z-10" />
              </span>
            ) : null}
          </div>
          <Editor
            name="editor"
            language={EDITOR_FORMAT[showCodingLanguage()?.toLowerCase()]}
            theme="vs-yellow"
            height="25vw"
            width="100%"
            className="relative z-0 mt-2"
            value={codingAnswer?.code_string}
            options={{
              readOnly: true,
              minimap: {
                enabled: false,
              },
              wordWrap: "on",
              automaticLayout: true,
            }}
          />
        </div>
        <div className="mr-2 pt-4 flex flex-wrap flex-row space-x-2 items-center">
          {Number(codingAnswer?.public_test_case_count) > 0 ? (
            <span className="flex items-center gap-2 ml-2">
              <span className="font-semibold">Public:</span>
              <Pill
                label={checkPublicTestCase(codingAnswer)}
                outline
                textColor={textColorForPill(
                  codingAnswer?.public_test_case_passed_count || 0,
                  codingAnswer?.public_test_case_count
                )}
                bgColor={bgColorForPill(
                  codingAnswer?.public_test_case_passed_count || 0,
                  codingAnswer?.public_test_case_count
                )}
              />
            </span>
          ) : null}

          {Number(codingAnswer?.private_test_case_count) > 0 && !isMockTest ? (
            <span className="flex items-center gap-2">
              <span className="font-semibold">Private:</span>
              <Pill
                label={checkPrivateTestCase(codingAnswer)}
                outline
                textColor={textColorForPill(
                  codingAnswer?.private_test_case_passed_count || 0,
                  codingAnswer?.private_test_case_count
                )}
                bgColor={bgColorForPill(
                  codingAnswer?.private_test_case_passed_count || 0,
                  codingAnswer?.private_test_case_count
                )}
              />
            </span>
          ) : null}

          {!codingAnswer?.private_test_case_count &&
            !codingAnswer?.public_test_case_count &&
            candidateAnswer.status?.toLowerCase() ===
              CANDIDATE_ANSWER_STATUS.COMPILATION_FAILED && (
              <Pill
                label="Compilation Failed"
                textColor="text-danger-alertDark"
                bgColor="bg-danger-alertBg"
                outline
              />
            )}

          <Button
            title={generateButtonTitle(candidateAnswer, codingAnswer)}
            btnName="View Test Cases"
            type={generateButtonType(candidateAnswer, codingAnswer)}
            btnClassName={classNames("!px-0", {
              "tertiary-disable": generateButtonClassName(
                candidateAnswer,
                codingAnswer
              ),
            })}
            size="md"
            customWidth={30}
            onClick={() => {
              setShowTestCasesModal(true);
            }}
          />
        </div>
      </div>

      {showTestCasesModal && (
        <TestCaseModal
          questionTestCases={candidateAnswer.question?.test_cases}
          candidateAnswerTestCases={codingAnswer?.candidate_answer_test_cases}
          setShowTestCasesModal={setShowTestCasesModal}
          isMockTest={isMockTest}
        />
      )}
    </>
  );
};

CodingAnswerScript.propTypes = {
  candidateAnswer: PropTypes.object,
  index: PropTypes.number,
  getAnswers: PropTypes.func,
  isMockTest: PropTypes.bool,
};

export default CodingAnswerScript;
