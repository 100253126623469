import { createSlice } from "@reduxjs/toolkit";

import {
  createProjectAction,
  deleteProjectAction,
  getProjectsAction,
  updateProjectAction,
} from "store/thunkActions/candidate/projectThunk";

export const getProject = createSlice({
  name: "project",
  initialState: {
    projects: [],
  },
  reducers: {},
  extraReducers: {
    [getProjectsAction.fulfilled]: (state, action) => {
      state.projects = action.payload;
    },
    [createProjectAction.fulfilled]: (state, action) => {
      state.projects.push(action.payload.data);
    },
    [updateProjectAction.fulfilled]: (state, action) => {
      const index = state.projects.findIndex(
        (project) => project.id == action.payload.data.id
      );
      state.projects[index] = action.payload.data;
    },
    [deleteProjectAction.fulfilled]: (state, action) => {
      const index = state.projects.findIndex(
        (project) => project.id == action.payload.data.id
      );
      state.projects.splice(index, 1);
    },
  },
});

export default getProject.reducer;
