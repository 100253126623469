import { createAsyncThunk } from "@reduxjs/toolkit";

import { createOrganisation, getOrganisation } from "api/erp/organizationApi";

export const getOrganisationAction = createAsyncThunk(
  "organization/get",
  async () => {
    const response = await getOrganisation();
    return response.data;
  }
);

export const createOrganisationAction = createAsyncThunk(
  "organization/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createOrganisation(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
