import PropTypes from "prop-types";

const StatusPill = ({ label }) => {
  return (
    <span
      className="self-end rounded-lg text-md border border-primary-light sm:px-4 px-2 font-medium capitalize text-primary-main
          "
    >
      {label}
    </span>
  );
};

StatusPill.propTypes = {
  label: PropTypes.string.isRequired,
};

export default StatusPill;
