import Proptypes from "prop-types";

import NoDataFound from "assets/images/DashboardImages/no_data_found.png";

const EmptyMessage = ({ message }) => (
  <div className="pt-16 text-center">
    <img src={NoDataFound} alt="No Data Found" className="m-auto" />
    <h4 className="text-2xl font-medium text-gray-darker">{message}</h4>
  </div>
);

EmptyMessage.propTypes = {
  message: Proptypes.string,
};

EmptyMessage.defaultProps = { message: "Not Found" };

export default EmptyMessage;
