import { createSlice } from "@reduxjs/toolkit";

import {
  createEducationQualificationsAction,
  deleteEducationQualificationAction,
  getEducationQualificationsAction,
  updateEducationQualificationsAction,
} from "store/thunkActions/candidate/educationQualificationThunk";

export const getEducationQualification = createSlice({
  name: "educationQualification",
  initialState: {
    educationQualifications: [],
  },
  reducers: {},
  extraReducers: {
    [getEducationQualificationsAction.fulfilled]: (state, action) => {
      state.educationQualifications = action.payload;
    },
    [createEducationQualificationsAction.fulfilled]: (state, action) => {
      state.educationQualifications.push(action.payload.data);
    },
    [updateEducationQualificationsAction.fulfilled]: (state, action) => {
      const index = state.educationQualifications.findIndex(
        (educationQualification) =>
          educationQualification.id == action.payload.data.id
      );
      state.educationQualifications[index] = action.payload.data;
    },
    [deleteEducationQualificationAction.fulfilled]: (state, action) => {
      const index = state.educationQualifications.findIndex(
        (educationQualification) =>
          educationQualification.id == action.payload.data.id
      );
      state.educationQualifications.splice(index, 1);
    },
  },
});

export default getEducationQualification.reducer;
