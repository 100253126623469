import { BsCheckCircleFill, BsExclamationCircleFill } from "react-icons/bs";
import PropTypes from "prop-types";

const Message = ({ message = "", successMessage }) =>
  successMessage ? (
    <div
      className="mt-20 mb-20 flex w-auto items-center justify-center
      gap-3 rounded-full border border-success-alertDark bg-success-alertBg p-2 px-2 text-success-alertDark sm:mx-8 md:mx-20"
    >
      <BsCheckCircleFill
        size={36}
        className="h-12 w-12 sm:h-14 sm:w-14 md:h-auto md:w-auto"
      />
      <div className="text-base">{message}</div>
    </div>
  ) : (
    <div
      className="mt-20 mb-20  flex w-auto items-center justify-center gap-3
      rounded-full border border-secondary-alertDark bg-secondary-alertBg p-2 px-2 text-secondary-alertDark opacity-95 sm:mx-8 md:mx-20"
    >
      <BsExclamationCircleFill
        size={36}
        className="h-12 w-12 sm:h-14 sm:w-14 md:h-auto md:w-auto"
      />
      <div className="text-base">{message}</div>
    </div>
  );

Message.propTypes = {
  message: PropTypes.string,
  successMessage: PropTypes.bool,
};

export default Message;
