import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const PopupAlert = ({ type, setIsAlertTriggered, message, header }) => {
  const [typeColors, setTypeColors] = useState({
    backgroundColor: undefined,
    textColor: undefined,
    borderColor: undefined,
  });

  useEffect(() => {
    if (type == "danger") {
      setTypeColors({
        backgroundColor: "bg-danger-lightest",
        textColor: "text-danger-dark",
        borderColor: "border-danger-main",
      });
    } else if (type == "info") {
      setTypeColors({
        backgroundColor: "bg-secondary-lightest",
        textColor: "text-secondary-dark",
        borderColor: "border-secondary-light",
      });
    } else {
      setTypeColors({
        backgroundColor: "bg-success-lightest",
        textColor: "text-success-dark",
        borderColor: "border-success-main",
      });
    }
  }, [type]);

  return (
    <div
      className={`${typeColors.backgroundColor} border ${typeColors.borderColor} ${typeColors.textColor}  custom-scrollbar fixed top-1/4 left-0 right-0 z-40 mx-auto overflow-y-auto rounded px-5 py-4 shadow-2xl sm:w-2/5 md:w-2/3 lg:w-2/6`}
      role="alert"
      onAnimationEnd={() => setIsAlertTriggered(false)}
    >
      <div className="p-1">
        <div className="text-center">
          <h2 className="truncate text-lg font-semibold" title={header}>
            {header}
          </h2>
        </div>
        <span className="mt-2 block text-center text-gray-800">{message}</span>

        <div className="mt-4 text-center">
          <button
            title="OK"
            type="button"
            onClick={() => setIsAlertTriggered(false)}
            className="rounded bg-success-main px-8  py-1 text-white"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

PopupAlert.propTypes = {
  type: PropTypes.oneOf(["success", "info", "danger"]).isRequired,
  message: PropTypes.string.isRequired,
  header: PropTypes.string,
  setIsAlertTriggered: PropTypes.func.isRequired,
};

export default PopupAlert;
