import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import { format, isBefore, toDate } from "date-fns";
import PropTypes from "prop-types";

import { getCompanies } from "api/candidate/profileApi";
import { Button } from "components/atoms";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import { handleOptionLabel } from "helpers/formatDropDownOption";
import { formatOption } from "helpers/formatOption";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import { validateDropdowns } from "helpers/utils";
import {
  createWorkExperiencesAction,
  getWorkExperiencesAction,
  updateWorkExperiencesAction,
} from "store/thunkActions/candidate/workExperienceThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const AsyncPaginateCreatable = withAsyncPaginate(Creatable);

const Form = ({ workExperience, setIsEditState, setIsAdding }) => {
  const { employmentTypes } = useSelector((state) => state.employmentTypes);

  const {
    watch,
    register,
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    formState: { isDirty, errors, isSubmitting },
    setError,
  } = useForm();

  const [companyOptions, setCompanyOptions] = useState([]);
  useEffect(
    () =>
      reset({
        ...workExperience,
        company: {
          id: workExperience?.company_id,
          name: workExperience?.company_name,
        },
        employment_type: {
          id: workExperience?.employment_type_id,
          name: workExperience?.employment_type_name,
        },
      }),
    [workExperience]
  );

  const dispatch = useDispatch();
  const startAt = watch("start_at");
  const endAt = watch("end_at");
  const isPresentlyWorking = watch("is_presently_working");

  const loadCompaniesOptions = async (search, _, { page }) => {
    const { data, meta } = await getCompanies(page, search);

    if (page === 1) {
      const newOptions = data.map((option) => formatOption(option.name));
      setCompanyOptions(newOptions);
    } else if (page > 1) {
      const newOptions = data.map((option) => formatOption(option.name));
      setCompanyOptions([...companyOptions, ...newOptions]);
    }

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (key == "company_id") {
        setError("company", { message: value[0] });
      } else if (key == "employment_type_id") {
        setError("employment_type", { message: value[0] });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const submitHandler = (data) => {
    // Restrict API call when no changes
    // temporary fix for the markdown update issue
    if (
      data.id &&
      data.description == workExperience.description &&
      !isDirty &&
      data.is_presently_working === workExperience.is_presently_working
    ) {
      return setIsEditState(false);
    }

    // Structuring req body
    data.start_at =
      data.start_at && format(new Date(data.start_at), "yyyy-MM-dd");
    data.end_at = data.end_at && format(new Date(data.end_at), "yyyy-MM-dd");
    data.company_id = data.company?.id;
    data.company_name = data.company?.label;
    data.description = data.description?.trim();
    data.employment_type_id = data.employment_type?.id;
    data.description = data.description?.trim();

    // Undefined because this need not to go in req body
    data.company = undefined;
    data.employment_type = undefined;

    // Update if existing record else create new
    const action = data.id
      ? updateWorkExperiencesAction(data)
      : createWorkExperiencesAction(data);

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(setCandidateUserAction());
        dispatch(getWorkExperiencesAction());
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <p className="text-sm font-medium text-gray-dark">
        Please fill out the details for adding new experience
      </p>
      <label
        htmlFor="company_select"
        className={`required mt-2 mb-1 block font-medium ${
          errors.company && "text-danger-main"
        }`}
      >
        Company
      </label>

      <Controller
        control={control}
        name="company"
        rules={{
          validate: (company) => {
            return validateDropdowns({ field: company, limit: 255 });
          },
        }}
        render={({ field }) => (
          <AsyncPaginateCreatable
            isSearchable
            isClearable
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            id="company_select"
            menuPlacement="auto"
            className="w-full"
            styles={reactSelectStyle(errors.company)}
            value={field.value?.label || field.value?.name ? field.value : null}
            loadOptions={loadCompaniesOptions}
            onChange={(value) => field.onChange(value)}
            noOptionsMessage={() => null}
            debounceTimeout={1000}
            placeholder="Enter Company Name..."
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) =>
              formatOption(option.name) || formatOption(option.label)
            }
            formatCreateLabel={(name) => `Create "${name}"`}
            additional={{ page: 1 }}
            formatOptionLabel={(option, { context }) =>
              handleOptionLabel(option, context, companyOptions)
            }
          />
        )}
      />

      {errors.company && (
        <p className="text-sm text-danger-dark">{errors.company.message}</p>
      )}

      <label
        htmlFor="position"
        className={`required mt-4 mb-1 block font-medium ${
          errors.title && "text-danger-main"
        }`}
      >
        Position
      </label>

      <input
        id="position"
        className={`${errors.title ? "input-error" : "input"} w-full`}
        type="text"
        placeholder="Enter position"
        {...register("title", {
          required: requiredValidation(),
          maxLength: {
            value: 100,
            message: characterValidation({ limit: 100 }),
          },
        })}
      />

      {errors.title && (
        <p className="text-sm text-danger-dark">{errors.title.message}</p>
      )}

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="start_at"
            className={`required mt-2 mb-1 block font-medium ${
              errors.start_at && "text-danger-main"
            }`}
          >
            From
          </label>

          <Controller
            control={control}
            name="start_at"
            rules={{
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <DatePicker
                showMonthYearPicker
                showFullMonthYearPicker
                placeholderText="Select Start date"
                className={`${
                  errors.start_at ? "input-error" : "input"
                } w-full`}
                dateFormat="MMMM yyyy"
                maxDate={new Date()}
                onChange={(date) => {
                  field.onChange(date);
                  if (isBefore(new Date(endAt), new Date(date))) {
                    setValue("end_at", null);
                    clearErrors("end_at");
                  } else if (date == null) {
                    setValue("end_at", null);
                  }
                }}
                selected={field.value && toDate(new Date(field.value))}
              />
            )}
          />

          {errors.start_at && (
            <p className="text-sm text-danger-dark">
              {errors.start_at.message}
            </p>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="end_at"
            className={`required mt-2 mb-1 block font-medium ${
              errors.end_at && "text-danger-main"
            }`}
          >
            To
          </label>

          <Controller
            control={control}
            name="end_at"
            rules={{
              required: isPresentlyWorking ? null : requiredValidation(),
            }}
            render={({ field }) => (
              <DatePicker
                disabled={isPresentlyWorking ? true : !startAt}
                showPopperArrow={false}
                showYearDropdown
                dropdownMode="select"
                showMonthYearPicker
                showFullMonthYearPicker
                placeholderText="Select End date"
                className={`${
                  errors.end_at ? "input-error" : "input"
                } disabled:input-disabled w-full`}
                dateFormat="MMMM yyyy"
                minDate={new Date(startAt)}
                onChange={(date) => {
                  field.onChange(date);
                }}
                selected={field.value && toDate(new Date(field.value))}
              />
            )}
          />

          {errors.end_at && (
            <p className="text-sm text-danger-dark">{errors.end_at.message}</p>
          )}

          <label
            htmlFor="currently_working"
            className="mt-1 flex items-center text-sm"
          >
            <input
              type="checkbox"
              id="currently_working"
              {...register("is_presently_working")}
              onChange={(e) => {
                setValue("is_presently_working", e.target.checked);
                setValue("end_at", null);
                clearErrors("end_at");
              }}
            />
            <span className="ml-2 ">Currently working here</span>
          </label>
        </div>
      </div>

      <label
        htmlFor="employment_type_select"
        className={`required mt-2 mb-1 block font-medium ${
          errors.employment_type && "text-danger-main"
        }`}
      >
        Employment Type
      </label>

      <Controller
        name="employment_type"
        control={control}
        rules={{
          validate: (jobType) => {
            return validateDropdowns({ field: jobType });
          },
        }}
        render={({ field }) => (
          <Select
            name="employmentType"
            isClearable
            options={employmentTypes}
            id="employment_type_select"
            menuPlacement="auto"
            styles={reactSelectStyle(errors.employment_type)}
            placeholder="Select Employment Type..."
            value={field.value?.label || field.value?.name ? field.value : null}
            onChange={(data) => field.onChange(data)}
            getOptionLabel={(option) => option.name || ""}
            getOptionValue={(option) => option.id || ""}
          />
        )}
      />

      {errors.employment_type && (
        <p className="text-sm text-danger-dark">
          {errors.employment_type.message}
        </p>
      )}

      <label
        htmlFor="description"
        className={`mt-4 mb-1 block font-medium ${
          errors.description && "text-danger-main"
        }`}
      >
        Description
      </label>

      <Controller
        name="description"
        control={control}
        defaultValue=""
        rules={{
          maxLength: {
            value: 2000,
            message: characterValidation({ limit: 2000 }),
          },
        }}
        render={({ field }) => (
          <RichTextEditor
            id="description"
            content={field.value}
            onChange={(content) => field.onChange(content)}
            placeholder="Enter description..."
            fieldError={!!errors.description?.message}
          />
        )}
      />
      {errors.description && (
        <p className="text-sm text-danger-dark">{errors.description.message}</p>
      )}

      <div className="mt-4 flex w-full">
        <Button
          title={setIsAdding ? "Save" : "Update"}
          btnClassName="px-2"
          testId="saveBtn"
          type="primary"
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          {setIsAdding ? "Save" : "Update"}
        </Button>

        <Button
          title="Cancel"
          testId="cancelBtn"
          type="secondary"
          size="sm"
          btnClassName="px-2 ml-2"
          onClick={() => setIsEditState(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  workExperience: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    is_presently_working: PropTypes.bool,
    company_id: PropTypes.number,
    company_name: PropTypes.string,
    employment_type_id: PropTypes.number,
    employment_type_name: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};

export default Form;
