import api, { organisationApi, organisationFileApi } from "api/axios";

// Organization API
export const getOrganisationCurrentUser = async () => {
  const response = await organisationApi.get("/me");
  return response;
};

export const getOrganisationType = async () => {
  const response = await organisationApi.get("/organisation_types");
  return response;
};

export const getEmployeeRange = async () => {
  const response = await organisationApi.get("/employee_ranges");
  return response;
};

export const getIndustryName = async (page, name) => {
  const response = await organisationApi.get("/industries", {
    params: { page, name },
  });
  return response;
};

export const getOrganisation = async (id) => {
  const response = await api.get(`/organisations/${id}`);
  return response;
};

export const createOrganisation = async (data) => {
  const response = await api.post("/organisations", data);
  return response;
};

export const getUsers = async (page, pageSize, searchTerm, roles, status) => {
  const response = await organisationApi.get("/users", {
    params: {
      page,
      page_size: pageSize,
      search_term: searchTerm,
      roles,
      status,
    },
  });
  return response;
};

export const getAllUsers = async (page, searchTerm, roles) => {
  const response = await organisationApi.get("/organisation_users", {
    params: { page, search_term: searchTerm, roles },
  });
  return response;
};

export const createUser = async (data) => {
  const response = await organisationApi.post("/users", data);
  return response;
};

export const updateUser = async (data) => {
  const response = await organisationApi.patch(`/users/${data.id}`, data);
  return response;
};

// Roles APIs
export const getUserRoles = async () => {
  const response = await organisationApi.get("/roles");
  return response;
};

// Exams API
export const createExam = async (jobUuid, stageId, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageId}/exams`,
    data
  );
  return response;
};

export const updateExam = async (jobUuid, stageId, examId, data) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}/exams/${examId}`,
    data
  );
  return response;
};

export const notifyExam = async (jobUuid, stageId, examId) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}/notify_exams/${examId}`
  );
  return response;
};

export const getExams = async (jobUuid, stageId) => {
  const response = await organisationApi.get(
    `/jobs/${jobUuid}/stages/${stageId}/exams`
  );
  return response;
};

export const getSlots = async (examId) => {
  const response = await organisationApi.get(`/exam/slots`, {
    params: { exam_id: examId },
  });
  return response;
};

export const createSlots = async (jobUuid, stageId, examId, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageId}/exams/${examId}/slots`,
    data
  );
  return response;
};

export const getScheduledSlots = async (jobUuid, stageId, examId) => {
  const response = await organisationApi.get(
    `jobs/${jobUuid}/stages/${stageId}/exams/${examId}/slots`
  );
  return response;
};

export const deleteSlot = async (jobUuid, stageId, examId, examSlotIds) => {
  const response = await organisationApi.delete(
    `/jobs/${jobUuid}/stages/${stageId}/exams/${examId}/slots?`,
    {
      params: { exam_slot_ids: examSlotIds },
    }
  );
  return response;
};

export const deleteExam = async (jobUuid, stageId, examId) => {
  const response = await organisationApi.delete(
    `/jobs/${jobUuid}/stages/${stageId}/exams/${examId}`
  );
  return response;
};

export const getStageReport = async (jobUuid, stageId) => {
  const response = await organisationApi.get(`/jobs/${jobUuid}/stage_report`, {
    params: { stage_id: stageId },
  });
  return response;
};

// Organisation show/edit APIs
export const getOrganisationDetails = async () => {
  const response = await api.get("/organisation");
  return response;
};

export const updateOrganisationDetails = async (data) => {
  const response = await api.patch("/organisation", data);
  return response;
};

// Organisation Interview APIs
export const createInterview = async (jobUuid, stageId, candidateId, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageId}/candidates/${candidateId}/interviews`,
    data
  );
  return response;
};

export const updateInterview = async (
  jobUuid,
  stageId,
  candidateId,
  interviewId,
  data
) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}/candidates/${candidateId}/interviews/${interviewId}`,
    data
  );
  return response;
};

export const deleteInterview = async (
  jobUuid,
  stageId,
  candidateId,
  interviewId
) => {
  const response = await organisationApi.delete(
    `/jobs/${jobUuid}/stages/${stageId}/candidates/${candidateId}/interviews/${interviewId}`
  );
  return response;
};

export const getPanelDetails = async (params) => {
  const response = await organisationApi.get("/user/events", {
    params,
  });
  return response;
};

export const notifyInterview = async (
  jobUuid,
  stageId,
  candidateId,
  interviewId
) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}/candidates/${candidateId}/notify_interviews/${interviewId}`
  );
  return response;
};

// Organisation feedback APIs
export const createFeedbackForInterview = async (interviewId, data) => {
  const response = await organisationApi.post(
    `/interviews/${interviewId}/feedbacks`,
    data
  );
  return response;
};

export const createFeedbackForGroup = async (groupUuid, data) => {
  const response = await organisationApi.post(
    `/groups/${groupUuid}/candidate_feedbacks`,
    data
  );
  return response;
};

// get gd details
export const getCandidateGroupDetails = async (params) => {
  const response = await organisationApi.get(`/user/group_candidates`, {
    params,
  });
  return response;
};

export const getDashboardStats = async () => {
  const response = await organisationApi.get("/dashboard_stats");
  return response;
};

// Get Screenshots
export async function getScreenShots(candidateId, examId) {
  const response = await organisationApi.get("/exam_candidate/screen_shots", {
    params: {
      candidate_id: candidateId,
      exam_id: examId,
    },
  });
  return response;
}

// Download stored screenshots
export async function downloadScreenShots(params) {
  const response = await organisationFileApi.get(
    "/exam_candidate/download_screen_shots",
    {
      params,
    }
  );
  return response;
}

// Add Coding Exam Evaluators
export const createEvaluators = async (jobUuid, stageId, data) => {
  const response = await organisationApi.post(
    `/jobs/${jobUuid}/stages/${stageId}/exam_evaluators`,
    data
  );
  return response;
};

export const updateEvaluators = async (jobUuid, stageId, data) => {
  const response = await organisationApi.patch(
    `/jobs/${jobUuid}/stages/${stageId}/exam_evaluators`,
    data
  );
  return response;
};

// Import Question Pack
export const importQuestionPapers = async (uuid) => {
  const response = await organisationApi.post("/question_papers/import", uuid);
  return response;
};
