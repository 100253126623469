import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getContactDetailsction } from "store/thunkActions/candidate/contactDetailsThunk";

import CardContainer from "../Common/CardContainer";

import ContactDetail from "./ContactDetail";
import Form from "./Form";

const ContactDetails = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { contactDetails } = useSelector((state) => state.contactDetails);

  useEffect(() => {
    dispatch(getContactDetailsction());
  }, []);

  return (
    <CardContainer
      title="Address"
      addEventButtonTitle="Add Address"
      addEventButtonTestId="add-address-btn"
      setIsAdding={setIsAdding}
      hideAddButton={Boolean(contactDetails.length)}
    >
      {!contactDetails.length && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on the plus icon to add address
        </span>
      )}

      {contactDetails.map((contactDetail) => {
        return (
          <div
            key={contactDetail.id}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <ContactDetail contactDetail={contactDetail} />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsEditState={setIsAdding} setIsAdding={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default ContactDetails;
