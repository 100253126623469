import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import { Modal } from "components/molecules";
import { deleteEducationQualificationAction } from "store/thunkActions/candidate/educationQualificationThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

import DescriptiveCard from "../Common/DescriptiveCard";

import Form from "./Form";

const EducationQualification = ({ educationQualification }) => {
  const [isEditState, setIsEditState] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  const onDelete = () => {
    return dispatch(
      deleteEducationQualificationAction(educationQualification.id)
    )
      .unwrap()
      .then(() => dispatch(setCandidateUserAction()));
  };

  return isEditState ? (
    <Form
      educationQualification={educationQualification}
      setIsEditState={setIsEditState}
    />
  ) : (
    <DescriptiveCard
      title={educationQualification.college.name}
      editButtonTitle="Edit Education"
      deleteButtonTitle="Delete Education"
      editButtonTestId="editEducationBtn"
      deleteButtonTestId="deleteEducationBtn"
      setIsEditState={setIsEditState}
      setIsDeleting={setIsDeleting}
    >
      <p className="flex flex-col text-sm">
        <span>{educationQualification.education_level_name}</span>
        <span>
          {educationQualification.qualification_name}{" "}
          {educationQualification.marks &&
            ` | ${educationQualification.marks} %`}
        </span>
      </p>
      <p className="text-sm italic">
        <span>{educationQualification.branch_name}</span>
      </p>
      <p className="text-sm italic text-gray-main">
        {moment(educationQualification.start_date).format("MMMM YYYY")} -{" "}
        {educationQualification.is_pursuing
          ? "Pursuing"
          : moment(educationQualification.end_date).format("MMMM YYYY")}
      </p>
      {isDeleting && (
        <Modal
          title="Confirm Delete"
          description="This will delete the education from your profile."
          acceptButtonText="OK, Delete"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="Cancel"
          customButtonWidth={32}
          handleAccept={onDelete}
          handleReject={() => setIsDeleting(false)}
        />
      )}
    </DescriptiveCard>
  );
};

EducationQualification.propTypes = {
  educationQualification: PropTypes.shape({
    id: PropTypes.number,
    college: PropTypes.object,
    qualification_id: PropTypes.number,
    qualification_name: PropTypes.string,
    branch_id: PropTypes.number,
    branch_name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    is_pursuing: PropTypes.bool,
    education_level_name: PropTypes.string,
    marks: PropTypes.string,
  }),
};

export default EducationQualification;
