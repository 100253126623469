import PropTypes from "prop-types";

export const stageExamDetailsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    auto_approve: PropTypes.bool,
    auto_email: PropTypes.bool,
    camera_option: PropTypes.string,
    coding_languages: PropTypes.arrayOf(
      PropTypes.shape({
        driver_code: PropTypes.string,
        file_extension: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.number,
        type: PropTypes.string,
      })
    ),
    cut_off_marks: PropTypes.string,
    cut_off_marks_type: PropTypes.string,
    cut_off_type: PropTypes.string,
    duration: PropTypes.number,
    end_at: PropTypes.string,
    feedback_link: PropTypes.string,
    has_different_sectional_cut_off_marks: PropTypes.bool,
    id: PropTypes.number,
    is_notified: PropTypes.bool,
    multi_slot: PropTypes.bool,
    name: PropTypes.string,
    question_paper: PropTypes.shape({
      created_at: PropTypes.string,
      created_by: PropTypes.string,
      name: PropTypes.string,
      question_paper_type: PropTypes.string,
      duration: PropTypes.string,
      id: PropTypes.number,
      sectional: PropTypes.bool,
      total_marks: PropTypes.string,
      total_questions: PropTypes.number,
      total_sections: PropTypes.number,
      type: PropTypes.string,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          total_marks: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    }),
    question_paper_type: PropTypes.string,
    total_marks: PropTypes.string,
    question_paper_id: PropTypes.number,
    sectional_cut_off: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        exam_id: PropTypes.number,
        section_id: PropTypes.number,
        type: PropTypes.string,
        cut_off_marks: PropTypes.string,
      })
    ),
    slot_active_duration: PropTypes.number,
    start_at: PropTypes.string,
    total_questions: PropTypes.number,
    type: PropTypes.string,
  })
);
