import { useState } from "react";
import PropTypes from "prop-types";

import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import { Button } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import { Modal } from "components/molecules";

const InterviewFeedback = ({ group, interview, candidateName }) => {
  const [showFeedback, setShowFeedback] = useState(null);
  const [interviewFeed, setInterviewFeed] = useState(null);

  return (
    <>
      {(interview || group) && (
        <div className="pt-1">
          <Button
            type="tertiary"
            btnName="View Feedback"
            title="View Feedback"
            onClick={() => {
              if (group) {
                setInterviewFeed(group);
              } else {
                setInterviewFeed(interview);
              }
              setShowFeedback(true);
            }}
          />
        </div>
      )}

      {showFeedback && (
        <Modal
          title={`Feedback for ${candidateName}`}
          handleCancel={() => setShowFeedback(false)}
          scrollClass="overflow-y-auto max-h-96"
        >
          {interviewFeed?.interview_users?.length > 0 &&
            interviewFeed?.interview_users?.map((user) => {
              let feedbackMarks;
              let feedbackComment;
              if (user.feedback && user.feedback.is_appeared) {
                feedbackMarks = `(${user.feedback.marks}/5)`;
                feedbackComment = `${user.feedback.comment || ""}`;
              } else if (user.feedback && !user.feedback.is_appeared) {
                feedbackMarks = null;
                feedbackComment = "Didn't appear";
              } else {
                feedbackMarks = null;
                feedbackComment = "";
              }

              return (
                <div
                  className="w-full border-b border-gray-lighterShade4 p-2 text-sm selection:bg-primary-lightest last:border-b-0"
                  key={user.id}
                >
                  <div className="flex flex-col">
                    <span className="mt-1 mb-2 text-base font-semibold">
                      {user.user_name || ""} &nbsp;
                      {feedbackMarks}
                    </span>

                    <span>
                      <RenderParsedHTML content={feedbackComment || ""} />
                    </span>
                  </div>
                </div>
              );
            })}

          {interviewFeed?.group_moderators?.length > 0 &&
            interviewFeed?.group_moderators?.map((user) => {
              let feedbackMarks;
              let feedbackComment;
              if (user.feedback && user.feedback.is_appeared) {
                feedbackMarks = `(${user.feedback.marks}/5)`;
                feedbackComment = `${user.feedback.comment || ""}`;
              } else if (user.feedback && !user.feedback.is_appeared) {
                feedbackMarks = null;
                feedbackComment = "Didn't appear";
              } else {
                feedbackMarks = null;
                feedbackComment = "";
              }

              return (
                <div
                  className="w-full border-b border-gray-lighterShade4 p-2 text-sm selection:bg-primary-lightest last:border-b-0"
                  key={user.moderator.id}
                >
                  <div className="flex flex-col">
                    <span className="mt-1 mb-2 text-base font-semibold">
                      {user.moderator.name || ""} &nbsp;
                      {feedbackMarks}
                    </span>

                    <span>
                      <RenderParsedHTML content={feedbackComment || ""} />
                    </span>
                  </div>
                </div>
              );
            })}

          {interviewFeed?.interview_users &&
            !interviewFeed?.interview_users?.length && (
              <div className="text-center">
                <img
                  src={NoDataFound}
                  alt="No feedback found"
                  className="m-auto w-40
              "
                />

                <p className="mt-4 text-gray-500">
                  No feedback found for interview
                  <span className="italic"> {interview?.title} </span>!
                </p>
              </div>
            )}

          {interviewFeed?.group_moderators &&
            !interviewFeed?.group_moderators?.length && (
              <div className="text-center">
                <img
                  src={NoDataFound}
                  alt="No feedback found"
                  className="m-auto w-40"
                />

                <p className="mt-4 text-gray-500">
                  No feedback found for group
                  <span className="italic"> {group?.title} </span>!
                </p>
              </div>
            )}
        </Modal>
      )}
    </>
  );
};

InterviewFeedback.propTypes = {
  interview: PropTypes.array.isRequired,
  candidateName: PropTypes.string.isRequired,
};

export default InterviewFeedback;
