const NavigatorPermissionChangeEvent = (setCaptureModal, showAlert) => {
  navigator?.permissions
    ?.query({ name: "camera" })
    .then((res) => {
      res.onchange = (e) => {
        if (e.type === "change") {
          const newState = e.target.state;
          if (newState === "denied") {
            setCaptureModal(false);
          }
        }
      };
    })
    .catch(() => {
      showAlert("danger", "Camera permission denied.");
    });
};

export default NavigatorPermissionChangeEvent;
