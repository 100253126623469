import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { bool } from "prop-types";

import ProviderButtons from "components/common/ProviderButtons";
import { SITE_TITLE } from "constants/common";

import Form from "./Form";

const Signin = ({ isRedirect = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRedirect) {
      navigate(
        `/login?redirect_url=${encodeURIComponent(
          location.pathname + location.search
        )}`
      );
    }
  }, [isRedirect]);

  useEffect(() => {
    document.title = `Organisation Login - ${SITE_TITLE}`;
  }, []);

  return (
    <div className="container mx-auto">
      <h1 className="pt-6 text-center text-3xl font-normal text-primary-main">
        Organisation Login to JobAlley
      </h1>
      <div className="mx-auto flex max-w-md flex-col lg:my-6 lg:max-w-3xl lg:flex-row-reverse ">
        <ProviderButtons isOrganisation />

        <div className="flex w-full border-t-2 border-dashed border-gray-400 p-3 md:items-center lg:w-1/2 lg:border-t-0 lg:border-r-2">
          <Form />
        </div>
      </div>
    </div>
  );
};
Signin.propTypes = {
  isRedirect: bool,
};

export default Signin;
