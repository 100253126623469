import {
  RiBriefcase4Line,
  RiDeleteBackLine,
  RiEdit2Line,
  RiFileCopyLine,
  RiRefreshLine,
  RiStopCircleLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { camelCase } from "lodash";
import PropTypes from "prop-types";

import { Button } from "components/atoms";
import { JOB_STATUS } from "constants/jobs";

const DotMenubuttonClass = "cursor-pointer hover:bg-primary-lighter w-full p-2";
const DotMenu = ({
  job,
  setIsApplicationClosed,
  setIsClosed,
  setIsActive,
  dropdownMenuRef,
  customTopClass = "top-14",
}) => {
  const navigate = useNavigate();

  return job?.status === JOB_STATUS.active.text ? (
    <div
      ref={dropdownMenuRef}
      className={classNames(
        customTopClass,
        "absolute right-8 z-10 mt-3 flex origin-top-right flex-col rounded-md bg-white text-sm shadow-lg ring-1 ring-primary-main ring-opacity-5 focus:outline-none"
      )}
    >
      <Button
        title="Edit"
        btnClassName={DotMenubuttonClass}
        onClick={() => navigate(`/jobs/${job?.uuid}/edit`)}
        btnName={
          <div className="flex px-4">
            <RiEdit2Line className="mr-2 mt-[1.5px] text-base" />
            Edit
          </div>
        }
      />

      <Button
        title="Close Application"
        btnClassName={DotMenubuttonClass}
        onClick={() => setIsApplicationClosed(true)}
        btnName={
          <div className="flex px-4">
            <RiDeleteBackLine className="mr-2 mt-[1.5px] text-base" />
            Close Application
          </div>
        }
      />

      <Button
        title="Close Job"
        btnClassName={DotMenubuttonClass}
        onClick={() => setIsClosed(true)}
        btnName={
          <div className="flex px-4">
            <RiStopCircleLine className="mr-2 mt-[1.5px] text-base" />
            Close Job
          </div>
        }
      />

      <Button
        title="Clone Job"
        btnClassName={DotMenubuttonClass}
        onClick={() => navigate(`/jobs/${job?.uuid}/edit?clone=true`)}
        btnName={
          <div className="flex px-4">
            <RiFileCopyLine className="mr-2 mt-[1.5px] text-base" />
            Clone Job
          </div>
        }
      />
    </div>
  ) : (
    <div
      ref={dropdownMenuRef}
      className="absolute right-8 top-14 z-10 mt-3 flex min-w-[6%] origin-top-right flex-col rounded-md bg-white text-sm shadow-lg ring-1 ring-primary-main ring-opacity-5 focus:outline-none"
    >
      {camelCase(job?.status) === JOB_STATUS.applicationClosed.text ? (
        <>
          <Button
            title="Close Job"
            btnClassName={DotMenubuttonClass}
            onClick={() => setIsClosed(true)}
            btnName={
              <div className="flex px-4">
                <RiStopCircleLine className="mr-2 mt-[1.5px] text-base" />
                Close Job
              </div>
            }
          />

          <Button
            title="Clone Job"
            btnClassName={DotMenubuttonClass}
            onClick={() => navigate(`/jobs/${job?.uuid}/edit?clone=true`)}
            btnName={
              <div className="flex px-4">
                <RiFileCopyLine className="mr-2 mt-[1.5px] text-base" />
                Clone Job
              </div>
            }
          />

          <Button
            title="Activate Job"
            btnClassName={DotMenubuttonClass}
            onClick={() =>
              navigate(`/jobs/${job?.uuid}/edit?activate_job=true`)
            }
            btnName={
              <div className="flex px-4">
                <RiRefreshLine className="mr-2 mt-[1.5px] text-base" />
                Activate Job
              </div>
            }
          />
        </>
      ) : (
        <div ref={dropdownMenuRef} className="flex flex-col">
          {camelCase(job.status) === JOB_STATUS.draft.text ? (
            <>
              <Button
                title="Edit"
                btnClassName={DotMenubuttonClass}
                onClick={() => navigate(`/jobs/${job?.uuid}/edit`)}
                btnName={
                  <div className="flex px-4">
                    <RiEdit2Line className="mr-2 mt-[1.5px] text-base" />
                    Edit
                  </div>
                }
              />

              <Button
                title="Post"
                btnClassName={DotMenubuttonClass}
                onClick={() => setIsActive(true)}
                btnName={
                  <div className="flex px-4">
                    <RiBriefcase4Line className="mr-2 mt-[1.5px] text-base" />
                    Post
                  </div>
                }
              />

              <Button
                title="Clone"
                btnClassName={DotMenubuttonClass}
                onClick={() => navigate(`/jobs/${job?.uuid}/edit?clone=true`)}
                btnName={
                  <div className="flex px-4">
                    <RiFileCopyLine className="mr-2 mt-[1.5px] text-base" />
                    Clone
                  </div>
                }
              />
            </>
          ) : (
            <Button
              title="Clone Job"
              btnClassName={DotMenubuttonClass}
              onClick={() => navigate(`/jobs/${job?.uuid}/edit?clone=true`)}
              btnName={
                <div className="flex">
                  <RiFileCopyLine className="mr-2 mt-[1.5px] text-base" />
                  Clone Job
                </div>
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

DotMenu.propTypes = {
  job: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    requirements: PropTypes.string,
    location: PropTypes.string,
    no_of_positions: PropTypes.number,
    ctc: PropTypes.string,
  }),
  setIsActive: PropTypes.func,
  setIsApplicationClosed: PropTypes.func,
  setIsClosed: PropTypes.func,
  dropdownMenuRef: PropTypes.any,
  customTopClass: PropTypes.string,
};

export default DotMenu;
