import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import { LOBBY_TYPES } from "app/constants";
import {
  getStagesAction,
  updateStageAction,
} from "store/thunkActions/erp/stagesThunk";

const UL_CLASS =
  "flex mb-0 list-none flex-row border-collapse pl-0 overflow-x-auto overflow-y-hidden text-gray-dark tab-list-bar";
const SCROLL_CLASS =
  "hidden lg:block text-gray-dark text-2xl mt-2 hover:text-primary-dark";
const TabList = ({
  link,
  tabs,
  lobbyTabs,
  stageTabs,
  currentTab,
  customClassNames,
  currentStageTab,
  children,
  paramBased,
  testId,
  onTabClick,
  onStageTabClick,
  optionalClassName,
  isDragAndDropAllowed,
}) => {
  const liClass = (key) =>
    classNames("flex items-center ", {
      "font-semibold border-b-2 border-primary-dark text-primary-dark":
        currentTab == key || currentStageTab === key,
      [optionalClassName]: optionalClassName,
    });

  const tablistRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState(false);

  const ScrollDirection = {
    LEFT: -1,
    RIGHT: 1,
  };

  const isAALobbyPresent = () => lobbyTabs?.[0] !== undefined;

  const isPlacedLobbyPresent = () => lobbyTabs?.[1] !== undefined;

  const handleScroll = () => {
    if (tablistRef.current) {
      const tablist = tablistRef.current;
      const scrollPosition = Math.ceil(
        tablist.clientWidth + tablist.scrollLeft
      );
      setCanScrollLeft(tablist.scrollLeft > 0);

      if (
        tablist.scrollWidth > tablist.clientWidth &&
        tablist.scrollWidth > scrollPosition &&
        currentTab !== lobbyTabs?.[1].key
      ) {
        setCanScrollRight(true);
      } else {
        setCanScrollRight(false);
      }
    }
  };

  useEffect(() => {
    const animation = requestAnimationFrame(() =>
      setIsDragAndDropEnabled(true)
    );

    return () => {
      cancelAnimationFrame(animation);
      setIsDragAndDropEnabled(false);
    };
  }, []);

  useLayoutEffect(() => {
    if (tablistRef.current) {
      tablistRef.current.addEventListener("scroll", handleScroll);
    }

    handleScroll();

    return () => {
      if (tablistRef.current) {
        tablistRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tabs, lobbyTabs, currentStageTab, stageTabs]);

  useEffect(() => {
    const tablist = tablistRef.current;
    if (!isEmpty(stageTabs)) {
      const selectedTabKey =
        currentTab === LOBBY_TYPES[1].key
          ? stageTabs.slice(-1)[0].key
          : currentTab || currentStageTab;

      const selectedItem = tablist.querySelector(`#tab-id-${selectedTabKey}`);

      if (selectedItem && tablist.scrollWidth > tablist.clientWidth) {
        const newPosition =
          selectedItem.offsetLeft -
          tablist.clientWidth / 2 +
          selectedItem.clientWidth / 2;

        tablist.scrollTo({
          left: newPosition,
          behavior: "smooth",
        });
      }
    }
  }, [currentStageTab, currentTab, stageTabs]);

  const scrollTablist = (scrollDirection) => {
    if (tablistRef.current) {
      const tablist = tablistRef.current;
      const scrollAmount = scrollDirection * tablist.clientWidth * 0.3;
      const newPosition = tablist.scrollLeft + scrollAmount;

      tablist.scrollTo({
        left: newPosition,
        behavior: "smooth",
      });
    }
  };

  const onDragEnd = (result) => {
    if (
      !result.destination ||
      result.destination?.index === result.source?.index
    ) {
      return;
    }

    const items = Array.from(stageTabs);
    const [recordedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, recordedItem);

    dispatch(
      updateStageAction({
        jobUuid: params.uuid,
        stageId: recordedItem.key,
        data: stageTabs[result.destination.index],
      })
    ).then(() => {
      dispatch(getStagesAction(params.uuid));
      onStageTabClick(recordedItem.key);
    });
  };

  const handleClick = (key) => {
    if (stageTabs) {
      onStageTabClick(key);
    } else {
      onTabClick(key);
    }
  };

  const handleKeyDown = (e, key) => {
    if (e.key === "Enter" || e.key === " ") {
      if (stageTabs) {
        onStageTabClick(key);
      } else {
        onTabClick(key);
      }
    }
  };

  return (
    <div>
      <div className="flex">
        {canScrollLeft && (
          <button
            title="Scroll Left"
            type="button"
            className={`${SCROLL_CLASS}`}
            onClick={() => scrollTablist(ScrollDirection.LEFT)}
          >
            &lt;
          </button>
        )}

        <ul
          className={classNames(UL_CLASS, customClassNames)}
          role="tablist"
          data-testid={testId}
          ref={tablistRef}
        >
          {isAALobbyPresent() && (
            <div className="sm:sticky sm:left-0 sm:z-20">
              <li
                key={`${lobbyTabs?.[0].label} + ${lobbyTabs?.[0].key}`}
                className={`${liClass(
                  lobbyTabs?.[0].key
                )} bg-gray-lighterShade3`}
                id={`tab-id-${lobbyTabs?.[0].key}`}
              >
                {paramBased ? (
                  <button
                    title={lobbyTabs?.[0].label}
                    type="button"
                    role="link"
                    onClick={() => onTabClick(lobbyTabs?.[0].key)}
                    className={classNames(
                      "h-full whitespace-nowrap px-6 pb-2 my-1  text-base text-primary-main",
                      {
                        "hover:text-gray-900":
                          currentTab !== lobbyTabs?.[0].key,
                      }
                    )}
                  >
                    {lobbyTabs?.[0].label}
                  </button>
                ) : (
                  <Link
                    to={`${link}/${lobbyTabs?.[0].key}`}
                    onClick={() => onTabClick(lobbyTabs?.[0].key)}
                    className={classNames(
                      "h-full w-max px-6 pb-2 tracking-tighter",
                      {
                        "hover:text-gray-900":
                          currentTab !== lobbyTabs?.[0].key,
                      }
                    )}
                  >
                    {lobbyTabs?.[0].label}
                  </Link>
                )}
              </li>
            </div>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            {isDragAndDropEnabled && (
              <Droppable droppableId="drag-drop" direction="horizontal">
                {(provided) => (
                  // The library for drag and drop doesn't include support for buttons, so the button is replaced with a more suitable element.
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex"
                  >
                    {stageTabs?.map(({ label, key }, index) => (
                      <Draggable
                        draggableId={key}
                        key={label + key}
                        index={index}
                        isDragDisabled={!isDragAndDropAllowed}
                      >
                        {(provided, snapshot) => (
                          <li
                            className={liClass(key)}
                            id={`tab-id-${key}`}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={{
                              backgroundColor: snapshot.isDragging && "#f0f0f0",
                              ...provided.draggableProps.style,
                            }}
                          >
                            {paramBased ? (
                              <div
                                role="link"
                                tabIndex={0}
                                onClick={() => handleClick(key)}
                                onKeyDown={() => handleKeyDown(key)}
                                className={classNames(
                                  "h-full w-max px-6 text-base mt-2",
                                  {
                                    "hover:text-gray-900": stageTabs
                                      ? currentStageTab !== key
                                      : currentTab !== key,
                                  },
                                  {
                                    "hover:cursor-pointer":
                                      !isDragAndDropAllowed,
                                  }
                                )}
                              >
                                {label}
                              </div>
                            ) : (
                              <Link
                                to={`${link}/${key}`}
                                onClick={() =>
                                  stageTabs
                                    ? onStageTabClick(key)
                                    : onTabClick(key)
                                }
                                className={classNames(
                                  "h-full w-max px-6 pb-2 tracking-tighter",
                                  {
                                    "hover:text-gray-900": stageTabs
                                      ? currentStageTab !== key
                                      : currentTab !== key,
                                  }
                                )}
                              >
                                {label}
                              </Link>
                            )}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </DragDropContext>

          {isPlacedLobbyPresent() && (
            <div className="sm:sticky sm:right-0 sm:z-20">
              <li
                key={`${lobbyTabs?.[1].label} + ${lobbyTabs?.[1].key}`}
                className={`${liClass(
                  lobbyTabs?.[1].key
                )} bg-gray-lighterShade3`}
                id={`tab-id-${lobbyTabs?.[1].key}`}
              >
                {paramBased ? (
                  <button
                    title={lobbyTabs?.[1].label}
                    type="button"
                    role="link"
                    onClick={() => {
                      onTabClick(lobbyTabs?.[1].key);
                    }}
                    className={classNames(
                      "h-full whitespace-nowrap px-6 pb-2 my-1 text-base text-primary-main",
                      {
                        "hover:text-gray-900":
                          currentTab !== lobbyTabs?.[1].key,
                      }
                    )}
                  >
                    {lobbyTabs?.[1].label}
                  </button>
                ) : (
                  <Link
                    to={`${link}/${lobbyTabs?.[1].key}`}
                    onClick={() => onTabClick(lobbyTabs?.[1].key)}
                    className={classNames(
                      "h-full w-max px-6 pb-2 tracking-tighter",
                      {
                        "hover:text-gray-900":
                          currentTab !== lobbyTabs?.[1].key,
                      }
                    )}
                  >
                    {lobbyTabs?.[1].label}
                  </Link>
                )}
              </li>
            </div>
          )}
          {tabs?.map(({ label, key }) => (
            <li key={label + key} className={liClass(key)} id={`tab-id-${key}`}>
              {paramBased ? (
                <button
                  type="button"
                  role="link"
                  onClick={() =>
                    stageTabs ? onStageTabClick(key) : onTabClick(key)
                  }
                  className={classNames("h-full w-max px-6 pb-2 text-base", {
                    "hover:text-gray-900": stageTabs
                      ? currentStageTab !== key
                      : currentTab !== key,
                  })}
                >
                  {label}
                </button>
              ) : (
                <Link
                  to={`${link}/${key}`}
                  onClick={() =>
                    stageTabs ? onStageTabClick(key) : onTabClick(key)
                  }
                  className={classNames(
                    "h-full w-max px-6 pb-2 tracking-tighter",
                    {
                      "hover:text-gray-900": stageTabs
                        ? currentStageTab !== key
                        : currentTab !== key,
                    }
                  )}
                >
                  {label}
                </Link>
              )}
            </li>
          ))}
        </ul>

        {canScrollRight && (
          <button
            title="Scroll Right"
            type="button"
            className={`${SCROLL_CLASS}`}
            onClick={() => scrollTablist(ScrollDirection.RIGHT)}
          >
            &gt;
          </button>
        )}
      </div>
      <hr className="mt-auto w-full border bg-gray-lighter" />
      {children}
    </div>
  );
};

TabList.defaultProps = {
  currentTab: undefined,
  link: "#",
  testId: "tab-list",
  children: null,
  paramBased: false,
  onTabClick: () => {},
  onStageTabClick: () => {},
  customClassNames: "pt-4",
  stageTabs: undefined,
  lobbyTabs: undefined,
  tabs: undefined,
};

TabList.propTypes = {
  link: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  stageTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  lobbyTabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  currentTab: PropTypes.string,
  currentStageTab: PropTypes.string,
  children: PropTypes.node,
  paramBased: PropTypes.bool,
  testId: PropTypes.string,
  customClassNames: PropTypes.string,
  optionalClassName: PropTypes.string,
  onTabClick: PropTypes.func,
  onStageTabClick: PropTypes.func,
  isDragAndDropAllowed: PropTypes.bool,
};

export default TabList;
