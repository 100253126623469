import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { FEATURE_FLAGS } from "app/constants";
import { checkErpEnvs, getCurrentSubdomain } from "helpers/utils";
import checkUserRole from "hooks/useCheckUserRole";

const SideNavigation = ({ links, openAside, setOpenAside }) => {
  const subdomain = getCurrentSubdomain();
  const featureFlags = useSelector((state) => state.featureFlags?.flags);
  const mockTestFeature = featureFlags?.some(
    (obj) => obj.name === FEATURE_FLAGS.MOCK_TEST
  );

  return (
    <ul className="mt-7 pl-3 text-sm">
      {checkErpEnvs(subdomain)
        ? links.map(
            ({ path, label, icon, allowedRoles }) =>
              checkUserRole(allowedRoles) && (
                <li key={label} className="list-none">
                  <NavLink
                    className={({ isActive }) =>
                      classNames(
                        "navlink mt-2 flex items-center rounded-tl-3xl py-3 px-5 font-bold text-primary-lighterShade1 hover:bg-gray-lighterShade3 hover:text-primary-darker",
                        { "is-activelabel bg-gray-lighterShade3": isActive }
                      )
                    }
                    data-testid={`${label}-sidenavlink`}
                    onClick={() => {
                      setOpenAside(false);
                      sessionStorage.removeItem("jobPage");
                      sessionStorage.removeItem("questionsPage");
                    }}
                    tabIndex={openAside ? 0 : -1}
                    to={path}
                  >
                    <span className="icon"> {icon} </span>
                    <span className="ml-2 text-base">{label}</span>
                  </NavLink>
                </li>
              )
          )
        : links.map(({ path, label, icon }) => {
            return (
              (label !== "Mock Tests" ||
                (label === "Mock Tests" && mockTestFeature)) && (
                <li key={label} className="list-none">
                  <NavLink
                    className={({ isActive }) =>
                      classNames(
                        "navlink mt-2 flex items-center rounded-tl-3xl py-3 px-5 font-bold text-primary-lighterShade1 hover:bg-gray-lighterShade3 hover:text-primary-darker",
                        { "is-activelabel bg-gray-lighterShade3": isActive }
                      )
                    }
                    data-testid={`${label}-sidenavlink`}
                    onClick={() => setOpenAside(false)}
                    tabIndex={openAside ? 0 : -1}
                    to={path}
                  >
                    <span className="icon"> {icon} </span>
                    <span className="ml-2 text-base">{label}</span>
                  </NavLink>
                </li>
              )
            );
          })}
    </ul>
  );
};

SideNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.node,
      allowedRoles: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  openAside: PropTypes.bool,
  setOpenAside: PropTypes.func,
};

export default SideNavigation;
