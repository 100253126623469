import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";

import {
  AppliedSortArrowIcon,
  CommonSortArrowIcon,
} from "components/common/Arrow/SortArrow";
import {
  showAppliedTooltipSort,
  showTooltipSort,
} from "helpers/pipeline/sort/showToolTip";
import { toCamelCase } from "helpers/utils";

const AllApplicantColumnHeaders = ({
  filteredAllApplicantsHeaders,
  sortBy,
  sortOrder,
  onClickSort,
}) => {
  return (
    <>
      {filteredAllApplicantsHeaders.map(
        ({
          label,
          sortingAllowed,
          sortByText,
          sortFlag,
          appliedOnSortingRule,
          headerClassName,
        }) => {
          if (sortingAllowed) {
            return (
              <th
                className={classNames("group text-left", headerClassName)}
                key={label}
              >
                <button
                  data-tooltip-id={`${toCamelCase(label)}Sort`}
                  data-tooltip-content={
                    appliedOnSortingRule
                      ? showAppliedTooltipSort(sortByText, sortBy, sortOrder)
                      : showTooltipSort(sortByText, sortBy, sortOrder)
                  }
                  onClick={() => onClickSort(sortByText, sortFlag)}
                >
                  <span className="mr-2 text-base font-semibold text-primary-dark">
                    {label}
                  </span>
                  <span className="inline-block">
                    {appliedOnSortingRule ? (
                      <AppliedSortArrowIcon
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        sortByText={sortByText}
                      />
                    ) : (
                      <CommonSortArrowIcon
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        sortByText={sortByText}
                      />
                    )}
                  </span>
                  <Tooltip id={`${toCamelCase(label)}Sort`} variant="dark" />
                </button>
              </th>
            );
          }

          return (
            <th
              className={classNames(
                "bg-gray-lighterShade3 text-left py-4 px-2",
                headerClassName
              )}
              key={label}
            >
              <span className="text-base font-semibold text-primary-dark">
                {label}
              </span>
            </th>
          );
        }
      )}
    </>
  );
};

AllApplicantColumnHeaders.propTypes = {
  filteredAllApplicantsHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      headerClassName: PropTypes.string.isRequired,
      sortByText: PropTypes.string,
      sortingAllowed: PropTypes.bool,
      sortFlag: PropTypes.bool,
      appliedOnSortingRule: PropTypes.bool,
    })
  ),
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onClickSort: PropTypes.func.isRequired,
};

AllApplicantColumnHeaders.defaultProps = {
  filteredAllApplicantsHeaders: [],
};

export default AllApplicantColumnHeaders;
