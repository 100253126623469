import parseISO from "date-fns/parseISO";

import { CANDIDATE_ACADEMIC_LIST } from "app/constants";
import {
  CUT_OFF_TYPES,
  MARKS_TYPES,
  PORTFOLIO_URL_TYPES,
  WILLING_TO_RELOCATE_TYPES,
} from "constants/common";

const resetFilterForm = (
  searchParams,
  CANDIDATE_STATUS_LIST,
  reset,
  groupsList
) => {
  const sectionalCutoff = {};
  const academicMarks = {};

  const sectionalData = searchParams.get("sectional_cutoff");
  const parseSectionalData = JSON.parse(decodeURIComponent(sectionalData));

  const academicMarksParam = searchParams.get("academic_marks");
  const parseAcademicMarks = JSON.parse(decodeURIComponent(academicMarksParam));

  parseSectionalData?.map((section) => {
    sectionalCutoff[`enter_section_cut_off_marks_${section.section_id}`] =
      searchParams.get("marks_type") === "PERCENTAGE"
        ? (section.cut_off_marks * 100).toFixed(2)
        : section.cut_off_marks;
  });

  parseAcademicMarks?.map((academicData, index) => {
    // eslint-disable-next-line prefer-destructuring
    academicMarks[`education_level_id_${index}`] =
      CANDIDATE_ACADEMIC_LIST.filter(({ id }) => {
        return Number(id) === Number(academicData.education_level_id);
      })[0];

    academicMarks[`marks_${index}`] = academicData.marks;
  });

  // TODO: Optimize the other filter functions

  reset({
    ...(searchParams.get("candidate_name")
      ? { candidate_name: searchParams.get("candidate_name") }
      : { candidate_name: null }),
    ...(searchParams.get("project")
      ? { project: searchParams.get("project") }
      : { project: null }),
    ...(searchParams.get("candidate_id")
      ? { candidate_id: searchParams.get("candidate_id") }
      : { candidate_id: null }),
    ...(searchParams.get("cutoff_type")
      ? {
          cutoff_type: CUT_OFF_TYPES.find(
            (cutoffType) => cutoffType.value === searchParams.get("cutoff_type")
          ),
        }
      : {}),
    ...(searchParams.get("cutoff_marks")
      ? {
          cutoff_marks:
            searchParams.get("marks_type") === "PERCENTAGE"
              ? Number(searchParams.get("cutoff_marks") * 100)
                  .toFixed(2)
                  .toString()
              : searchParams.get("cutoff_marks"),
        }
      : {}),
    ...(searchParams.get("marks_type")
      ? {
          marks_type: MARKS_TYPES.find(
            (marksType) => marksType.value === searchParams.get("marks_type")
          ),
        }
      : {}),
    ...(searchParams.get("has_different_sectional_cut_off_marks")
      ? {
          has_different_sectional_cut_off_marks:
            searchParams.get("has_different_sectional_cut_off_marks") ===
            "true",
        }
      : {}),
    ...sectionalCutoff,
    ...academicMarks,
    ...(searchParams.has("statuses")
      ? {
          statuses: CANDIDATE_STATUS_LIST.filter(({ value }) =>
            searchParams.getAll("statuses").includes(value)
          ),
        }
      : {}),
    ...(searchParams.has("locations")
      ? {
          locations: searchParams.getAll("locations").map((name) => {
            return { name };
          }),
        }
      : {}),
    ...(searchParams.has("groups")
      ? {
          groups: groupsList?.map((groupTitle) => {
            return groupTitle;
          }),
        }
      : {}),
    ...(searchParams.has("has_portfolio")
      ? {
          has_portfolio: PORTFOLIO_URL_TYPES.filter(
            ({ value }) => searchParams.get("has_portfolio") === value
          ),
        }
      : {}),
    ...(searchParams.has("willing_to_relocate")
      ? {
          willing_to_relocate: WILLING_TO_RELOCATE_TYPES.filter(
            ({ value }) => searchParams.get("willing_to_relocate") === value
          ),
        }
      : {}),
    ...(searchParams.get("from_date")
      ? { from_date: parseISO(searchParams.get("from_date")) }
      : {}),
    ...(searchParams.get("to_date")
      ? { to_date: parseISO(searchParams.get("to_date")) }
      : {}),
    ...(searchParams.has("appeared")
      ? { appeared: searchParams.get("appeared") === "true" }
      : {}),
  });
};
export { resetFilterForm };
