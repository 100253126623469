import api, { candidateApi, masterDataApi } from "api/axios";

// Current User APIs
export const getCurrentUser = async () => {
  const response = await candidateApi.get("/me");
  return response;
};

export const checkProfile = async () => {
  const response = await candidateApi.get("/check_profile");
  return response;
};

export const updateCurrentUser = async (data) => {
  const response = await candidateApi.put("/me", data);
  return response;
};

export const uploadResume = async (data) => {
  const response = await candidateApi.patch("/upload_resume", data);
  return response;
};

export const removeResume = (data) => {
  const response = candidateApi.delete("/delete_resume", data);
  return response;
};

export const removeCandidatePicture = (data) =>
  candidateApi.delete("/delete_avatar", data);

// Skills APIs
export const getSkills = async (page, name) => {
  // todo: debug the issue with masterDataApi
  const response = await api.get("/master_data/skills", {
    params: { page, name },
  });
  return response;
};

export const getCandidateSkills = async () => {
  const response = await candidateApi.get("/candidate_skills");
  return response;
};

export const updateMailSubscriptionPreference = async (mailSubscription) => {
  const response = await candidateApi.patch("/communication_preferences", {
    mail_subscription: mailSubscription,
  });
  return response;
};

export const addCandidateSkill = async (data) => {
  const response = await candidateApi.post("/candidate_skills", data);
  return response;
};

export const updateCandidateSkill = async (data) => {
  const response = await candidateApi.patch(`/candidate_skills/${data.id}`, {
    proficiency: data.proficiency,
  });
  return response;
};

export const removeCandidateSkill = async (id) => {
  const response = await candidateApi.delete(`/candidate_skills/${id}`);
  return response;
};

// language apis
export const getLanguages = async (page, name) => {
  // todo: debug the issue with masterDataApi
  const response = await api.get("/master_data/languages", {
    params: { page, name },
  });
  return response;
};

export const getCandidateLanguage = async () => {
  const response = await candidateApi.get("/candidate_languages");
  return response;
};

export const addCandidateLanguage = async (data) => {
  const response = await candidateApi.post("/candidate_languages", data);
  return response;
};

export const updateCandidateLanguage = async (data) => {
  const response = await candidateApi.patch(`/candidate_languages/${data.id}`, {
    proficiency: data.proficiency,
  });
  return response;
};

export const removeCandidateLanguage = async (id) => {
  const response = await candidateApi.delete(`/candidate_languages/${id}`);
  return response;
};

// Work Experience APIs
export const getEmploymentTypes = async () => {
  const response = await api.get("/employment_types");
  return response;
};

export const getLocationTypes = async () => {
  const response = await api.get("/location_types");
  return response;
};

export const getCompanies = async (page, name) => {
  // todo: debug the issue with masterDataApi
  const response = await api.get("/master_data/companies", {
    params: { page, name },
  });
  return response;
};

export const getDesignations = async (page, name) => {
  const response = await api.get("/designations", { params: { page, name } });
  return response;
};

export const getWorkExperiences = async () => {
  const response = await candidateApi.get("/work_experiences");
  return response;
};

export const creatWorkExperience = async (data) => {
  const response = await candidateApi.post("/work_experiences", data);
  return response;
};

export const updateWorkExperience = async (data) => {
  const response = await candidateApi.patch(
    `/work_experiences/${data.id}`,
    data
  );
  return response;
};

export const deleteWorkExperience = async (id) => {
  const response = await candidateApi.delete(`/work_experiences/${id}`);
  return response;
};

// Contact details APIs
export const getContactDetails = async () => {
  const response = await candidateApi.get("/addresses");
  return response;
};

export const createContactDetails = async (data) => {
  const response = await candidateApi.post("/addresses", data);
  return response;
};

export const updateContactDetails = async (data) => {
  const response = await candidateApi.patch(`/addresses/${data.id}`, data);
  return response;
};

export const deleteContactDetails = async (id) => {
  const response = await candidateApi.delete(`/addresses/${id}`);
  return response;
};

// Project Api
export const getProjects = async () => {
  const response = await candidateApi.get("/projects");
  return response;
};

export const createProject = async (data) => {
  const response = await candidateApi.post("/projects", data);
  return response;
};

export const updateProject = async (data) => {
  const response = await candidateApi.patch(`/projects/${data.id}`, data);
  return response;
};

export const deleteProject = async (id) => {
  const response = await candidateApi.delete(`/projects/${id}`);
  return response;
};

// Education Qualification API
export const getColleges = async (page, name, stateId) => {
  const response = await api.get("/colleges", {
    params: {
      page,
      name,
      state_id: stateId,
    },
  });
  return response;
};

export const getStates = async (page, name) => {
  const response = await masterDataApi.get("/states", {
    params: {
      page,
      name,
    },
  });
  return response;
};

export const getCities = async (page, searchTerm, stateUuid) => {
  const response = await masterDataApi.get(`/states/${stateUuid}/cities`, {
    params: {
      page,
      search_term: searchTerm,
    },
  });
  return response;
};

export const getQualifications = async (page, name, educationLevelId) => {
  // todo: debug the issue with masterDataApi
  const response = await api.get("/master_data/qualifications", {
    params: { page, name, education_level_id: educationLevelId },
  });
  return response;
};

export const getSpecializations = async (page, name) => {
  const response = await api.get("/branches", { params: { page, name } });
  return response;
};

export const getEducationQualifications = async () => {
  const response = await candidateApi.get("/educations");
  return response;
};

export const createEducationQualification = async (data) => {
  const response = await candidateApi.post("/educations", data);
  return response;
};

export const updateEducationQualification = async (data) => {
  const response = await candidateApi.patch(`/educations/${data.id}`, data);
  return response;
};

export const deleteEducationQualification = async (id) => {
  const response = await candidateApi.delete(`/educations/${id}`);
  return response;
};

export const getReferenceDetails = async () => {
  const response = await candidateApi.get("/references");
  return response;
};

export const createReferenceDetails = async (data) => {
  const response = await candidateApi.post("/references", data);
  return response;
};

export const updateReferenceDetails = async (data) => {
  const response = await candidateApi.patch(`/references/${data.uuid}`, data);
  return response;
};

export const deleteReferenceDetails = async (id) => {
  const response = await candidateApi.delete(`/references/${id}`);
  return response;
};

// Education Levels API
export const getEducationLevels = async (page, name) => {
  const response = await api.get("/education_levels", {
    params: { page, name },
  });
  return response;
};

// Phone number APIs
export const getCandidatePhoneNumbers = async () => {
  const response = await candidateApi.get("/phone_details");
  return response;
};

export const addCandidatePhoneNumbers = async (data) => {
  const response = await candidateApi.post("/phone_details", data);
  return response;
};

export const updateCandidatePhoneNumbers = async (data) => {
  const response = await candidateApi.patch(`/phone_details/${data.id}`, data);
  return response;
};

export const removeCandidatePhoneNumbers = async (id) => {
  const response = await candidateApi.delete(`/phone_details/${id}`);
  return response;
};

// Ref Image API
export const uploadReferenceImage = async (data) => {
  const response = await candidateApi.post("/upload_reference_image", data);
  return response;
};
