import axios from "axios";

import { getApiUrl } from "app/apiPaths";

const api = axios.create({
  withCredentials: true,
  baseURL: `${getApiUrl()}`,
});

export const commonApi = axios.create({
  withCredentials: true,
  baseURL: `${getApiUrl()}`,
});

export const candidateApi = axios.create({
  withCredentials: true,
  baseURL: `${getApiUrl()}/candidate`,
});

export const organisationApi = axios.create({
  withCredentials: true,
  baseURL: `${getApiUrl()}/organisation`,
});

export const organisationFileApi = axios.create({
  withCredentials: true,
  baseURL: `${getApiUrl()}/organisation`,
  responseType: "arraybuffer",
});

export const masterDataApi = axios.create({
  withCredentials: true,
  baseURL: `${getApiUrl()}/master_data`,
});

// temporary solution to use for existing exam apis
export const examApi = axios.create({
  withCredentials: true,
  baseURL: `${getApiUrl()}`,
});

export default api;
