import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { BsPencilSquare } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import NoDataFound from "assets/images/DashboardImages/no_data_found.png";
import { Button } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import Pagination from "components/common/Pagination";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import {
  DEFAULT_PAGE_SIZE,
  MANAGE_USER_ROLES,
  MANAGE_USER_STATUS,
  PER_PAGE_RECORD_LIMIT,
  SITE_TITLE,
} from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import formatRoleOption from "helpers/formatRoleOption";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { getUsersAction } from "store/thunkActions/erp/userThunk";

import UserModal from "./UserModal";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useFormProps = useForm();
  const { reset } = useFormProps;

  const { data, meta } = useSelector(({ users }) => users);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState();

  const currentPage = 1;
  const page = parseInt(searchParams.get("page"), 10) || currentPage;
  const pageSize =
    parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE;

  const filterField = [
    {
      label: "User Role",
      placeholder: "user role",
      name: "roles",
      multiSelect: true,
      dropdownOptions: MANAGE_USER_ROLES,
    },
    {
      label: "User Status",
      placeholder: "user status",
      name: "status",
      multiSelect: false,
      dropdownOptions: MANAGE_USER_STATUS,
    },
  ];

  const urlQueryParams = {
    status: searchParams.get("status"),
    roles: searchParams.getAll("roles"),
    search_term: searchParams.get("search_term"),
  };

  const fetchUserData = async () => {
    try {
      dispatch(
        getUsersAction({
          page,
          page_size: pageSize,
          status: searchParams.get("status"),
          roles: searchParams.getAll("roles"),
          searchTerm: searchParams.get("search_term"),
        })
      ).then(() => resetFilterForm());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const newUrlSearchParams = (pageNumber, pageSizeValue = pageSize) => {
    const newSearchParams = {
      page: pageNumber,
      page_size: pageSizeValue,
    };

    if (urlQueryParams.status !== null) {
      newSearchParams.status = urlQueryParams.status;
    }
    if (urlQueryParams.roles.length > 0) {
      newSearchParams.roles = urlQueryParams.roles;
    }
    if (urlQueryParams.search_term !== null) {
      newSearchParams.search_term = urlQueryParams.search_term;
    }

    return newSearchParams;
  };

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: { currentPageName: "Manage Users" },
      })
    );
  }, []);

  useEffect(() => {
    if (parseInt(searchParams.get("page"), 10) > meta?.total_pages) {
      const newSearchParams = newUrlSearchParams(meta?.page_number);
      setSearchParams(newSearchParams);
    }
  }, [meta]);

  useEffect(() => {
    document.title = `Users - ${SITE_TITLE}`;
    if (
      urlQueryParams.status !== null ||
      urlQueryParams.roles.length > 0 ||
      urlQueryParams.search_term !== null
    ) {
      navigate(
        `/users?${constructQueryParams({
          page: 1,
          page_size: 20,
        })}`
      );
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchUserData();
  }, [page, pageSize, searchParams]);

  const onSetCurrentPage = (pageNumber, pageSizeValue = pageSize) => {
    const newSearchParams = newUrlSearchParams(pageNumber, pageSizeValue);
    setSearchParams(newSearchParams);
    window.scrollTo({ top: 0, left: 0 });
  };

  const resetFilterForm = () => {
    reset({
      status: searchParams.get("status")
        ? MANAGE_USER_STATUS.find(
            (status) => status.value === searchParams.get("status")
          )
        : undefined,

      roles: searchParams.has("roles")
        ? MANAGE_USER_ROLES.filter((role) =>
            searchParams.getAll("roles").includes(role.value)
          )
        : undefined,
    });
  };

  const anyFilterApplied = () =>
    searchParams.has("roles") || searchParams.has("status");

  const generateFilterCriterias = () => {
    const filterCriterias = [];

    if (searchParams.has("roles")) {
      filterCriterias.push("User Role");
    }
    if (searchParams.has("status")) {
      filterCriterias.push("User Status");
    }

    return filterCriterias.join(" | ");
  };

  if (isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="bg-gray-lighterShade3 ">
      <Helmet>
        <title>{`Users - ${SITE_TITLE}`}</title>
      </Helmet>
      <div className="flex gap-4 pl-3.5 lg:pl-5 pr-4 sm:pr-2 justify-between items-start py-4 bg-gray-lighterShade3 z-10 sticky top-[44px]">
        <div>
          <div className="sm:max-w-[450px]">
            <SearchBar
              anyFilterApplied={anyFilterApplied}
              searchParams={searchParams}
              resetFilterForm={resetFilterForm}
              useFormProps={useFormProps}
              setSearchParams={setSearchParams}
              filterField={filterField}
              searchBarPlaceholder="Search by name or email"
            />
          </div>
          {anyFilterApplied() && (
            <p className="my-3 text-sm text-primary-main align-top">
              <span className="mr-1"> Filtered by: </span>

              <span>{generateFilterCriterias()}</span>
            </p>
          )}
        </div>

        <Button
          title="Add New User"
          data-testid="add-user-btn"
          btnClassName="items-center rounded-full bg-primary-main p-2 mr-0 sm:mr-2 hover:bg-primary-dark"
          onClick={() => setIsAdding(true)}
        >
          <AiOutlinePlus className="text-2xl text-white" />
        </Button>
      </div>

      {data && data.length > 0 ? (
        <div className="overflow-x-auto mx-0 sm:mx-6">
          <table className="mb-8 mt-2 w-full min-w-[736px] table-auto border-collapse text-sm">
            <thead>
              <tr className="border-b-2 text-lg capitalize text-primary-main">
                <th className="w-1/5 py-4 pl-4 text-left">Name</th>
                <th className="w-2/5 py-4 pl-4 text-left">Email</th>
                <th className="w-1/4 py-4 pl-4 text-left">Role</th>
                <th className="w-[15%] py-4 pr-4 text-left" />
              </tr>
            </thead>

            <tbody className="bg-white">
              {data.map((user) => {
                return (
                  <tr className="border-b-2" key={user.id}>
                    <td className="w-4/12 break-all py-4 pl-4 pr-4 text-left align-top ">
                      {user.name ?? "---"}
                    </td>

                    <td className="break-all py-4 pr-4 pl-4 text-left align-top">
                      {user.email}
                    </td>
                    <td className="whitespace-normal py-4 pr-4 pl-4 text-left align-top">
                      {user.roles
                        ? user.roles
                            .map((role) => {
                              return formatRoleOption(role.role_name);
                            })
                            .join(", ")
                        : ""}
                    </td>

                    <td className="float-right flex py-4 pr-4 items-center gap-2">
                      {user.active ? (
                        <span className="text-primary-main border py-1 px-2 bg-primary-lightest rounded-md">
                          Active
                        </span>
                      ) : (
                        <span className="text-danger-main border py-1 px-2  bg-danger-lightest rounded-md">
                          Inactive
                        </span>
                      )}

                      <Button
                        btnClassName="p-1 text-base hover:text-primary-main hover:cursor-pointer"
                        title="Edit User"
                        onClick={() => {
                          setSelectedUser(user);
                          setIsEditing(true);
                        }}
                      >
                        <BsPencilSquare />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {meta?.total_entries > PER_PAGE_RECORD_LIMIT && (
            <Pagination
              currentPage={meta?.page_number}
              totalPage={meta?.total_pages}
              setCurrentPage={onSetCurrentPage}
              totalEntries={meta?.total_entries}
              pageSize={
                parseInt(searchParams.get("page_size"), 10) || DEFAULT_PAGE_SIZE
              }
            />
          )}
        </div>
      ) : (
        <div className="pt-16 text-center">
          <img src={NoDataFound} alt="No Data Found" className="m-auto" />
        </div>
      )}

      {isAdding && (
        <UserModal
          currentPage={page}
          setIsEditState={setIsAdding}
          pageSize={pageSize}
          totalEntries={meta.total_entries}
          setSearchParams={setSearchParams}
          newUrlSearchParams={newUrlSearchParams}
        />
      )}

      {isEditing && (
        <UserModal
          selectedUser={selectedUser}
          currentPage={page}
          pageSize={pageSize}
          setIsEditState={setIsEditing}
          setSelectedUser={setSelectedUser}
        />
      )}
    </div>
  );
};

export default Users;
