import { RiBriefcase4Fill, RiClipboardFill, RiDraftFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";
import PropTypes from "prop-types";

import { ICON_SIZE_HASH } from "app/constants";
import { constructQueryParams } from "helpers/constructQueryParams";
import useCheckUserRole from "hooks/useCheckUserRole";

const ORGANISATION_LINKS = {
  Jobs: {
    icon: <RiBriefcase4Fill size={ICON_SIZE_HASH.lg} />,
    link: `/jobs`,
  },
  "Question Manager": {
    icon: <RiDraftFill size={ICON_SIZE_HASH.lg} />,
    link: `/questions_manager?${constructQueryParams({
      tab: "question_papers",
      page: 1,
      page_size: 20,
    })}`,
  },
  "My Interviews": {
    icon: <RiClipboardFill size={ICON_SIZE_HASH.lg} />,
    link: `/interviews?${constructQueryParams({
      status: "ongoing",
    })}`,
  },
};

const Stats = ({ header, count }) => {
  return (
    <div className="flex flex-col pr-5 text-md">
      <span className="text-gray-darker">{startCase(header)}</span>
      <span className="text-gray-main mb-3">{count}</span>
    </div>
  );
};

const StatsWithMoreData = ({ header, count }) => {
  return (
    <div className="flex flex-row text-gray-main">
      <span className="text-sm uppercase">{header}</span>&nbsp; : &nbsp;
      <span className="text-md">{count}</span>
    </div>
  );
};

const DashboardCard = ({
  title,
  data,
  testId,
  moreData,
  headerNames,
  allowedRoles,
  showStats,
}) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(ORGANISATION_LINKS[title].link);
  const checkuserRole = useCheckUserRole(allowedRoles);

  return (
    checkuserRole && (
      <div
        role="button"
        data-testid={testId}
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
        className="mt-1 sm:mt-4 w-full rounded-lg border  bg-white p-4  shadow-md md:max-w-[49%]"
      >
        <div className="flex flex-row border-b-2 py-4 text-primary-main">
          <div className="pr-6">{ORGANISATION_LINKS[title].icon}</div>
          <h3 className="mb-2 text-2xl font-semibold">{title}</h3>
        </div>

        {showStats &&
          (!moreData ? (
            <div className="flex flex-col flex-wrap justify-start pt-2 md:flex-row">
              {Object.entries(data.stats).map((stat) => {
                return <Stats key={stat[0]} header={stat[0]} count={stat[1]} />;
              })}
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row sm:space-x-8">
              <div className="flex flex-col flex-wrap pt-2 mb-3">
                <div className="text-md mb-1 text-gray-darker">
                  {headerNames[0].label}
                </div>

                {Object.entries(data.stats).map((stat) => {
                  return (
                    <StatsWithMoreData
                      key={stat[0]}
                      header={stat[0]}
                      count={stat[1]}
                    />
                  );
                })}
              </div>
              <div className="flex flex-col flex-wrap pt-2">
                <div className="mb-1 text-md text-gray-darker">
                  {headerNames[1].label}
                </div>
                {Object.entries(moreData?.stats).map((stat) => {
                  return (
                    <StatsWithMoreData
                      key={stat[0]}
                      header={stat[0]}
                      count={stat[1]}
                    />
                  );
                })}
              </div>
            </div>
          ))}
      </div>
    )
  );
};

DashboardCard.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
  data: PropTypes.shape({
    total_count: PropTypes.number,
    stats: PropTypes.objectOf(PropTypes.number),
  }),
  moreData: PropTypes.shape({
    total_count: PropTypes.number,
    stats: PropTypes.objectOf(PropTypes.number),
  }),
  headerNames: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  showStats: PropTypes.bool,
};

StatsWithMoreData.propTypes = {
  header: PropTypes.string,
  count: PropTypes.number,
};

Stats.propTypes = { header: PropTypes.string, count: PropTypes.number };

export default DashboardCard;
