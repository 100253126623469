import React, { useEffect, useState } from "react";
import { format, startOfDay } from "date-fns";
import PropTypes from "prop-types";

const ExamCandidateMetaData = ({
  candidateJobId,
  candidateName,
  sectionName,
  duration,
}) => {
  const [time, setTime] = useState(duration);

  const CalculateTime = () => {
    return (
      !Number.isNaN(Number(time)) &&
      format(
        new Date(startOfDay(new Date()).getTime() + time * 1000),
        "HH:mm:ss"
      )
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => time > 0 && time - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [duration]);

  return (
    <div className="text-roboto text-black-primary sticky self-start break-words px-4 pt-2 text-xl ">
      <div className="mb-2 flex">
        <p className="mr-4 font-semibold">ID:</p>
        <p className="break-all text-lg">{candidateJobId}</p>
      </div>
      <div className="mb-2 flex lg:flex">
        <p className="mr-4 font-semibold">Name:</p>
        <p className="break-all text-lg">{candidateName}</p>
      </div>
      {sectionName && (
        <div className="mb-2 flex lg:flex">
          <p className="mr-4 font-semibold">Section:</p>
          <p className="break-all text-lg">{sectionName}</p>
        </div>
      )}
      <div className="text-danger-main flex lg:flex">
        <p className="mr-4 font-semibold">Exam Time Left:</p>
        <p className="break-all text-lg">
          <CalculateTime />
        </p>
      </div>
    </div>
  );
};

ExamCandidateMetaData.defaultProps = {
  candidateJobId: 0,
  candidateName: "Exam Candidate",
  sectionName: "Sample Section",
  duration: 0,
};

ExamCandidateMetaData.propTypes = {
  candidateJobId: PropTypes.number,
  candidateName: PropTypes.string,
  sectionName: PropTypes.string,
  duration: PropTypes.number,
};

export default ExamCandidateMetaData;
