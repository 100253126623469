import { lowerCase, startCase } from "lodash";

const formatRoleOption = (name) => {
  if (name !== "INTERVIEWER_MODERATOR") {
    return startCase(lowerCase(name));
  }
  return "Interviewer/Moderator";
};

export default formatRoleOption;
