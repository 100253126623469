import { createSlice } from "@reduxjs/toolkit";

import {
  createCategoryAction,
  deleteCategoryAction,
  getCategoryAction,
  getFilterCategoryAction,
  updateCategoryAction,
} from "store/thunkActions/erp/categoriesThunk";

const categories = createSlice({
  name: "Categories",
  initialState: {
    data: [],
    filters: null,
  },
  reducers: {},
  extraReducers: {
    [getCategoryAction.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
    },
    [getFilterCategoryAction.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
    },
    [createCategoryAction.fulfilled]: (state, action) => {
      state.data.push(action.payload.data);
    },
    [updateCategoryAction.fulfilled]: (state, action) => {
      const index = (state.data ?? []).findIndex(
        (tag) => tag.id == action.payload.data.id
      );
      if (index !== -1) {
        state.data[index] = action.payload.data;
      }
    },
    [deleteCategoryAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (tag) => tag.id == action.payload.data.id
      );
      state.data.splice(index, 1);
    },
  },
});

export default categories.reducer;
