import moment from "moment";
import PropTypes from "prop-types";

const EducationQualification = ({ educationQualification }) => {
  return (
    <>
      <h4 className="pt-2 pr-2 text-lg font-medium capitalize leading-5">
        {educationQualification?.college?.name.toLowerCase()}
      </h4>

      <p className="mt-1 flex flex-col text-sm">
        <span>{educationQualification.education_level_name}</span>
        <span>
          {educationQualification.qualification_name}
          {educationQualification.marks &&
            ` | ${educationQualification.marks} %`}
        </span>
      </p>
      <p className="text-sm">
        <span>{educationQualification.branch_name}</span>
      </p>
      <p className="text-sm italic text-gray-main">
        {`${moment(educationQualification.start_date).format("MMMM YYYY")} - ${
          educationQualification.is_pursuing
            ? "Pursuing"
            : moment(educationQualification.end_date).format("MMMM YYYY")
        }`}
      </p>
    </>
  );
};

EducationQualification.propTypes = {
  educationQualification: PropTypes.shape({
    id: PropTypes.number,
    college: PropTypes.object,
    qualification_id: PropTypes.number,
    qualification_name: PropTypes.string,
    branch_id: PropTypes.number,
    branch_name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    is_pursuing: PropTypes.bool,
    education_level_name: PropTypes.string,
    marks: PropTypes.string,
  }),
};

export default EducationQualification;
