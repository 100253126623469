import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { bool } from "prop-types";

import ProviderButtons from "components/common/ProviderButtons";
import { SITE_TITLE } from "constants/common";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";

import SigninForm from "./Form";

const Signin = ({ isSignupPage, isRedirect = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRedirect && location.pathname !== "/login") {
      navigate(
        `/login?redirect_url=${encodeURIComponent(
          location.pathname + location.search
        )}`
      );
    }
  }, [isRedirect]);

  useEffect(() => {
    document.title = `${isSignupPage ? "Sign Up" : "Login"} - ${SITE_TITLE}`;
    hideFreshWorkWidget();
  });

  return (
    <div className="container mx-auto">
      <Helmet>
        <title>
          {isSignupPage ? "Sign Up" : "Login"} - {SITE_TITLE}
        </title>
      </Helmet>
      <h1 className="pt-6 text-center text-3xl text-primary-main">
        {isSignupPage ? "Sign Up" : "Login"}
      </h1>
      <div className="mx-auto flex max-w-md flex-col justify-between lg:my-6 lg:max-w-3xl lg:flex-row-reverse">
        <ProviderButtons isSignup={isSignupPage} />

        <div className="flex w-full border-t-2 border-dashed border-gray-400 p-2 lg:w-1/2 lg:border-t-0 lg:border-r-2 lg:pr-10">
          <SigninForm isSignup={isSignupPage} />
        </div>
      </div>
    </div>
  );
};

Signin.propTypes = {
  isSignupPage: bool,
  isRedirect: bool,
};

export default Signin;
