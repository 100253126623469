import React from "react";
import {
  RiCalendar2Line,
  RiCheckboxCircleLine,
  RiCloseFill,
  RiCreativeCommonsNdLine,
} from "react-icons/ri";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import PropTypes from "prop-types";

import { timelineStatusObj } from "constants/jobs";

import "react-vertical-timeline-component/style.min.css";

const StatusTimeline = ({ record }) => {
  const getIcon = (status) => {
    let icon;
    if (status?.endsWith(timelineStatusObj.rejected)) {
      icon = <RiCloseFill />;
    } else if (
      status === timelineStatusObj.loi_accepted ||
      status?.endsWith(timelineStatusObj.approved)
    ) {
      icon = <RiCheckboxCircleLine />;
    } else if (
      status?.endsWith(timelineStatusObj.cleared) ||
      status?.endsWith(timelineStatusObj.placed)
    ) {
      icon = <RiCheckboxCircleLine />;
    } else if (status?.endsWith(timelineStatusObj.scheduled)) {
      icon = <RiCalendar2Line />;
    } else {
      icon = <RiCreativeCommonsNdLine />;
    }

    return icon;
  };

  const getColor = (status) => {
    let color;
    if (status.endsWith(timelineStatusObj.rejected)) {
      color = "#D92031";
    } else if (
      status === timelineStatusObj.loi_accepted ||
      status?.endsWith(timelineStatusObj.placed)
    ) {
      color = "#2EAD42";
    } else {
      color = "#143A6A";
    }
    return color;
  };

  return (
    <div>
      {record.length !== 0 ? (
        <VerticalTimeline
          layout="1-column-left"
          lineColor="blue"
          animate={false}
          className="p-0"
        >
          {record.map((status) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work my-[25px]"
              key={status}
              contentStyle={{
                border: `2px solid ${getColor(status)}`,
              }}
              contentArrowStyle={{
                borderRight: `15px solid ${getColor(status)}`,
              }}
              iconStyle={{
                color: "#fff",
                backgroundColor: getColor(status),
              }}
              icon={getIcon(status)}
            >
              <p className="inline">
                {status ? status.replace("ON_HOLD", "ON HOLD") : ""}
              </p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      ) : (
        <span className="block text-base italic text-gray-main">
          No History Found
        </span>
      )}
    </div>
  );
};

StatusTimeline.propTypes = {
  record: PropTypes.arrayOf(PropTypes.string),
};

StatusTimeline.defaultProps = {
  record: [],
};
export default StatusTimeline;
