import PropTypes from "prop-types";

import { Modal } from "components/molecules";

const AllowedColleges = ({
  setShowRestrictedCollege,
  allowedColleges,
  allowedSkills,
  allowedQualifications,
  restrictedByColleges,
  restrictedByQualifications,
  restrictedBySkills,
}) => {
  return (
    <Modal
      title="Restrictions"
      handleCancel={() => setShowRestrictedCollege(false)}
      scrollClass="overflow-y-auto apply-modal-height"
    >
      {allowedColleges?.length > 0 && restrictedByColleges && (
        <div className="my-2">
          <h2 className="font-bold">Allowed colleges</h2>
          <ul
            start={1}
            className="mt-2 list-outside list-decimal space-y-2 break-words px-4 text-sm"
          >
            {allowedColleges.map((college) => {
              return <li key={college.id}>{college.name}</li>;
            })}
          </ul>
        </div>
      )}
      {allowedQualifications?.length > 0 && restrictedByQualifications && (
        <div className="my-4">
          <h2 className="font-bold">Allowed Qualifications</h2>
          <ul
            start={1}
            className="mt-2 list-outside list-decimal space-y-2 break-words px-4 text-sm"
          >
            {allowedQualifications.map((qualifications) => {
              return <li key={qualifications.id}>{qualifications.name}</li>;
            })}
          </ul>
        </div>
      )}
      {allowedSkills?.length > 0 && restrictedBySkills && (
        <div className="my-4">
          <h2 className="font-bold">Allowed Skills</h2>
          <ul
            start={1}
            className="mt-2 list-outside list-decimal space-y-2 break-words px-4 text-sm"
          >
            {allowedSkills.map((skills) => {
              return <li key={skills.id}>{skills.name}</li>;
            })}
          </ul>
        </div>
      )}
    </Modal>
  );
};

AllowedColleges.defaultProps = {
  restrictedByColleges: false,
  restrictedByQualifications: false,
  restrictedBySkills: false,
};

AllowedColleges.propTypes = {
  setShowRestrictedCollege: PropTypes.func,
  allowedColleges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  allowedSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  allowedQualifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  restrictedByColleges: PropTypes.bool,
  restrictedByQualifications: PropTypes.bool,
  restrictedBySkills: PropTypes.bool,
};

export default AllowedColleges;
