import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";

import { CommonSortArrowIcon } from "components/common/Arrow/SortArrow";
import { showTooltipSort } from "helpers/pipeline/sort/showToolTip";
import { toCamelCase } from "helpers/utils";

const StageColumnHeaders = ({
  filteredStageHeaders,
  sortBy,
  sortOrder,
  onClickSort,
}) => {
  return (
    <>
      {filteredStageHeaders.map(
        ({ label, sortingAllowed, sortByText, title, headerClassName }) => {
          if (sortingAllowed) {
            return (
              <th
                className={classNames("group text-left", headerClassName)}
                key={title || label}
              >
                <button
                  data-tooltip-id={`${toCamelCase(title || label)}Sort`}
                  data-tooltip-content={showTooltipSort(
                    sortByText,
                    sortBy,
                    sortOrder
                  )}
                  onClick={() => onClickSort(sortByText)}
                >
                  <span className="mr-2 text-base font-semibold text-primary-dark">
                    {label}
                  </span>
                  <span className="inline-block">
                    <CommonSortArrowIcon
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      sortByText={sortByText}
                    />
                  </span>
                  <Tooltip
                    id={`${toCamelCase(title || label)}Sort`}
                    className="z-10"
                    variant="dark"
                  />
                </button>
              </th>
            );
          }
          return (
            <th
              key={label}
              className={classNames("text-left", headerClassName)}
            >
              <span className="text-base font-semibold text-primary-dark">
                {label}
              </span>
            </th>
          );
        }
      )}
    </>
  );
};

StageColumnHeaders.propTypes = {
  filteredStageHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      title: PropTypes.string,
      sortByText: PropTypes.string,
      sortingAllowed: PropTypes.bool,
      headerClassName: PropTypes.string,
    })
  ),
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onClickSort: PropTypes.func.isRequired,
};

StageColumnHeaders.defaultProps = {
  filteredStageHeaders: [],
};

export default StageColumnHeaders;
