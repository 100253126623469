import { BsExclamationCircleFill } from "react-icons/bs";
import PropTypes from "prop-types";

export const InfoBox = ({ isCameraOn }) => {
  return (
    <div className="mb-2 rounded-full border border-warning-light p-2 text-warning-main opacity-80">
      <div className="flex items-center justify-start gap-3 px-2 md:ml-12">
        <div>
          <BsExclamationCircleFill size={30} className="h-8 w-8" />
        </div>
        <div className="text-base">
          {isCameraOn && (
            <p>Please allow camera permission to be able to start the exam</p>
          )}
        </div>
      </div>
    </div>
  );
};
InfoBox.propTypes = {
  isCameraOn: PropTypes.bool,
};
