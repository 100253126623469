import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { lowerCase } from "lodash";
import PropTypes from "prop-types";

import { Button } from "components/atoms";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";
import { updateCurrentUserAction } from "store/thunkActions/currentUserThunk";

const Form = ({ interests, setIsEditState }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm();
  const dispatch = useDispatch();
  const showAlert = useAlert();

  useEffect(() => {
    reset({ interests });
  }, [interests]);

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (key === "interests") {
        setError(key, { message: value[0] });
      } else {
        showAlert("danger", `Error on ${lowerCase(key)} : ${value[0]}`);
      }
    });
  };

  const submitHandler = ({ interests }) =>
    dispatch(updateCurrentUserAction({ interests: interests.trim() }))
      .unwrap()
      .then(() => {
        setIsEditState(false);
        clearErrors();
      })
      .catch(({ errors }) => {
        if (errors) {
          setErrorsOnFields(errors);
        } else {
          showAlert("danger", "Something went wrong!");
        }
      });

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <input
        id="interests"
        className={`${errors.interests ? "input-error" : "input"} w-full`}
        type="text"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        placeholder="Enter comma-seperated hobbies & interest"
        {...register("interests", {
          required: requiredValidation(),
          maxLength: {
            value: 255,
            message: characterValidation({ limit: 255 }),
          },
        })}
      />

      {errors.interests && (
        <p className="text-sm text-danger-dark">{errors.interests.message}</p>
      )}

      <div className="mt-6 mb-2 flex w-full">
        <Button
          title={interests ? "Update" : "Save"}
          testId="saveInterestsBtn"
          type="primary"
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          {interests ? "Update" : "Save"}
        </Button>

        <Button
          title="Cancel"
          testId="cancelInterestsBtn"
          type="secondary"
          size="sm"
          btnClassName="ml-2"
          onClick={() => setIsEditState(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  interests: PropTypes.string,
  setIsEditState: PropTypes.func,
};

export default Form;
