export const CTC_REGEX = /^[a-zA-Z0-9.\s-]*$/;

export const JOB_STATUS = {
  active: { text: "active", type: "success" },
  draft: { text: "draft", type: "info" },
  jobClosed: { text: "jobClosed", type: "danger" }, // previously -> canceled job
  applicationClosed: { text: "applicationClosed", type: "warning" }, // previously -> closed job
};

export const JOB_FILTER_STATUSES = [
  { label: "Draft", value: "draft" },
  { label: "Application Closed", value: "application_closed" },
  { label: "Active", value: "active" },
  { label: "Job Closed", value: "job_closed" },
];

export const JOB_FILTER_VISIBILITY = [
  { label: "Public", value: "PUBLIC" },
  { label: "Restricted", value: "RESTRICTED" },
];

export const JOB_FILTER_HIDDEN = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const JOBS_STATUSES = [
  "draft",
  "active",
  "application_closed",
  "job_closed",
];

export const JOB_TYPES = [
  { label: "Suggested", key: "suggested" },
  { label: "Applied", key: "applied" },
  { label: "Saved", key: "saved" },
];

export const JOB_VISIBILITY = {
  public: "PUBLIC",
  restricted: "RESTRICTED",
};

export const jobHeaderMessage = ({ clone, job }) => {
  if (clone) {
    return "Please review the details below before cloning the job. ";
  }
  if (job) {
    return "Please fill out the details to update the job. ";
  }

  return "Please fill out the details to create a job. ";
};

export const timelineStatusObj = {
  loi_accepted: "LOI ACCEPTED",
  rejected: "REJECTED",
  approved: "APPROVED",
  cleared: "CLEARED",
  placed: "PLACED",
  scheduled: "SCHEDULED",
};

export const JOB_PROFILE_STATS = [
  { label: "Employment Type", value: "employment_type_name", key: 1 },
  { label: "Vacancies", value: "no_of_positions", key: 2 },
  { label: "CTC", value: "ctc", key: 3 },
  { label: "Location Type", value: "location_type_name", key: 4 },
  { label: "Location", value: "location", key: 5 },
];

export const JOB_PREFERENCES = [
  {
    label: "Preferred Colleges",
    value: "colleges",
    key: 1,
    restrictedBy: "restricted_by_colleges",
  },
  {
    label: "Preferred Qualifications",
    value: "qualifications",
    key: 2,
    restrictedBy: "restricted_by_qualifications",
  },
  {
    label: "Preferred Skills",
    value: "skills",
    key: 3,
    restrictedBy: "restricted_by_skills",
  },
];

export const JOB_SETTINGS = [
  {
    label: "Restricted By",
    key: 1,
    isMultiRecords: true,
    customClassName: "pb-[0px]",
  },
  {
    label: "Only Accessible By Link",
    value: "hidden",
    key: 2,
    isBool: true,
    customClassName: "py-[10px]",
  },
  {
    label: "Add Applicants To First Stage",
    value: "auto_add_to_first_stage",
    key: 3,
    isBool: true,
  },
  {
    label: "Allow External Access",
    value: "allow_external_access",
    key: 4,
    isBool: true,
    customClassName: "pb-[0px]",
  },
];
