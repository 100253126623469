import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { isAfter, toDate } from "date-fns";
import PropTypes from "prop-types";

import {
  getColleges,
  getEmploymentTypes,
  getLocationTypes,
  getQualifications,
  getSkills,
} from "api/candidate/profileApi";
import { generateJobDescription } from "api/erp/jobApi";
import {
  ALERT_TYPE,
  ICON_SIZE_HASH,
  JOB_RESTRICTED_BY,
  WEBSITE_REGEX,
} from "app/constants";
import { Button, LabeledInput } from "components/atoms";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import { Modal } from "components/molecules";
import { SITE_TITLE } from "constants/common";
import {
  CTC_REGEX,
  JOB_STATUS,
  JOB_VISIBILITY,
  jobHeaderMessage,
} from "constants/jobs";
import { handleOptionLabel } from "helpers/formatDropDownOption";
import {
  characterValidation,
  dateTimeValidation,
  requiredValidation,
  reviewAutoCloseDate,
  selectFutureDateTime,
  validFormatValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";
import { resetState } from "store/slices/erp/jobsSlice";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import {
  createJobAction,
  updateJobAction,
} from "store/thunkActions/erp/jobThunk";

const Form = ({ job }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const params = useParams();
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isDraft, setIsDraft] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [college, setCollege] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isJobGenerated, setIsJobGenerated] = useState(false);

  const clone = new URLSearchParams(location.search).has("clone");
  const activateJob = new URLSearchParams(location.search).has("activate_job");
  const [showSaveDraftbutton, setShowSaveDraftbutton] = useState(true);
  const [showPostbutton, setShowPostbutton] = useState(false);

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const checkForValue = (value) => value && value.length > 0;

  const restrictedBy = (data) => {
    const restrictedByObj = {};

    JOB_RESTRICTED_BY.forEach((item) => {
      restrictedByObj[item.value] = false;
    });

    if (data.restricted_by?.length) {
      data.restricted_by.forEach((item) => {
        restrictedByObj[item.value] = true;
      });
    }
    restrictedByObj.uuid = data.restrictedBy?.uuid;
    return restrictedByObj;
  };

  // for posting job
  const postSubmitHandler = (data) => {
    const restrictedByObj = restrictedBy(data);

    const jobData = {
      ...data,
      id: clone ? null : data.uuid ?? null,
      status: JOB_STATUS[isDraft ? "draft" : "active"].text,
      college_ids: data.colleges?.map((c) => c.id) || [],
      qualification_ids: data.qualifications?.map((c) => c.id) || [],
      skill_ids: data.skills?.map((c) => parseInt(c.id, 10)) || [],
      description: data.description?.trim(),
      requirements: data.requirements?.trim(),
      employment_type_uuid: data.employment_type_name?.uuid ?? null,
      location_type_uuid: data.location_type_name?.uuid ?? null,
      restricted_by_colleges:
        data.visibility === JOB_VISIBILITY.restricted
          ? restrictedByObj.colleges
          : null,
      restricted_by_qualifications:
        data.visibility === JOB_VISIBILITY.restricted
          ? restrictedByObj.qualifications
          : null,
      restricted_by_skills:
        data.visibility === JOB_VISIBILITY.restricted
          ? restrictedByObj.skills
          : null,
      redirect_url: data.allow_external_access
        ? data.redirect_url ?? null
        : null,
    };

    delete jobData.colleges;
    delete jobData.qualifications;
    delete jobData.skills;
    delete jobData.employment_type;
    delete jobData.location_type;
    delete jobData.restricted_by;

    const action = jobData.id
      ? updateJobAction(jobData)
      : createJobAction(jobData);

    return dispatch(action)
      .unwrap()
      .then(({ data, meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        dispatch(resetState());
        setIsDraft(false);
        if (job) {
          navigate(`/jobs/${job.uuid}`);
        } else {
          navigate(`/jobs/${data.uuid}/pipeline`);
        }
      })
      .catch(({ errors }) => {
        setErrorsOnFields(errors);
      });
  };

  // save draft
  const submitHandler = (data) => {
    const restrictedByObj = restrictedBy(data);

    const requiredFields = ["colleges", "qualifications", "skills"];

    if (
      data.visibility === JOB_VISIBILITY.restricted &&
      !(
        restrictedByObj.colleges ||
        restrictedByObj.qualifications ||
        restrictedByObj.skills
      )
    ) {
      setError("restricted_by");
      setError(
        "visibility",
        "You need to select a value for at least one of the three fields"
      );
    } else if (
      data.visibility === JOB_VISIBILITY.restricted &&
      requiredFields.some(
        (field) => !checkForValue(data[field]) && restrictedByObj[field]
      )
    ) {
      requiredFields.forEach((field) => {
        if (!checkForValue(data[field]) && restrictedByObj[field]) {
          setError(field, {
            message: `You need to select atleast one option from the ${field}`,
          });
        }
      });
    } else {
      const jobData = {
        ...data,
        id: clone ? null : data.uuid ?? null,
        status: JOB_STATUS[isDraft ? "draft" : "active"].text,
        college_ids: data.colleges?.map((c) => c.id) || [],
        qualification_ids: data.qualifications?.map((c) => c.id) || [],
        skill_ids: data.skills?.map((c) => parseInt(c.id, 10)) || [],
        description: data.description?.trim(),
        requirements: data.requirements?.trim(),
        employment_type_uuid: data.employment_type_name?.uuid ?? null,
        location_type_uuid: data.location_type_name?.uuid ?? null,
        restricted_by_colleges:
          data.visibility === JOB_VISIBILITY.restricted
            ? restrictedByObj.colleges
            : null,
        restricted_by_qualifications:
          data.visibility === JOB_VISIBILITY.restricted
            ? restrictedByObj.qualifications
            : null,
        restricted_by_skills:
          data.visibility === JOB_VISIBILITY.restricted
            ? restrictedByObj.skills
            : null,
        redirect_url: data.allow_external_access
          ? data.redirect_url ?? null
          : null,
      };

      delete jobData.colleges;
      delete jobData.qualifications;
      delete jobData.skills;
      delete jobData.employment_type;
      delete jobData.location_type;
      delete jobData.restricted_by;

      if (jobData.status === JOB_STATUS.draft.text) {
        const action = jobData.id
          ? updateJobAction(jobData)
          : createJobAction(jobData);

        return dispatch(action)
          .unwrap()
          .then(({ meta }) => {
            showAlert(ALERT_TYPE[meta.message_type], meta.message);
            dispatch(resetState());
            setIsDraft(false);
            if (clone) {
              navigate("/jobs");
            } else if (job) {
              navigate(`/jobs/${params.uuid}`);
            } else {
              navigate("/jobs");
            }
          })
          .catch(({ errors }) => {
            setErrorsOnFields(errors);
            setIsDraft(false);
          });
      }
      setIsActive(true);
    }
  };

  const loadCollegeOptions = async (search, _, { page }) => {
    const { data, meta } = await getColleges(page, search);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadQualificationOptions = async (search, _, { page }) => {
    const { data, meta } = await getQualifications(page, search);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadSkillsOptions = async (search, _, { page }) => {
    const { data, meta } = await getSkills(page, search);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const employmentTypes = async () => {
    const { data } = await getEmploymentTypes();
    return {
      options: data,
    };
  };

  const locationTypes = async () => {
    const { data } = await getLocationTypes();
    return {
      options: data,
    };
  };

  const inputErrClass = (error) => {
    return error ? "input-error" : "input";
  };

  const filterPassedTime = (time, fromDate) => {
    const currentDate = new Date(fromDate);
    const selectedDate = new Date(time);

    return currentDate.getTime() <= selectedDate.getTime();
  };

  const applicationCloseDateChecker = (value) => {
    if (value === null) {
      return true;
    }

    const currentDate = new Date();
    const selectedDate = new Date(value);

    if (selectedDate <= currentDate) {
      return dateTimeValidation();
    }
    return true;
  };

  const showActivateJobDateWarning = () => {
    if (!watch("application_close_at")) {
      return;
    }

    if (isAfter(new Date(), new Date(watch("application_close_at")))) {
      setError("application_close_at", {
        message: selectFutureDateTime(),
      });
    } else {
      setError("application_close_at", {
        message: reviewAutoCloseDate(),
        warning: true,
      });
    }
  };

  const handleJobDescriptionGeneration = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const jobPromptData = {
      title: watch("title"),
      skills:
        watch("skills")
          ?.slice(0, 3)
          .map((c) => c.name)
          .join(",") || null,
      qualifications:
        watch("qualifications")
          ?.slice(0, 3)
          .map((c) => c.name)
          .join(",") || null,
      emp_type: watch("employment_type_name")?.name ?? null,
      location_type: watch("location_type_name")?.name ?? null,
      location: watch("location") || null,
      vacancies: watch("no_of_positions") || null,
      ctc: watch("ctc") || null,
    };
    return generateJobDescription(jobPromptData)
      .then(({ data }) => {
        setIsJobGenerated(true);
        setValue("description", data.description);
      })
      .catch(({ response }) => {
        showAlert(
          ALERT_TYPE[response.data.meta.message_type],
          response.data.meta.message
        );
      })
      .finally(() => setIsLoading(false));
  };

  const getHeaderTitle = () => {
    if (job) {
      return clone ? "Clone Job" : "Edit Job";
    }
    return "Create Job";
  };

  useEffect(() => {
    if (job) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              { name: "Jobs", link: "/jobs" },
              { name: job?.title, link: `/jobs/${job?.uuid}` },
            ],
            currentPageName: clone ? "Clone Job" : "Edit Job",
          },
        })
      );
    } else {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [{ name: "Jobs", link: "/jobs" }],
            currentPageName: "Create Job",
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (job) {
      if (job.visibility === JOB_VISIBILITY.restricted) {
        setCollege(true);
      }
      if (job.status === JOB_STATUS.draft.text) {
        setShowSaveDraftbutton(true);
      } else if (clone == true) {
        setShowSaveDraftbutton(true);
      } else {
        setShowSaveDraftbutton(false);
      }
      if (job.status === JOB_STATUS.active.text) {
        setShowPostbutton(true);
      }

      const restrictedByArr = [];
      if (job?.restricted_by_colleges) {
        restrictedByArr.push(JOB_RESTRICTED_BY[0]);
      }
      if (job?.restricted_by_qualifications) {
        restrictedByArr.push(JOB_RESTRICTED_BY[1]);
      }
      if (job?.restricted_by_skills) {
        restrictedByArr.push(JOB_RESTRICTED_BY[2]);
      }

      reset({
        ...job,
        restricted_by: restrictedByArr,
        employment_type_name: {
          name: job?.employment_type_name,
          uuid: job?.employment_type_uuid,
        },

        location_type_name: {
          name: job?.location_type_name,
          uuid: job?.location_type_uuid,
        },
      });
    }
  }, [reset]);

  useEffect(() => {
    setTimeout(() => {
      if (job && activateJob) {
        showActivateJobDateWarning();
      }
    }, 500);
  }, []);

  return (
    <form
      data-testid="add-job-form"
      className="mx-auto h-fit w-full bg-white p-4 xl:container md:px-6"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <Helmet>
        <title>{`${getHeaderTitle()} - ${SITE_TITLE}`}</title>
      </Helmet>
      <h5 className="text-lg">
        {clone ? "Clone" : `${job ? "Update" : "Create a"}`} Job
      </h5>
      <p className="text-sm font-medium text-gray-dark">
        {jobHeaderMessage({ clone, job })}
        {job?.status !== "active" &&
          "You can directly post it or save it as a draft to post later."}
      </p>

      <div className="w-full flex-col sm:w-5/12">
        <LabeledInput
          id="title"
          labelClassNames={classNames("block mt-2 mb-1 font-medium required", {
            "text-danger-main": errors.title,
          })}
          label="Position"
          name="title"
          inputClassNames={classNames("w-full", inputErrClass(errors.title))}
          placeholder="Enter position"
          register={register}
          type="text"
          rows={5}
          validation={{
            required: requiredValidation(),
            maxLength: {
              value: 50,
              message: characterValidation({ limit: 50 }),
            },
          }}
          errorMessage={errors.title?.message}
          // onBlur={() => setCurrentJobPosition(watch("title"))}
        />
      </div>

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label htmlFor="select-skills" className="my-2 block font-medium">
            Preferred Skills
          </label>

          <Controller
            control={control}
            name="skills"
            render={({ field }) => (
              <AsyncPaginate
                isSearchable
                isClearable
                isMulti
                closeMenuOnSelect={false}
                id="skills_select"
                menuPlacement="auto"
                className="w-full"
                styles={reactSelectStyle(errors.skills)}
                value={field.value}
                loadOptions={loadSkillsOptions}
                onChange={(value) => field.onChange(value)}
                noOptionsMessage={() => "No options"}
                debounceTimeout={1000}
                placeholder="Enter skills"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                additional={{ page: 1 }}
                formatOptionLabel={(option, { context }) =>
                  handleOptionLabel(option, context)
                }
              />
            )}
          />

          {errors.skills && (
            <p className="text-sm text-danger-dark">{errors.skills.message}</p>
          )}
        </div>
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="select-qualifications"
            className={`my-2 block font-medium `}
          >
            Preferred Qualifications
          </label>

          <Controller
            control={control}
            name="qualifications"
            render={({ field }) => (
              <AsyncPaginate
                isSearchable
                isClearable
                isMulti
                closeMenuOnSelect={false}
                id="qualifications_select"
                menuPlacement="auto"
                className="w-full"
                styles={reactSelectStyle(errors.qualifications)}
                value={field.value}
                loadOptions={loadQualificationOptions}
                onChange={(value) => field.onChange(value)}
                noOptionsMessage={() => "No options"}
                debounceTimeout={1000}
                placeholder="Enter qualifications"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                additional={{ page: 1 }}
                formatOptionLabel={(option, { context }) =>
                  handleOptionLabel(option, context)
                }
              />
            )}
          />

          {errors.qualifications && (
            <p className="text-sm text-danger-dark">
              {errors.qualifications.message}
            </p>
          )}
        </div>
      </div>

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="location_type_name"
            className="mt-4 mb-1 block font-medium"
          >
            Location Type
          </label>

          <Controller
            name="location_type_name"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <AsyncPaginate
                {...field}
                loadOptions={locationTypes}
                closeMenuOnSelect
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.uuid}
                id="location_type_name"
                menuPlacement="auto"
                maxMenuHeight={140}
                placeholder="Select location type"
                styles={reactSelectStyle}
                isSearchable={false}
                isClearable
                className="mb-3"
                onChange={(value) => field.onChange(value)}
                value={field.value?.name ? field.value : null}
                debounceTimeout={1000}
              />
            )}
          />
        </div>
        <div className="w-full flex-col sm:w-5/12">
          <LabeledInput
            id="location"
            labelClassNames={classNames("block font-medium mt-4 mb-1", {
              "text-danger-main": errors.location,
            })}
            label="Location"
            name="location"
            inputClassNames={classNames(
              "w-full",
              inputErrClass(errors.location)
            )}
            placeholder="Enter location"
            register={register}
            type="text"
            validation={{
              maxLength: {
                value: 255,
                message: characterValidation({ limit: 255 }),
              },
            }}
            errorMessage={errors.location?.message}
          />
        </div>
      </div>

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="employment_type_name"
            className="mt-2 mb-1 block font-medium "
          >
            Employment Type
          </label>

          <Controller
            name="employment_type_name"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <AsyncPaginate
                loadOptions={employmentTypes}
                closeMenuOnSelect
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.uuid}
                id="employment_type_name"
                menuPlacement="auto"
                maxMenuHeight={120}
                placeholder="Select employment type"
                styles={reactSelectStyle}
                isSearchable={false}
                isClearable
                className="mb-3"
                onChange={(value) => field.onChange(value)}
                value={field.value?.name ? field.value : null}
                debounceTimeout={1000}
              />
            )}
          />
        </div>

        <div className="w-full flex-col sm:w-5/12">
          <LabeledInput
            id="no_of_positions"
            labelClassNames={classNames("block font-medium mt-2 mb-1", {
              "text-danger-main": errors.no_of_positions,
            })}
            label="Vacancies"
            name="no_of_positions"
            inputClassNames={classNames(
              "w-full",
              inputErrClass(errors.no_of_positions)
            )}
            placeholder="Enter the number of vacancies"
            register={register}
            type="number"
            validation={{
              max: {
                value: 999,
                message: "must be less than 1000",
              },
              min: {
                value: 0,
                message: "must be equal or greater than 0",
              },
            }}
            errorMessage={errors.no_of_positions?.message}
          />
        </div>
      </div>

      <div className="flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <LabeledInput
            id="ctc"
            labelClassNames={classNames("block font-medium mt-4 sm:mt-2 mb-1", {
              "text-danger-main": errors.ctc,
            })}
            label="CTC"
            name="ctc"
            inputClassNames={classNames("w-full", inputErrClass(errors.ctc))}
            placeholder="Enter CTC"
            register={register}
            type="text"
            validation={{
              maxLength: {
                value: 100,
                message: characterValidation({ limit: 100 }),
              },
              pattern: {
                value: CTC_REGEX,
                message: validFormatValidation({ data: "CTC" }),
              },
            }}
            errorMessage={errors.ctc?.message}
          />
        </div>
      </div>
      <div>
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{
            maxLength: {
              value: 3000,
              message: characterValidation({ limit: 3000 }),
            },
          }}
          render={({ field }) => (
            <RichTextEditor
              id="description"
              content={field.value?.replace(/(?<!^)\r?\n|\r/g, "<br>")}
              isLoading={isLoading}
              onChange={(content) => field.onChange(content)}
              placeholder="Enter job description..."
              label={<span className="required">Job Details</span>}
              fieldError={!!errors?.description?.message}
              labelClassNames={classNames("block font-medium mt-7 mb-3", {
                "text-danger-main": errors.description,
              })}
            >
              <div
                data-tooltip-id="generateButton"
                data-tooltip-content={
                  !watch("title")
                    ? "Please fill the Position field to generate Job Details"
                    : ""
                }
                className="ml-2"
              >
                <Button
                  buttonType="button"
                  btnName={
                    (isJobGenerated && watch("description")) || job?.description
                      ? "Regenerate"
                      : "Generate With AI"
                  }
                  testId="generate-description-btn"
                  type={watch("title") ? "info" : "disabled"}
                  onClick={(e) => handleJobDescriptionGeneration(e)}
                  isAsyncClick
                  customWidth={
                    (isJobGenerated && watch("description")) || job?.description
                      ? 28
                      : 32
                  }
                />
                <Tooltip
                  id="generateButton"
                  variant="dark"
                  place="top-start"
                  className="z-10"
                />
              </div>
            </RichTextEditor>
          )}
        />

        {errors.description && (
          <p className="mt-0 text-sm text-danger-dark">
            {errors.description?.message}
          </p>
        )}
      </div>
      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <div className="my-2">
            <label
              htmlFor="visibility"
              className={classNames(
                "required mt-3 block font-medium",
                errors.visibility ? "text-danger-main" : ""
              )}
            >
              Visibility
            </label>

            <div
              className="mt-1 mb-2 flex flex-row justify-between"
              id="visibility"
            >
              <div className="flex items-stretch">
                <input
                  className="mr-2"
                  type="radio"
                  id="visibility-public"
                  data-testid="visibility-public-radio"
                  value={JOB_VISIBILITY.public}
                  onClick={() => setCollege(false)}
                  {...register("visibility", {
                    required: requiredValidation(),
                  })}
                />

                <label
                  htmlFor="visibility-public"
                  className={classNames({
                    "text-danger-main": errors.visibility,
                  })}
                >
                  Public
                </label>
              </div>

              <div className="ml-2 flex items-stretch">
                <input
                  className="mr-2"
                  type="radio"
                  id="visibility-restricted"
                  data-testid="visibility-restricted-radio"
                  value={JOB_VISIBILITY.restricted}
                  onClick={() => setCollege(true)}
                  {...register("visibility", {
                    required: requiredValidation(),
                  })}
                />

                <label
                  htmlFor="visibility-restricted"
                  className={classNames({
                    "text-danger-main": errors.visibility,
                  })}
                >
                  Restricted
                </label>
              </div>
            </div>
          </div>
          {college && (
            <>
              <div
                className={classNames(
                  errors.visibility
                    ? "mt-2 flex text-danger-main"
                    : "mt-2 flex text-secondary-alertDark"
                )}
              >
                <BsFillExclamationCircleFill size={20} />
                <span className="ml-2 text-sm ">
                  You need to select a value for at least one of the three
                  fields (preferred college or preferred education or preferred
                  skills).
                </span>
              </div>

              <div className="my-3 justify-between sm:flex-row">
                <label
                  htmlFor="select-restricted_by"
                  className="my-2 block font-medium required"
                >
                  Restricted By
                </label>

                <Controller
                  control={control}
                  name="restricted_by"
                  render={({ field }) => (
                    <Select
                      isClearable
                      isSearchable={false}
                      options={JOB_RESTRICTED_BY}
                      isMulti
                      closeMenuOnSelect={false}
                      id="restricted-by-select"
                      menuPlacement="auto"
                      className="w-full"
                      styles={reactSelectStyle(errors.restricted_by)}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      noOptionsMessage={() => "No options"}
                      placeholder="Enter restricted by"
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.label}
                    />
                  )}
                />

                {errors.qualifications && (
                  <p className="text-sm text-danger-dark">
                    {errors.qualifications.message}
                  </p>
                )}
              </div>
            </>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12 ">
          <label htmlFor="select-colleges" className="my-2 block font-medium">
            Preferred Colleges
          </label>

          <Controller
            control={control}
            name="colleges"
            render={({ field }) => (
              <AsyncPaginate
                isSearchable
                isClearable
                isMulti
                closeMenuOnSelect={false}
                id="colleges_select"
                menuPlacement="auto"
                className="w-full"
                styles={reactSelectStyle(errors.colleges)}
                value={field.value}
                loadOptions={loadCollegeOptions}
                onChange={(value) => field.onChange(value)}
                noOptionsMessage={() => "No options"}
                debounceTimeout={1000}
                placeholder="Enter college name"
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                additional={{ page: 1 }}
                formatOptionLabel={(option, { context }) =>
                  handleOptionLabel(option, context)
                }
              />
            )}
          />

          {errors.colleges && (
            <p className="text-sm text-danger-dark">
              {errors.colleges.message}
            </p>
          )}
        </div>
      </div>

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label htmlFor="appeared" className="mt-2 mb-1 block font-medium">
            Close application on
          </label>

          <Controller
            control={control}
            id="application_close_at"
            name="application_close_at"
            rules={{
              validate: (date) => applicationCloseDateChecker(date),
            }}
            render={({ field }) => (
              <DatePicker
                placeholderText="Select auto-close date and time"
                isClearable
                className={`${
                  errors.application_close_at &&
                  !errors.application_close_at.warning
                    ? "input-error"
                    : "input"
                } w-full`}
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeSelect
                timeFormat="p"
                minDate={new Date()}
                styles={reactSelectStyle(errors.application_close_at)}
                timeIntervals={30}
                filterTime={(e) => filterPassedTime(e, new Date())}
                onChange={(date) => {
                  if (date === undefined || date === null) {
                    clearErrors("application_close_at");
                  } else if (isAfter(new Date(), new Date(date))) {
                    setError("application_close_at", {
                      message: dateTimeValidation(),
                    });
                  } else {
                    clearErrors("application_close_at");
                  }
                  return field.onChange(date);
                }}
                selected={field.value && toDate(new Date(field.value))}
              />
            )}
          />
          {errors.application_close_at && (
            <p
              className={classNames(
                "mt-2 text-sm",
                errors.application_close_at.warning
                  ? "text-warning-dark"
                  : "text-danger-dark"
              )}
            >
              {errors.application_close_at.message}
            </p>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12 mt-5 ">
          <div className="flex flex-col mt-[inherit]">
            <label
              className="relative flex w-max cursor-pointer select-none items-center"
              data-testid="auto-add-to-first-stage"
            >
              <span className="text-md mr-3">
                Auto add applicants to first stage
              </span>
              <input
                type="checkbox"
                className="sm:ml-4 h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
                {...register("auto_add_to_first_stage")}
              />
              <span className="font-small absolute right-1.5 text-xs uppercase text-white">
                OFF
              </span>
              <span className="absolute right-10 text-xs font-medium uppercase text-white">
                ON
              </span>
              <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
            </label>
          </div>

          {watch("auto_add_to_first_stage") && (
            <div className="mt-2 flex">
              <FcInfo size={20} />
              <span className="ml-2  text-sm text-secondary-alertDark ">
                Make sure to create the first stage in pipeline, for this
                feature to work.
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="mt-5 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <div className="w-full flex-col sm:w-5/12">
            <div className="flex flex-row">
              <div>
                <label
                  className="relative flex w-max cursor-pointer select-none items-center"
                  data-testid="allow-external-access"
                >
                  <span className="text-md mr-3">
                    Allow external access to this job
                  </span>
                  <input
                    type="checkbox"
                    className="sm:ml-4 h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
                    {...register("allow_external_access")}
                    onInput={() => {
                      if (watch("allow_external_access")) {
                        window.scrollTo(0, document.body.scrollHeight);
                      }
                      if (watch("allow_external_access") && !job) {
                        setValue("redirect_url", null);
                      }
                    }}
                  />
                  <span className="font-small absolute right-1.5 text-xs uppercase text-white">
                    OFF
                  </span>
                  <span className="absolute right-10 text-xs font-medium uppercase text-white">
                    ON
                  </span>
                  <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
                </label>
              </div>
              <div className="ml-2">
                <FaInfoCircle
                  className="ml-1 cursor-pointer text-gray-400 hover:text-secondary-alertDark"
                  size={ICON_SIZE_HASH.md}
                  data-tooltip-html="By enabling this, you enable <br/> candidates to apply for this job <br/> through a third-party website."
                  data-tooltip-id="allow-external-access"
                />
              </div>
            </div>
          </div>

          {watch("allow_external_access") && (
            <div className="w-full flex-col">
              <LabeledInput
                id="redirect-url"
                data-testid="redirect-url"
                labelClassNames={classNames("block mt-2 mb-1 font-medium", {
                  "text-danger-main": errors.redirect_url,
                })}
                label="Fallback URL"
                name="redirect_url"
                inputClassNames={classNames(
                  "w-full",
                  inputErrClass(errors.redirect_url)
                )}
                placeholder="Enter Fallback URL"
                register={register}
                type="text"
                rows={5}
                validation={{
                  pattern: {
                    value: WEBSITE_REGEX,
                    message: validFormatValidation({
                      data: "website",
                    }),
                  },
                }}
                errorMessage={errors.redirect_url?.message}
              />
            </div>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12 mt-4 sm:mt-0">
          <label
            className="relative flex  cursor-pointer select-none items-center"
            data-testid="hidden"
          >
            <span className="text-md mr-3">
              Only allow people with access link to apply
            </span>
            <div className="w-auto sm:relative sm:right-0">
              <input
                data-testid="hidden-job-toggle"
                type="checkbox"
                className="sm:ml-4 h-6 w-16 mt-0.5 cursor-pointer appearance-none rounded-full bg-gray-400 sm:mt-0"
                {...register("hidden")}
              />
              <span className="font-small absolute right-1.5 top-3.5 text-xs uppercase text-white sm:top-1">
                OFF
              </span>
              <span className="absolute right-10 top-3.5 text-xs font-medium uppercase text-white sm:top-1">
                ON
              </span>
              <span className="absolute top-3.5 right-8  h-4 w-7 transform rounded-full bg-white transition-transform sm:top-1" />
            </div>
          </label>
        </div>
      </div>

      <div className="mt-6 flex w-full">
        <Button
          title={clone ? "Post" : `${showPostbutton ? "Update" : "Post"}`}
          btnName={clone ? "Post" : `${showPostbutton ? "Update" : "Post"}`}
          testId="post-job-btn"
          buttonType="submit"
          type="primary"
          isLoading={isSubmitting && isActive}
          size="md"
        />

        {showSaveDraftbutton && (
          <Button
            title="Save Draft"
            btnName="Save Draft"
            btnClassName="ml-5"
            customWidth={32}
            testId="draft-job-btn"
            type="secondary"
            size="md"
            isLoading={isSubmitting && !isActive}
            onClick={() => setIsDraft(true)}
            buttonType="submit"
          />
        )}

        <Button
          title="Cancel"
          btnName="Cancel"
          testId="cancel-job-btn"
          type="tertiary"
          size="md"
          onClick={() =>
            job ? navigate(`/jobs/${params.uuid}`) : navigate("/jobs")
          }
        />
      </div>

      {isActive && (
        <Modal
          title="Are you sure?"
          testId="confirm-job-post-modal"
          description={`Are you sure you want to ${
            job
              ? `${
                  clone
                    ? "Post"
                    : `${job.status == "draft" ? "Post" : "Update"}`
                }`
              : "post"
          } this job?`}
          acceptButtonText="Yes"
          rejectButtonText="No"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          handleAccept={handleSubmit((data) => {
            setIsActive(false);
            postSubmitHandler(data);
          })}
          handleReject={() => setIsActive(false)}
          onOutsideClickHandler={() => setIsActive((post) => !post)}
        />
      )}

      <Tooltip id="allow-external-access" variant="dark" className="z-10" />
    </form>
  );
};

Form.propTypes = {
  job: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    requirements: PropTypes.string,
    location: PropTypes.string,
    no_of_positions: PropTypes.number,
    ctc: PropTypes.string,
    visibility: PropTypes.string,
    status: PropTypes.string,
    restricted_by: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    restricted_by_colleges: PropTypes.bool,
    restricted_by_qualifications: PropTypes.bool,
    restricted_by_skills: PropTypes.bool,
    application_close_at: PropTypes.string,
  }),
};

export default Form;
