import { BiWalletAlt } from "react-icons/bi";
import { CgOrganisation } from "react-icons/cg";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { MdLocationOn, MdOutlineHomeWork } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { formatDistanceToNow } from "date-fns";
import { camelCase, startCase } from "lodash";
import PropTypes from "prop-types";

import { ICON_SIZE_HASH } from "app/constants";
import { Avatar, Button, ListElement } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import Pill from "components/atoms/Pill";
import { JOB_STATUS } from "constants/jobs";
import { job } from "types/types";

const JobList = ({
  jobs,
  handleOnJobClick,
  currentTab,
  selectedJob,
  handleBookmarkJob,
}) => {
  return (
    <>
      {jobs.map((job) => (
        <div
          className="bg-white"
          tabIndex="0"
          onKeyDown={({ code }) => code == "Space" && handleOnJobClick(job)}
          key={job.uuid}
          role="button"
          onClick={() => handleOnJobClick(job)}
        >
          <ListElement
            isSelected={selectedJob && selectedJob.uuid === job.uuid}
            title={
              <>
                <div className="flex items-start">
                  {job.organisation.logo?.thumb ? (
                    <Avatar
                      src={job.organisation.logo?.thumb}
                      alt={job.organisation}
                      height={16}
                      width={16}
                      shape="rounded-md"
                      border="border-0"
                      optionalClass="mr-6 aspect-square	"
                    />
                  ) : (
                    <CharacterAvatar
                      char={job.organisation.name.charAt(0)}
                      width={14}
                      height={14}
                      bgColor="bg-primary-main"
                      textColor="text-white"
                      shape="rounded-md"
                      textSize="text-[2rem]"
                      optionalClass="py-2 px-6 mr-6"
                    />
                  )}

                  <div className="font-sans">
                    <p className="text-primary-light font-semibold text-lg">
                      {job.title}
                    </p>
                    <div>
                      <CgOrganisation
                        className="inline mr-2 mb-1"
                        size={ICON_SIZE_HASH.sm}
                        data-tooltip-content="Company Name"
                        data-tooltip-id={`company-name${job.uuid}`}
                      />
                      <span className="text-base font-medium text-gray-dark">
                        {job.organisation.name}
                      </span>
                    </div>
                    <div className="flex flex-wrap">
                      {job?.location && (
                        <div className="flex mr-4" data-testid="location-icon">
                          <span className="text-sm italic text-gray-main">
                            <MdLocationOn
                              className="inline mr-2 mb-1"
                              size={ICON_SIZE_HASH.sm}
                              data-tooltip-content="Job Location"
                              data-tooltip-id={`jobLocation-${job.uuid}`}
                            />
                          </span>
                          <span className="text-sm italic text-gray-main">
                            {job.location}
                          </span>
                        </div>
                      )}

                      {job?.location_type && (
                        <div
                          className="flex mr-4"
                          data-testid="location-type-icon"
                        >
                          <span className="text-sm italic text-gray-main">
                            <MdOutlineHomeWork
                              className="inline mr-2 mb-1"
                              size={ICON_SIZE_HASH.sm}
                              data-tooltip-content="Location Type"
                              data-tooltip-id={`locationType-${job.uuid}`}
                            />
                          </span>
                          <span className="text-sm italic text-gray-main">
                            {job?.location_type}
                          </span>
                        </div>
                      )}
                      {job.ctc != 0 && job.ctc != null && (
                        <div className="flex mr-4" data-testid="ctc-icon">
                          <span className="text-sm italic text-gray-main">
                            <BiWalletAlt
                              className="inline mb-1 mr-2"
                              size={ICON_SIZE_HASH.sm}
                              data-tooltip-content="CTC"
                              data-tooltip-id={`ctc-${job.uuid}`}
                            />
                          </span>
                          <span className="text-sm italic text-gray-main">
                            {job?.ctc}
                          </span>
                        </div>
                      )}
                    </div>
                    <Tooltip
                      id={`company-name${job.uuid}`}
                      variant="dark"
                      className="text-xs"
                    />
                    <Tooltip
                      id={`ctc-${job.uuid}`}
                      variant="dark"
                      className="text-xs"
                    />
                    <Tooltip
                      id={`locationType-${job.uuid}`}
                      variant="dark"
                      className="text-xs"
                    />
                    <Tooltip
                      id={`jobLocation-${job.uuid}`}
                      variant="dark"
                      className="text-xs"
                    />
                  </div>
                </div>
                <div className="flex">
                  {camelCase(job.status) === JOB_STATUS.jobClosed.text && (
                    <div className="text-end p-2">
                      <Pill
                        text={startCase(JOB_STATUS[camelCase(job.status)].text)}
                        type={JOB_STATUS[camelCase(job.status)].type}
                      />
                    </div>
                  )}
                  {currentTab === "saved" &&
                    camelCase(job.status) ===
                      JOB_STATUS.applicationClosed.text && (
                      <div className="text-end p-2">
                        <Pill
                          text={startCase(
                            JOB_STATUS[camelCase(job.status)].text
                          )}
                          type={JOB_STATUS[camelCase(job.status)].type}
                        />
                      </div>
                    )}
                  {currentTab !== "applied" && (
                    <span
                      data-tooltip-content={
                        job.is_saved ? "Remove Saved Job" : "Save Job!"
                      }
                      data-tooltip-id="bookmarkJob"
                    >
                      <Button
                        btnClassName="btn-icon-primary rounded-none"
                        testId="job-bookmark-btn"
                        onClick={(event) => handleBookmarkJob(event, job)}
                      >
                        {job.is_saved ? (
                          <FaBookmark className="text-primary-main" />
                        ) : (
                          <FaRegBookmark className="text-primary-main" />
                        )}
                      </Button>
                      <Tooltip
                        id="bookmarkJob"
                        variant="dark"
                        className="z-10"
                      />
                    </span>
                  )}
                </div>
              </>
            }
            description={
              <div className="flex w-full text-xs text-gray-dark justify-between items-center gap-2 my-2">
                {camelCase(job.status) !== JOB_STATUS.jobClosed.text && (
                  <span className="ml-auto whitespace-nowrap items-right">
                    {currentTab === "applied" ? "applied " : ""}
                    {formatDistanceToNow(
                      new Date(
                        currentTab === "applied"
                          ? job?.applied_at
                          : job?.status_updated_at || job?.created_at
                      ),
                      { addSuffix: true }
                    )}
                  </span>
                )}
              </div>
            }
          />
        </div>
      ))}
    </>
  );
};

JobList.propTypes = {
  jobs: PropTypes.arrayOf(job),
  handleOnJobClick: PropTypes.func,
  currentTab: PropTypes.oneOf(["suggested", "applied", "saved"]),
  selectedJob: job,
  handleBookmarkJob: PropTypes.func,
};

export default JobList;
