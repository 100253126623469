import pageErrorImage from "assets/images/DashboardImages/404_Error.png";

const NotFound = () => {
  return (
    <div className="grid h-64 place-items-center pt-16">
      <img src={pageErrorImage} alt="404 Error" className="m-auto w-1/4" />
      <p className="font-mono text-2xl font-semibold">Page not found</p>
    </div>
  );
};

export default NotFound;
