import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import PropTypes from "prop-types";

import { Button } from "components/atoms";
import Pill from "components/common/Pill";
import { Modal } from "components/molecules";

const TestCaseModal = ({
  questionTestCases,
  candidateAnswerTestCases,
  setShowTestCasesModal,
  isMockTest,
}) => {
  const [expandedIndex, setExpandedIndex] = useState();
  const collapsedCardClass = "flex flex-col gap-1 py-2 align-top";
  const collapsedCodeBlockClass =
    "whitespace-pre-wrap break-words rounded-md border p-2";

  const candidateAnswerTestCaseIds = candidateAnswerTestCases?.map(
    (e) => e.test_case_id
  );
  const filteredQuestionTestCases = questionTestCases.filter(
    (questionTestCase) =>
      candidateAnswerTestCaseIds.includes(questionTestCase.id)
  );

  const showTestCaseOutputAndStatus = (questionTestCaseId) => {
    const matchedTestCase = candidateAnswerTestCases?.find((answerTestCase) => {
      return answerTestCase.test_case_id === questionTestCaseId;
    });
    if (matchedTestCase) {
      return {
        output: matchedTestCase.output,
        status: matchedTestCase.status,
      };
    }
    return {
      output: "",
      status: false,
    };
  };

  const handleClickOnExpanding = (index) => {
    setExpandedIndex((currentExpandedIndex) => {
      if (currentExpandedIndex === index) {
        return -1;
      }
      return index;
    });
  };

  return (
    <Modal
      title="Test Case Evaluation Result"
      testId="test-case-modal-container"
      customModalWidth="70vw"
      handleCancel={() => {
        setShowTestCasesModal(false);
      }}
      scrollClass="overflow-auto"
      onOutsideClickHandler={() => setShowTestCasesModal(false)}
    >
      <div className="max-h-[600px] min-w-[400px]">
        {filteredQuestionTestCases.map((testCase, testCaseIndex) => {
          const isExpanded = testCaseIndex === expandedIndex;

          if (isMockTest && testCase.is_public) {
            return (
              <div
                className="mt-4 rounded-md border border-gray-light bg-white pl-6 pr-2 py-2 shadow-sm"
                key={testCase.id}
                data-testid="test-case-item"
              >
                <div className="flex w-full flex-grow items-center justify-between gap-2 bg-white">
                  <span>{testCaseIndex + 1}.</span>
                  <div className="flex items-center gap-3">
                    <span>Public:</span>
                    <span className="font-medium">
                      {testCase.is_public ? "Yes" : "No"}
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span>Status:</span>
                    <Pill
                      textColor="text-white"
                      label={
                        showTestCaseOutputAndStatus(testCase.id).status
                          ? "Pass"
                          : "Fail"
                      }
                      bgColor={
                        showTestCaseOutputAndStatus(testCase.id).status
                          ? "bg-success-main"
                          : "bg-danger-main"
                      }
                      className="font-medium"
                    />
                  </div>
                  <div>
                    {isExpanded ? (
                      <Button
                        title="Collapse"
                        onClick={() => {
                          handleClickOnExpanding(testCaseIndex);
                        }}
                        btnClassName="p-1 sm:p-2 mx-1 hover:bg-primary-lighter rounded-md"
                      >
                        <IoIosArrowUp className="text-2xl" />
                      </Button>
                    ) : (
                      <Button
                        title="Expand"
                        onClick={() => {
                          handleClickOnExpanding(testCaseIndex);
                        }}
                        btnClassName="p-1 sm:p-2 mx-1 hover:bg-primary-lighter rounded-md"
                      >
                        <IoIosArrowDown className="text-2xl" />
                      </Button>
                    )}
                  </div>
                </div>

                {isExpanded && (
                  <div>
                    <div className={collapsedCardClass}>
                      <span className="font-medium">Input:</span>
                      <span className={collapsedCodeBlockClass}>
                        {testCase.input}
                      </span>
                    </div>
                    <div className={collapsedCardClass}>
                      <span className="font-medium">Expected Output:</span>
                      <span className={collapsedCodeBlockClass}>
                        {testCase.output}
                      </span>
                    </div>
                    <div className={collapsedCardClass}>
                      <span className="font-medium">Actual Output:</span>
                      <span className={collapsedCodeBlockClass}>
                        {showTestCaseOutputAndStatus(testCase.id).output}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );
          }

          if (!isMockTest) {
            return (
              <div
                className="mt-4 rounded-md border border-gray-light bg-white pl-6 pr-2 py-2 shadow-sm"
                key={testCase.id}
                data-testid="test-case-item"
              >
                <div className="flex w-full flex-grow items-center justify-between gap-2 bg-white">
                  <span>{testCaseIndex + 1}.</span>
                  <div className="flex items-center gap-3">
                    <span>Public:</span>
                    <span className="font-medium">
                      {testCase.is_public ? "Yes" : "No"}
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span>Status:</span>
                    <Pill
                      textColor="text-white"
                      label={
                        showTestCaseOutputAndStatus(testCase.id).status
                          ? "Pass"
                          : "Fail"
                      }
                      bgColor={
                        showTestCaseOutputAndStatus(testCase.id).status
                          ? "bg-success-main"
                          : "bg-danger-main"
                      }
                      className="font-medium"
                    />
                  </div>
                  <div>
                    {isExpanded ? (
                      <Button
                        title="Collapse"
                        onClick={() => {
                          handleClickOnExpanding(testCaseIndex);
                        }}
                        btnClassName="p-1 sm:p-2 mx-1 hover:bg-primary-lighter rounded-md"
                      >
                        <IoIosArrowUp className="text-2xl" />
                      </Button>
                    ) : (
                      <Button
                        title="Expand"
                        onClick={() => {
                          handleClickOnExpanding(testCaseIndex);
                        }}
                        btnClassName="p-1 sm:p-2 mx-1 hover:bg-primary-lighter rounded-md"
                      >
                        <IoIosArrowDown className="text-2xl" />
                      </Button>
                    )}
                  </div>
                </div>

                {isExpanded && (
                  <div>
                    <div className={collapsedCardClass}>
                      <span className="font-medium">Input:</span>
                      <span className={collapsedCodeBlockClass}>
                        {testCase.input}
                      </span>
                    </div>
                    <div className={collapsedCardClass}>
                      <span className="font-medium">Expected Output:</span>
                      <span className={collapsedCodeBlockClass}>
                        {testCase.output}
                      </span>
                    </div>
                    <div className={collapsedCardClass}>
                      <span className="font-medium">Actual Output:</span>
                      <span className={collapsedCodeBlockClass}>
                        {showTestCaseOutputAndStatus(testCase.id).output}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
    </Modal>
  );
};

TestCaseModal.propTypes = {
  questionTestCases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      input: PropTypes.string,
      output: PropTypes.string,
      is_public: PropTypes.bool,
    })
  ),
  setShowTestCasesModal: PropTypes.func,
  candidateAnswerTestCases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      test_case_id: PropTypes.number,
      output: PropTypes.string,
      status: PropTypes.bool,
    })
  ),
  isMockTest: PropTypes.bool,
};

export default TestCaseModal;
