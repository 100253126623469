import PropTypes from "prop-types";

const Reference = ({ referenceDetail }) => {
  return (
    <>
      <div className="flex items-start justify-between">
        <h4 className="pt-2 pr-2 text-lg font-medium leading-5">
          {referenceDetail.name}
        </h4>
      </div>

      <p className="text-md pt-1">
        {referenceDetail.designation_name}{" "}
        {referenceDetail.designation_name && referenceDetail.company_name
          ? "|"
          : ""}
        <span> {referenceDetail.company_name}</span>
      </p>

      <p className="text-sm pt-1 pr-2 font-normal leading-5">
        {`+91 ${referenceDetail.phone.slice(
          0,
          4
        )}-${referenceDetail.phone.slice(4)}`}
      </p>
    </>
  );
};

Reference.propTypes = {
  referenceDetail: PropTypes.shape({
    uuid: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    designation_uuid: PropTypes.number,
    designation_name: PropTypes.string,
    company_uuid: PropTypes.number,
    company_name: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default Reference;
