const navigatorPermission = (setIsCameraOn) => {
  if (navigator.permissions) {
    navigator.permissions
      .query({ name: "camera" })
      .then((res) => {
        res.onchange = (e) => {
          if (e.type === "change") {
            const newState = e.target.state;
            if (newState === "denied") {
              setIsCameraOn(false);
            } else {
              setIsCameraOn(true);
            }
          }
        };
      })
      .catch(() => setIsCameraOn(false));
  }
};

export default navigatorPermission;
