import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BsFillTrashFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import classNames from "classnames";
import PropTypes, { string } from "prop-types";

import {
  addQuestion,
  addQuestionPaperQuestion,
  getDriverCodes,
  updateQuestion,
} from "api/erp/questionPaperApi";
import { ALERT_TYPE, QUESTION_PAGE_TYPES } from "app/constants";
import { Button, LabeledInput } from "components/atoms";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import { SITE_TITLE } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import { requiredValidation } from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { getCategoryAction } from "store/thunkActions/erp/categoriesThunk";
import { updateQuestionAction } from "store/thunkActions/erp/questionBankThunk";
import { getQuestionTagAction } from "store/thunkActions/erp/questionTagThunk";

const CodingForm = ({ questionPaper, selectedQuestion }) => {
  const [codingLanguage, setCodingLanguage] = useState();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const question = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const hasIndexPage = location?.state?.index;
  const viewQuestionID = searchParams.get("view_question");
  const hasQuestionType = searchParams.has("question_type");
  const questionType = searchParams.get("question_type");
  const clone = new URLSearchParams(window.location.search).has("clone");
  const questionBank = QUESTION_PAGE_TYPES[1].key;
  const isSectional = questionPaper?.sectional;

  const { data } = useSelector((state) => state.questionTag);
  const list = useSelector((state) => state.categories.data);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      testCase: [{ input: "", output: "", is_public: false }],
      driverCode: [],
    },
  });

  const {
    fields: testCaseFields,
    append: testCaseAppend,
    remove: removeTestCase,
  } = useFieldArray({ control, name: "testCase" });

  const { fields: codeFields, append: codingLanguageAppend } = useFieldArray({
    control,
    name: "driverCode",
  });

  const currentPageHeaderName = () => {
    if (selectedQuestion) {
      if (clone) {
        return "Clone";
      }
      return "Edit";
    }
    return "Add";
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: {
          intermediateLinkList: !hasQuestionType
            ? [
                { name: "Question Paper", link: "/questions_manager" },
                {
                  name: questionPaper?.name || "",
                  link: `/questions_manager/question_paper/${
                    question?.id || ""
                  }`,
                },
              ]
            : [
                {
                  name: "Question Bank",
                  link: "/questions_manager?tab=question_bank",
                },
              ],
          currentPageName: `${currentPageHeaderName()} Question`,
        },
      })
    );
  }, [dispatch, questionPaper, selectedQuestion, hasQuestionType, clone]);

  useEffect(() => {
    document.title = `${
      selectedQuestion ? `${clone ? "Clone" : "Edit"}` : "Add"
    } Question - ${
      hasQuestionType ? questionType.toUpperCase() : questionPaper?.name
    } - ${SITE_TITLE}`;
    clearErrors();
  }, [selectedQuestion]);

  useEffect(() => {
    getDriverCodes().then((response) => {
      setCodingLanguage(response.data);
    });
  }, []);

  // here we are appending the coding language object to driverCode fieldArray, after loading data from api we are appending the array using map.
  useEffect(() => {
    if (codingLanguage?.length > 0 && !selectedQuestion) {
      codingLanguage.map((language) => {
        return codingLanguageAppend({
          coding_language_id: { id: language.id, name: language.name },
          code_string: language.driver_code,
        });
      });
    }
  }, [codingLanguage]);

  useEffect(() => {
    dispatch(getQuestionTagAction());
    dispatch(getCategoryAction());
  }, []);

  useEffect(() => {
    if (selectedQuestion) {
      reset({
        ...selectedQuestion,
        question: clone
          ? `Copy of- ${selectedQuestion.text}`
          : selectedQuestion.text,
        tags: selectedQuestion.tags.map(
          ({ tag_id: tagId, tag_name: tagName }) => ({
            id: tagId,
            name: tagName,
          })
        ),
        category: selectedQuestion.category_name
          ? { name: selectedQuestion.category_name }
          : null,

        testCase: selectedQuestion.test_cases,
        driverCode: selectedQuestion.driver_codes.map((value) => {
          return {
            coding_language_id: {
              id: value.coding_language_id,
              name: value.coding_language,
            },
            code_string: value.code_string,
            id: value.id,
          };
        }),
      });
    }
  }, [selectedQuestion]);

  const setErrorsOnFields = (errors) => {
    if (!watch("marks")) {
      setError("marks", { message: "can't be blank" });
    }

    Object.entries(errors).forEach(([key, value]) => {
      if (key === "text") {
        setError("question", { message: value });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const getSubmitButtonText = () =>
    selectedQuestion && !clone ? "Update" : "Save";
  const deleteTestCaseField = (index) => {
    removeTestCase(index);
  };

  const submitHandler = (data) => {
    const categoryId = list?.filter((l) => l.name == data.category?.name);
    const formValues = {};
    formValues.active = true;
    formValues.duration = data.duration;
    formValues.marks = data.marks;
    formValues.category_id = data.category?.id || categoryId[0]?.id;
    formValues.text = data.question.trim();
    formValues.tags = data.tags.map(({ value: name, ...rest }) => ({
      name,
      id: null,
      ...rest,
    }));
    formValues.test_cases = data.testCase.map((e) => {
      if (e.input !== "" && e.output !== "") {
        const test = {
          id: e.id,
          input: e.input,
          output: e.output,
          is_public: e.is_public,
        };
        return test;
      }
    });
    formValues.driver_codes = data.driverCode.map((e) => {
      if (e.code_string !== "") {
        const code = {
          id: e.id,
          code_string: e.code_string,
          coding_language_id: e.coding_language_id.id,
        };
        return code;
      }
    });
    if (!hasQuestionType) {
      formValues.question_paper_id = questionPaper.id;
      if (isSectional) {
        formValues.section_id = question.section_id;
      }
    } else {
      formValues.question_type = questionType;
    }
    if (selectedQuestion && !clone) {
      // eslint-disable-next-line no-return-assign
      return updateQuestion(
        (selectedQuestion.question_id ??= selectedQuestion.id),
        formValues
      )
        .then((response) => {
          dispatch(updateQuestionAction(question.question_id, formValues));
          if (hasIndexPage) {
            navigate(
              `/questions_manager?${constructQueryParams({
                tab: questionBank,
              })}`
            );
          } else if (!hasQuestionType) {
            navigate(`/questions_manager/question_paper/${questionPaper.id}`);
          } else if (viewQuestionID) {
            navigate(
              `/questions_manager/question/${viewQuestionID}?${constructQueryParams(
                {
                  direct: questionBank,
                }
              )}`
            );
          } else {
            navigate(
              `/questions_manager?${constructQueryParams({
                tab: questionBank,
              })}`
            );
          }
          showAlert(
            ALERT_TYPE[response.meta.message_type],
            response.meta.message
          );
        })
        .catch((errors) => {
          setErrorsOnFields(errors.response.data);
        });
    }
    if (hasQuestionType) {
      return addQuestion(formValues)
        .then(async (response) => {
          if (viewQuestionID) {
            navigate(
              `/questions_manager/question/${
                response.data.id
              }?${constructQueryParams({ direct: questionBank })}`
            );
          } else {
            navigate(
              `/questions_manager?${constructQueryParams({
                tab: questionBank,
              })}`
            );
          }
          showAlert(
            ALERT_TYPE[response.meta.message_type],
            response.meta.message
          );
        })
        .catch((errors) => {
          setErrorsOnFields(errors.response.data);
        });
    }
    return addQuestionPaperQuestion(questionPaper.id, formValues)
      .then(async (response) => {
        if (isSectional) {
          navigate(
            `/questions_manager/question_paper/${
              question.id
            }?${constructQueryParams({
              tab: question.section_id,
            })}`
          );
        } else {
          navigate(`/questions_manager/question_paper/${question.id}`);
        }
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );
      })
      .catch((errors) => {
        setErrorsOnFields(errors.response.data);
      });
  };

  const handleNavigationCancel = () => {
    if (hasIndexPage || (!viewQuestionID && clone)) {
      navigate(
        `/questions_manager?${constructQueryParams({ tab: questionBank })}`
      );
    } else if (!hasQuestionType && isSectional) {
      navigate(
        `/questions_manager/question_paper/${
          question.id
        }?${constructQueryParams({ tab: question.section_id })}`
      );
    } else if (!hasQuestionType) {
      navigate(`/questions_manager/question_paper/${question.id}`);
    } else if (viewQuestionID) {
      navigate(
        `/questions_manager/question/${viewQuestionID}?${constructQueryParams({
          direct: questionBank,
        })}`
      );
    } else {
      navigate(
        `/questions_manager?${constructQueryParams({ tab: questionBank })}`
      );
    }
  };

  return (
    <div className="w-full py-4 px-4">
      <form
        className="mx-auto mt-8 h-fit w-full bg-white p-4 xl:container md:px-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <label
          htmlFor="question"
          className={`required mt-2 mb-1 block font-medium ${
            errors.question && "text-danger-main"
          }`}
        >
          Question
        </label>

        <Controller
          name="question"
          control={control}
          defaultValue=""
          rules={{
            required: "cant't be blank",
            validate: (value) => value.trim() !== "" || "can't be blank",
          }}
          render={({ field }) => (
            <RichTextEditor
              id="question"
              content={field.value}
              onChange={(content) => field.onChange(content)}
              placeholder="Enter question here"
              fieldError={!!errors.question?.message}
            />
          )}
        />
        {errors.question && (
          <p className="text-sm text-danger-dark">{errors.question.message}</p>
        )}

        <div className="mt-2 flex flex-col justify-between sm:flex-row">
          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="question_type"
              className="required mt-4 mb-1 block font-medium"
            >
              Question Type
            </label>

            <Controller
              name="question_type"
              control={control}
              render={({ field }) => (
                <Select
                  name="question_type"
                  className="bg-gray-lighter"
                  isClearable
                  isDisabled
                  id="question_type"
                  menuPlacement="auto"
                  styles={reactSelectStyle(errors.question_type)}
                  placeholder={
                    !hasQuestionType
                      ? questionPaper?.question_paper_type?.toUpperCase()
                      : questionType.toUpperCase()
                  }
                  value={field.value}
                />
              )}
            />
          </div>
          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="category"
              className={classNames("required mt-4 mb-1 block font-medium", {
                "text-danger-main": errors.category,
              })}
            >
              Categories
            </label>

            <Controller
              name="category"
              control={control}
              rules={{
                required: requiredValidation(),
              }}
              render={({ field }) => (
                <Select
                  name="category"
                  placeholder="Select a category"
                  options={list}
                  id="category"
                  className="w-full"
                  value={field.value}
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                  isClearable
                  isSearchable
                  menuPlacement="auto"
                  styles={reactSelectStyle(errors.category)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors.category && (
              <p className="text-sm text-danger-dark">
                {errors.category.message}
              </p>
            )}
          </div>
        </div>

        <LabeledInput
          id="tags"
          labelClassNames={classNames("block mt-2 mb-1 font-medium required", {
            "text-danger-main": errors.tags,
          })}
          label="Tags"
          errorMessageClassNames="text-sm text-danger-dark"
        />
        <Controller
          name="tags"
          control={control}
          rules={{
            required: requiredValidation(),
          }}
          render={({ field }) => (
            <CreatableSelect
              isClearable
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              maxMenuHeight={160}
              id="tags"
              menuPlacement="auto"
              className="w-full"
              styles={reactSelectStyle(errors.tags)}
              value={field.value ? field.value.name || field.value : null}
              options={data}
              onChange={(value) => field.onChange(value)}
              debounceTimeout={1000}
              placeholder="Enter text to get matching tags or to create a new tag"
              getOptionValue={(option) => option.id || { name: option.label }}
              getOptionLabel={(option) =>
                option.label || option.name || option.tag_name
              }
              formatCreateLabel={(name) => `Create tag "${name}"`}
            />
          )}
        />

        {errors.tags && (
          <p className="text-sm text-danger-dark">{errors.tags?.message}</p>
        )}

        <div className="mt-2 flex flex-col justify-between sm:flex-row ">
          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="duration"
              className={`required mt-2 mb-1 block font-medium ${
                errors.duration && "text-danger-main"
              }`}
            >
              Duration{" "}
              <span className="mt-1 text-xs italic">( in seconds )</span>
            </label>

            <input
              id="duration"
              className={`${errors.duration ? "input-error" : "input"} w-full`}
              type="number"
              pattern="[0-9]"
              placeholder="Duration"
              {...register("duration", {
                min: {
                  value: 1,
                  message: "Minimum duration must be 1 sec",
                },
                required: "can't be blank",
                valueAsNumber: true,
              })}
            />

            {errors.duration && (
              <p className="text-sm text-danger-dark">
                {errors.duration.message}
              </p>
            )}
          </div>

          <div className="w-full flex-col sm:w-5/12">
            <label
              htmlFor="marks"
              className={`required mt-2 mb-1 block font-medium ${
                errors.marks && "text-danger-main"
              }`}
            >
              Marks
            </label>

            <input
              id="marks"
              className={`${errors.marks ? "input-error" : "input"} w-full`}
              type="number"
              placeholder="Marks"
              pattern="[0-9]"
              step="0.01"
              {...register("marks", {
                min: {
                  value: 1,
                  message: "Minimum mark must be 1",
                },
                required: "can't be blank",
                valueAsNumber: true,
              })}
            />

            {errors.marks && (
              <p className="text-sm text-danger-dark">{errors.marks.message}</p>
            )}
          </div>
        </div>

        <div className="required mt-10 font-medium">
          Languages & Driver Codes
        </div>

        <div className="flex flex-col flex-wrap justify-between sm:flex-row">
          {codeFields.map((item, index) => {
            return (
              <div key={item.id} className=" mt-10 w-full sm:w-5/12 ">
                <div className="w-full flex-col flex-wrap">
                  <div className="mb-2 w-full">
                    <div>
                      <label
                        htmlFor={`driverCode.${index}.coding_language`}
                        className="hidden"
                      >
                        Coding Language:
                      </label>
                    </div>

                    <div className="w-full ">
                      <Controller
                        name={`driverCode.${index}.coding_language`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            name={`driverCode.${index}.coding_language`}
                            placeholder={item.coding_language_id?.name}
                            options={item.coding_language_id}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            id={`driverCode.${index}.coding_language`}
                            className=" text-gray-800"
                            defaultValue={item.coding_language_id?.name}
                            onChange={(data) => field.onChange(data)}
                            styles={reactSelectStyle(
                              errors?.driverCode?.[index]?.coding_language_id
                            )}
                            isDisabled
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                          />
                        )}
                      />
                      {errors?.driverCode?.[index]?.coding_language && (
                        <p className="text-sm text-danger-dark">
                          {errors.driverCode?.[index]?.coding_language.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <LabeledInput
                      id={`driverCode.${index}.code_string`}
                      rows={5}
                      type="textarea"
                      label="Driver Code Input:"
                      name={`driverCode.${index}.code_string`}
                      defaultValue={item.code_string}
                      inputClassNames={`${
                        errors.driverCode?.[index]?.code_string
                          ? "input-error"
                          : "input"
                      } w-full border-2 rounded-md whitespace-pre-wrap`}
                      labelClassNames={classNames("hidden", {
                        "text-danger-main":
                          errors.driverCode?.[index]?.code_string,
                      })}
                      register={register}
                      validation={{
                        required: requiredValidation(),
                        validate: (value) =>
                          value.trim() !== "" || "can't be blank",
                      }}
                    />
                    {errors?.driverCode?.[index]?.code_string && (
                      <p className="text-sm text-danger-dark">
                        {errors.driverCode[index].code_string.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-10 font-medium"> Test Cases</div>
        <div className="flex flex-col flex-wrap justify-between sm:flex-row">
          {testCaseFields.map((item, index) => {
            return (
              <div key={item.id} className="w-full sm:w-5/12">
                <div className="mt-10 px-2">
                  <div className="flex w-full flex-col">
                    <div>
                      <div className="w-full">
                        <div className="mb-2 flex flex-col gap-2">
                          <div>
                            <label
                              htmlFor={`testCase.${index}.input`}
                              className={`required font-medium ${
                                errors.testCase?.[index]?.input &&
                                "text-danger-main"
                              }`}
                            >
                              Input:
                            </label>
                          </div>
                          <div className="w-full">
                            <textarea
                              id={`testCase.${index}.input`}
                              rows={1}
                              className={`${
                                errors.testCase?.[index]?.input
                                  ? "input-error"
                                  : "input"
                              } mt-1 mb-1 w-full`}
                              type="text"
                              {...register(`testCase.${index}.input`, {
                                required: "can't be blank",
                                validate: (value) =>
                                  value.trim() !== "" || "can't be blank",
                              })}
                            />
                            {errors.testCase?.[index]?.input && (
                              <p className="text-sm text-danger-dark">
                                {errors.testCase?.[index]?.input.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <div>
                            <label
                              htmlFor={`testCase.${index}.output`}
                              className={`required font-medium${
                                errors.testCase?.[index]?.output &&
                                "text-danger-main"
                              }`}
                            >
                              Expected Output:
                            </label>
                          </div>
                          <div className="w-full">
                            <textarea
                              id={`testCase.${index}.output`}
                              rows={1}
                              className={`${
                                errors.testCase?.[index]?.output
                                  ? "input-error"
                                  : "input"
                              } w-full`}
                              type="text"
                              {...register(`testCase.${index}.output`, {
                                required: "can't be blank",
                                validate: (value) =>
                                  value.trim() !== "" || "can't be blank",
                              })}
                            />
                            {errors.testCase?.[index]?.output && (
                              <p className="text-sm text-danger-dark">
                                {errors.testCase?.[index]?.output?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <label
                          htmlFor={`testCase.${index}.is_public`}
                          className="my-1"
                        >
                          Make it Public
                        </label>
                        <input
                          id="is_public"
                          className=" float-left mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
                          type="checkbox"
                          {...register(`testCase.${index}.is_public`)}
                        />
                      </div>

                      {index > 0 && (
                        <BsFillTrashFill
                          className="cursor-pointer text-lg text-danger-main"
                          onClick={() => deleteTestCaseField(index)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="w-5/12">
            {" "}
            <button
              title="Add Test Case"
              className="left-0 my-6 self-start py-4 font-medium uppercase text-primary-main sm:block hover:underline"
              type="button"
              data-testid="addTest"
              onClick={() =>
                testCaseAppend({ input: "", output: "", is_public: false })
              }
            >
              Add Test Case +
            </button>
          </div>
        </div>

        <div className="mt-6 flex w-full flex-row">
          <Button
            title={getSubmitButtonText()}
            type="primary"
            size="md"
            testId="questionBtn"
            buttonType="submit"
            isLoading={isSubmitting}
          >
            {getSubmitButtonText()}
          </Button>

          <Button
            title="Cancel"
            testId="cancelBtn"
            type="secondary"
            size="md"
            btnClassName="ml-2"
            onClick={handleNavigationCancel}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

CodingForm.propTypes = {
  questionPaper: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    question_paper_type: string,
    sectional: PropTypes.bool,
  }),
  selectedQuestion: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    marks: PropTypes.string,
    duration: PropTypes.number,
    category_id: PropTypes.number,
    category_name: PropTypes.string,
    section_id: PropTypes.number,
    question_id: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        attachment: PropTypes.string,
        attachment_file_name: PropTypes.string,
        id: PropTypes.number,
        question_id: PropTypes.number,
        type: PropTypes.string,
      })
    ),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        marks: PropTypes.string,
        duration: PropTypes.number,

        section_id: PropTypes.number,
        question_id: PropTypes.number,
        attachment_file_name: PropTypes.string,
        attachment: PropTypes.string,
        correct_answer: PropTypes.bool,
      })
    ),
    test_cases: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        input: PropTypes.string,
        output: PropTypes.string,
        section_id: PropTypes.number,
        question_id: PropTypes.number,
        is_public: PropTypes.bool,
      })
    ),
    driver_codes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        driver_code: PropTypes.string,
        output: PropTypes.string,
        coding_language_id: PropTypes.number,
        question_id: PropTypes.number,
      })
    ),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        tag_name: PropTypes.string,
        tag_id: PropTypes.number,
      })
    ),
  }),
  getQuestionPapersQuestions: PropTypes.func,
};

export default CodingForm;
