import React from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";

export const ListItem = ({ name }) => {
  return (
    <ol className="mb-6 list-decimal pl-4 text-base">
      {name.map((data) => (
        <li
          key={`${uniqueId}`}
          className={classNames(
            "text-roboto text-sm font-normal leading-7 text-black-primary",
            {
              "text-danger-main": data.warningContent,
            }
          )}
        >
          {data.boldContent && (
            <span className="font-semibold">{`${data.boldContent} `}</span>
          )}
          {data.lightContent && <span>{data.lightContent}</span>}
          {data.warningContent && (
            <span className="text-danger-main">{data.warningContent}</span>
          )}
          {data.listContent && (
            <ul className="list-disc pl-0">
              {data.listContent.map((list) => {
                return (
                  <li className="ml-8" key={list.item}>
                    {list.item}
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      ))}
    </ol>
  );
};
ListItem.propTypes = {
  name: PropTypes.array,
};
