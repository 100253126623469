import { createSlice } from "@reduxjs/toolkit";

import { getLocationTypesAction } from "store/thunkActions/erp/locationTypesThunk";

export const locationTypesSlice = createSlice({
  name: "locationTypes",
  initialState: {
    locationTypes: undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocationTypesAction.fulfilled, (state, action) => {
      state.locationTypes = action.payload;
    });
  },
});

export default locationTypesSlice.reducer;
