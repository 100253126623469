import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { format } from "date-fns";

import { downloadScreenShots, getScreenShots } from "api/erp/organizationApi";
import { Button } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import ActivityIndicator from "components/common/ActivityIndicator";
import Slider from "components/common/ImageZoom";
import { Modal } from "components/molecules";
import { examDuration, SITE_TITLE } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import { FORMATTED_DATE_TYPE, FORMATTED_TIME_TYPE } from "helpers/dateFormat";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

const Screenshots = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const [screenshots, setScreenshots] = useState([]);
  const [referenceImage, setReferenceImage] = useState([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [showReferenceImageModal, setShowReferenceImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [showModalMessage, setShowModalMessage] = useState("");
  const [examMetaData, setExamMetaData] = useState(null);
  const [isLoadingScreenshots, setIsLoadingScreenshots] = useState(false);
  const [screenshotStatus, setScreenshotStatus] = useState({
    message: null,
    message_type: "WARNING",
  });

  const handleImageClick = (index) => {
    setShowCarousel(true);
    setCurrentImage(index);
    setCurrentImageIndex(index);
  };

  const handleDownload = async (fileType) => {
    showAlert("success", "Download starting soon");
    const parts = screenshots[currentImage]?.attachment_file_name.split("/");

    const fileName = fileType === "image" ? parts[parts.length - 1] : "";
    const query = {
      candidate_id: params.candidateId,
      exam_id: params.examId,
    };

    if (fileName !== "") {
      query.file_name = fileName;
    }

    await downloadScreenShots(query)
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${
          response.headers["content-disposition"]?.split('"')[1]
        }`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          showAlert("danger", "Error downloading file");
        }
      });
  };

  useEffect(() => {
    setModalTitle(screenshots[currentImageIndex]?.metadata?.uploaded_at);
    setShowModalMessage(screenshots[currentImageIndex]?.metadata?.description);
  }, [currentImageIndex, screenshots]);

  const ReferenceImageClickhandler = () => {
    setShowReferenceImageModal(true);
  };

  useEffect(() => {
    document.title = `Candidate Screenshots - ${SITE_TITLE}`;
    setIsLoadingScreenshots(true);

    getScreenShots(params.candidateId, params.examId)
      .then(({ data, meta }) => {
        setScreenshots(data.screen_shots);
        setReferenceImage(data.reference_images);
        setExamMetaData(data);
        const newStatus = {
          message: meta.message,
          message_type: "WARNING", // todo: from BE meesage type is coming success that is why i have hard coded this but in future we will be updating this.
        };
        setScreenshotStatus(newStatus);
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          navigate("/notfound");
        }
      })
      .finally(() => {
        setIsLoadingScreenshots(false);
      });
  }, []);

  useEffect(() => {
    if (examMetaData) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              { name: "Jobs", link: "/jobs" },
              { name: examMetaData.job_title, link: `/jobs/${params.uuid}` },
              {
                name: examMetaData.stage_name,
                link: `/jobs/${params.uuid}/pipeline?${constructQueryParams({
                  page: 1,
                  page_size: 20,
                  stageId: examMetaData.stage_id,
                })}`,
              },
              {
                name: examMetaData.candidate_job_id,
                link: `/jobs/${params.uuid}/candidates/${examMetaData.candidate_job_id}`,
              },
            ],
            currentPageName: "Snapshots",
          },
        })
      );
    }
  }, [dispatch, params.uuid, examMetaData]);

  return (
    <>
      {screenshotStatus.message && (
        <div className="mx-2 sm:mx-4 flex justify-center my-2 ">
          <ActionAlert
            alertType={screenshotStatus.message_type.toLowerCase()}
            message={<p className="xs:pr-0 pr-4">{screenshotStatus.message}</p>}
            isButtonRequire={false}
            iconName="warning"
            roundedBorder
            customClassName="mb-2 md:pl-1"
          />
        </div>
      )}
      <div className="flex flex-col sm:flex-row w-full bg-gray-lighterShade3 px-4 pt-2 md:px-6 p-2">
        <div className="pt-4 sm:w-2/3  sm:border-r-4 w-full">
          {isLoadingScreenshots ? (
            <ActivityIndicator />
          ) : (
            <div className="grid md:grid-cols-2 2xl:grid-cols-3 gap-8 bg-gray-lighterShade3 px-4 md:px-6">
              {screenshots.map((screenshot, index) => (
                <div
                  className="flex flex-col rounded-lg border border-gray-200 shadow"
                  key={index}
                >
                  <button onClick={() => handleImageClick(index)}>
                    <img
                      key={index}
                      src={screenshot.attachment}
                      alt={`Screenshot ${index}`}
                      title="Click to Zoom"
                    />
                  </button>
                  <div className="px-3 py-6">
                    <p className="text-lg text-gray-900">
                      {format(
                        new Date(screenshot.metadata.uploaded_at),
                        "dd-MMM-yyyy h:mm:ss aaa"
                      )}
                    </p>

                    <p className="pt-2 text-md text-gray-600">
                      {screenshot.metadata.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="relative flex flex-col w-full order-first sm:order-last sm:w-1/3 ">
          <div className="sticky top-20 sm:p-2">
            <div className="flex justify-between items-center flex-wrap mx-6 overflow-x-auto">
              <h2 className="text-lg sm:text-sm break-words lg:text-lg text-primary-main mr-1">
                {examMetaData?.candidate_name}
              </h2>
              {screenshots.length > 0 && (
                <Button
                  btnClassName={classNames(
                    "text-md my-1 mr-1 text-primary-main px-3",
                    {
                      "text-left": examMetaData?.candidate_name?.length > 20,
                    }
                  )}
                  title="Download all ss"
                  type="secondary"
                  onClick={() => handleDownload("zip")}
                >
                  Download All
                </Button>
              )}
            </div>
            <div className="m-2 rounded-xl border-2 bg-white p-3">
              <div className="p-1 text-base">
                <p className="break-words">
                  <span className="meta-data">Exam:</span>
                  {examMetaData?.exam_name}
                </p>
                <p className="break-words">
                  <span className="meta-data">Stage:</span>
                  {examMetaData?.stage_name}
                </p>
                <p>
                  <span className="meta-data">Appeared on:</span>
                  {examMetaData?.start_time && (
                    <>
                      <span className="mb-1">
                        {format(
                          new Date(examMetaData?.start_time),
                          FORMATTED_DATE_TYPE
                        )}
                        ,{" "}
                      </span>
                      <span>
                        {format(
                          new Date(examMetaData?.start_time),
                          FORMATTED_TIME_TYPE
                        )}
                      </span>
                    </>
                  )}
                </p>
                <p>
                  <span className="meta-data">Duration:</span>
                  {examDuration(
                    examMetaData?.start_time,
                    examMetaData?.end_time
                  )}
                </p>
              </div>
            </div>

            <div className="m-2 rounded-xl border-2 bg-white p-3">
              <div className="p-1 text-base">
                <p className="break-words">
                  <span className="meta-data text-lg">Reference Image</span>
                </p>
              </div>
              {isLoadingScreenshots ? (
                <ActivityIndicator />
              ) : (
                referenceImage?.map((image, index) => (
                  <button key={index} onClick={ReferenceImageClickhandler}>
                    <div key={index} className="pt-3">
                      <img
                        src={image?.attachment}
                        alt={`Reference ${index}`}
                        key={index}
                        title="Click to Zoom"
                      />
                    </div>
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      {showCarousel && (
        <Modal
          title={
            modalTitle &&
            format(new Date(modalTitle), "dd-MMM-yyyy h:mm:ss aaa")
          }
          onOutsideClickHandler={() => setShowCarousel(false)}
          handleCancel={() => setShowCarousel(false)}
          customModalWidth="sm:100%"
        >
          <Slider
            screenshots={screenshots}
            currentIndex={currentImageIndex}
            onChangeIndex={setModalTitle}
            setShowModalMessage={setShowModalMessage}
            violationMessage={showModalMessage}
            handleDownload={handleDownload}
            setCurrentImage={setCurrentImage}
          />
        </Modal>
      )}

      {showReferenceImageModal && (
        <Modal
          title="Reference Image"
          onOutsideClickHandler={() => setShowReferenceImageModal(false)}
          handleCancel={() => setShowReferenceImageModal(false)}
          customModalWidth="sm:100%"
        >
          <Slider screenshots={referenceImage} />
        </Modal>
      )}
    </>
  );
};

export default Screenshots;
