import { createSlice } from "@reduxjs/toolkit";

import {
  createQuestionTagAction,
  deleteQuestionTagAction,
  getFilterQuestionTagAction,
  getQuestionTagAction,
  updateQuestionTagAction,
} from "store/thunkActions/erp/questionTagThunk";

const questionTag = createSlice({
  name: "questionTag",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getQuestionTagAction.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
    },
    [getFilterQuestionTagAction.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
    },
    [createQuestionTagAction.fulfilled]: (state, action) => {
      state.data.push(action.payload.data);
    },
    [updateQuestionTagAction.fulfilled]: (state, action) => {
      const index = (state.data ?? []).findIndex(
        (tag) => tag.id == action.payload.data.id
      );
      if (index !== -1) {
        state.data[index] = action.payload.data;
      }
    },
    [deleteQuestionTagAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (tag) => tag.id == action.payload.data.id
      );
      state.data.splice(index, 1);
    },
  },
});

export default questionTag.reducer;
