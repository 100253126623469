import { useEffect, useState } from "react";
import PropTypes from "prop-types";

let timer = "";

const Timer = ({ question, onTimeOver, setIsSaving }) => {
  const [time, setTime] = useState(question.secondsLeft);

  useEffect(() => {
    timer = setInterval(() => {
      setTime((prevTime) => prevTime > 0 && prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (time === 0) {
      onTimeOver();
    }
    if (time <= 3) {
      setIsSaving(true);
    }
  }, [time]);

  useEffect(() => {
    setTime(question.secondsLeft);
  }, [question.id, question.secondsLeft]);

  const CalculateTime = () => {
    return (
      !Number.isNaN(Number(time)) &&
      new Date(1000 * time).toISOString().substr(12, 7)
    );
  };

  return (
    <span>
      <CalculateTime />
    </span>
  );
};

Timer.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    secondsLeft: PropTypes.number,
  }),
  onTimeOver: PropTypes.func,
  setIsSaving: PropTypes.func,
};
export default Timer;
