import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";

import { getCities, getStates } from "api/candidate/profileApi";
import { Button } from "components/atoms";
import { handleOptionLabel } from "helpers/formatDropDownOption";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import getBrowserLocation from "helpers/getBrowserLocation";
import reactSelectStyle from "helpers/reactSelectStyle";
import specialCharacterChecker from "helpers/specialCharacterChecker";
import { validateDropdowns } from "helpers/utils";
import {
  createContactDetailsAction,
  updateContactDetailsAction,
} from "store/thunkActions/candidate/contactDetailsThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const Form = ({ contactDetail, setIsEditState, setIsAdding }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { isDirty, errors, isSubmitting },
    setError,
  } = useForm();
  const state = watch("state");

  const dispatch = useDispatch();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentState, setCurrentState] = useState(null);

  const loadStateOptions = async (search, _, { page }) => {
    if (specialCharacterChecker(search)) {
      const {
        data: {
          data: options,
          meta: { has_more: hasMore },
        },
      } = await getStates(page, search);
      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    }
    return { options: [] };
  };

  const loadCityOptions = useCallback(
    async (search, _, { page }) => {
      if (state?.name && specialCharacterChecker(search)) {
        const {
          data: {
            data: options,
            meta: { has_more: hasMore },
          },
        } = await getCities(page, search, state.uuid);
        return {
          options,
          hasMore,
          additional: {
            page: page + 1,
          },
        };
      }
      return { options: [] };
    },
    [state]
  );

  useEffect(() => {
    if (!contactDetail) {
      getBrowserLocation(
        latitude,
        longitude,
        setLatitude,
        setLongitude,
        setCurrentCity,
        setCurrentState
      );
    }
  }, [contactDetail, latitude, longitude]);

  useEffect(() => {
    if (!contactDetail && currentState) {
      loadStateOptions().then((res) => {
        res.options?.forEach((option) => {
          if (option.name === currentState) {
            setValue("state", option);
          }
        });
      });
    }
  }, [contactDetail, currentState, setValue]);

  useEffect(() => {
    if (!contactDetail && currentCity) {
      loadCityOptions().then((res) => {
        res.options?.forEach((option) => {
          if (option.name === currentCity) {
            setValue("city", option);
          }
        });
      });
    }
  }, [contactDetail, currentCity, loadCityOptions, setValue]);

  useEffect(() => {
    reset({
      ...contactDetail,
      state: { name: contactDetail?.state, uuid: contactDetail?.state_uuid },
      city: { name: contactDetail?.city, uuid: contactDetail?.city_uuid },
    });
  }, [contactDetail, reset]);

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    // Restrict API call when no changes
    if (data.id && !isDirty) {
      return setIsEditState(false);
    }

    // Structuring req body
    data.state_uuid = data.state?.uuid;
    data.city_uuid = data.city?.uuid;
    data.state = data.state?.name;
    data.city = data.city?.name;
    data.country = "India";

    // Update if existing record else create new
    const action = data.id
      ? updateContactDetailsAction(data)
      : createContactDetailsAction(data);

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(setCandidateUserAction());
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <p className="text-sm font-medium text-gray-dark">
        Please fill out the details for adding your address
      </p>

      <label
        htmlFor="address_line1"
        className={`mt-2 mb-1 block font-medium ${
          errors.address_line1 && "text-danger-main"
        }`}
      >
        Address Line 1
      </label>

      <input
        id="address_line1"
        className={`${errors.address_line1 ? "input-error" : "input"} w-full`}
        type="text"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        placeholder="Enter address line 1"
        {...register("address_line1", {
          maxLength: {
            value: 255,
            message: characterValidation({ limit: 255 }),
          },
        })}
      />

      {errors.address_line1 && (
        <p className="text-sm text-danger-dark">
          {errors.address_line1.message}
        </p>
      )}

      <label
        htmlFor="address_line2"
        className={`mt-2 mb-1 block font-medium ${
          errors.address_line2 && "text-danger-main"
        }`}
      >
        Address Line 2
      </label>

      <input
        id="address_line2"
        className={`${errors.address_line2 ? "input-error" : "input"} w-full`}
        type="text"
        placeholder="Enter address line 2"
        {...register("address_line2", {
          maxLength: {
            value: 255,
            message: characterValidation({ limit: 255 }),
          },
        })}
      />

      {errors.address_line2 && (
        <p className="text-sm text-danger-dark">
          {errors.address_line2.message}
        </p>
      )}

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="country"
            className={`required mt-2 mb-1 block font-medium ${
              errors.country && "text-danger-main"
            }`}
          >
            Country
          </label>

          <input
            id="country"
            className={`${
              errors.country ? "input-error" : "input"
            } w-full disabled:cursor-not-allowed disabled:text-gray-main`}
            type="text"
            disabled
            value="India"
            placeholder="Enter country"
            {...register("country", {
              maxLength: {
                value: 50,
                message: characterValidation({ limit: 50 }),
              },
            })}
          />

          {errors.country && (
            <p className="text-sm text-danger-dark">{errors.country.message}</p>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="state_select"
            className={`required mt-2 mb-1 block font-medium ${
              errors.state && "text-danger-main"
            }`}
          >
            State
          </label>
          <Controller
            control={control}
            name="state"
            rules={{
              validate: (state) => validateDropdowns({ field: state }),
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <AsyncPaginate
                isSearchable
                isClearable
                id="state_select"
                menuPlacement="auto"
                className="w-full"
                styles={reactSelectStyle(errors.state)}
                value={
                  field.value?.label || field.value?.name ? field.value : null
                }
                loadOptions={loadStateOptions}
                onChange={(value) => {
                  field.onChange(value);
                  setValue("city", null);
                  setValue("postal_code", null);
                  setCurrentCity(null);
                  setCurrentState(null);
                }}
                noOptionsMessage={() => "Not found"}
                debounceTimeout={1000}
                placeholder="Select state"
                getOptionValue={(option) => option.uuid}
                getOptionLabel={(option) => option.name}
                additional={{ page: 1 }}
                formatOptionLabel={(option, { context }) =>
                  handleOptionLabel(option, context)
                }
              />
            )}
          />

          {errors.state && (
            <p className="text-sm text-danger-dark">{errors.state.message}</p>
          )}
        </div>
      </div>

      <div className="mt-2 flex flex-col justify-between sm:flex-row">
        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="city-select"
            className={`required mt-2 mb-1 block font-medium ${
              errors.city && "text-danger-main"
            }`}
          >
            City
          </label>
          <Controller
            control={control}
            name="city"
            rules={{
              validate: (city) => validateDropdowns({ field: city }),
              required: requiredValidation(),
            }}
            render={({ field }) => (
              <AsyncPaginate
                isSearchable
                isClearable
                isDisabled={state?.name == null}
                id="city-select"
                menuPlacement="auto"
                className={`w-full ${state?.name == null && "bg-gray-lighter"}`}
                styles={reactSelectStyle(errors.city)}
                value={
                  field.value?.label || field.value?.name ? field.value : null
                }
                loadOptions={loadCityOptions}
                cacheUniqs={[state]}
                onChange={(value) => {
                  field.onChange(value);
                  setValue("postal_code", null);
                }}
                noOptionsMessage={() => "Not found"}
                debounceTimeout={1000}
                placeholder="Select City"
                getOptionValue={(option) => option.uuid}
                getOptionLabel={(option) => option.name || option.label}
                additional={{ page: 1 }}
                formatOptionLabel={(option, { context }) =>
                  handleOptionLabel(option, context)
                }
              />
            )}
          />
          {errors.city && (
            <p className="text-sm text-danger-dark">{errors.city.message}</p>
          )}
        </div>

        <div className="w-full flex-col sm:w-5/12">
          <label
            htmlFor="postal_code"
            className={`mt-4 mb-1 block font-medium ${
              errors.postal_code && "text-danger-main"
            }`}
          >
            Postal Code
          </label>

          <input
            id="postal_code"
            className={`${errors.postal_code ? "input-error" : "input"} w-full`}
            type="number"
            placeholder="Enter postal code"
            {...register("postal_code", {
              maxLength: {
                value: 6,
                message: "Please enter a valid postal code",
              },
              pattern: {
                value: /([1-9])\d{5}/i,
                message: "Please enter a valid postal code",
              },
            })}
          />

          {errors.postal_code && (
            <p className="text-sm text-danger-dark">
              {errors.postal_code.message}
            </p>
          )}
        </div>
      </div>

      <div className="mt-2 w-full flex-col sm:w-5/12">
        <label className="relative flex w-max cursor-pointer select-none items-center">
          <span className="text-md mr-3">Willing to relocate?</span>
          <input
            type="checkbox"
            data-testid="willing-to-relocate-checkbox"
            className="ml-4 h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
            {...register("willing_to_relocate")}
          />
          <span className="font-small absolute right-1.5  text-xs text-white">
            NO
          </span>
          <span className="absolute right-9 mr-0  text-xs font-medium text-white">
            YES
          </span>
          <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
        </label>
      </div>

      <div className="mt-6 mb-2 flex w-full">
        <Button
          title={setIsAdding ? "Save" : "Update"}
          testId="saveAddressBtn"
          type="primary"
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          {setIsAdding ? "Save" : "Update"}
        </Button>

        <Button
          title="Cancel"
          testId="cancelAddressBtn"
          type="secondary"
          size="sm"
          btnClassName="ml-2"
          onClick={() => setIsEditState(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

Form.defaultProps = {
  contactDetail: undefined,
};

Form.propTypes = {
  contactDetail: PropTypes.shape({
    id: PropTypes.number,
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    city_uuid: PropTypes.string,
    permanent_address: PropTypes.bool,
    state: PropTypes.string,
    state_uuid: PropTypes.string,
    postal_code: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};

export default Form;
