const codeEditorLanguage = (question) => {
  if (question.driver_codes[0]) {
    if (!question.coding_answer.coding_language_id) {
      return question.driver_codes[0];
    }

    const cc = question.driver_codes.find(
      (l) => l.coding_language_id === question.coding_answer.coding_language_id
    );

    return cc;
  }
  return {};
};

export default codeEditorLanguage;
