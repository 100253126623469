import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";

const CandidateEducation = ({ educationQualifications }) => {
  return (
    <>
      <div className="resume-section-heading">EDUCATION</div>
      {educationQualifications.map((educationQualification, index, list) => {
        return (
          <div
            className={classnames("resume-section-content", {
              "pb-4": index !== list.length - 1,
            })}
            style={{
              color: "rgb(100 100 100)",
              wordBreak: "break-word",
            }}
            key={educationQualification.id}
          >
            <div
              style={{
                fontSize: "1rem",
                lineHeight: "1.5rem",
                fontWeight: 500,
              }}
            >
              {educationQualification?.qualification_name}
            </div>
            <div
              className="resume-section-content"
              style={{
                color: "rgb(55 65 81)",
              }}
            >
              {educationQualification?.college?.name}
              {educationQualification?.college?.state_name
                ? ` | ${educationQualification?.college?.state_name}`
                : ""}
            </div>
            <div className="py-1">
              {educationQualification?.education_level_name}
              {educationQualification?.marks
                ? ` | ${educationQualification?.marks}%`
                : ""}
            </div>
            <div className="">
              {moment(educationQualification?.start_date).format("MMMM YYYY")} -{" "}
              {educationQualification?.is_pursuing
                ? "Pursuing"
                : moment(educationQualification?.end_date).format("MMMM YYYY")}
            </div>
          </div>
        );
      })}
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

CandidateEducation.propTypes = {
  educationQualifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      college: PropTypes.object,
      qualification_id: PropTypes.number,
      qualification_name: PropTypes.string,
      branch_id: PropTypes.number,
      branch_name: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      is_pursuing: PropTypes.bool,
      education_level_name: PropTypes.string,
      marks: PropTypes.string,
    })
  ),
};

export default CandidateEducation;
