import { useMemo, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsBell, BsPencilSquare } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdNotificationsActive } from "react-icons/md";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { format, isBefore, parseISO } from "date-fns";
import PropTypes from "prop-types";

import { notifyGroupDiscussion } from "api/erp/jobApi";
import { GD_CARD_COUNT } from "app/constants";
import { Button } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import { GD_MEETING_MODES } from "constants/groupDiscussion";
import useAlert from "hooks/useAlert";

const GroupDiscussionCard = ({
  currentStageId,
  statusCounts,
  isLoading,
  stageGroupDiscussionDetails,
  isUserRoleAllowed,
  isJobClosed,
  getGroupDiscussionData,
  setSelectedGroupDetails,
}) => {
  const params = useParams();
  const showAlert = useAlert();
  const [viewMore, setViewMore] = useState(false);
  const [showCount, setShowCount] = useState(GD_CARD_COUNT);

  const handleViewMore = () => {
    setViewMore(!viewMore);
    setShowCount(stageGroupDiscussionDetails.length);
  };

  const handleViewLess = () => {
    setViewMore(!viewMore);
    setShowCount(GD_CARD_COUNT);
  };

  const calculateAssignedCandidates = useMemo(() => {
    return new Set(
      stageGroupDiscussionDetails.reduce(
        (assignedCandidateJobIds, gd) => [
          ...assignedCandidateJobIds,
          ...gd.group_candidates.map((gc) => gc.candidate_job_id),
        ],
        []
      )
    ).size;
  }, [stageGroupDiscussionDetails]);

  const notifyModerator = (stageGroupDiscussionDetail) => {
    if (stageGroupDiscussionDetail.link || stageGroupDiscussionDetail.address) {
      notifyGroupDiscussion(
        params.uuid,
        currentStageId,
        stageGroupDiscussionDetail.uuid
      )
        .then(() => {
          showAlert("success", "Notification mail sent successfully.");
          getGroupDiscussionData();
        })
        .catch((errors) => {
          if (errors) {
            showAlert("danger", errors.response.data.message);
          } else {
            showAlert("danger", "Try again.");
          }
        });
    } else {
      showAlert(
        "danger",
        "Please set a meeting mode to use the notification feature."
      );
    }
  };

  return (
    <div className="mb-5 mt-2 h-full w-full rounded-lg border border-sky-200 bg-white p-1 shadow-lg md:w-[49%]">
      {isLoading ? (
        <div className="my-8">
          <ActivityIndicator
            containerClass="mt-2"
            svgClassName="w-100%"
            size={12}
          />
        </div>
      ) : (
        <div>
          <div className="flex justify-between">
            <div className="mx-1">
              <p className="text-primary-main font-medium">Groups</p>
              <p className="text-sm">
                Assigned: {calculateAssignedCandidates} |&nbsp;
                <span
                  className={classNames({
                    "text-danger-main":
                      statusCounts.total_entries - calculateAssignedCandidates >
                      0,
                  })}
                >
                  Left:&nbsp;
                  {statusCounts.total_entries - calculateAssignedCandidates}
                </span>
              </p>
            </div>
            <button
              title="Add Group"
              onClick={() => {
                setSelectedGroupDetails({});
              }}
              className="z-10 items-center p-2"
            >
              <AiOutlinePlus className="text-2xl text-primary-main hover:text-primary-dark" />
            </button>
          </div>

          {stageGroupDiscussionDetails
            ?.slice(0, showCount)
            .map((stageGroupDiscussionDetail) => {
              return (
                <div
                  className="bg-white"
                  key={stageGroupDiscussionDetail.id}
                  data-testid={`question-id-${stageGroupDiscussionDetail.id}`}
                >
                  <div className="border-t py-2 border-gray-lighter">
                    <div className="flex justify-between pl-1 pr-3">
                      <div className="w-5/6">
                        <p className="text-sm text-gray-500">
                          Group {stageGroupDiscussionDetail.serialNo}{" "}
                          {stageGroupDiscussionDetail.title && (
                            <span>- {stageGroupDiscussionDetail.title} </span>
                          )}
                          {stageGroupDiscussionDetail.start_at && (
                            <span>
                              {" "}
                              | Start Time:&nbsp;
                              {format(
                                new Date(stageGroupDiscussionDetail.start_at),
                                "dd/MM/yyyy"
                              )}
                              ,&nbsp;
                              {format(
                                new Date(stageGroupDiscussionDetail.start_at),
                                "h:mm a"
                              )}{" "}
                              | Duration:&nbsp;
                              {stageGroupDiscussionDetail.duration} mins
                            </span>
                          )}
                        </p>
                      </div>

                      {isUserRoleAllowed && !isJobClosed && (
                        <div className="w-1/6 flex justify-end">
                          {stageGroupDiscussionDetail?.is_notified ? (
                            <MdNotificationsActive
                              role="button"
                              onClick={() =>
                                showAlert(
                                  "info",
                                  "Notification mail already sent!"
                                )
                              }
                              className="text-lg"
                              title="Notified"
                            />
                          ) : (
                            stageGroupDiscussionDetail?.start_at && (
                              <BsBell
                                role="button"
                                title="Send Notification"
                                data-testid="notifyBell"
                                onClick={() =>
                                  notifyModerator(stageGroupDiscussionDetail)
                                }
                              />
                            )
                          )}

                          <BsPencilSquare
                            title="Edit Group Discussion"
                            role="button"
                            onClick={() => {
                              if (
                                !stageGroupDiscussionDetail.start_at ||
                                stageGroupDiscussionDetail.start_at >
                                  new Date().toISOString()
                              ) {
                                setSelectedGroupDetails(
                                  stageGroupDiscussionDetail
                                );
                              } else {
                                showAlert(
                                  "danger",
                                  "You can not edit a group discussion which has already started."
                                );
                              }
                            }}
                            data-testid="edit-group-btn"
                            className={classNames(
                              "ml-2 text-base text-primary-main hover:cursor-pointer",
                              {
                                "opacity-40": isBefore(
                                  parseISO(stageGroupDiscussionDetail.start_at),
                                  new Date()
                                ),
                              }
                            )}
                            data-tooltip-id="editGroup"
                          />
                        </div>
                      )}
                    </div>

                    <div className="px-1">
                      {stageGroupDiscussionDetail.mode && (
                        <p className="text-sm text-gray-500">
                          Mode:&nbsp;
                          {
                            GD_MEETING_MODES.find(
                              (mode) =>
                                mode.value === stageGroupDiscussionDetail.mode
                            ).label
                          }
                        </p>
                      )}

                      <p className="text-sm text-gray-500 truncate">
                        <span>
                          Candidates:&nbsp;
                          {stageGroupDiscussionDetail.total_candidates}{" "}
                        </span>

                        {stageGroupDiscussionDetail.group_moderators.length >
                          0 && (
                          <span className="text-ellipsis">
                            | Moderators:&nbsp;
                            {stageGroupDiscussionDetail.group_moderators
                              .map((moderator) => moderator.name)
                              .join(", ")}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {stageGroupDiscussionDetails.length > 3 && (
        <div className="flex flex-row justify-end text-xs pr-3">
          {!viewMore ? (
            <Button
              onClick={handleViewMore}
              type="tertiary"
              btnClassName="flex flex-row"
              textTransform="normalcase"
            >
              <IoIosArrowDown className="mt-[2px]" /> view more
            </Button>
          ) : (
            <Button
              onClick={handleViewLess}
              type="tertiary"
              btnClassName="flex flex-row"
              textTransform="normalcase"
            >
              <IoIosArrowUp className="mt-[2px]" /> view less
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

GroupDiscussionCard.defaultProps = {
  setSelectedGroupDetails: () => {},
  getGroupDiscussionData: () => {},
  // setShowGDModal: () => {},
};

GroupDiscussionCard.propTypes = {
  currentStageId: PropTypes.number,
  stageGroupDiscussionDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  stageGroupDiscussionDetail: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    total_candidates: PropTypes.number,
    start_at: PropTypes.string,
    duration: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
  statusCounts: PropTypes.shape({
    total_entries: PropTypes.number,
  }),
  setSelectedGroupDetails: PropTypes.func,
  getGroupDiscussionData: PropTypes.func,
};

export default GroupDiscussionCard;
