import { useEffect, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { FcOk } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Modal } from "components/molecules";
import { JOB_VISIBILITY } from "constants/jobs";

import Education from "../Profile/Education";
import Skills from "../Profile/Skills";

const ConfirmApplyModal = ({
  isProfileCompleted,
  handleApplyJob,
  setShowModal,
  jobUuid,
  setShowConfirmApplyModal,
  setIsProfileCompleted,
  visibility,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.auth);

  const [isProceedToPrimaryModal, setIsProceedToPrimaryModal] = useState(false);
  const [showSecondaryApplyModal, setShowSecondaryApplyModal] = useState(false);
  const [showPrimaryApplyModal, setShowPrimaryApplyModal] = useState(false);

  useEffect(() => {
    if (visibility == JOB_VISIBILITY.public || isProceedToPrimaryModal) {
      setShowPrimaryApplyModal(true);
      setShowSecondaryApplyModal(false);
    } else if (
      visibility == JOB_VISIBILITY.restricted &&
      !currentUser.data.is_profile_completed
    ) {
      setShowSecondaryApplyModal(true);
    } else {
      setShowPrimaryApplyModal(true);
    }
  }, [isProceedToPrimaryModal]);

  const modalHeader = () => {
    if (currentUser.data.is_profile_completed) {
      return (
        <span className="flex items-center gap-2">
          <FcOk className="text-[29px]" />
          Success: Profile complete
        </span>
      );
    }
    return (
      <span className="flex items-center gap-2">
        <AiOutlineWarning className="text-[30px] text-danger-main" />
        Error: Profile incomplete
      </span>
    );
  };

  const handleReject = () => {
    if (isProfileCompleted) {
      navigate("/profile");
    } else {
      setShowConfirmApplyModal(false);
    }
  };

  // this has been done for new requirement - if Restricted user didn't complete profile then we will show the skill and education in modal and go previous way.
  // in public job any candidate can apply without filling their skill and education
  // if we want to enable previous logic remove useEffect function just add condition **isProfileCompleted || (currentUser.is_profile_completed && isProceed) **
  // in return function and based on ternary condition we will show different modal.

  return (
    <>
      {showPrimaryApplyModal && (
        <Modal
          title="Confirm Application"
          scrollClass="overflow-y-auto max-h-screen"
          acceptBtnType="primary"
          acceptButtonText="Apply now"
          rejectButtonText="Edit profile"
          rejectBtnType="secondary"
          testId="confirm-job-apply-modal"
          customButtonWidth={32}
          handleAccept={handleApplyJob}
          handleReject={() => {
            setShowModal(false);
            navigate(`/jobs/${jobUuid}/apply`);
          }}
        >
          <span>
            Your profile will be shared with the employer once you apply for
            this job.
          </span>
        </Modal>
      )}
      {showSecondaryApplyModal && (
        <Modal
          title={modalHeader()}
          scrollClass="overflow-y-auto apply-modal-height"
          acceptBtnType={
            currentUser.data.is_profile_completed ? "primary" : "disabled"
          }
          rejectBtnType="secondary"
          acceptButtonText="Proceed"
          rejectButtonText={isProfileCompleted ? "Edit profile" : "Not Now"}
          handleAccept={() => {
            setIsProfileCompleted(true);
            setIsProceedToPrimaryModal(true);
          }}
          handleReject={handleReject}
          handleCancel={() => setShowConfirmApplyModal(false)}
          customModalWidth="600px"
          isAsyncClick={false}
        >
          <Skills />
          <Education />
        </Modal>
      )}
    </>
  );
};

ConfirmApplyModal.propTypes = {
  isProfileCompleted: PropTypes.bool,
  handleApplyJob: PropTypes.func,
  setShowModal: PropTypes.func,
  setShowConfirmApplyModal: PropTypes.func,
  setIsProfileCompleted: PropTypes.func,
  visibility: PropTypes.string,
  jobUuid: PropTypes.string,
};

export default ConfirmApplyModal;
