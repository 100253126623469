import PropTypes from "prop-types";

import { QUESTION_TYPE } from "app/constants";

import CodingAnswerScript from "./CodingAnswerScript";
import McqAnswerScript from "./McqAnswerScript";

const McqCodingCandidateAnswer = ({
  isMockTest,
  candidateAnswers,
  getAnswers,
}) => {
  return (
    <div>
      <ul>
        {candidateAnswers?.map((candidateAnswer, index) =>
          candidateAnswer.question.question_type == QUESTION_TYPE[0].name ? (
            <div
              key={candidateAnswer.question.id}
              className="my-2 rounded-xl border-2 bg-white"
            >
              <McqAnswerScript
                isMockTest={isMockTest}
                candidateAnswer={candidateAnswer}
                index={index}
              />
            </div>
          ) : (
            <div
              key={candidateAnswer.question.id}
              className="mt-2 border-2 p-4"
            >
              <CodingAnswerScript
                isMockTest={isMockTest}
                candidateAnswer={candidateAnswer}
                getAnswers={getAnswers}
                index={index}
              />
            </div>
          )
        )}
      </ul>
    </div>
  );
};

McqCodingCandidateAnswer.propTypes = {
  isMockTest: PropTypes.bool,
  candidateAnswers: PropTypes.arrayOf(PropTypes.shape({})),
  getAnswers: PropTypes.func,
};

export default McqCodingCandidateAnswer;
