import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import GenericErrorPage from "components/common/GenericError";

const FallbackRender = ({ resetErrorBoundary }) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    resetErrorBoundary();
    navigate("/");
  };

  return (
    <GenericErrorPage
      homePageClickHandler={clickHandler}
      resetErrorBoundary={resetErrorBoundary}
    />
  );
};

FallbackRender.defaultProps = {
  resetErrorBoundary: () => null,
};

FallbackRender.propTypes = {
  resetErrorBoundary: PropTypes.func,
};

export default FallbackRender;
