import { useState } from "react";
import { BsBell, BsPencilSquare, BsTrash } from "react-icons/bs";
import { MdNotificationsActive } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";

import { deleteInterview, notifyInterview } from "api/erp/organizationApi";
import { Modal } from "components/molecules";
import useAlert from "hooks/useAlert";

const InterviewActions = ({
  interview,
  currentStageId,
  candidateName,
  setShowScheduleInterviewModal,
  setInterview,
  setCandidateID,
  getCandidateStageData,
  isUserRoleAllowed,
  isJobClosed,
}) => {
  const [showCancelInterviewModal, setShowCancelInterviewModal] =
    useState(false);

  const showAlert = useAlert();
  const params = useParams();

  const cancelInterview = () =>
    deleteInterview(
      params.uuid,
      currentStageId,
      interview.candidate_id,
      interview.id
    )
      .then(() => {
        getCandidateStageData();
        showAlert("success", "Interview cancelled successfully!");
        setShowCancelInterviewModal(false);
      })
      .catch((errors) => {
        // todo: when all BE will be changed to new format this extra checking will be deleted
        if (errors.response.data.meta) {
          showAlert("danger", errors.response.data.meta.message);
        } else {
          showAlert("danger", errors.response.data.detail);
        }
      });

  const notifyUser = (notifyInterviewData) => {
    if (notifyInterviewData.link) {
      notifyInterview(
        params.uuid,
        currentStageId,
        notifyInterviewData.candidate_id,
        notifyInterviewData.id
      )
        .then(() => {
          showAlert("success", "Notification mail sent successfully.");
          getCandidateStageData();
        })
        .catch((errors) => {
          if (errors) {
            showAlert("danger", errors.response.data.message);
          } else {
            showAlert("danger", "Try again.");
          }
        });
    } else {
      showAlert(
        "danger",
        "Please paste a meeting link to use the notification feature."
      );
    }
  };

  return (
    <div className="">
      <div className="pb-2" key={interview?.id}>
        <span className="block break-all pb-1">{interview?.title}</span>

        {isUserRoleAllowed && interview && !isJobClosed && (
          <div className="flex flex-row space-x-3 text-base">
            {interview?.is_notified ? (
              <MdNotificationsActive
                role="button"
                onClick={() =>
                  showAlert("info", "Notification mail already sent!")
                }
                className="text-lg"
                title="Notified"
              />
            ) : (
              <BsBell
                role="button"
                title="Send Notification"
                data-testid="notifyBell"
                onClick={() => notifyUser(interview)}
              />
            )}

            <BsPencilSquare
              onClick={() => {
                if (interview?.start_at > new Date().toISOString()) {
                  setInterview(interview);
                  setCandidateID(interview.candidate_id);
                  setShowScheduleInterviewModal(true);
                } else {
                  showAlert(
                    "danger",
                    "You can not edit an interview which has already started."
                  );
                }
              }}
              className={classNames("cursor-pointer", {
                "text-slate-400 font-thin":
                  interview?.start_at <= new Date().toISOString(),
                "hover:cursor-not-allowed":
                  interview?.start_at <= new Date().toISOString(),
              })}
              data-testid="editInterview"
              data-tooltip-content={`${
                interview?.start_at > new Date().toISOString()
                  ? ""
                  : "You can not edit an interview which has already started."
              }`}
              data-tooltip-id="interviewEdit"
            />

            <BsTrash
              className="hover:cursor-pointer"
              onClick={() => {
                setShowCancelInterviewModal(true);
              }}
              data-testid="deleteInterview"
              title="Cancel Interview"
            />
          </div>
        )}

        <Tooltip id="interviewEdit" variant="dark" className="z-10" />
      </div>

      {showCancelInterviewModal && (
        <Modal
          title="Are you sure?"
          acceptButtonText="Yes"
          rejectButtonText="No"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          handleAccept={cancelInterview}
          handleReject={() => {
            setShowCancelInterviewModal(false);
          }}
        >
          <span className="normal-case">
            Are you sure you want to cancel{" "}
            <span className="italic">{interview?.title}</span> for{" "}
            <span className="italic">{candidateName}</span> ?
          </span>
        </Modal>
      )}
    </div>
  );
};

InterviewActions.propTypes = {
  interview: PropTypes.shape({
    candidate_id: PropTypes.number,
    link: PropTypes.string,
    title: PropTypes.string,
    start_at: PropTypes.string,
    is_notified: PropTypes.bool,
    id: PropTypes.number,
  }),
  candidateName: PropTypes.string,
  setShowScheduleInterviewModal: PropTypes.func,
  setInterview: PropTypes.func,
  setCandidateID: PropTypes.func,
  getCandidateStageData: PropTypes.func,
  currentStageId: PropTypes.number,
  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
};

export default InterviewActions;
