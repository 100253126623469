import React from "react";
import { useLocation } from "react-router-dom";

import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "app/constants";

const Footer = () => {
  const { pathname } = useLocation();

  if (pathname === "/question_paper") {
    return null;
  }

  return (
    <footer className="w-full bg-gray-lighterShade3 p-2 text-center">
      <div className="container mx-auto flex flex-col justify-center gap-6 text-xs sm:flex-row ">
        <div className="flex justify-center gap-6">
          <a
            title="Terms of Service"
            target="_blank"
            rel="noreferrer"
            href={TERMS_OF_SERVICE}
            className="text-primary-main hover:underline"
          >
            Terms of Service
          </a>
          <a
            title="Privacy Policy"
            target="_blank"
            rel="noreferrer"
            href={PRIVACY_POLICY}
            className="text-primary-main hover:underline"
          >
            Privacy Policy
          </a>
        </div>
        <p className="text-xs text-gray-main">
          Copyright &copy; {new Date().getFullYear()} JobAlley. All Rights
          Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
