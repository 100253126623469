import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { BsGlobe2 } from "react-icons/bs";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import Creatable from "react-select/creatable";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";

import {
  getEmployeeRange,
  getIndustryName,
  getOrganisationDetails,
  getOrganisationType,
  updateOrganisationDetails,
} from "api/erp/organizationApi";
import {
  ALERT_TYPE,
  EMAIL_REGEX,
  ICON_SIZE_HASH,
  WEBSITE_REGEX,
} from "app/constants";
import { Avatar, Button, LabeledInput } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import FormattedContactNumber from "components/atoms/FormattedContactNumber";
import IconInput from "components/atoms/IconInput";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import SocialMediaIcon from "components/atoms/SocialMediaIcon";
import ActivityIndicator from "components/common/ActivityIndicator";
import {
  LOGO_SIZE,
  LOGO_TYPE,
  LOGO_UPLOAD_FAILED,
  SITE_TITLE,
  USER_ROLES,
} from "constants/common";
import { formatPhoneNumber } from "helpers/formatPhoneNumber";
import {
  characterValidation,
  formattedPhoneNumberValidation,
  requiredValidation,
  validFormatValidation,
} from "helpers/genericErrorMessages";
import phoneNumberFormatter from "helpers/phoneNumberFormatter";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";
import useCheckUserRole from "hooks/useCheckUserRole";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { setOrganisationUserAction } from "store/thunkActions/currentUserThunk";

const AsyncPaginateCreatable = withAsyncPaginate(Creatable);

const Profile = () => {
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState();
  const [isEditState, setEditState] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const isUserRoleAllowed = useCheckUserRole([USER_ROLES.ADMIN]);

  const showAlert = useAlert();
  const inputFile = useRef(null);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty, errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
  });

  const getOrganisation = () => {
    getOrganisationDetails().then((response) => {
      setOrganisation(response.data);
    });
  };

  const loadOrganisationTypeOptions = async () => {
    const { data } = await getOrganisationType();
    return {
      options: data,
    };
  };

  const loadEmployeeRangeOptions = async () => {
    const { data } = await getEmployeeRange();
    return {
      options: data,
    };
  };

  const loadIndustryNameOptions = async (search, _, { page }) => {
    const { data, meta } = await getIndustryName(page, search);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitOrganisationHandler = (data) => {
    // Restrict API call when no changes
    if (data.about === organisation.about && !isDirty) {
      return setEditState(false);
    }
    // Structuring req body
    data.organisation_type_id = data.organisation_type?.id;
    data.industry_id = data.industry.id;
    data.industry_name = data.industry?.name;
    data.employee_range_id = data.employee_range?.id;
    data.about = data?.about?.trim();

    setIsUpdating(true);
    return updateOrganisationDetails(data)
      .then(() => {
        getOrganisation();
        setIsUpdating(false);
        showAlert("success", "Profile has been updated successfully!");
        setEditState(false);
        window.scroll(0, 0);
        dispatch(setOrganisationUserAction());
      })
      .catch((errors) => {
        setIsUpdating(false);
        setErrorsOnFields(errors.response.data.errors);
      });
  };

  const handleFileUpload = (e) => {
    const { files } = e.target;
    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    if (files && files.length) {
      if (!files[0].type.match(imageMimeType)) {
        showAlert(ALERT_TYPE.DANGER, LOGO_TYPE);
        inputFile.current.value = null;
      } else if (files[0].size > 5000000) {
        showAlert(ALERT_TYPE.DANGER, LOGO_SIZE);
        inputFile.current.value = null;
      } else {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("logo", files[0]);
        updateOrganisationDetails(formData)
          .then(({ meta }) => {
            setIsUploading(false);
            getOrganisation();
            showAlert(ALERT_TYPE[meta.message_type], meta.message);
            setEditState(false);
          })
          .catch(() => {
            setIsUploading(false);
            showAlert(ALERT_TYPE.DANGER, LOGO_UPLOAD_FAILED);
          });
      }
    }
  };

  const inputErrClass = (error) => {
    return error ? "input-error" : "input";
  };
  const onProfileImageButtonClick = () => {
    inputFile.current.click();
  };

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: { currentPageName: "Company Profile" },
      })
    );
  }, []);

  useEffect(() => {
    getOrganisation();
  }, []);

  useEffect(() => {
    reset({
      ...organisation,
      organisation_type: {
        id: organisation?.organisation_type_id,
        name: organisation?.organisation_type,
      },
      industry: {
        id: organisation?.industry_id,
        name: organisation?.industry_name,
      },
      employee_range: {
        id: organisation?.employee_range_id,
        range: organisation?.employee_range,
      },
      phone: formatPhoneNumber(organisation?.phone),
      alt_phone: formatPhoneNumber(organisation?.alt_phone),
    });
  }, [organisation, reset]);

  if (!organisation) {
    return <ActivityIndicator />;
  }

  return (
    <div className="p-4">
      <Helmet>
        <title>{`Company Profile - ${SITE_TITLE}`}</title>
      </Helmet>
      <form
        onSubmit={handleSubmit((data) =>
          submitOrganisationHandler({
            ...data,
            phone: data?.phone.replace("-", ""),
            alt_phone: data?.alt_phone.replace("-", ""),
          })
        )}
      >
        <div className="flex flex-col md:flex-row border-solid border-b-2 border-primary-lighter mx-2 pb-5">
          {!isEditState && (
            <div
              className="flex justify-center items-center bg:to-primary-light text-[63px] font-medium
              text-primary-main m-2"
            >
              <input
                style={{ display: "none" }}
                accept=".jpg, .jpeg, .png"
                ref={inputFile}
                onChange={handleFileUpload}
                type="file"
                data-testid="logo-input-file"
              />

              {organisation.logo?.thumb ? (
                <div className="flex flex-col self-start">
                  <div className="rounded-full h-32 w-32 ring-2 ring-primary-dark">
                    {isUploading ? (
                      <ActivityIndicator containerClass="mt-5" />
                    ) : (
                      <Avatar
                        src={organisation.logo?.thumb}
                        height={32}
                        width={32}
                        optionalClass="border -2"
                      />
                    )}
                  </div>
                  {!isUploading && isUserRoleAllowed && (
                    <button
                      title="Upload Profile Image"
                      onClick={() => onProfileImageButtonClick()}
                      className="flex items-center bg-gray-lighterShade3 h-8 w-8 px-1 border-2 border-primary-dark rounded-full absolute mt-20 ml-[100px] hover:bg-primary-lightest"
                    >
                      <MdOutlineModeEditOutline
                        size={ICON_SIZE_HASH.md}
                        className="text-primary-main"
                      />
                    </button>
                  )}
                </div>
              ) : (
                <div className="flex flex-col self-start ring-2 ring-primary-dark rounded-full ring-offset-4 w-28 h-28 bg-primary-lightest ml-3">
                  {isUploading ? (
                    <ActivityIndicator containerClass="mt-5" />
                  ) : (
                    <CharacterAvatar
                      char={organisation.name?.split(" ")[0][0]}
                      width={28}
                      height={28}
                    />
                  )}
                  {!isUploading && isUserRoleAllowed && (
                    <button
                      title="Upload Profile Image"
                      onClick={() => onProfileImageButtonClick()}
                      className="flex items-center bg-gray-lighterShade3 h-8 w-8 px-1 border-2 border-primary-dark rounded-full absolute mt-20 ml-20 hover:bg-primary-lightest"
                    >
                      <MdOutlineModeEditOutline
                        size={ICON_SIZE_HASH.md}
                        className="text-primary-main"
                      />
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          <div
            className={`flex-grow mt-4 md:w-5/12 md:mt-0 ${
              isEditState ? "md:ml-36" : "md:ml-8"
            }`}
          >
            {isEditState ? (
              <LabeledInput
                containerClassNames="sm:w-5/6 pb-3"
                errorMessage={errors.name?.message}
                id="organisation-name"
                inputClassNames={classNames(
                  "w-full",
                  inputErrClass(errors.name)
                )}
                label="Organisation Name"
                labelClassNames={classNames(
                  "block mt-2 mb-1 font-medium required",
                  { "text-danger-main": errors.name }
                )}
                name="name"
                placeholder="Enter organisation name"
                register={register}
                type="text"
                validation={{
                  required: requiredValidation(),
                  maxLength: {
                    value: 255,
                    message: characterValidation({ limit: 255 }),
                  },
                }}
              />
            ) : (
              <div className="flex justify-between">
                <h3 className="flex items-center text-3xl font-normal">
                  {organisation.name}
                </h3>

                {isUserRoleAllowed && (
                  <button
                    title="Edit Details"
                    data-testid="editOrganisationBtn"
                    className="btn-icon-gray mr-3"
                    onClick={() => setEditState((editable) => !editable)}
                  >
                    <MdOutlineModeEditOutline className="text-lg" />
                  </button>
                )}
              </div>
            )}
            <div
              className={classNames({
                "flex flex-row items-center": !isEditState,
              })}
            >
              {isEditState ? (
                <>
                  <div
                    className={classNames(
                      "block mt-2 mb-1 font-medium required",
                      {
                        "text-danger-main": errors.email,
                      }
                    )}
                  >
                    Organisation Email
                  </div>
                  <div className="flex flex-col sm:w-5/6 pb-3">
                    <IconInput
                      error={errors.email}
                      disabled
                      placeholder="Enter organisation email"
                      icon={FaEnvelope}
                      name="email"
                      register={register}
                      size={ICON_SIZE_HASH.smd}
                      validation={{
                        required: requiredValidation(),
                        maxLength: {
                          value: 255,
                          message: characterValidation({ limit: 255 }),
                        },
                        pattern: {
                          value: EMAIL_REGEX,
                          message: validFormatValidation({
                            data: "email",
                          }),
                        },
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="mt-2 mb-1">
                  <a
                    title="Email"
                    target="_blank"
                    href={`mailto:${organisation.email}`}
                    className={classNames("text-sm text-gray-dark italic", {
                      "pointer-events-none": !organisation.email,
                    })}
                    rel="noreferrer"
                  >
                    <FaEnvelope
                      className="inline mr-2 mb-1"
                      size={ICON_SIZE_HASH.sm}
                    />
                  </a>
                </div>
              )}

              {isEditState ? (
                <>
                  <div
                    className={classNames("block mt-2 mb-1 font-medium ", {
                      "text-danger-main": errors.website,
                    })}
                  >
                    Website Link
                  </div>
                  <div className="flex flex-col sm:w-5/6 pb-3">
                    <IconInput
                      error={errors.website}
                      placeholder="https://www.example.com"
                      icon={BsGlobe2}
                      name="website"
                      register={register}
                      size={ICON_SIZE_HASH.md}
                      validation={{
                        maxLength: {
                          value: 255,
                          message: characterValidation({ limit: 255 }),
                        },
                        pattern: {
                          value: WEBSITE_REGEX,
                          message: validFormatValidation({
                            data: "website",
                          }),
                        },
                      }}
                    />
                  </div>
                </>
              ) : (
                organisation.website && (
                  <div className="mt-2 mb-1">
                    <a
                      title="Website"
                      target="_blank"
                      href={organisation.website || "#"}
                      className={classNames("text-sm text-gray-dark italic", {
                        "pointer-events-none": !organisation.website,
                      })}
                      rel="noreferrer"
                    >
                      <BsGlobe2
                        className="inline mr-2 mb-1"
                        size={ICON_SIZE_HASH.sm}
                      />
                    </a>
                  </div>
                )
              )}

              <div
                className={classNames("flex sm:flex-row", {
                  "sm:gap-20 sm:justify-between mb-5 sm:w-5/6 mt-3 flex-col":
                    isEditState,
                })}
              >
                {isEditState ? (
                  <div className="flex flex-col sm:w-1/2 mt-3">
                    <IconInput
                      error={errors.linkedin_url}
                      placeholder="Enter Linkedin URL"
                      icon={FaLinkedin}
                      name="linkedin_url"
                      register={register}
                      size={ICON_SIZE_HASH.md}
                      validation={{
                        maxLength: {
                          value: 255,
                          message: characterValidation({ limit: 255 }),
                        },
                        pattern: {
                          value: WEBSITE_REGEX,
                          message: validFormatValidation({
                            data: "website",
                          }),
                        },
                      }}
                    />
                  </div>
                ) : (
                  organisation.linkedin_url && (
                    <SocialMediaIcon
                      title="Linkedin"
                      link={organisation.linkedin_url}
                      icon={FaLinkedin}
                    />
                  )
                )}
                {isEditState ? (
                  <div className="flex flex-col sm:w-1/2 mt-3">
                    <IconInput
                      error={errors.twitter_url}
                      placeholder="Enter Twitter URL"
                      icon={FaTwitter}
                      name="twitter_url"
                      register={register}
                      size={ICON_SIZE_HASH.md}
                      validation={{
                        maxLength: {
                          value: 255,
                          message: characterValidation({ limit: 255 }),
                        },
                        pattern: {
                          value: WEBSITE_REGEX,
                          message: validFormatValidation({
                            data: "website",
                          }),
                        },
                      }}
                    />
                  </div>
                ) : (
                  organisation.twitter_url && (
                    <SocialMediaIcon
                      title="Twitter"
                      link={organisation.twitter_url}
                      icon={FaTwitter}
                    />
                  )
                )}
              </div>
              <div
                className={classNames("flex sm:flex-row", {
                  "sm:gap-20 sm:justify-between mb-5 sm:w-5/6 flex-col":
                    isEditState,
                })}
              >
                {isEditState ? (
                  <div className="flex flex-col sm:w-1/2 mt-3">
                    <IconInput
                      error={errors.instagram_url}
                      placeholder="Enter Instagram URL"
                      icon={FaInstagram}
                      name="instagram_url"
                      register={register}
                      size={ICON_SIZE_HASH.md}
                      validation={{
                        maxLength: {
                          value: 255,
                          message: characterValidation({ limit: 255 }),
                        },
                        pattern: {
                          value: WEBSITE_REGEX,
                          message: validFormatValidation({
                            data: "website",
                          }),
                        },
                      }}
                    />
                  </div>
                ) : (
                  organisation.instagram_url && (
                    <SocialMediaIcon
                      title="Instagram"
                      link={organisation.instagram_url}
                      icon={FaInstagram}
                    />
                  )
                )}
                {isEditState ? (
                  <div className="flex flex-col sm:w-1/2 mt-3">
                    <IconInput
                      error={errors.facebook_url}
                      placeholder="Enter Facebook URL"
                      icon={FaFacebook}
                      name="facebook_url"
                      register={register}
                      size={ICON_SIZE_HASH.md}
                      validation={{
                        maxLength: {
                          value: 255,
                          message: characterValidation({ limit: 255 }),
                        },
                        pattern: {
                          value: WEBSITE_REGEX,
                          message: validFormatValidation({
                            data: "website",
                          }),
                        },
                      }}
                    />
                  </div>
                ) : (
                  organisation.facebook_url && (
                    <SocialMediaIcon
                      title="Facebook"
                      link={organisation.facebook_url}
                      icon={FaFacebook}
                    />
                  )
                )}
              </div>
            </div>
            {isEditState ? (
              <>
                <Controller
                  name="about"
                  control={control}
                  defaultValue=""
                  rules={{
                    maxLength: {
                      value: 2000,
                      message: characterValidation({ limit: 2000 }),
                    },
                  }}
                  render={({ field }) => (
                    <RichTextEditor
                      id="about"
                      content={field.value}
                      onChange={(content) => field.onChange(content)}
                      placeholder="Write something about your organisation"
                      fieldError={!!errors.about?.message}
                      label="About Us"
                      containerClassNames="mt-2 sm:w-5/6"
                      labelClassNames={classNames(
                        "block mt-2 mb-1 font-medium",
                        {
                          "text-danger-main": errors.about,
                        }
                      )}
                    />
                  )}
                />
                {errors.about && (
                  <p className="mt-0 text-sm text-danger-dark">
                    {errors.about?.message}
                  </p>
                )}
              </>
            ) : (
              <div className="pt-2 sm:w-5/6">
                <h4 className="mt-2 mb-1 font-medium"> About Us </h4>

                <span className="text-gray-main">
                  <RenderParsedHTML content={organisation?.about || ""} />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="mt-1 md:mr-6 md:ml-36">
          <div className="flex flex-col sm:flex-row sm:justify-between mx-2 md:mb-8">
            <div className="sm:w-5/12">
              {isEditState ? (
                <div className="py-2">
                  <label
                    htmlFor="phone"
                    className={`required mt-2 mb-1 block font-medium ${
                      errors.phone && "text-danger-main"
                    }`}
                  >
                    Contact Number
                  </label>
                  <div className="flex">
                    <input
                      disabled
                      name="country-code"
                      type="text"
                      value="+91 "
                      className="w-12 input"
                    />
                    <input
                      id="phone"
                      className={`${
                        errors.phone ? "input-error" : "input"
                      } w-full`}
                      type="text"
                      placeholder="XXXX-XXXXXX"
                      {...register("phone", {
                        required: requiredValidation(),
                        maxLength: {
                          value: 11,
                          message: formattedPhoneNumberValidation(),
                        },
                        pattern: {
                          value: /^\d{4}-\d{6}$/i,
                          message: formattedPhoneNumberValidation(),
                        },
                      })}
                      onChange={(e) =>
                        phoneNumberFormatter(e, setValue, clearErrors)
                      }
                    />
                  </div>
                  {errors.phone && (
                    <p className="text-sm text-danger-dark">
                      {errors.phone.message}
                    </p>
                  )}
                </div>
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium"> Contact Number </h4>

                  <span className="text-gray-main">
                    {organisation?.phone && (
                      <span>
                        <FormattedContactNumber number={organisation.phone} />
                      </span>
                    )}
                  </span>
                </div>
              )}

              {isEditState ? (
                <div className="py-2">
                  <label
                    htmlFor="alt_phone"
                    className={`mt-2 mb-1 block font-medium ${
                      errors.alt_phone && "text-danger-main"
                    }`}
                  >
                    Alt. Contact Number
                  </label>
                  <div className="flex">
                    <input
                      disabled
                      name="country-code"
                      type="text"
                      value="+91 "
                      className="w-12 input"
                    />
                    <input
                      id="alt_phone"
                      className={`${
                        errors.alt_phone ? "input-error" : "input"
                      } w-full`}
                      type="text"
                      placeholder="XXXX-XXXXXX"
                      {...register("alt_phone", {
                        maxLength: {
                          value: 11,
                          message: formattedPhoneNumberValidation(),
                        },
                        pattern: {
                          value: /^\d{4}-\d{6}$/i,
                          message: formattedPhoneNumberValidation(),
                        },
                      })}
                      onChange={(e) =>
                        phoneNumberFormatter(
                          e,
                          setValue,
                          clearErrors,
                          "alt_phone"
                        )
                      }
                    />
                  </div>
                  {errors.alt_phone && (
                    <p className="text-sm text-danger-dark">
                      {errors.alt_phone.message}
                    </p>
                  )}
                </div>
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium">
                    {" "}
                    Alt. Contact Number{" "}
                  </h4>

                  <span className="text-gray-main">
                    {organisation?.alt_phone && (
                      <FormattedContactNumber number={organisation.alt_phone} />
                    )}
                  </span>
                </div>
              )}

              {isEditState ? (
                <div className="py-2">
                  <label
                    htmlFor="typeSelect"
                    className={classNames(
                      "block mt-2 mb-1 font-medium",
                      { required: isEditState },
                      { "text-danger-main": errors.organisation_type }
                    )}
                  >
                    Organisation Type
                  </label>

                  <Controller
                    control={control}
                    name="organisation_type"
                    render={({ field }) => (
                      <AsyncPaginateCreatable
                        isSearchable={false}
                        id="typeSelect"
                        menuPlacement="auto"
                        styles={reactSelectStyle(errors.organisation_type)}
                        value={field.value}
                        className="w-full"
                        loadOptions={loadOrganisationTypeOptions}
                        onChange={(value) => field.onChange(value)}
                        noOptionsMessage={() => null}
                        debounceTimeout={1000}
                        placeholder="Select organisation type"
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name || option.label}
                        formatCreateLabel={(name) => `Create "${name}"`}
                        additional={{ page: 1 }}
                      />
                    )}
                  />

                  {errors.organisation_type && (
                    <p className="text-sm text-danger-dark">
                      {errors.organisation_type.message}
                    </p>
                  )}
                </div>
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium">Organisation Type</h4>
                  <span className="text-gray-main">
                    {organisation?.organisation_type || ""}
                  </span>
                </div>
              )}

              {isEditState ? (
                <div className="py-2">
                  <label
                    htmlFor="employeeRangeSelect"
                    className={classNames(
                      "block mt-2 mb-1 font-medium",
                      { required: isEditState },
                      { "text-danger-main": errors.employee_range }
                    )}
                  >
                    Number of Employees
                  </label>

                  <Controller
                    control={control}
                    name="employee_range"
                    render={({ field }) => (
                      <AsyncPaginateCreatable
                        isSearchable={false}
                        id="employeeRangeSelect"
                        menuPlacement="auto"
                        styles={reactSelectStyle(errors.employee_range)}
                        value={field.value}
                        className="w-full"
                        loadOptions={loadEmployeeRangeOptions}
                        onChange={(value) => field.onChange(value)}
                        noOptionsMessage={() => null}
                        debounceTimeout={1000}
                        placeholder="Select number of employees"
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) =>
                          option.range || option.label
                        }
                        formatCreateLabel={(name) => `Create "${name}"`}
                        additional={{ page: 1 }}
                      />
                    )}
                  />

                  {errors.employee_range && (
                    <p className="text-sm text-danger-dark">
                      {errors.employee_range.message}
                    </p>
                  )}
                </div>
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium">Number of Employees</h4>

                  <span className="text-gray-main">
                    {organisation?.employee_range || ""}
                  </span>
                </div>
              )}
            </div>

            <div className="sm:w-5/12">
              {isEditState ? (
                <div className="py-2">
                  <label
                    htmlFor="industryNameSelect"
                    className={classNames(
                      "block mt-2 mb-1 font-medium",
                      { required: isEditState },
                      { "text-danger-main": errors.industry }
                    )}
                  >
                    Industry
                  </label>

                  <Controller
                    control={control}
                    name="industry"
                    render={({ field }) => (
                      <AsyncPaginate
                        id="industryNameSelect"
                        menuPlacement="auto"
                        styles={reactSelectStyle(errors.industry)}
                        value={field.value}
                        className="w-full"
                        loadOptions={loadIndustryNameOptions}
                        onChange={(value) => field.onChange(value)}
                        noOptionsMessage={() => "No options"}
                        debounceTimeout={1000}
                        placeholder="Select industry"
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name || option.label}
                        additional={{ page: 1 }}
                      />
                    )}
                  />

                  {errors.industry_name && (
                    <p className="text-sm text-danger-dark">
                      {errors.industry.message}
                    </p>
                  )}
                </div>
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium"> Industry </h4>

                  <span className="text-gray-main whitespace-pre-wrap">
                    {organisation?.industry_name || ""}
                  </span>
                </div>
              )}

              {isEditState ? (
                <LabeledInput
                  containerClassNames="py-2"
                  errorMessage={errors.registration_number?.message}
                  id="registration_number"
                  inputClassNames={classNames(
                    "w-full",
                    inputErrClass(errors.registration_number)
                  )}
                  label="Corporate Identification Number (CIN)"
                  labelClassNames={classNames("block mt-2 mb-1 font-medium", {
                    "text-danger-main": errors.registration_number,
                  })}
                  name="registration_number"
                  placeholder="Enter corporate identification number (CIN)"
                  register={register}
                  type="text"
                  validation={{
                    pattern: {
                      value: /^[A-Za-z0-9]{21}$/i,
                      message: "must be exactly 21 alphanumeric characters",
                    },
                  }}
                />
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium">
                    {" "}
                    Corporate Identification Number (CIN){" "}
                  </h4>

                  <span className="text-gray-main">
                    {organisation?.registration_number || ""}
                  </span>
                </div>
              )}

              {isEditState ? (
                <LabeledInput
                  containerClassNames="py-2"
                  errorMessage={errors.gstin?.message}
                  id="gstin"
                  inputClassNames={classNames(
                    "w-full",
                    inputErrClass(errors.gstin)
                  )}
                  label="GSTIN"
                  labelClassNames={classNames("block mt-2 mb-1 font-medium", {
                    "text-danger-main": errors.gstin,
                  })}
                  name="gstin"
                  placeholder="Enter GST number"
                  register={register}
                  type="text"
                  validation={{
                    pattern: {
                      value: /^[A-Za-z0-9]{15}$/i,
                      message: "must be exactly 15 alphanumeric characters", // TODO: If this message is used again then move it to genericErrorMessages file.
                    },
                  }}
                />
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium"> GSTIN </h4>

                  <span className="text-gray-main">
                    {organisation?.gstin || ""}
                  </span>
                </div>
              )}

              {isEditState ? (
                <div className="w-full py-2">
                  <p
                    className={`mb-1 block mt-2 font-medium ${
                      errors.custom_email && "text-danger-main"
                    }`}
                  >
                    Send Custom Email to Candidates
                  </p>
                  <label
                    htmlFor="custom_email"
                    className="relative w-max cursor-pointer select-none items-center"
                    data-testid="custom_email"
                  >
                    <input
                      defaultChecked={false}
                      name="custom_email"
                      id="custom_email"
                      data-testid="custom_email-toggle"
                      type="checkbox"
                      className="h-6 w-16 mt-1 cursor-pointer appearance-none rounded-full bg-gray-400"
                      {...register("custom_email")}
                    />
                    <span className="font-small absolute bottom-2.5 right-1.5 text-xs uppercase text-white">
                      No
                    </span>
                    <span className="absolute bottom-2.5 right-9 text-xs font-medium uppercase text-white">
                      Yes
                    </span>
                    <span className="mt-1 absolute bottom-2.5 right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
                  </label>
                </div>
              ) : (
                <div className="py-2">
                  <h4 className="mt-2 mb-1 font-medium">
                    Send Custom Email to Candidates
                  </h4>

                  <span className="text-gray-main">
                    {organisation?.custom_email ? "Yes" : "No"}
                  </span>
                </div>
              )}
            </div>
          </div>

          {isEditState && (
            <div className="mt-6 mb-2 flex w-full py-2">
              <Button
                title={isUpdating ? "Updating..." : "Update"}
                btnClassName="mr-3"
                size="md"
                type="primary"
                testId="saveOrganisationBtn"
                buttonType="submit"
                isLoading={isSubmitting}
              >
                {isUpdating ? "Updating..." : "Update"}
              </Button>

              <Button
                title="Cancel"
                testId="cancel-organisation-btn"
                type="secondary"
                size="md"
                onClick={() => {
                  window.scroll(0, 0);
                  setEditState(false);
                  getOrganisation();
                  clearErrors();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Profile;
