import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";

const Option = ({ options, selectedOption, onOptionChange }) => {
  return (
    <>
      {options.map((option) => (
        <div key={option.id} className="m-3 select-none">
          <div className="px-4">
            <label
              htmlFor={option.id}
              className="flex flex-col hover:cursor-pointer"
            >
              <div className="flex">
                <input
                  id={option.id}
                  type="radio"
                  value={option.id}
                  className="mr-3 mt-1 self-start"
                  checked={selectedOption == option.id}
                  onChange={onOptionChange}
                />
                <div className="mb-5 text-justify">
                  <RenderParsedHTML content={option.text || ""} />
                </div>
              </div>
              {option.attachment_file_name != null && (
                <img
                  src={option.attachment}
                  alt="Option"
                  className="ml-5 h-auto w-auto object-contain"
                />
              )}
            </label>
          </div>
        </div>
      ))}
    </>
  );
};

Option.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    })
  ),
  selectedOption: PropTypes.number,
  onOptionChange: PropTypes.func,
};
export default Option;
