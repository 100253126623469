import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ALERT_TYPE } from "app/constants";
import { Button, EmptyMessage } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import { Modal } from "components/molecules";
import useAlert from "hooks/useAlert";
import {
  deleteQuestionTagAction,
  getFilterQuestionTagAction,
} from "store/thunkActions/erp/questionTagThunk";

import QuestionTagModal from "./QuestionTagModal";

const QuestionTagIndex = ({ showQPImportAlert }) => {
  const dispatch = useDispatch();
  const showAlert = useAlert();

  const [isTagAdding, setIsTagAdding] = useState(false);
  const [isTagEditing, setIsTagEditing] = useState(false);
  const [isTagDeleting, setIsTagDeleting] = useState(false);
  const [tag, setTag] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useSelector((state) => state.questionTag);

  useEffect(() => {
    dispatch(
      getFilterQuestionTagAction({
        search_term: searchParams.get("search_term"),
      })
    );
  }, [searchParams]);

  const onTagDelete = () => {
    return dispatch(deleteQuestionTagAction(tag))
      .unwrap()
      .then(({ meta }) => {
        setIsTagDeleting(false);
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
      })
      .catch(({ meta, errors }) => {
        if (errors) {
          showAlert(ALERT_TYPE.DANGER, errors.question_tags[0]);
        } else {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
        }
        setIsTagDeleting(false);
      });
  };

  if (!data) {
    return <ActivityIndicator />;
  }

  return (
    <div>
      <div
        className={classNames(
          "py-4 sticky z-10 bg-gray-lighterShade3 flex flex-row gap-4 min-h-[50px] items-center justify-between",
          showQPImportAlert ? "top-[230px] sm:top-[175px]" : "top-[94px]"
        )}
      >
        <div>
          <div className="sm:max-w-[450px]">
            <SearchBar
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              searchBarPlaceholder="Search by question tag"
              defaultSearchParams={{
                tab: searchParams.get("tab"),
              }}
            />
          </div>
        </div>
        <button
          title="Create Question Tag"
          data-testid="add-tag-btn"
          className="z-10 items-center rounded-full bg-primary-main p-2 hover:bg-primary-dark"
          onClick={() => setIsTagAdding(true)}
        >
          <AiOutlinePlus className="text-2xl text-white" />
        </button>
        {isTagAdding && <QuestionTagModal setIsEditState={setIsTagAdding} />}
      </div>
      {data.length > 0 && (
        <table className="w-full table-auto border-collapse text-sm">
          <thead>
            <tr className="text-lg font-bold capitalize text-primary-main">
              <th className="py-3 pl-2 text-left">Tags</th>
            </tr>
          </thead>

          <tbody className="divide-y bg-white">
            {data.map((tag) => {
              return (
                <tr
                  key={tag.id}
                  data-testid={`tag-id-${tag.id}`}
                  className="flex flex-row justify-between px-2 py-4 pl-2 hover:bg-gray-lighter"
                >
                  <td data-testid="tag-name">{tag.name}</td>
                  <td className="flex flex-row justify-between space-x-8">
                    <Button
                      title="Edit"
                      testId="tag-edit-btn"
                      onClick={() => {
                        setTag(tag);
                        setIsTagEditing(true);
                      }}
                      btnClassName="p-2 ml-2 rounded-md items-center hover:bg-primary-lighter py-1"
                    >
                      <AiFillEdit className="text-xl text-primary-dark" />
                    </Button>

                    <Button
                      title="Delete"
                      testId="tag-delete-btn"
                      onClick={() => {
                        setTag(tag);
                        setIsTagDeleting(true);
                      }}
                      btnClassName="p-2 ml-2 rounded-md items-center hover:bg-danger-lighter py-1"
                    >
                      <AiFillDelete className="text-xl text-danger-dark" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {data.length === 0 && <EmptyMessage message="" />}

      {isTagEditing && (
        <QuestionTagModal
          tag={tag}
          tagList={data}
          setIsEditState={setIsTagEditing}
        />
      )}

      {isTagDeleting && (
        <Modal
          title="Are you sure?"
          testId="tag-delete-modal"
          acceptButtonText="Yes"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="No"
          handleAccept={onTagDelete}
          handleReject={() => setIsTagDeleting(false)}
        >
          Are you sure you want to delete the tag{" "}
          <span className="font-semibold italic">{tag.name} </span>?
        </Modal>
      )}
    </div>
  );
};

QuestionTagIndex.propTypes = {
  showQPImportAlert: PropTypes.bool,
};
export default QuestionTagIndex;
