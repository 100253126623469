import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { BRAND_URL, NAVBAR_PATH_EXCEPTIONS } from "app/constants";
import logo from "assets/images/logo/joballey_logo_light_text.png";

import { CANDIDATE_LINKS, ORGANISATION_LINKS } from "./navLinks";
import SideNavigation from "./SideNavigation";

const Layout = ({ openAside, setOpenAside, currentUser, children }) => {
  const location = useLocation();

  useEffect(() => {
    const bodyStyle = document.body.style;
    if (openAside) {
      bodyStyle.overflow = "hidden";
    } else {
      bodyStyle.overflow = "auto";
    }
  }, [openAside]);

  const pathException = NAVBAR_PATH_EXCEPTIONS.includes(location.pathname);

  if (!currentUser || pathException) {
    return children;
  }

  let sideNavigationLinks;
  if (currentUser.organisation_id) {
    sideNavigationLinks = ORGANISATION_LINKS;
  }
  if (!currentUser.organisation_id) {
    sideNavigationLinks = CANDIDATE_LINKS;
  }

  const logoUrl = process.env.NODE_ENV === "development" ? "/" : BRAND_URL;

  return (
    <div className="bg-gradient">
      <div className="mx-auto flex">
        {openAside && (
          <button
            type="button"
            onClick={() => setOpenAside(false)}
            className="fixed z-40 min-h-screen w-full bg-primary-lightest opacity-80 backdrop-blur-sm"
          />
        )}

        <aside
          className={`fixed z-50 min-h-screen w-[200px] duration-100 ease-in-out lg:translate-x-0 ${
            openAside ? "translate-x-0" : "translate-x-[-200px]"
          } bg-primary-dark lg:block`}
        >
          <div className="mt-3 min-h-screen w-[200px]">
            <Link to="/" href={logoUrl}>
              <img className="ml-3 pb-1" width={168} src={logo} alt="logo" />
            </Link>

            <SideNavigation
              links={sideNavigationLinks}
              openAside={openAside}
              setOpenAside={setOpenAside}
              roles={currentUser.roles}
            />
          </div>
        </aside>

        <div
          className={`${
            pathException ? "w-full" : "screen-width lg:ml-[200px]"
          } bg-gray-lighterShade3`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  openAside: PropTypes.bool,
  setOpenAside: PropTypes.func,
  currentUser: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    role: PropTypes.string,
    about: PropTypes.string,
    date_of_birth: PropTypes.string,
    is_profile_completed: PropTypes.bool,
    photo_url: PropTypes.string,
    organisation_id: PropTypes.number,
    organisation_name: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  children: PropTypes.node,
};

export default Layout;
