import { createSlice } from "@reduxjs/toolkit";

import { ALERT_TYPE } from "app/constants";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    type: undefined,
    message: undefined,
  },
  reducers: {
    renderAlert: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.triggerAlert = !!action.payload.message;
    },
    hideAlert: (state) => {
      state.type = undefined;
      state.message = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, { meta, payload }) => {
      if (meta?.requestStatus === "fulfilled" && payload?.meta?.message) {
        return {
          type: ALERT_TYPE[payload.meta.message_type || "success"],
          message: payload.meta.message,
        };
      }

      return state;
    });
  },
});

export const { renderAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
