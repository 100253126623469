import { QUESTION_TYPE } from "app/constants";

export const generalExamInstructions = () => {
  const generalInstruction = [
    {
      boldContent: null,
      lightContent:
        "If you face any issue while appearing for the exam, clear browser cache, logout and login back to the application.",
      listContent: null,
    },
    {
      boldContent: null,
      lightContent: "You can appear for this exam only once.",
      listContent: null,
    },
    {
      boldContent: null,
      lightContent:
        "Make sure your system is working fine and you are comfortable with it, before starting the exam.",
      listContent: null,
    },
    {
      boldContent: null,
      lightContent:
        "Each question will have a pre-assigned maximum time limit. You can move on to the next question if you complete the question before that time.",
      listContent: null,
    },
    {
      boldContent: null,
      warningContent:
        "Once you move on to the next question, you will not be allowed to return to or review the previous question.",
      listContent: null,
    },
    {
      boldContent: null,
      lightContent:
        "Any balance time from a previous question will not be carried forward to the next question.",
      listContent: null,
    },
  ];

  return [...generalInstruction];
};

export const systemRequirementsInstructions = () => {
  const systemRequirements = [
    {
      boldContent: null,
      lightContent:
        "Network Requirements: A stable internet connection with proper download & upload speed of at least 1 MBPS.",
      listContent: null,
    },
    {
      boldContent: null,
      lightContent:
        "Supported Browsers: Chrome v85.0 (or above) and Firefox v80.0 (or above).",
      listContent: null,
    },
    {
      boldContent: null,
      lightContent:
        "Operating System: Windows 10 (or above), Ubuntu 18.04 (or above), Linux Mint 19 (or above), Fedora 32 (or above), macOS 11 (or above).",
      listContent: null,
    },
  ];

  return [...systemRequirements];
};

export const doNotInstructions = (exam) => {
  const doNotInstruction = [
    {
      boldContent: null,
      warningContent:
        "Tab switching is not allowed. Doing so might end your exam.",
      listContent: null,
    },
    {
      boldContent: null,
      warningContent:
        "Exiting full screen is not allowed. Doing so might end your exam.",
      listContent: null,
    },
  ];

  const codingInstructions = [
    {
      boldContent: null,
      warningContent:
        "Do not copy the answer from anywhere. Plagiarized answers, if found, will lead to immediate disqualification of the candidate from subsequent rounds.",
      listContent: null,
    },
  ];

  return [
    ...doNotInstruction,
    exam.exam_type == QUESTION_TYPE[1].name && [...codingInstructions],
  ]
    .flat()
    .filter(Boolean)
    .map(Object);
};
