import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { startCase } from "lodash";
import PropTypes from "prop-types";

import { Button } from "components/atoms";
import CopyLink from "components/common/CopyLink";
import { DEFAULT_PAGE_SIZE } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";

const PanelList = ({
  panels,
  status,
  setInterview,
  setShowFeedbackModal,
  setViewOnly,
}) => {
  const showInterviewStatusAction = (panel) => {
    if (panel.link && status === "ongoing") {
      return (
        <a
          title="Join"
          className="btn-success block w-28 self-end rounded-full text-center"
          href={panel.link}
          rel="noreferrer"
          target="_blank"
        >
          Join
        </a>
      );
    }

    if (status === "upcoming") {
      return (
        <span className="text-gray-darker">
          {formatDistanceToNow(new Date(panel.start_at), {
            addSuffix: true,
          })}
        </span>
      );
    }

    if (panel.feedback && status === "past") {
      return (
        <Button
          title="View Feedback"
          role="link"
          btnName="View Feedback"
          btnClassName="mr-5"
          viewOnly
          type="secondary"
          onClick={() => {
            setInterview(panel);
            setShowFeedbackModal(true);
            setViewOnly(true);
          }}
        />
      );
    }

    if (status === "feedback_pending") {
      return (
        <Button
          title="Share Feedback"
          role="link"
          btnName="Share Feedback"
          btnClassName="mr-5"
          type="primary"
          onClick={() => {
            setInterview(panel);
            setShowFeedbackModal(true);
            setViewOnly(false);
          }}
        />
      );
    }

    return <span className="mr-5" />;
  };

  const getPanelType = ({ type }) => {
    return type === "group" ? "Group Discussion" : startCase(type);
  };

  const getLinkOrAddress = (panel) => {
    return panel.type === "group" && panel.address ? "Address" : "Link";
  };

  return (
    <table className="my-8 w-full min-w-[736px] table-auto border-collapse text-sm">
      <thead>
        <tr className="border-b-2 text-lg capitalize text-primary-main">
          <th className="w-2/12 py-4 pl-4 text-left text-lg font-semibold text-primary-dark">
            Event
          </th>
          <th className="w-2/12 text-lg font-semibold text-primary-dark sm:w-1/12">
            Date
          </th>
          <th className="w-2/12 text-lg font-semibold text-primary-dark sm:w-1/12">
            Start Time
          </th>
          <th className="w-1/12 text-left text-lg font-semibold text-primary-dark">
            Duration
          </th>
          <th className="sm:2/12 w-1/12 text-lg font-semibold text-primary-dark" />
        </tr>
      </thead>

      <tbody className="bg-white">
        {panels.map((panel, index) => {
          return (
            <tr key={index} className="border-b-2 hover:bg-secondary-hover">
              <td className="w-3/12 py-4 pl-4 pr-4 text-primary-main hover:underline">
                <Link
                  to={`/jobs/${panel.job_uuid}/pipeline?${constructQueryParams({
                    page: 1,
                    page_size: DEFAULT_PAGE_SIZE,
                    stageId: panel.stage_id,
                    candidate_id: panel.candidate_job_id,
                  })}`}
                  className="break-all hover:cursor-pointer hover:underline"
                  title={getPanelType(panel)}
                >
                  {panel.title}
                  <span className="text-xs italic"> with </span>
                  {panel.candidate_name}
                </Link>
              </td>

              <td className="break-all py-4 text-center text-gray-darker">
                {format(new Date(panel.start_at), "dd/MM/yyyy")}
              </td>

              <td className="break-all py-4 text-center text-gray-darker">
                {format(new Date(panel.start_at), "h:mm a")}
              </td>

              <td className="py-4 pr-4 text-gray-darker">
                {panel.duration} Minutes
              </td>

              <td className="flex items-center justify-between py-4 pr-4 text-gray-darker">
                {showInterviewStatusAction(panel)}

                <div className="relative mr-6 inline-block">
                  {status !== "past" && status !== "feedback_pending" && (
                    <CopyLink
                      link={
                        panel.type === "group" && panel.mode === "WALK_IN"
                          ? panel.address
                          : panel.link
                      }
                      tooltipId="interviewLink"
                      tooltipContent={`Copy ${getPanelType(
                        panel
                      )} ${getLinkOrAddress(panel)}`}
                      particular={getLinkOrAddress(panel)}
                    />
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

PanelList.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming", "feedback_pending", "past"]),
  setInterview: PropTypes.func,
  setViewOnly: PropTypes.func,
  setShowFeedbackModal: PropTypes.func,
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      job_uuid: PropTypes.string,
      stage_id: PropTypes.number,
      candidate_job_id: PropTypes.number,
      title: PropTypes.string,
      candidate_name: PropTypes.string,
      start_at: PropTypes.string,
      duration: PropTypes.number,
      link: PropTypes.string,
    })
  ),
};

export default PanelList;
