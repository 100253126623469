import { checkAppEnvs, checkErpEnvs, getCurrentSubdomain } from "helpers/utils";

export const checkPublicPage = () => {
  const publicPagePatterns = [
    /\/jobs\/([a-fA-F0-9-]+)\/details/,
    /\/login/,
    /\/signup/,
    /\/help/,
    /\/about/,
  ];

  return publicPagePatterns.some((pattern) =>
    pattern.test(window.location.pathname)
  );
};

export const checkERPPublicPage = () => {
  const publicPagePatterns = [/\/login/, /\/organisation/];

  return publicPagePatterns.some((pattern) =>
    pattern.test(window.location.pathname)
  );
};

export const isPublicPage = () => {
  const subdomain = getCurrentSubdomain();

  if (checkAppEnvs(subdomain)) {
    if (checkPublicPage()) {
      return true;
    }

    return false;
  }
  if (checkErpEnvs(subdomain)) {
    if (checkERPPublicPage()) {
      return true;
    }

    return false;
  }
};
