import { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import Modal from "components/molecules/Modal";
import { updateCurrentUserAction } from "store/thunkActions/currentUserThunk";

import CardContainer from "../Common/CardContainer";

import Form from "./Form";

const Interests = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = () => {
    setIsDeleting(false);
    return dispatch(updateCurrentUserAction({ interests: null }));
  };

  return (
    <CardContainer
      title="Hobbies &amp; Interest"
      addEventButtonTitle="Add"
      addEventButtonTestId="add-interests-btn"
      setIsAdding={setIsAdding}
      hideAddButton={Boolean(currentUser?.data?.interests)}
    >
      {!currentUser?.data?.interests && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on plus icon to add hobbies and interest
        </span>
      )}

      {isAdding && (
        <Form
          interests={currentUser?.data?.interests}
          setIsEditState={setIsAdding}
        />
      )}

      {currentUser?.data?.interests && !isAdding && (
        <div className="break-all text-justify">
          <div className="flex items-start justify-between">
            <p className="pt-2 pr-2 text-sm leading-5">
              {currentUser?.data?.interests}
            </p>
            <div className="flex">
              <button
                title="Edit Interest"
                data-testid="editInterestsBtn"
                className="btn-icon-secondary"
                onClick={() => setIsAdding(true)}
              >
                <MdOutlineModeEditOutline className="text-lg" />
              </button>
              <button
                title="Delete Interest"
                data-testid="deleteInterestsButton"
                className="btn-icon-danger"
                onClick={() => setIsDeleting(true)}
              >
                <BiTrash className="text-lg" />
              </button>
            </div>
          </div>
          {isDeleting && (
            <Modal
              title="Confirm Delete"
              description="This will delete the hobbies & interest from your profile."
              acceptButtonText="OK, Delete"
              acceptBtnType="primary"
              rejectButtonText="Cancel"
              customButtonWidth={32}
              rejectBtnType="secondary"
              handleAccept={onDelete}
              handleReject={() => setIsDeleting(false)}
            />
          )}
        </div>
      )}
    </CardContainer>
  );
};

export default Interests;
