import PropTypes from "prop-types";

function SectionCandidateMetaData({ currentSection, isSectional }) {
  return (
    <div className="m-2 rounded-xl border-2 bg-white p-4">
      <div className="p-1 text-base">
        {isSectional && (
          <div>
            <p className="break-words">
              <span className="meta-data">Section:</span>
              {currentSection.name}
            </p>
            <p>
              <span className="meta-data">Marks Obtained:</span>
              {currentSection.obtained_marks}
            </p>
            <p>
              <span className="meta-data">Total Marks:</span>
              {currentSection.total_marks}
            </p>
          </div>
        )}
        <p>
          <span className="meta-data">Correct:</span>
          {currentSection.correct_count}
        </p>
        <p>
          <span className="meta-data">Incorrect:</span>
          {currentSection.incorrect_count}
        </p>
        <p>
          <span className="meta-data">Un-attempted:</span>
          {currentSection.unattempted_count}
        </p>
      </div>
    </div>
  );
}

SectionCandidateMetaData.propTypes = {
  currentSection: PropTypes.shape({
    name: PropTypes.string,
    correct_count: PropTypes.string,
    incorrect_count: PropTypes.string,
    unattempted_count: PropTypes.string,
    obtained_marks: PropTypes.string,
    total_marks: PropTypes.string,
  }),
  isSectional: PropTypes.bool,
};

export default SectionCandidateMetaData;
