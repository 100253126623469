import { FaTasks } from "react-icons/fa";
import {
  RiBriefcase4Fill,
  RiClipboardFill,
  RiDashboardFill,
  RiDraftFill,
  RiFileUserFill,
} from "react-icons/ri";

import { ICON_SIZE_HASH } from "app/constants";
import { USER_ROLES } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";

export const CANDIDATE_LINKS = [
  {
    label: "Dashboard",
    path: "/",
    icon: <RiDashboardFill size={ICON_SIZE_HASH.md} />,
  },
  {
    label: "Jobs",
    path: "/jobs",
    icon: <RiBriefcase4Fill size={ICON_SIZE_HASH.md} />,
  },
  {
    label: "Events",
    path: `/events?${constructQueryParams({
      tab: "ongoing",
      page: 1,
      page_size: 20,
    })}`,
    icon: <RiFileUserFill size={ICON_SIZE_HASH.md} />,
  },
  {
    label: "Mock Tests",
    path: `/mock-tests?${constructQueryParams({
      page: 1,
      page_size: 20,
    })}`,
    icon: <FaTasks size={ICON_SIZE_HASH.md} />,
  },
];

export const ORGANISATION_LINKS = [
  {
    label: "Dashboard",
    path: "/",
    icon: <RiDashboardFill size={ICON_SIZE_HASH.md} />,
    allowedRoles: [
      USER_ROLES.ADMIN,
      USER_ROLES.INTERVIEWER,
      USER_ROLES.QUESTION_SETTER,
      USER_ROLES.EVALUATOR,
      USER_ROLES.HIRING_MANAGER,
    ],
  },
  {
    label: "Jobs",
    path: `/jobs`,
    icon: <RiBriefcase4Fill size={ICON_SIZE_HASH.md} />,
    allowedRoles: [
      USER_ROLES.ADMIN,
      USER_ROLES.INTERVIEWER,
      USER_ROLES.EVALUATOR,
      USER_ROLES.HIRING_MANAGER,
    ],
  },
  {
    label: "Question Manager",
    path: `/questions_manager?${constructQueryParams({
      tab: "question_papers",
      page: 1,
      page_size: 20,
    })}`,
    icon: <RiDraftFill size={ICON_SIZE_HASH.md} />,
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.QUESTION_SETTER],
  },
  {
    label: "My Panels",
    path: `/panels?${constructQueryParams({
      status: "ongoing",
    })}`,
    icon: <RiClipboardFill size={ICON_SIZE_HASH.md} />,
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.INTERVIEWER],
  },
];
