import { format } from "date-fns";
import PropTypes from "prop-types";

import { examDuration } from "constants/common";

const FormattedTime = ({ dateArg }) =>
  dateArg && (
    <>
      <span className="mb-1">{format(new Date(dateArg), "d/M/yy")}, </span>
      <span>{format(new Date(dateArg), "h:mm aaa")}</span>
    </>
  );

const ExamCandidateMetaData = ({ examCandidate }) => {
  return (
    <div className="m-2 rounded-xl border-2 bg-white p-3">
      <div className="p-1 text-base">
        <p className="break-words">
          <span className="meta-data">Exam:</span>
          {examCandidate.exam_name}
        </p>
        <p className="break-words">
          <span className="meta-data">Stage:</span>
          {examCandidate.stage_name}
        </p>
        <p>
          <span className="meta-data">Appeared on:</span>
          <FormattedTime dateArg={examCandidate.start_time} />
        </p>
        <p>
          <span className="meta-data">Duration:</span>
          {examDuration(examCandidate.start_time, examCandidate.end_time)}
        </p>
        <p>
          <span className="meta-data">Marks Obtained:</span>
          {examCandidate.obtained_marks}
        </p>
        <p>
          <span className="meta-data">Total marks:</span>
          {examCandidate.total_marks}
        </p>
      </div>
    </div>
  );
};

FormattedTime.propTypes = {
  dateArg: PropTypes.string.isRequired,
};

ExamCandidateMetaData.propTypes = {
  examCandidate: PropTypes.shape({
    exam_name: PropTypes.string,
    stage_name: PropTypes.string,
    obtained_marks: PropTypes.string,
    total_marks: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
  }),
};

export default ExamCandidateMetaData;
