import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import PropTypes from "prop-types";

import SkeletonLoader from "components/common/SkeletonLoader";

import { CKEDITOR_CONFIG } from "../../../constants/ckeditorConfigurations";

const RichTextEditor = ({
  id,
  content,
  onChange,
  disabled,
  placeholder,
  fieldError,
  label,
  labeTestId,
  labelClassNames,
  containerClassNames,
  isLoading,
  children,
}) => {
  const [editorInstance, setEditorInstance] = useState(null);

  const handleClassNames = (editor) => {
    if (disabled) {
      editor.ui.view.element.classList.add(`editor-disabled`);
    } else {
      editor.ui.view.element.classList.remove(`editor-disabled`);
    }
    if (fieldError) {
      editor.ui.view.element.classList.add(`input-error`);
      editor.ui.view.element.classList.remove(`input`);
    } else {
      editor.ui.view.element.classList.add(`input`);
      editor.ui.view.element.classList.remove("input-error");
    }
  };

  useEffect(() => {
    if (editorInstance) {
      handleClassNames(editorInstance);
    }
  }, [fieldError, disabled]);

  return (
    <div className={`${containerClassNames}`}>
      {label && (
        <label
          className={labelClassNames}
          data-testid={labeTestId}
          htmlFor={id}
        >
          <div className="flex items-center">
            <div>{label}</div>
            <div>{children}</div>
          </div>
        </label>
      )}
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <div className="ck-editor mb-2">
          <CKEditor
            id={id}
            editor={Editor}
            data={content || ""}
            config={{ ...CKEDITOR_CONFIG, placeholder }}
            disableWatchdog
            onChange={(_, editor) => {
              if (editor) {
                onChange(editor.getData());
                handleClassNames(editor);
              }
            }}
            onReady={(editor) => {
              if (editor) {
                setEditorInstance(editor);
                handleClassNames(editor);
              }
            }}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

RichTextEditor.defaultProps = {
  label: "",
  labelClassNames: "",
  labeTestId: "",
  containerClassNames: "",
};

RichTextEditor.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelClassNames: PropTypes.string,
  labeTestId: PropTypes.string,
  containerClassNames: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  content: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  fieldError: PropTypes.bool,
};

export default RichTextEditor;
