import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createQuestionPaper,
  getQuestionPapers,
  showQuestionPaper,
  updateQuestionPaperName,
} from "api/erp/questionPaperApi";

export const getQuestionPapersAction = createAsyncThunk(
  "question_papers/get",
  async ({
    page,
    page_size: pageSize,
    sectional,
    type,
    active,
    search_term: searchTerm,
  }) => {
    const response = await getQuestionPapers(
      page,
      pageSize,
      sectional,
      type,
      active,
      searchTerm
    );
    return response;
  }
);

export const createQuestionPaperAction = createAsyncThunk(
  "question_paper/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createQuestionPaper(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const showQuestionPaperAction = createAsyncThunk(
  "question_paper/show",
  async (id, { rejectWithValue }) => {
    try {
      const response = await showQuestionPaper(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQuestionPaperNameAction = createAsyncThunk(
  "question_paper/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateQuestionPaperName(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
