import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ActivityIndicator,
  EmptyMessage,
  InfiniteScrollWrapper,
} from "components/atoms";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import { SITE_TITLE, USER_ROLES } from "constants/common";
import {
  JOB_FILTER_HIDDEN,
  JOB_FILTER_STATUSES,
  JOB_FILTER_VISIBILITY,
} from "constants/jobs";
import useCheckUserRole from "hooks/useCheckUserRole";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { getJobsAction } from "store/thunkActions/erp/jobThunk";

import Job from "./Job";

const Jobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: storeJobList,
    meta: jobsMeta,
    isLoading: isJobPostLoading,
  } = useSelector((state) => state.jobs);

  const isUserRoleAllowed = useCheckUserRole([
    USER_ROLES.ADMIN,
    USER_ROLES.HIRING_MANAGER,
  ]);

  const useFormProps = useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const filterField = [
    {
      label: "Job Status",
      placeholder: "job status",
      name: "statuses",
      multiSelect: true,
      dropdownOptions: JOB_FILTER_STATUSES,
    },
    {
      label: "Job Visibility",
      placeholder: "job visibility",
      name: "visibility",
      multiSelect: false,
      dropdownOptions: JOB_FILTER_VISIBILITY,
    },
    {
      label: "Job Only Accessible by Link?",
      placeholder: "job only accessible by link?",
      name: "hidden",
      multiSelect: false,
      dropdownOptions: JOB_FILTER_HIDDEN,
    },
  ];

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: {
          currentPageName: "Jobs",
        },
      })
    );
  }, []);

  const page = 1;
  const tableHeadRef = useRef();

  useEffect(() => {
    const searchTerm = searchParams.get("search_term");
    const statuses = searchParams.getAll("statuses");
    const visibility = searchParams.get("visibility");
    const hidden = searchParams.get("hidden");
    window.scrollTo({
      top: tableHeadRef?.current?.offsetTop,
    });
    setIsLoading(true);
    dispatch(
      getJobsAction({
        page,
        searchTerm,
        statuses,
        visibility,
        hidden,
      })
    )
      .then(() => resetFilterForm())
      .finally(() => setIsLoading(false));
  }, [searchParams, dispatch]);

  const getAllJob = (page, searchTerm, statuses, visibility, hidden) =>
    dispatch(getJobsAction({ page, searchTerm, statuses, visibility, hidden }));

  const anyFilterApplied = () =>
    searchParams.has("statuses") ||
    searchParams.has("visibility") ||
    searchParams.has("hidden");

  const resetFilterForm = () => {
    useFormProps.reset({
      statuses: searchParams.get("statuses")
        ? JOB_FILTER_STATUSES.filter((status) =>
            searchParams.getAll("statuses").includes(status.value)
          )
        : undefined,
      visibility: searchParams.get("visibility")
        ? JOB_FILTER_VISIBILITY.find(
            (status) => status.value === searchParams.get("visibility")
          )
        : undefined,
      hidden: searchParams.get("hidden")
        ? JOB_FILTER_HIDDEN.find(
            (status) => status.value === searchParams.get("hidden")
          )
        : undefined,
    });
  };

  const generateFilterCriterias = () => {
    const filterCriterias = [];

    if (searchParams.has("statuses")) {
      filterCriterias.push("Job Status");
    }
    if (searchParams.has("visibility")) {
      filterCriterias.push("Job Visibility");
    }
    if (searchParams.has("hidden")) {
      filterCriterias.push("Job Only Accessible by Link?");
    }
    return filterCriterias.join(" | ");
  };

  useEffect(() => {
    const scrollYSavedOffset = localStorage.getItem("jobpost:scrollY");

    if (scrollYSavedOffset) {
      window.scrollTo({ top: Number(scrollYSavedOffset), behavior: "smooth" });
      localStorage.removeItem("jobpost:scrollY");
    }
  }, []);

  const jobClickHandler = () =>
    localStorage.setItem("jobpost:scrollY", window.scrollY);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  return (
    <div className="w-full">
      <Helmet>
        <title>{`Jobs - ${SITE_TITLE}`}</title>
        <meta property="og:url" content="https://app.joballey.in/" />
      </Helmet>
      <div>
        <div className="pb-2" data-testid="statusSelect">
          <div className="flex gap-4 pl-3.5 lg:pl-5 pr-4 justify-between items-start py-4 bg-gray-lighterShade3 z-10 sticky top-[44px]">
            <div>
              <div className="sm:max-w-[450px]">
                <SearchBar
                  anyFilterApplied={anyFilterApplied}
                  searchParams={searchParams}
                  resetFilterForm={resetFilterForm}
                  useFormProps={useFormProps}
                  setSearchParams={setSearchParams}
                  filterField={filterField}
                  searchBarPlaceholder="Search by job title"
                  defaultSearchParams={{ page: null, page_size: null }}
                />
              </div>
              {anyFilterApplied() && (
                <p className="mt-3 text-sm text-primary-main align-top">
                  <span className="mr-1"> Filtered by: </span>

                  <span>{generateFilterCriterias()}</span>
                </p>
              )}
            </div>
            {isUserRoleAllowed && (
              <button
                title="Create Job"
                data-testid="add-job-btn"
                className="items-center rounded-full bg-primary-main p-2 hover:bg-primary-dark"
                onClick={() => navigate("/jobs/create")}
              >
                <AiOutlinePlus className="text-2xl text-white" />
              </button>
            )}
          </div>
          {!storeJobList.length && isJobPostLoading !== null ? (
            <EmptyMessage message="" />
          ) : (
            <div className="px-4 relative rounded-xl">
              <div className="pl-2 overflow-x rounded-xl">
                <div className="mb-8 mt-2 overflow-x-auto overflow-y-hidden shadow-sm">
                  <InfiniteScrollWrapper
                    hasMore={jobsMeta.has_more}
                    loadMoreHandler={() =>
                      getAllJob(
                        jobsMeta.page_number + 1,
                        searchParams.get("search_term"),
                        searchParams.getAll("statuses"),
                        searchParams.get("visibility"),
                        searchParams.get("hidden")
                      )
                    }
                  >
                    <table className="w-full min-w-[992px] table-fixed border-collapse text-sm">
                      <thead
                        ref={tableHeadRef}
                        className="border-b-2 text-lg capitalize text-primary-main"
                      >
                        <tr>
                          <th className="w-64 p-2 pl-4 pb-3 text-left">
                            Title
                          </th>
                          <th className="w-28 p-2 pt-0 pb-3 text-center">
                            Status
                          </th>
                          <th className="w-40 p-2 pt-0 pb-3 text-left">
                            Location
                          </th>
                          <th className="w-28 p-2 pt-0 pb-3 text-left">
                            Visibility
                          </th>
                          <th className="w-28 xl:w-32 2xl:w-24" />
                          <th className="w-16 sm:w-14 " />
                          <th className="w-8 " />
                        </tr>
                      </thead>

                      <tbody className="bg-white">
                        {storeJobList.map((job) => (
                          <tr
                            data-testid={`job-id-${job.uuid}`}
                            key={job.uuid}
                            className="border-b hover:bg-gray-lighter"
                          >
                            <Job
                              job={job}
                              isUserRoleAllowed={isUserRoleAllowed}
                              onJobClick={jobClickHandler}
                            />
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </InfiniteScrollWrapper>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Jobs;
