import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BiSelectMultiple } from "react-icons/bi";
import { GrCheckboxSelected } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { emailResult } from "api/erp/jobApi";
import {
  ALERT_TYPE,
  EXAM_EMAIL_MESSAGE,
  INTERVIEW_EMAIL_MESSAGE,
  STAGE_TYPE,
} from "app/constants";
import { Button, ThreeDotMenu } from "components/atoms";
import { Modal } from "components/molecules";
import {
  getAllApplicantsHeaders,
  getExamHeaders,
  getGDHeaders,
  getInterviewHeaders,
  getPlacedHeaders,
} from "helpers/pipeline/pipelineHeaders";
import { stageExamDetailsPropTypes } from "helpers/Props/stageExamDetailsPropTypes";
import useAlert from "hooks/useAlert";
import {
  addSelectedIds,
  resetSelection,
  selectAllOfType,
} from "store/slices/miscellaneousSlice";

import SendCustomEmailModal from "../Candidates/SendCustomEmailModal";

import AllApplicantColumnHeaders from "./AllApplicantColumnHeaders";
import HeaderActionItems from "./HeaderActionItems";
import PlacedColumnHeaders from "./PlacedColumnHeaders";
import StageColumnHeaders from "./StageColumnHeaders";

const PipelineCandidatesHeader = ({
  data,
  totalCandidates,
  currentStage,
  currentLobby,
  stageExamDetails,
  setIsAddingToStage,
  setExamCandidateId,
  setExamEvaluators,
  setSelectedCandidates,
  setIsSingleOperation,
  setIsUpdatingStatus,
  setShowAddEvaluatorModal,
  isUserRoleAllowed,
  onClickSort,
  sortOrder,
  sortBy,
  isJobClosed,
  filterData,
  getCandidateStageData,
  organisationData,
}) => {
  const { selectedIds, selectedObjectType, allSelected } = useSelector(
    (state) => state.miscellaneous
  );

  const [isEmailResult, setIsEmailResult] = useState(false);
  const [showCustomEmailModal, setShowCustomEmailModal] = useState(false);
  const [showAllCheckDropdown, setShowAllCheckDropdown] = useState(false);
  const params = useParams();
  const showAlert = useAlert();
  const allCheckRef = useRef();
  const allCheckDropdownMenuRef = useRef();
  const dispatch = useDispatch();

  const anyRecordSelected = useMemo(() => {
    return selectedObjectType === "candidate" && selectedIds.length > 0;
  }, [selectedObjectType, selectedIds]);

  const allCheckedHandler = ({ checked }) => {
    if (checked) {
      const toBeAddedIds = data
        .map((candidate) => candidate.id.toString())
        .filter((id) => !selectedIds.includes(id));
      dispatch(
        addSelectedIds({ objectIds: toBeAddedIds, objectType: "candidate" })
      );
    } else {
      dispatch(resetSelection());
    }
  };

  const candidateData = data.filter((candidate) =>
    selectedIds.includes(candidate.id.toString())
  );

  const updateEvaluatorsHandle = () => {
    setExamCandidateId(selectedIds);
    setShowAddEvaluatorModal(true);
    setIsSingleOperation(false);
    setExamEvaluators(null);
    setSelectedCandidates(
      candidateData.map((candidate) => {
        return candidate.exam_evaluators;
      })
    );
  };

  const sendEmail = () => {
    const selectedData = { candidate_job_ids: selectedIds };

    emailResult(params.uuid, currentStage.id, selectedData)
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        setIsEmailResult(false);
      })
      .catch((error) => {
        showAlert(
          ALERT_TYPE[error.response.data.meta?.message_type],
          error.response.data.meta?.message
        );
        setIsEmailResult(false);
      });
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        showAllCheckDropdown &&
        !allCheckDropdownMenuRef.current.contains(e.target)
      ) {
        setShowAllCheckDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showAllCheckDropdown]);

  const filteredAllApplicantsHeaders = getAllApplicantsHeaders();
  const filteredPlacedHeaders = getPlacedHeaders(isUserRoleAllowed);
  const filteredExamHeaders = getExamHeaders(stageExamDetails);
  const filteredInterviewHeaders = getInterviewHeaders();
  const filteredGDHeaders = getGDHeaders();

  const getFilterHeaders = useCallback(() => {
    if (currentStage?.stage_type === STAGE_TYPE[2].value) {
      return filteredInterviewHeaders;
    }
    if (currentStage?.stage_type === STAGE_TYPE[1].value) {
      return filteredGDHeaders;
    }
    if (currentStage?.stage_type === STAGE_TYPE[0].value) {
      return filteredExamHeaders;
    }
  }, [currentStage?.stage_type]);

  return (
    <>
      <tr data-testid="record-selection-status-row">
        {(allSelected || anyRecordSelected) && (
          <HeaderActionItems
            updateEvaluatorsHandle={updateEvaluatorsHandle}
            setIsAddingToStage={setIsAddingToStage}
            setIsUpdatingStatus={setIsUpdatingStatus}
            setIsEmailResult={setIsEmailResult}
            currentLobby={currentLobby}
            currentStage={currentStage}
            stageExamDetails={stageExamDetails}
            data={data}
            selectedIds={selectedIds}
            isJobClosed={isJobClosed}
            setShowCustomEmailModal={setShowCustomEmailModal}
            allSelected={allSelected}
            totalCandidates={totalCandidates}
            filterData={filterData}
            organisationData={organisationData}
          />
        )}
      </tr>
      <tr>
        {currentLobby === "PLACED" ? (
          <PlacedColumnHeaders
            sortBy={sortBy}
            sortOrder={sortOrder}
            filteredPlacedHeaders={filteredPlacedHeaders}
            onClickSort={onClickSort}
          />
        ) : (
          <>
            {isUserRoleAllowed ? (
              <td
                className={classNames(
                  "bg-gray-lighterShade3 text-left p-4 md:sticky md:left-0 w-20 relative",
                  { "z-[15]": showAllCheckDropdown }
                )}
              >
                <Button
                  title="View Select All Options"
                  testId="select-all-dropdown-arrow"
                  onClick={(e) => {
                    if (!allCheckRef.current.contains(e.target)) {
                      setShowAllCheckDropdown((dropdown) => !dropdown);
                    }
                  }}
                  btnClassName={`flex block justify-between py-2${
                    showAllCheckDropdown
                      ? " bg-gray-lighter outline outline-8 outline-gray-lighter"
                      : ""
                  }`}
                >
                  <input
                    title="Select All Records On This Page"
                    className="h-5 w-4 hover:cursor-pointer focus:outline-none"
                    type="checkbox"
                    ref={allCheckRef}
                    checked={
                      allSelected ||
                      data
                        .map((d) => d.id)
                        .sort()
                        .toString() === [...selectedIds].sort().toString()
                    }
                    onChange={(e) => allCheckedHandler(e.target)}
                    id="flexCheckDefault"
                    data-testid="select-all-page-box"
                  />

                  <span className="inline-block text-md pl-2">&#9660;</span>
                </Button>

                {showAllCheckDropdown && (
                  <ThreeDotMenu
                    menuRef={allCheckDropdownMenuRef}
                    menuOptions={[
                      {
                        dataTestId: "all_in_page",
                        onClick: () => {
                          allCheckedHandler({ checked: true });
                          setShowAllCheckDropdown(false);
                        },
                        icon: (
                          <GrCheckboxSelected className="mr-2 mt-[2px] text-base" />
                        ),
                        text: "All In Page",
                      },
                      {
                        dataTestId: "all_in_stage",
                        onClick: () => {
                          dispatch(
                            selectAllOfType({ objectType: "candidate" })
                          );
                          setShowAllCheckDropdown(false);
                        },
                        icon: (
                          <BiSelectMultiple className="mr-2 mt-[2px] text-base" />
                        ),
                        text: "All In Stage",
                      },
                    ]}
                    posFromRight="left-3"
                    posFromTop={currentStage ? "top-16" : "top-20"}
                  />
                )}
              </td>
            ) : (
              <td className="bg-gray-lighterShade3 p-4 text-left md:sticky md:left-0 w-16" />
            )}

            {currentStage && (
              <StageColumnHeaders
                sortBy={sortBy}
                sortOrder={sortOrder}
                filteredStageHeaders={getFilterHeaders()}
                onClickSort={onClickSort}
                anyRecordSelected={anyRecordSelected}
              />
            )}

            {currentLobby === "ALL_APPLICANTS" && (
              <AllApplicantColumnHeaders
                sortBy={sortBy}
                sortOrder={sortOrder}
                filteredAllApplicantsHeaders={filteredAllApplicantsHeaders}
                onClickSort={onClickSort}
                anyRecordSelected={anyRecordSelected}
              />
            )}
          </>
        )}
      </tr>

      {isEmailResult && (
        <Modal
          title="Are You Sure?"
          description={
            currentStage.stage_type === "interview"
              ? INTERVIEW_EMAIL_MESSAGE
              : EXAM_EMAIL_MESSAGE
          }
          acceptButtonText="Send"
          acceptBtnType="primary"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          handleAccept={sendEmail}
          handleReject={() => setIsEmailResult(false)}
          onOutsideClickHandler={() => setIsEmailResult(false)}
        />
      )}

      {showCustomEmailModal && (
        <SendCustomEmailModal
          selectedIds={selectedIds}
          currentStage={currentStage}
          setShowModal={setShowCustomEmailModal}
          allSelected={allSelected}
          getCandidateStageData={getCandidateStageData}
        />
      )}
    </>
  );
};

PipelineCandidatesHeader.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  totalCandidates: PropTypes.number,
  currentLobby: PropTypes.string,
  sortOrder: PropTypes.string,
  sortBy: PropTypes.string,
  stageExamDetails: stageExamDetailsPropTypes,
  isUserRoleAllowed: PropTypes.bool,
  setIsAddingToStage: PropTypes.func,
  setShowAddEvaluatorModal: PropTypes.func,
  setIsSingleOperation: PropTypes.func,
  setExamEvaluators: PropTypes.func,
  setSelectedCandidates: PropTypes.func,
  setExamCandidateId: PropTypes.func,
  setIsUpdatingStatus: PropTypes.func,
  onClickSort: PropTypes.func,
  isJobClosed: PropTypes.bool,
  filterData: PropTypes.shape({}),
};

PipelineCandidatesHeader.defaultProps = {
  data: [],
  totalCandidates: 0,
  stageExamDetails: [],
  currentStage: null,
  currentLobby: null,
  sortOrder: "",
  sortBy: "",
  isUserRoleAllowed: true,
  setIsAddingToStage: () => {},
  setIsUpdatingStatus: () => {},
  setShowAddEvaluatorModal: () => {},
  setIsSingleOperation: () => {},
  setExamEvaluators: () => {},
  setSelectedCandidates: () => {},
  setExamCandidateId: () => {},
  onClickSort: () => {},
  isJobClosed: () => {},
  filterData: {},
};

export default memo(PipelineCandidatesHeader);
