import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { updateMailSubscriptionPreference } from "api/candidate/profileApi";
import { ALERT_TYPE, ICON_SIZE_HASH } from "app/constants";
import ActionAlert from "components/atoms/ActionAlert";
import useAlert from "hooks/useAlert";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

import CardContainer from "../Common/CardContainer";

const CommunicationPreference = ({ currentUser }) => {
  const showAlert = useAlert();
  const dispatch = useDispatch();

  const handleSubscriptionCheckbox = (mailSubscription) => {
    return updateMailSubscriptionPreference(!mailSubscription)
      .then(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        dispatch(setCandidateUserAction());
      })
      .catch(({ meta }) => {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
      });
  };

  return (
    <CardContainer
      title="Communication Preferences"
      customChildrenClass="rounded-lg  border border-gray-light bg-white pl-3 pr-4 pt-4 pb-4"
    >
      <ActionAlert
        message={
          <p className="text-sm">
            You will still get all the important account-related emails (applied
            jobs, upcoming exams/interviews, results, etc.)
          </p>
        }
        alertType="info"
        iconName="info"
        isButtonRequire={false}
        roundedBorder={false}
        iconSize={ICON_SIZE_HASH.md}
        iconVerticalAlignment="self-start"
      />

      <div className="flex flex-col w-full mt-2.5 sm:ml-1.5 ml-3">
        <label className="relative flex justify-between cursor-pointer select-none items-center">
          <div className="flex flex-col w-40 sm:w-max">
            <span className="text-lg break-normal">Marketing emails</span>
            <span className="text-sm opacity-80 text-gray-500 break-normal">
              Suggested jobs, upcoming events, etc.
            </span>
          </div>
          <input
            type="checkbox"
            className="ml-4 h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400 mr-4 sm:mr-3"
            onChange={() => {
              handleSubscriptionCheckbox(currentUser?.data?.mail_subscription);
            }}
            checked={currentUser?.data?.mail_subscription}
          />
          <span className="font-small absolute right-1.5  text-xs text-white mr-4 sm:mr-3">
            NO
          </span>
          <span className="absolute right-9 mr-0  text-xs font-medium text-white mr-4 sm:mr-3">
            YES
          </span>
          <span className="absolute right-8 h-4 w-7 transform rounded-full bg-white transition-transform mr-4 sm:mr-3" />
        </label>
      </div>
    </CardContainer>
  );
};

CommunicationPreference.propTypes = {
  currentUser: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      uuid: PropTypes.string,
      title: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      portfolio_url: PropTypes.string,
      about: PropTypes.string,
      date_of_birth: PropTypes.string,
      is_profile_completed: PropTypes.bool,
      photo_url: PropTypes.string,
      mail_subscription: PropTypes.bool,
      reference_images: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_name: PropTypes.string,
          attachment: PropTypes.string,
        })
      ),
      avatar: PropTypes.shape({
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }),
  }),
};

export default CommunicationPreference;
