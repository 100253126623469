import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import {
  getQuestionPaperQuestion,
  showQuestionPaper,
} from "api/erp/questionPaperApi";
import { QUESTION_TYPE } from "app/constants";
import { getQuestionPapersAction } from "store/thunkActions/erp/questionsThunk";

import CodingForm from "../../QuestionForm/CodingForm";
import Form from "../../QuestionForm/Form";

const Question = () => {
  const questionPaperId = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [questionPaper, setQuestionPaper] = useState();
  const questionID = searchParams.get("question_id");

  const getQuestionPapersQuestions = () => {
    getQuestionPaperQuestion(questionPaperId.id, questionID).then(
      ({ data }) => {
        setSelectedQuestion(data);
      }
    );
  };

  useEffect(() => {
    if (questionID) {
      getQuestionPapersQuestions();
    }

    showQuestionPaper(questionPaperId.id)
      .then(({ data }) => setQuestionPaper(data))
      .catch(() => {});
  }, [questionPaperId.id]);

  useEffect(() => {
    dispatch(getQuestionPapersAction());
  }, []);

  return (
    <div className="mx-auto h-full md:px-4">
      {questionPaper &&
        (questionPaper.question_paper_type == QUESTION_TYPE[1].name ? (
          <CodingForm
            questionPaper={questionPaper}
            selectedQuestion={selectedQuestion}
            getQuestionPapersQuestions={getQuestionPapersQuestions}
          />
        ) : (
          <Form
            questionPaper={questionPaper}
            selectedQuestion={selectedQuestion}
            getQuestionPapersQuestions={getQuestionPapersQuestions}
          />
        ))}
    </div>
  );
};

export default Question;
