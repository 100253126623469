import { createAsyncThunk } from "@reduxjs/toolkit";

import { getLocationTypes } from "api/candidate/profileApi";

export const getLocationTypesAction = createAsyncThunk(
  "locationTypes/get",
  async () => {
    const response = await getLocationTypes();
    return response.data;
  }
);
