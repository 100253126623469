const getPreviewScreenshot = async (videoRef, canvasRef) => {
  // Delay added to ensure video frame is rendered on the canvas
  await new Promise((resolve) => {
    setTimeout(resolve, 2000);
  });

  // Taking screenshot of webcam
  const video = videoRef?.current;
  const canvas = canvasRef?.current;

  if (canvas) {
    canvas.width = video?.videoWidth;
    canvas.height = video?.videoHeight;
  }

  canvas.getContext("2d")?.drawImage(video, 0, 0);
  const base64Image = canvas.toDataURL("image/png");

  const previewScreenshot = await fetch(base64Image)?.then((res) => res.blob());

  return { base64Image, previewScreenshot };
};

export default getPreviewScreenshot;
