import { useEffect, useRef, useState } from "react";
import { BsEnvelope, BsTelephone } from "react-icons/bs";
import { RiCheckDoubleFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import { PLACED_CANDIDATE_STATUS } from "app/constants";
import { Avatar, Button } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import FormattedContactNumber from "components/atoms/FormattedContactNumber";
import CopyLink from "components/common/CopyLink";
import { USER_ROLES } from "constants/common";
import useCheckUserRole from "hooks/useCheckUserRole";

import SendLOIModal from "./SendLOIModal";

const PlacedCandidates = ({
  candidate,
  getCandidateStageData,
  isJobClosed,
  showSidePanel,
  openSidePanel,
}) => {
  const dropdownMenuRef = useRef(null);

  const isUserRoleAllowed = useCheckUserRole([
    USER_ROLES.ADMIN,
    USER_ROLES.HIRING_MANAGER,
  ]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showLOIModal, setShowLOIModal] = useState(false);

  const { selectedIds, selectedObjectType } = useSelector(
    (state) => state.miscellaneous
  );

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  return (
    <tr
      className={classNames(
        "my-4 border-y hover:bg-gray-lighter",
        (selectedObjectType === "candidate" &&
          selectedIds.includes(candidate.id.toString())) ||
          showSidePanel
          ? "bg-primary-lighter"
          : "bg-white"
      )}
    >
      <td className="p-4 align-top text-base text-primary-main whitespace-nowrap">
        <button
          title={candidate.id}
          id="candidate-id"
          className="break-all text-base text-primary-main hover:underline"
          onClick={() => openSidePanel(candidate?.id)}
        >
          {candidate.id}
        </button>
      </td>

      <td className="p-4 align-top text-slate-500">
        <div className="mb-2 flex items-center gap-3">
          <div className="  mb-1  h-10  w-10 rounded-full ring-2 ring-primary-dark ring-offset-2 ">
            {candidate?.avatar?.thumb ? (
              <div className="h-10 w-10">
                <Avatar src={candidate.avatar?.thumb} height={10} width={10} />
              </div>
            ) : (
              <CharacterAvatar
                char={candidate.name.charAt(0)}
                width={10}
                height={10}
                textSize="text-[1.5rem]"
              />
            )}
          </div>
          <span data-testid="candidate_data" className="block pr-2 pb-1 ">
            <button
              id="candidate-name"
              className="break-words text-start text-base text-primary-main hover:underline"
              onClick={() => openSidePanel(candidate?.id)}
            >
              {candidate?.name}
            </button>
          </span>
        </div>

        <p className="capitalize">
          {candidate?.work_experiences[0]?.title
            ? candidate?.work_experiences[0]?.title
            : ""}
          {candidate?.work_experiences.length ? " , " : ""}
          {candidate?.work_experiences.length > 0
            ? candidate?.work_experiences[0]?.company_name
            : ""}
          {candidate?.work_experiences.length > 1 && (
            <span className="ml-1 text-primary-dark">
              <Button
                title="Experience Details"
                type="tertiary"
                onClick={() =>
                  openSidePanel(candidate?.id, "workExperienceRef")
                }
                btnName={`+ ${candidate.work_experiences.length - 1}`}
              />
            </span>
          )}
        </p>

        <p className="pt-2 capitalize">
          {candidate?.candidate_skills?.length > 0
            ? candidate.candidate_skills
                .slice(0, 4)
                .map((candidateSkill) => candidateSkill.skill.name)
                .join(", ")
            : ""}
          {candidate?.candidate_skills?.length <= 4 &&
            candidate.candidate_skills.length > 0 && (
              <div className="">
                <Button
                  title="Skills"
                  type="tertiary"
                  onClick={() => openSidePanel(candidate?.id, "skillsRef")}
                  btnName="Show details"
                />
              </div>
            )}
          {candidate?.candidate_skills?.length > 4 && (
            <Button
              title="Skills"
              type="tertiary"
              onClick={() => openSidePanel(candidate?.id, "skillsRef")}
              btnName={`+ ${candidate.candidate_skills.length - 4}`}
            />
          )}
        </p>
      </td>

      {isUserRoleAllowed && (
        <td className="p-4 align-top flex flex-col gap-4 text-primary-main">
          {candidate.email && (
            <div className="flex gap-2 items-center">
              <BsEnvelope className="inline" />
              <a href={`mailto:${candidate.email}`}>{candidate.email}</a>
              <CopyLink
                icon="copy"
                link={candidate.email}
                tooltipId="copyEmail"
                tooltipContent="Copy email Id"
              />
            </div>
          )}

          {candidate.phone_numbers?.map((phoneNumber, index) => (
            <div key={index} className="flex gap-2 items-center">
              <BsTelephone className="inline" />
              <FormattedContactNumber number={phoneNumber} />
              <CopyLink
                icon="copy"
                link={phoneNumber}
                tooltipId="copyPhoneNo"
                tooltipContent="Copy Phone Number"
              />
            </div>
          ))}
        </td>
      )}

      <td className="p-4 align-top">
        <div className="text-primary-main">
          {
            PLACED_CANDIDATE_STATUS.find(
              ({ value }) => value === candidate?.status
            )?.label
          }
        </div>
      </td>
      {isUserRoleAllowed && (
        <td className="p-4 align-top flex flex-col">
          {candidate?.placed_candidate_loi_id && (
            <div className="flex">
              <RiCheckDoubleFill className="text-sm text-gray-dark" />
              <span className="ml-1 text-xs italic text-gray-dark">
                LOI Sent
              </span>
            </div>
          )}
          {!isJobClosed && (
            <div>
              <Button
                title={
                  candidate?.placed_candidate_loi_id ? "View LOI" : "Send LOI"
                }
                onClick={() => setShowLOIModal(true)}
                type="tertiary"
                btnName={
                  candidate?.placed_candidate_loi_id ? "View LOI" : "Send LOI"
                }
              />
            </div>
          )}
        </td>
      )}
      {showLOIModal && (
        <SendLOIModal
          candidate={candidate}
          setShowModal={setShowLOIModal}
          showModal={showLOIModal}
          getCandidateStageData={getCandidateStageData}
        />
      )}
    </tr>
  );
};

PlacedCandidates.defaultProps = {
  showSidePanel: false,
  openSidePanel: null,
};

PlacedCandidates.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    job_uuid: PropTypes.string,
    name: PropTypes.string,
    placed_candidate_loi_id: PropTypes.number,
    email: PropTypes.string,
    phone_numbers: PropTypes.array,
    candidate_skills: PropTypes.array,
    placed_candidate_id: PropTypes.number,
    work_experiences: PropTypes.array,
    status: PropTypes.string,
    placed: PropTypes.shape({
      stage_id: PropTypes.number,
      stage_name: PropTypes.string,
    }),
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
  }),
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  getCandidateStageData: PropTypes.func,
  isJobClosed: PropTypes.bool,
  showSidePanel: PropTypes.bool,
  openSidePanel: PropTypes.func,
};

export default PlacedCandidates;
