import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import classNames from "classnames";
import PropTypes from "prop-types";

import { getUserRoles } from "api/erp/organizationApi";
import { ALERT_TYPE, EMAIL_REGEX } from "app/constants";
import { LabeledInput } from "components/atoms";
import { Modal } from "components/molecules";
import formatRoleOption from "helpers/formatRoleOption";
import {
  characterValidation,
  requiredValidation,
  validFormatValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";
import {
  createUsersAction,
  getUsersAction,
  updateUsersAction,
} from "store/thunkActions/erp/userThunk";

const UserModal = ({
  selectedUser,
  currentPage,
  pageSize,
  totalEntries,
  setIsEditState,
  setSelectedUser,
  setSearchParams,
  newUrlSearchParams,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setError,
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const showAlert = useAlert();
  const [disableOption, setDisableOption] = useState(false);
  const [userRoles, setUserRoles] = useState([{}]);
  const [searchParams] = useSearchParams();

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  useEffect(() => {
    getUserRoles().then(({ data }) => {
      setUserRoles(data);
    });
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      reset({
        ...selectedUser,
        user_roles: selectedUser.roles?.map(
          ({ role_id: roleId, role_name: roleName }) => {
            if (roleName == "ADMIN") {
              setDisableOption(true);
            }
            return { id: roleId, name: roleName };
          }
        ),
        active: !selectedUser.active,
      });
    }
  }, [selectedUser]);

  const submitHandler = (data) => {
    if (selectedUser) {
      const dataWithoutActive = { ...data };
      dataWithoutActive.role_ids = data.user_roles.map(({ id }) => id);
      dataWithoutActive.active = !data.active;

      return dispatch(updateUsersAction(dataWithoutActive))
        .unwrap()
        .then(({ meta }) => {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
          dispatch(
            getUsersAction({
              page: currentPage,
              page_size: pageSize,
              status: searchParams.get("status"),
              roles: searchParams.getAll("roles"),
              searchTerm: searchParams.get("search_term"),
            })
          );
          setIsEditState(false);
          setSelectedUser(undefined);
        })
        .catch(({ errors, meta }) => {
          if (meta) {
            showAlert(ALERT_TYPE[meta.message_type], meta.message);
            setIsEditState(false);
            setSelectedUser(undefined);
          } else {
            setErrorsOnFields(errors);
          }
        });
    }
    const fieldValue = {
      role_ids: data.user_roles?.map(({ id }) => id),
      email: data.email,
      name: data.name,
    };

    return dispatch(createUsersAction(fieldValue))
      .unwrap()
      .then(() => {
        showAlert("success", "User created successfully!");
        setSearchParams(
          newUrlSearchParams(Math.ceil((totalEntries + 1) / pageSize))
        );

        setIsEditState(false);
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  return (
    <Modal
      title={selectedUser ? "Edit User" : "Create New User"}
      acceptButtonText={selectedUser ? "Update" : "Save"}
      acceptBtnType={isDirty ? "primary" : "disabled"}
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setIsEditState(false)}
      isDirty={isDirty}
    >
      <form
        data-testid="addUserForm"
        className="mb-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <LabeledInput
          id="name"
          labelClassNames={classNames(
            "block mt-2 mb-1 font-medium required",
            `${errors.name && "text-danger-main"}`
          )}
          label="Name"
          name="name"
          inputFieldId="name"
          inputClassNames={classNames(
            "w-full",
            `${errors.name ? "input-error" : "input"}`
          )}
          type="text"
          placeholder="Name is required"
          register={register}
          validation={{
            required: requiredValidation(),
            maxLength: {
              value: 100,
              message: characterValidation({ limit: 100 }),
            },
          }}
          errorMessage={errors.name?.message}
        />

        <LabeledInput
          id="email"
          labelClassNames={classNames(
            "block mt-2 mb-1 font-medium required",
            `${errors.email && "text-danger-main"}`
          )}
          label="Email"
          name="email"
          inputFieldId="email"
          inputClassNames={classNames(
            "w-full",
            `${errors.email ? "input-error" : "input"}`
          )}
          type="text"
          placeholder="Email is required"
          register={register}
          validation={{
            required: requiredValidation(),
            maxLength: {
              value: 255,
              message: characterValidation({ limit: 255 }),
            },
            pattern: {
              value: EMAIL_REGEX,
              message: validFormatValidation({ data: "email" }),
            },
          }}
          errorMessage={errors.email?.message}
        />

        <LabeledInput
          id="user_roles"
          labelClassNames={classNames(
            "block font-medium required mt-2 mb-1",
            `${errors.user_roles && "text-danger-main"}`
          )}
          label="Role"
          placeholder="Email is required"
        />

        <Controller
          name="user_roles"
          control={control}
          rules={{ required: requiredValidation() }}
          render={({ field }) => (
            <Select
              name="user_roles"
              options={
                disableOption
                  ? userRoles?.map((value) => ({ ...value, disabled: true }))
                  : userRoles
              }
              isMulti
              isClearable
              className="mb-6"
              isOptionDisabled={(option) => option.disabled}
              id="user_rolesSelect"
              menuPlacement="auto"
              maxMenuHeight={80}
              styles={reactSelectStyle(errors.user_roles)}
              placeholder="Select User Role"
              value={field.value}
              onChange={(data) => {
                field.onChange(data);
                const role = data.filter((item) => item.name == "ADMIN");
                if (role.length > 0) {
                  setDisableOption(true);
                  setValue("user_roles", role);
                } else {
                  setDisableOption(false);
                }
              }}
              getOptionLabel={(option) => formatRoleOption(option.name)}
              getOptionValue={(option) => option.id || option.name}
            />
          )}
        />

        {errors.user_roles && (
          <p className="text-sm text-danger-dark">
            {errors.user_roles.message}
          </p>
        )}

        {selectedUser && (
          <div className="mt-2">
            <div className="w-full flex gap-2">
              <p
                className={`mb-1 block font-medium ${
                  errors.active && "text-danger-main"
                }`}
              >
                Deactivate the User
              </p>
              <label
                htmlFor="deactivate-user"
                className="relative w-max cursor-pointer select-none items-center"
                data-testid="deactivate-user"
              >
                <input
                  defaultChecked={false}
                  name="active"
                  id="deactivate-user"
                  data-testid="deactivate-user-toggle"
                  type="checkbox"
                  className="h-6 w-16 cursor-pointer appearance-none rounded-full bg-gray-400"
                  {...register("active")}
                />
                <span className="font-small absolute bottom-2.5 right-1.5 text-xs uppercase text-white">
                  No
                </span>
                <span className="absolute bottom-2.5 right-9 text-xs font-medium uppercase text-white">
                  Yes
                </span>
                <span className="absolute bottom-[11px] right-8 h-4 w-7 transform rounded-full bg-white transition-transform" />
              </label>
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

UserModal.propTypes = {
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalEntries: PropTypes.number,
  setIsEditState: PropTypes.func,
  selectedUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        role_id: PropTypes.number,
        role_name: PropTypes.string,
      })
    ),
    active: PropTypes.bool,
  }),
  setSelectedUser: PropTypes.func,
  fetchUserData: PropTypes.func,
  setSearchParams: PropTypes.func,
  newUrlSearchParams: PropTypes.func,
};

export default UserModal;
