import moment from "moment";
import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";

const WorkExperience = ({ workExperience }) => {
  return (
    <>
      <div className="flex items-start justify-between">
        <h4 className="pt-2 pr-2 text-lg font-medium leading-5">
          {workExperience.company_name}
        </h4>
      </div>

      <p className="text-sm">
        {workExperience.title} |
        <span> {workExperience.employment_type_name}</span>
      </p>
      <p className="text-sm italic text-gray-main">
        {moment(workExperience.start_at).format("MMMM YYYY")} -{" "}
        {workExperience.is_presently_working
          ? "Present"
          : moment(workExperience.end_at).format("MMMM YYYY")}
      </p>

      <div className="mt-2">
        <RenderParsedHTML content={workExperience.description || ""} />
      </div>
    </>
  );
};

WorkExperience.propTypes = {
  workExperience: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    is_presently_working: PropTypes.bool,
    company_id: PropTypes.number,
    company_name: PropTypes.string,
    employment_type_id: PropTypes.number,
    employment_type_name: PropTypes.string,
  }),
};

export default WorkExperience;
