import PropTypes from "prop-types";

function removeContentBeforeFirstNewline(str) {
  const parts = str.split("\n");

  if (parts.length > 1) {
    return parts.slice(1).join("\n");
  }
  return str;
}

function output(compilationOutput) {
  if (
    compilationOutput.error === false &&
    typeof compilationOutput.output === "object"
  ) {
    return compilationOutput.output[1].output;
  }
  return removeContentBeforeFirstNewline(compilationOutput.output).replace(
    /\/tmp\/main\.(c|js|java|py|cpp):/g,
    ""
  );
}

const CodeOutput = ({ compilationOutput }) => {
  const detailedError =
    compilationOutput.error === "compilation" ? (
      <div className="text-red-500">{compilationOutput.error} error :(</div>
    ) : (
      compilationOutput.error === true && (
        <div className="text-red-500">wrong answer</div>
      )
    );

  return (
    <section className="py-3 px-5" id="code-output">
      <p className="my-2">Output</p>
      <div className="h-40 overflow-auto rounded-md border border-gray-light px-3 py-2 whitespace-pre-line">
        {detailedError}
        {output(compilationOutput)}
      </div>
    </section>
  );
};

CodeOutput.propTypes = {
  compilationOutput: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    output: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }).isRequired,
};

export default CodeOutput;
