import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { sendCustomEmail, showCustomEmail } from "api/erp/jobApi";
import { ALERT_TYPE } from "app/constants";
import { ActivityIndicator, LabeledInput } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import { Modal } from "components/molecules";
import { ATTACHMENT_SIZE, CUSTOM_ATTACHMENT_TYPE } from "constants/common";
import {
  characterValidation,
  minCharacterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";

const SendCustomEmailModal = ({
  candidate,
  currentStage,
  setShowModal,
  selectedIds,
  getCandidateStageData,
  allSelected,
}) => {
  const [attachment, setAttachment] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [customEmail, setCustomEmail] = useState();

  const showAlert = useAlert();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    clearErrors,
    watch,
  } = useForm();

  useEffect(() => {
    if (candidate?.mail_sent) {
      showCustomEmail(candidate.job_uuid, currentStage?.id, candidate.id).then(
        (response) => {
          setCustomEmail(response.data);
        }
      );
    }
  }, [
    candidate?.id,
    candidate?.job_uuid,
    candidate?.mail_sent,
    currentStage?.id,
  ]);

  const pdfMimeType = /application\/pdf|image\/(jpg|jpeg|png)/i;

  const setFileAttachment = (e) => {
    const file = e.target?.files[0];

    if (!file.type.match(pdfMimeType)) {
      setError("attachment", { message: CUSTOM_ATTACHMENT_TYPE });
    } else if (file.size >= 5 * 1000 * 1024) {
      setError("attachment", { message: ATTACHMENT_SIZE });
    } else {
      setAttachment(file);
      clearErrors("attachment");
    }
  };

  const sendMail = (data) => {
    const formData = new FormData();

    formData.append("subject", data.subject);
    formData.append("description", data.description);
    if (attachment) {
      formData.append("attachment", attachment);
    }
    if (allSelected) {
      formData.append("select_all", true);
    } else if (selectedIds) {
      selectedIds.forEach((id) => {
        formData.append("candidate_job_ids[]", id);
      });
    } else {
      formData.append("candidate_job_ids[]", candidate.id);
    }

    if (showConfirmationModal) {
      return sendCustomEmail(params.uuid, currentStage.id, formData)
        .then((response) => {
          setShowModal(false);
          showAlert(
            ALERT_TYPE[response.meta.message_type],
            response.meta.message
          );
          getCandidateStageData();
        })
        .catch((errors) => {
          setShowConfirmationModal(false);

          if (errors.response?.data?.meta) {
            return showAlert(
              ALERT_TYPE[errors.response.data.meta.message_type],
              errors.response.data.meta.message
            );
          }
        });
    }
  };

  const submitHandler = () => {
    setShowConfirmationModal(true);
  };

  return (
    <>
      {candidate?.mail_sent ? (
        <Modal
          title="View Custom Email"
          scrollClass="overflow-y-auto max-h-[35rem]"
          handleCancel={() => setShowModal(false)}
          customModalWidth="35rem"
          onOutsideClickHandler={() => setShowModal(false)}
          isAsyncClick={false}
        >
          {customEmail ? (
            <div>
              <div className="my-2">
                <label
                  htmlFor="subject"
                  className="mt-2 mb-1 block font-medium"
                >
                  Subject
                </label>
                <div className="border p-2 rounded-md hover:bg-gray-lighterShade3">
                  {customEmail?.subject}
                </div>
              </div>
              <div className="py-4 border px-2 my-4 rounded-md hover:bg-gray-lighterShade3 break-words">
                <RenderParsedHTML content={customEmail?.description || ""} />
              </div>
              {customEmail?.filename && (
                <div className="my-2 flex flex-col ">
                  <label
                    htmlFor="attachment"
                    className="mt-2 block font-medium "
                  >
                    Attachment
                  </label>
                  <div className="mt-3 flex flex-col ">
                    <div className="border mb-2 rounded-md">
                      <a
                        title="View Attachment"
                        target="_blank"
                        rel="noreferrer"
                        href={customEmail.attachment}
                        className="flex p-2 text-left text-sm text-primary-main hover:bg-primary-lightest"
                      >
                        {customEmail.filename}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <ActivityIndicator />
          )}
        </Modal>
      ) : (
        <Modal
          title="Custom Email"
          scrollClass="overflow-y-auto xs:max-h-[32rem] max-h-[28rem]"
          acceptBtnType={!watch("subject") ? "disabled" : "primary"}
          handleCancel={() => {
            setShowModal(false);
            setAttachment();
          }}
          acceptButtonText="Send"
          testId="send-email-modal"
          customButtonWidth={32}
          customModalWidth="35rem"
          isAsyncClick={false}
          handleAccept={handleSubmit((data) => submitHandler(data))}
        >
          <div className=" mb-2 text-center">
            <ActionAlert
              message={
                <p className="xs:pr-0 text-sm ">
                  Custom emails once sent, cannot be edited again.
                </p>
              }
              alertType="primary"
              iconName="info"
              isButtonRequire={false}
              roundedBorder
              customClassName="w-full text-center"
              iconSize={24}
            />
          </div>
          <form
            data-testid="Send-email-Form"
            className="mt-4"
            onSubmit={handleSubmit((data) => submitHandler(data))}
          >
            <div className="my-2">
              <LabeledInput
                name="subject"
                id="subject"
                type="text"
                data-testid="subject-input"
                label="Subject"
                labelClassNames={classNames(
                  "required mt-2 mb-1 block font-medium ",
                  errors.subject && "text-danger-main"
                )}
                inputClassNames={`${
                  errors.subject ? "input input-error" : "input"
                } w-full`}
                register={register}
                validation={{
                  required: requiredValidation(),
                  validate: (value) => value.trim() !== "" || "can't be blank",
                  maxLength: {
                    value: 70,
                    message: characterValidation({ limit: 70 }),
                  },
                }}
                errorMessage={errors.subject?.message}
              />
            </div>

            <div>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                rules={{
                  required: requiredValidation(),
                  validate: (value) =>
                    value.trim().length >= 250 ||
                    minCharacterValidation({ limit: 250 }),
                  maxLength: {
                    value: 3000,
                    message: characterValidation({ limit: 3000 }),
                  },
                  minLength: {
                    value: 250,
                    message: minCharacterValidation({ limit: 250 }),
                  },
                }}
                render={({ field }) => (
                  <RichTextEditor
                    id="description"
                    content={field.value}
                    onChange={(content) => field.onChange(content)}
                    fieldError={!!errors.description?.message}
                    containerClassNames="mt-5"
                  />
                )}
              />
              {errors.description && (
                <p className="mt-0 text-sm text-danger-dark">
                  {errors.description?.message}
                </p>
              )}
            </div>

            {attachment && (
              <div className="flex flex-col ">
                <div className="flex w-fit break-all text-sm flex-row rounded-md bg-gray-200 py-1 items-center px-2 my-1 gap-1">
                  {attachment.name || attachment.attachment_file_name}
                  <button
                    title="Remove Attachment"
                    type="button"
                    onClick={() => setAttachment()}
                    className="cursor-pointer rounded-full hover:bg-primary-lightest"
                  >
                    <AiOutlineClose className="text-sm" />
                  </button>
                </div>
              </div>
            )}
            <label
              htmlFor="attachment"
              className={`pointer-events-none my-2 block font-medium ${
                errors.attachment && "text-danger-main"
              }`}
              {...register("attachment")}
            >
              Attachment{" "}
              <span className="text-xs italic">( less than 5 mb )</span>
            </label>

            <label
              htmlFor="attachment"
              className="btn-text-primary bg-secondary-lighter text-sm"
            >
              Choose File
            </label>

            <input
              id="attachment"
              className={`${
                errors.attachment ? "input-error" : ""
              } hidden w-[229px]`}
              type="file"
              accept=".pdf, .png, .jpeg, .jpg"
              {...register("attachment")}
              onChange={setFileAttachment}
            />
            {errors.attachment && (
              <p className="text-sm text-danger-dark mt-1">
                {errors.attachment.message}
              </p>
            )}
          </form>
        </Modal>
      )}

      {showConfirmationModal && (
        <Modal
          title="Are you sure?"
          description="Send email to the candidate? This will be an irreversible action."
          acceptBtnType="primary"
          acceptButtonText="Send"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          handleAccept={handleSubmit((data) => sendMail(data))}
          handleReject={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};

SendCustomEmailModal.propTypes = {
  candidate: PropTypes.shape({
    candidate_id: PropTypes.number,
    job_uuid: PropTypes.string.isRequired,
    id: PropTypes.number,
    mail_sent: PropTypes.bool,
  }),
  selectedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  getCandidateStageData: PropTypes.func,
  allSelected: PropTypes.bool,
  currentStage: PropTypes.shape({
    id: PropTypes.number,
  }),
  setShowModal: PropTypes.func,
};

export default SendCustomEmailModal;
