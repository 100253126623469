import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createEducationQualification,
  deleteEducationQualification,
  getEducationQualifications,
  updateEducationQualification,
} from "api/candidate/profileApi";

export const getEducationQualificationsAction = createAsyncThunk(
  "educationQualification/get",
  async () => {
    const response = await getEducationQualifications();
    return response.data;
  }
);

export const createEducationQualificationsAction = createAsyncThunk(
  "education-qualification/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createEducationQualification(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEducationQualificationsAction = createAsyncThunk(
  "education-qualification/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateEducationQualification(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteEducationQualificationAction = createAsyncThunk(
  "education-qualification/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteEducationQualification(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
