export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WEBSITE_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+~#?&/=]*)$/i;

export const BRAND_URL = "https://joballey.in/";

export const SESSION_EXPIRED = "You have been logged out from other sessions.";
export const SESSION_EXPIRED_MESSAGE =
  "you are already logged in from another browser/device.";

export const FEATURE_FLAGS = { MOCK_TEST: "MOCK TEST" };

export const NAVBAR_PATH_EXCEPTIONS = ["/question_paper", "/instructions"];

export const QUESTION_TYPE = [{ name: "mcq" }, { name: "coding" }];
export const DURATION_TYPE = [{ name: "Sec" }, { name: "Min" }];
export const VIEW_TYPE = [{ name: "write" }, { name: "preview" }];
export const WEBSITE_TYPE = [{ name: "Portfolio" }];

export const QUESTION_PAPER_TYPE = [
  { label: "MCQ", value: "mcq" },
  { label: "CODING", value: "coding" },
];

export const STAGE_TYPE = [
  { label: "Exam", value: "exam" },
  { label: "Group Discussion", value: "gd" },
  { label: "Interview", value: "interview" },
];

export const SUGGESTED_BULK_STAGES = [
  {
    name: "Aptitude Round",
    stage_type: "exam",
    sequence: 1,
  },
  {
    name: "Machine Test Round",
    stage_type: "exam",
    sequence: 2,
  },
  {
    name: "Interview Round",
    stage_type: "interview",
    sequence: 3,
  },
];

export const CAMERA_OPTION = [
  { name: "REQUIRED", value: "REQUIRED" },
  // { name: "OPTIONAL", value: "OPTIONAL" }, todo: future plans
  { name: "NOT_REQUIRED", value: "NOT_REQUIRED" },
];

export const EXAM_CANDIDATE_STATUS = [
  { label: "Uncategorized", value: "UNCATEGORIZED", color: "#646464" },
  { label: "Absent", value: "ABSENT", color: "#333333" },
  { label: "Approved", value: "APPROVED", color: "#143A7B" },
  { label: "On hold", value: "ON_HOLD", color: "#FC9403" },
  { label: "Placed", value: "PLACED", color: "#45C65A" },
  { label: "Rejected", value: "REJECTED", color: "#DC3545" },
  { label: "Disqualified", value: "DISQUALIFIED", color: "#DC3545" },
  { label: "Withdrew", value: "WITHDREW", color: "#A74F48" },
];

export const INTERVIEW_GD_CANDIDATE_STATUS = [
  { label: "Uncategorized", value: "UNCATEGORIZED", color: "#646464" },
  { label: "Absent", value: "ABSENT", color: "#333333" },
  { label: "Approved", value: "APPROVED", color: "#143A7B" },
  { label: "On hold", value: "ON_HOLD", color: "#FC9403" },
  { label: "Placed", value: "PLACED", color: "#45C65A" },
  { label: "Rejected", value: "REJECTED", color: "#DC3545" },
  { label: "Withdrew", value: "WITHDREW", color: "#A74F48" },
];

export const AA_CANDIDATE_STATUS = [
  { label: "Uncategorized", value: "UNCATEGORIZED", color: "#646464" },
  { label: "Rejected", value: "REJECTED", color: "#DC3545" },
  { label: "Shortlisted", value: "SHORTLISTED", color: "#143A7B" },
  { label: "On hold", value: "ON_HOLD", color: "#FC9403" },
];

export const PLACED_CANDIDATE_STATUS = [
  { label: "LOI Sent", value: "LOI_SENT" },
  { label: "LOI Pending", value: "LOI_PENDING" },
  { label: "Accepted", value: "ACCEPTED" },
  { label: "Withdrew", value: "WITHDREW" },
];

export const PER_PAGE_RECORDS = [
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "75", value: 75 },
  { label: "100", value: 100 },
];

export const EVENT_TYPES = [
  { label: "Ongoing Events", key: "ongoing" },
  { label: "Upcoming Events", key: "upcoming" },
  { label: "Past Events", key: "past" },
];

export const INTERVIEW_TYPES = [
  { label: "Ongoing Interviews", key: "ongoing" },
  { label: "Upcoming Interviews", key: "upcoming" },
  { label: "Past Interviews", key: "past" },
];

export const ERP_PANEL_TYPES = [
  { label: "Ongoing Panels", key: "ongoing" },
  { label: "Upcoming Panels", key: "upcoming" },
  { label: "Feedback Pending", key: "feedback_pending" },
  { label: "Past Panels", key: "past" },
];

export const QUESTION_PAGE_TYPES = [
  { label: "Question Papers", key: "question_papers" },
  { label: "Question Bank", key: "question_bank" },
  { label: "Categories", key: "categories" },
  { label: "Question Tags", key: "question_tags" },
];

export const WIDGET_TYPES = [
  { label: "Apply Button", key: "button_only" },
  { label: "Job Overview", key: "overview" },
  { label: "Job Details", key: "details" },
];

export const LOBBY_TYPES = [
  { label: "All Applicants", key: "ALL_APPLICANTS" },
  { label: "Placed", key: "PLACED" },
];

export const STATE_LIST = [
  {
    name: "Andaman and Nicobar Islands",
  },
  {
    name: "Andhra Pradesh",
  },
  {
    name: "Arunachal Pradesh",
  },
  {
    name: "Assam",
  },
  {
    name: "Bihar",
  },
  {
    name: "Chandigarh",
  },
  {
    name: "Chhattisgarh",
  },
  {
    name: "Dadra and Nagar Haveli",
  },
  {
    name: "Daman and Diu",
  },
  {
    name: "Delhi",
  },
  {
    name: "Goa",
  },
  {
    name: "Gujarat",
  },
  {
    name: "Haryana",
  },
  {
    name: "Himachal Pradesh",
  },
  {
    name: "Jammu and Kashmir",
  },
  {
    name: "Jharkhand",
  },
  {
    name: "Karnataka",
  },
  {
    name: "Kerala",
  },
  {
    name: "Lakshadweep",
  },
  {
    name: "Madhya Pradesh",
  },
  {
    name: "Maharashtra",
  },
  {
    name: "Manipur",
  },
  {
    name: "Meghalaya",
  },
  {
    name: "Mizoram",
  },
  {
    name: "Nagaland",
  },
  {
    name: "Odisha",
  },
  {
    name: "Puducherry",
  },
  {
    name: "Punjab",
  },
  {
    name: "Rajasthan",
  },
  {
    name: "Sikkim",
  },
  {
    name: "Tamil Nadu",
  },
  {
    name: "Telangana",
  },
  {
    name: "Tripura",
  },
  {
    name: "Uttar Pradesh",
  },
  {
    name: "Uttarakhand",
  },
  {
    name: "West Bengal",
  },
];

export const MAX_FILE_SIZE = 1000000;

export const EDITOR_FORMAT = {
  python: "python",
  "c++": "cpp",
  c: "c",
  java: "java",
  javascript: "javascript",
  ruby: "ruby",
};

export const ARROW_TYPE = ["LEFT", "RIGHT"];
export const ICON_SIZE_HASH = {
  sm: 16,
  smd: 20,
  md: 24,
  lg: 32,
};

export const ALERT_TYPE = {
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  INFO: "info",
};

export const DECIMAL_INFO = {
  MIN: "must be greater than 0",
  MIN_ZERO: "must be greater than or equal to 0",
  MAX: "must be less than or equal to 100",
  PATTERN: "must be a positive and valid number with maximum 2 decimal places",
};

export const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const WEB_PUSH_PUBLIC_VAPID_KEY =
  process.env.REACT_APP_WEB_PUSH_VAPID_PUBLIC_KEY;

export const API_URL = process.env.REACT_APP_API_URL;

export const TERMS_OF_SERVICE = "https://joballey.in/terms-and-conditions";
export const PRIVACY_POLICY = "https://joballey.in/privacy-and-policy";

export const EXAM_EMAIL_MESSAGE =
  "Result will only be emailed to candidates who have appeared for the exam and have been approved/rejected. This will be an irreversible action.";
export const INTERVIEW_EMAIL_MESSAGE =
  "Result will only be emailed to candidates who have appeared for the interview, their feedback has been shared and have been approved/rejected. This will be an irreversible action.";

export const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

export const SORT_BY = {
  CANDIDATE_NAME: "CANDIDATE_NAME",
  SLOT: "SLOT",
  START_TIME: "START_TIME",
  TOTAL_MARKS: "TOTAL_MARKS",
  CREATED_AT: "CREATED_AT",
};

// We are using this to reset the data in filter and can be refactored later using api call
export const CANDIDATE_ACADEMIC_LIST = [
  { name: "10th Standard", id: 1 },
  { name: "12th Standard", id: 2 },
  { name: "Graduation", id: 3 },
  { name: "PostGraduation", id: 4 },
];

export const PROFICIENCY_LEVELS = [
  { label: "Beginner", value: "BEGINNER" },
  { label: "Intermediate", value: "INTERMEDIATE" },
  { label: "Advanced", value: "ADVANCED" },
];

export const PIPELINE_FILTER_PARAMS_NAME = [
  "candidate_name",
  "candidate_id",
  "cutoff_marks",
  "statuses",
  "groups",
  "from_date",
  "has_different_sectional_cut_off_marks",
  "academic_marks",
  "willing_to_relocate",
  "locations",
  "has_portfolio",
  "project",
];

export const PIPELINE_PRESERVED_PARAMS_NAME = [
  "lobby",
  "stageId",
  "sort_by",
  "sort_order",
];

export const JOB_RESTRICTED_BY = [
  { label: "Preferred Colleges", value: "colleges" },
  { label: "Preferred Qualifications", value: "qualifications" },
  { label: "Preferred Skills", value: "skills" },
];

export const GD_CARD_COUNT = 3;
