import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { applicationTimeline } from "api/candidate/jobApi";
import { Button } from "components/atoms";
import Pill from "components/atoms/Pill";
import StatusTimeline from "components/atoms/StatusTimeline";
import { Modal } from "components/molecules";
import { timelineStatusObj } from "constants/jobs";

const JobStatus = ({ jobUuid, title, testId, getJobStatus }) => {
  const [currentStatus, setCurrentStatus] = useState();
  const [currentRecord, setCurrentRecord] = useState([]);
  const [isRecordModal, setIsRecordModal] = useState(false);
  useEffect(() => {
    applicationTimeline(jobUuid).then(({ data }) => {
      setCurrentStatus(data.current_status.toUpperCase());
      setCurrentRecord(data.status_history.map((value) => value.toUpperCase()));
    });
  }, [jobUuid, getJobStatus]);

  const toggleRecordModal = () => {
    setIsRecordModal((prev) => !prev);
  };

  const currentRecordType = () => {
    let type;
    if (currentStatus?.endsWith(timelineStatusObj.rejected)) {
      type = "danger";
    } else if (currentStatus?.endsWith(timelineStatusObj.loi_accepted)) {
      type = "success";
    } else {
      type = "info";
    }
    return type;
  };

  return (
    <div data-testid={testId}>
      {currentStatus && (
        <Button
          title="See Status"
          onClick={toggleRecordModal}
          btnClassName="py-2"
        >
          <Pill text={currentStatus} type={currentRecordType()} />
        </Button>
      )}
      {isRecordModal && (
        <Modal
          modalType="message"
          title={`${title}-Application Timeline`}
          handleCancel={toggleRecordModal}
          onOutsideClickHandler={toggleRecordModal}
          scrollClass="overflow-y-auto max-h-[28rem]"
        >
          <StatusTimeline record={currentRecord} />
        </Modal>
      )}
    </div>
  );
};

JobStatus.propTypes = {
  jobUuid: PropTypes.string,
  title: PropTypes.string,
  testId: PropTypes.string,
  getJobStatus: PropTypes.bool,
};

JobStatus.defaultProps = {
  jobUuid: "",
  title: "",
  testId: "",
  getJobStatus: false,
};

export default JobStatus;
