import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillDelete, AiFillEdit, AiOutlinePlus } from "react-icons/ai";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ALERT_TYPE, ICON_SIZE_HASH } from "app/constants";
import { Button, EmptyMessage } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import { Modal } from "components/molecules";
import useAlert from "hooks/useAlert";
import {
  deleteCategoryAction,
  getCategoryAction,
  getFilterCategoryAction,
} from "store/thunkActions/erp/categoriesThunk";

import CategoriesModal from "./CategoriesModal";

const CategoriesIndex = ({ showQPImportAlert }) => {
  const dispatch = useDispatch();
  const showAlert = useAlert();

  const [isCategoryEditing, setIsCategoryEditing] = useState(false);
  const [isCategoryDeleting, setIsCategoryDeleting] = useState(false);
  const [category, setCategory] = useState({});
  const [isCategoryAdding, setIsCategoryAdding] = useState(false);
  const { data } = useSelector((state) => state.categories);
  const [searchParams, setSearchParams] = useSearchParams();
  const useFormProps = useForm();
  const [categoriesList, setCategoriesList] = useState();

  const QUESTION_CATEGORY_FILTER = [
    {
      label: "Parent Category",
      placeholder: "parent category",
      name: "parent_category",
      multiSelect: false,
      dropdownOptions: categoriesList,
    },
  ];

  useEffect(() => {
    const searchTerm = searchParams.get("search_term");
    const parentCategoryId = searchParams.get("parent_category");
    dispatch(
      getFilterCategoryAction({
        parent_category_id: parentCategoryId,
        search_term: searchTerm,
      })
    ).then(() => resetFilterForm());
  }, [searchParams]);

  useEffect(() => {
    dispatch(getCategoryAction())
      .unwrap()
      .then((response) => {
        setCategoriesList(
          response.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      });
  }, [dispatch]);

  const onCategoryDelete = () => {
    return dispatch(deleteCategoryAction(category))
      .unwrap()
      .then(({ meta }) => {
        setIsCategoryDeleting(false);
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        dispatch(getFilterCategoryAction());
      })
      .catch(({ meta, errors }) => {
        if (errors) {
          showAlert(
            ALERT_TYPE.DANGER,
            errors.questions[0] ?? errors.questions[0]
          );
        } else {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
        }
      });
  };

  function Duration(second) {
    let remainingSeconds = 0;
    const minutes = Math.floor(second / 60);
    remainingSeconds = second % 60;
    let result = "";

    if (minutes > 0) {
      result = `${minutes} Min `;
    }
    if (remainingSeconds > 0) {
      result = `${result + remainingSeconds} Sec`;
    }
    return result;
  }

  const anyFilterApplied = () => searchParams.has("parent_category");

  const generateFilterCriterias = () => {
    const filterCriterias = [];

    if (searchParams.has("parent_category")) {
      filterCriterias.push("Parent Category");
    }

    return filterCriterias.join(" | ");
  };

  const resetFilterForm = () => {
    useFormProps.reset({
      parent_category: searchParams.has("parent_category")
        ? QUESTION_CATEGORY_FILTER[0].dropdownOptions?.find(
            (item) => String(item.value) === searchParams.get("parent_category")
          )
        : undefined,
    });
  };

  if (!data) {
    return <ActivityIndicator />;
  }

  return (
    <div>
      <div
        className={classNames(
          "py-4 sticky z-10 bg-gray-lighterShade3 flex gap-4 min-h-[50px] justify-between items-start",
          showQPImportAlert ? "top-[230px] sm:top-[175px]" : "top-[94px]"
        )}
      >
        <div>
          <div className="sm:max-w-[450px]">
            <SearchBar
              anyFilterApplied={anyFilterApplied}
              searchParams={searchParams}
              resetFilterForm={resetFilterForm}
              useFormProps={useFormProps}
              setSearchParams={setSearchParams}
              filterField={QUESTION_CATEGORY_FILTER}
              searchBarPlaceholder="Search by category"
              defaultSearchParams={{
                tab: searchParams.get("tab"),
              }}
            />
          </div>
          {anyFilterApplied() && (
            <p className="my-3 text-sm text-primary-main align-top">
              <span className="mr-1"> Filtered by: </span>

              <span>{generateFilterCriterias()}</span>
            </p>
          )}
        </div>
        <button
          title="Create Category"
          data-testid="add-category-btn"
          className="z-10 items-center rounded-full bg-primary-main p-2 hover:bg-primary-dark"
          onClick={() => setIsCategoryAdding(true)}
        >
          <AiOutlinePlus className="text-2xl text-white" />
        </button>
        {isCategoryAdding && (
          <CategoriesModal setIsEditState={setIsCategoryAdding} />
        )}
      </div>
      {data?.length > 0 && (
        <div className="overflow-x-auto">
          <table className="w-full table-fixed border-collapse text-sm">
            <thead className="border-b-2 text-lg font-bold capitalize text-primary-main">
              <tr>
                <th className="w-80 p-2 pl-4 text-left md:pl-4">Category</th>
                <th className="w-40 p-2 text-center">Default Duration</th>
                <th className="w-80 p-2 text-center">Parent Category</th>
                <th className="w-20 xl:w-32 2xl:w-24"> </th>
              </tr>
            </thead>
            <tbody className=" divide-y">
              {data.map((category) => {
                return (
                  <tr
                    key={category.id}
                    data-testid={`category-id-${category.id}`}
                    className="w-full justify-between bg-white px-2 py-4 hover:bg-gray-lighter md:pl-2"
                  >
                    <td
                      className="py-4 pl-2 md:pl-4"
                      data-testid="category-name"
                    >
                      {category.name}
                    </td>

                    <td
                      className="py-4 text-center"
                      data-testid="category-default-duration"
                    >
                      {Duration(category.default_duration)}
                    </td>
                    <td
                      className="py-4 text-center"
                      data-testid="category-perent-category"
                    >
                      {category.parent_category}
                    </td>
                    <td className="flex flex-row justify-end gap-2 py-4 md:pr-4">
                      <Button
                        title="Edit"
                        testId="category-edit-btn"
                        onClick={() => {
                          setCategory(category);
                          setIsCategoryEditing(true);
                        }}
                        btnClassName="p-2 ml-2 rounded-md items-center hover:bg-primary-lighter py-1"
                      >
                        <AiFillEdit className="text-xl text-primary-dark" />
                      </Button>

                      <Button
                        title="Delete"
                        testId="category-delete-btn"
                        onClick={() => {
                          setCategory(category);
                          setIsCategoryDeleting(true);
                        }}
                        btnClassName="p-2 ml-2 rounded-md items-center hover:bg-danger-lighter py-1"
                      >
                        <AiFillDelete className="text-xl text-danger-dark" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {data?.length === 0 && <EmptyMessage message="" />}
      {isCategoryEditing && (
        <CategoriesModal
          category={category}
          categoryList={data}
          setIsEditState={setIsCategoryEditing}
        />
      )}

      {isCategoryDeleting && (
        <Modal
          title="Are you sure?"
          testId="category-delete-modal"
          acceptButtonText="Yes"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="No"
          handleAccept={onCategoryDelete}
          handleReject={() => setIsCategoryDeleting(false)}
        >
          {category.no_of_sub_categories > 0 && (
            <div className="mb-5 flex  items-center  gap-3 text-primary-main opacity-80 ">
              <div>
                <BsFillExclamationCircleFill
                  size={ICON_SIZE_HASH.md}
                  className="h-6 w-6"
                />
              </div>
              <div>
                This category has {category.no_of_sub_categories} subcategor
                {category.no_of_sub_categories > 1 ? "ies. They" : "y. It"} will
                not have any parent category if you proceed.
              </div>
            </div>
          )}

          <div className="p-1">
            Are you sure you want to delete the category
            <span className="font-semibold italic"> {category.name}</span>?
          </div>
        </Modal>
      )}
    </div>
  );
};

CategoriesIndex.propTypes = {
  showQPImportAlert: PropTypes.bool,
};

export default CategoriesIndex;
