import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createStage,
  deleteStage,
  getStages,
  updateStage,
} from "api/erp/jobApi";

export const createStageAction = createAsyncThunk(
  "stage/create",
  async ({ jobUuid, data }, { rejectWithValue }) => {
    try {
      const response = await createStage(jobUuid, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStagesAction = createAsyncThunk("stage/get", async (id) => {
  const response = await getStages(id);
  return response;
});

export const updateStageAction = createAsyncThunk(
  "stage/update",
  async ({ jobUuid, stageId, data }, { rejectWithValue }) => {
    try {
      const response = await updateStage(jobUuid, stageId, data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStageAction = createAsyncThunk(
  "stage/delete",
  async ({ jobUuid, stageId }, { rejectWithValue }) => {
    try {
      const response = await deleteStage(jobUuid, stageId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
