import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "components/atoms";
import BasicInfo from "components/candidate/Profile/BasicInfo";
import ContactDetails from "components/candidate/Profile/ContactDetails";
import Education from "components/candidate/Profile/Education";
import WorkExperiences from "components/candidate/Profile/Experience";
import Interests from "components/candidate/Profile/Interests";
import Languages from "components/candidate/Profile/Languages";
import PhoneNumbers from "components/candidate/Profile/PhoneNumbers";
import Projects from "components/candidate/Profile/Projects";
import Skills from "components/candidate/Profile/Skills";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

const CreateResume = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: {
          currentPageName: "Review Details",
        },
      })
    );
  }, []);

  return (
    <div className="h-full min-h-screen pt-5">
      <BasicInfo currentUser={currentUser.data} reviewProfile />

      <div className="mt-2 grid h-full grid-cols-1 gap-0 px-2 pt-2 pb-8 sm:px-8 md:rounded-t-lg md:px-6 xl:grid-cols-12 xl:gap-8">
        <div className="xl:col-span-7">
          <WorkExperiences />
          <Projects />
          <Education />
          <ContactDetails />
        </div>
        <div className="xl:col-span-5">
          <Skills />
          <Languages />
          <Interests />
          <PhoneNumbers />
        </div>
      </div>

      <div className="fixed bottom-0 ml-4 flex w-full gap-4 border-t-2 bg-gray-lighterShade3 p-3">
        <Button
          btnClassName="mx-2 text-sm"
          type={currentUser.data.is_profile_completed ? "primary" : "disabled"}
          btnName="Preview"
          onClick={() => navigate("/create-resume-preview")}
        />
        <Button
          btnClassName="mx-2 text-sm"
          type="secondary"
          btnName="Cancel"
          onClick={() => navigate("/profile")}
        />
      </div>
    </div>
  );
};

export default CreateResume;
