import classNames from "classnames";
import PropTypes from "prop-types";

import Project from "./Project";

const Projects = ({ projects, modalView }) => {
  return (
    <div
      className={classNames(
        "mx-auto w-full bg-white",
        !modalView && "mt-4 rounded-lg border border-gray-light p-4"
      )}
    >
      <div className="flex items-center justify-between">
        <h3
          className={classNames(
            "font-normal",
            modalView ? "text-xl" : "text-2xl "
          )}
        >
          Projects
        </h3>
      </div>

      {!projects?.length && (
        <span className="mt-4 block text-sm italic text-gray-main">
          No projects to show
        </span>
      )}

      {projects?.map((project) => {
        return (
          <div
            key={project.id}
            className="border-b border-gray-dark py-4 last:border-b-0"
          >
            <Project project={project} />
          </div>
        );
      })}
    </div>
  );
};
Projects.propTypes = {
  projects: PropTypes.array,
  modalView: PropTypes.bool,
};

export default Projects;
