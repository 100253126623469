import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { SITE_TITLE } from "constants/common";
import { getDomainName } from "helpers/utils";

const PipelineMetaContent = ({ getTitle }) => {
  const { job } = useSelector((state) => state.job);

  return (
    <Helmet>
      <title>{`${getTitle()} - ${SITE_TITLE}`}</title>
      <meta
        name="description"
        content={`View hiring pipeline for ${job?.title} by ${job?.created_by} on JobAlley platform.`}
      />
      <meta property="og:image" content="/joballey_logo_dark_text.png" />

      <meta
        property="og:url"
        content={`https://erp.${getDomainName()}/jobs/${job?.uuid}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${job?.title} - ${SITE_TITLE}`} />
      <meta
        property="og:description"
        content={`View hiring pipeline for${job?.title} by ${job?.created_by} on JobAlley platform.`}
      />
      <meta property="og:image" content="/joballey_logo_dark_text.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="og:url"
        content={`https://erp.${getDomainName()}/jobs/${job?.uuid}`}
      />
      <meta property="twitter:domain" content={`erp.${getDomainName()}`} />
      <meta name="twitter:title" content={`${job?.title} - ${SITE_TITLE}`} />
      <meta
        name="twitter:description"
        content={`View hiring pipeline for ${job?.title} by ${job?.created_by} on JobAlley platform.`}
      />
      <meta name="twitter:image" content="/joballey_logo_dark_text.png" />
    </Helmet>
  );
};

PipelineMetaContent.propTypes = {
  getTitle: PropTypes.func,
};

PipelineMetaContent.defaultProps = {
  getTitle: () => {},
};

export default PipelineMetaContent;
