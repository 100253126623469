import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { createGroups } from "api/erp/jobApi";
import { ALERT_TYPE } from "app/constants";
import { Modal } from "components/molecules";
import { requiredValidation } from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";

const GDModal = ({
  currentStage,
  setShowGDModal,
  getGDDetails,
  candidateCount,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    setError,
    watch,
  } = useForm();

  const params = useParams();
  const showAlert = useAlert();

  const numberOfGroups = watch("number_of_groups");

  const anyFieldEmpty = !numberOfGroups;

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    if (Number(candidateCount) < 1) {
      return showAlert(
        ALERT_TYPE.WARNING,
        "You have to add minimum one candidate to create a group."
      );
    }

    if (!Number.isInteger(Number(data.number_of_groups))) {
      return setError("number_of_groups", {
        message: "Please enter integer value",
      });
    }

    const gdData = {
      number_of_groups: Number(data.number_of_groups),
    };

    return createGroups(params.uuid, currentStage.id, gdData)
      .then((response) => {
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );

        setShowGDModal(false);
        getGDDetails();
      })
      .catch(({ errors, response }) => {
        if (response) {
          showAlert(
            ALERT_TYPE[response.data.meta.message_type],
            response.data.meta.message
          );
        } else {
          setErrorsOnFields(errors);
        }
      });
  };

  return (
    <Modal
      title="Create Groups"
      setOpen={setShowGDModal}
      testId="add-and-edit-gd-modal"
      acceptButtonText="Create"
      acceptBtnType={anyFieldEmpty || !isDirty ? "disabled" : "primary"}
      acceptBtnSize="smResponsive"
      acceptBtnClassName="sm:ml-3"
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setShowGDModal(false)}
      isDirty={isDirty}
    >
      <form
        data-testid="add-and-edit-gd-modal-form"
        className="my-4"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <label
          htmlFor="number_of_groups"
          className={`required mt-2 mb-1 block font-medium ${
            errors.number_of_groups && "text-danger-main"
          }`}
        >
          Number of Groups
        </label>

        <input
          id="number_of_groups"
          className={`${
            errors.number_of_groups ? "input-error" : "input"
          } mb-2 w-full`}
          type="number"
          placeholder="Number of Groups"
          {...register("number_of_groups", {
            required: requiredValidation(),
            max: {
              value: 9999,
              message: "Maximum value is 9999",
            },
            min: {
              value: 1,
              message: "Please enter positive integer",
            },
          })}
        />

        {errors.number_of_groups && (
          <p className="text-sm text-danger-dark">
            {errors.number_of_groups.message}
          </p>
        )}
      </form>
    </Modal>
  );
};

GDModal.defaultProps = {
  setShowGDModal: () => {},
  currentStage: {},
  getGDDetails: () => {},
  candidateCount: 0,
};

GDModal.propTypes = {
  setShowGDModal: PropTypes.func,
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),
  getGDDetails: PropTypes.func,
  candidateCount: PropTypes.number,
};

export default GDModal;
