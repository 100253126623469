import { useEffect, useState } from "react";
import { bool, func, oneOf, string } from "prop-types";

import { alertIconTypeMapper } from "helpers/alert";

const Alert = ({ type, isAlertTriggered, setIsAlertTriggered, message }) => {
  const AlertIcon = alertIconTypeMapper(type);

  const [typeColors, setTypeColors] = useState({
    backgroundColor: undefined,
    textColor: undefined,
    borderColor: undefined,
  });

  useEffect(() => {
    if (type == "danger") {
      setTypeColors({
        backgroundColor: "bg-danger-alertBg",
        textColor: "text-danger-alertDark",
        borderColor: "border-danger-alertDark",
      });
    } else if (type == "info") {
      setTypeColors({
        backgroundColor: "bg-secondary-alertBg",
        textColor: "text-secondary-alertDark",
        borderColor: "border-secondary-alertDark",
      });
    } else if (type == "warning") {
      setTypeColors({
        backgroundColor: "bg-warning-alertBg",
        textColor: "text-warning-alertDark",
        borderColor: "border-warning-alertDark",
      });
    } else {
      setTypeColors({
        backgroundColor: "bg-success-alertBg",
        textColor: "text-success-alertDark",
        borderColor: "border-success-alertDark",
      });
    }
  }, [type]);

  return (
    <div
      className={`${typeColors.backgroundColor} border ${
        typeColors.borderColor
      } ${
        typeColors.textColor
      } fixed top-10 left-0 right-0 z-40 mx-auto flex w-11/12 self-center rounded-full py-1 pl-1 pr-5 shadow-xl md:w-4/6 lg:w-5/12 ${
        isAlertTriggered ? "animate-fade" : "hidden"
      }
    `}
      role="alert"
      onAnimationEnd={() => setIsAlertTriggered(false)}
    >
      <span className="self-center rounded-full">
        <AlertIcon size={36} />
      </span>
      <span className="ml-2 mr-8 block self-center">{message}</span>
    </div>
  );
};

Alert.propTypes = {
  type: oneOf(["success", "info", "danger", "warning"]).isRequired,
  message: string.isRequired,
  isAlertTriggered: bool.isRequired,
  setIsAlertTriggered: func.isRequired,
};

export default Alert;
