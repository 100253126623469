import { useEffect, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { getRestrictedJobDetailWithToken } from "api/candidate/jobApi";
import { ALERT_TYPE } from "app/constants";
import { ActivityIndicator } from "components/atoms";
import Button from "components/atoms/Button";
import { SITE_TITLE } from "constants/common";
import { JOBS_STATUSES } from "constants/jobs";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { handleApplyJobAction } from "store/thunkActions/candidate/jobApplyThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

import Profile from "../Profile";

import ConfirmApplyModal from "./ConfirmApplyModal";

const RestrictedJobWithToken = () => {
  const [showModal, setShowModal] = useState(false);
  const [jobDetails, setJobDetails] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const params = useParams();

  const { educationQualifications } = useSelector(
    (state) => state.educationQualifications
  );
  const { candidateSkills } = useSelector((state) => state.candidateSkills);

  useEffect(() => {
    hideFreshWorkWidget();
    if (!showLoader && jobDetails) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            currentPageName: "Review Profile",
          },
        })
      );
    }
  }, [dispatch, jobDetails, showLoader]);

  useEffect(() => {
    hideFreshWorkWidget();
    document.title = `Review Profile - ${SITE_TITLE}`;
    dispatch(setCandidateUserAction());
  }, []);

  const handleApplyJob = () => {
    setShowModal(false);
    return dispatch(handleApplyJobAction(jobDetails?.uuid))
      .unwrap()
      .then(() => {
        navigate(`/jobs/${jobDetails?.uuid}/details`);
      })
      .catch(({ errors, meta }) => {
        if (jobDetails?.redirect_url) {
          window.location.replace(jobDetails?.redirect_url);
        }
        setShowModal(false);
        showAlert(ALERT_TYPE[meta?.message_type], meta?.message);
        // TODO: when we can confirm errors is coming in meta then we can remove this below check
        if (errors && errors?.unique_job_and_candidate?.length !== 0) {
          showAlert(ALERT_TYPE.DANGER, errors.unique_job_and_candidate[0]);
        }
      });
  };

  const getJobStatus = (job) => {
    if (
      job?.applied === true ||
      job?.status === JOBS_STATUSES[2] ||
      job?.status === JOBS_STATUSES[3]
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setShowLoader(true);
    getRestrictedJobDetailWithToken(params.uuid, params.token)
      .then(({ data }) => {
        setShowLoader(false);
        if (!getJobStatus(data)) {
          navigate(`/jobs/${params.uuid}/details`);
        } else {
          setJobDetails(data);
        }
      })
      .catch(() => {
        navigate(`/jobs/${params.uuid}/details`);
        setShowLoader(false);
      });
  }, [params.token]);

  return !showLoader && jobDetails ? (
    <>
      <div className="fixed z-20 mb-4 w-full border-b bg-gray-lighterShade3 p-2 text-warning-main">
        <div className="flex items-center justify-start gap-3 px-2 text-center">
          <div>
            <BsExclamationCircleFill size={30} className="h-8 w-8 " />
          </div>
          <div className="text-base">
            Please provide accurate and complete profile details to increase
            your hireability.
          </div>
        </div>
      </div>
      <Profile communicationPreferenceVisibility={false} />
      <div className="fixed bottom-0 ml-4 flex w-full gap-4 border-t-2 bg-gray-lighterShade3 p-3">
        <div
          className="z-20"
          data-tooltip-content={
            // eslint-disable-next-line no-nested-ternary
            jobDetails?.visibility === "RESTRICTED"
              ? educationQualifications?.length > 0 &&
                candidateSkills?.length > 0
                ? ""
                : "Please fill the education and skills to continue!"
              : ""
          }
          data-tooltip-id="reviewApplication"
        >
          <Button
            title="Proceed"
            btnName="Proceed"
            type={`${
              // eslint-disable-next-line no-nested-ternary
              jobDetails?.visibility === "PUBLIC"
                ? "primary"
                : educationQualifications?.length > 0 &&
                  candidateSkills?.length > 0
                ? "primary"
                : "disabled"
            }`}
            size="md"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>

        <Button
          title="Cancel"
          btnName="Cancel"
          type="secondary"
          size="md"
          onClick={() => {
            if (jobDetails?.redirect_url) {
              window.location.replace(jobDetails?.redirect_url);
            } else {
              navigate(`/jobs/${params.uuid}/details`);
            }
          }}
        />
      </div>
      {showModal && jobDetails?.uuid && (
        <ConfirmApplyModal
          jobUuid={jobDetails?.uuid}
          handleApplyJob={handleApplyJob}
          setShowModal={setShowModal}
        />
      )}
      <Tooltip
        className="z-20"
        id="reviewApplication"
        variant="dark"
        place="right"
      />
    </>
  ) : (
    <ActivityIndicator />
  );
};

export default RestrictedJobWithToken;
