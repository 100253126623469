import { useState } from "react";
import { BiUndo } from "react-icons/bi";
import { BsDownload, BsZoomIn, BsZoomOut } from "react-icons/bs";
import ImageGallery from "react-image-gallery";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import classNames from "classnames";
import PropTypes from "prop-types";

import "react-image-gallery/styles/css/image-gallery.css";

const Slider = ({
  screenshots,
  currentIndex,
  onChangeIndex,
  setShowModalMessage,
  violationMessage,
  handleDownload,
  setCurrentImage,
}) => {
  const [zoomInCount, setZoomInCount] = useState(0);
  const screenshotImages = screenshots.map((obj) => {
    return { original: obj.attachment, thumbnail: obj.attachment };
  });

  const isZoomCountZero = () => zoomInCount === 0;

  const handleClick = (actionName, action) => {
    action();
    if (actionName === "zoomIn") {
      setZoomInCount((prevCount) => prevCount + 1);
    } else if (actionName === "zoomOut") {
      setZoomInCount((prevCount) => prevCount - 1);
    } else if (actionName === "resetZoom") {
      setZoomInCount(0);
    }
  };

  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      wheel={{ disabled: true }}
      doubleClick={{ disabled: true }}
    >
      {({ zoomIn, zoomOut, resetTransform }) => (
        <>
          <div className="flex flex-col">
            <div className="flex mx-1 justify-center">
              <TransformComponent>
                <ImageGallery
                  items={screenshotImages}
                  showPlayButton={false}
                  showThumbnails={false}
                  showFullscreenButton={isZoomCountZero()}
                  showNav={isZoomCountZero()}
                  showIndex={isZoomCountZero()}
                  additionalClass="lg:h-auto lg:w-[43vw]"
                  startIndex={currentIndex}
                  onSlide={(index) => {
                    resetTransform();
                    setCurrentImage(index);
                    onChangeIndex(screenshots[index]?.metadata?.uploaded_at);
                    setShowModalMessage(
                      screenshots[index]?.metadata?.description
                    );
                    setZoomInCount(0);
                  }}
                />
              </TransformComponent>
            </div>
            <p className="p-2">{violationMessage}</p>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              className={classNames(
                "p-2 rounded-full border-2 border-primary-main hover:bg-gray-lighterShade3 transform transition-transform hover:scale-110",
                {
                  "bg-gray-lighterShade3 cursor-not-allowed opacity-50 p-2":
                    zoomInCount >= 5,
                }
              )}
              onClick={() => handleClick("zoomIn", zoomIn)}
              disabled={zoomInCount >= 5}
            >
              <BsZoomIn
                title="Zoom In"
                className="text-xl text-primary-main text-center"
              />
            </button>
            <button
              type="button"
              className={classNames(
                "p-2 rounded-full border-2 border-primary-main mx-4  hover:bg-gray-lighterShade3 transform transition-transform hover:scale-110",
                {
                  "bg-gray-lighterShade3 cursor-not-allowed opacity-50 p-2":
                    zoomInCount <= 0,
                }
              )}
              onClick={() => handleClick("zoomOut", zoomOut)}
              disabled={zoomInCount <= 0}
            >
              <BsZoomOut
                title="Zoom Out"
                className="text-xl text-primary-main text-center"
              />
            </button>
            <button
              type="button"
              className="p-2 rounded-full border-2 border-primary-main  hover:bg-gray-lighterShade3 transform transition-transform hover:scale-110"
              onClick={() => handleClick("resetZoom", resetTransform)}
            >
              <BiUndo
                title="Reset Zoom"
                className="text-xl text-primary-main text-center"
              />
            </button>
            {handleDownload && (
              <button
                type="button"
                className="p-2 ml-4 rounded-full border-2 border-primary-main  hover:bg-gray-lighterShade3 transform transition-transform hover:scale-110"
                onClick={() => handleDownload("image")}
              >
                <BsDownload
                  title="Download"
                  className="text-xl text-primary-main text-center"
                />
              </button>
            )}
          </div>
        </>
      )}
    </TransformWrapper>
  );
};

Slider.propTypes = {
  screenshots: PropTypes.arrayOf(
    PropTypes.shape({
      attachment: PropTypes.string,
      attachment_file_name: PropTypes.string,
      metadata: PropTypes.shape({
        description: PropTypes.string,
        uploaded_at: PropTypes.string,
      }),
      type: PropTypes.string,
    })
  ),
  currentIndex: PropTypes.number,
  onChangeIndex: PropTypes.func,
  setShowModalMessage: PropTypes.func,
  violationMessage: PropTypes.string,
  handleDownload: PropTypes.func,
  setCurrentImage: PropTypes.func,
};

export default Slider;
