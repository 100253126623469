import { createSlice } from "@reduxjs/toolkit";

const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState: {
    flags: [],
    isLoading: true,
  },
  reducers: {
    flagsDetails: (state, action) => ({
      ...state,
      flags: action.payload,
      isLoading: false,
    }),
  },
});

export const { flagsDetails } = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
