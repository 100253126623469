import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import FormattedContactNumber from "components/atoms/FormattedContactNumber";
import { Modal } from "components/molecules";
import { deleteReferenceDetailsAction } from "store/thunkActions/candidate/referenceDetailsThunk";

import DescriptiveCard from "../Common/DescriptiveCard";

import Form from "./Form";

const Reference = ({ referenceDetail }) => {
  const [isEditState, setIsEditState] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  const onDelete = () => {
    return dispatch(deleteReferenceDetailsAction(referenceDetail.uuid));
  };

  return isEditState ? (
    <Form referenceDetail={referenceDetail} setIsEditState={setIsEditState} />
  ) : (
    <DescriptiveCard
      title={referenceDetail.name}
      editButtonTitle="Edit Reference"
      deleteButtonTitle="Delete Reference"
      editButtonTestId="editBtn"
      deleteButtonTestId="deleteBtn"
      setIsEditState={setIsEditState}
      setIsDeleting={setIsDeleting}
    >
      <p className="text-md">
        {referenceDetail.designation_name}{" "}
        {referenceDetail.designation_name && referenceDetail.company_name
          ? "|"
          : ""}
        <span> {referenceDetail.company_name}</span>
      </p>

      <p className="text-sm pt-1 pr-2 font-normal leading-5">
        <FormattedContactNumber number={referenceDetail.phone} />
      </p>

      {isDeleting && (
        <Modal
          title="Confirm Delete"
          description="This will delete the reference from your profile."
          acceptButtonText="OK, Delete"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="Cancel"
          customButtonWidth={32}
          handleAccept={onDelete}
          handleReject={() => setIsDeleting(false)}
        />
      )}
    </DescriptiveCard>
  );
};

Reference.propTypes = {
  referenceDetail: PropTypes.shape({
    uuid: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    designation_uuid: PropTypes.number,
    designation_name: PropTypes.string,
    company_uuid: PropTypes.number,
    company_name: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default Reference;
