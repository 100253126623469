import { useEffect, useRef, useState } from "react";
import { BiBuildings } from "react-icons/bi";
import { FaSignOutAlt, FaUserFriends } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiUser3Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { candidateApi, organisationApi } from "api/axios";
import {
  BRAND_URL,
  ICON_SIZE_HASH,
  NAVBAR_PATH_EXCEPTIONS,
} from "app/constants";
import logo from "assets/images/logo/joballey_logo_dark_text.png";
import { Avatar, BreadCrumb, Button } from "components/atoms";
import { NAVBAR_HEIGHT_CUSTOM_EVENT, USER_ROLES } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import { checkAppEnvs, getCurrentSubdomain } from "helpers/utils";
import { clearApiError } from "store/slices/apiErrorSlice";
import { logout } from "store/slices/authSlice";

const Navbar = ({ setOpenAside, isErrorPage, resetErrorBoundary }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { isLoggedIn, currentUser } = useSelector((state) => state.auth);
  const subdomain = getCurrentSubdomain();

  const dropdownMenuRef = useRef(null);
  const navRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const logoUrl = process.env.NODE_ENV === "development" ? "/" : BRAND_URL;
  const pathException = NAVBAR_PATH_EXCEPTIONS.includes(
    window.location.pathname
  );
  // const checkEvaluatorUserRole = useCheckUserRole([USER_ROLES.EVALUATOR]);
  // if profile don't need to show then use this variable for checking

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  const [shadow, setShadow] = useState(false);
  const handleshadow = () => {
    if (window.scrollY > 10) {
      setShadow(true);
    } else {
      setShadow(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleshadow);
    return () => {
      window.removeEventListener("scroll", handleshadow);
    };
  }, []);

  useEffect(() => {
    if (navRef.current) {
      const event = new CustomEvent(NAVBAR_HEIGHT_CUSTOM_EVENT, {
        detail: navRef.current.offsetHeight,
      });
      dispatchEvent(event);
    }
  }, [navRef.current?.offsetHeight]);

  async function handleLogout() {
    const api = currentUser?.data.organisation_id
      ? organisationApi
      : candidateApi;
    await api.post("/logout").then(() => {
      dispatch(logout());
    });
    dispatch(clearApiError());
    navigate("/login");
  }

  const showBreadcrumbToggle = () => {
    if (isErrorPage) {
      return <img width={168} src={logo} alt="logo" />;
    }
    return (
      <h1 className="ml-8 text-base font-normal text-primary-darker lg:ml-0">
        <BreadCrumb />
      </h1>
    );
  };

  const handleClickHelp = () => {
    if (isErrorPage) {
      resetErrorBoundary();
      dispatch(clearApiError());
    }
    navigate("/help");
  };

  const handleClickProfile = () => {
    if (isErrorPage) {
      resetErrorBoundary();
      dispatch(clearApiError());
    }

    setShowDropdown(false);
    navigate("/profile");
  };

  const handleClickManageUsers = () => {
    if (isErrorPage) {
      resetErrorBoundary();
      dispatch(clearApiError());
    }

    setShowDropdown(false);
    navigate(
      `/users?${constructQueryParams({
        page: 1,
        page_size: 20,
      })}`
    );
  };

  const handleLogin = () => {
    if (isErrorPage) {
      resetErrorBoundary();
      dispatch(clearApiError());
    }
    navigate(
      `/login?redirect_url=${encodeURIComponent(window.location.pathname)}`
    );
  };

  const handleSignup = () => {
    if (isErrorPage) {
      resetErrorBoundary();
      dispatch(clearApiError());
    }
    navigate(
      `/signup?redirect_url=${encodeURIComponent(window.location.pathname)}`
    );
  };

  return (
    <nav
      className={classNames(
        "sticky top-0 z-30 flex flex-wrap items-center justify-between bg-gray-lighterShade3 pl-1.5 pr-4 py-[6px]",
        `${shadow ? "shadow-md" : ""}`
      )}
      id="navbar-header"
      ref={navRef}
    >
      <div className="flex flex-1 items-center justify-between lg:mx-auto lg:pl-2">
        <div className="relative flex items-center lg:static lg:block lg:w-auto lg:justify-start">
          {isLoggedIn && !pathException && !isErrorPage && (
            <button
              title="Menu"
              type="button"
              className="absolute left-0 h-8 w-8 rounded-full p-2 hover:bg-gray-lighter focus:bg-gray-lighter lg:hidden"
              onClick={() => setOpenAside(true)}
            >
              <GiHamburgerMenu />
            </button>
          )}
          {!isLoggedIn ? (
            <div className="width-custom flex justify-between text-md">
              <Link to="/" href={logoUrl}>
                <img width={168} src={logo} alt="logo" />
              </Link>

              {pathname !== "/login" &&
              pathname !== "/signup" &&
              checkAppEnvs(subdomain) ? (
                <div className="flex px-2">
                  <Button
                    title="Help"
                    btnClassName="w-20 sm:w-24"
                    onClick={handleClickHelp}
                    btnName="Help"
                    type="tertiary"
                  />
                  <Button
                    title="Login"
                    btnClassName="w-20 sm:w-24"
                    onClick={handleLogin}
                    btnName="Login"
                    type="tertiary"
                  />
                  <Button
                    title="Sign Up"
                    btnClassName="w-16 sm:w-24"
                    onClick={handleSignup}
                    btnName="Sign Up"
                    type="tertiary"
                  />
                </div>
              ) : (
                checkAppEnvs(subdomain) && (
                  <Button
                    title="Help"
                    btnClassName="w-20 sm:w-24"
                    onClick={handleClickHelp}
                    btnName="Help"
                    type="tertiary"
                  />
                )
              )}
            </div>
          ) : (
            showBreadcrumbToggle()
          )}
        </div>

        {isLoggedIn && currentUser.data && (
          <div className="flex text-md">
            {!currentUser?.data.organisation_id && (
              <Button
                btnClassName="w-20 sm:w-24 mr-2"
                onClick={handleClickHelp}
                btnName="Help"
                type="tertiary"
                title="Help"
              />
            )}

            <ul className="flex list-none flex-row items-center lg:ml-auto">
              <li className="nav-item relative" ref={dropdownMenuRef}>
                <button
                  title={currentUser?.data?.name}
                  type="button"
                  className="transform transition-transform hover:scale-110"
                  data-testid="toggle-menu-btn"
                  onClick={() => setShowDropdown((dropdown) => !dropdown)}
                >
                  <div className="flex items-center justify-center w-9 px-0.5 h-10">
                    {currentUser?.data?.avatar?.thumb ? (
                      <div className="rounded-full grow ring-2 ring-primary-dark">
                        <Avatar
                          src={currentUser?.data?.avatar?.thumb}
                          height={8}
                          width={8}
                        />
                      </div>
                    ) : (
                      <div className="btn-icon-primary h-8 w-8 text-xl font-semibold capitalize ring-2 ring-primary-dark">
                        {currentUser?.data?.name?.[0] ||
                          currentUser?.data?.email?.[0]}
                      </div>
                    )}
                  </div>
                </button>

                {showDropdown && (
                  <div className="absolute top-11 right-0 z-20 w-48 rounded-md border bg-white shadow-2xl">
                    {currentUser && currentUser.data.organisation_id ? (
                      <>
                        <p className="mt-2 flex w-full flex-col truncate px-2 text-sm hover:cursor-default">
                          <span
                            className="mr-2 truncate px-2 text-primary-main"
                            title={
                              currentUser.data.name || currentUser.data.email
                            }
                          >
                            {currentUser.data.name || currentUser.data.email}
                          </span>
                          <span className="px-2 text-xs capitalize italic text-gray-main">
                            {currentUser.data.role}
                          </span>
                        </p>
                        {/* check will be done here */}
                        <button
                          type="button"
                          className="flex w-full px-4 py-2 text-left text-sm hover:bg-gray-lighter"
                          onClick={handleClickProfile}
                        >
                          <span className="mr-2 mt-1 w-4 text-primary-main">
                            <BiBuildings size={ICON_SIZE_HASH.sm} />
                          </span>
                          <span
                            className="flex flex-col truncate"
                            title={currentUser.data.organisation_name}
                          >
                            <span className="text-primary-main">
                              Company Profile
                            </span>
                            <span className="truncate text-xs italic text-gray-main">
                              {currentUser.data.organisation_name}
                            </span>
                          </span>
                        </button>
                        {currentUser?.data.roles.includes(USER_ROLES.ADMIN) && (
                          <button
                            title="Manage Users"
                            type="button"
                            className="flex w-full px-4 py-2 text-left text-sm hover:bg-gray-lighter"
                            onClick={handleClickManageUsers}
                          >
                            <FaUserFriends
                              className="mr-2 mt-[2px] text-primary-main"
                              size={ICON_SIZE_HASH.sm}
                            />
                            <span className="text-primary-main">
                              Manage Users
                            </span>
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        type="button"
                        role="link"
                        className="flex w-full px-4 py-2 text-left text-sm hover:bg-gray-lighter"
                        onClick={handleClickProfile}
                      >
                        <span className="mr-2 mt-[4px] w-4 text-primary-main">
                          <RiUser3Fill size={ICON_SIZE_HASH.sm} />
                        </span>

                        <span
                          className="flex flex-col truncate"
                          title={currentUser?.data?.name}
                        >
                          <span className="text-primary-main ">Profile</span>
                          <span className="truncate text-xs italic text-gray-main">
                            {currentUser?.data?.name}
                          </span>
                        </span>
                      </button>
                    )}

                    <button
                      title="Logout"
                      type="button"
                      className="text-primary-mainLighter flex w-48 border-t-2 border-gray-lighter px-4 py-2 text-center text-sm font-medium hover:bg-gray-lighter"
                      onClick={handleLogout}
                    >
                      <FaSignOutAlt
                        className="mr-2 mt-[2px] text-primary-main"
                        size={ICON_SIZE_HASH.sm}
                      />
                      <span className="text-primary-main ">Logout</span>
                    </button>
                  </div>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  setOpenAside: PropTypes.func,
  isErrorPage: PropTypes.bool,
  resetErrorBoundary: PropTypes.func,
};

export default Navbar;
