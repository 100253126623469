import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getNextQuestion } from "api/candidate/candidateApi";

const questionIndex =
  parseInt(localStorage.getItem("questionIndex"), 10) || null;

export const setQuestion = createAsyncThunk("api/next_question", async (id) => {
  const response = await getNextQuestion(id);
  return response.data;
});

export const questionSlice = createSlice({
  name: "question",
  initialState: {
    question: {},
    section: {},
    isQuestionPresent: null,
    message: null,
    currentQuestionIndex: questionIndex || 0,
    questionId: localStorage.getItem("questionId"),
    candidateAnswerId: 0,
    isCodingQuestion: null,
    driverCodes: null,
  },
  reducers: {
    increaseQuestionIndex: (state) => {
      state.currentQuestionIndex += 1;
    },
    setCurrentQuestionId: (state, action) => {
      state.questionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setQuestion.fulfilled, (state, action) => {
      state.section = action.payload.section;
      state.question = action.payload.question;
      state.candidateAnswerId = action.payload.candidate_answer_id;
      state.isQuestionPresent = true;
      state.isCodingQuestion = action.payload.question.allowUpload;
      state.driverCodes = action.payload.question.driver_codes;
    });
    builder.addCase(setQuestion.rejected, (state, action) => {
      state.message = action.error.message;
      state.isQuestionPresent = false;
    });
  },
});

export const { increaseQuestionIndex, setCurrentQuestionId } =
  questionSlice.actions;

export default questionSlice.reducer;
