import { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import FormattedContactNumber from "components/atoms/FormattedContactNumber";
import Modal from "components/molecules/Modal";
import { deleteCandidatePhoneNumberAction } from "store/thunkActions/candidate/phoneNumberThunk";

import Form from "./Form";

const PhoneNumber = ({ phoneNumber }) => {
  const [isEditState, setIsEditState] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  const onDelete = () => {
    return dispatch(deleteCandidatePhoneNumberAction(phoneNumber.id));
  };

  return isEditState ? (
    <Form
      phoneNumber={{
        ...phoneNumber,
        phone: `${phoneNumber?.phone.slice(0, 4)}-${phoneNumber?.phone.slice(
          4
        )}`,
      }}
      setIsEditState={setIsEditState}
    />
  ) : (
    <>
      <div className="flex items-start justify-between break-all text-justify">
        <p className="text-md pt-2 pr-2 font-normal leading-5">
          <FormattedContactNumber number={phoneNumber.phone} />
        </p>
        <div className="flex">
          <button
            title="Edit Phone Number"
            data-testid="editPhoneNumberBtn"
            className="btn-icon-secondary"
            onClick={() => setIsEditState(true)}
          >
            <MdOutlineModeEditOutline className="text-lg" />
          </button>
          <button
            title="Delete Phone Number"
            data-testid="deletePhoneNumberBtn"
            className="btn-icon-danger"
            onClick={() => setIsDeleting(true)}
          >
            <BiTrash className="text-lg" />
          </button>
        </div>
      </div>
      {isDeleting && (
        <Modal
          title="Confirm Delete"
          description="This will delete this phone number from your profile."
          acceptButtonText="OK, Delete"
          acceptBtnType="primary"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          customButtonWidth={32}
          handleAccept={onDelete}
          handleReject={() => setIsDeleting(false)}
        />
      )}
    </>
  );
};

PhoneNumber.propTypes = {
  phoneNumber: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.string,
  }),
};

export default PhoneNumber;
