import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ALERT_TYPE } from "app/constants";
import { ActivityIndicator } from "components/atoms";
import useAlert from "hooks/useAlert";
import { getJobAction } from "store/thunkActions/erp/jobThunk";

import Form from "./Form";

const Edit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const { job } = useSelector((state) => state.job);
  const location = useLocation();
  const clone = new URLSearchParams(location.search).has("clone");
  const jobData = {
    ...job,
    title: clone ? `Copy of- ${job?.title}` : job?.title,
  };

  useEffect(() => {
    dispatch(getJobAction(param.uuid));
  }, [dispatch, param.uuid]);

  useEffect(() => {
    if (!clone && job?.status === "job_closed") {
      showAlert(ALERT_TYPE.DANGER, "Closed jobs can't be edited.");
      navigate(`/jobs/${job.uuid}`);
    }
  }, [job, clone, navigate, showAlert]);

  if (!job) {
    return <ActivityIndicator />;
  }

  return (
    <div className="mx-auto h-full min-h-screen xl:container md:px-4">
      <Form job={jobData} />
    </div>
  );
};

export default Edit;
