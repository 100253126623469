import { createSlice } from "@reduxjs/toolkit";

import { getJobAction } from "store/thunkActions/erp/jobThunk";

const job = createSlice({
  name: "job",
  initialState: {
    job: undefined,
  },
  reducers: {},
  extraReducers: {
    [getJobAction.fulfilled]: (state, action) => {
      state.job = action.payload;
    },
  },
});

export default job.reducer;
