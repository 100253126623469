import { createAsyncThunk } from "@reduxjs/toolkit";

import { createUser, getUsers, updateUser } from "api/erp/organizationApi";

export const getUsersAction = createAsyncThunk(
  "users/get",
  async ({ page, page_size: pageSize, searchTerm, roles, status }) => {
    const response = await getUsers(page, pageSize, searchTerm, roles, status);
    return response;
  }
);

export const createUsersAction = createAsyncThunk(
  "users/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createUser(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUsersAction = createAsyncThunk(
  "users/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
