import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAllUsers } from "api/erp/organizationApi";

export const getAllUsersAction = createAsyncThunk(
  "organisation_users/get",
  async ({ page, name, roles }) => {
    const response = await getAllUsers(page, name, roles);
    return response;
  }
);
