import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  createReferenceDetails,
  deleteReferenceDetails,
  getReferenceDetails,
  updateReferenceDetails,
} from "api/candidate/profileApi";

export const getReferenceDetailsAction = createAsyncThunk(
  "referenceDetails/get",
  async () => {
    const response = await getReferenceDetails();
    return response.data;
  }
);

export const createReferenceDetailsAction = createAsyncThunk(
  "reference-details/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createReferenceDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateReferenceDetailsAction = createAsyncThunk(
  "reference-details/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateReferenceDetails(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteReferenceDetailsAction = createAsyncThunk(
  "reference-details/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteReferenceDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
