import Proptypes from "prop-types";

const ThreeDotMenu = ({ menuRef, menuOptions, posFromRight, posFromTop }) => (
  <div
    ref={menuRef}
    className={`absolute block bg-white ${posFromRight} ${posFromTop}
    ring-black z-50 overflow-y-visible rounded-md shadow-lg
    ring-1 ring-opacity-5 focus:outline-none`}
  >
    {menuOptions
      .filter((option) => !("condition" in option) || option.condition)
      .map((item) => (
        // if no condition is passed then button will be included
        <button
          title={item.text}
          type="button"
          data-testid={item.dataTestId}
          role="link"
          className="flex w-full whitespace-nowrap px-4 py-2 text-left text-sm hover:bg-primary-lightest"
          onClick={item.onClick}
          key={item.text}
        >
          {item.icon} {item.text}
        </button>
      ))}
  </div>
);

ThreeDotMenu.defaultProps = {
  menuRef: null,
  posFromRight: "right-0",
  posFromTop: "top-8",
};

ThreeDotMenu.propTypes = {
  menuRef: Proptypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: Proptypes.object,
  }),
  menuOptions: Proptypes.arrayOf(
    Proptypes.shape({
      condition: Proptypes.bool,
      dataTestId: Proptypes.string,
      onClick: Proptypes.func.isRequired,
      icon: Proptypes.element,
      text: Proptypes.string.isRequired,
    })
  ).isRequired,
  posFromRight: Proptypes.string,
  posFromTop: Proptypes.string,
};

export default ThreeDotMenu;
