import { SORT_BY, SORT_ORDER } from "app/constants";

const showTooltipSort = (text, sortBy, sortOrder) => {
  let newText;
  if (sortBy !== text || sortOrder === "") {
    newText = "Click to Sort";
  } else if (text === SORT_BY.CANDIDATE_NAME && sortOrder === SORT_ORDER.ASC) {
    newText = "Name Asc";
  } else if (text === SORT_BY.CANDIDATE_NAME && sortOrder === SORT_ORDER.DESC) {
    newText = "Name Desc";
  } else if (text === SORT_BY.SLOT && sortOrder === SORT_ORDER.ASC) {
    newText = "Slot Asc";
  } else if (text === SORT_BY.SLOT && sortOrder === SORT_ORDER.DESC) {
    newText = "Slot Desc";
  } else if (text === SORT_BY.START_TIME && sortOrder === SORT_ORDER.ASC) {
    newText = "Start Time Asc";
  } else if (text === SORT_BY.START_TIME && sortOrder === SORT_ORDER.DESC) {
    newText = "Start Time Desc";
  } else if (text === SORT_BY.TOTAL_MARKS && sortOrder === SORT_ORDER.ASC) {
    newText = "Total Marks Asc";
  } else if (text === SORT_BY.TOTAL_MARKS && sortOrder === SORT_ORDER.DESC) {
    newText = "Total Marks Desc";
  } else if (text === SORT_BY.CREATED_AT && sortOrder === SORT_ORDER.ASC) {
    newText = "Applied On Asc";
  } else if (text === SORT_BY.CREATED_AT && sortOrder === SORT_ORDER.DESC) {
    newText = "Applied On Desc";
  }
  return newText;
};

const showAppliedTooltipSort = (text, sortBy, sortOrder) => {
  let newText;
  if (sortBy !== text && sortOrder === "") {
    newText = "Applied On Asc";
  } else if (sortBy === text && sortOrder === "") {
    newText = "Applied On Asc";
  } else if (sortBy !== text && sortOrder !== "") {
    newText = "Click to Sort";
  } else if (text === SORT_BY.CREATED_AT && sortOrder === SORT_ORDER.ASC) {
    newText = "Applied On Asc";
  } else if (text === SORT_BY.CREATED_AT && sortOrder === SORT_ORDER.DESC) {
    newText = "Applied On Desc";
  }
  return newText;
};

export { showAppliedTooltipSort, showTooltipSort };
