import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaDatabase, FaGithub } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { getBackEndVersion } from "api/common/api";
import { ICON_SIZE_HASH } from "app/constants";
import { Card } from "components/atoms";
import { SITE_TITLE } from "constants/common";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import packageJson from "../../../../package.json";

const Version = () => {
  const dispatch = useDispatch();
  const [backendVersion, setBackendVersion] = useState();

  useEffect(() => {
    getBackEndVersion().then(({ data }) => {
      setBackendVersion(data.version);
    });
  }, []);

  useEffect(() => {
    dispatch(
      updateHeaderTitle({ breadCrumbDetails: { currentPageName: "About" } })
    );
  }, [dispatch]);

  function renderVersionInfo(type, version, icon) {
    return (
      <div
        className="text-base font-medium text-gray-dark"
        id={`version-${type.toLowerCase()}`}
        data-testid={`version-${type.toLowerCase()}`}
      >
        {icon && React.cloneElement(icon, { className: "inline mr-2 mb-1" })}
        <span>{`${type} Version : ${version || "Loading..."}`}</span>
      </div>
    );
  }

  return (
    <div className="mx-2 sm:mx-6 md:mx-10 flex mb-10 flex-col gap-8 px-4 pt-4 sm:px-2 md:px-8">
      <Helmet>
        <title>{`About - ${SITE_TITLE}`}</title>
      </Helmet>
      <div className="flex justify-center">
        <Card>
          <div className="flex items-start mb-3 px-10 py-4">
            <div className="px-2 sm:px-4 md:px-8 lg:px-10">
              {renderVersionInfo(
                "Frontend",
                packageJson.version,
                <FaGithub size={ICON_SIZE_HASH.sm} />
              )}
              {renderVersionInfo(
                "Backend",
                backendVersion,
                <FaDatabase size={ICON_SIZE_HASH.sm} />
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Version;
