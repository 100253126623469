import { useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Modal from "components/molecules/Modal";
import { deleteProjectAction } from "store/thunkActions/candidate/projectThunk";

import DescriptiveCard from "../Common/DescriptiveCard";

import Form from "./Form";

const Projects = ({ project }) => {
  const [isEditState, setIsEditState] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  const onDelete = () => {
    return dispatch(deleteProjectAction(project.id));
  };

  const endAtFormat = () => {
    if (project.is_ongoing) {
      return "Ongoing";
    }
    return format(new Date(project.end_at), "MMMM yyyy");
  };

  return isEditState ? (
    <Form project={project} setIsEditState={setIsEditState} />
  ) : (
    <DescriptiveCard
      title={project.title}
      link={project.project_url}
      editButtonTitle="Edit Project"
      deleteButtonTitle="Delete Project"
      editButtonTestId="editProjectBtn"
      deleteButtonTestId="deleteProjectBtn"
      setIsEditState={setIsEditState}
      setIsDeleting={setIsDeleting}
    >
      <div>
        <RenderParsedHTML content={project.description || ""} />
      </div>

      {project.start_at && endAtFormat() && (
        <p className="text-sm italic text-gray-main">
          {format(new Date(project.start_at), "MMMM yyyy")} - {endAtFormat()}
        </p>
      )}

      {isDeleting && (
        <Modal
          title="Confirm Delete"
          description="This will delete the project from your profile."
          acceptButtonText="OK, Delete"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          rejectButtonText="Cancel"
          customButtonWidth={32}
          handleAccept={onDelete}
          handleReject={() => setIsDeleting(false)}
        />
      )}
    </DescriptiveCard>
  );
};

Projects.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    is_ongoing: PropTypes.bool,
    project_url: PropTypes.string,
  }),
};

export default Projects;
