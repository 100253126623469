import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { getDashboardStats } from "api/erp/organizationApi";
import { QUESTION_PAGE_TYPES } from "app/constants";
import { ActivityIndicator } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import { SITE_TITLE, USER_ROLES } from "constants/common";
import useCheckUserRole from "hooks/useCheckUserRole";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";

import DashboardCard from "./DashboardCard";

const OrganisationDashboard = () => {
  const [dashboardData, setDashboardData] = useState();
  const dispatch = useDispatch();
  const checkInterviewUserRole = useCheckUserRole([USER_ROLES.INTERVIEWER]);
  const checkAdminUserRole = useCheckUserRole([USER_ROLES.ADMIN]);
  const checkQuestionSetterUserRole = useCheckUserRole([
    USER_ROLES.QUESTION_SETTER,
  ]);
  const checkEvaluatorUserRole = useCheckUserRole([USER_ROLES.EVALUATOR]);
  const checkHiringManagerUserRole = useCheckUserRole([
    USER_ROLES.HIRING_MANAGER,
  ]);

  const loadDashboardStats = async () => {
    const { data } = await getDashboardStats();
    return setDashboardData(data);
  };

  useEffect(() => {
    dispatch(
      updateHeaderTitle({ breadCrumbDetails: { currentPageName: "Dashboard" } })
    );
  }, []);

  useEffect(() => {
    loadDashboardStats();
  }, []);

  if (!dashboardData) {
    return <ActivityIndicator />;
  }

  return (
    <div className="mx-10 flex mb-10 flex-col gap-8 px-4 pt-4 sm:px-2 md:px-8">
      <Helmet>
        <title>{`Dashboard - ${SITE_TITLE}`}</title>
      </Helmet>
      {dashboardData.jobs.total_count === 0 &&
        (checkAdminUserRole || checkHiringManagerUserRole) && (
          <ActionAlert
            message="Get Started and Post Your First Job"
            link="/jobs/create"
            alertType="primary"
            btnName="Create Job"
            buttonTitle="Create Job"
          />
        )}
      <div className="flex flex-col gap-8 md:flex-row">
        <DashboardCard
          title="Jobs"
          testId="jobs-card"
          data={dashboardData.jobs}
          allowedRoles={[
            USER_ROLES.ADMIN,
            USER_ROLES.INTERVIEWER,
            USER_ROLES.EVALUATOR,
            USER_ROLES.HIRING_MANAGER,
          ]}
          showStats={
            !!(
              checkAdminUserRole ||
              checkEvaluatorUserRole ||
              checkHiringManagerUserRole
            )
          }
        />
        <DashboardCard
          title="Question Manager"
          testId="question-manager-card"
          data={dashboardData.question_papers}
          moreData={dashboardData.questions}
          headerNames={QUESTION_PAGE_TYPES}
          allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.QUESTION_SETTER]}
          showStats={!!(checkAdminUserRole || checkQuestionSetterUserRole)}
        />
      </div>
      <DashboardCard
        title="My Interviews"
        testId="interviews-card"
        data={dashboardData.interviews}
        allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.INTERVIEWER]}
        showStats={!!(checkAdminUserRole || checkInterviewUserRole)}
      />
    </div>
  );
};

export default OrganisationDashboard;
