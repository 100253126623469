import PropTypes from "prop-types";

const ContactDetail = ({ contactDetail }) => {
  return (
    <div className="mx-auto mt-4 w-full rounded-lg border border-gray-light bg-white p-4">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-2xl font-normal"> Address</h3>
      </div>
      {contactDetail ? (
        <div className="text-sm">
          <h4 className=" font-normal  leading-5">
            {contactDetail?.address_line1}
          </h4>
          <p className=" mt-2 leading-5"> {contactDetail?.address_line2}</p>
          <p className="mt-2 flex">
            {contactDetail?.city}, {contactDetail?.state},{" "}
            {contactDetail?.country}, {contactDetail?.postal_code}
          </p>
          <div className="mt-2 flex text-gray-500">
            <span className="italic"> Willing to relocate?</span>
            <span className="ml-2">
              {contactDetail?.willing_to_relocate ? (
                <span className="font-semibold text-primary-main">YES</span>
              ) : (
                <span className="font-semibold text-primary-main">NO</span>
              )}
            </span>
          </div>
        </div>
      ) : (
        <span className="text-sm italic text-gray-main">
          No Address to Display
        </span>
      )}
    </div>
  );
};

ContactDetail.propTypes = {
  contactDetail: PropTypes.shape({
    id: PropTypes.number,
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    permanent_address: PropTypes.bool,
    state: PropTypes.string,
    postal_code: PropTypes.string,
    willing_to_relocate: PropTypes.bool,
  }),
};
export default ContactDetail;
