import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  creatWorkExperience,
  deleteWorkExperience,
  getWorkExperiences,
  updateWorkExperience,
} from "api/candidate/profileApi";

export const getWorkExperiencesAction = createAsyncThunk(
  "workExperience/get",
  async () => {
    const response = await getWorkExperiences();
    return response.data;
  }
);

export const createWorkExperiencesAction = createAsyncThunk(
  "work-experience/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await creatWorkExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateWorkExperiencesAction = createAsyncThunk(
  "work-experience/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateWorkExperience(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteWorkExperiencesAction = createAsyncThunk(
  "work-experience/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteWorkExperience(id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
