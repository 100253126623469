import classNames from "classnames";
import propTypes from "prop-types";

import { ICON_SIZE_HASH } from "app/constants";

const SocialMediaIcon = ({ link, icon: Icon, title }) => {
  return (
    <div className="mt-2 mb-2">
      <a
        title={title}
        href={link || "#"}
        target="_blank"
        data-testid="social-media-link"
        className={classNames("text-sm text-gray-dark italic", {
          "pointer-events-none": !link,
        })}
        rel="noreferrer"
      >
        <Icon
          size={ICON_SIZE_HASH.sm}
          className="inline mr-2"
          data-testid="social-media-icon"
        />
      </a>
    </div>
  );
};

SocialMediaIcon.propTypes = {
  title: propTypes.string,
  link: propTypes.string,
  icon: propTypes.elementType.isRequired,
};

export default SocialMediaIcon;
