import { useEffect, useRef, useState } from "react";
import { withOneTabEnforcer } from "react-one-tab-enforcer";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import screenfull from "screenfull";

import {
  finishExamCandidate,
  saveExamViolation,
  showExamCandidate,
} from "api/candidate/examApi";
import { Button } from "components/atoms";
import Alert from "components/common/Alert";
import { Modal } from "components/molecules";
import { EXAM_VIOLATION_TYPES, SITE_TITLE } from "constants/common";
import DifferentWarningComponent from "constants/singleTabEnforcerWarning";
import { cameraWarning, fullScreenWarning } from "helpers/genericErrorMessages";
import hideFreshWorkWidget from "helpers/hideFreshWorkWidget";

import Header from "./Header";
import Message from "./Message";
import QuestionPaper from "./QuestionPaper";

window.history.pushState(null, "", window.location.href);

const ExamComponent = () => {
  const videoRef = useRef(null);

  const [examData, setExamData] = useState({});
  const [isQuestionPresent, setIsQuestionPresent] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(true);
  const [isAlertTriggered, setIsAlertTriggered] = useState(false);
  const [isCameraModalVisible, setIsCameraModalVisible] = useState(false);
  const [isFinishModalVisible, setIsFinishModalVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreenModal, setIsFullScreenModal] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isTabSwitch, setIsTabSwitch] = useState(false);
  const [isFeedBackLink, setIsFeedBackLink] = useState("");
  const [sectionName, setSectionName] = useState("");

  const [searchParams] = useSearchParams();

  const intervalIdRef = useRef(null);

  const examId = searchParams.get("id");
  const FULL_SCREEN_ALERT_TIMER = 10000;

  const stopCapture = () => {
    clearTimeout(intervalIdRef.current);
  };

  const requestFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request();
      setIsFullScreen(true);
      setIsFullScreenModal(false);
    }
  };

  const examViolation = ({ violationType }) => {
    const data = {
      exam_candidate_id: examId,
      violation_type: violationType, // do not change case here
    };
    saveExamViolation(data);
  };

  const onFinishButtonClick = () => {
    finishExamCandidate(examId)
      .then(() => {
        stopCapture();
        setMessage("Thank you! Your exam has been submitted successfully.");
        setIsQuestionPresent(false);
        setIsFinishModalVisible(false);
      })
      .catch(() => {
        setIsFinishModalVisible(false);
        setMessage("You Have Already Appeared For The Exam.");
      });
  };

  const hideFWWidgetAsync = async () => {
    hideFreshWorkWidget();
  };

  useEffect(() => {
    hideFWWidgetAsync();
  }, []);

  useEffect(() => {
    showExamCandidate(examId)
      .then((response) => {
        document.title = `${response.data.exam_name} - ${SITE_TITLE}`;
        setExamData(() => response.data);
        setIsQuestionPresent(true);
        setIsFeedBackLink(response.data.exam_feedback_link);
        setMessage(() =>
          response.data.ended_at == null
            ? null
            : `You have finished your exam at ${moment(
                response.data.ended_at
              ).format("lll")}`
        );
      })
      .catch((error) => {
        setSuccessMessage(false);
        setIsFeedBackLink(error?.response?.data?.data?.exam_feedback_link);
        setMessage(error?.response?.data.meta?.message);
        setIsQuestionPresent(false);
      });
  }, []);

  useEffect(() => {
    const intervalTimeoutId = setInterval(() => {
      if (!screenfull.isFullscreen) {
        if (message == null) {
          setAlertMessage(fullScreenWarning());
          setIsAlertTriggered(true);
          examViolation({
            violationType: EXAM_VIOLATION_TYPES.FULL_SCREEN,
          });
        }
      }
    }, FULL_SCREEN_ALERT_TIMER);
    return () => clearInterval(intervalTimeoutId);
  }, [message]);

  useEffect(() => {
    if (isQuestionPresent) {
      setIsFullScreenModal(true);
    }
    return () => {
      setIsFullScreenModal(false);
    };
  }, [isQuestionPresent]);

  useEffect(() => {
    if (screenfull.isEnabled) {
      if (message != null) {
        screenfull.exit();
      }
      screenfull.on("change", () => {
        if (!screenfull.isFullscreen) {
          setIsFullScreen(false);
        }
      });
    }
    return () =>
      screenfull.off("change", () => {
        if (!screenfull.isFullscreen) {
          setIsFullScreen(false);
        }
      });
  }, [message]);

  useEffect(() => {
    const onChangeTabSwitchCallback = () => {
      if (message === null) {
        examViolation({ violationType: EXAM_VIOLATION_TYPES.TAB_SWITCH });
        setIsTabSwitch(true);
      }
    };

    window.addEventListener("blur", onChangeTabSwitchCallback);
    return () => {
      window.removeEventListener("blur", onChangeTabSwitchCallback);
      setIsTabSwitch(false);
    };
  }, [message]);

  useEffect(() => {
    const intervalTimeoutId = setInterval(() => {
      if (message === null && examData?.camera_option === "REQUIRED") {
        navigator.mediaDevices?.enumerateDevices().then((devices) => {
          devices.forEach((device) => {
            if (device.kind === "videoinput") {
              if (!device.label) {
                setIsCameraModalVisible(true);
                examViolation({
                  violationType: EXAM_VIOLATION_TYPES.CAMERA_PERMISSION,
                });
              }
            }
          });
        });

        navigator.mediaDevices.ondevicechange = () => {
          if (navigator.mediaDevices?.getUserMedia) {
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((stream) => {
                if (stream.id !== "" || stream.getTracks().length > 0) {
                  videoRef.current.srcObject = stream;
                  setIsCameraModalVisible(false);
                }
              })
              .catch(() => {
                setIsCameraModalVisible(true);
                examViolation({
                  violationType: EXAM_VIOLATION_TYPES.CAMERA_PERMISSION,
                });
              });
          } else {
            setIsCameraModalVisible(true);
            examViolation({
              violationType: EXAM_VIOLATION_TYPES.CAMERA_PERMISSION,
            });
          }
        };
      }
    }, FULL_SCREEN_ALERT_TIMER);

    return () => clearInterval(intervalTimeoutId);
  }, [message, examData]);

  useEffect(() => {
    const onRightClick = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", onRightClick);
    return () => document.removeEventListener("contextmenu", onRightClick);
  }, []);

  useEffect(() => {
    const onF11Disable = (event) => {
      if (event.key == "F11") {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", onF11Disable);
    return () => document.removeEventListener("keydown", onF11Disable);
  }, []);

  window.history.pushState(null, "", window.location.href);

  window.onpopstate = function preventNavigation() {
    if (!window.location.href.includes("#code-output")) {
      if (message != null) {
        window.location.assign("/");
      } else {
        window.history.pushState(null, "", window.location.href);
        setAlertMessage("You are not allowed to navigate");
        setIsAlertTriggered(true);
      }
    }
  };

  return (
    <section className="min-h-screen bg-gray-lighterShade3">
      {examData && examData.exam_type && (
        <Header
          examName={examData.exam_name}
          onFullScreenButtonClick={requestFullScreen}
          showFullScreenButton={!isFullScreen}
          isQuestionPresent={isQuestionPresent}
          candidateJobId={examData.candidate_job_id}
          candidateName={examData.candidateName}
          duration={examData.seconds_left}
          setIsFinishModalVisible={setIsFinishModalVisible}
          sectionName={sectionName}
        />
      )}
      {isAlertTriggered && (
        <Alert
          type="warning"
          isAlertTriggered={isAlertTriggered}
          setIsAlertTriggered={setIsAlertTriggered}
          message={alertMessage}
        />
      )}
      {isCameraModalVisible && (
        <Modal
          warningIcon
          title="Warning"
          description={cameraWarning()}
          rejectButtonText="OK, I understand"
          rejectBtnType="warning"
          customButtonWidth={44}
          handleReject={() => setIsCameraModalVisible(false)}
        />
      )}
      {isFinishModalVisible && (
        <Modal
          title="Finish Exam"
          description="Do you want to finish the exam? This will be an irreversible action."
          testId="finish-exam-confirm-modal"
          acceptButtonText="Finish Exam"
          rejectButtonText="Cancel"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          customButtonWidth={32}
          handleAccept={onFinishButtonClick}
          handleReject={() => setIsFinishModalVisible(false)}
          isAsyncClick={false}
        />
      )}
      {isFullScreenModal && (
        <Modal
          warningIcon
          title="Warning"
          description="Please go to full-screen by clicking on the Full Screen button. Not doing this will disqualify you from the exam."
          acceptButtonText="Go to Full Screen"
          testId="full-screen-warning-modal"
          acceptBtnType="warning"
          customButtonWidth={44}
          handleAccept={requestFullScreen}
          isAsyncClick={false}
        />
      )}
      {message != null ? (
        <div>
          {isQuestionPresent === false ? (
            <div className="text-center">
              <Message message={message} successMessage={successMessage} />
              {!searchParams.get("mock_test") && (
                <p className="relative bottom-16">
                  {" "}
                  Result will be emailed to you shortly.
                </p>
              )}
            </div>
          ) : (
            <Message
              message="You Have Already Appeared For The Exam"
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
            />
          )}

          {isFeedBackLink ? (
            <div className="mx-auto w-fit">
              <a
                title="Share Feedback"
                target="_blank"
                rel="noreferrer"
                href={isFeedBackLink}
                className="mx-auto h-auto w-fit rounded-full border-primary-main bg-primary-main hover:bg-primary-dark py-2 px-20 text-center text-base text-white"
              >
                Share Feedback
              </a>
            </div>
          ) : (
            <div className="text-center">
              <Button
                title="Close Tab"
                btnName="Close Tab"
                btnClassName="w-72 text-center"
                size="lg"
                type="primary"
                onClick={() => window.close()}
                isAsyncClick
              />
            </div>
          )}
        </div>
      ) : (
        examData &&
        examData.exam_type && (
          <QuestionPaper
            examName={examData.exam_name}
            duration={examData.seconds_left}
            examCandidateId={Number(examId)}
            candidateJobId={examData.candidate_job_id}
            totalQuestions={examData.total_questions}
            examType={examData.exam_type === "coding"}
            setIsFinishModalVisible={setIsFinishModalVisible}
            onFinish={onFinishButtonClick}
            isDropDownOpen={isDropDownOpen}
            setIsDropDownOpen={setIsDropDownOpen}
            videoRef={videoRef}
            cameraOption={examData.camera_option}
            intervalIdRef={intervalIdRef}
            examViolation={examViolation}
            setSectionName={setSectionName}
            setAlertMessage={setAlertMessage}
            setIsAlertTriggered={setIsAlertTriggered}
          />
        )
      )}
      {isTabSwitch && (
        <Modal
          warningIcon
          title="Warning"
          description="Switching tabs multiple times will terminate your exam."
          rejectButtonText="OK, I understand"
          testId="tab-switching-warning-modal"
          rejectBtnType="warning"
          customButtonWidth={44}
          handleReject={() => setIsTabSwitch(false)}
        />
      )}
    </section>
  );
};

export default withOneTabEnforcer({
  appName: "Unique-CandidateExam",
  OnlyOneTabComponent: DifferentWarningComponent,
  localStorageTimeout: 15 * 1000,
  localStorageResetInterval: 10 * 1000,
})(ExamComponent);
