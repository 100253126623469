import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";
import classNames from "classnames";
import PropTypes from "prop-types";

import { sampleLOIDetails, sendLOI, showLOI } from "api/erp/jobApi";
import { ALERT_TYPE } from "app/constants";
import { ActivityIndicator, LabeledInput } from "components/atoms";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import { Modal } from "components/molecules";
import { ATTACHMENT_SIZE, ATTACHMENT_TYPE } from "constants/common";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";

const SendLOIModal = ({ candidate, setShowModal, getCandidateStageData }) => {
  const [sampleMail, setSampleMail] = useState();
  const [showAttachments, setShowAttachments] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loiDetails, setLoiDetails] = useState();

  const showAlert = useAlert();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    clearErrors,
    watch,
    reset,
  } = useForm();

  useEffect(() => {
    if (candidate?.placed_candidate_loi_id) {
      showLOI(
        candidate?.job_uuid,
        candidate?.placed_candidate_id,
        candidate?.placed_candidate_loi_id
      ).then((response) => {
        setLoiDetails(response.data);
      });
    } else {
      sampleLOIDetails(candidate?.placed_candidate_id).then((response) => {
        setSampleMail(response.data);
      });
    }
  }, []);

  useEffect(
    () =>
      reset({
        subject: sampleMail?.subject,
        description: sampleMail?.description,
        image: [],
      }),
    [sampleMail]
  );
  const pdfMimeType = /application\/pdf/i;

  const setAttachments = (e) => {
    const files = e.target?.files;

    for (let i = 0; i < files.length; i += 1) {
      if (!files[i].type.match(pdfMimeType)) {
        setError("attachments", { message: ATTACHMENT_TYPE });
      } else if (files[i].size >= 5000000) {
        setError("attachments", { message: ATTACHMENT_SIZE });
      } else {
        setShowAttachments((prevAttachments) => [...prevAttachments, files[i]]);
        clearErrors("attachments");
      }
    }
  };

  const handleRemoveAttachment = (attachment) => {
    const arr = showAttachments.filter((item) => item.name !== attachment.name);
    setShowAttachments(arr);
  };

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    setShowConfirmationModal(true);
    const formData = new FormData();
    showAttachments.forEach((image) => formData.append("loi_files[]", image));
    formData.append("subject", data.subject);
    formData.append("description", data.description);
    if (showConfirmationModal) {
      return sendLOI(
        candidate.job_uuid,
        candidate.placed_candidate_id,
        formData
      )
        .then((response) => {
          setShowModal(false);
          getCandidateStageData();
          showAlert(
            ALERT_TYPE[response.meta.message_type],
            response.meta.message
          );
        })
        .catch((errors) => {
          setShowConfirmationModal(false);
          if (errors.response.data.meta) {
            return showAlert(
              ALERT_TYPE[errors.response.data.meta.message_type],
              errors.response.data.meta.message
            );
          }
          setErrorsOnFields(errors.response.data.errors);
        });
    }
  };

  return (
    <>
      {candidate?.placed_candidate_loi_id ? (
        <Modal
          title="View LOI"
          scrollClass="overflow-y-auto max-h-[30rem]"
          handleCancel={() => setShowModal(false)}
          testId="send-loi-modal"
          customModalWidth="35rem"
          onOutsideClickHandler={() => setShowModal(false)}
          isAsyncClick={false}
        >
          {loiDetails ? (
            <div>
              <div className="my-2">
                <label
                  htmlFor="subject"
                  className="mt-2 mb-1 block font-medium"
                >
                  Subject
                </label>
                <div className="border p-2 rounded-md hover:bg-gray-lighterShade3">
                  {loiDetails.subject}
                </div>
              </div>
              <div className="py-4 border px-2 my-4 rounded-md hover:bg-gray-lighterShade3">
                <RenderParsedHTML content={loiDetails.description || ""} />{" "}
              </div>
              <div className="my-2 flex flex-col ">
                <label
                  htmlFor="attachments"
                  className="mt-2 block font-medium "
                >
                  LOI Documents
                </label>
                <div className="mt-3 flex flex-col ">
                  {loiDetails?.attachments.map((attachment, index) => (
                    <div key={index} className="border mb-2 rounded-md">
                      <a
                        title="View Pdf"
                        target="_blank"
                        rel="noreferrer"
                        href={attachment.attachment}
                        className="flex p-2 text-left text-sm text-primary-main hover:bg-primary-lightest"
                      >
                        {attachment.name || attachment.attachment_file_name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <ActivityIndicator />
          )}
        </Modal>
      ) : (
        <Modal
          title="Send LOI"
          scrollClass="overflow-y-auto max-h-[30rem]"
          acceptBtnType={
            !watch("subject") || showAttachments.length == 0
              ? "disabled"
              : "primary"
          }
          handleCancel={() => {
            setShowModal(false);
            setShowAttachments([]);
          }}
          acceptButtonText="Send"
          testId="send-loi-modal"
          customButtonWidth={32}
          customModalWidth="35rem"
          isAsyncClick={false}
          handleAccept={handleSubmit((data) => submitHandler(data))}
          isDirty={
            showAttachments.length > 0 ||
            watch("subject") !== sampleMail?.subject ||
            watch("description") !== sampleMail?.description
          }
        >
          {sampleMail ? (
            <form
              data-testid="Send-loi-Form"
              className="mt-4"
              onSubmit={handleSubmit((data) => submitHandler(data))}
            >
              <div className="my-2">
                <LabeledInput
                  name="subject"
                  id="subject"
                  type="text"
                  data-testid="subject-input"
                  label="Subject"
                  labelClassNames={classNames(
                    "required mt-2 mb-1 block font-medium ",
                    errors.subject && "text-danger-main"
                  )}
                  inputClassNames={`${
                    errors.subject ? "input input-error" : "input"
                  } w-full`}
                  register={register}
                  validation={{
                    required: requiredValidation(),
                    validate: (value) =>
                      value.trim() !== "" || "can't be blank",
                    maxLength: {
                      value: 255,
                      message: characterValidation({ limit: 255 }),
                    },
                  }}
                  errorMessage={errors.subject?.message}
                />
              </div>

              <div className="mt-3 mb-1">
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: requiredValidation(),
                    validate: (value) =>
                      value.trim() !== "" || "can't be blank",
                    maxLength: {
                      value: 3000,
                      message: characterValidation({ limit: 3000 }),
                    },
                  }}
                  render={({ field }) => (
                    <RichTextEditor
                      id="description"
                      content={field.value}
                      onChange={(content) => field.onChange(content)}
                      placeholder="Description"
                      fieldError={!!errors.description?.message}
                    />
                  )}
                />
                {errors.description && (
                  <p className="mt-0 text-sm text-danger-dark">
                    {errors.description?.message}
                  </p>
                )}
              </div>

              <div className="flex flex-col ">
                {showAttachments?.map((attachment, index) => (
                  <div
                    key={index}
                    className="flex w-fit break-all text-sm flex-row rounded-md bg-gray-200 py-1 items-center px-2 my-1 gap-1"
                  >
                    {attachment.name || attachment.attachment_file_name}
                    <button
                      title="Remove Attachment"
                      type="button"
                      onClick={() => handleRemoveAttachment(attachment)}
                      className="cursor-pointer rounded-full hover:bg-primary-lightest"
                    >
                      <AiOutlineClose className="text-sm" />
                    </button>
                  </div>
                ))}
              </div>
              <label
                htmlFor="attachments"
                className={`pointer-events-none my-2 block font-medium required ${
                  errors.attachments && "text-danger-main"
                }`}
                {...register("filename")}
              >
                Attach LOI Pdf{" "}
                <span className=" text-xs italic">( less than 5 mb )</span>
              </label>

              <label
                htmlFor="attachments"
                className="btn-text-primary bg-primary-lightest"
              >
                Choose File(S)
              </label>

              <input
                id="attachments"
                className={`${
                  errors.attachments ? "input-error" : ""
                } hidden w-[229px]`}
                type="file"
                accept=".pdf"
                {...register("attachments", {
                  required: requiredValidation(),
                })}
                onChange={setAttachments}
                multiple
              />
              {errors.attachments && (
                <p className="text-sm text-danger-dark">
                  {errors.attachments.message}
                </p>
              )}
              <div className="flex text-secondary-alertDark items-center gap-2 mt-3 pt-4">
                <FaInfoCircle />
                <span className="text-sm">
                  The response link will be attached in the mail.
                </span>
              </div>
            </form>
          ) : (
            <ActivityIndicator />
          )}
        </Modal>
      )}
      {showConfirmationModal && (
        <Modal
          title="Are you sure?"
          description="This will be an irreversible action."
          acceptBtnType="primary"
          acceptButtonText="Send"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          handleAccept={handleSubmit((data) => submitHandler(data))}
          handleReject={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};

SendLOIModal.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    job_uuid: PropTypes.string,
    placed_candidate_id: PropTypes.number,
    placed_candidate_loi_id: PropTypes.number,
  }),
  setShowModal: PropTypes.func,
  getCandidateStageData: PropTypes.func,
};

export default SendLOIModal;
