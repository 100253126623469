import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { capitalize } from "lodash";
import PropTypes from "prop-types";

import { ICON_SIZE_HASH } from "app/constants";
import ActionAlert from "components/atoms/ActionAlert";
import { JOB_VISIBILITY } from "constants/jobs";

import AllowedColleges from "../AllowedColleges";

const JobVisibility = ({ job, stageCount }) => {
  const [searchParams] = useSearchParams();

  const stageId = searchParams.get("stageId");
  const currentLobby = searchParams.get("lobby");
  const [showRestrictedCollege, setShowRestrictedCollege] = useState(false);

  const getAutoAddStatus = () => {
    if (job?.auto_add_to_first_stage) {
      if (stageCount > 0) {
        return <span className="ml-1 font-semibold text-primary-main">ON</span>;
      }
      return (
        <>
          <span className="ml-1 font-semibold text-primary-main">PAUSED</span>
          <span className="mx-1 text-gray-dark">
            due to absence of first stage.
          </span>
        </>
      );
    }
    return <span className="ml-1 font-semibold text-primary-main">OFF</span>;
  };
  return (
    <>
      {currentLobby === "ALL_APPLICANTS" && (
        <div className="mt-2 flex flex-col text-md">
          {job?.hidden && (
            <p className="mt-1 text-gray-dark">Only accessible by link</p>
          )}

          <div className="flex text-gray-dark mt-1">
            <span className="mr-1"> Visibility : </span>
            {job?.visibility === JOB_VISIBILITY.restricted ? (
              <div className="flex items-center">
                {capitalize(JOB_VISIBILITY.restricted)}
                <FaInfoCircle
                  className="ml-1 cursor-pointer text-secondary-alertDark"
                  size={18}
                  onClick={() => setShowRestrictedCollege(true)}
                />
              </div>
            ) : (
              capitalize(job?.visibility)
            )}
          </div>

          <div className="mt-1">
            <p className="mr-2 text-gray-dark">
              Auto add applicants is {getAutoAddStatus()}
            </p>
          </div>
        </div>
      )}

      {stageId && (
        <ActionAlert
          message="Approved candidates are automatically moved to the next stage."
          alertType="info"
          isButtonRequire={false}
          roundedBorder={false}
          customClassName="mt-3"
          iconName="info"
          iconSize={ICON_SIZE_HASH.smd}
        />
      )}

      {showRestrictedCollege && (
        <AllowedColleges
          setShowRestrictedCollege={setShowRestrictedCollege}
          allowedColleges={job?.colleges}
          allowedSkills={job?.skills}
          allowedQualifications={job?.qualifications}
          restrictedByColleges={job?.restricted_by_colleges}
          restrictedByQualifications={job?.restricted_by_qualifications}
          restrictedBySkills={job?.restricted_by_skills}
        />
      )}
    </>
  );
};

JobVisibility.propTypes = {
  job: PropTypes.shape({
    visibility: PropTypes.string,
    hidden: PropTypes.bool,
    auto_add_to_first_stage: PropTypes.bool,
    colleges: PropTypes.arrayOf(PropTypes.object),
    skills: PropTypes.array,
    qualifications: PropTypes.array,
    restricted_by_colleges: PropTypes.bool,
    restricted_by_qualifications: PropTypes.bool,
    restricted_by_skills: PropTypes.bool,
  }),
  stageCount: PropTypes.number,
};

export default JobVisibility;
