/* eslint-disable no-underscore-dangle */
import parse from "html-react-parser";

export const handleOptionLabel = (option, context, existingOptions) => {
  // parse the HTML to highlight search word
  if (context === "menu") {
    if (
      option?.__isNew__ &&
      !existingOptions?.some(
        (existingOption) =>
          existingOption.toLowerCase() === option?.value.toLowerCase()
      )
    ) {
      return parse(`${option.label}`);
    }

    if (!option?.__isNew__) {
      return parse(`${option.name}`);
    }
    return null;
  }

  if (context === "value") {
    const content = option.name || option.label;
    const optionValue = content?.replace("class='highlight-text'", "");
    return parse(`${optionValue}`);
  }
};
