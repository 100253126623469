import { AiOutlineClose } from "react-icons/ai";
import classNames from "classnames";
import PropTypes from "prop-types";

const Pill = ({
  label,
  outline,
  bgColor = "bg-gray-lightest" && !outline,
  textColor,
  actionable,
  onDelete,
  padding,
  margin,
  className,
}) => {
  return (
    <div
      className={classNames(
        `my-2 mr-2 flex max-w-max items-center rounded-full px-1 py-1 shadow-md`,
        {
          "outline outline-1": outline,
          [textColor]: textColor,
          [bgColor]: bgColor,
          [padding]: padding,
          [margin]: margin,
          [className]: className,
        }
      )}
    >
      <span className="cursor-default break-normal px-2 text-sm">{label}</span>
      {actionable && (
        <button
          type="button"
          onClick={onDelete}
          className="cursor-pointer rounded-full bg-gray-300 p-1 hover:bg-gray-light"
        >
          <AiOutlineClose />
        </button>
      )}
    </div>
  );
};

Pill.propTypes = {
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  padding: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.string,
  outline: PropTypes.bool,
  actionable: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default Pill;
