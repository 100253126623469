import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineCopy,
  AiOutlinePlus,
} from "react-icons/ai";
import { BsEyeFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { getCategories, getQuestionTags } from "api/erp/questionPaperApi";
import { ALERT_TYPE } from "app/constants";
import { Button, EmptyMessage, InfiniteScrollWrapper } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import SearchBar from "components/common/SearchFilterToolBar/SearchBar";
import { Modal } from "components/molecules";
import { DEFAULT_PAGE_NUMBER } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import useAlert from "hooks/useAlert";
import {
  deleteQuestionAction,
  getQuestionBankAction,
} from "store/thunkActions/erp/questionBankThunk";

import QuestionBankModal from "./QuestionBankModal";

const QuestionBankIndex = ({ showQPImportAlert }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const [searchParams, setSearchParams] = useSearchParams();
  const useFormProps = useForm();

  const [tags, setTags] = useState();
  const [categories, setCategories] = useState();
  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [isQBQuestionAdding, setIsQBQuestionAdding] = useState(false);

  const {
    data: storeQuestionsList,
    meta: questionBankMeta,
    isLoading: isQuestionBankLoading,
  } = useSelector((state) => state.questionBank);

  const QUESTION_BANK_FILTER = [
    {
      label: "Question Type",
      placeholder: "question type",
      name: "type",
      multiSelect: false,
      dropdownOptions: [
        { label: "MCQ", value: "mcq" },
        { label: "Coding", value: "coding" },
      ],
    },
    {
      label: "Tags",
      placeholder: "tags",
      name: "tags",
      multiSelect: true,
      dropdownOptions: tags,
    },
    {
      label: "Category",
      placeholder: "category",
      name: "category",
      multiSelect: false,
      dropdownOptions: categories,
    },
  ];

  const resetFilterForm = () => {
    useFormProps.reset({
      type: searchParams.has("type")
        ? QUESTION_BANK_FILTER[0].dropdownOptions.find(
            (type) => type.value === searchParams.get("type")
          )
        : undefined,
      tags: searchParams.has("tags")
        ? QUESTION_BANK_FILTER[1].dropdownOptions?.filter((tag) =>
            searchParams.getAll("tags").includes(String(tag.value))
          )
        : undefined,

      category: searchParams.has("category")
        ? QUESTION_BANK_FILTER[2].dropdownOptions?.find(
            (category) => category.value === searchParams.get("category")
          )
        : undefined,
    });
  };

  const getQuestionBank = (page) =>
    dispatch(
      getQuestionBankAction({
        page,
        type: searchParams.get("type"),
        tag_ids: searchParams.getAll("tags"),
        category: searchParams.get("category"),
        search_term: searchParams.get("search_term"),
      })
    ).then(() => {
      resetFilterForm();
    });

  const handleDelete = (question) => {
    setOpenDeleteQuestionModal(true);
    setSelectedQuestion(question);
  };

  const handleDeleteQuestion = () => {
    return dispatch(
      deleteQuestionAction(
        selectedQuestion.question_id
          ? selectedQuestion.question_id
          : selectedQuestion.id
      )
    )
      .unwrap()
      .then(({ meta }) => {
        setOpenDeleteQuestionModal(false);
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        getQuestionBank(DEFAULT_PAGE_NUMBER);
      })
      .catch(({ meta, errors }) => {
        setOpenDeleteQuestionModal(false);
        if (errors) {
          showAlert(ALERT_TYPE.DANGER, errors.question_papers_questions[0]);
        } else {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
        }
      });
  };

  const storePosition = (e) => {
    const position = e.target.getBoundingClientRect();
    sessionStorage.setItem("PositionQB-X", window.pageXOffset + position.x);
    sessionStorage.setItem("PositionQB-Y", window.pageYOffset);
  };

  useEffect(() => {
    const positionX = sessionStorage.getItem("PositionQB-X");
    const positionY = sessionStorage.getItem("PositionQB-Y");

    window.scrollTo({ top: positionY, left: positionX, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getQuestionBank(DEFAULT_PAGE_NUMBER);
  }, [searchParams]);

  const anyFilterApplied = () =>
    searchParams.has("type") ||
    searchParams.has("tags") ||
    searchParams.has("category");

  const generateFilterCriterias = () => {
    const filterCriterias = [];

    if (searchParams.has("type")) {
      filterCriterias.push("Question Type");
    }
    if (searchParams.has("tags")) {
      filterCriterias.push("Tags");
    }
    if (searchParams.has("category")) {
      filterCriterias.push("Category");
    }

    return filterCriterias.join(" | ");
  };

  useEffect(() => {
    getQuestionTags().then((response) => {
      const tagData = response.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setTags(tagData);
    });

    getCategories().then((response) => {
      const categoriesData = response.data.map((item) => ({
        label: item.name,
        value: item.name,
      }));

      setCategories(categoriesData);
    });
  }, []);

  return (
    <div>
      <div
        className={classNames(
          "py-4 sticky z-10 bg-gray-lighterShade3 flex flex-row gap-4 min-h-[50px] items-start justify-between",
          showQPImportAlert ? "top-[230px] sm:top-[175px]" : "top-[94px]"
        )}
      >
        <div>
          <div className="sm:max-w-[450px]">
            <SearchBar
              anyFilterApplied={anyFilterApplied}
              searchParams={searchParams}
              resetFilterForm={resetFilterForm}
              useFormProps={useFormProps}
              setSearchParams={setSearchParams}
              filterField={QUESTION_BANK_FILTER}
              searchBarPlaceholder="Search by question name"
              defaultSearchParams={{
                tab: searchParams.get("tab"),
              }}
            />
          </div>
          {anyFilterApplied() && (
            <p className="my-3 text-sm text-primary-main align-top">
              <span className="mr-1"> Filtered by: </span>

              <span>{generateFilterCriterias()}</span>
            </p>
          )}
        </div>
        <button
          title="Create Question"
          data-testid="add-question-bank-question-btn"
          className="z-10 items-center rounded-full bg-primary-main p-2 hover:bg-primary-dark"
          onClick={() => setIsQBQuestionAdding(true)}
        >
          <AiOutlinePlus className="text-2xl text-white" />
        </button>
        {isQBQuestionAdding && (
          <QuestionBankModal setIsEditState={setIsQBQuestionAdding} />
        )}
      </div>

      <div className="relative rounded-xl">
        <div className="overflow-x rounded-xl">
          <div className="mb-8 overflow-x-auto overflow-y-hidden shadow-sm">
            <InfiniteScrollWrapper
              hasMore={questionBankMeta.has_more}
              loadMoreHandler={() =>
                getQuestionBank(questionBankMeta.page_number + 1)
              }
            >
              {storeQuestionsList?.length === 0 && !isQuestionBankLoading && (
                <EmptyMessage message="" />
              )}

              {storeQuestionsList.map((data, index) => {
                return (
                  <div
                    className="rounded-md border border-gray-light bg-white px-2 shadow-sm hover:bg-gray-lighter mb-2"
                    data-testid={`question-id-${data.id}`}
                    key={data.id}
                  >
                    <div className="flex justify-between items-center max-w-xl py-2 md:max-w-full">
                      <span className="text-lg font-medium">
                        Question {index + 1}
                      </span>
                      <div className="flex justify-between items-center ">
                        <Link
                          title="View Question"
                          data-testid="view-question"
                          className="cursor-pointer rounded-md p-2 hover:bg-primary-lighter"
                          to={`/questions_manager/question/${
                            data.id
                          }?${constructQueryParams({
                            direct: "question_bank",
                          })}`}
                          onClick={storePosition}
                        >
                          <BsEyeFill className="text-xl text-primary-main " />
                        </Link>
                        <Link
                          title="Edit"
                          data-testid="question-edit-btn"
                          className="cursor-pointer rounded-md p-2 hover:bg-primary-lighter"
                          to={`/questions_manager/question_bank?${constructQueryParams(
                            {
                              question_type: data.question_type,
                              question_id: data.id,
                              view_question: data.id,
                            }
                          )}`}
                          state={{ index: true }}
                          onClick={storePosition}
                        >
                          <AiFillEdit className="text-xl text-primary-dark" />
                        </Link>
                        <Button
                          title="Clone"
                          onClick={() =>
                            navigate(
                              `/questions_manager/question_bank?${constructQueryParams(
                                {
                                  question_type: data.question_type,
                                  question_id: data.id,
                                  clone: true,
                                }
                              )}`
                            )
                          }
                          btnClassName="p-2 hover:bg-primary-lighter rounded-md"
                        >
                          <AiOutlineCopy className="text-xl text-primary-main" />
                        </Button>
                        <Button
                          title="Delete"
                          onClick={() => handleDelete(data)}
                          testId="delete-question-btn"
                          btnClassName="p-2 hover:bg-danger-lighter rounded-md"
                        >
                          <AiFillDelete className="text-xl text-danger-dark " />
                        </Button>
                      </div>
                    </div>

                    <div className="flex w-full justify-between">
                      <div data-testid="question-text">
                        <RenderParsedHTML
                          content={data.text || ""}
                          className="w-full pb-4"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScrollWrapper>
            {openDeleteQuestionModal && (
              <Modal
                title="Are you sure?"
                testId="delete-question-modal"
                description="Are you sure you want to delete this question?"
                acceptButtonText="Yes"
                acceptBtnType="primary"
                rejectButtonText="No"
                rejectBtnType="secondary"
                handleAccept={handleDeleteQuestion}
                handleReject={() => setOpenDeleteQuestionModal(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

QuestionBankIndex.propTypes = {
  showQPImportAlert: PropTypes.bool,
};

export default QuestionBankIndex;
