import PropTypes from "prop-types";

import { Button } from "components/atoms";

const ExamCandidateDetails = ({
  examName,
  candidateName,
  sectionName,
  setIsFinishModalVisible,
  isDropdownOpen,
  setIsDropDownOpen,
}) => {
  return (
    <aside
      className={`fixed z-50 hidden min-h-screen w-[200px] duration-100 ease-in-out lg:translate-x-0 ${
        isDropdownOpen ? "translate-x-0" : "translate-x-[-200px]"
      } lg:block`}
    >
      <div
        className={`width-full sticky bottom-2 z-10 flex min-h-screen
                   flex-col justify-between bg-gray-lighterShade3 pt-4 text-primary-main lg:hidden ${
                     isDropdownOpen ? "border-none" : "border-l-4 "
                   }`}
      >
        <div className="ml-4 flex flex-col gap-6 pt-2">
          <p className="font-semibold">{examName}</p>
          <div>
            <p>
              Name:
              {candidateName}
            </p>
            {sectionName && (
              <p>
                Section:
                {sectionName}
              </p>
            )}
          </div>
        </div>
        <div className="sticky bottom-2 z-20 mr-[-30px] w-auto  self-end">
          <Button
            size="md"
            customWidth={32}
            onClick={() => {
              setIsFinishModalVisible(true);
              setIsDropDownOpen(false);
            }}
            btnName="Finish Exam"
            type="primary"
            title="Finish Exam"
          />
        </div>
      </div>
    </aside>
  );
};

ExamCandidateDetails.propTypes = {
  examName: PropTypes.string,
  candidateName: PropTypes.string,
  sectionName: PropTypes.string,
  setIsFinishModalVisible: PropTypes.func,
  isDropdownOpen: PropTypes.bool,
  setIsDropDownOpen: PropTypes.func,
};

export default ExamCandidateDetails;
