import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { showQuestion } from "api/erp/questionPaperApi";
import CodingForm from "components/erp/QuestionsManager/QuestionForm/CodingForm";
import Form from "components/erp/QuestionsManager/QuestionForm/Form";

const QuestionBankForm = () => {
  const [searchParams] = useSearchParams();
  const questionType = searchParams.get("question_type");
  const questionId = searchParams.get("question_id");
  const [thisQuestion, setThisQuestion] = useState();

  useEffect(() => {
    if (questionId) {
      showQuestion(questionId)
        .then(({ data }) => setThisQuestion(data))
        .catch(() => {});
    }
  }, []);

  return (
    <div className="mx-auto h-full md:px-4">
      {questionType === "mcq" ? (
        <Form selectedQuestion={thisQuestion} />
      ) : (
        <CodingForm selectedQuestion={thisQuestion} />
      )}
    </div>
  );
};

export default QuestionBankForm;
