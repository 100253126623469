import { AiOutlineDoubleRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import GroupDiscussion from "../GroupDiscussions/GroupDiscussion";

const GroupDiscussionCard = ({
  status,
  groupDiscussions,
  hasMoreGroupDiscussions,
}) => {
  if (!groupDiscussions?.length) {
    return null;
  }

  return (
    <>
      <h2 className="py-2 text-xl font-semibold capitalize">
        {status} Group Discussions
      </h2>
      <div
        className={`  relative bg-white px-4 py-3 text-sm  ${
          status === "upcoming" ? "" : "rounded-2xl border "
        } `}
      >
        <div>
          {groupDiscussions?.map((groupDiscussion) => (
            <div
              className={
                groupDiscussions.indexOf(groupDiscussion) != 0 &&
                "border-t-2 border-solid border-gray-lighterShade2"
              }
              key={groupDiscussion.id}
            >
              <GroupDiscussion
                groupDiscussion={groupDiscussion}
                status={status}
              />
            </div>
          ))}
        </div>
        {status === "upcoming" && hasMoreGroupDiscussions && (
          <Link
            to="/groupDiscussions?tab=upcoming"
            className="absolute right-1.5 bottom-1.5 flex text-primary-light"
            title="View all"
          >
            View all <AiOutlineDoubleRight className="mt-1" />
          </Link>
        )}
      </div>
    </>
  );
};

GroupDiscussionCard.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming"]),
  groupDiscussions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      groupDiscussion_type: PropTypes.oneOf(["coding", "mcq"]),
      start_at: PropTypes.string,
      end_at: PropTypes.string,
      duration: PropTypes.number,
      job_uuid: PropTypes.string,
      job_title: PropTypes.string,
      organisation_name: PropTypes.string,
      job_location: PropTypes.string,
      has_started: PropTypes.bool,
      link: PropTypes.string,
      job_page_number: PropTypes.number,
      candidate_status: PropTypes.string,
    })
  ),
  hasMoreGroupDiscussions: PropTypes.bool,
};
export default GroupDiscussionCard;
