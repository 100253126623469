import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getJobsCandidate,
  getPlacedCandidates,
  getStageCandidates,
  removeStageCandidate,
} from "api/erp/jobApi";

export const getJobCandidatesAction = createAsyncThunk(
  "jobs/candidate/get",
  async ({
    job_uuid: jobUuid,
    page,
    candidate_name: candidateName,
    id,
    statuses,
    locations,
    project,
    willing_to_relocate: willingToRelocate,
    has_portfolio: hasPortfolio,
    sort_order: sortOrder,
    sort_by: sortBy,
    academic_marks: academicMarks,
    page_size: pageSize,
  }) => {
    const response = await getJobsCandidate(
      jobUuid,
      page,
      candidateName,
      id,
      statuses,
      locations,
      project,
      willingToRelocate,
      hasPortfolio,
      sortOrder,
      sortBy,
      academicMarks,
      pageSize
    );
    return response;
  }
);

export const getStageCandidatesAction = createAsyncThunk(
  "jobs/stages/candidate/get",
  async (
    {
      job_uuid: jobUuid,
      stages_id: stagesId,
      page,
      candidate_name: candidateName,
      id,
      cutoff_marks: cutoffMarks,
      cutoff_type: cutoffType,
      marks_type: marksType,
      has_different_sectional_cut_off_marks: hasDifferentSectionalCutOffMarks,
      sectional_cutoff: sectionalCutoff,
      statuses,
      from_date: fromDate,
      to_date: toDate,
      appeared,
      sort_order: sortOrder,
      sort_by: sortBy,
      page_size: pageSize,
      groupUuids,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await getStageCandidates(
        jobUuid,
        stagesId,
        page,
        candidateName,
        id,
        cutoffMarks,
        cutoffType,
        marksType,
        hasDifferentSectionalCutOffMarks,
        sectionalCutoff,
        statuses,
        fromDate,
        toDate,
        appeared,
        sortOrder,
        sortBy,
        pageSize,
        groupUuids
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPlacedCandidatesAction = createAsyncThunk(
  "jobs/placed_candidate/get",
  async ({
    job_uuid: jobUuid,
    page,
    candidate_name: candidateName,
    id,
    statuses,
    sort_order: sortOrder,
    sort_by: sortBy,
    page_size: pageSize,
  }) => {
    const response = await getPlacedCandidates(
      jobUuid,
      page,
      candidateName,
      id,
      statuses,
      sortOrder,
      sortBy,
      pageSize
    );
    return response;
  }
);

export const removeStageCandidateAction = createAsyncThunk(
  "jobs/stages/candidate/remove",
  async (
    {
      job_uuid: jobUuid,
      stage_id: stageId,
      candidate_job_ids: candidateJobIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await removeStageCandidate(
        jobUuid,
        stageId,
        candidateJobIds
      );
      return { response, candidate_job_ids: candidateJobIds };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
