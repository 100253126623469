import { SORT_BY } from "app/constants";

const allApplicantsHeaders = [
  {
    label: "Id",
    sortingAllowed: false,
    headerClassName: "w-10 bg-gray-lighterShade3 md:sticky pr-16 md:left-16",
  },
  {
    label: "Name",
    sortingAllowed: true,
    headerClassName: "w-2/12 bg-gray-lighterShade3 md:sticky md:left-[9.55rem]",
    sortByText: SORT_BY.CANDIDATE_NAME,
  },
  {
    label: "Applied On",
    sortingAllowed: true,
    sortByText: SORT_BY.CREATED_AT,
    sortFlag: true,
    appliedOnSortingRule: true,
    headerClassName: "w-36 pl-5 pr-1",
  },
  {
    label: "Website",
    sortingAllowed: false,
    headerClassName: "w-20",
  },
  {
    label: "Education",
    sortingAllowed: false,
    headerClassName: "w-32",
  },
  {
    label: "Work Experience",
    sortingAllowed: false,
    headerClassName: "w-36",
  },
  {
    label: "Skills",
    sortingAllowed: false,
    headerClassName: "w-20",
  },
  {
    label: "Projects",
    sortingAllowed: false,
    headerClassName: "w-28",
  },
  {
    label: "Languages",
    sortingAllowed: false,
    headerClassName: "w-20",
  },
  {
    label: "Address",
    sortingAllowed: false,
    headerClassName: "w-32",
  },
];

const getAllApplicantsHeaders = () => {
  return allApplicantsHeaders;
};

const getPlacedHeaders = (isUserRoleAllowed) => {
  const placedHeaders = [
    {
      label: "Id",
      sortingAllowed: false,
      headerClassName: isUserRoleAllowed ? "" : "w-1/3",
    },
    {
      label: "Name",
      sortingAllowed: true,
      sortByText: SORT_BY.CANDIDATE_NAME,
    },
    {
      label: "Contact Info",
      sortingAllowed: false,
      isUserRoleAllowed,
    },
    { label: "Status", sortingAllowed: false },
    {
      label: "",
      sortingAllowed: false,
      isUserRoleAllowed,
    },
  ];

  const filteredData = placedHeaders.filter((header) => {
    const userAllowedColumn =
      header?.isUserRoleAllowed === undefined || header?.isUserRoleAllowed;
    return userAllowedColumn;
  });
  return filteredData;
};

const getExamHeaders = (stageExamDetails) => {
  const examHeaders = [
    {
      label: "Id",
      sortingAllowed: false,
      headerClassName:
        "w-10 bg-gray-lighterShade3 md:sticky py-4 pl-2.5 pr-16 md:left-16",
    },
    {
      label: "Name",
      sortingAllowed: true,
      headerClassName:
        "w-2/12 pl-3 bg-gray-lighterShade3 md:sticky md:left-[9.70rem]",
      sortByText: SORT_BY.CANDIDATE_NAME,
    },
    {
      label: "Slot",
      sortingAllowed: true,
      optionalCondition: stageExamDetails[0]?.multi_slot || false,
      sortByText: SORT_BY.SLOT,

      headerClassName: "py-4 pr-4 pl-6",
    },
    {
      label: "Started At",
      sortingAllowed: false,
      headerClassName: stageExamDetails[0]?.multi_slot ? "" : "pl-6",
    },
    {
      label: "Sectional Marks",
      sortingAllowed: false,
      headerClassName: "p-4",
      optionalCondition:
        stageExamDetails[0]?.question_paper?.sectional || false,
    },
    {
      label: `Total Marks \n${
        stageExamDetails[0]?.total_marks
          ? `(out of ${stageExamDetails[0]?.total_marks})`
          : ``
      }`,
      sortingAllowed: true,
      sortByText: SORT_BY.TOTAL_MARKS,

      title: "Total Marks",
      headerClassName: "p-4 items-center",
    },
    {
      label: "Violations",
      sortingAllowed: false,
      headerClassName: "p-4",
    },
    {
      label: "Evaluators",
      sortingAllowed: false,
      optionalCondition: stageExamDetails[0]?.question_paper_type === "coding",
      headerClassName: "p-4",
    },
  ];

  const filteredData = examHeaders.filter((header) => {
    const optionalRenderedColumn =
      header?.optionalCondition === undefined || header?.optionalCondition;
    return optionalRenderedColumn;
  });
  return filteredData;
};

const getInterviewHeaders = () => {
  const interviewHeaders = [
    {
      label: "Id",
      sortingAllowed: false,
      headerClassName:
        "w-20 bg-gray-lighterShade3 py-4 pl-2 md:sticky md:left-16",
    },
    {
      label: "Name",
      sortingAllowed: true,
      headerClassName:
        "w-2/12 bg-gray-lighterShade3 md:sticky p-4 md:left-[9rem]",
      sortByText: SORT_BY.CANDIDATE_NAME,
    },
    {
      label: "Interview Name",
      sortingAllowed: false,
      headerClassName: "py-4 pl-5",
    },
    {
      label: "Start Time",
      sortingAllowed: true,
      sortByText: SORT_BY.START_TIME,

      headerClassName: "p-4",
    },
    { label: "Duration", sortingAllowed: false, headerClassName: "p-4" },
    {
      label: "Interviewer(s)",
      sortingAllowed: false,
      headerClassName: "p-4",
    },
  ];

  return interviewHeaders;
};

const getGDHeaders = () => {
  const interviewHeaders = [
    {
      label: "Id",
      sortingAllowed: false,
      headerClassName:
        "w-20 bg-gray-lighterShade3 py-4 pl-2 md:sticky md:left-16",
    },
    {
      label: "Name",
      sortingAllowed: true,
      headerClassName:
        "w-2/12 bg-gray-lighterShade3 md:sticky p-4 md:left-[9rem]",
      sortByText: SORT_BY.CANDIDATE_NAME,
    },
    {
      label: "Group",
      sortingAllowed: false,
      headerClassName: "py-4 pl-5",
    },
    {
      label: "Start Time",
      sortingAllowed: true,
      sortByText: SORT_BY.START_TIME,

      headerClassName: "p-4",
    },
    { label: "Moderator(s)", sortingAllowed: false, headerClassName: "p-4" },
  ];

  return interviewHeaders;
};

export {
  getAllApplicantsHeaders,
  getExamHeaders,
  getGDHeaders,
  getInterviewHeaders,
  getPlacedHeaders,
};
