import { BiLink } from "react-icons/bi";
import { RiCake2Line } from "react-icons/ri";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import PropTypes from "prop-types";

import { ICON_SIZE_HASH } from "app/constants";
import { Avatar } from "components/atoms";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";

const BasicInfo = ({ candidate }) => {
  return (
    <div className="mx-auto mt-4  w-full p-2 md:px-4 ">
      <div className="flex flex-col md:flex-row">
        {candidate?.avatar?.thumb ? (
          <div className="h-20 w-20 rounded-full outline outline-primary-main">
            <Avatar src={candidate.avatar?.thumb} height={20} width={20} />
          </div>
        ) : (
          <div className="bg:to-primary-light m-2 flex h-20 w-20 items-center justify-center rounded-full bg-orange-50 text-[63px] font-medium text-primary-main outline outline-offset-4">
            {candidate?.name?.charAt(0)}
          </div>
        )}
        <div className="mx-2 mt-4 flex-grow md:mt-0 md:ml-8 md:mr-4 md:w-9/12">
          <div className="flex justify-between">
            <h3
              className="mt-2 flex items-center text-3xl font-normal"
              data-testid="candidate-name"
            >
              {candidate?.name || "Name"}
            </h3>
          </div>
          <p
            className="text-md block font-medium italic text-gray-dark"
            data-testid="candidate-designation"
          >
            {candidate?.designation || "Current Position"}
          </p>
          {candidate?.date_of_birth && (
            <p className="my-1 flex items-center text-sm text-gray-dark">
              <RiCake2Line className="mr-2 inline" />
              {format(new Date(candidate.date_of_birth), "dd MMMM yyyy", {
                locale: enGB,
              })}
            </p>
          )}
          {candidate.portfolio_url && (
            <span className="items-center text-xs text-gray-dark">
              <BiLink className="mr-2 mb-1 inline" size={ICON_SIZE_HASH.sm} />
              <a
                title="Portfolio"
                target="_blank"
                rel="noreferrer"
                className="italic text-primary-light hover:underline"
                href={candidate.portfolio_url}
              >
                Portfolio
              </a>
            </span>
          )}
          <p className="mt-1 block whitespace-pre-wrap text-sm text-gray-main">
            <RenderParsedHTML content={candidate?.about || ""} />
          </p>
        </div>
      </div>
    </div>
  );
};

BasicInfo.propTypes = {
  candidate: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    portfolio_url: PropTypes.string,
    about: PropTypes.string,
    date_of_birth: PropTypes.string,
    is_profile_completed: PropTypes.bool,
    photo_url: PropTypes.string,
    designation: PropTypes.string,
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
  }),
};

export default BasicInfo;
