import { createSlice } from "@reduxjs/toolkit";

import {
  createUsersAction,
  getUsersAction,
  updateUsersAction,
} from "store/thunkActions/erp/userThunk";

const users = createSlice({
  name: "users",
  initialState: {
    data: [],
    meta: undefined,
    isLoading: true,
  },
  reducers: {},
  extraReducers: {
    [getUsersAction.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    [createUsersAction.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [updateUsersAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (user) => user.id == action.payload.data.id
      );
      state.data[index] = action.payload.data;
    },
  },
});

export default users.reducer;
