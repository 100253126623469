import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { checkAppEnvs, getCurrentSubdomain } from "helpers/utils";
import useCheckUserRole from "hooks/useCheckUserRole";

export const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const isLogoutClicked = useSelector(({ auth }) => auth.isLogOutClicked);
  const checkRole = useCheckUserRole(allowedRoles);
  const subdomain = getCurrentSubdomain();

  if (
    checkAppEnvs(subdomain) &&
    location.search?.includes("redirect_to_signup")
  ) {
    if (isLoggedIn) {
      return <Navigate to="/" />;
    }
    return <Navigate to="/signup" />;
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to={`${
          location.pathname.length > 1 && !isLogoutClicked
            ? `/login?redirect_url=${encodeURIComponent(
                location.pathname + location.search
              )}`
            : "/login"
        }`}
      />
    );
  }
  if (checkAppEnvs(subdomain)) {
    return <Outlet />;
  }
  return checkRole ? (
    <Outlet />
  ) : (
    <Navigate to="notfound" state={{ from: location }} replace />
  );
};

RequireAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};
