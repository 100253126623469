import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { startCase } from "lodash";

import { importQuestionPapers } from "api/erp/organizationApi";
import { ALERT_TYPE, QUESTION_PAGE_TYPES } from "app/constants";
import { TabList } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import { DEFAULT_PAGE_SIZE, SITE_TITLE } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import useAlert from "hooks/useAlert";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { getQuestionPapersAction } from "store/thunkActions/erp/questionsThunk";

import CategoriesIndex from "./Categories";
import QuestionBankIndex from "./QuestionBank";
import QuestionPapersIndex from "./QuestionPaper";
import QuestionTagIndex from "./QuestionTag";

const QuestionsManager = () => {
  const { data } = useSelector(({ questionPapers }) => questionPapers);

  const [currentTab, setCurrentTab] = useState(QUESTION_PAGE_TYPES[0].key);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [isQPImporting, setIsQPImporting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const tab = searchParams.get("tab") || currentTab;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const showQPImportAlert =
    !isLoading &&
    data?.length === 0 &&
    !(
      searchParams.has("type") ||
      searchParams.has("sectional") ||
      searchParams.has("active")
    );

  useEffect(() => {
    dispatch(
      updateHeaderTitle({
        breadCrumbDetails: {
          currentPageName: QUESTION_PAGE_TYPES.find((item) => item.key === tab)
            ?.label,
        },
      })
    );
  }, [tab]);

  useEffect(() => {
    if (
      currentTab === QUESTION_PAGE_TYPES[1].key ||
      currentTab === QUESTION_PAGE_TYPES[2].key
    ) {
      sessionStorage.removeItem(`page:${currentPage}:PositionY`);
    }

    if (
      currentTab === QUESTION_PAGE_TYPES[0].key ||
      currentTab === QUESTION_PAGE_TYPES[2].key
    ) {
      sessionStorage.removeItem("PositionQB-Y");
    }
  }, [currentTab]);

  useEffect(() => {
    if (tab === "question_papers") {
      sessionStorage.removeItem("selectedSection");
      Object.keys(sessionStorage).forEach((position) => {
        if (position.startsWith("PositionQP-")) {
          sessionStorage.removeItem(`${position}`);
        }
      });
    } else {
      dispatch(
        getQuestionPapersAction({
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        })
      ).then(() => {
        setIsLoading(false);
      });
    }
  }, [tab]);

  useEffect(() => {
    if (tab !== "question_papers") {
      sessionStorage.removeItem("questionsPage");
      sessionStorage.removeItem("questionsPageSize");
    }
  }, [tab]);

  useEffect(() => {
    const validTab = QUESTION_PAGE_TYPES.find((item) => item.key === tab);
    if (!validTab) {
      setCurrentTab(QUESTION_PAGE_TYPES[0].key);
      navigate(
        `/questions_manager?${constructQueryParams({
          tab: "question_papers",
          page: 1,
          page_size: 20,
        })}`
      );
    }
  }, [tab]);

  const questionPackUuid = () => {
    return process.env.REACT_APP_ENV === "production"
      ? "018d2fdd-26b8-7d98-bae3-0d14ce16a865"
      : "018cfd82-5e9d-77b6-8924-da54a2606de3";
  };

  const handleImportQuestionPapers = () => {
    importQuestionPapers({
      question_pack_uuid: questionPackUuid(),
    })
      .then(({ meta }) => {
        dispatch(
          getQuestionPapersAction({
            page: searchParams.get("page"),
            page_size: searchParams.get("page_size"),
            sectional: searchParams.get("sectional"),
            type: searchParams.get("type"),
            active: searchParams.get("active"),
            search_term: searchParams.get("search_term"),
          })
        )
          .unwrap()
          .then(() => {
            showAlert(ALERT_TYPE[meta.message_type], meta.message);
          });

        setCurrentTab(QUESTION_PAGE_TYPES[0].key);
        navigate(
          `/questions_manager?${constructQueryParams({
            tab: "question_papers",
            page: 1,
            page_size: 20,
          })}`
        );
      })
      .catch(({ meta }) => {
        showAlert(ALERT_TYPE[meta?.message_type], meta?.message);
      });
  };

  return (
    <div className="w-full px-4 md:px-4">
      <Helmet>
        <title>{`${startCase(tab)} - ${SITE_TITLE}`}</title>
        <meta property="og:url" content="https://app.joballey.in/" />
      </Helmet>

      <div className="sticky top-[44px] z-10 bg-gray-lighterShade3">
        {showQPImportAlert && (
          <div className="sm:top-[50px] pt-3 bg-gray-lighterShade3">
            <ActionAlert
              message={
                <p className="xs:pr-0 pr-4">
                  Import Question Papers suggested by JobAlley.
                </p>
              }
              alertType="primary"
              iconVerticalAlignment="xs:self-center self-end"
              btnName="+ Import"
              buttonTitle="Import"
              buttonType={isQPImporting ? "disabled" : "primary"}
              customButtonWidth={41}
              iconName="info"
              customClassName="mb-2"
              handleButtonClick={() => {
                setIsQPImporting(true);
                handleImportQuestionPapers();
              }}
            />
          </div>
        )}
        <TabList
          testId="question-manager-tabs"
          tabs={QUESTION_PAGE_TYPES}
          currentTab={tab}
          paramBased
          onTabClick={(key) => {
            if (key !== tab) {
              setCurrentTab(key);
              setSearchParams({ tab: key });
            }
          }}
        />
      </div>

      {tab === QUESTION_PAGE_TYPES[0].key && (
        <QuestionPapersIndex
          currentPage={
            parseInt(sessionStorage.getItem("questionsPage"), 10) || 1
          }
          setCurrentPage={setCurrentPage}
          showQPImportAlert={showQPImportAlert}
          setIsLoading={setIsLoading}
        />
      )}
      {tab === QUESTION_PAGE_TYPES[1].key && (
        <QuestionBankIndex showQPImportAlert={showQPImportAlert} />
      )}
      {tab === QUESTION_PAGE_TYPES[2].key && (
        <CategoriesIndex showQPImportAlert={showQPImportAlert} />
      )}
      {tab === QUESTION_PAGE_TYPES[3].key && (
        <QuestionTagIndex showQPImportAlert={showQPImportAlert} />
      )}
    </div>
  );
};

export default QuestionsManager;
