const hideFreshWorkWidget = () => {
  const hideFreshWorkScript = document.createElement("script");
  hideFreshWorkScript.innerText = `
  if(window.FreshworksWidget){
    FreshworksWidget('hide');
  }
  `;
  document.body.appendChild(hideFreshWorkScript);
};

export default hideFreshWorkWidget;
