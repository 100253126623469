const getBrowserLocation = (
  latitude,
  longitude,
  setLatitude,
  setLongitude,
  setCurrentCity,
  setCurrentState
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }

  if (latitude && longitude) {
    fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    )
      .then((res) => res.json())
      .then((res) => {
        setCurrentCity(res.address?.city);
        setCurrentState(res.address?.state);
      });
  }
};

export default getBrowserLocation;
