import { createSlice } from "@reduxjs/toolkit";

import {
  getJobCandidatesAction,
  getPlacedCandidatesAction,
  getStageCandidatesAction,
  removeStageCandidateAction,
} from "store/thunkActions/erp/candidateJobStageThunk";

const candidateJobStage = createSlice({
  name: "candidateJobStage",
  initialState: {
    isLoading: true,
    data: [],
    meta: undefined,
  },
  reducers: {},
  extraReducers: {
    [getJobCandidatesAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getJobCandidatesAction.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    [getStageCandidatesAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getStageCandidatesAction.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    [getPlacedCandidatesAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getPlacedCandidatesAction.fulfilled]: (state, action) => {
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.isLoading = false;
    },
    [removeStageCandidateAction.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (candidate) => candidate.id == action.payload.candidate_job_ids[0]
      );
      state.data.splice(index, 1);
    },
  },
});

export default candidateJobStage.reducer;
