import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";

import RenderParsedHTML from "components/atoms/RenderParsedHTML";

const WorkExperience = ({ workExperiences }) => {
  return (
    <>
      <div className="resume-section-heading">WORK EXPERIENCE</div>
      {workExperiences.map((experience) => {
        return (
          <div
            className={classnames("resume-section-content", {
              "lg:pb-3 pb-2": workExperiences.length > 1,
            })}
            style={{
              textAlign: "justify",
              color: "rgb(100 100 100)",
              wordBreak: "break-word",
            }}
            key={experience.id}
          >
            <div className="text-base font-semibold">{experience.title}</div>
            <div className="flex lg:flex-row flex-col justify-between">
              <div className="font-medium text-gray-darker">
                {experience.company_name} | {experience.employment_type_name}
              </div>
              <div className="text-gray-darker">
                {moment(experience.start_at).format("YYYY")} -{" "}
                {experience.is_presently_working
                  ? "Present"
                  : moment(experience.end_at).format("YYYY")}
              </div>
            </div>
            <div className="lg:pt-2 pt-1">
              <RenderParsedHTML content={experience.description || ""} />
            </div>
          </div>
        );
      })}
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

WorkExperience.propTypes = {
  workExperiences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      start_at: PropTypes.string,
      end_at: PropTypes.string,
      is_presently_working: PropTypes.bool,
      company_id: PropTypes.number,
      company_name: PropTypes.string,
      employment_type_id: PropTypes.number,
      employment_type_name: PropTypes.string,
    })
  ),
};

export default WorkExperience;
