import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import PropTypes from "prop-types";

import { getCompanies, getDesignations } from "api/candidate/profileApi";
import { Button, LabeledInput } from "components/atoms";
import { handleOptionLabel } from "helpers/formatDropDownOption";
import {
  characterValidation,
  formattedPhoneNumberValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import phoneNumberFormatter from "helpers/phoneNumberFormatter";
import reactSelectStyle from "helpers/reactSelectStyle";
import {
  createReferenceDetailsAction,
  getReferenceDetailsAction,
  updateReferenceDetailsAction,
} from "store/thunkActions/candidate/referenceDetailsThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

const Form = ({ referenceDetail, setIsEditState, setIsAdding }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
    setError,
  } = useForm();
  const dispatch = useDispatch();

  useEffect(
    () =>
      reset({
        ...referenceDetail,
        company: {
          uuid: referenceDetail?.company_uuid,
          name: referenceDetail?.company_name,
        },
        designation: {
          uuid: referenceDetail?.designation_uuid,
          name: referenceDetail?.designation_name,
        },
        phone: referenceDetail
          ? `${referenceDetail.phone.slice(0, 4)}-${referenceDetail.phone.slice(
              4
            )}`
          : undefined,
      }),
    [referenceDetail]
  );

  const designation = watch("designation");
  const company = watch("company");

  const inputErrClass = (error) => {
    return error ? "input-error" : "input";
  };

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const loadCompaniesOptions = async (search, _, { page }) => {
    const { data, meta } = await getCompanies(page, search);

    return {
      options: meta.has_more ? data : [...data, { uuid: null, name: "OTHERS" }],
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadDesignationsOptions = async (search, _, { page }) => {
    const { data, meta } = await getDesignations(page, search);

    return {
      options: meta.has_more ? data : [...data, { uuid: null, name: "OTHERS" }],
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const submitHandler = (data) => {
    const referenceData = {};
    [referenceData.name, referenceData.phone] = [
      data.name,
      data.phone.replace("-", ""),
    ];

    if (data.company?.name) {
      if (data.company.uuid) {
        referenceData.company_uuid = data.company.uuid;
      } else {
        referenceData.company_uuid = null;
        referenceData.company_name = "OTHERS";
        referenceData.company_name_other = data.otherCompany;
      }
    } else {
      referenceData.company_uuid = null;
    }

    if (data.designation?.name) {
      if (data.designation.uuid) {
        referenceData.designation_uuid = data.designation.uuid;
      } else {
        referenceData.designation_uuid = null;
        referenceData.designation_name = "OTHERS";
        referenceData.designation_name_other = data.otherDesignation;
      }
    } else {
      referenceData.designation_uuid = null;
    }

    if (referenceDetail?.uuid) {
      referenceData.uuid = referenceDetail.uuid;
    }

    const action = referenceDetail?.uuid
      ? updateReferenceDetailsAction(referenceData)
      : createReferenceDetailsAction(referenceData);

    return dispatch(action)
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(setCandidateUserAction());
        dispatch(getReferenceDetailsAction());
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  return (
    <form
      className="mt-4"
      onSubmit={handleSubmit((data) => submitHandler(data))}
    >
      <p className="text-sm font-medium text-gray-dark">
        Please fill out the details
        {referenceDetail ? " to update" : " for adding new"} reference
      </p>
      <label
        htmlFor="name"
        className={`required mt-2 mb-1 block font-medium ${
          errors.name && "text-danger-main"
        }`}
      >
        Name
      </label>
      <input
        id="name"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className={`${errors.name ? "input-error" : "input"} w-full`}
        type="text"
        placeholder="Enter name"
        {...register("name", {
          required: requiredValidation(),
          maxLength: {
            value: 100,
            message: characterValidation({ limit: 100 }),
          },
        })}
      />
      {errors.name && (
        <p className="text-sm text-danger-dark">{errors.name.message}</p>
      )}

      <label
        htmlFor="designation_select"
        className={`mt-2 mb-1 block font-medium ${
          errors.designation && "text-danger-main"
        }`}
      >
        Designation
      </label>

      <Controller
        control={control}
        name="designation"
        render={({ field }) => (
          <AsyncPaginate
            isSearchable
            isClearable
            id="designation-select"
            menuPlacement="auto"
            maxMenuHeight={165}
            className="w-full"
            styles={reactSelectStyle(errors.designation)}
            value={field.value?.label || field.value?.name ? field.value : null}
            loadOptions={loadDesignationsOptions}
            onChange={(value) => {
              field.onChange(value);
            }}
            noOptionsMessage={() => "Not found"}
            debounceTimeout={1000}
            placeholder="Select designation name"
            getOptionValue={(option) => option.uuid}
            getOptionLabel={(option) => option.name || option.label}
            additional={{ page: 1 }}
          />
        )}
      />

      {designation?.name === "OTHERS" && (
        <>
          <p className="text-sm  text-warning-main">
            Please recheck the list again before creating a custom designation
            name.
          </p>
          <LabeledInput
            id="others-designation"
            labelClassNames={classNames(
              "block mt-2 mb-1 font-medium required",
              {
                "text-danger-main": errors.otherDesignation,
              }
            )}
            label="Designation Name"
            name="otherDesignation"
            inputClassNames={classNames(
              "w-full",
              inputErrClass(errors.otherDesignation)
            )}
            placeholder="Enter designation name"
            register={register}
            type="text"
            rows={1}
            validation={{
              required: requiredValidation(),
              maxLength: {
                value: 50,
                message: characterValidation({ limit: 50 }),
              },
            }}
            errorMessage={errors.otherDesignation?.message}
          />
        </>
      )}

      <label
        htmlFor="company_select"
        className={`mt-2 mb-1 block font-medium ${
          errors.company && "text-danger-main"
        }`}
      >
        Organisation
      </label>

      <Controller
        control={control}
        name="company"
        render={({ field }) => (
          <AsyncPaginate
            isSearchable
            isClearable
            id="company-select"
            menuPlacement="auto"
            maxMenuHeight={165}
            className="w-full"
            styles={reactSelectStyle(errors.company)}
            value={field.value?.label || field.value?.name ? field.value : null}
            loadOptions={loadCompaniesOptions}
            onChange={(value) => {
              field.onChange(value);
            }}
            noOptionsMessage={() => "Not found"}
            debounceTimeout={1000}
            placeholder="Select organisation name"
            getOptionValue={(option) => option.uuid}
            getOptionLabel={(option) => option.name || option.label}
            additional={{ page: 1 }}
            formatOptionLabel={(option, { context }) =>
              handleOptionLabel(option, context)
            }
          />
        )}
      />

      {company?.name === "OTHERS" && (
        <>
          <p className="text-sm  text-warning-main">
            Please recheck the list again before creating a custom organisation
            name.
          </p>
          <LabeledInput
            id="others-company"
            labelClassNames={classNames(
              "block mt-2 mb-1 font-medium required",
              {
                "text-danger-main": errors.otherCompany,
              }
            )}
            label="Organisation Name"
            name="otherCompany"
            inputClassNames={classNames(
              "w-full",
              inputErrClass(errors.otherCompany)
            )}
            placeholder="Enter organisation name"
            register={register}
            type="text"
            rows={1}
            validation={{
              required: requiredValidation(),
              maxLength: {
                value: 255,
                message: characterValidation({ limit: 255 }),
              },
            }}
            errorMessage={errors.otherCompany?.message}
          />
        </>
      )}

      <label
        htmlFor="phone"
        className={`required mt-2 mb-1 block font-medium ${
          errors.phone && "text-danger-main"
        }`}
      >
        Phone Number
      </label>
      <div className="flex">
        <input
          disabled
          name="country-code"
          type="text"
          value="+91 "
          className="w-12 input"
        />
        <input
          id="phone"
          className={`${errors.phone ? "input-error" : "input"} w-full`}
          type="text"
          placeholder="XXXX-XXXXXX"
          {...register("phone", {
            required: requiredValidation(),
            maxLength: {
              value: 11,
              message: formattedPhoneNumberValidation(),
            },
            pattern: {
              value: /^\d{4}-\d{6}$/i,
              message: formattedPhoneNumberValidation(),
            },
          })}
          onChange={(e) => phoneNumberFormatter(e, setValue, clearErrors)}
        />
      </div>

      {errors.phone && (
        <p className="text-sm text-danger-dark">{errors.phone.message}</p>
      )}

      <div className="mt-4 flex w-full">
        <Button
          title={setIsAdding ? "Save" : "Update"}
          btnClassName="px-2"
          testId="saveReferenceBtn"
          type="primary"
          size="sm"
          buttonType="submit"
          isLoading={isSubmitting}
        >
          {setIsAdding ? "Save" : "Update"}
        </Button>
        <Button
          title="Cancel"
          testId="cancelReferenceBtn"
          type="secondary"
          size="sm"
          btnClassName="px-2 ml-2"
          onClick={() => setIsEditState(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
Form.propTypes = {
  referenceDetail: PropTypes.shape({
    uuid: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    designation_uuid: PropTypes.number,
    designation_name: PropTypes.string,
    company_uuid: PropTypes.number,
    company_name: PropTypes.string,
    type: PropTypes.string,
  }),
  setIsEditState: PropTypes.func,
  setIsAdding: PropTypes.func,
};
export default Form;
