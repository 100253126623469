import { createSlice } from "@reduxjs/toolkit";

import {
  createContactDetailsAction,
  getContactDetailsction,
  updateContactDetailsAction,
} from "store/thunkActions/candidate/contactDetailsThunk";

export const getContactDetail = createSlice({
  name: "contactDetail",
  initialState: {
    contactDetails: [],
  },
  reducers: {},
  extraReducers: {
    [getContactDetailsction.fulfilled]: (state, action) => {
      state.contactDetails = action.payload;
    },
    [createContactDetailsAction.fulfilled]: (state, action) => {
      state.contactDetails.push(action.payload.data);
    },
    [updateContactDetailsAction.fulfilled]: (state, action) => {
      const index = state.contactDetails.findIndex(
        (contactDetail) => contactDetail.id == action.payload.data.id
      );
      state.contactDetails[index] = action.payload.data;
    },
  },
});

export default getContactDetail.reducer;
