export const CKEDITOR_CONFIG = {
  image: {
    styles: ["full", "alignLeft", "alignRight"],
    upload: { types: ["jpg", "jpeg", "png"] },
    insert: { integrations: ["url"] },
  },
  toolbar: [
    "heading",
    "|",
    "imageInsert",
    "bold",
    "italic",
    "strikethrough",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "|",
    "code",
  ],
  link: {
    defaultProtocol: "https://",
    addTargetToExternalLinks: true,
  },
  heading: {
    options: [
      { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
      {
        model: "heading",
        view: "h3",
        title: "Heading",
        class: "ck-heading_heading1",
      },
    ],
  },
  shouldNotGroupWhenFull: true,
  language: "en",
};
