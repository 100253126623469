import PropTypes from "prop-types";

const CandidateSkills = ({ candidateSkills }) => {
  return (
    <>
      <div className="resume-section-heading">SKILLS</div>
      <div
        className="resume-section-content"
        style={{
          display: "flex",
          flexWrap: "wrap",
          color: "rgb(100 100 100)",
          textAlign: "justify",
          wordBreak: "break-all",
          "@media (minWidth: 992px)": { gap: "0.5rem" },
        }}
      >
        {candidateSkills.map((skill, index) => {
          return (
            <div key={skill.id}>
              {skill.skill.name}
              {index < candidateSkills.length - 1 ? `,\u00A0` : "."}
            </div>
          );
        })}
      </div>
      <hr className="h-0.5 bg-gray-dark lg:my-4 my-2" />
    </>
  );
};

CandidateSkills.propTypes = {
  candidateSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

export default CandidateSkills;
