import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import {
  evaluateCodingAnswer,
  updateCodingAnswer,
} from "api/candidate/examApi";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import codeEditorLanguage from "helpers/codeEditorLanguage";
import codeEditorText from "helpers/codeEditorText";

import CodeOutput from "./CodeOutput";
import CodingAnswer from "./CodingAnswer";
import Option from "./Option";
import QuestionFooter from "./QuestionFooter";
import Timer from "./Timer";

const Question = ({
  question,
  currentQuestionIndex,
  fetchNextQuestion,
  onNextButtonClick,
  onResetButtonClick,
  onOptionChange,
  selectedOption,
  totalQuestions,
  isCodingQuestion,
  customInputChecked,
  customInput,
  setCustomInputChecked,
  setCustomInput,
  isSaving,
  setIsSaving,
}) => {
  const scrollToRef = useRef();
  const scrollCodeOutputToRef = useRef();
  const questionToRef = useRef();
  const selectedLanguage = useRef(codeEditorLanguage(question));

  const [codeText, setCodeText] = useState(codeEditorText(question));
  const [compilationOutput, setCompilationOutput] = useState(undefined);
  const [isCompiling, setIsCompiling] = useState(false);

  const saveButtonText = () => {
    return isSaving ? "Saving..." : " Save & Next";
  };

  const compileButton = () => {
    return isCompiling ? "Compiling..." : "Compile & Run";
  };

  const onChangeCodeLanguage = (data) => {
    const answerData = {
      code_string: data.code_string,
      candidate_answer_id: question.candidate_answer_id,
      coding_language_id: data.coding_language_id,
    };
    updateCodingAnswer(answerData).then(() => {
      selectedLanguage.current = data;
      setCodeText(data.code_string);

      setCompilationOutput(undefined);
      setCustomInput("");
      setCustomInputChecked(false);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callCodeChangeApi = useCallback(
    debounce((data) => {
      const answerData = {
        code_string: data,
        candidate_answer_id: question.candidate_answer_id,
        coding_language_id: selectedLanguage.current.coding_language_id,
      };
      updateCodingAnswer(answerData);
    }, 5000),
    []
  );

  const onChangeCodeText = (data) => {
    setCodeText(data);
    callCodeChangeApi(data);
  };

  const onCompileButtonClick = () => {
    setIsCompiling(true);
    const answerData = {
      code_string: codeText,
      candidate_answer_id: question.candidate_answer_id,
      coding_language_id: selectedLanguage.current.coding_language_id,
    };
    return updateCodingAnswer(answerData).then(() => {
      const evaluateAnswer = {
        candidate_answer_id: question.candidate_answer_id,
        input: customInput.length > 0 ? customInput : "",
      };
      return evaluateCodingAnswer(evaluateAnswer)
        .then((response) => {
          setIsCompiling(false);
          setCompilationOutput(response.data);
          if (compilationOutput) {
            window.scrollTo({
              top: scrollCodeOutputToRef.current.offsetTop,
            });
          }
        })
        .catch((err) => {
          setIsCompiling(false);
          setCompilationOutput(err);
        });
    });
  };

  const onCustomInputClick = () => {
    setCustomInputChecked((checked) => !checked);
    setCustomInput("");
    if (customInputChecked === true) {
      window.scrollTo({
        top: scrollToRef.current.offsetTop,
      });
    }
  };

  useEffect(() => {
    setCodeText(codeEditorText(question));
    selectedLanguage.current = codeEditorLanguage(question);
    setIsCompiling(false);
    setIsSaving(false);
  }, [question.id]);

  useEffect(() => {
    window.scrollTo({
      top: questionToRef.current.offsetTop,
    });
  }, [question.id]);

  useEffect(() => {
    if (customInputChecked === true) {
      window.scrollTo({
        top: scrollToRef.current.offsetTop,
      });
    }
  }, [customInputChecked]);

  useEffect(() => {
    if (compilationOutput) {
      window.scrollTo({
        top: scrollCodeOutputToRef.current.offsetTop,
      });
    }
  }, [compilationOutput]);

  return (
    <div className="text-roboto w-screen select-none md:w-3/4">
      <div className="question-body" ref={questionToRef}>
        <div className="pb-4">
          <div className="top-18 sticky z-10 flex justify-between border-t-2 bg-gray-lighterShade3 px-4 pb-2 pt-4 text-sm shadow-lg">
            <p className="text-danger-main xs:text-lg">
              Question Time Left:&nbsp;
              <Timer
                question={question}
                onTimeOver={fetchNextQuestion}
                setIsSaving={setIsSaving}
              />
            </p>
            <p className="font-medium text-primary-main xs:text-lg">
              Marks: {question.marks}
            </p>
          </div>

          <p className="my-3 mt-8 px-4 text-xl font-semibold">
            <span>Question {currentQuestionIndex}</span>
          </p>
          <div className="break-words p-3 px-4 text-justify">
            <RenderParsedHTML content={question.text || ""} />
          </div>

          <div className="mt-2 flex flex-row flex-wrap space-x-2 px-4">
            {question.images?.map((element) => {
              return (
                <img
                  key={element.id}
                  src={element.attachment}
                  alt="Question"
                  className="h-auto w-auto object-contain"
                />
              );
            })}
          </div>
          <hr className="mt-4 mb-2" />

          {isCodingQuestion ? (
            <CodingAnswer
              setCodeText={setCodeText}
              codeText={codeText}
              selectedLanguage={selectedLanguage.current}
              onChangeCodeText={onChangeCodeText}
              codeLanguagesList={
                question.driver_codes ? question.driver_codes : []
              }
              onChangeCodeLanguage={onChangeCodeLanguage}
            />
          ) : (
            <>
              <p className="px-4 text-xl font-semibold">Options</p>
              <Option
                options={question.answers}
                selectedOption={selectedOption}
                onOptionChange={onOptionChange}
              />
            </>
          )}
        </div>

        {customInputChecked && (
          <div
            className="flex flex-col items-start py-3 px-5"
            ref={scrollToRef}
          >
            <label htmlFor="test-input">
              <span className="my-4">Input</span>
            </label>
            <textarea
              id="test-input"
              className="w-full rounded-md border border-gray-light px-3 py-1.5 text-base"
              onChange={(e) => setCustomInput(e.target.value)}
              value={customInput}
            />
          </div>
        )}
        {compilationOutput && (
          <div ref={scrollCodeOutputToRef}>
            <CodeOutput compilationOutput={compilationOutput} />
          </div>
        )}
      </div>
      <QuestionFooter
        onNextButtonClick={onNextButtonClick}
        onResetButtonClick={onResetButtonClick}
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={totalQuestions}
        isCodingQuestion={isCodingQuestion}
        customInputChecked={customInputChecked}
        setCustomInputChecked={onCustomInputClick}
        onCompileButtonClick={onCompileButtonClick}
        isCompiling={isCompiling}
        compileButton={compileButton}
        saveButtonText={saveButtonText}
        isSaving={isSaving}
      />
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    marks: PropTypes.any,
    text: PropTypes.string,
    attachmentContentType: PropTypes.string,
    attachmentFileName: PropTypes.string,
    attachment: PropTypes.string,
    secondsLeft: PropTypes.number,
    candidate_answer_id: PropTypes.number,
    coding_answer: PropTypes.shape({
      code_string: PropTypes.string,
      coding_language_id: PropTypes.number,
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        attachment: PropTypes.string,
      })
    ),
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      })
    ),
    driver_codes: PropTypes.arrayOf(
      PropTypes.shape({
        code_string: PropTypes.string,
        coding_language: PropTypes.string,
        file_extension: PropTypes.string,
      })
    ),
  }),
  fetchNextQuestion: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  onResetButtonClick: PropTypes.func,
  onOptionChange: PropTypes.func,
  selectedOption: PropTypes.number,
  currentQuestionIndex: PropTypes.number,
  totalQuestions: PropTypes.number,
  isCodingQuestion: PropTypes.bool,
  customInputChecked: PropTypes.bool,
  setCustomInputChecked: PropTypes.func,
  customInput: PropTypes.string,
  setCustomInput: PropTypes.func,
  isSaving: PropTypes.bool,
  setIsSaving: PropTypes.func,
};

export default Question;
