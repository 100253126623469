import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getReferenceDetailsAction } from "store/thunkActions/candidate/referenceDetailsThunk";

import CardContainer from "../Common/CardContainer";

import Form from "./Form";
import Reference from "./Reference";

const References = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { referenceDetails } = useSelector((state) => state.referenceDetails);

  useEffect(() => {
    dispatch(getReferenceDetailsAction());
  }, []);

  return (
    <CardContainer
      title="Reference(s)"
      addEventButtonTitle="Add Reference"
      addEventButtonTestId="add-reference-btn"
      setIsAdding={setIsAdding}
    >
      {!referenceDetails.length && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on the plus icon to add reference
        </span>
      )}
      {referenceDetails.map((referenceDetail) => {
        return (
          <div
            key={referenceDetail.uuid}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <Reference referenceDetail={referenceDetail} />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsEditState={setIsAdding} setIsAdding={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default References;
