import { useSelector } from "react-redux";

const useCheckUserRole = (allowedRoles) => {
  const [currentUser] = useSelector(({ auth }) => [auth.currentUser]);

  let roleCount = 0;
  currentUser?.data?.roles?.map((value) => {
    if (allowedRoles?.includes(value)) {
      roleCount += 1;
    }
  });
  return roleCount > 0;
};

export default useCheckUserRole;
