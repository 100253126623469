import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import PropTypes from "prop-types";

import { SORT_ORDER } from "app/constants";

const CommonSortArrowIcon = ({ sortOrder, sortBy, sortByText }) => {
  if (sortOrder === SORT_ORDER.ASC && sortBy === sortByText) {
    return <AiOutlineArrowUp />;
  }
  if (sortOrder === SORT_ORDER.DESC && sortBy === sortByText) {
    return <AiOutlineArrowDown />;
  }
  return (
    <span className="opacity-0 transition-opacity duration-300 group-hover:opacity-60">
      <AiOutlineArrowUp />
    </span>
  );
};

const AppliedSortArrowIcon = ({ sortOrder, sortBy, sortByText }) => {
  if (
    (sortOrder === SORT_ORDER.ASC && sortBy === sortByText) ||
    sortOrder === ""
  ) {
    return <AiOutlineArrowUp />;
  }
  if (sortOrder === SORT_ORDER.DESC && sortBy === sortByText) {
    return <AiOutlineArrowDown />;
  }
  return (
    <span className="opacity-0 transition-opacity duration-300 group-hover:opacity-60">
      <AiOutlineArrowUp />
    </span>
  );
};

CommonSortArrowIcon.propTypes = {
  sortOrder: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortByText: PropTypes.string.isRequired,
};

AppliedSortArrowIcon.propTypes = {
  sortOrder: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortByText: PropTypes.string.isRequired,
};

export { AppliedSortArrowIcon, CommonSortArrowIcon };
