import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { format, isBefore, parseISO } from "date-fns";
import PropTypes from "prop-types";

import { updateCandidateForGroups } from "api/erp/jobApi";
import { ALERT_TYPE } from "app/constants";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";

import InterviewFeedback from "../../InterviewFeedback";

const FormattedTime = ({ dateArg }) =>
  dateArg && (
    <>
      <span className="mb-1">{format(new Date(dateArg), "dd/MM/yyyy")}, </span>
      <span>{format(new Date(dateArg), "h:mm a")}</span>
    </>
  );

const GDCandidates = ({
  candidate,
  currentStage,
  isUserRoleAllowed,
  isJobClosed,
  groups,
  getGDDetails,
  getCandidateStageData,
}) => {
  const dropdownMenuRef = useRef(null);
  const {
    control,
    formState: { errors },
  } = useForm();
  const [showDropdown, setShowDropdown] = useState(false);
  const showAlert = useAlert();

  const submitHandler = (selectedGroup) => {
    updateCandidateForGroups(
      candidate.job_uuid,
      currentStage.id,
      selectedGroup.uuid,
      candidate.id
    )
      .then((response) => {
        getGDDetails();
        getCandidateStageData();
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );
      })
      .catch((errors) => {
        showAlert("danger", errors.meta.message);
      });
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (showDropdown && !dropdownMenuRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);

    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [showDropdown]);

  return (
    <>
      <td className="py-4 pr-4 pl-6 align-top text-gray-darker z-10">
        {isUserRoleAllowed && !isJobClosed && (
          <div className="mr-4 order-2">
            <Controller
              name="groups"
              control={control}
              type="submit"
              render={({ field }) => (
                <Select
                  name="status"
                  options={groups}
                  id="group_select"
                  className="w-56"
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isDisabled={
                    isJobClosed ||
                    (candidate.group?.start_at &&
                      isBefore(parseISO(candidate.group.start_at), new Date()))
                  }
                  isOptionDisabled={(option) => {
                    return (
                      option.start_at &&
                      isBefore(parseISO(option.start_at), new Date())
                    );
                  }}
                  value={
                    groups.find(
                      (group) =>
                        candidate.group && group.uuid === candidate.group.uuid
                    ) || { uuid: null, title: "Select group" }
                  }
                  placeholder="Select Group"
                  styles={{
                    ...reactSelectStyle(errors.groups),
                    control: (styles) => ({
                      ...styles,
                      borderColor: "ActiveBorder",
                      borderRadius: "15px",
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      color: "",
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 12 }),
                  }}
                  onChange={(data) => {
                    field.onChange(data);
                    submitHandler(data);
                  }}
                  getOptionLabel={(option) =>
                    option.title || `Group ${option.serialNo} (Unallocated)`
                  }
                  getOptionValue={(option) => option.uuid}
                />
              )}
            />
          </div>
        )}
      </td>

      <td className="p-4 align-top text-gray-darker">
        {candidate?.group && candidate?.group.start_at && (
          <FormattedTime dateArg={candidate?.group?.start_at} />
        )}
      </td>

      <td className="p-4 align-top text-gray-darker">
        {candidate.group &&
          candidate.group.group_moderators.map(({ feedback, moderator }) => (
            <span key={moderator.id} className="mb-2 block leading-5">
              {moderator.name || moderator.email}
              {feedback && `: ${feedback.marks}/5`}
            </span>
          ))}
        {candidate.group &&
          candidate?.group.group_moderators?.some(
            (moderator) => moderator.feedback
          ) && (
            <InterviewFeedback
              group={candidate.group}
              candidateName={candidate.name}
            />
          )}
      </td>
    </>
  );
};

GDCandidates.defaultProps = {
  candidate: {
    id: 0,
    candidate_id: 0,
    job_uuid: "",
    name: "",
    group: {
      uuid: "",
      link: "",
      mode: "",
      start_at: "",
      duration: 0,
      group_candidates: [],
      group_moderators: [],
    },
  },
  currentStage: {
    id: 0,
    name: "",
    sequence: 0,
    stage_type: "",
  },
  groups: [],
  getGDDetails: () => {},
  getCandidateStageData: () => {},
  isUserRoleAllowed: false,
  isJobClosed: false,
};

GDCandidates.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    candidate_id: PropTypes.number,
    job_uuid: PropTypes.string,
    name: PropTypes.string,
    group: PropTypes.shape({
      link: PropTypes.string,
      uuid: PropTypes.string,
      start_at: PropTypes.string,
      duration: PropTypes.number,
      group_moderators: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          email: PropTypes.string,
        })
      ),
    }),
  }),
  currentStage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sequence: PropTypes.number,
    stage_type: PropTypes.string,
  }),

  isUserRoleAllowed: PropTypes.bool,
  isJobClosed: PropTypes.bool,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      uuid: PropTypes.string,
      start_at: PropTypes.string,
      duration: PropTypes.number,
      group_candidates: PropTypes.shape({
        uuid: PropTypes.string,
        candidate_job_id: PropTypes.number,
      }),
      group_moderators: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  getGDDetails: PropTypes.func,
  getCandidateStageData: PropTypes.func,
};

export default GDCandidates;
