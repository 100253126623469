import { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { format, formatDistanceToNow } from "date-fns";
import { startCase } from "lodash";
import PropTypes from "prop-types";

import {
  checkExamAppearance,
  createExamCandidate,
} from "api/candidate/examApi";
import { ALERT_TYPE, QUESTION_TYPE } from "app/constants";
import { Button } from "components/atoms";
import Pill from "components/atoms/Pill";
import RefImageButton from "components/common/RefImageButton";
import StatusPill from "components/common/StatusPill";
import Modal from "components/molecules/Modal";
import {
  convertSecondsToTime,
  REF_IMAGE_RESHOOT_MESSAGE,
  UPLOAD_REF_IMAGE_MODAL_MESSAGE,
} from "constants/common";
import useAlert from "hooks/useAlert";

const ExamRow = ({ exam, status, currentUser, showType }) => {
  const showAlert = useAlert();

  const [isRefModal, setIsRefModal] = useState(false);

  const handleStartExam = () => {
    checkExamAppearance(exam.id).then(({ data, meta }) => {
      if (!data.appeared) {
        localStorage.setItem("exam", JSON.stringify(exam));
        window.open(`/instructions?id=${exam.id}`, "_blank", "noopener");
      } else if (data.appeared) {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        setTimeout(() => window.location.reload(), 10000);
      }
    });
  };

  const onResume = () => {
    checkExamAppearance(exam.id).then(({ data, meta }) => {
      if (!data.appeared) {
        createExamCandidate(exam.id).then((response) => {
          window.open(`/question_paper?id=${response.data.id}`, "_blank");
        });
      } else if (data.appeared) {
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        setTimeout(() => window.location.reload(), 10000);
      }
    });
  };
  const RefAlert = () => {
    if (
      exam.camera_option === "REQUIRED" &&
      (currentUser?.reference_images.length === 0 ||
        currentUser?.reference_images[0]?.face_embedding_absent)
    ) {
      setIsRefModal(true);
    } else {
      setIsRefModal(false);
      handleStartExam();
    }
  };

  const formattedStartDate = format(new Date(exam.start_at), "dd/MM/yyyy");
  const formattedEndDate = format(new Date(exam.end_at), "dd/MM/yyyy");

  return (
    <div
      className="min-h-[150px] py-3 px-2 font-sans"
      data-testid={`exam-id-${exam.id}`}
    >
      <div className="flex items-start">
        <span className="pr-6 text-lg font-bold text-black-shade1" id="name">
          {exam.name}
        </span>

        <div className="flex gap-4">
          {showType && <Pill text={startCase(exam.type)} type="info" />}

          <Pill
            text={
              exam.exam_type === QUESTION_TYPE[0].name
                ? "MCQ"
                : startCase(exam.exam_type)
            }
            type="info"
          />
        </div>
      </div>

      <div className="mt-2 mb-2 text-[14px] font-medium text-gray-lightShade1">
        <Link
          className="cursor-pointer hover:underline"
          to={`/jobs/${exam.job_uuid}/details`}
          title="View Job Details"
        >
          <p id="job-title" className="inline">
            {exam.job_title}
          </p>
          <br />
          <p className="inline">
            {exam.organisation_name} {exam.job_location ? "|" : ""}&nbsp;
            {exam.job_location}
          </p>
        </Link>
      </div>

      <div className={classNames("flex mb-2 justify-between")}>
        <div className="mr-2 text-[12px] text-gray-lightShade1">
          <p className="text-black-primary">
            {formattedStartDate} - {formattedEndDate}
          </p>
          <p className="whitespace-nowrap">
            Duration:
            {(exam.duration && convertSecondsToTime(exam.duration)) || ""}
          </p>{" "}
        </div>

        {status === "ongoing" &&
          (exam.has_started ? (
            <Button
              onClick={onResume}
              type="warning"
              size="sm"
              customWidth={28}
              btnClassName="btn-warning h-fit self-end"
              testId="exam-resume-btn"
              title="Resume"
            >
              Resume
            </Button>
          ) : (
            <Button
              onClick={RefAlert}
              type="success"
              size="sm"
              customWidth={28}
              btnClassName="h-fit self-end"
              testId="exam-start-btn"
              title="Start"
            >
              Start
            </Button>
          ))}

        {status === "upcoming" && (
          <span className="self-end text-primary-main text-[15px]">
            {formatDistanceToNow(new Date(exam.start_at), {
              addSuffix: true,
            })}
          </span>
        )}
        {status === "past" && exam?.candidate_status !== null && (
          <StatusPill label={exam?.candidate_status} />
        )}
      </div>
      {isRefModal &&
        (!currentUser.reference_images[0] ||
          currentUser?.reference_images[0]?.face_embedding_absent) && (
          <Modal
            title="Upload Reference Image"
            customButtonWidth={44}
            handleCancel={() => setIsRefModal(false)}
          >
            <div className="mb-2 p-2">
              {!currentUser.reference_images[0]
                ? UPLOAD_REF_IMAGE_MODAL_MESSAGE
                : REF_IMAGE_RESHOOT_MESSAGE}
            </div>
            <div className=" sm:flex sm:flex-row-reverse ">
              <RefImageButton currentUser={currentUser} insideRefModal />
            </div>
          </Modal>
        )}
    </div>
  );
};

ExamRow.defaultProps = {
  showType: false,
};

ExamRow.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming", "past"]),
  exam: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    exam_type: PropTypes.oneOf(["coding", "mcq", "code"]),
    start_at: PropTypes.string,
    end_at: PropTypes.string,
    duration: PropTypes.number,
    job_uuid: PropTypes.string,
    job_title: PropTypes.string,
    organisation_name: PropTypes.string,
    job_location: PropTypes.string,
    has_started: PropTypes.bool,
    job_page_number: PropTypes.number,
    camera_option: PropTypes.string,
    candidate_status: PropTypes.string,
    type: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    portfolio_url: PropTypes.string,
    about: PropTypes.string,
    date_of_birth: PropTypes.string,
    is_profile_completed: PropTypes.bool,
    photo_url: PropTypes.string,
    reference_images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        file_name: PropTypes.string,
        attachment: PropTypes.string,
        face_embedding_absent: PropTypes.bool,
      })
    ),
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
      original: PropTypes.string,
    }),
  }),
  showType: PropTypes.bool,
};

export default ExamRow;
