export const getStatusSelectDisabled = (candidate, searchParams) => {
  if (
    candidate?.status === "PLACED" ||
    (candidate?.placed?.stage_id &&
      candidate?.placed?.stage_id === searchParams.get("stageId"))
  ) {
    return false;
  }
  if (
    !candidate?.placed?.stage_id &&
    (!candidate?.placed?.stage_name ||
      (candidate?.placed?.stage_name === "All Applicants" &&
        searchParams.get("lobby") === "ALL_APPLICANTS"))
  ) {
    return false;
  }
  return true;
};
