import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import {
  FaCheckCircle,
  FaEnvelope,
  FaInfoCircle,
  FaUserAlt,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { startCase, toLower } from "lodash";
import PropTypes from "prop-types";

import api from "api/axios";
import {
  ALERT_TYPE,
  EMAIL_REGEX,
  RECAPTCHA_KEY,
  SESSION_EXPIRED_MESSAGE,
} from "app/constants";
import { Button } from "components/atoms";
import {
  RECAPTCHA_LOGIN_FAILED_MESSAGE,
  RECAPTCHA_SIGNUP_FAILED_MESSAGE,
} from "constants/common";
import { requiredValidation } from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";

const SigninForm = ({ isSignup }) => {
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const location = useLocation();
  const showAlert = useAlert();
  const recaptchaRef = useRef();
  const [token, setToken] = useState(false);
  const fullName = watch("fullName");
  const email = watch("email");

  useEffect(() => {
    clearErrors();
    setIsSubmitted(false);
    if (RECAPTCHA_KEY) {
      recaptchaRef?.current.reset();
    }
  }, [isSignup]);

  const { errorMessage } = useSelector((state) => state.apiErrors);

  useEffect(() => {
    if (errorMessage?.includes(SESSION_EXPIRED_MESSAGE)) {
      showAlert("info", errorMessage);
    } else if (location.search.includes("redirect_url")) {
      showAlert(ALERT_TYPE.INFO, "Please login/signup to proceed.");
    }
    if (location.search.includes("message")) {
      showAlert(
        ALERT_TYPE.INFO,
        new URLSearchParams(location.search).get("message")
      );
    }
  }, [
    new URLSearchParams(location.search).get("message"),
    new URLSearchParams(location.search).get("redirect_url"),
  ]);

  const onChange = () => {
    setToken(recaptchaRef.current.getValue());
  };

  const setErrorsOnFields = (errors) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (key === "name") {
        setError("fullName", { message: value[0] });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const submitHandler = (body) => {
    setIsSaving(true);
    const url = isSignup ? "/signup" : "/send_email";
    const data = {
      email: body.email,
      name: isSignup ? body.fullName : undefined,
      redirect_url: new URLSearchParams(location.search).get("redirect_url"),
      token,
    };
    return api
      .post(url, data)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch((error) => {
        setToken(null);
        setErrorsOnFields(error?.response?.data?.errors);
        recaptchaRef.current.reset();
      })
      .finally(() => setIsSaving(false));
  };

  const changeBtnText = () => {
    if (isSaving) {
      return "Processing...";
    }
    if (isSignup) {
      return "Sign Up";
    }
    return "Login";
  };

  return isSubmitted ? (
    <div className="min-h-13 mx-auto w-full pb-6">
      <div className="flex justify-center">
        <FaCheckCircle className="mt-3 text-6xl text-green-500" />
      </div>

      <p className="mt-5 text-center text-gray-dark">
        {isSignup ? "Sign up" : "Login"} successful. <br />
        An email containing the password-less login has been sent to{" "}
        <span className="break-words font-semibold">{getValues("email")}</span>
        .
        <br />
        Please follow the instructions on the mail to continue.
      </p>
    </div>
  ) : (
    <form
      className="min-h-13 mx-auto w-full pt-3 pb-6 lg:pt-0"
      onSubmit={handleSubmit(submitHandler)}
    >
      <p className="mb-3 text-center text-sm text-gray-dark">
        We will send a link to your email for password less{" "}
        {isSignup ? "sign up" : "login"}.
      </p>

      {isSignup && (
        <div className="my-3">
          <div
            className={`flex h-10 items-center rounded-sm border-2 border-gray-lighter pl-2 text-gray-main focus-within:border-primary-main focus-within:text-gray-dark hover:border-primary-main hover:text-gray-dark ${
              errors.fullName &&
              `border-danger-light bg-danger-lighter focus-within:border-danger-light hover:border-danger-light`
            }`}
          >
            <FaUserAlt className="mr-4" />
            <input
              name="fullName"
              className={`h-full w-full capitalize outline-none ${
                errors.fullName && `bg-danger-lighter`
              }`}
              placeholder="Full Name"
              {...register("fullName", {
                required: requiredValidation(),
                setValueAs: (v) => startCase(toLower(v)),
                onBlur: (e) =>
                  setValue("fullName", startCase(toLower(e.target.value))),
              })}
            />
          </div>
          {errors.fullName && (
            <p className="mt-0 text-sm text-danger-dark">
              {errors.fullName.message}
            </p>
          )}
        </div>
      )}

      <div className="my-3">
        <div
          className={`flex h-10 items-center rounded-sm border-2 border-gray-lighter pl-2 text-gray-main focus-within:border-primary-main focus-within:text-gray-dark hover:border-primary-main hover:text-gray-dark ${
            errors.email &&
            `border-danger-light bg-danger-lighter focus-within:border-danger-light hover:border-danger-light`
          }`}
        >
          <FaEnvelope className="mr-4" />
          <input
            name="email"
            className={`h-full w-full outline-none ${
              errors.email && `bg-danger-lighter`
            }`}
            disabled={!RECAPTCHA_KEY}
            placeholder="Email"
            {...register("email", {
              required: requiredValidation(),
              pattern: { value: EMAIL_REGEX, message: "invalid email" },
            })}
          />
        </div>
        {errors.email && (
          <p className="text-sm text-danger-dark">
            {toLower(errors.email.message)}
          </p>
        )}
        {RECAPTCHA_KEY ? (
          <div className="mt-3 flex flex-col items-center">
            <div className="flex-start">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                size="normal"
                onChange={onChange}
              />
              {errors.token && (
                <p className="text-sm text-danger-dark">
                  {errors.token.message}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="flex text-danger-main  gap-1 mt-6 justify-start">
            <div className="mt-0.5">
              <FaInfoCircle className="text-danger-main" />
            </div>
            <span className="text-xs">
              {isSignup
                ? RECAPTCHA_SIGNUP_FAILED_MESSAGE
                : RECAPTCHA_LOGIN_FAILED_MESSAGE}
            </span>
          </div>
        )}
      </div>

      <Button
        title={changeBtnText()}
        btnClassName="w-full h-auto py-2 font-semibold text-sm"
        type={
          email && token && (!isSignup || (isSignup && fullName)) && !isSaving
            ? "primary"
            : "disabled"
        }
        testId="app-login-signup"
      >
        {changeBtnText()}
      </Button>

      <p className="mt-3 h-auto w-full rounded-full border border-primary-main py-2 text-center text-sm text-gray-dark">
        {`${isSignup ? `Already` : `Don't`} have an account?`}
        <Link
          className="ml-1 text-secondary-main hover:text-secondary-dark hover:underline"
          to={
            isSignup
              ? `${location.search ? `/login${location.search}` : "/login"}`
              : `${location.search ? `/signup${location.search}` : "/signup"}`
          }
          title={isSignup ? "Login" : "Sign Up"}
        >
          <span className="font-bold">{isSignup ? "Login" : "Sign Up"}</span>
        </Link>
      </p>
    </form>
  );
};

SigninForm.propTypes = {
  isSignup: PropTypes.bool,
};

export default SigninForm;
