import classNames from "classnames";
import PropTypes from "prop-types";

import Reference from "./Reference";

const References = ({ referenceDetails }) => {
  return (
    <div
      className={classNames(
        "mx-auto w-full bg-white mt-4 rounded-lg border border-gray-light p-4"
      )}
    >
      <h3 className={classNames("font-normal text-2xl")}>Reference(s)</h3>

      {!referenceDetails?.length && (
        <span className="mt-4 block text-sm italic text-gray-main">
          No data found
        </span>
      )}

      {referenceDetails?.map((referenceDetail) => {
        return (
          <div
            key={referenceDetail.uuid}
            className="border-b border-gray-dark py-4 last:border-b-0"
          >
            <Reference referenceDetail={referenceDetail} />
          </div>
        );
      })}
    </div>
  );
};

References.propTypes = {
  referenceDetails: PropTypes.array,
};

export default References;
