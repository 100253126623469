import { useEffect, useRef, useState } from "react";
import { bool, func, node } from "prop-types";

import ActivityIndicator from "../ActivityIndicator";

/** @function loadMoreHandler, maintain single instance for the loadMore callback  */

const InfiniteScrollWrapper = ({ children, hasMore, loadMoreHandler }) => {
  const lastItemRef = useRef();
  const observerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((en) => {
        if (en.intersectionRatio > 0 && !isLoading) {
          setIsLoading(true);
          loadMoreHandler()
            .then(() => setIsLoading(false))
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    });

    if (lastItemRef.current && !isLoading) {
      observerRef.current.observe(lastItemRef.current);
    }

    return () => {
      if (lastItemRef.current) {
        observerRef.current.unobserve(lastItemRef.current);
      }
    };
  }, [lastItemRef.current, loadMoreHandler, isLoading]);

  return (
    <>
      {children}
      {hasMore ? <ActivityIndicator ref={lastItemRef} /> : null}
    </>
  );
};

InfiniteScrollWrapper.propTypes = {
  children: node.isRequired,
  hasMore: bool.isRequired,
  loadMoreHandler: func.isRequired,
};

export default InfiniteScrollWrapper;
