import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCandidateSkillsAction } from "store/thunkActions/candidate/skillThunk";

import CardContainer from "../Common/CardContainer";

import Form from "./Form";
import Skill from "./Skill";

const Skills = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { candidateSkills } = useSelector((state) => state.candidateSkills);
  useEffect(() => {
    dispatch(getCandidateSkillsAction());
  }, []);

  return (
    <CardContainer
      title="Skills"
      addEventButtonTitle="Add Skill"
      addEventButtonTestId="add-skill-btn"
      setIsAdding={() => setIsAdding(true)}
    >
      {!candidateSkills.length && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on the plus icon to add skills
        </span>
      )}

      {candidateSkills.map((candidateSkill) => {
        return (
          <div
            key={candidateSkill.id}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <Skill candidateSkill={candidateSkill} />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsEditState={setIsAdding} setIsAdding={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default Skills;
