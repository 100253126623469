import { createSlice } from "@reduxjs/toolkit";

export const miscellaneousSlice = createSlice({
  name: "miscellaneous",
  initialState: {
    headerTitle: "",
    selectedIds: [],
    selectedObjectType: "",
    allSelected: false,
  },
  reducers: {
    updateHeaderTitle: (state, { payload }) => ({
      ...state,
      headerTitle: payload.breadCrumbDetails,
    }),

    addSelectedIds: (state, { payload }) => {
      if (state.selectedObjectType !== payload.objectType) {
        state.selectedObjectType = payload.objectType;
      }

      state.selectedIds = [...state.selectedIds, ...payload.objectIds];
      state.allSelected = false;
    },
    removeSelectedIds: (state, { payload }) => {
      state.selectedIds = state.selectedIds.filter(
        (sid) => !payload.objectIds.includes(sid)
      );

      if (state.selectedIds.length === 0) {
        state.selectedObjectType = "";
      }

      state.allSelected = false;
    },
    selectAllOfType: (state, { payload }) => {
      state.allSelected = true;
      state.selectedIds = [];
      if (state.selectedObjectType !== payload.objectType) {
        state.selectedObjectType = payload.objectType;
      }
    },
    resetSelection: (state) => {
      state.selectedIds = [];
      state.selectedObjectType = "";
      state.allSelected = false;
    },
  },
});

export const {
  updateHeaderTitle,
  addSelectedIds,
  removeSelectedIds,
  selectAllOfType,
  resetSelection,
} = miscellaneousSlice.actions;

export default miscellaneousSlice.reducer;
