import { createSlice } from "@reduxjs/toolkit";

import { getStatesAction } from "store/thunkActions/candidate/locationDetailsThunk";

export const getLocationDetail = createSlice({
  name: "locationDetail",
  initialState: {
    allStates: [],
  },
  reducers: {},
  extraReducers: {
    [getStatesAction.fulfilled]: (state, action) => {
      state.allStates = action.payload;
    },
  },
});

export default getLocationDetail.reducer;
