import { AiOutlineDoubleRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Interview from "../Interviews/Interview";

const InterviewCard = ({ status, interviews, hasMoreInterviews }) => {
  if (!interviews?.length) {
    return null;
  }

  return (
    <>
      <h2 className="py-2 text-xl font-semibold capitalize">
        {status} Interviews
      </h2>
      <div
        className={`  relative bg-white px-4 py-3 text-sm  ${
          status === "upcoming" ? "" : "rounded-2xl border "
        } `}
      >
        <div>
          {interviews?.map((interview) => (
            <div
              className={
                interviews.indexOf(interview) != 0 &&
                "border-t-2 border-solid border-gray-lighterShade2"
              }
              key={interview.id}
            >
              <Interview interview={interview} status={status} />
            </div>
          ))}
        </div>
        {status === "upcoming" && hasMoreInterviews && (
          <Link
            to="/interviews?tab=upcoming"
            className="absolute text-md right-1.5 bottom-1.5 flex text-primary-light"
            title="View all"
          >
            View all <AiOutlineDoubleRight className="mt-1" />
          </Link>
        )}
      </div>
    </>
  );
};

InterviewCard.propTypes = {
  status: PropTypes.oneOf(["ongoing", "upcoming"]),
  interviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      interview_type: PropTypes.oneOf(["coding", "mcq"]),
      start_at: PropTypes.string,
      end_at: PropTypes.string,
      duration: PropTypes.number,
      job_uuid: PropTypes.string,
      job_title: PropTypes.string,
      organisation_name: PropTypes.string,
      job_location: PropTypes.string,
      has_started: PropTypes.bool,
      link: PropTypes.string,
      job_page_number: PropTypes.number,
      candidate_status: PropTypes.string,
    })
  ),
  hasMoreInterviews: PropTypes.bool,
};
export default InterviewCard;
