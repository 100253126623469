import React, { useCallback, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FormattedTime } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import {
  deleteSlot,
  getExams,
  getScheduledSlots,
  getSlots,
} from "api/erp/organizationApi";
import { ALERT_TYPE } from "app/constants";
import { ActivityIndicator, Button } from "components/atoms";
import { Modal } from "components/molecules";
import { DEFAULT_PAGE_SIZE } from "constants/common";
import { formatSlots } from "helpers/pipeline/exam/multiSlot";
import useAlert from "hooks/useAlert";
import { getStageCandidatesAction } from "store/thunkActions/erp/candidateJobStageThunk";

const SlotViewModal = ({
  currentStageId,
  setViewSlot,
  onScheduleSlotClick,
}) => {
  const [isExpanded, setIsexpanded] = useState([]);
  const [slotsDetails, setSlotDetails] = useState();
  const [stageExamDetails, setStageExamDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteIds, setDeleteIds] = useState([]);
  const [totalCandidateLeft, setTotalCandidateLeft] = useState(0);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const params = useParams();
  const showAlert = useAlert();
  const dispatch = useDispatch();

  const fetchSlotDetails = useCallback(() => {
    if (stageExamDetails[0]) {
      getSlots(stageExamDetails[0]?.id).then((response) => {
        setTotalCandidateLeft(response.data.candidates_left);
      });
    }
  }, [stageExamDetails]);

  const fetchSlots = useCallback(() => {
    if (stageExamDetails[0]) {
      getScheduledSlots(
        params.uuid,
        currentStageId,
        stageExamDetails[0].id
      ).then((response) => {
        setSlotDetails(response.data);
        setIsLoading(false);
      });
    }
  }, [stageExamDetails, currentStageId, params.uuid]);

  useEffect(() => {
    fetchSlotDetails();
  }, [fetchSlotDetails]);

  useEffect(() => {
    getExams(params.uuid, currentStageId).then((response) => {
      setStageExamDetails(response.data);
    });
  }, [params.uuid, currentStageId]);

  useEffect(() => {
    fetchSlots();
  }, [fetchSlots]);

  const handleClickOnExpanding = (index) => {
    const arr = [...isExpanded];
    arr[index] = isExpanded[index] ? !isExpanded[index] : true;
    setIsexpanded(arr);
  };

  const onClickHandler = (e, id) => {
    if (e.target.checked) {
      setDeleteIds((prev) => [...prev, id]);
    } else {
      setDeleteIds((prev) => prev.filter((i) => i !== id));
    }
  };

  const title = (
    <div className="flex-col">
      <h3>Scheduled Slot(s)</h3>
      <p className="text-sm mt-1">Candidates Left: {totalCandidateLeft}</p>
    </div>
  );

  const handleDelete = () => {
    deleteSlot(params.uuid, currentStageId, stageExamDetails[0]?.id, deleteIds)
      .then((res) => {
        dispatch(
          getStageCandidatesAction({
            stages_id: currentStageId,
            page_size: DEFAULT_PAGE_SIZE,
            page: 1,
            job_uuid: params.uuid,
          })
        );
        showAlert(ALERT_TYPE[res.meta.message_type], res.meta.message);
      })
      .catch((error) => {
        showAlert(
          ALERT_TYPE[error.response.data.meta.message_type],
          error.response.data.meta.message
        );
      });
    setDeleteConfirmModal(false);
  };

  const slots = formatSlots(slotsDetails);

  if (isLoading) {
    return (
      <Modal
        title="Scheduled Slot(s)"
        testId="scheduled-slots-container"
        customModalWidth="720px"
        scrollClass="overflow-auto"
        handleCancel={() => setViewSlot(false)}
      >
        <div className="max-h-[600px] min-w-[400px]">
          <div className="my-8">
            <ActivityIndicator
              containerClass="mt-2"
              svgClassName="w-100%"
              size={12}
            />
          </div>
        </div>
      </Modal>
    );
  }

  if (isEmpty(slotsDetails)) {
    return (
      <Modal
        title={title}
        acceptButtonText="Schedule Slot(s)"
        customButtonWidth={40}
        handleCancel={() => setViewSlot(false)}
        handleAccept={() => {
          onScheduleSlotClick();
          setViewSlot(false);
        }}
        acceptBtnType={totalCandidateLeft > 0 ? "primary" : "disabled"}
      >
        There is no slot(s) for this exam.
      </Modal>
    );
  }

  return (
    <>
      {deleteConfirmModal && (
        <Modal
          title="Are you sure?"
          testId="delete-slot-container"
          customModalWidth="540px"
          scrollClass="overflow-auto"
          handleReject={() => setDeleteConfirmModal(false)}
          handleAccept={() => handleDelete()}
          acceptButtonText="Yes"
          rejectButtonText="No"
          acceptBtnType="primary"
          rejectBtnType="secondary"
        >
          Are you sure you want to delete the slot(s)?
        </Modal>
      )}
      <Modal
        title={title}
        testId="scheduled-slots-container"
        customModalWidth="720px"
        scrollClass="overflow-y-auto max-h-[70vh]"
        handleAccept={() => {
          onScheduleSlotClick();
          setViewSlot(false);
        }}
        handleReject={() => setDeleteConfirmModal(true)}
        handleCancel={() => setViewSlot(false)}
        acceptBtnType={totalCandidateLeft > 0 ? "primary" : "disabled"}
        rejectBtnType={deleteIds.length > 0 ? "secondary" : "disabled"}
        acceptButtonText="Schedule Slot(s)"
        rejectButtonText="Delete Slot(s)"
        customButtonWidth={40}
      >
        <div className="max-h-[600px] min-w-[400px]">
          {Object.keys(slots)
            .sort()
            .map((date, slotIndex) => {
              return (
                <div
                  className="mt-4 rounded-md border border-gray-light bg-white pl-6 pr-2 py-2 shadow-sm"
                  key={date}
                  data-testid="test-case-item"
                >
                  <div className="flex w-full flex-grow items-center justify-between gap-2 bg-white">
                    <span>{slotIndex + 1}.</span>
                    <div className="flex items-center gap-3">
                      <span>Date:</span>
                      <span className="font-medium">{date}</span>
                    </div>
                    <div className="flex items-center gap-3">
                      <span>Candidates Count:</span>
                      <span>
                        {Object.values(slots[date]).reduce(
                          (acc, val) => acc + val.count,
                          0
                        )}
                      </span>
                    </div>
                    <div>
                      <Button
                        title={isExpanded[slotIndex] ? "Collapse" : "Expand"}
                        onClick={() => {
                          handleClickOnExpanding(slotIndex);
                        }}
                        btnClassName="p-1 sm:p-2 mx-1 hover:bg-primary-lighter rounded-md"
                        testId={
                          isExpanded[slotIndex]
                            ? "collapse-button"
                            : "expand-button"
                        }
                      >
                        {isExpanded[slotIndex] ? (
                          <IoIosArrowUp className="text-2xl" />
                        ) : (
                          <IoIosArrowDown className="text-2xl" />
                        )}
                      </Button>
                    </div>
                  </div>
                  {isExpanded[slotIndex] &&
                    Object.keys(slots[date]).map((time) => (
                      <div
                        key={slots[date][time].id}
                        data-testid="slot-time-value"
                      >
                        <hr />
                        <div className="mt-2 flex justify-between mr-5">
                          <input
                            type="checkbox"
                            id="delete-checkbox"
                            data-testid="delete-checkbox"
                            onClick={(e) =>
                              onClickHandler(e, slots[date][time].id)
                            }
                            disabled={new Date() > new Date(time)}
                            checked={deleteIds.includes(slots[date][time].id)}
                          />
                          <div className="flex justify-between w-1/2 m-auto">
                            <span>
                              <FormattedTime value={time} />
                            </span>
                            <span className="ml-6">
                              {slots[date][time].count}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              );
            })}
        </div>
      </Modal>
    </>
  );
};

SlotViewModal.propTypes = {
  currentStageId: PropTypes.number.isRequired,
  setViewSlot: PropTypes.func.isRequired,
  onScheduleSlotClick: PropTypes.func.isRequired,
};

export default SlotViewModal;
