import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import { isBefore, toDate } from "date-fns";
import PropTypes from "prop-types";

import {
  createGroups,
  deleteSingleGroup,
  getStageCandidateOptions,
  updateGroupDetails,
} from "api/erp/jobApi";
import { getAllUsers } from "api/erp/organizationApi";
import { ALERT_TYPE } from "app/constants";
import { LabeledInput } from "components/atoms";
import { Modal } from "components/molecules";
import { DEFAULT_PAGE_SIZE } from "constants/common";
import { GD_MEETING_MODES } from "constants/groupDiscussion";
import {
  characterValidation,
  dateTimeValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";
import useAlert from "hooks/useAlert";

const ScheduleGDModal = ({
  currentStageId,
  selectedGroupDetails,
  setSelectedGroupDetails,
  getGDDetails,
  getCandidateStageData,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors, isDirty },
    setError,
  } = useForm();

  const isCreating = Object.keys(selectedGroupDetails).length === 0;
  const params = useParams();
  const showAlert = useAlert();
  const meetingMode = watch("mode")?.value;
  const [showDeleteGDConfirmModal, setShowDeleteGDConfirmModal] =
    useState(false);

  const onShowConfirmModal = () => {
    setShowDeleteGDConfirmModal(true);
  };

  const onDeleteGroup = () => {
    return deleteSingleGroup(
      params.uuid,
      currentStageId,
      selectedGroupDetails.uuid
    )
      .then((response) => {
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );
        getGDDetails();
        getCandidateStageData();
        setSelectedGroupDetails(null);
        setShowDeleteGDConfirmModal(false);
      })
      .catch(() => {
        showAlert(ALERT_TYPE.DANGER, "Something went wrong, Please try again.");
        setShowDeleteGDConfirmModal(false);
      });
  };

  const modalSideJobProps = {
    title: "Delete",
    testId: "delete-group",
    onClick: () => {
      onShowConfirmModal();
    },
    type: selectedGroupDetails.start_at ? "secondary-danger" : "disabled",
    btnName: "Delete",
    isAsyncClick: false,
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() <= selectedDate.getTime();
  };

  const loadAllUsersOptions = async (search, _, { page }) => {
    const { data, meta } = await getAllUsers(page, search, [
      "ADMIN",
      "INTERVIEWER_MODERATOR",
    ]);

    return {
      options: data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllStageCandidates = async (_search, _, { page }) => {
    const { data, meta } = await getStageCandidateOptions(
      params.uuid,
      currentStageId,
      page,
      DEFAULT_PAGE_SIZE
    );

    return {
      options:
        page === 1 && !isCreating
          ? [...selectedGroupDetails.group_candidates, ...data]
          : data,
      hasMore: meta.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const customSelect = {
    maxHeight: "80px",
    overflowY: "auto",
  };

  const inputErrClass = (error) => {
    return error ? "input-error" : "input";
  };

  const setErrorsOnFields = ({ errors }) => {
    Object.entries(errors).forEach(([key, value]) => {
      if (key === "candidates") {
        setError("group_candidates", { message: value[0] });
      } else if (key === "moderators") {
        setError("group_moderators", { message: value[0] });
      } else {
        setError(key, { message: value[0] });
      }
    });
  };

  const submitHandler = (data) => {
    data.mode = data.mode.value;

    if (data.mode === GD_MEETING_MODES[0].value) {
      data.link = null;
    } else {
      data.address = null;
    }

    data.candidate_job_ids = data.group_candidates.map(
      (candidate) => candidate.candidate_job_id || candidate.id
    );
    data.moderator_uuids = data.group_moderators.map(({ uuid }) => uuid);

    delete data.group_candidates;
    delete data.group_moderators;

    if (isCreating) {
      return createGroups(params.uuid, currentStageId, data)
        .then(({ meta }) => {
          showAlert(ALERT_TYPE[meta.message_type], meta.message);
          getGDDetails();
          getCandidateStageData();
          setSelectedGroupDetails(null);
        })
        .catch((errors) => {
          showAlert(ALERT_TYPE.DANGER, errors.response.data.message);
          setErrorsOnFields(errors.response.data);
        });
    }

    return updateGroupDetails(
      params.uuid,
      currentStageId,
      selectedGroupDetails.uuid,
      data
    )
      .then(({ meta }) => {
        getGDDetails();
        getCandidateStageData();
        showAlert(
          ALERT_TYPE[meta.message_type],
          selectedGroupDetails.start_at
            ? "GD updated successfully!"
            : meta.message
        );
        setSelectedGroupDetails(null);
      })
      .catch((errors) => {
        showAlert(ALERT_TYPE.DANGER, errors.response.data.message);
        setErrorsOnFields(errors.response.data);
      });
  };

  useEffect(() => {
    if (selectedGroupDetails) {
      reset({
        ...selectedGroupDetails,
        mode: GD_MEETING_MODES.find(
          (mmode) => mmode.value === selectedGroupDetails.mode
        ),
      });
    }
  }, [selectedGroupDetails]);

  const getModalTitle = () => {
    if (selectedGroupDetails.start_at) {
      return `Edit Group Discussion for Group ${selectedGroupDetails.serialNo}`;
    }
    if (isCreating) {
      return "Create & Schedule Group Discussion";
    }
    return `Schedule Group Discussion for Group ${selectedGroupDetails.serialNo}`;
  };

  return (
    <>
      <Modal
        title={getModalTitle()}
        customModalWidth="540px"
        acceptBtnType={!isDirty ? "disabled" : "primary"}
        acceptButtonText={selectedGroupDetails.start_at ? "Update" : "Create"}
        handleCancel={() => {
          setSelectedGroupDetails(null);
        }}
        handleAccept={handleSubmit((data) => submitHandler(data))}
        hasSideJobButton={!isCreating}
        sideJobButtonProps={modalSideJobProps}
        isDirty={isDirty}
        scrollClass="overflow-y-auto h-[82vh]"
      >
        <form
          className="mb-12"
          onSubmit={handleSubmit((data) => submitHandler(data))}
          data-testid="multi-slot-form"
        >
          <div className="h-[80px]">
            <LabeledInput
              id="title"
              labelClassNames={classNames(
                "block mt-2 mb-1 font-medium required",
                {
                  "text-danger-main": errors.name,
                }
              )}
              label="Group Discussion Name"
              inputFieldId="title"
              name="title"
              inputClassNames={classNames(
                "w-full",
                `${errors.title ? "input-error" : "input"}`
              )}
              placeholder="Enter group discussion name"
              register={register}
              type="text"
              validation={{
                required: requiredValidation(),
                maxLength: {
                  value: 50,
                  message: characterValidation({ limit: 50 }),
                },
              }}
              errorMessage={errors.title?.message}
              errorMessageClassNames="text-sm text-danger-dark"
            />
          </div>

          <div className="flex flex-row justify-between h-[100px] mt-2 mb-5">
            <div className="flex-col w-full md:w-5/12">
              <LabeledInput
                id="start_at"
                labelClassNames={classNames(
                  "block mt-2 mb-1 font-medium required",
                  { "text-danger-main": errors.start_at }
                )}
                label="Start"
              />

              <Controller
                control={control}
                name="start_at"
                rules={{
                  required: requiredValidation(),
                  validate: (startDate) => {
                    if (isBefore(new Date(startDate), new Date())) {
                      return dateTimeValidation();
                    }
                  },
                }}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Start date and time"
                    className={`${
                      errors.start_at ? "input-error" : "input"
                    } w-full`}
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeSelect
                    timeFormat="p"
                    styles={reactSelectStyle(errors.end_at)}
                    timeIntervals={15}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value && toDate(new Date(field.value))}
                  />
                )}
              />

              {errors.start_at && (
                <p className="text-sm text-danger-dark">
                  {errors.start_at.message}
                </p>
              )}
            </div>

            <div className="flex-col w-full md:w-5/12">
              <LabeledInput
                id="duration"
                labelClassNames={classNames(
                  "block mt-2 mb-1 font-medium required",
                  {
                    "text-danger-main": errors.duration,
                  }
                )}
                label="Duration"
                name="duration"
                inputClassNames={classNames(
                  "w-full",
                  inputErrClass(errors.duration)
                )}
                placeholder="Enter duration"
                register={register}
                type="number"
                rows={1}
                validation={{
                  required: requiredValidation(),
                  maxLength: {
                    value: 255,
                    message: characterValidation({ limit: 255 }),
                  },
                  min: {
                    value: 10,
                    message: "must be greater than or equal to 10",
                  },
                }}
                errorMessage={errors.duration?.message}
              >
                <span className="text-xs font-normal"> in minutes</span>
              </LabeledInput>
            </div>
          </div>

          <div className="mb-4 h-[75px]">
            <label
              htmlFor="mode"
              className={`mt-2 mb-1 block font-medium required ${
                errors.mode && "text-danger-main"
              }`}
            >
              Mode of Meeting
            </label>

            <Controller
              name="mode"
              control={control}
              rules={{
                required: requiredValidation(),
              }}
              render={({ field }) => (
                <Select
                  data-testid="mode-input"
                  id="modeInput"
                  maxMenuHeight={120}
                  menuPlacement="auto"
                  placeholder="Select mode of meeting"
                  options={GD_MEETING_MODES}
                  value={field.value}
                  onChange={(data) => {
                    field.onChange(data);
                    setValue("link", null);
                    setValue("address", null);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  styles={reactSelectStyle(errors.mode)}
                />
              )}
            />
            {errors.mode && (
              <p className="text-sm text-danger-dark">{errors.mode.message}</p>
            )}
          </div>

          <div className="h-[58px]">
            {meetingMode === GD_MEETING_MODES[0].value ? (
              <input
                name="address"
                data-testid="address-input"
                className={`${errors.address ? "input input-error" : "input"}`}
                placeholder="Paste address"
                disabled={!meetingMode}
                {...register("address", {
                  required:
                    meetingMode === GD_MEETING_MODES[0].value &&
                    requiredValidation(),
                  maxLength: {
                    value: 500,
                    message: characterValidation({ limit: 500 }),
                  },
                })}
              />
            ) : (
              <input
                name="link"
                data-testid="link-input"
                className={`${errors.link ? "input input-error" : "input"}`}
                placeholder={`Paste ${
                  meetingMode === GD_MEETING_MODES[1].value
                    ? "meeting link"
                    : "meeting link/address"
                }`}
                disabled={!meetingMode}
                {...register("link", {
                  required:
                    meetingMode === GD_MEETING_MODES[1].value &&
                    requiredValidation(),
                  maxLength: {
                    value: 500,
                    message: characterValidation({ limit: 500 }),
                  },
                })}
              />
            )}

            {errors.address && (
              <p className="mt-0 text-sm text-danger-dark">
                {errors.address.message}
              </p>
            )}

            {errors.link && (
              <p className="mt-0 text-sm text-danger-dark">
                {errors.link.message}
              </p>
            )}
          </div>

          <div className="min-h-[75px]">
            <label
              htmlFor="group_candidates"
              className={`mt-2 mb-1 block font-medium required ${
                errors.group_candidates && "text-danger-main"
              }`}
            >
              Candidates List
            </label>
            <Controller
              name="group_candidates"
              control={control}
              rules={{
                required: requiredValidation(),
              }}
              render={({ field }) => (
                <AsyncPaginate
                  isClearable
                  isMulti
                  closeMenuOnSelect={false}
                  data-testid="group-candidates-input"
                  maxMenuHeight={92}
                  id="group-candidates-select"
                  menuPlacement="auto"
                  className="w-full"
                  styles={reactSelectStyle(
                    errors.group_candidates,
                    customSelect
                  )}
                  value={field.value}
                  loadOptions={loadAllStageCandidates}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Select candidates"
                  getOptionValue={(option) =>
                    option.candidate_job_id || option.id
                  }
                  getOptionLabel={(option) =>
                    option.candidate_name || option.name
                  }
                  additional={{ page: 1 }}
                />
              )}
            />
            {errors.group_candidates && (
              <p className="text-sm text-danger-dark">
                {errors.group_candidates.message}
              </p>
            )}
          </div>

          <div className="my-4 h-[75px]">
            <label
              htmlFor="group_moderators"
              className={`mt-2 mb-1 block font-medium required ${
                errors.group_moderators && "text-danger-main"
              }`}
            >
              Assign Moderators
            </label>

            <Controller
              name="group_moderators"
              control={control}
              rules={{
                required: requiredValidation(),
              }}
              render={({ field }) => (
                <AsyncPaginate
                  isClearable
                  isMulti
                  isSearchable
                  closeMenuOnSelect={false}
                  data-testid="group-moderators-input"
                  maxMenuHeight={70}
                  id="group-moderators-select"
                  menuPlacement="auto"
                  className="w-full"
                  styles={reactSelectStyle(
                    errors.group_moderators,
                    customSelect
                  )}
                  value={field.value}
                  loadOptions={loadAllUsersOptions}
                  onChange={(value) => field.onChange(value)}
                  debounceTimeout={1000}
                  placeholder="Select moderators"
                  getOptionValue={(option) => option.uuid}
                  getOptionLabel={(option) => option.name || option.email}
                  additional={{ page: 1 }}
                />
              )}
            />

            {errors.group_moderators && (
              <p className="text-sm text-danger-dark">
                {errors.group_moderators.message}
              </p>
            )}
          </div>
        </form>
      </Modal>
      {showDeleteGDConfirmModal && (
        <Modal
          title="Are you sure?"
          handleAccept={() => {
            onDeleteGroup();
          }}
          handleReject={() => {
            setShowDeleteGDConfirmModal(false);
          }}
          modalType="confirm"
          acceptButtonText="Yes"
          rejectButtonText="No"
        >
          Are you sure you want to cancel {selectedGroupDetails.title || "NA"}{" "}
          for Group {selectedGroupDetails.serialNo} ?
        </Modal>
      )}
    </>
  );
};

ScheduleGDModal.propTypes = {
  currentStageId: PropTypes.number,
  setSelectedGroupDetails: PropTypes.func,
  getGDDetails: PropTypes.func,
  selectedGroupDetails: PropTypes.shape({
    serialNo: PropTypes.number,
    uuid: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    address: PropTypes.string,
    start_at: PropTypes.string,
    mode: PropTypes.string,
    group_candidates: PropTypes.shape({
      candidate_job_id: PropTypes.number,
      candidate_name: PropTypes.string,
    }),
    group_moderators: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  alreadyGDOverCandidates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      candidate_id: PropTypes.number,
      candidate_job_id: PropTypes.number,
      candidate_name: PropTypes.string,
    })
  ),
  getCandidateStageData: PropTypes.func,
};

ScheduleGDModal.defaultProps = {
  currentStageId: 0,
  setSelectedGroupDetails: () => {},
  getCandidateStageData: () => {},
  getGDDetails: () => {},
};

export default ScheduleGDModal;
