import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// In future all flag based functionality checking will go here
export const FeatureFlagable = ({ featureName }) => {
  const location = useLocation();
  const { flags } = useSelector((state) => state.featureFlags);
  const isFeatureEnable = flags?.some((obj) => obj.name === featureName);

  return isFeatureEnable ? (
    <Outlet />
  ) : (
    <Navigate to="notfound" state={{ from: location }} replace />
  );
};

FeatureFlagable.propTypes = {
  featureName: PropTypes.string,
};
