import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import PropTypes from "prop-types";

import { getCandidateStageHistory } from "api/erp/jobApi";
import StatusTimeline from "components/atoms/StatusTimeline";

const CandidateStageHistory = ({ candidate, job_uuid: jobUuid }) => {
  const [candidateHistory, setCandidateHistory] = useState([]);

  useEffect(() => {
    getCandidateStageHistory(jobUuid, candidate.id).then((candidateHistory) => {
      const examNames = candidateHistory.data.map(
        (item) => `${item.name} ${item.status}`
      );
      setCandidateHistory(examNames);
    });
  }, []);
  return (
    <div className="mt-2 p-4 text-gray-600">
      {!candidateHistory && (
        <div className="mx-[50%] py-2">
          <CgSpinner className="spinner text-lg text-primary-main" />
        </div>
      )}

      {candidateHistory && <StatusTimeline record={candidateHistory} />}
    </div>
  );
};

CandidateStageHistory.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    educations: PropTypes.array,
    skills: PropTypes.array,
    work_experiences: PropTypes.array,
    designation: PropTypes.string,
    current_location: PropTypes.string,
    status: PropTypes.string,
    no_of_positions: PropTypes.number,
  }),

  job_uuid: PropTypes.string,
};
export default CandidateStageHistory;
