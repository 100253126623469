import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ALERT_TYPE } from "app/constants";
import { LabeledInput } from "components/atoms";
import Modal from "components/molecules/Modal";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";
import {
  createQuestionTagAction,
  getFilterQuestionTagAction,
  updateQuestionTagAction,
} from "store/thunkActions/erp/questionTagThunk";

const QuestionTagModal = ({ tag, setIsEditState }) => {
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [suggestions, setSuggestions] = useState([]);

  const { data } = useSelector((state) => state.questionTag);

  useEffect(() => {
    if (tag) {
      reset({ ...tag });
    }
  }, [tag]);

  const showAlert = useAlert();
  const dispatch = useDispatch();

  const setErrorsOnFields = ({ errors }) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, { message: value[0] });
    });
  };

  const submitHandler = (data) => {
    const action = tag
      ? updateQuestionTagAction({ ...data, name: data.name.trim() })
      : createQuestionTagAction({ name: data.name.trim() });
    return dispatch(action)
      .unwrap()
      .then(({ meta }) => {
        setIsEditState(false);
        showAlert(ALERT_TYPE[meta.message_type], meta.message);
        dispatch(getFilterQuestionTagAction());
      })
      .catch((errors) => {
        if (errors) {
          setErrorsOnFields(errors);
        }
      });
  };

  const inputErrClass = (error) => {
    return error ? "input-error" : "input";
  };

  const setSuggestionList = (text) => {
    if (data.length > 0 && text != "") {
      const suggestedTags = data.filter((tag) =>
        tag.name.toLowerCase().includes(text.toLowerCase())
      );
      setSuggestions(suggestedTags);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <Modal
      title={tag ? "Edit Tag" : "Add Tag"}
      testId="add-edit-tag-modal"
      acceptBtnType={
        !watch("name") || watch("name") === tag?.name ? "disabled" : "primary"
      }
      acceptButtonText={tag ? "Update" : "Save"}
      scrollClass="overflow-y-auto max-h-96 h-auto"
      handleAccept={handleSubmit((data) => submitHandler(data))}
      handleCancel={() => setIsEditState(false)}
      isDirty={!(!watch("name") || watch("name") === tag?.name)}
    >
      <form
        className={classNames("m-1", !tag && "min-h-[120px]")}
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <LabeledInput
          id="tags"
          labelClassNames={classNames("block font-medium mt-4 mb-1 required", {
            "text-danger-main": errors.name,
          })}
          label="Tag"
          name="name"
          inputClassNames={classNames("w-full", inputErrClass(errors.name))}
          placeholder="Please enter a tag name."
          register={register}
          type="text"
          validation={{
            maxLength: {
              value: 50,
              message: characterValidation({ limit: 50 }),
            },
            required: requiredValidation(),
          }}
          errorMessage={errors.name?.message}
          onKeyUp={(e) => setSuggestionList(e.target.value)}
          autoComplete="off"
        />

        {!tag && (
          <div>
            {suggestions?.map((data) => {
              return (
                <p key={data.id} className="my-2 rounded-md p-2 text-sm">
                  {data.name}
                </p>
              );
            })}
          </div>
        )}
      </form>
    </Modal>
  );
};

QuestionTagModal.propTypes = {
  setIsEditState: PropTypes.func,
  tag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default QuestionTagModal;
