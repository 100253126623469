import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";

import { EDITOR_FORMAT } from "app/constants";
import { Modal } from "components/molecules";

const CodingAnswer = ({
  codeText,
  codeLanguagesList,
  selectedLanguage,
  onChangeCodeText,
  onChangeCodeLanguage,
}) => {
  const { control, setValue } = useForm({
    mode: "onchange",
  });

  const [tempLanguage, setTempLanguage] = useState();
  const [isConfirm, setIsConfirm] = useState();

  const handleCodeChange = (data) => {
    if (selectedLanguage.coding_language_id !== data.coding_language_id) {
      setIsConfirm(true);
      setTempLanguage(data);
    }
  };

  const handleLanguageSwitch = () => {
    onChangeCodeLanguage(tempLanguage);
    setIsConfirm(false);
  };

  useEffect(() => {
    setValue("languageSelect", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <>
      <form className="mt-4 flex space-x-4 px-4">
        <label htmlFor="languageSelect" className="mt-1 font-semibold">
          Select Coding Language:
        </label>
        <Controller
          control={control}
          name="languageSelect"
          id="languageSelect"
          className="ml-2 border-2 p-1"
          render={({ field }) => (
            <Select
              type="submit"
              name="languageSelect"
              className="w-40"
              options={codeLanguagesList}
              defaultValue={codeLanguagesList.find((cl) => {
                return (
                  cl.coding_language_id === selectedLanguage.coding_language_id
                );
              })}
              id="languageSelect"
              menuPlacement="auto"
              value={field.value}
              onChange={(data) => {
                handleCodeChange(data);
              }}
              getOptionLabel={(option) => option.coding_language}
              getOptionValue={(option) => option.id}
            />
          )}
        />
      </form>

      <Editor
        name="editor"
        theme="vs-dark"
        language={
          EDITOR_FORMAT[selectedLanguage?.coding_language?.toLowerCase()]
        }
        className="relative z-0 mt-2 px-4"
        height="600px"
        value={
          codeText || selectedLanguage.code_text || "// write your code here"
        }
        onChange={(data) => onChangeCodeText(data)}
        options={{
          contextmenu: false,
          dragAndDrop: false,
          dropIntoEditor: false,
        }}
      />

      {isConfirm && (
        <Modal
          title="Are you sure?"
          description="Switching language will delete your current code and you will not be able to recover it later."
          acceptButtonText="Switch"
          rejectButtonText="Cancel"
          acceptBtnType="primary"
          rejectBtnType="secondary"
          acceptButtonColor="bg-danger-main"
          handleAccept={handleLanguageSwitch}
          handleReject={() => setIsConfirm(false)}
        />
      )}
    </>
  );
};

CodingAnswer.propTypes = {
  codeText: PropTypes.string,
  codeLanguagesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      file_extension: PropTypes.string,
      code_string: PropTypes.string,
    })
  ),
  selectedLanguage: PropTypes.any, // TODO: whoever pics up this file for work please define selectedLanguage
  onChangeCodeText: PropTypes.func,
  onChangeCodeLanguage: PropTypes.func,
};

export default CodingAnswer;
