import { createSlice } from "@reduxjs/toolkit";

export const candidateAnswersSlice = createSlice({
  name: "examCandidate",
  initialState: {
    candidate_answers: {},
  },
  reducers: {
    loadCandidateAnswers: (state, action) => {
      state.candidate_answers = action.payload;
    },
  },
});

export const { loadCandidateAnswers } = candidateAnswersSlice.actions;

export default candidateAnswersSlice.reducer;
