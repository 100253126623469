import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCandidateLanguagesAction } from "store/thunkActions/candidate/languageThunk";

import CardContainer from "../Common/CardContainer";

import Form from "./Form";
import Language from "./Language";

const Languages = () => {
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();

  const { candidateLanguages } = useSelector(
    (state) => state.candidateLanguages
  );
  useEffect(() => {
    dispatch(getCandidateLanguagesAction());
  }, []);

  return (
    <CardContainer
      title="Languages"
      addEventButtonTitle="Add Language"
      addEventButtonTestId="add-language-btn"
      setIsAdding={() => setIsAdding(true)}
    >
      {!candidateLanguages.length && !isAdding && (
        <span className="block text-sm italic text-gray-main">
          Click on the plus icon to add languages
        </span>
      )}

      {candidateLanguages.map((candidateLanguage) => {
        return (
          <div
            key={candidateLanguage.id}
            className="border-b border-gray-lighterShade2 py-4 last:border-b-0"
          >
            <Language candidateLanguage={candidateLanguage} />
          </div>
        );
      })}

      {isAdding && (
        <Form setIsEditState={setIsAdding} setIsAdding={setIsAdding} />
      )}
    </CardContainer>
  );
};

export default Languages;
