import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { AiFillEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";

import { getQuestionPaperQuestionsBySection } from "api/erp/questionPaperApi";
import { Button } from "components/atoms";
import ActivityIndicator from "components/common/ActivityIndicator";
import { NAVBAR_HEIGHT_CUSTOM_EVENT, SITE_TITLE } from "constants/common";
import { constructQueryParams } from "helpers/constructQueryParams";
import { updateHeaderTitle } from "store/slices/miscellaneousSlice";
import { showQuestionPaperAction } from "store/thunkActions/erp/questionsThunk";
import { getSectionAction } from "store/thunkActions/erp/sectionsThunk";

import SectionModal from "./Section/SectionModal";
import QuestionPaperModal from "./QuestionPaperModal";
import Section from "./Section";

const UL_CLASS =
  "flex mb-0 list-none pt-4 flex-row border-collapse pl-0 overflow-x-auto text-gray-light";

const QuestionPaper = () => {
  const questionPaperId = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedSectionId = parseInt(
    sessionStorage.getItem("selectedSection"),
    10
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isSectional, setIsSectional] = useState();
  const [isAddingSection, setIsAddingSection] = useState(false);
  const [questions, setQuestions] = useState();
  const [selected, setSelected] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [isSectionLoaded, setSectionLoaded] = useState(false);
  const [navbarHeight, setNavbarHeight] = useState(46);

  const [questionPaper, sections] = useSelector(
    ({ questionPaper, sections }) => [questionPaper.data, sections.data]
  );

  const storedSection = useMemo(
    () => sections?.find((section) => section.id === selectedSectionId),
    [selected, sections]
  );

  useEffect(() => {
    if (questionPaper) {
      dispatch(
        updateHeaderTitle({
          breadCrumbDetails: {
            intermediateLinkList: [
              {
                name: "Question Paper",
                link: `/questions_manager?${constructQueryParams({
                  tab: "question_papers",
                  page: 1,
                  page_size: 20,
                })}`,
              },
            ],
            currentPageName: questionPaper?.name,
          },
        })
      );
    }
  }, [questionPaper]);

  useEffect(() => {
    Promise.allSettled([
      dispatch(showQuestionPaperAction(questionPaperId.id))
        .unwrap()
        .then((response) => setIsSectional(response.sectional)),
      dispatch(getSectionAction(questionPaperId.id)).unwrap(),
    ]).then(() => {
      setSectionLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (isSectionLoaded) {
      if (searchParams.get("tab") !== null) {
        setSelected(searchParams.get("tab"));
      } else if (selectedSectionId) {
        setSelected(selectedSectionId);
      } else if (sections?.length > 0) {
        setSelected(sections[0].id);
      }
    }
  }, [questionPaperId.id, searchParams, isSectionLoaded]);

  useEffect(() => {
    setQuestions();
    if (selected) {
      sessionStorage.setItem("selectedSection", selected);
      getQuestionPaperQuestionsBySection(questionPaperId.id, selected).then(
        (response) => {
          setQuestions(response.data);
        }
      );
    } else if (isSectional === false) {
      getQuestionPaperQuestionsBySection(questionPaperId.id).then(
        (response) => {
          setQuestions(response.data);
        }
      );
    } else {
      setQuestions([]);
    }
  }, [selected, isSectional, questionPaperId.id]);

  useEffect(() => {
    if (sections && sections.length) {
      setSelectedSection((prevState) => {
        const data = sections.find((section) => section.id == selected);
        if (data != prevState) {
          return data;
        }
      });
    }
  }, [sections, selected]);

  useEffect(() => {
    const handleNavbarHeightUpdate = (event) => {
      const navbarHeaderHeight = event.detail - 10;
      setNavbarHeight(navbarHeaderHeight);
    };
    window.addEventListener(
      NAVBAR_HEIGHT_CUSTOM_EVENT,
      handleNavbarHeightUpdate
    );
    return () =>
      window.removeEventListener(
        NAVBAR_HEIGHT_CUSTOM_EVENT,
        handleNavbarHeightUpdate
      );
  }, []);

  localStorage.setItem("question_paper_id", questionPaper?.id);

  if (!questions || !questionPaper || !isSectionLoaded) {
    return <ActivityIndicator />;
  }

  return (
    <div className="w-full pb-4 pt-2 px-4 md:px-6">
      <Helmet>
        <title>{`${questionPaper.name} - ${SITE_TITLE}`}</title>
      </Helmet>

      <div className="w-full pt-4">
        <div className="mt-1 mb-2 flex flex-wrap justify-between px-2 md:flex-nowrap">
          <div className="mr-2 mb-2 flex items-center lg:mr-4">
            <p
              title={questionPaper.name}
              className="w-full cursor-default break-before-auto text-xl font-medium sm:w-5/6"
            >
              {questionPaper.name}
            </p>

            <div className=" mx-4 h-fit min-w-fit items-center rounded-md bg-gray-lighterShade2 px-2 py-1 text-xs font-medium uppercase text-black-shade1 xl:ml-4">
              {questionPaper.question_paper_type}
            </div>

            <Button
              title="Edit Question Paper Name"
              testId="edit-question-paper-name"
              onClick={() => setIsEditing(true)}
              btnClassName="btn-icon-edit"
            >
              <AiFillEdit className="text-sm" />
            </Button>
          </div>

          {isSectional && (
            <div className="mr-0 min-w-fit lg:mr-1">
              <Button
                title="Add Section"
                testId="add-section-btn"
                btnName="Add Section"
                onClick={() => setIsAddingSection(true)}
                type="primary"
                size="md"
                customWidth={32}
              />
            </div>
          )}
        </div>

        {isSectional && (
          <div
            className="sticky bg-gray-lighterShade3"
            style={{ top: `${navbarHeight}px` }}
          >
            <ul className={UL_CLASS} role="tablist">
              {sections.map(({ name: label, id: key }) => (
                <li
                  key={key}
                  className={classNames(
                    "flex items-center border-b-2",
                    selected == key
                      ? "border-primary-dark font-semibold text-primary-dark"
                      : "text-gray-dark hover:text-gray-900"
                  )}
                >
                  <button
                    className="w-max px-4 pb-2 text-base"
                    onClick={() => {
                      setSelected(key);
                    }}
                  >
                    {label}
                  </button>
                </li>
              ))}
              <hr className="mt-auto w-full border bg-gray-lighter" />
            </ul>
          </div>
        )}

        {(sections.length > 0 || !isSectional) && (
          <div className="question-body custom-scrollbar ml-2 overflow-y-auto">
            <Section
              sectionQuestions={questions}
              section={selectedSection || storedSection || sections[0]}
              questionPaperName={questionPaper.name}
              questionPaperType={questionPaper.question_paper_type}
              questionPaperId={questionPaper.id}
              setQuestions={setQuestions}
              isSectional={isSectional}
            />
          </div>
        )}

        {isAddingSection && (
          <SectionModal setIsEditState={setIsAddingSection} />
        )}

        {isEditing && (
          <QuestionPaperModal
            questionPaperName={questionPaper.name}
            questionPaperType={questionPaper.question_paper_type}
            setIsEditState={setIsEditing}
            isSectional={isSectional}
          />
        )}
      </div>
    </div>
  );
};
export default QuestionPaper;
