import { BiTrash } from "react-icons/bi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import classNames from "classnames";
import PropTypes from "prop-types";

const DescriptiveCard = ({
  title,
  link,
  editButtonTitle,
  deleteButtonTitle,
  editButtonTestId,
  deleteButtonTestId,
  children,
  setIsEditState,
  setIsDeleting,
}) => {
  return (
    <div className="break-all text-justify">
      <div className="flex items-start justify-between">
        <h4 className="py-2 pr-2 text-lg capitalize leading-4">
          <a
            title={title}
            target="_blank"
            rel="noreferrer"
            className={classNames(link && "text-primary-light hover:underline")}
            href={link}
          >
            {title}
          </a>
        </h4>
        <div className="flex">
          <button
            title={editButtonTitle}
            data-testid={editButtonTestId}
            className="btn-icon-secondary"
            onClick={() => setIsEditState(true)}
          >
            <MdOutlineModeEditOutline className="text-lg" />
          </button>
          <button
            title={deleteButtonTitle}
            data-testid={deleteButtonTestId}
            className="btn-icon-danger"
            onClick={() => setIsDeleting(true)}
          >
            <BiTrash className="text-lg" />
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

DescriptiveCard.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  editButtonTitle: PropTypes.string,
  deleteButtonTitle: PropTypes.string,
  editButtonTestId: PropTypes.string,
  deleteButtonTestId: PropTypes.string,
  children: PropTypes.node,
  setIsEditState: PropTypes.func,
  setIsDeleting: PropTypes.func,
};

export default DescriptiveCard;
