import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
import PropTypes from "prop-types";

import { STAGE_TYPE } from "app/constants";
import { Button } from "components/atoms";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import reactSelectStyle from "helpers/reactSelectStyle";

const BulkStageForm = ({
  setStageDetails,
  stageDetails,
  setIsAdding,
  currentStage,
  isEditing,
  setIsEditing,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors, isSubmitting },
    watch,
  } = useForm();

  const stageName = watch("name");
  const stageType = watch("stage_type");
  const anyFieldEmpty = !stageName || !stageType;

  useEffect(() => {
    if (currentStage) {
      reset({
        ...currentStage,
        stage_type: STAGE_TYPE.find(
          (type) => type.value === currentStage.stage_type
        ),
      });
    }
  }, [currentStage, reset]);

  const handleCloseForm = (e) => {
    e.preventDefault();
    if (isEditing) {
      setIsEditing(false);
    } else {
      setIsAdding(false);
    }
  };

  const submitHandler = (data) => {
    if (isEditing && data.sequence) {
      const allStages = stageDetails.map((stage) => {
        if (stage.sequence === currentStage.sequence) {
          return {
            name: data.name,
            stage_type: data.stage_type.value,
            sequence: currentStage.sequence,
          };
        }
        return stage;
      });

      setStageDetails([...allStages]);
      setIsEditing(false);
    } else {
      setStageDetails([
        ...stageDetails,
        {
          name: data.name,
          stage_type: data.stage_type.value,
          sequence: stageDetails.length + 1,
        },
      ]);
      setIsAdding(false);
    }
  };

  return (
    <div className={classNames({ "pb-2 border-b": isEditing })}>
      <form
        data-testid="add-and-edit-stage-modal-form"
        className="my-2 mx-2"
        onSubmit={handleSubmit((data) => submitHandler(data))}
      >
        <label
          htmlFor="name"
          className={`required mt-2 mb-1 font-medium ${
            errors.name && "text-danger-main"
          }`}
        >
          Name
        </label>

        <input
          id="name"
          name="stage_name"
          className={`mb-4 w-full ${errors.name ? "input-error" : "input"}`}
          type="text"
          placeholder="Enter stage name"
          {...register("name", {
            required: requiredValidation(),
            maxLength: {
              value: 100,
              message: characterValidation({ limit: 100 }),
            },
          })}
        />

        {errors.name && (
          <p className="text-sm text-danger-dark mb-2">{errors.name.message}</p>
        )}

        <label
          htmlFor="stage_type"
          className={`required mt-5 mb-1 font-medium ${
            errors.stage_type && "text-danger-main"
          }`}
        >
          Type
        </label>

        <Controller
          name="stage_type"
          control={control}
          rules={{
            required: requiredValidation(),
          }}
          render={({ field }) => (
            <Select
              name="stage_type"
              options={STAGE_TYPE}
              isClearable
              isDisabled={isEditing}
              id="stage-type-select"
              maxMenuHeight={120}
              menuPosition="fixed"
              menuPlacement="auto"
              styles={reactSelectStyle(errors.stage_type)}
              placeholder="Select stage type"
              value={field.value}
              onChange={(data) => field.onChange(data)}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              className="mb-8"
            />
          )}
        />

        {errors.stage_type && (
          <p className="text-sm text-danger-dark mt-1">
            {errors.stage_type.message}
          </p>
        )}

        <div className="flex justify-end gap-3 ">
          <Button
            title="Cancel"
            btnName="Cancel"
            type="secondary"
            size="sm"
            testId="cancel"
            onClick={(e) => handleCloseForm(e)}
            buttonType="button"
            isLoading={isSubmitting}
          />
          <Button
            title={isEditing ? "Update" : "Create"}
            btnName={isEditing ? "Update" : "Create"}
            type={anyFieldEmpty || !isDirty ? "disabled" : "primary"}
            size="sm"
            testId="add-stage"
            buttonType="submit"
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

BulkStageForm.propTypes = {
  setStageDetails: PropTypes.func,
  setIsAdding: PropTypes.func,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  currentStage: PropTypes.shape({
    name: PropTypes.string,
    stage_type: PropTypes.string,
    sequence: PropTypes.number,
  }),
  stageDetails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      stage_type: PropTypes.string,
      sequence: PropTypes.number,
    })
  ),
};

export default BulkStageForm;
