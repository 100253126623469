import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { BiLink } from "react-icons/bi";
import { BsEnvelopeFill } from "react-icons/bs";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiCake2Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import axios from "axios";
import classNames from "classnames";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import moment from "moment";
import PropTypes from "prop-types";

import { getPresignedURL } from "api/candidate/candidateApi";
import { ALERT_TYPE, ICON_SIZE_HASH, WEBSITE_TYPE } from "app/constants";
import { ActivityIndicator, Avatar, LabeledInput } from "components/atoms";
import CharacterAvatar from "components/atoms/CharacterAvatar";
import RichTextEditor from "components/atoms/CKEditor/Editor";
import RenderParsedHTML from "components/atoms/RenderParsedHTML";
import Modal from "components/molecules/Modal";
import { LOGO_SIZE, LOGO_TYPE, LOGO_UPLOAD_FAILED } from "constants/common";
import {
  characterValidation,
  requiredValidation,
} from "helpers/genericErrorMessages";
import useAlert from "hooks/useAlert";
import {
  setCandidateUserAction,
  updateCurrentUserAction,
} from "store/thunkActions/currentUserThunk";

import RefImageButton from "../../../common/RefImageButton";

const BasicInfo = ({ currentUser, jobApplicationId, reviewProfile }) => {
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setError,
    reset,
  } = useForm({ shouldUnregister: true });

  const [isEditState, setIsEditState] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const inputFile = useRef(null);

  useEffect(() => {
    reset({
      ...currentUser?.data,
      date_of_birth: currentUser?.data?.date_of_birth
        ? new Date(currentUser?.data?.date_of_birth)
        : null,
    });
  }, [isEditState]);

  const setErrorsOnFields = (errors) => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        setError(key, { message: value[0] });
      });
    }
  };

  const submitHandler = (data) => {
    data.name = data.name?.trim();
    data.title = data.title?.trim();
    data.date_of_birth =
      data.date_of_birth &&
      moment(data.date_of_birth).startOf("day").format("yyyy-MM-DD");
    data.about = data.about?.trim();
    return dispatch(updateCurrentUserAction(data))
      .unwrap()
      .then(() => {
        setIsEditState(false);
        dispatch(setCandidateUserAction());
      })
      .catch(({ errors }) => setErrorsOnFields(errors));
  };

  const handleProfilePictureUpload = (e) => {
    const { files } = e.target;
    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    if (files && files.length) {
      if (!files[0].type.match(imageMimeType)) {
        showAlert(ALERT_TYPE.DANGER, LOGO_TYPE);
        inputFile.current.value = null;
      } else if (files[0].size > 5000000) {
        showAlert(ALERT_TYPE.DANGER, LOGO_SIZE);
        inputFile.current.value = null;
      } else {
        setIsUploading(true);

        const fileName = files[0].name;

        getPresignedURL(currentUser.data.id, fileName)
          .then(async (response) => {
            const presignedURL = response.data.url;
            const { fields } = response.data;
            const formData = new FormData();

            Object.keys(fields).forEach((key) => {
              formData.append(key, fields[key]);
            });

            formData.append("Content-Type", "image/png");
            formData.append("file", files[0]);
            try {
              await axios.post(presignedURL, formData);
              showAlert(
                ALERT_TYPE.INFO,
                "Profile picture is uploaded successfully. We are processing it"
              );
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            } catch (error) {
              showAlert(ALERT_TYPE.DANGER, "Please try again!");
            }
          })

          .catch(() => {
            setIsUploading(false);
            showAlert(ALERT_TYPE.DANGER, LOGO_UPLOAD_FAILED);
          });
      }
    }
  };

  const onProfileImageButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div
      className={classNames(
        " w-full break-all p-2 px-8 text-justify ",
        jobApplicationId && "pt-8 sm:pt-4"
      )}
    >
      <div className="flex flex-col md:flex-row">
        {!reviewProfile && (
          <div className="flex flex-col">
            <input
              style={{ display: "none" }}
              accept=".jpg, .jpeg, .png"
              ref={inputFile}
              onChange={handleProfilePictureUpload}
              type="file"
              data-testid="avatar-file-input"
            />

            {currentUser.data?.avatar.thumb ? (
              <div className="flex flex-col self-start">
                <div className="h-32 w-32 rounded-full ring-2 ring-primary-dark">
                  {isUploading ? (
                    <ActivityIndicator />
                  ) : (
                    <Avatar
                      src={currentUser.data?.avatar?.thumb}
                      height={32}
                      width={32}
                    />
                  )}
                </div>
                {!isUploading && (
                  <button
                    title="Upload Profile Image"
                    onClick={() => onProfileImageButtonClick()}
                    className="absolute mt-20 ml-[100px] flex h-8 w-8 items-center rounded-full border-2 border-primary-dark bg-gray-lighterShade3 px-1 hover:bg-primary-lightest"
                  >
                    <MdOutlineModeEditOutline
                      size={ICON_SIZE_HASH.md}
                      className="text-primary-main"
                    />
                  </button>
                )}
              </div>
            ) : (
              <div className="ml-3 flex h-28 w-28 flex-col self-start rounded-full bg-primary-lightest ring-2 ring-primary-dark ring-offset-4">
                {isUploading ? (
                  <ActivityIndicator containerClass="mt-0" />
                ) : (
                  <CharacterAvatar
                    char={currentUser.data?.name.charAt(0)}
                    width={28}
                    height={28}
                  />
                )}
                {!isUploading && (
                  <button
                    title="Upload Profile Image"
                    onClick={() => onProfileImageButtonClick()}
                    className="absolute mt-20 ml-20 flex h-8 w-8 items-center rounded-full border-2 border-primary-dark bg-gray-lighterShade3 px-1 hover:bg-primary-lightest"
                  >
                    <MdOutlineModeEditOutline
                      size={ICON_SIZE_HASH.md}
                      className="text-primary-main"
                    />
                  </button>
                )}
              </div>
            )}
          </div>
        )}

        {/* {showRemovingPictureModal && (
          <Modal
            title="Are you sure?"
            description="Are you sure you want to remove your profile picture?"
            acceptButtonText="Yes"
            rejectButtonText="No"
            acceptBtnType="primary"
            rejectBtnType="secondary"
            handleAccept={removePicture}
            handleReject={() => setShowRemovingPictureModal(false)}
          />
        )} */}
        <div
          className={classNames(
            "relative left-1 mr-2 mt-4 flex-grow sm:mr-0 md:left-5 md:mt-0 md:mr-4 md:w-9/12",
            !reviewProfile && "md:ml-8"
          )}
        >
          <div className="mb-2 flex">
            <div>
              <h3 className="text-3xl font-normal">
                {currentUser?.data?.name}
              </h3>
              <p className="text-md block font-medium italic text-gray-dark">
                {currentUser?.data?.title || "Current Position"}
              </p>
              <p className="my-2 flex items-center text-sm text-gray-dark">
                <RiCake2Line className="mr-2 inline" />
                {currentUser?.data?.date_of_birth
                  ? format(
                      new Date(currentUser?.data?.date_of_birth),
                      "dd MMMM yyyy",
                      {
                        locale: enGB,
                      }
                    )
                  : "add your date of birth..."}
              </p>
              <div className="flex flex-col">
                <span className="items-center text-xs italic text-gray-dark">
                  <BsEnvelopeFill
                    className="mr-2 mb-1 inline"
                    size={ICON_SIZE_HASH.sm}
                  />
                  {currentUser?.data?.email}
                </span>
                {currentUser?.data?.portfolio_url && (
                  <span className="items-center text-xs text-gray-dark">
                    <BiLink
                      className="mr-2 mb-1 inline"
                      size={ICON_SIZE_HASH.sm}
                    />
                    <a
                      title="Portfolio"
                      target="_blank"
                      rel="noreferrer"
                      className="italic text-primary-light hover:underline"
                      href={currentUser?.data?.portfolio_url}
                    >
                      Portfolio
                    </a>
                  </span>
                )}
              </div>
            </div>
            <button
              title="Edit Personal Info"
              data-testid="personal-info-edit-btn"
              className="btn-icon-secondary"
              onClick={() => setIsEditState((editable) => !editable)}
            >
              <MdOutlineModeEditOutline className="text-lg" />
            </button>
          </div>
          <p className="text-md block font-medium text-gray-dark">About Me</p>
          <div className="mt-2 block text-sm text-gray-main">
            <RenderParsedHTML
              content={
                currentUser?.data?.about || "Write something about yourself"
              }
            />
          </div>
        </div>
        <div className="my-4 mr-4 flex flex-col items-end sm:mx-2">
          <RefImageButton currentUser={currentUser.data} />
        </div>
      </div>

      {isEditState && (
        <Modal
          title="Edit Details"
          acceptButtonText="Update"
          acceptBtnType="primary"
          handleAccept={handleSubmit(submitHandler)}
          scrollClass="overflow-y-scroll max-h-[28rem]"
          customModalWidth="600px"
          handleCancel={() => setIsEditState(false)}
          isDirty={isDirty}
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="my-2">
              <label
                htmlFor="name"
                className={`required mt-2 mb-1 block font-medium ${
                  errors.name && "text-danger-main"
                }`}
              >
                Name
              </label>

              <input
                name="name"
                data-testid="name-input"
                className={`${errors.name ? "input input-error" : "input"}`}
                placeholder="Name"
                {...register("name", {
                  required: requiredValidation(),
                  maxLength: {
                    value: 100,
                    message: characterValidation({ limit: 100 }),
                  },
                })}
              />

              {errors.name && (
                <p className="mt-0 text-sm text-danger-dark">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div className="my-2">
              <label
                htmlFor="email"
                className="required mt-2 mb-1 block font-medium"
              >
                Email
              </label>

              <input
                name="email"
                data-testid="email-input"
                disabled
                className="input text-black bg-gray-200  opacity-70 hover:outline-gray-main"
                value={currentUser?.data?.email}
              />
            </div>

            <div className="my-2">
              <label
                htmlFor="portfolio-url"
                className="mt-2 mb-1 block font-medium"
              >
                Website
              </label>
              <div className=" flex flex-col md:flex-row md:gap-1">
                <div className="w-full md:w-1/5">
                  <input
                    name="webstite_type"
                    disabled
                    className="input text-black bg-gray-200 opacity-90 hover:outline-gray-main"
                    value={WEBSITE_TYPE[0].name}
                  />
                </div>
                <div className="w-full md:w-4/5">
                  <LabeledInput
                    id="portfolio-url"
                    inputFieldId="portfolio-url"
                    name="portfolio_url"
                    inputClassNames={classNames(
                      "w-full disabled:bg-slate-200",
                      `${errors.portfolio_url ? "input-error" : "input"}`
                    )}
                    placeholder="Enter portfolio link"
                    register={register}
                    type="url"
                    validation={{
                      maxLength: {
                        value: 255,
                        message: characterValidation({ limit: 255 }),
                      },
                    }}
                    errorMessage={errors.portfolio_url?.message}
                    errorMessageClassNames="text-sm text-danger-dark"
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <label
                htmlFor="title"
                className={`mt-2 mb-1 block font-medium ${
                  errors.title && "text-danger-main"
                }`}
              >
                Current Position
              </label>

              <input
                name="title"
                data-testid="job-title-input"
                className={`${errors.title ? "input input-error" : "input"}`}
                placeholder="Current Position"
                {...register("title", {
                  maxLength: {
                    value: 100,
                    message: characterValidation({ limit: 100 }),
                  },
                })}
              />

              {errors.title && (
                <p className="mt-0 text-sm text-danger-dark">
                  {errors.title.message}
                </p>
              )}
            </div>

            <div className="my-2">
              <label
                htmlFor="date_of_birth"
                className={`mt-2 mb-1 block font-medium ${
                  errors.date_of_birth && "text-danger-main"
                }`}
              >
                Date of Birth
              </label>

              <Controller
                control={control}
                name="date_of_birth"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value && new Date(field.value)}
                    data-testid="date-of-birth-input"
                    showPopperArrow={false}
                    showYearDropdown
                    scrollableYearDropdown
                    placeholderText="Select date of birth"
                    className={`${
                      errors.date_of_birth ? "input-error" : "input"
                    } w-full`}
                    dateFormat="dd MMMM yyyy"
                    yearDropdownItemNumber={73}
                    maxDate={new Date().setFullYear(
                      new Date().getFullYear() - 15
                    )}
                    minDate={new Date().setFullYear(
                      new Date().getFullYear() - 80
                    )}
                    onChange={(date) => field.onChange(date)}
                  />
                )}
              />

              {errors.date_of_birth && (
                <p className="mt-0 text-sm text-danger-dark">
                  {errors.date_of_birth.message}
                </p>
              )}
            </div>

            <div className="my-2">
              <label
                className={`mt-2 mb-1 block font-medium ${
                  errors.about && "text-danger-main"
                }`}
                htmlFor="about"
              >
                About Me
              </label>

              <Controller
                name="about"
                control={control}
                defaultValue=""
                rules={{
                  maxLength: {
                    value: 2000,
                    message: characterValidation({ limit: 2000 }),
                  },
                }}
                render={({ field }) => (
                  <RichTextEditor
                    id="about"
                    content={field.value}
                    onChange={(content) => field.onChange(content)}
                    placeholder="Write something about yourself"
                    fieldError={!!errors.about?.message}
                  />
                )}
              />

              {errors.about && (
                <p className="mt-0 text-sm text-danger-dark">
                  {errors.about.message}
                </p>
              )}
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

BasicInfo.defaultProps = {
  reviewProfile: false,
};

BasicInfo.propTypes = {
  jobApplicationId: PropTypes.string,
  reviewProfile: PropTypes.bool,
  currentUser: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      portfolio_url: PropTypes.string,
      about: PropTypes.string,
      date_of_birth: PropTypes.string,
      is_profile_completed: PropTypes.bool,
      photo_url: PropTypes.string,
      reference_images: PropTypes.array,
      avatar: PropTypes.shape({
        thumb: PropTypes.string,
        original: PropTypes.string,
      }),
    }),
  }),
};

export default BasicInfo;
