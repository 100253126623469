import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import classNames from "classnames";
import PropTypes from "prop-types";

import { PER_PAGE_RECORDS } from "app/constants";

const Pagination = ({
  pageName = "",
  currentPage,
  totalPage,
  setCurrentPage,
  totalEntries,
  pageSize,
}) => {
  const PADDING_CLASSES =
    "h-5 w-5 xl:h-8 xl:w-8 rounded-full xl:mx-1 flex justify-center items-center";
  const TEXT_SIZE_CLASSES = "font-semibold text-sm md:text-md";
  const COLOR_CLASSES = "text-primary-main";
  const firstUpdate = useRef(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [perPage, setPerPage] = useState();
  const [goToPage, setGoToPage] = useState(currentPage || "");

  useEffect(() => {
    setPerPage();
    setGoToPage("");
  }, [pageName]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setCurrentPage(
        Math.ceil(totalEntries / perPage) >= currentPage ? currentPage : 1,
        perPage
      );
    }
  }, [perPage]);

  const setCurrentPageValue = ({ selected }) => {
    const page = selected + 1;
    setGoToPage("");
    sessionStorage.removeItem(`page:${currentPage}:PositionX`);
    sessionStorage.removeItem(`page:${currentPage}:PositionY`);
    sessionStorage.setItem(`${pageName}Page`, page);
    setCurrentPage(page, perPage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPageValue({ selected: e.target[0].value - 1 });
  };

  const handleFormChange = (e) => {
    setGoToPage(e.target.value);
  };

  const handleChange = (e) => {
    setGoToPage("");
    setPerPage(e.value);
  };

  return (
    <div className="mt-3 mx-1 bg-white border border-primary rounded-3xl py-2 flex items-center justify-between lg:px-5 px-5 md:flex-row flex-col">
      <div className="text-gray-darker text-md flex items-center justify-self-end mt-4 sm:mt-0">
        <span className="pr-4 md:pr-1 xl:pr-3 text-sm"> Entries per page </span>
        <Select
          options={PER_PAGE_RECORDS}
          onChange={(values) => handleChange(values)}
          className="w-22 z-10"
          menuPlacement="top"
          placeholder=" "
          defaultValue={{ value: 20, label: "20" }}
          value={{ value: pageSize, label: `${pageSize}` }}
        />
      </div>

      <ReactPaginate
        previousLabel="&lt; Prev"
        nextLabel="Next &gt;"
        onPageChange={setCurrentPageValue}
        forcePage={currentPage - 1}
        pageRangeDisplayed={2}
        marginPagesDisplayed={windowWidth >= 1200 ? 2 : 1}
        pageCount={totalPage}
        containerClassName="flex flex-wrap justify-center items-center py-2"
        pageLinkClassName={classNames(
          PADDING_CLASSES,
          TEXT_SIZE_CLASSES,
          COLOR_CLASSES,
          "rounded-3xl hover:outline hover:outline-1 hover:outline-primary-main leading-10"
        )}
        previousLinkClassName={classNames(
          TEXT_SIZE_CLASSES,
          COLOR_CLASSES,
          "px-3 hover:underline"
        )}
        nextLinkClassName={classNames(
          TEXT_SIZE_CLASSES,
          COLOR_CLASSES,
          "px-3 hover:underline"
        )}
        activeLinkClassName="bg-primary-main text-white hover:outline-0"
        breakLabel=".&nbsp;.&nbsp;."
        breakLinkClassName={classNames(
          PADDING_CLASSES,
          TEXT_SIZE_CLASSES,
          COLOR_CLASSES,
          "hover:underline"
        )}
        disabledLinkClassName="aria-disabled:text-gray-400 cursor-default hover:no-underline"
        renderOnZeroPageCount={null}
      />

      <div className="text-gray-darker flex items-center justify-self-end">
        <span className="pr-4 md:pr-1 xl:pr-3 text-sm"> Go to page </span>
        <form onSubmit={handleSubmit}>
          <input
            type="number"
            min={1}
            max={totalPage}
            onChange={handleFormChange}
            value={goToPage}
            className="text-center px-1 w-14 h-9 border-2 border-hsl(0, 0%, 80%) bg-hsl(0, 0%, 100%) rounded-[10%] outline-primary-main text-md"
          />

          <button
            title="Go"
            type="submit"
            className="px-3 py-1 ml-4 md:ml-1 xl:ml-3 btn-primary"
            disabled={!goToPage}
          >
            Go
          </button>
        </form>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalEntries: PropTypes.number,
  pageSize: PropTypes.number,
  pageName: PropTypes.string,
};

export default Pagination;
