import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { withOneTabEnforcer } from "react-one-tab-enforcer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { uniqueId } from "lodash";

import { createExamCandidate } from "api/candidate/examApi";
import { QUESTION_TYPE } from "app/constants";
import logo from "assets/images/logo/joballey_logo_light_text.png";
import { Button } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import Alert from "components/common/Alert";
import { convertSecondsToTime, SITE_TITLE } from "constants/common";
import {
  doNotInstructions,
  generalExamInstructions,
  systemRequirementsInstructions,
} from "constants/examInstruction";
import DifferentWarningComponent from "constants/singleTabEnforcerWarning";
import navigatorPermission from "helpers/navigatorPermission";

import { InfoBox } from "./infoBox";
import { ListItem } from "./listItem";

const ExamInstructions = () => {
  const [searchParams] = useSearchParams();
  const videoRef = useRef(null);
  const examId = searchParams.get("id");
  const navigate = useNavigate();
  const exam = JSON.parse(localStorage.getItem("exam"));

  const [checked, setChecked] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertTriggered, setIsAlertTriggered] = useState(false);

  const generalExamInstructionsData = generalExamInstructions();

  const systemExamInstructionsData = systemRequirementsInstructions();

  const doNotInstructionsData = doNotInstructions(exam);

  const handleChange = () => setChecked((prevState) => !prevState);

  const createExamCandidateHandle = () => {
    return createExamCandidate(examId)
      .then((response) => {
        if (exam.mock_test_type) {
          navigate(`/question_paper?id=${response.data.id}&&mock_test=true`);
        } else {
          navigate(`/question_paper?id=${response.data.id}`);
        }
        localStorage.removeItem("exam");
      })
      .catch(() => {
        setAlertMessage(
          "Either your slot duration is over or you have already appeared for this exam."
        );
        setIsAlertTriggered(true);
        setTimeout(() => navigate(`/`), 10000);
      });
  };

  const handleStartExam = () => {
    if (exam.camera_option === "REQUIRED") {
      if (checked && isCameraOn) {
        createExamCandidateHandle();
      }
    } else {
      createExamCandidateHandle();
    }
  };

  const checkCamera = () => {
    if (navigator.mediaDevices?.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (stream.id !== "" || stream.getTracks().length > 0) {
            videoRef.current.srcObject = stream;
            setIsCameraOn(true);
          }
        })
        .catch(() => setIsCameraOn(false));
    } else {
      setIsCameraOn(false);
    }
  };

  useEffect(() => {
    document.title = `${exam.name} - ${SITE_TITLE}`;
  }, []);

  useLayoutEffect(() => {
    if (exam.camera_option === "REQUIRED") {
      if (videoRef.current) {
        checkCamera();
        navigator.mediaDevices.ondevicechange = checkCamera;
      }

      return () =>
        videoRef.current?.srcObject?.getTracks().map((track) => {
          track.enabled = false; // TODO: we have to return anything from map, so whoever works in this file please fix this issue
          track.stop();
        });
    }
  }, [isCameraOn]);

  useEffect(() => {
    if (navigator.userAgent.match(/firefox|fxios/i)) {
      setIsCameraOn(true);
    } else {
      navigatorPermission(setIsCameraOn);
    }
  }, []);

  const getStartButtonType = () => {
    if (exam.camera_option === "REQUIRED") {
      if (checked && isCameraOn) {
        return "primary";
      }
      return "disabled";
    }
    if (checked) {
      return "primary";
    }
    return "disabled";
  };

  return (
    <>
      <div
        className="sticky top-0 z-10 mb-6
                    flex items-center justify-between bg-primary-main p-5 font-medium text-white"
      >
        <img className="ml-3 pb-1" width={168} src={logo} alt="logo" />

        <h1 className="break-words pl-4 text-center text-xl sm:px-4">
          {exam.name || ""}
        </h1>
        <div className="hidden flex-col justify-center gap-1 text-base sm:flex">
          <span>
            Duration:&nbsp;
            {(exam.duration && convertSecondsToTime(exam.duration)) || ""}
          </span>
          <span>Total Marks: {exam.total_marks || ""}</span>
        </div>
      </div>
      <div className="text-roboto container mx-auto px-4">
        <ActionAlert
          message={
            <div className="text-base">
              Please read the instructions given below carefully and&nbsp;
              <b>tick the checkbox&nbsp;</b>
              to start the exam.
            </div>
          }
          alertType="primary"
          isButtonRequire={false}
        />

        {/Mobi|Android/i.test(window.navigator.userAgent) && (
          <ActionAlert
            message="For the best bug-free experience, we recommend appearing for the
            exam using a proper desktop/laptop."
            alertType="primary"
            iconName="info"
            isButtonRequire={false}
          />
        )}
        {exam.camera_option === "REQUIRED" && !isCameraOn && (
          <InfoBox isCameraOn={!isCameraOn} />
        )}
      </div>
      <div className="text-roboto container mx-auto flex flex-col-reverse justify-start px-6 md:flex-row">
        <div className="w-full">
          <div className="mt-10 text-justify text-base font-semibold text-primary-main sm:mx-1 md:mx-2">
            <h4>General Instructions:</h4>
            <ListItem name={generalExamInstructionsData} />

            <h4>System Requirements:</h4>
            <ListItem name={systemExamInstructionsData} />

            <h4>Don&apos;ts:</h4>
            <ListItem name={doNotInstructionsData} />

            <h4>Exam Details:</h4>
            <ol
              className="text-roboto mb-6 list-decimal pl-4 text-sm
                         font-normal leading-7 text-black-primary"
            >
              {exam.exam_type === QUESTION_TYPE[0].name && (
                <div>
                  <li key={`${uniqueId}1`}>
                    <span>
                      ¼ Marks of the assigned marks will be deducted in case of
                      wrong answer.
                    </span>
                  </li>
                  <li key={`${uniqueId}2`}>
                    <span>
                      You have to clear the{" "}
                      {exam.total_sections
                        ? "sectional cutoff in each section"
                        : "overall cutoff"}
                      , to pass the exam.
                    </span>
                  </li>
                </div>
              )}
              <li key={`${uniqueId}3`}>
                <span>
                  Maximum Duration:{" "}
                  {(exam.duration && convertSecondsToTime(exam.duration)) || ""}{" "}
                  &nbsp;|&nbsp; Total Marks: {exam.total_marks || ""} &nbsp;
                  {exam.total_sections &&
                    ` | No. of Sections: ${exam.total_sections || ""}`}{" "}
                  &nbsp;|&nbsp; Total No. of Questions: {exam.total_questions}
                </span>
              </li>
            </ol>

            <label
              htmlFor="accept-instruction"
              className="mb-10 flex items-center justify-start gap-2 text-sm"
            >
              <input
                data-testid="check-exam-instruction"
                type="checkbox"
                checked={checked}
                id="accept-instruction"
                onChange={handleChange}
              />
              <span className="text-sm font-normal text-black-primary">
                I have read and understood the instructions.{" "}
              </span>
            </label>

            <div className="mb-4 flex justify-center">
              <div
                data-tooltip-content={
                  // eslint-disable-next-line no-nested-ternary
                  exam.camera_option === "REQUIRED" // TODO: whoever pics up please resolve this with a simple if else function
                    ? (!checked && !isCameraOn
                        ? "Allow camera permission & Tick the checkbox"
                        : "") ||
                      (!checked ? "Tick the checkbox " : "") ||
                      (!isCameraOn ? "Allow camera permission " : "")
                    : !checked
                    ? "Tick the checkbox "
                    : ""
                }
                data-tooltip-id="instructionBox"
              >
                <Button
                  btnName="Start"
                  title="Start"
                  btnClassName="w-24 py-2 rounded-full"
                  size="md"
                  testId="start-exam-btn"
                  type={getStartButtonType()}
                  onClick={handleStartExam}
                />
              </div>
            </div>
          </div>
        </div>
        {exam.camera_option === "REQUIRED" && (
          <div className="w-96 self-center pb-2 md:sticky md:bottom-0 md:self-end md:pl-4">
            <video
              autoPlay
              muted
              onPause={() => setIsCameraOn(false)}
              ref={videoRef}
            />
          </div>
        )}
      </div>

      {isAlertTriggered && (
        <Alert
          type="warning"
          isAlertTriggered={isAlertTriggered}
          setIsAlertTriggered={setIsAlertTriggered}
          message={alertMessage}
        />
      )}

      <Tooltip id="instructionBox" variant="dark" />
    </>
  );
};

export default withOneTabEnforcer({
  appName: "Unique-examInstructionPage",
  OnlyOneTabComponent: DifferentWarningComponent,
  localStorageTimeout: 15 * 1000,
  localStorageResetInterval: 10 * 1000,
})(ExamInstructions);
