import { Controller } from "react-hook-form";
import Select from "react-select";
import PropTypes from "prop-types";

import { Modal } from "components/molecules";

const FilterModal = ({
  setIsOpen,
  filterField,
  submitHandler,
  useFormProps,
  handleClearFilter,
}) => {
  const { control, handleSubmit } = useFormProps;

  return (
    <Modal
      title="Filter"
      onOutsideClickHandler={() => setIsOpen(false)}
      acceptButtonText="Apply Filter"
      acceptBtnType="primary"
      rejectButtonText="Clear Filter"
      rejectBtnType="secondary"
      handleReject={() => {
        handleClearFilter();
        setIsOpen(false);
      }}
      handleCancel={() => setIsOpen(false)}
      handleAccept={handleSubmit((data) => submitHandler(data))}
      customButtonWidth={36}
    >
      <form className="mb-[90px]">
        {filterField.map((details) => (
          <div key={details.name}>
            <label
              htmlFor={details.label}
              className="mt-2 mb-1 block font-medium"
            >
              {details.label}
            </label>

            <Controller
              name={details?.name}
              control={control}
              render={({ field }) => (
                <Select
                  name={details?.name}
                  options={details.dropdownOptions}
                  isMulti={details.multiSelect}
                  isClearable
                  id="dropdown-options"
                  menuPlacement="auto"
                  maxMenuHeight={120}
                  className="mb-10"
                  placeholder={`Select ${details?.placeholder}`}
                  value={field.value}
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
          </div>
        ))}
      </form>
    </Modal>
  );
};

const filterFieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool.isRequired,
});

FilterModal.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  filterField: PropTypes.arrayOf(filterFieldShape).isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  useFormProps: PropTypes.shape({
    handleSubmit: PropTypes.func.isRequired,
    control: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
export default FilterModal;
