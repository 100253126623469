import { useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { HiFilter } from "react-icons/hi";
import { RiArrowDropDownLine } from "react-icons/ri";
import PropTypes from "prop-types";

import { Button } from "components/atoms";

import FilterModal from "./FilterModal";

const SearchBarFilterButton = ({
  updateQueryParameters,
  anyFilterApplied,
  handleClearFilter,
  resetFilterForm,
  useFormProps,
  filterField,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFilterModal = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const submitHandler = (data) => {
    const filteredData = {};

    filterField.forEach((details) => {
      const selectedOptions = data[details.name];

      if (selectedOptions && selectedOptions !== null) {
        filteredData[details.name] = Array.isArray(selectedOptions)
          ? selectedOptions.map((option) => option.value)
          : selectedOptions.value;
      } else {
        filteredData[details.name] = selectedOptions?.value;
      }
    });

    updateQueryParameters(filteredData);

    setIsOpen(false);
  };

  useEffect(() => {
    resetFilterForm();
  }, [isOpen]);

  return (
    <>
      <div className="relative">
        <Button
          id="dropdown-button"
          onClick={toggleFilterModal}
          btnClassName="flex-shrink-0 z-10 inline-flex items-center py-2 px-1 text-sm font-medium text-center border-r-0 rounded-l-lg border border-primary-main bg-white h-10"
          data-testid="filter-button"
          title="Filter Button"
        >
          {anyFilterApplied() ? (
            <>
              <HiFilter className="ml-1 text-xl text-primary-dark" />
              <span className="filter-dot absolute left-[1.4rem] bottom-[1.6rem]" />
            </>
          ) : (
            <BiFilterAlt className="ml-1 text-xl text-primary-dark" />
          )}

          <span className="ml-1 hidden sm:block">Filter</span>
          <RiArrowDropDownLine className="w-5 h-5 " />
        </Button>
      </div>

      {isOpen && (
        <FilterModal
          filterField={filterField}
          submitHandler={submitHandler}
          setIsOpen={setIsOpen}
          useFormProps={useFormProps}
          handleClearFilter={handleClearFilter}
        />
      )}
    </>
  );
};

const filterFieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool.isRequired,
});

SearchBarFilterButton.propTypes = {
  anyFilterApplied: PropTypes.func.isRequired,
  updateQueryParameters: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  resetFilterForm: PropTypes.func.isRequired,
  useFormProps: PropTypes.shape({
    reset: PropTypes.func.isRequired,
  }).isRequired,
  filterField: PropTypes.arrayOf(filterFieldShape).isRequired,
};

export default SearchBarFilterButton;
