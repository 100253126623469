import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addCandidateSkill,
  getCandidateSkills,
  removeCandidateSkill,
  updateCandidateSkill,
} from "api/candidate/profileApi";

export const getCandidateSkillsAction = createAsyncThunk(
  "skill/get/candidate-skills",
  async () => {
    const response = await getCandidateSkills();
    return response.data;
  }
);

export const createCandidateSkillsAction = createAsyncThunk(
  "skill/create/candidate-skills",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addCandidateSkill(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateCandidateSkillsAction = createAsyncThunk(
  "skill/update/candidate-skills",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateCandidateSkill(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCandidateSkillsAction = createAsyncThunk(
  "skill/delete/candidate-skills",
  async (skill, { rejectWithValue }) => {
    try {
      const response = await removeCandidateSkill(skill.id);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
