import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import ActivityIndicator from "components/common/ActivityIndicator";

const CandidateStatusCard = ({
  isLoading,
  statusCounts,
  anyFilterApplied,
  currentStageType,
}) => {
  const [searchParams] = useSearchParams();
  const GRID_CLASS =
    "xs:grid xs:grid-flow-col xs:grid-rows-3 md:grid-rows-4 xl:grid-rows-3 xs:gap-x-6 sm:gap-x-16 md:gap-x-8 2xl:gap-x-16 xs:justify-start";

  const generateFilterCriterias = () => {
    const filterCriterias = [];

    if (searchParams.has("candidate_name")) {
      filterCriterias.push("Name");
    }
    if (searchParams.has("candidate_id")) {
      filterCriterias.push("Id");
    }

    if (searchParams.has("cutoff_marks")) {
      if (["interview", "gd"].includes(currentStageType)) {
        filterCriterias.push("Rating");
      } else {
        filterCriterias.push("Marks");
      }
    }

    if (searchParams.has("academic_marks")) {
      filterCriterias.push("Marks");
    }

    if (searchParams.has("statuses")) {
      filterCriterias.push("Status");
    }
    if (searchParams.has("locations")) {
      filterCriterias.push("Current Location");
    }
    if (searchParams.has("groups")) {
      filterCriterias.push("Groups");
    }
    if (searchParams.has("willing_to_relocate")) {
      if (searchParams.get("willing_to_relocate") === "true") {
        filterCriterias.push("Willing to relocate");
      } else {
        filterCriterias.push("Unwilling to relocate");
      }
    }
    if (searchParams.has("has_portfolio")) {
      if (searchParams.get("has_portfolio") === "true") {
        filterCriterias.push("Portfolio link present");
      } else {
        filterCriterias.push("Portfolio link absent");
      }
    }
    if (searchParams.has("project")) {
      filterCriterias.push("Projects");
    }

    if (searchParams.get("has_different_sectional_cut_off_marks") === "true") {
      filterCriterias.push("Sectional Cut Off Marks");
    }

    if (searchParams.has("from_date")) {
      filterCriterias.push("Date and Time");
    }

    return filterCriterias.join(" | ");
  };

  return (
    <div className="mb-5 mt-2 w-full h-[fit-content] rounded-lg border border-sky-200 bg-white px-2 py-3 shadow-lg md:w-[49%]">
      {isLoading ? (
        <div className="my-8">
          <ActivityIndicator
            containerClass="mt-2"
            svgClassName="w-100%"
            size={12}
          />
        </div>
      ) : (
        <>
          <div>
            <p className="text-lg font-medium text-gray-700">
              Total: {statusCounts?.total_entries || 0}
            </p>

            {anyFilterApplied && (
              <p className="my-2 text-sm text-primary-main">
                <span className="mr-1"> Filtered by: </span>

                <span>{generateFilterCriterias()}</span>
              </p>
            )}
          </div>

          <div className="mt-2 border-t-2 pt-2 text-gray-500">
            {searchParams.has("stageId") && (
              <div className={classNames(GRID_CLASS, "text-md pb-5")}>
                <div className="mb-1 flex justify-between">
                  <span> Uncategorized: </span>

                  <span className="ml-5">{statusCounts?.uncategorized}</span>
                </div>

                <div className="mb-1 flex justify-between">
                  <span> Absent: </span>

                  <span className="ml-5">{statusCounts?.absent}</span>
                </div>

                <div className="mb-1 flex justify-between">
                  <span> Approved: </span>

                  <span className="ml-5">{statusCounts?.approved}</span>
                </div>

                <div className="mb-1 flex justify-between">
                  <span> On Hold: </span>

                  <span className="ml-5">{statusCounts?.on_hold}</span>
                </div>

                <div className="mb-1 flex justify-between">
                  <span> Placed: </span>

                  <span className="ml-5">{statusCounts?.placed}</span>
                </div>

                <div className="mb-1 flex justify-between">
                  <span> Rejected: </span>

                  <span className="ml-5">{statusCounts?.rejected}</span>
                </div>

                {currentStageType === "exam" && (
                  <div className="mb-1 flex justify-between">
                    <span> Disqualified: </span>

                    <span className="ml-5">{statusCounts?.disqualified}</span>
                  </div>
                )}

                <div className="mb-1 flex justify-between">
                  <span> Withdrew: </span>

                  <span className="ml-5">{statusCounts?.withdrew}</span>
                </div>
              </div>
            )}

            {searchParams.get("lobby") === "PLACED" && (
              <div className="text-md flex flex-col flex-wrap pt-1 xs:pr-32 sm:flex-row sm:space-x-6 sm:pr-0 md:flex-col md:space-x-0 md:pr-32 3xl:flex-row 3xl:space-x-6 3xl:pr-16">
                <div className="mb-1 flex justify-between ">
                  <span> LOI Sent: </span>
                  <span className="ml-5">{statusCounts?.loi_sent}</span>
                </div>
                <div className="mb-1 flex justify-between ">
                  <span> LOI Pending: </span>
                  <span className="ml-5">{statusCounts?.loi_pending}</span>
                </div>
                <div className="mb-1 flex justify-between ">
                  <span> Accepted: </span>
                  <span className="ml-4">{statusCounts?.accepted}</span>
                </div>
                <div className="mb-1 flex justify-between pb-5">
                  <span> Withdrew: </span>
                  <span className="ml-5">{statusCounts?.withdrew}</span>
                </div>
              </div>
            )}

            {searchParams.get("lobby") === "ALL_APPLICANTS" && (
              <div className="text-md flex flex-col flex-wrap pt-1 xs:pr-32 sm:flex-row sm:space-x-6 sm:pr-0 md:flex-col md:space-x-0 md:pr-32 3xl:flex-row 3xl:space-x-6 3xl:pr-16">
                <div className="mb-1 flex justify-between ">
                  <span> Shortlisted: </span>
                  <span className="ml-5">{statusCounts?.shortlisted}</span>
                </div>
                <div className="mb-1 flex justify-between ">
                  <span> On Hold: </span>
                  <span className="ml-5">{statusCounts?.on_hold}</span>
                </div>
                <div className="mb-1 flex justify-between ">
                  <span> Rejected: </span>
                  <span className="ml-5">{statusCounts?.rejected}</span>
                </div>
                <div className="mb-1 flex justify-between pb-5">
                  <span> Uncategorized: </span>
                  <span className="ml-5">{statusCounts?.uncategorized}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CandidateStatusCard.defaultTypes = {
  anyFilterApplied: false,
  currentStageType: "exam",
};

CandidateStatusCard.propTypes = {
  statusCounts: PropTypes.shape({
    total_entries: PropTypes.number,
    approved: PropTypes.number,
    rejected: PropTypes.number,
    uncategorized: PropTypes.number,
    on_hold: PropTypes.number,
    withdrew: PropTypes.number,
    placed: PropTypes.number,
    absent: PropTypes.number,
    loi_sent: PropTypes.number,
    loi_pending: PropTypes.number,
    accepted: PropTypes.number,
    shortlisted: PropTypes.number,
    disqualified: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  anyFilterApplied: PropTypes.bool,
  currentStageType: PropTypes.oneOf(["exam", "interview", "gd"]),
};

export default CandidateStatusCard;
