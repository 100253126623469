import { createSlice } from "@reduxjs/toolkit";

import {
  createCandidateLanguagesAction,
  deleteCandidateLanguagesAction,
  getCandidateLanguagesAction,
  updateCandidateLanguagesAction,
} from "store/thunkActions/candidate/languageThunk";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    candidateLanguages: [],
  },
  reducers: {},
  extraReducers: {
    [getCandidateLanguagesAction.fulfilled]: (state, action) => {
      state.candidateLanguages = action.payload;
    },
    [createCandidateLanguagesAction.fulfilled]: (state, action) => {
      state.candidateLanguages.push(action.payload.data);
    },
    [updateCandidateLanguagesAction.fulfilled]: (state, action) => {
      const index = state.candidateLanguages.findIndex(
        (candidateLanguage) => candidateLanguage.id === action.payload.data.id
      );
      state.candidateLanguages[index] = action.payload.data;
    },
    [deleteCandidateLanguagesAction.fulfilled]: (state, action) => {
      state.candidateLanguages = state.candidateLanguages.filter(
        (s) => s.id != action.payload.data.id
      );
    },
  },
});

export default languageSlice.reducer;
