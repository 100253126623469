import Proptypes from "prop-types";

import RenderParsedHTML from "../RenderParsedHTML";

const DetailBox = ({ title, description, testId }) => (
  <div className="my-3">
    {description && (
      <div data-testid={testId}>
        {title && (
          <h5 className="text-md font-semibold" id="detail-box-title">
            {title}
          </h5>
        )}
        <div className="text-justify" id="detail-box-descripton">
          <RenderParsedHTML content={description || ""} />{" "}
        </div>
      </div>
    )}
  </div>
);

DetailBox.defaultTypes = {
  title: "",
  description: "",
  testId: "generic-detail-box",
};

DetailBox.propTypes = {
  title: Proptypes.string,
  description: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  testId: Proptypes.string,
};

export default DetailBox;
