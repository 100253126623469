import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BiTrash } from "react-icons/bi";
import { MdDragIndicator, MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ALERT_TYPE, STAGE_TYPE, SUGGESTED_BULK_STAGES } from "app/constants";
import { Button } from "components/atoms";
import ActionAlert from "components/atoms/ActionAlert";
import CardContainer from "components/candidate/Profile/Common/CardContainer";
import { Modal } from "components/molecules";
import useAlert from "hooks/useAlert";
import { createStageAction } from "store/thunkActions/erp/stagesThunk";

import BulkStageForm from "./BulkStageForm";

const BulkStageModal = ({ setOpenBulkStageModal, setCurrentStageLobby }) => {
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const params = useParams();

  const [stageDetails, setStageDetails] = useState(SUGGESTED_BULK_STAGES);

  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentStage, setCurrentStage] = useState({});
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    if (!isEditing) {
      setCurrentStage({});
    }
  }, [isEditing]);

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isAdding]);

  useEffect(() => {
    const isDifferent =
      stageDetails?.length !== 3
        ? true
        : stageDetails?.some((stage, index) => {
            return (
              stage.name !== SUGGESTED_BULK_STAGES[index].name ||
              stage.stage_type !== SUGGESTED_BULK_STAGES[index].stage_type ||
              stage.sequence !== SUGGESTED_BULK_STAGES[index].sequence
            );
          });

    // Show alert if the stage values change
    if (isDifferent) {
      setIsUnsavedChanges(true);
    } else {
      setIsUnsavedChanges(false);
    }
  }, [stageDetails]);

  const handleAddNewStage = () => {
    setIsAdding(true);
  };

  const handleEdit = (stage) => {
    setIsEditing(true);
    setCurrentStage(stage);
  };

  const submitHandler = () => {
    const action = createStageAction({
      jobUuid: params.uuid,
      data: { stages: [...stageDetails] },
    });

    return dispatch(action)
      .unwrap()
      .then((response) => {
        showAlert(
          ALERT_TYPE[response.meta.message_type],
          response.meta.message
        );

        setOpenBulkStageModal(false);
        setCurrentStageLobby(response.data[0].id);
      })
      .catch(({ errors, message }) => {
        if (message) {
          showAlert("danger", message);
        } else if (Object.keys(errors)[0] && Object.values(errors)[0]) {
          showAlert(
            "danger",
            `${Object.keys(errors)[0]} ${Object.values(errors)[0]}`
          );
        }
      });
  };

  const updateSequenceWithIndex = (allStages) => {
    const reOrderedStages = allStages.map((stage, index) => {
      return {
        ...stage,
        sequence: index + 1,
      };
    });
    return reOrderedStages;
  };

  const handleDelete = (stage) => {
    setCurrentStage(stage);
    setIsDeleting(true);
  };

  const handleDeleteConfirm = () => {
    const allStages = stageDetails.filter(
      (stage) => stage.sequence !== currentStage?.sequence
    );

    setStageDetails(updateSequenceWithIndex(allStages));
    setIsDeleting(false);
  };

  const handleDragEnd = (result) => {
    if (
      result.destination?.index !== result.source?.index &&
      result.destination !== null
    ) {
      const sourceIndex = result.source?.index;
      const destinationIndex = result.destination?.index;

      const reorderedRows = [...stageDetails];

      const [movedRow] = reorderedRows.splice(sourceIndex, 1);
      reorderedRows.splice(destinationIndex, 0, movedRow);

      setStageDetails(updateSequenceWithIndex(reorderedRows));
    }
  };

  return (
    <div>
      <Modal
        title="Create Stages"
        customModalWidth="540px"
        modalType="form"
        scrollClass="overflow-y-auto max-h-[65vh]"
        isDefaultScrolling={false}
        handleCancel={() => setOpenBulkStageModal(false)}
        setOpen={setOpenBulkStageModal}
        testId="add-and-edit-stage-modal"
        size="sm"
        acceptButtonText="Proceed"
        acceptBtnType={
          isAdding || isEditing || stageDetails?.length === 0
            ? "disabled"
            : "primary"
        }
        acceptBtnSize="smResponsive"
        handleAccept={submitHandler}
        isDirty={isEditing || isAdding || isUnsavedChanges}
      >
        <div>
          <ActionAlert
            message={
              <p className="text-sm font-semibold">
                You can drag and drop the stages to change their order.
              </p>
            }
            alertType="info"
            iconName="info"
            isButtonRequire={false}
            customClassName="border-none py-0"
            iconSize={20}
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="drag-drop">
              {(provided) => (
                <div
                  className="drag-drop"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <CardContainer
                    addEventButtonTitle="Add"
                    addEventButtonTestId="add-interests-btn"
                    customContainerClass="mx-auto w-full"
                    customChildrenClass="rounded-lg"
                    hideAddButton
                  >
                    {stageDetails?.map((stage, index) => {
                      if (
                        currentStage?.sequence === stage.sequence &&
                        isEditing
                      ) {
                        return (
                          <BulkStageForm
                            key={stage.sequence}
                            currentStage={currentStage}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            stageDetails={stageDetails}
                            setStageDetails={setStageDetails}
                          />
                        );
                      }
                      return (
                        <Draggable
                          key={stage.sequence}
                          draggableId={stage.sequence.toString()}
                          index={index}
                          isDragDisabled={isEditing || isAdding} // Disable drag-and-drop while adding/updating
                        >
                          {(draggableProvided, snapshot) => (
                            <div
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                                backgroundColor: snapshot.isDragging
                                  ? "#f0f0f0"
                                  : "",
                              }}
                              ref={draggableProvided.innerRef}
                              className="hover:bg-gray-lighter first:border-t border-b border-gray-lighterShade2 pl-2 py-2 rounded-sm"
                            >
                              <div className="mb-1 flex justify-between align-middle">
                                <div className="flex gap-2 text-primary-dark max-w-[70%]">
                                  <span className="self-start mt-2">
                                    <MdDragIndicator
                                      className="opacity-[75%]"
                                      title="Drag-Drop"
                                    />
                                  </span>
                                  <h4 className="max-w-full text-lg capitalize self-center break-words">
                                    {stage.name}
                                  </h4>
                                </div>
                                <div className="flex gap-3">
                                  <MdOutlineModeEditOutline
                                    onClick={() =>
                                      !isAdding && handleEdit(stage)
                                    }
                                    data-testid="rename-stage"
                                    title="Rename Stage"
                                    className={classNames(
                                      "btn-icon-secondary text-lg hover:cursor-pointer",
                                      { "opacity-20": isAdding }
                                    )}
                                  />
                                  <BiTrash
                                    onClick={() =>
                                      !isEditing &&
                                      !isAdding &&
                                      handleDelete(stage)
                                    }
                                    title="Delete Stage"
                                    data-testid="delete-stage"
                                    className={classNames(
                                      "btn-icon-danger text-lg hover:cursor-pointer",
                                      { "opacity-20": isEditing || isAdding }
                                    )}
                                  />
                                </div>
                              </div>
                              <span className="text-md pb-1 capitalize pl-6">
                                {stage.stage_type === STAGE_TYPE[1].value
                                  ? STAGE_TYPE[1].label
                                  : stage.stage_type}
                              </span>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </CardContainer>
                  {provided.placeholder}
                  {!isAdding && !isEditing && (
                    <Button
                      title="Add Stage"
                      type={isAdding ? "hidden" : "tertiary"}
                      btnClassName="text-primary-main font-medium hover:font-semibold text-md px-1 pt-4 pb-2"
                      data-testid="add-stage-btn"
                      className="z-10 rounded-full bg-primary-main p-1 hover:bg-primary-light"
                      onClick={handleAddNewStage}
                      buttonType="button"
                    >
                      + Add Stage
                    </Button>
                  )}
                  {isAdding && (
                    <div id="add-stage-form" ref={targetRef}>
                      <BulkStageForm
                        setStageDetails={setStageDetails}
                        stageDetails={stageDetails}
                        isAdding={isAdding}
                        setIsAdding={setIsAdding}
                      />
                    </div>
                  )}
                  {isDeleting && (
                    <Modal
                      title="Confirm Delete"
                      description="Are you sure you want to delete this stage?"
                      acceptButtonText="Yes"
                      acceptBtnType="primary"
                      rejectButtonText="No"
                      rejectBtnType="secondary"
                      customButtonWidth={32}
                      handleAccept={handleDeleteConfirm}
                      handleReject={() => setIsDeleting(false)}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>
    </div>
  );
};

BulkStageModal.propTypes = {
  setOpenBulkStageModal: PropTypes.func,
  setCurrentStageLobby: PropTypes.func,
};

export default BulkStageModal;
