import { ImRadioChecked, ImRadioUnchecked } from "react-icons/im";
import classNames from "classnames";
import PropTypes from "prop-types";

import { PROFICIENCY_LEVELS } from "app/constants";

const VisualRating = ({ fillUpMarker }) => {
  return (
    <div className="flex ml-4 sm:pt-6 break-normal">
      {PROFICIENCY_LEVELS.map((level, index) => {
        return (
          <div key={index}>
            <div className="flex flex-col items-center justify-center gap-1">
              <span
                className={classNames(
                  index <= fillUpMarker
                    ? "text-primary-main"
                    : "text-gray-lighterShade2"
                )}
              >
                {index <= fillUpMarker && <ImRadioChecked />}
                {index > fillUpMarker && <ImRadioUnchecked />}
              </span>

              <div className="text-[9px] w-16 text-center">
                {index === fillUpMarker ? level.label : ""}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

VisualRating.propTypes = {
  fillUpMarker: PropTypes.number,
};

export default VisualRating;
