import { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import { PROFICIENCY_LEVELS } from "app/constants";
import VisualRating from "components/atoms/VisualRating";
import Modal from "components/molecules/Modal";
import { deleteCandidateSkillsAction } from "store/thunkActions/candidate/skillThunk";
import { setCandidateUserAction } from "store/thunkActions/currentUserThunk";

import Form from "./Form";

const Skill = ({ candidateSkill }) => {
  const [isEditState, setIsEditState] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  const onDelete = () => {
    return dispatch(deleteCandidateSkillsAction(candidateSkill)).then(() => {
      dispatch(setCandidateUserAction());
    });
  };
  const fillUpMarker = PROFICIENCY_LEVELS.findIndex(
    (item) => item.value === candidateSkill.proficiency
  );

  return isEditState ? (
    <Form candidateSkill={candidateSkill} setIsEditState={setIsEditState} />
  ) : (
    <>
      <div className="flex items-center justify-between ">
        <div
          className={classNames(
            "text-md flex flex-col gap-3 pt-2 pr-2 sm:flex-row sm:pt-0 sm:pr-0 sm:gap-0 font-normal w-full sm:items-center leading-5 overflow-y-hidden sm:overflow-x-hidden",
            { "overflow-x-auto": fillUpMarker >= 0 }
          )}
        >
          <div
            className={classNames("break-words", {
              "sm:w-[30%]": fillUpMarker >= 0,
            })}
          >
            {candidateSkill.skill.name}
          </div>
          {fillUpMarker >= 0 && <VisualRating fillUpMarker={fillUpMarker} />}
        </div>
        <div className="flex mb-12 sm:mb-0">
          <button
            title="Edit Skill"
            data-testid="edit-skill-btn"
            className="btn-icon-secondary"
            onClick={() => setIsEditState(true)}
          >
            <MdOutlineModeEditOutline className="text-lg" />
          </button>
          <button
            title="Delete SKill"
            data-testid="delete-skill-btn"
            className="btn-icon-danger"
            onClick={() => setIsDeleting(true)}
          >
            <BiTrash className="text-lg" />
          </button>
        </div>
      </div>

      {isDeleting && (
        <Modal
          title="Confirm Delete"
          description="This will delete this skill from your profile."
          acceptButtonText="OK, Delete"
          acceptBtnType="primary"
          rejectButtonText="Cancel"
          rejectBtnType="secondary"
          customButtonWidth={32}
          handleAccept={onDelete}
          handleReject={() => setIsDeleting(false)}
        />
      )}
    </>
  );
};

Skill.propTypes = {
  candidateSkill: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.string,
    proficiency: PropTypes.string,
    type: PropTypes.string,
    skill: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

export default Skill;
